import { useState } from "react";
import { Button } from "@mui/material";

import { ReturningListItem } from "types/returning";

import EditSenderDetailModal from "./EditSenderDetailModal";

export default function EditSenderDetail({
  returningDetail,
}: {
  returningDetail: ReturningListItem | undefined;
}) {
  const [showsEditSenderDetailModal, setShowsEditSenderDetailModal] =
    useState(false);

  const handleEditSenderDetailModalClose = () => {
    setShowsEditSenderDetailModal(false);
  };

  const handleEditSenderDetailModalOpen = () => {
    setShowsEditSenderDetailModal(true);
  };

  if (!returningDetail) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" onClick={handleEditSenderDetailModalOpen}>
        반품 배송 정보 수정
      </Button>

      <EditSenderDetailModal
        key={String(showsEditSenderDetailModal)}
        returningDetail={returningDetail}
        showsEditSenderDetailModal={showsEditSenderDetailModal}
        onEditSenderDetailModalClose={handleEditSenderDetailModalClose}
      />
    </>
  );
}

import { atom } from "jotai";

import { InspectionItemAgreementInfo } from "types/returning";

export type AgreementType = "unverified" | "problem" | "normal";

export type AgreementInfoState = Pick<InspectionItemAgreementInfo, "itemId"> &
  Partial<Pick<InspectionItemAgreementInfo, "processStatus" | "skuId">> & {
    type: AgreementType;
    actualQty?: number; // 검수 수량
    placeQty?: number | null; // 최종 입고 수량
    problemQuantity?: number; // 비정상 상품의 합계 수량
    normalQuantity?: number; // 정상 수량
    managementDate?: string;
  };

const AGREEMENT_INFO_LIST_FOR_INSPECTION = atom<AgreementInfoState[]>([]);

export default {
  AGREEMENT_INFO_LIST_FOR_INSPECTION,
};

import styled from "styled-components";

const imageSliderHeader = styled.div`
  > .image-type {
    display: flex;
    padding-top: 24px;

    > button {
      margin-right: 6px;
    }
  }

  > .item-name {
    margin-top: 20px;
  }

  > .package-info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 10px;
    font-weight: normal;
  }
`;

export default {
  imageSliderHeader,
};

import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { CalendarToday } from "@mui/icons-material";
import { Button } from "@mui/material";

import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";

import useDialog from "hooks/useDialog";
import { DomesticCategory } from "pages/shipping/domestic";
import { OverseasCategory } from "pages/shipping/overseas";

import { CalendarInputValue } from "components/Calendar";

import DueDateModal from "./DueDateModal";

export default function EditDueDate({
  pageCategory,
  selectedShippingIds,
}: {
  pageCategory: DomesticCategory | OverseasCategory;
  selectedShippingIds: number[];
}) {
  const [dueDate, seDueDate] = useState<CalendarInputValue>(null);

  const resetDueDateState = useCallback(() => {
    seDueDate(null);
  }, []);

  const [isDueDateModalOpen, handleDueDateModalOpen, handleDueDateModalClose] =
    useDialog({ onClose: resetDueDateState });

  const queryClient = useQueryClient();

  const {
    mutate: editDeliveryType,
    ResponseHandler: ResponseHandlerOfEditDeliveryType,
  } = SHIPPING_QUERY.useEditDeliveryInfo();

  const handleDueDateEdit = () => {
    if (!dueDate) {
      return;
    }

    editDeliveryType(
      { ids: selectedShippingIds, dueDate },
      {
        onSuccess: () => {
          handleDueDateModalClose();

          queryClient.invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping());
        },
      }
    );
  };

  const handleDueDateChange = (date: CalendarInputValue) => {
    seDueDate(date);
  };

  const needsTimePicker = pageCategory === "TRUCK";

  return (
    <>
      <Button
        startIcon={<CalendarToday />}
        variant="outlined"
        onClick={handleDueDateModalOpen}
        disabled={!selectedShippingIds.length}
      >
        {needsTimePicker ? "발송희망일시" : "발송희망일"} 변경
      </Button>

      <DueDateModal
        dueDate={dueDate}
        onDueDateChange={handleDueDateChange}
        selectedShippingCount={selectedShippingIds.length}
        isDueDateModalOpen={isDueDateModalOpen}
        onDueDateModalClose={handleDueDateModalClose}
        onDueDateModalConfirm={handleDueDateEdit}
        needsTimePicker={needsTimePicker}
      />

      {ResponseHandlerOfEditDeliveryType}
    </>
  );
}

import styled from "styled-components";

const inputTruckModalBody = styled.div`
  > .input-select {
    text-align: left;
    margin-bottom: 24px;
  }

  .input-text {
    margin-bottom: 8px;
  }
`;

const driverDetail = styled.div`
  margin-bottom: 24px;
`;

export default {
  inputTruckModalBody,
  driverDetail,
};

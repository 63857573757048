import { useMemo } from "react";

import { UserReturningDetailItem } from "types/returning";
import {
  checkForNormalItem,
  checkForProblemItem,
  checkForUnverifiedItem,
} from "utils/fulfillment";
import { getIsDifferentQuantity } from "./utils";

export default function useInspectionList({
  items,
  isDecidedQuantityStatus,
}: {
  items: UserReturningDetailItem[] | undefined;
  isDecidedQuantityStatus: boolean;
}) {
  // 정상 & 비정상 상품 리스트 - 불일치 상품을 제외한 모든 검수 리스트.
  // inspectProblems의 객체에 problem:"불일치상품"이 포함되지 않은 경우.
  const inspectionListExcludingUnverifiedItem = useMemo(() => {
    if (!items) return [];

    return items.filter(
      (item) => checkForNormalItem(item) || checkForProblemItem(item)
    );
  }, [items]);

  // 불일치 상품 리스트 - inspectProblems의 객체에 problem:"불일치상품" 이 포함되어있는 경우.
  const unverifiedList = useMemo(() => {
    if (!items) return [];

    return items.filter((item) => checkForUnverifiedItem(item));
  }, [items]);

  const hasProblem = useMemo(() => {
    if (!items) return false;

    return inspectionListExcludingUnverifiedItem.some((item) => {
      const isDifferenceQuantity = getIsDifferentQuantity({
        items,
        targetItem: item,
        isDecidedQuantityStatus,
      });
      const hasInspectionProblem = item.inspectProblems.length > 0;

      return hasInspectionProblem || isDifferenceQuantity;
    });
  }, [inspectionListExcludingUnverifiedItem, isDecidedQuantityStatus, items]);

  return {
    inspectionListExcludingUnverifiedItem,
    unverifiedList,
    hasProblem,
  };
}

import { useMemo } from "react";
import { GET_ADMIN_RECEIVING_LIST_REQ_STATUS } from "api-interfaces/receiving";

import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { ReceivingStatus } from "types/receiving";

type ListType = "requests" | "tasks" | "canceled";

type StatusFilterType = ReceivingStatus | "completeInspectionHold";

const filterOptionList: {
  [key in ListType]: TableHeadFilterOption<StatusFilterType>[];
} = {
  requests: [
    { label: "도착 전", value: "beforeReceiving" },
    { label: "검수 대기 중", value: "waitingInspection" },
  ],
  tasks: [
    { label: "검수 중", value: "inspecting" },
    {
      label: "검수완료(이슈)",
      value: "completeInspectionHold",
    },
    {
      label: "입고 중",
      value: "putAway",
    },
    { label: "전담매니저 확인 중", value: "hold" },
  ],
  canceled: [
    { label: "취소", value: "canceled" },
    { label: "회송", value: "returned" },
  ],
};

function getPayloadByStatusFilter(
  filter: StatusFilterType
): GET_ADMIN_RECEIVING_LIST_REQ_STATUS {
  if (filter === "completeInspectionHold") {
    return {
      status: "completeInspection",
      inspectionStatus: "hold",
    };
  }

  return {
    status: filter,
  };
}

export default function useStatusFilter({
  listType,
  resetCurrentPage,
}: {
  listType: ListType;
  resetCurrentPage?: () => void;
}) {
  const { FilterPanel: StatusFilterPanel, filterList: statusFilterList } =
    useTableHeadFilter({
      filterOptionList: filterOptionList[listType],
      resetCurrentPage,
    });

  const statusPayloadListByFilter = useMemo(() => {
    if (!statusFilterList) return [];

    const payloads: GET_ADMIN_RECEIVING_LIST_REQ_STATUS[] = [];

    statusFilterList.forEach((v) => {
      const payload = getPayloadByStatusFilter(v);
      if (payload) {
        payloads.push(payload);
      }
    });

    return payloads;
  }, [statusFilterList]);

  return {
    StatusFilterPanel,
    statusPayloadListByFilter,
  };
}

import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import useSkuImageModal from "hooks/useSkuImageModal";
import SKU_QUERY from "queries/SKU_QUERY";
import { useCallback, useMemo, useState } from "react";
import {
  getFormattedMaterialId,
  getFormattedSingleSkuId,
  getMaterialPackageType,
  getPackageCategoryName,
  getPackageTypeName,
  getReturningConditionName,
} from "utils/fulfillment";
import Detail, { DetailProps } from "../../Detail/Detail";

export default function useSingleProductDetailModal() {
  const [skuId, setSkuId] = useState<number>(0);
  const [active, setActive] = useState(false);

  const {
    data: singleSkuDetail,
    ResponseHandler: ResponseHandlerOfGetSingleSkuDetail,
  } = SKU_QUERY.useGetSingleSkuDetail({
    enabled: !!skuId,
    skuId,
  });

  const handleModalClose = useCallback(() => {
    setActive(false);
    setSkuId(0);
  }, []);

  const showSingleProductDetailModal = useCallback((skuId: number) => {
    setSkuId(skuId);
    setActive(true);
  }, []);

  const ResponseHandlerOfSingleProductDetail = useMemo(() => {
    return <>{ResponseHandlerOfGetSingleSkuDetail}</>;
  }, [ResponseHandlerOfGetSingleSkuDetail]);

  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();

  const SingleProductDetailModal = useMemo(() => {
    const handleSkuImageModalOpen = () => {
      if (singleSkuDetail) {
        const refinedSku = {
          ...singleSkuDetail.sku,
          attachment: [...singleSkuDetail.attachment],
          type: "single" as const,
        };
        handleSkuForImageModalSelect({
          skuData: refinedSku,
          imageType: "item",
        });
      }
    };

    const basicDetail: DetailProps = {
      title: "기본정보",
      detailList: [
        {
          label: "상품명",
          value: singleSkuDetail?.sku.itemName,
        },
        {
          label: "상품이미지",
          value: (
            <Button
              variant="outlined"
              size="small"
              sx={{ ml: 1 }}
              onClick={handleSkuImageModalOpen}
              disabled={!singleSkuDetail || !singleSkuDetail.attachment?.length}
            >
              이미지
            </Button>
          ),
        },
        {
          label: "회사명",
          value: singleSkuDetail?.sku.team?.company ?? "-",
        },
        {
          label: "상품바코드",
          value: singleSkuDetail?.sku.barCode ?? "-",
        },
        {
          label: "팀명",
          value: singleSkuDetail?.sku.team?.name ?? "-",
        },
        {
          label: "판매자상품코드",
          value: singleSkuDetail?.sku.productCode ?? "-",
        },
        {
          label: "유통기한/관리일자",
          value:
            singleSkuDetail?.sku.managementKind === "expirationDate"
              ? "유통기한"
              : singleSkuDetail?.sku.managementKind === "manufacturingDate"
              ? "제조일자"
              : "-",
        },
        {
          label: "옵션관리코드",
          value: singleSkuDetail?.sku.managementCode ?? "-",
        },
      ],
    };

    const additionalDetail: DetailProps = {
      title: "부가정보",
      detailList: [
        {
          label: "안전재고",
          value: singleSkuDetail?.sku.safetyQty ?? "-",
        },
        {
          label: "구매처",
          value: singleSkuDetail?.sku.buyer?.company ?? "-",
        },
        {
          label: "구매처 URL",
          value: singleSkuDetail?.sku.buyingURL ?? "-",
        },
      ],
    };

    const a = singleSkuDetail?.sku.packages?.map((v) => {
      return {};
    });

    const subsidiaryDetail: DetailProps = {
      title: "부자재",
      detailList: [
        {
          label: "포장방법",
          value: singleSkuDetail?.sku.materialPackageType
            ? getMaterialPackageType(singleSkuDetail?.sku.materialPackageType)
            : "",
        },
        ...(singleSkuDetail
          ? singleSkuDetail.sku.packages?.map((v) => {
              return {
                label: getPackageCategoryName(v.packageCategory),

                value: `${getPackageTypeName(
                  v.packageType
                )}(${getFormattedMaterialId(v)})`,
              };
            }) ?? []
          : []),
      ],
    };

    const returningDetail: DetailProps = {
      title: "반품조건",
      detailList: [
        {
          label: "반품조건",
          value: `${
            getReturningConditionName(singleSkuDetail?.sku.returningCondition)
              .label
          } (${singleSkuDetail?.sku.returningCondition})`,
        },
      ],
    };

    const exportDetail: DetailProps = {
      title: "수출정보",
      detailList: [
        {
          label: "영문상품명",
          value: singleSkuDetail?.sku.engName,
        },
        {
          label: "HS코드",
          value: singleSkuDetail?.sku.hsCode,
        },
        {
          label: "품목분류명",
          value: singleSkuDetail?.sku.engCategory,
        },
        {
          label: "상품 인보이스 금액",
          value: singleSkuDetail?.sku.value,
        },
      ],
    };

    return (
      <>
        <Dialog
          open={active}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleModalClose}
        >
          <DialogContent dividers>
            <Stack spacing={4}>
              <Typography variant="h6">
                {getFormattedSingleSkuId(singleSkuDetail?.sku.id)} 상세 정보
              </Typography>
              <Divider />
              <Stack gap={3}>
                <Detail {...basicDetail} />
                <Detail {...additionalDetail} />
                <Detail {...subsidiaryDetail} />
                <Detail {...returningDetail} />
                <Detail {...exportDetail} />
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        <SkuImageModal />
      </>
    );
  }, [
    SkuImageModal,
    active,
    handleModalClose,
    handleSkuForImageModalSelect,
    singleSkuDetail,
  ]);

  return {
    showSingleProductDetailModal,
    SingleProductDetailModal,
    ResponseHandlerOfSingleProductDetail,
  };
}

import { useCallback, useMemo } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import {
  ReturningDeliveringStatus,
  ReturningProcessStatus,
  ReturningStatus,
} from "types/returning";
import {
  checkCanDisposal,
  checkCanForward,
  checkCanRestock,
  RETURNING_PROCESS_STATUS_DICT,
} from "utils/returning";

interface SelectOptionType<T extends string> {
  label: string;
  value: T;
  disabled?: boolean;
}

export default function ProcessStatus({
  returningId,
  status,
  deliveringStatus,
  returningProcessStatus,
  openConfirmToChangeProcessStatus,
}: {
  returningId: number;
  status: ReturningStatus;
  deliveringStatus: ReturningDeliveringStatus;
  returningProcessStatus?: ReturningProcessStatus;
  openConfirmToChangeProcessStatus: ({
    returningId,
    processStatus,
  }: {
    returningId: number;
    processStatus: ReturningProcessStatus;
  }) => void;
}) {
  const selectOptions =
    useMemo((): SelectOptionType<ReturningProcessStatus>[] => {
      return [
        {
          label: "재입고",
          value: "restock",
          disabled: !checkCanRestock({ status }),
        },
        {
          label: "폐기",
          value: "disposal",
          disabled: !checkCanDisposal({ status }),
        },
        {
          label: "회송",
          value: "forward",
          disabled: !checkCanForward({ status, deliveringStatus }),
        },
      ];
    }, [deliveringStatus, status]);

  const handleSelect = useCallback(
    (event: SelectChangeEvent<ReturningProcessStatus | "">) => {
      if (event.target.value) {
        // 컨펌 & 이후 처리는 콜백에 위임
        openConfirmToChangeProcessStatus({
          returningId,
          processStatus: event.target.value as ReturningProcessStatus,
        });
      }
    },
    [openConfirmToChangeProcessStatus, returningId]
  );

  return returningProcessStatus ? (
    <span>{RETURNING_PROCESS_STATUS_DICT[returningProcessStatus]}</span>
  ) : (
    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
      <Select<ReturningProcessStatus | "">
        labelId="process-status-select-label"
        id="process-status-select"
        displayEmpty
        value={returningProcessStatus || ""}
        onChange={handleSelect}
        size="small"
        renderValue={() => {
          return <span style={{ color: "grey" }}>선택</span>;
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {selectOptions.map((option, i) => (
          <MenuItem value={option.value} key={i} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

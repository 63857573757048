import { useMemo } from "react";

import PRINT_QUERY from "queries/PRINT_QUERY";
import SKU_QUERY from "queries/SKU_QUERY";

/**
 * 입고 상품 라벨 출력을 처리
 */
export default function usePrintReceivingProductLabel() {
  const {
    mutate: getProductLabelPrintingData,
    ResponseHandler: ResponseHandlerOfGetProductLabelPrintingData,
    isLoading: isLoadingOfGetProductLabelPrintingData,
    reset: resetGetProductLabelPrintingData,
  } = SKU_QUERY.useGetProductLabelPrintingData({
    onSuccess: (res) => print(res),
  });

  const {
    mutate: print,
    ResponseHandler: ResponseHandlerOfPrint,
    data: resultOfPrint,
    isLoading: isLoadingOfPrint,
    reset: resetPrint,
  } = PRINT_QUERY.usePrint();

  const ResponseHandlerOfPrintProductLabel = useMemo(() => {
    return (
      <>
        {ResponseHandlerOfGetProductLabelPrintingData}
        {ResponseHandlerOfPrint}
      </>
    );
  }, [ResponseHandlerOfGetProductLabelPrintingData, ResponseHandlerOfPrint]);

  return {
    printProductLabel: getProductLabelPrintingData,
    ResponseHandlerOfPrintProductLabel,
    resultOfPrintProductLabel: resultOfPrint,
    isLoadingOfPrintProductLabel:
      isLoadingOfPrint || isLoadingOfGetProductLabelPrintingData,
    resetPrintProductLabel: () => {
      resetPrint();
      resetGetProductLabelPrintingData();
    },
  };
}

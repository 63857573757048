import { ReactNode } from "react";

import { COLOR } from "../../../styles/colors";

import StateBadge from "../StateBadge";

type FulfillmentStatus =
  | "preparation"
  | "inProgress"
  | "complete"
  | "problem"
  | "warning";

interface StateBadgeForFulfillmentProps {
  status: FulfillmentStatus;
  label: ReactNode;
  className?: string;
}

export type { FulfillmentStatus, StateBadgeForFulfillmentProps };

const STYLE_BY_STATUS: {
  [key in FulfillmentStatus]: {
    color: string;
    borderColor: string;
    backgroundColor: string;
  };
} = {
  preparation: {
    color: "#FF8A00",
    borderColor: "#FFE58F",
    backgroundColor: "#FFFBE6",
  },
  inProgress: {
    color: "#2D7738",
    borderColor: "rgba(76, 175, 80, 0.30)",
    backgroundColor: "rgba(76, 175, 80, 0.10)",
  },
  complete: {
    color: "#005AA3",
    borderColor: COLOR.primary_100,
    backgroundColor: "rgba(193, 220, 248, 0.40)",
  },
  problem: {
    color: "#8F2300",
    borderColor: "#F48B6A",
    backgroundColor: "rgba(237, 87, 39, 0.30)",
  },
  warning: {
    color: "#E94600",
    borderColor: "#FFB278",
    backgroundColor: "#FFE3CE",
  },
};

export default function StateBadgeForFulfillment({
  status,
  label,
  className,
}: StateBadgeForFulfillmentProps) {
  return (
    <StateBadge
      color={STYLE_BY_STATUS[status].color}
      borderColor={STYLE_BY_STATUS[status].borderColor}
      backgroundColor={STYLE_BY_STATUS[status].backgroundColor}
      type="default"
      size="small"
      hasBorder
      label={label}
      className={className}
    />
  );
}

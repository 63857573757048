import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { CreatedFromType } from "types/shipping";

const filterOptionList: TableHeadFilterOption<CreatedFromType>[] = [
  { label: "주문연동", value: "oms" },
  { label: "대량등록", value: "excel" },
  { label: "직접등록", value: "user" },
  { label: "API", value: "api" },
];

export default function useCreateFromFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: CreateFromFilterPanel,
    filterList: createFromFilterList,
    resetFilterState: resetCreateFromFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    CreateFromFilterPanel,
    createFromPayloadListByFilter: createFromFilterList,
    resetCreateFromFilter,
  };
}

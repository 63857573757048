import { ReactNode } from "react";

import { GET_ADMIN_SHIPPING_LIST_RES_TOTAL_SHIPPINGS } from "api-interfaces/shipping";
import { StateCode } from "types/address";
import { BofulWorker } from "types/auth";
import {
  AdminShippingListStatus,
  AdminShippingListTabStatus,
  AdminShippingListWmsStatus,
  AdminShippingLitItem,
  PartialShippingListItem,
} from "types/shipping";
import { isAfterBusinessDays, toFormattedDate } from "utils/date";
import { toThousandUnitFormat } from "utils/number";
import { getAdminStatus } from "utils/shipping";
import {
  GroupedHeadRow,
  GroupedHeadRowItem,
  TableHeadCell,
} from "components/Table";

import { CellId, GroupedHeadId } from "./types";

const getDefaultGroupedHeadRowCellIdList = (
  status: AdminShippingListTabStatus
): CellId[] => {
  if (status === "ALL") {
    return ["status"];
  }

  if (status === "PICKING") {
    return ["isETDSupported", "printedAt"];
  }

  if (
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT" ||
    status === "PACKING" ||
    status === "SHIPMENT_READY" ||
    status === "SHIPMENT_DONE"
  ) {
    return ["isETDSupported"];
  }

  if (status === "DONE" || status === "CANCEL") {
    return ["updateDate"];
  }

  return ["isETDSupported", "updateDate"];
};

const getShipmentAtGroupedHeadRowCellId = (
  status: AdminShippingListTabStatus
): CellId[] => {
  if (
    status === "ALL" ||
    status === "SHIPMENT_READY" ||
    status === "SHIPMENT_DONE" ||
    status === "DONE"
  ) {
    return ["shipmentAt"];
  }

  return [];
};

const getCustomerAddressHeadCellIds = (isForCSV: boolean): CellId[] => {
  if (isForCSV) {
    return [
      "customerDetailAddress1",
      "customerDetailAddress2",
      "customerDetailAddress3",
      "customerStateCode",
      "customerDetailAddress",
    ];
  }

  return ["customerAddress"];
};

const getTaskGroupedHeadRow = (
  status: AdminShippingListTabStatus
): GroupedHeadRowItem<GroupedHeadId, CellId>[] => {
  const pickingHeadCellIds: CellId[] = [
    "isPicking",
    "startedPickingAt",
    "endedPickingAt",
    "pickerId",
  ];
  const packingHeadCellIds: CellId[] = [
    "isPacking",
    "startedPackingAt",
    "endedPackingAt",
    "packerId",
  ];
  const shipmentDoneHeadCellIds: CellId[] = [
    "dispatchedAt",
    "isShipment",
    "sentAt",
    "isDelay",
  ];

  if (status === "ALL") {
    return [
      {
        id: "pickingTask",
        label: "작업현황(피킹)",
        headCellIds: ["printedAt", ...pickingHeadCellIds],
      },
      {
        id: "packingTask",
        label: "작업현황(패킹)",
        headCellIds: packingHeadCellIds,
      },
      {
        id: "shipmentTask",
        label: "출하완료현황",
        headCellIds: shipmentDoneHeadCellIds,
      },
      {
        id: "doneTask",
        label: "배송완료현황",
        headCellIds: ["doneUpdatedDate"],
      },
      {
        id: "cancelTask",
        label: "취소현황",
        headCellIds: ["cancelUpdatedDate"],
      },
    ];
  }

  if (status === "PICKING") {
    return [
      {
        id: "task",
        label: "작업현황",
        headCellIds: pickingHeadCellIds,
      },
    ];
  }

  if (status === "PACKING") {
    return [
      {
        id: "task",
        label: "작업현황",
        headCellIds: packingHeadCellIds,
      },
    ];
  }

  if (status === "SHIPMENT_READY") {
    return [
      {
        id: "task",
        label: "작업현황",
        headCellIds: ["endedPackingAt"],
      },
    ];
  }

  if (status === "SHIPMENT_DONE") {
    return [
      {
        id: "task",
        label: "작업현황",
        headCellIds: shipmentDoneHeadCellIds,
      },
    ];
  }

  return [
    {
      id: "task",
      label: "작업현황",
      headCellIds: [],
    },
  ];
};

const getGroupedHeadRow = ({
  status,
  isForCSV,
}: {
  status: AdminShippingListTabStatus | undefined;
  isForCSV: boolean;
}): GroupedHeadRow<GroupedHeadId, CellId> => {
  if (!status) {
    return [];
  }

  return [
    {
      id: "default",
      label: "기본정보",
      headCellIds: [
        "shippingId",
        "saleChannel",
        "orderNo",
        "createdAt",
        "createdFrom",
        ...getDefaultGroupedHeadRowCellIdList(status),
      ],
    },
    {
      id: "product",
      label: "상품정보",
      headCellIds: [
        "skuIdList",
        "orderQuantity",
        "shippingQuantity",
        "itemName",
      ],
    },
    {
      id: "delivery",
      label: "운송정보",
      headCellIds: [
        "dueDate",
        ...getShipmentAtGroupedHeadRowCellId(status),
        "deliveryType",
        "parcelCompany",
        "invoiceNo",
      ],
    },
    {
      id: "sender",
      label: "보내는 분 정보",
      headCellIds: [
        "senderCompanyName",
        "senderTeamName",
        "senderName",
        "senderPhone",
      ],
    },

    {
      id: "customer",
      label: "받는 분 정보",
      headCellIds: [
        "customerCountryCode",
        "customerName",
        "customerPhone",
        ...getCustomerAddressHeadCellIds(isForCSV),
        "customerPostalCode",
      ],
    },
    ...getTaskGroupedHeadRow(status),
    { id: "memo", label: "요청사항", headCellIds: ["memo"] },
    { id: "adminMemo", label: "메모", headCellIds: ["adminMemo"] },
  ];
};

type DefaultColumnFilterType = "isETDSupported";
const getDefaultHeadCells = ({
  status,
  columnFilter,
}: {
  status: AdminShippingListTabStatus;
  columnFilter: Record<DefaultColumnFilterType, ReactNode> | undefined;
}): TableHeadCell<CellId>[] => {
  if (status === "ALL") {
    return [
      {
        id: "status",
        disablePadding: false,
        label: "상태",
        width: 120,
      },
    ];
  }

  if (status === "PICKING") {
    return [
      {
        id: "isETDSupported",
        disablePadding: false,
        label: "ETD",
        width: 120,
        filter: columnFilter?.isETDSupported,
      },
      {
        id: "printedAt",
        disablePadding: false,
        label: "송장출력일시",
        width: 160,
      },
    ];
  }

  if (
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT" ||
    status === "PACKING" ||
    status === "SHIPMENT_READY" ||
    status === "SHIPMENT_DONE"
  ) {
    return [
      {
        id: "isETDSupported",
        disablePadding: false,
        label: "ETD",
        width: 120,
        filter: columnFilter?.isETDSupported,
      },
    ];
  }

  if (status === "DONE" || status === "CANCEL") {
    return [
      {
        id: "updateDate",
        disablePadding: false,
        label: "업데이트일시",
        width: 160,
      },
    ];
  }

  return [
    {
      id: "isETDSupported",
      disablePadding: false,
      label: "ETD",
      width: 120,
      filter: columnFilter?.isETDSupported,
    },
    {
      id: "updateDate",
      disablePadding: false,
      label: "업데이트일시",
      width: 160,
    },
  ];
};

const getShipmentAtHeadCells = (
  status: AdminShippingListTabStatus
): TableHeadCell<CellId>[] => {
  if (
    status === "ALL" ||
    status === "SHIPMENT_READY" ||
    status === "SHIPMENT_DONE" ||
    status === "DONE"
  ) {
    return [
      {
        // 패킹마감일을 발송일로 써달라고 함 (백엔드 요청)
        id: "shipmentAt",
        disablePadding: false,
        label: "발송일시",
        width: 160,
      },
    ];
  }

  return [];
};

type TaskColumnFilterType =
  | "isPicking"
  | "isPacking"
  | "isShipment"
  | "isDelay";
const getTaskHeadCells = ({
  status,
  columnFilter,
}: {
  status: AdminShippingListTabStatus;
  columnFilter: Record<TaskColumnFilterType, ReactNode>;
}): TableHeadCell<CellId>[] => {
  const pickingHeadCells: TableHeadCell<CellId>[] = [
    {
      id: "isPicking",
      disablePadding: false,
      label: "피킹시작여부",
      width: 150,
      filter: columnFilter.isPicking,
    },
    {
      id: "startedPickingAt",
      disablePadding: false,
      label: "피킹시작일시",
      width: 160,
    },
    {
      id: "endedPickingAt",
      disablePadding: false,
      label: "피킹마감일시",
      width: 160,
    },
    {
      id: "pickerId",
      disablePadding: false,
      label: "최초담당자ID",
      width: 120,
    },
  ];
  const packingHeadCells: TableHeadCell<CellId>[] = [
    {
      id: "isPacking",
      disablePadding: false,
      label: "패킹시작여부",
      width: 150,
      filter: columnFilter.isPacking,
    },
    {
      id: "startedPackingAt",
      disablePadding: false,
      label: "패킹시작일시",
      width: 160,
    },
    {
      id: "endedPackingAt",
      disablePadding: false,
      label: "패킹마감일시",
      width: 160,
    },
    {
      id: "packerId",
      disablePadding: false,
      label: "최초담당자ID",
      width: 120,
    },
  ];
  const shipmentDoneHeadCells: TableHeadCell<CellId>[] = [
    {
      id: "dispatchedAt",
      disablePadding: false,
      label: "출하마감일시",
      width: 160,
    },
    {
      id: "isShipment",
      disablePadding: false,
      label: "집하여부",
      width: 150,
      filter: columnFilter.isShipment,
    },
    {
      id: "sentAt",
      disablePadding: false,
      label: "집화일시",
      width: 160,
    },
    {
      id: "isDelay",
      disablePadding: false,
      label: "배송지연여부",
      width: 150,
      filter: columnFilter.isDelay,
    },
  ];

  if (status === "ALL") {
    return [
      /** 작업현황(피킹) */
      {
        id: "printedAt",
        disablePadding: false,
        label: "송장출력일시",
        width: 160,
      },
      ...pickingHeadCells,
      /** 작업현황(패킹) */
      ...packingHeadCells,
      /** 출하완료현황 */
      ...shipmentDoneHeadCells,
      /** 배송완료현황 */
      {
        id: "doneUpdatedDate",
        disablePadding: false,
        label: "업데이트일시",
        width: 160,
      },
      /** 취소현황 */
      {
        id: "cancelUpdatedDate",
        disablePadding: false,
        label: "업데이트일시",
        width: 160,
      },
    ];
  }

  if (status === "PICKING") {
    return pickingHeadCells;
  }

  if (status === "PACKING") {
    return packingHeadCells;
  }

  if (status === "SHIPMENT_READY") {
    return [
      {
        id: "endedPackingAt",
        disablePadding: false,
        label: "패킹마감일시",
        width: 160,
      },
    ];
  }

  if (status === "SHIPMENT_DONE") {
    return shipmentDoneHeadCells;
  }

  return [];
};

const getAddressHeadCells = (isForCSV?: boolean): TableHeadCell<CellId>[] => {
  if (isForCSV) {
    return [
      {
        id: "customerDetailAddress1",
        disablePadding: false,
        label: "상세주소1",
        width: 400,
      },
      {
        id: "customerDetailAddress2",
        disablePadding: false,
        label: "상세주소2",
        width: 400,
      },
      {
        id: "customerDetailAddress3",
        disablePadding: false,
        label: "상세주소3",
        width: 400,
      },
      {
        id: "customerStateCode",
        disablePadding: false,
        label: "주명",
        width: 120,
      },
      {
        id: "customerDetailAddress",
        disablePadding: false,
        label: "도시명",
        width: 400,
      },
    ];
  }

  return [
    {
      id: "customerAddress",
      disablePadding: false,
      label: "주소",
      width: 400,
    },
  ];
};

type ColumnFilterType =
  | "saleChannel"
  | "createFrom"
  | "deliveryType"
  | "parcelCompany"
  | "isPicking"
  | "isPacking"
  | "isShipment"
  | "isDelay"
  | "shippingQuantity"
  | "isETDSupported";
const getHeadCells = ({
  status,
  isForCSV,
  columnFilter,
}: {
  status: AdminShippingListTabStatus | undefined;
  isForCSV: boolean;
  columnFilter?: Record<ColumnFilterType, ReactNode>;
}): TableHeadCell<CellId>[] => {
  if (!status) {
    return [];
  }

  return [
    /** 기본정보 */
    {
      id: "shippingId",
      disablePadding: false,
      label: "출고요청번호",
      width: 140,
    },
    {
      id: "saleChannel",
      disablePadding: false,
      label: "판매채널",
      width: 130,
      filter: columnFilter?.saleChannel,
    },
    {
      id: "orderNo",
      disablePadding: false,
      label: "주문번호",
      width: 200,
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: "등록일시",
      width: 160,
    },
    {
      id: "createdFrom",
      disablePadding: false,
      label: "등록방식",
      width: 120,
      filter: columnFilter?.createFrom,
    },
    ...getDefaultHeadCells({ status, columnFilter }),
    /** 상품정보 */
    {
      id: "skuIdList",
      disablePadding: false,
      label: "SKU ID",
      width: 150,
    },
    {
      id: "orderQuantity",
      disablePadding: false,
      label: "주문수량(ORDER)",
      width: 150,
    },
    {
      id: "shippingQuantity",
      disablePadding: false,
      label: "출고수량(PCS)",
      width: 160,
      ...(status === "WAITING_EXPECTED" ||
      status === "WAITING_TODAY" ||
      status === "INSPECT" ||
      status === "PICKING" ||
      status === "PACKING"
        ? {
            filter: columnFilter?.shippingQuantity,
          }
        : {}),
    },
    {
      id: "itemName",
      disablePadding: false,
      label: "상품명",
      width: 200,
    },
    /** 운송정보 */
    {
      id: "dueDate",
      disablePadding: false,
      label: "발송희망일",
      width: 150,
    },
    ...getShipmentAtHeadCells(status),
    {
      id: "deliveryType",
      disablePadding: false,
      label: "운송수단",
      width: 120,
      filter: columnFilter?.deliveryType,
    },
    {
      id: "parcelCompany",
      disablePadding: false,
      label: "택배사",
      width: 120,
      filter: columnFilter?.parcelCompany,
    },
    {
      id: "invoiceNo",
      disablePadding: false,
      label: "송장번호",
      width: 240,
    },
    /** 보내는 분 정보 */
    {
      id: "senderCompanyName",
      disablePadding: false,
      label: "회사명",
      width: 150,
    },
    {
      id: "senderTeamName",
      disablePadding: false,
      label: "팀명",
      width: 150,
    },
    {
      id: "senderName",
      disablePadding: false,
      label: "성함",
      width: 150,
    },
    {
      id: "senderPhone",
      disablePadding: false,
      label: "연락처",
      width: 160,
    },
    /** 받는 분 정보 */
    {
      id: "customerCountryCode",
      disablePadding: false,
      label: "국가명",
      width: 80,
    },
    {
      id: "customerName",
      disablePadding: false,
      label: "성함",
      width: 150,
    },
    {
      id: "customerPhone",
      disablePadding: false,
      label: "연락처",
      width: 150,
    },
    ...getAddressHeadCells(isForCSV),
    {
      id: "customerPostalCode",
      disablePadding: false,
      label: "우편번호",
      width: 130,
    },
    /** 작업현황 */
    ...getTaskHeadCells({
      status,
      columnFilter: {
        isPicking: columnFilter?.isPicking,
        isPacking: columnFilter?.isPacking,
        isShipment: columnFilter?.isShipment,
        isDelay: columnFilter?.isDelay,
      },
    }),
    /** 요청사항 */
    {
      id: "memo",
      disablePadding: false,
      label: "요청사항",
      width: 120,
    },
    /** 메모 */
    {
      id: "adminMemo",
      disablePadding: false,
      label: "메모",
      width: 120,
    },
  ];
};

const getDefaultData = ({
  status,
  shipping,
}: {
  status: AdminShippingListTabStatus | undefined;
  shipping: AdminShippingLitItem | undefined;
}) => {
  if (!status || !shipping) {
    return {};
  }

  if (status === "ALL") {
    return {
      status: getAdminStatus(shipping),
    };
  }

  if (status === "PICKING") {
    return {
      isETDSupported: shipping.isETDSupported ? "ETD 제공" : "ETD 미제공",
      printedAt: toFormattedDate(shipping.printedAt, "YYYY-MM-DD HH:mm"),
    };
  }

  if (status === "DONE") {
    return {
      updateDate: toFormattedDate(shipping.deliveredAt, "YYYY-MM-DD HH:mm"),
    };
  }

  if (status === "CANCEL") {
    return {
      updateDate: toFormattedDate(shipping.canceledAt, "YYYY-MM-DD HH:mm"),
    };
  }

  return {
    isETDSupported: shipping.isETDSupported ? "ETD 제공" : "ETD 미제공",
  };
};

const getShipmentAt = ({
  status,
  shipping,
}: {
  status: AdminShippingListTabStatus | undefined;
  shipping: AdminShippingLitItem | undefined;
}) => {
  if (!status || !shipping) {
    return {};
  }

  if (
    status === "ALL" ||
    status === "SHIPMENT_READY" ||
    status === "SHIPMENT_DONE" ||
    status === "DONE"
  ) {
    return {
      shipmentAt: toFormattedDate(shipping.endedPackingAt, "YYYY-MM-DD HH:mm"),
    };
  }

  return {};
};

const getCustomerAddressDataList = ({
  customerAddress,
  customerStateCode,
  customerDetailAddress,
  isForCSV,
}: {
  customerAddress: string;
  customerStateCode: StateCode | undefined;
  customerDetailAddress: string | undefined;
  isForCSV?: boolean;
}) => {
  if (isForCSV) {
    return {
      customerDetailAddress1: customerAddress.slice(0, 150),
      customerDetailAddress2: customerAddress.slice(150, 300),
      customerDetailAddress3: customerAddress.slice(300, 450),
      customerStateCode,
      customerDetailAddress,
    };
  }

  return { customerAddress };
};

const getTaskDataList = ({
  status,
  shipping,
  workerList,
}: {
  status: AdminShippingListTabStatus | undefined;
  shipping: AdminShippingLitItem | undefined;
  workerList: BofulWorker[] | undefined;
}) => {
  if (!status || !shipping) {
    return {};
  }

  const pickingTaskDataList = {
    isPicking: shipping.startedPickingAt ? "Y" : "N",
    startedPickingAt: toFormattedDate(
      shipping.startedPickingAt,
      "YYYY-MM-DD HH:mm"
    ),
    endedPickingAt: toFormattedDate(
      shipping.endedPickingAt,
      "YYYY-MM-DD HH:mm"
    ),
    pickerId: workerList?.find((worker) => worker.id === shipping.pickerId)
      ?.accountId,
  };
  const packingTaskDataList = {
    isPacking: shipping.startedPackingAt ? "Y" : "N",
    startedPackingAt: toFormattedDate(
      shipping.startedPackingAt,
      "YYYY-MM-DD HH:mm"
    ),
    endedPackingAt: toFormattedDate(
      shipping.endedPackingAt,
      "YYYY-MM-DD HH:mm"
    ),
    packerId: workerList?.find((worker) => worker.id === shipping.packerId)
      ?.accountId,
  };
  const shipmentDoneTaskDataList = {
    dispatchedAt: toFormattedDate(shipping.dispatchedAt, "YYYY-MM-DD HH:mm"),
    isShipment: shipping.sentAt ? "Y" : "N",
    sentAt: toFormattedDate(shipping.sentAt, "YYYY-MM-DD HH:mm"),
    /**
     * 출하완료 후 배송완료까지 3영업일이 지났으나 택배사로부터 완료 API를 받지 못했을 경우
     */
    isDelay:
      shipping.shippingStatus === "delivering" &&
      shipping.endedPackingAt &&
      isAfterBusinessDays({
        baseDate: shipping.endedPackingAt,
        days: 3,
      })
        ? "Y"
        : "N",
  };

  if (status === "ALL") {
    return {
      /** 작업현황(피킹) */
      printedAt: toFormattedDate(shipping.printedAt, "YYYY-MM-DD HH:mm"),
      ...pickingTaskDataList,
      /** 작업현황(패킹) */
      ...packingTaskDataList,
      /** 출하완료현황 */
      ...shipmentDoneTaskDataList,
      /** 배송완료현황 */
      doneUpdatedDate: toFormattedDate(
        shipping.deliveredAt,
        "YYYY-MM-DD HH:mm"
      ),
      /** 취소현황 */
      cancelUpdatedDate: toFormattedDate(
        shipping.canceledAt,
        "YYYY-MM-DD HH:mm"
      ),
    };
  }

  if (status === "PICKING") {
    return pickingTaskDataList;
  }

  if (status === "PACKING") {
    return packingTaskDataList;
  }

  if (status === "SHIPMENT_READY") {
    return {
      endedPackingAt: toFormattedDate(
        shipping.endedPackingAt,
        "YYYY-MM-DD HH:mm"
      ),
    };
  }

  if (status === "SHIPMENT_DONE") {
    return shipmentDoneTaskDataList;
  }

  return {};
};

const getGroupedHeadRowIdsToExceptForExcelDownload = (
  status: AdminShippingListTabStatus | undefined
) => {
  if (!status) {
    return [];
  }

  if (
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT" ||
    status === "DONE" ||
    status === "CANCEL"
  ) {
    return ["checkbox", "task", "adminMemo"];
  }

  return ["checkbox", "adminMemo"];
};

const makeStatusToGetAdminShippingList = (
  status: AdminShippingListTabStatus
): {
  shippingStatus?: AdminShippingListStatus;
  wmsStatus?: AdminShippingListWmsStatus;
} => {
  switch (status) {
    case "ALL": {
      return {};
    }

    case "WAITING_EXPECTED": {
      return {
        shippingStatus: "waitingExpected",
      };
    }

    case "WAITING_TODAY": {
      return {
        shippingStatus: "waitingToday",
      };
    }

    case "INSPECT": {
      return {
        shippingStatus: "inspect",
      };
    }

    case "PICKING": {
      return {
        wmsStatus: "picking",
      };
    }

    case "PACKING": {
      return {
        wmsStatus: "packing",
      };
    }

    case "SHIPMENT_READY": {
      return {
        wmsStatus: "ready",
      };
    }

    case "SHIPMENT_DONE": {
      return {
        wmsStatus: "done",
      };
    }

    case "DONE": {
      return {
        shippingStatus: "done",
      };
    }

    case "CANCEL": {
      return {
        shippingStatus: "cancel",
      };
    }

    default: {
      return {};
    }
  }
};

const getInvoiceNoFromShipping = ({
  shipping,
  isForCSV,
}: {
  shipping: PartialShippingListItem;
  isForCSV?: boolean;
}) => {
  if (!shipping.packings?.length || !shipping.packings.length) {
    return shipping.invoiceNo;
  }

  if (isForCSV) {
    return shipping.packings?.map((p) => p.invoiceNo).join("\n");
  }

  return (
    <>
      {shipping.packings?.reduce((a, c) => {
        if (c.invoiceNo) {
          a.push(<p key={c.invoiceNo}>{c.invoiceNo}</p>);
        }

        return a;
      }, [] as React.ReactNode[])}
    </>
  );
};

const getFormattedLabel = ({
  currentTabStatus,
  status,
  label,
  count,
  total,
}: {
  currentTabStatus: AdminShippingListTabStatus;
  status: AdminShippingListTabStatus;
  label: string;
  count: number | string;
  total: number | undefined;
}) => {
  return (
    <>
      {label}
      <br />(
      {toThousandUnitFormat(currentTabStatus === status ? total ?? 0 : count)})
    </>
  );
};

const getTabList = ({
  status,
  totalShippings,
  total,
}: {
  status: AdminShippingListTabStatus | undefined;
  totalShippings: GET_ADMIN_SHIPPING_LIST_RES_TOTAL_SHIPPINGS | undefined;
  total: number | undefined;
}): { label: ReactNode; status: AdminShippingListTabStatus }[] => {
  if (!status) {
    return [];
  }

  return [
    {
      label: "전체",
      status: "ALL",
    },
    {
      label: getFormattedLabel({
        currentTabStatus: status,
        status: "INSPECT",
        label: "에러발생",
        count: totalShippings?.inspect ?? 0,
        total,
      }),
      status: "INSPECT",
    },
    {
      label: getFormattedLabel({
        currentTabStatus: status,
        status: "WAITING_EXPECTED",
        label: "의뢰대기(예정)",
        count: totalShippings?.waitingExpected ?? 0,
        total,
      }),
      status: "WAITING_EXPECTED",
    },
    {
      label: getFormattedLabel({
        currentTabStatus: status,
        status: "WAITING_TODAY",
        label: "의뢰대기(당일)",
        count: totalShippings?.waitingToday ?? 0,
        total,
      }),
      status: "WAITING_TODAY",
    },
    {
      label: getFormattedLabel({
        currentTabStatus: status,
        status: "PICKING",
        label: "피킹 중",
        count: totalShippings?.picking ?? 0,
        total,
      }),
      status: "PICKING",
    },
    {
      label: getFormattedLabel({
        currentTabStatus: status,
        status: "PACKING",
        label: "패킹 중",
        count: totalShippings?.packing ?? 0,
        total,
      }),
      status: "PACKING",
    },
    {
      label: getFormattedLabel({
        currentTabStatus: status,
        status: "SHIPMENT_READY",
        label: "출하 준비",
        count: totalShippings?.ready ?? 0,
        total,
      }),
      status: "SHIPMENT_READY",
    },
    {
      label: getFormattedLabel({
        currentTabStatus: status,
        status: "SHIPMENT_DONE",
        label: "출하 완료",
        count: totalShippings?.delivering ?? 0,
        total,
      }),
      status: "SHIPMENT_DONE",
    },
    {
      label: "배송 완료",
      status: "DONE",
    },
    {
      label: "취소",
      status: "CANCEL",
    },
  ];
};

export {
  getGroupedHeadRow,
  getHeadCells,
  getDefaultData,
  getShipmentAt,
  getCustomerAddressDataList,
  getTaskDataList,
  getGroupedHeadRowIdsToExceptForExcelDownload,
  makeStatusToGetAdminShippingList,
  getInvoiceNoFromShipping,
  getTabList,
};

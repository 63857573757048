import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { GET_MATERIAL_LIST_FOR_TOTAL_PICKING_RES } from "api-interfaces/material";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { toThousandUnitFormat } from "utils/number";

export default function TempTotalPickingModal({
  selectedShippingIdList,
  materialListForTotalPicking,
  isTempTotalPickingModalOpen,
  onTempTotalPickingModalClose,
}: {
  selectedShippingIdList: number[];
  materialListForTotalPicking:
    | GET_MATERIAL_LIST_FOR_TOTAL_PICKING_RES
    | undefined;
  isTempTotalPickingModalOpen: boolean;
  onTempTotalPickingModalClose: () => void;
}) {
  const [materialId, setMaterialId] = useState<number | null>(null);

  const queryClient = useQueryClient();

  const {
    mutateAsync: forceShipping,
    ResponseHandler: ResponseHandlerOfForceShipping,
  } = SHIPPING_QUERY.useForceShipping({});

  const materialList = (materialListForTotalPicking ?? []).map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const selectedMaterial =
    materialList.find((option) => option.value === materialId) ?? null;

  const handleMaterialChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: { label: string; value: number } | null
  ) => {
    setMaterialId(value?.value ?? null);
  };

  const handleForceShippingComplete = async () => {
    if (!materialId) {
      return;
    }

    for (const shippingId of selectedShippingIdList) {
      await forceShipping({
        shippingId,
        outerPackagesId: materialId,
        adminMemo: "토탈피킹",
      });
    }

    queryClient
      .invalidateQueries(SHIPPING_QUERY_KEY_GEN.getAdminShippingList({}))
      .then(onTempTotalPickingModalClose);
  };

  return (
    <>
      <Dialog
        open={isTempTotalPickingModalOpen}
        onClose={onTempTotalPickingModalClose}
      >
        <DialogTitle>
          출고 {toThousandUnitFormat(selectedShippingIdList.length)}건을 선택한
          포장재로 출고 처리 합니다.
        </DialogTitle>

        <DialogContent>
          <Autocomplete
            sx={{ mt: 1 }}
            options={materialList}
            renderInput={(params) => <TextField {...params} label="포장재" />}
            value={selectedMaterial}
            onChange={handleMaterialChange}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={handleForceShippingComplete}
            disabled={!materialId}
          >
            요청
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfForceShipping}
    </>
  );
}

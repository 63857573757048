import Icon from "components/sds-v1/Icon";
import Styled from "./index.styles";
import { COLOR } from "styles/constants";

export default function BeforeCompleteInspection() {
  return (
    <Styled.beforeCompleteInspectionContainer>
      <Icon type="warning" size={2.5} color={COLOR.pointWarning} />

      <span className="title">현재 검수 진행 중입니다.</span>

      <span className="desc">
        입고요청된 상품의 검수가 완료된 후 검수 결과를 확인하실 수 있습니다.
      </span>
    </Styled.beforeCompleteInspectionContainer>
  );
}

import { useMemo } from "react";
import { useAtom } from "jotai";
import { FULFILLMENT_INSPECTION_ATOMS } from "jotaiStates/inspection";
import {
  AgreementInfoState,
  AgreementType,
} from "jotaiStates/inspection/atoms";

import { COLOR } from "styles/constants";
import { toThousandUnitFormat } from "utils/number";
import { replaceEmptyToDash } from "utils/string";

import Icon from "components/sds-v1/Icon";
import ToolTip from "components/sds-v1/ToolTip";
import StateBadgeForInspection from "_sds-v2/components/stateBadge/StateBadgeForInspection";

import Styled from "./index.styles";

const getQuantityOfFilteredType = (
  list: AgreementInfoState[],
  type: AgreementType,
  quantityCategory:
    | "actualQty"
    | "problemQuantity"
    | "normalQuantity"
    | "placeQty"
) => {
  return list
    .filter((item) => item.type === type)
    .reduce((acc, cur) => acc + (cur[quantityCategory] ?? 0), 0);
};

export default function InspectQuantityConfirm({
  canAgreeToWarehousing,
  canSelectedProcessStatus,
  isDecidedQuantityStatus,
}: {
  canAgreeToWarehousing: boolean;
  canSelectedProcessStatus: boolean;
  isDecidedQuantityStatus: boolean;
}) {
  const [agreementInfoList, setAgreementInfoList] = useAtom(
    FULFILLMENT_INSPECTION_ATOMS.AGREEMENT_INFO_LIST_FOR_INSPECTION
  );

  const stockStatusList = agreementInfoList.filter(
    ({ processStatus }) => processStatus === "stock"
  );

  const faultyStatusList = agreementInfoList.filter(
    ({ processStatus }) => processStatus === "faulty"
  );

  // 총 정상입고 수량 - 검수결과 이상 없음과 수량불일치의 검수수량, 각 입고처리별 정상수량의 합계.
  const stockQuantity = useMemo(() => {
    if (!canAgreeToWarehousing) return;

    // 정상입고를 선택한 불일치 상품의 정상수량 합계
    const stockQuantityOfStockUnverifiedList = getQuantityOfFilteredType(
      stockStatusList,
      "unverified",
      isDecidedQuantityStatus ? "placeQty" : "actualQty"
    );

    // 정상입고를 선택한 비정상 상품의 정상수량 합계
    const stockQuantityOfStockProblemList = getQuantityOfFilteredType(
      stockStatusList,
      "problem",
      isDecidedQuantityStatus ? "placeQty" : "actualQty"
    );

    // 불량입고를 선택한 비정상 상품의 정상수량 합계
    const stockQuantityOfFaultyProblemList = getQuantityOfFilteredType(
      faultyStatusList,
      "problem",
      "normalQuantity"
    );

    // 검수결과 이상 없음과 수량불일치의 검수수량
    const normalQuantity = getQuantityOfFilteredType(
      agreementInfoList,
      "normal",
      isDecidedQuantityStatus ? "placeQty" : "actualQty"
    );

    return (
      stockQuantityOfStockUnverifiedList +
      stockQuantityOfStockProblemList +
      stockQuantityOfFaultyProblemList +
      normalQuantity
    );
  }, [
    agreementInfoList,
    canAgreeToWarehousing,
    faultyStatusList,
    isDecidedQuantityStatus,
    stockStatusList,
  ]);

  // 총 불량입고 수량 - 각 입고처리별 불량수량의 합계.
  const faultyQuantity = useMemo(() => {
    if (!canAgreeToWarehousing) return;

    // 불량입고를 선택한 불일치 상품의 불량수량 합계
    const faultyQuantityOfFaultyUnverifiedList = getQuantityOfFilteredType(
      faultyStatusList,
      "unverified",
      isDecidedQuantityStatus ? "placeQty" : "actualQty"
    );

    // 불량입고를 선택한 비정상 상품의 불량수량 합계
    const faultyQuantityOfFaultyProblemList = getQuantityOfFilteredType(
      faultyStatusList,
      "problem",
      "problemQuantity"
    );

    return (
      faultyQuantityOfFaultyUnverifiedList + faultyQuantityOfFaultyProblemList
    );
  }, [canAgreeToWarehousing, faultyStatusList, isDecidedQuantityStatus]);

  const totalQuantity = useMemo(() => {
    if (!canAgreeToWarehousing) return;

    return (stockQuantity ?? 0) + (faultyQuantity ?? 0);
  }, [canAgreeToWarehousing, faultyQuantity, stockQuantity]);

  return (
    <Styled.inspectConfirmContainer>
      <Styled.title>최종 검수 확인</Styled.title>

      <Styled.inspectTotalQuantity
        isBackgroundColorBlue={canAgreeToWarehousing}
      >
        <Styled.badgeAndQuantity>
          <StateBadgeForInspection
            status="stock"
            label="정상입고"
            disabled={!canAgreeToWarehousing}
          />

          <span>
            {replaceEmptyToDash(toThousandUnitFormat(stockQuantity), true)} PCS
          </span>
        </Styled.badgeAndQuantity>

        <Styled.badgeAndQuantity>
          <StateBadgeForInspection
            status="faulty"
            label="불량입고"
            disabled={!canAgreeToWarehousing}
          />

          <span>
            {replaceEmptyToDash(toThousandUnitFormat(faultyQuantity), true)} PCS
          </span>
        </Styled.badgeAndQuantity>

        <div className="divider" />

        <Styled.totalQuantity>
          <span className="label">
            {isDecidedQuantityStatus ? "최종 입고수량" : "입고 예정수량"}

            <ToolTip
              displayType="block"
              contentForDesktop={{
                position: "bottomRight",
                content: {
                  type: "descOnly",
                  desc: isDecidedQuantityStatus ? (
                    <>
                      입고 시 최종으로 반영되는 수량입니다.
                      <br />
                      입고 단계에서 특정 문제 발생 시 입고 수량이 변동될 수
                      있습니다.
                    </>
                  ) : (
                    <>
                      입고하기 전 검수완료(이슈) 단계에서 비정상 또는 불일치
                      상품 발생 시<br />
                      정상, 불량 입고 처리를 선택하여 반영되는 수량입니다.
                    </>
                  ),
                },
                bodyWidth: 26,
              }}
              contentForMobile={{
                isSameToDesktop: true,
              }}
            >
              <Icon
                type="circleFilledInformation"
                color={COLOR.grayScale_800}
                size={1}
              />
            </ToolTip>
          </span>

          {canSelectedProcessStatus && (
            <div className="guide">
              <Icon
                type="circleFilledInformation"
                size={1}
                color={COLOR.grayScale_600}
              />

              <span>
                각 불일치/비정상 상품에 대해서 “선택하기”를 눌러 검수완료 처리를
                해주세요.
              </span>
            </div>
          )}

          <span className="quantity">
            {replaceEmptyToDash(toThousandUnitFormat(totalQuantity), true)} PCS
          </span>
        </Styled.totalQuantity>
      </Styled.inspectTotalQuantity>
    </Styled.inspectConfirmContainer>
  );
}

import { ProcessStatus, UserReturningDetailItem } from "types/returning";
import { checkUsesManagementDate } from "utils/common";
import { calculateDifference } from "utils/number";

import StateBadgeForInspection from "_sds-v2/components/stateBadge/StateBadgeForInspection";

import Styled from "./index.styles";

const getProblemStatus = (processStatus: ProcessStatus | "all") => {
  if (processStatus === "all")
    return (
      <Styled.problemStatus>
        <StateBadgeForInspection status="stock" label="정상입고" />
        <StateBadgeForInspection status="faulty" label="불량입고" />
      </Styled.problemStatus>
    );

  if (processStatus === "stock")
    return <StateBadgeForInspection status="stock" label="정상입고" />;

  if (processStatus === "faulty")
    return <StateBadgeForInspection status="faulty" label="불량입고" />;

  if (processStatus === "normal")
    return <StateBadgeForInspection status="unselect" label="미선택" />;

  return <></>;
};

const getActualQtySumAndQuantitySum = ({
  items,
  targetItem,
}: {
  items: UserReturningDetailItem[];
  targetItem: UserReturningDetailItem;
}) => {
  const filteredItemsBySkuId = items.filter(
    (item) => item.skuId === targetItem.skuId
  );

  const actualQtySum = filteredItemsBySkuId.reduce(
    (acc, cur) => acc + (cur.actualQty ?? 0),
    0
  );
  const quantitySum = filteredItemsBySkuId.reduce(
    (acc, cur) => acc + cur.quantity,
    0
  );

  return { actualQtySum, quantitySum };
};

const getIsDifferentQuantity = ({
  items,
  targetItem,
  isDecidedQuantityStatus,
}: {
  items: UserReturningDetailItem[];
  targetItem: UserReturningDetailItem;
  isDecidedQuantityStatus: boolean;
}) => {
  const { quantity, actualQty, placeQty } = targetItem;

  const usesManagementDate = checkUsesManagementDate(targetItem);

  if (isDecidedQuantityStatus) {
    if (usesManagementDate) {
      const { actualQtySum, quantitySum } = getActualQtySumAndQuantitySum({
        items,
        targetItem,
      });

      return (
        !!calculateDifference(quantitySum, actualQtySum) ||
        !!calculateDifference(actualQty, placeQty)
      );
    }

    return (
      !!calculateDifference(quantity, actualQty) ||
      !!calculateDifference(actualQty, placeQty)
    );
  }

  if (usesManagementDate) {
    const { actualQtySum, quantitySum } = getActualQtySumAndQuantitySum({
      items,
      targetItem,
    });

    return !!calculateDifference(quantitySum, actualQtySum);
  }

  return !!calculateDifference(quantity, actualQty);
};

export { getProblemStatus, getIsDifferentQuantity };

import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Pagination,
  Typography,
} from "@mui/material";

import { GET_PRODUCT_LABEL_PRINTING_DATA_REQ_LABEL_SIZE } from "api-interfaces/sku";
import SDSModal from "components/sds-v1/Modal";
import usePrintReceivingProductLabel from "hooks/usePrintReceivingProductLabel";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import Modal from "components/Modal";
import TextField from "components/TextField";

import Styled from "./index.styles";

interface DataForPrintingProductLabel {
  skuId: number;
  barCode?: string;
  companyName: string;
  teamName: string;
  itemName: string;
  quantity?: number;
}

interface PrintingData {
  companyName: string;
  teamName: string;
  itemName: string;

  skuId: number;
  barCode?: string;
  quantity: number | "";
  useProductMgmtCode: boolean;
  useOptionMgmtCode: boolean;
}

export default function usePrintProductLabelModal() {
  const [printingDataList, setPrintingDataList] = useState<PrintingData[]>([]);

  const [labelSize, setLabelSize] =
    useState<GET_PRODUCT_LABEL_PRINTING_DATA_REQ_LABEL_SIZE>("50x30");

  const [currentPage, setCurrentPage] = useState(0);

  const handleCurrentPageChange = useCallback(
    (e: ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page - 1);
    },
    []
  );

  const handleLabelSizeSelect = useCallback(
    (labelSize: GET_PRODUCT_LABEL_PRINTING_DATA_REQ_LABEL_SIZE) => () => {
      setLabelSize(labelSize);
    },
    []
  );

  const resetLocalState = useCallback(() => {
    setLabelSize("50x30");
    setPrintingDataList([]);
    setCurrentPage(0);
  }, []);

  const handleModalClose = useCallback(() => {
    resetLocalState();
  }, [resetLocalState]);

  const handlePrintQtyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const val = Number(e.target.value);

      if (Number.isNaN(val) || val < 0) {
        return;
      }

      const newPrintQty = !e.target.value ? "" : val;

      setPrintingDataList((prev) =>
        prev.map((v, i) =>
          i === currentPage ? { ...v, quantity: newPrintQty } : v
        )
      );
    },
    [currentPage]
  );

  const canPrint = useMemo(
    () => printingDataList.every((v) => !!v.quantity),
    [printingDataList]
  );

  const {
    printProductLabel,
    ResponseHandlerOfPrintProductLabel,
    resultOfPrintProductLabel,
    resetPrintProductLabel,
  } = usePrintReceivingProductLabel();

  const handlePrintClick = useCallback(() => {
    printingDataList.forEach((v) => {
      printProductLabel({
        labelSize,
        specifications: {
          skuId: v.skuId,
          quantity: Number(v.quantity),
          useProductMgmtCode: v.useProductMgmtCode,
          useOptionMgmtCode: v.useOptionMgmtCode,
        },
      });
    });
  }, [labelSize, printProductLabel, printingDataList]);

  const handlePrintBarcodeClick = useCallback(() => {
    printingDataList.forEach((v) => {
      printProductLabel({
        labelSize,
        useProductBarcodeAsCode128: true,
        specifications: {
          skuId: v.skuId,
          quantity: Number(v.quantity),
          useProductMgmtCode: v.useProductMgmtCode,
          useOptionMgmtCode: v.useOptionMgmtCode,
        },
      });
    });
  }, [labelSize, printProductLabel, printingDataList]);

  const ResultModalOfPrintProductLabel = useMemo(() => {
    if (!resultOfPrintProductLabel) return null;

    let title = "";
    let body = "";
    if (resultOfPrintProductLabel?.RESULT_CD === "1") {
      title = "상품 라벨 출력을 요청했습니다";
    } else {
      title = "상품 라벨 출력 요청에 실패했습니다";
      body = resultOfPrintProductLabel?.RESULT_MSG || "-";
    }

    return (
      <SDSModal
        uiType="content"
        title={title}
        body={body}
        active
        actionPositive={{
          label: "확인",
          handleClick: () => {
            resetPrintProductLabel();
            handleModalClose();
          },
        }}
      />
    );
  }, [handleModalClose, resetPrintProductLabel, resultOfPrintProductLabel]);

  const ModalOfPrintingProductLabel = useMemo(() => {
    if (!printingDataList.length) return null;

    return (
      <>
        <Modal
          isOpened
          handleClose={handleModalClose}
          modalBody={
            <div style={{ maxHeight: "80vh", overflow: "scroll" }}>
              {(() => {
                const infoList: {
                  label: string;
                  value: string | number;
                }[] = [
                  {
                    label: "회사명",
                    value: printingDataList[currentPage].companyName,
                  },
                  {
                    label: "팀명",
                    value: printingDataList[currentPage].teamName,
                  },
                  {
                    label: "상품명",
                    value: printingDataList[currentPage].itemName,
                  },
                  {
                    label: "SKU ID",
                    value: getFormattedSingleSkuId(
                      printingDataList[currentPage].skuId
                    ),
                  },
                  {
                    label: "상품 바코드",
                    value: printingDataList[currentPage].barCode || "-",
                  },
                ];

                return (
                  <Styled.modalBodyOfPrintingProductLabel>
                    <Typography variant="h6" component="div">
                      라벨 출력
                    </Typography>

                    <div className="info">
                      {infoList.map((v, i) => (
                        <div className="item" key={i}>
                          <div className="label">{v.label}</div>
                          <div className="value">{v.value}</div>
                        </div>
                      ))}
                    </div>

                    <div className="input-size">
                      <Button
                        variant={
                          labelSize === "50x30" ? "contained" : "outlined"
                        }
                        size="small"
                        onClick={handleLabelSizeSelect("50x30")}
                      >
                        50x30(mm)
                      </Button>

                      <Button
                        variant={
                          labelSize === "35x25" ? "contained" : "outlined"
                        }
                        size="small"
                        onClick={handleLabelSizeSelect("35x25")}
                      >
                        35x25(mm)
                      </Button>
                    </div>

                    <div className="input-qty">
                      <TextField
                        label="출력 매수"
                        size="small"
                        value={printingDataList[currentPage].quantity}
                        onChange={handlePrintQtyChange}
                      />
                    </div>

                    <div className="options">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() =>
                              setPrintingDataList((prev) =>
                                prev.map((v, i) =>
                                  i === currentPage
                                    ? {
                                        ...v,
                                        useProductMgmtCode:
                                          !v.useProductMgmtCode,
                                      }
                                    : v
                                )
                              )
                            }
                            checked={
                              printingDataList[currentPage].useProductMgmtCode
                            }
                          />
                        }
                        label="판매자상품코드"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() =>
                              setPrintingDataList((prev) =>
                                prev.map((v, i) =>
                                  i === currentPage
                                    ? {
                                        ...v,
                                        useOptionMgmtCode: !v.useOptionMgmtCode,
                                      }
                                    : v
                                )
                              )
                            }
                            checked={
                              printingDataList[currentPage].useOptionMgmtCode
                            }
                          />
                        }
                        label="옵션관리코드"
                      />
                    </div>

                    {printingDataList.length > 1 && (
                      <Pagination
                        sx={{ mt: 2 }}
                        count={printingDataList.length}
                        page={currentPage + 1}
                        onChange={handleCurrentPageChange}
                      />
                    )}

                    <div className="submit">
                      <Button
                        variant="contained"
                        size="large"
                        onClick={handlePrintClick}
                        disabled={!canPrint}
                      >
                        SKU바코드 출력
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={handlePrintBarcodeClick}
                        disabled={!canPrint}
                      >
                        상품바코드 출력
                      </Button>
                    </div>
                  </Styled.modalBodyOfPrintingProductLabel>
                );
              })()}
            </div>
          }
        />

        {ResultModalOfPrintProductLabel}
        {ResponseHandlerOfPrintProductLabel}
      </>
    );
  }, [
    handleModalClose,
    ResultModalOfPrintProductLabel,
    ResponseHandlerOfPrintProductLabel,
    currentPage,
    labelSize,
    handleLabelSizeSelect,
    printingDataList,
    handlePrintQtyChange,
    handleCurrentPageChange,
    handlePrintClick,
    handlePrintBarcodeClick,
    canPrint,
  ]);

  const openPrintingProductLabel = useCallback(
    (v: DataForPrintingProductLabel[]) => {
      setPrintingDataList(
        v.map((data) => ({
          companyName: data.companyName,
          teamName: data.teamName,
          itemName: data.itemName,

          skuId: data.skuId,
          barCode: data.barCode,
          quantity: data?.quantity ?? "",
          useProductMgmtCode: false,
          useOptionMgmtCode: false,
        }))
      );
    },
    []
  );

  return {
    openPrintingProductLabel,
    ModalOfPrintingProductLabel,
  };
}

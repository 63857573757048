import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Grid, TextField } from "@mui/material";

import {
  UPDATE_MATERIAL_DIMENSION_REQ,
  UPDATE_SKU_DIMENSION_REQ,
} from "api-interfaces/fulfillment";
import MATERIAL_QUERY from "queries/MATERIAL_QUERY";
import SKU_QUERY from "queries/SKU_QUERY";
import Modal from "components/Modal";

interface DimensionData {
  width: string | number | null;
  height: string | number | null;
  length: string | number | null;
  weight: string | number | null;
}

export default function useDimensionModal({
  isMaterialTable,
  refetchList,
}: {
  isMaterialTable?: boolean;
  refetchList: () => void;
}) {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<DimensionData>({
    mode: "onChange",
    defaultValues: {
      width: "",
      height: "",
      length: "",
      weight: "",
    },
  });

  const [opensDimensionModal, setOpensDimensionModal] = useState(false);

  const [materialId, setMaterialId] = useState(0);
  const [skuId, setSkuId] = useState(0);

  const {
    mutate: updateSkuDimension,
    ResponseHandler: ResponseHandlerOfUpdateSkuDimension,
  } = SKU_QUERY.useUpdateSkuDimension({
    skuId,
  });

  const {
    mutate: updateMaterialDimension,
    ResponseHandler: ResponseHandlerOfUpdateMaterialDimension,
  } = MATERIAL_QUERY.useUpdateMaterialDimension({
    materialId,
  });

  const handleDimensionUpdate = useCallback(
    (data: DimensionData) => {
      if (isMaterialTable) {
        updateMaterialDimension(data as UPDATE_MATERIAL_DIMENSION_REQ, {
          onSuccess: () => {
            refetchList();
            setOpensDimensionModal(false);
          },
        });
        return;
      }

      const numericValue = {
        // validate에서 required로 입력 검증 완료
        width: parseFloat(data.width as string),
        height: parseFloat(data.height as string),
        length: parseFloat(data.length as string),
        weight: parseFloat(data.weight as string),
      };

      updateSkuDimension(numericValue as UPDATE_SKU_DIMENSION_REQ, {
        onSuccess: () => {
          refetchList();
          setOpensDimensionModal(false);
        },
      });
      return;
    },
    [isMaterialTable, refetchList, updateMaterialDimension, updateSkuDimension]
  );

  const handleDimensionModalOpen = useCallback(
    ({
      width,
      height,
      length,
      weight,
      isSku,
      skuId,
      materialId,
    }: DimensionData & {
      isSku: boolean;
      skuId?: number;
      materialId?: number;
    }) => {
      return () => {
        if (isSku && skuId) {
          setSkuId(skuId);
        }

        if (!isSku && materialId) {
          setMaterialId(materialId);
        }
        reset({
          weight,
          width,
          height,
          length,
        });
        setOpensDimensionModal(true);
      };
    },
    [reset]
  );

  const getDimensionTextField = useCallback(
    (fieldName: keyof DimensionData, label: string) => {
      return (
        <Controller
          control={control}
          name={fieldName}
          rules={{ required: true, pattern: /^\d+(\.\d)?$/ }}
          render={({ field }) => (
            <TextField
              {...field}
              label={label}
              error={!!errors[fieldName]}
              helperText={
                errors[fieldName] && "숫자(소숫점 한자리까지)만 입력해주세요."
              }
            />
          )}
        />
      );
    },
    [control, errors]
  );

  const DimensionModal = useMemo(() => {
    return (
      <>
        <Modal
          isOpened={opensDimensionModal}
          handleClose={() => setOpensDimensionModal(false)}
          modalBody={
            <Grid
              container
              spacing={4}
              component="form"
              onSubmit={handleSubmit(handleDimensionUpdate)}
            >
              <Grid item xs={12}>
                측정값 입력
              </Grid>

              <Grid item container xs={12} spacing={2}>
                <Grid item>{getDimensionTextField("width", "가로(cm)")}</Grid>

                <Grid item>{getDimensionTextField("length", "세로(cm)")}</Grid>

                <Grid item>{getDimensionTextField("height", "높이(cm)")}</Grid>
              </Grid>

              <Grid item xs={12}>
                {getDimensionTextField("weight", "무게(kg)")}
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" disabled={!isDirty}>
                  수정
                </Button>
              </Grid>
            </Grid>
          }
        />

        {ResponseHandlerOfUpdateSkuDimension}

        {ResponseHandlerOfUpdateMaterialDimension}
      </>
    );
  }, [
    ResponseHandlerOfUpdateMaterialDimension,
    ResponseHandlerOfUpdateSkuDimension,
    getDimensionTextField,
    handleDimensionUpdate,
    handleSubmit,
    isDirty,
    opensDimensionModal,
  ]);

  return {
    DimensionModal,
    handleDimensionModalOpen,
  };
}

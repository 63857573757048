import React from "react";
import ReactDOM from "react-dom";

import { IS_ON_BROWSER } from "constants/common";

export default function Portal({
  children,
  selector,
}: {
  children: React.ReactNode;
  selector: string;
}) {
  const container = IS_ON_BROWSER && document.querySelector(selector);

  if (!children || !container) return null;

  return ReactDOM.createPortal(children, container);
}

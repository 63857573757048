import { Tooltip, Typography } from "@mui/material";

export default function TransportNumberWithTooltip({
  invoiceNoLabels,
  transportNumber,
}: {
  invoiceNoLabels: string[];
  transportNumber: string;
}) {
  return (
    <Tooltip
      placement="right"
      title={
        <ul>
          {invoiceNoLabels.map((v) => (
            <Typography variant="body2" component="li" key={v}>
              - {v}
            </Typography>
          ))}
        </ul>
      }
    >
      <Typography variant="body2" component="span">
        {transportNumber}
      </Typography>
    </Tooltip>
  );
}

import { useMemo } from "react";
import { Typography } from "@mui/material";

import { LinkedShoppingMall } from "api-interfaces/order";
import ORDER_QUERY from "queries/ORDER_QUERY";
import { toFormattedDate } from "utils/date";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

type LinkedShoppingMallCellId =
  | Extract<keyof LinkedShoppingMall, "id" | "mall" | "accountId" | "createdAt">
  | "status";

const linkedShoppingMallHeadCellList: TableHeadCell<LinkedShoppingMallCellId>[] =
  [
    {
      id: "id",
      disablePadding: false,
      label: "NO.",
      width: 40,
    },
    {
      id: "mall",
      disablePadding: false,
      label: "판매채널",
      width: 80,
    },
    {
      id: "accountId",
      disablePadding: false,
      label: "연동계정/API",
      width: 200,
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: "연동일시",
      width: 120,
    },
    {
      id: "status",
      disablePadding: false,
      label: "연동확인",
      width: 80,
    },
  ];

export default function ShoppingMall({
  teamId,
}: {
  teamId: number | undefined;
}) {
  const {
    data: linkedShoppingMallList,
    ResponseHandler: ResponseHandlerOfLinkedShoppingMallList,
  } = ORDER_QUERY.useGetLinkedShoppingMallList({
    teamId,
    enabled: !!teamId,
  });

  const linkedShoppingMallRowList =
    useMemo((): TableBodyRow<LinkedShoppingMallCellId>[] => {
      if (!linkedShoppingMallList) {
        return [];
      }

      return linkedShoppingMallList.map((mallItem) => {
        const isLinked = !!mallItem.createdAt && !mallItem.deletedAt;

        return {
          id: mallItem.id,
          mall: mallItem.mall,
          accountId: (
            <Typography
              variant="body2"
              component="span"
              sx={{
                textDecoration: mallItem.deletedAt ? "line-through" : "none",
              }}
            >
              {mallItem.accountId}
            </Typography>
          ),
          createdAt: (
            <Typography
              variant="body2"
              component="span"
              sx={{
                textDecoration: mallItem.deletedAt ? "line-through" : "none",
              }}
            >
              {toFormattedDate(mallItem.createdAt, `YYYY.MM.DD HH:mm`)}
            </Typography>
          ),
          status: isLinked ? "연동중" : "미연동",
        };
      });
    }, [linkedShoppingMallList]);

  return (
    <>
      <Table
        title="쇼핑몰 연동 현황"
        headCells={linkedShoppingMallHeadCellList}
        rows={linkedShoppingMallRowList}
      />

      {ResponseHandlerOfLinkedShoppingMallList}
    </>
  );
}

import { memo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { LocationFilterValue } from ".";

export default memo(function LocationFilter({
  locationFilterValue,
  handleLocationFilterChange,
}: {
  locationFilterValue: LocationFilterValue | undefined;
  handleLocationFilterChange: (e: SelectChangeEvent) => void;
}) {
  return (
    <FormControl sx={{ minWidth: 200, ml: 1 }}>
      <InputLabel id="location-filter-label">로케이션 보기 선택</InputLabel>
      <Select
        label="로케이션 보기 선택"
        labelId="location-filter-label"
        id="location-filter"
        value={locationFilterValue ?? ""}
        onChange={handleLocationFilterChange}
        autoWidth
      >
        <MenuItem value="all">전체 보기</MenuItem>
        <MenuItem value="empty">빈 로케이션만 보기 (활성화)</MenuItem>
        <MenuItem value="receiving">입고 로케이션만 보기</MenuItem>
        <MenuItem value="shipping">출고 로케이션만 보기</MenuItem>
        <MenuItem value="discard">폐기 로케이션만 보기</MenuItem>
        <MenuItem value="cancel">취소 로케이션만 보기</MenuItem>
        <MenuItem value="putAway">재고보관 로케이션만 보기</MenuItem>
        <MenuItem value="active">활성화된 로케이션만 보기</MenuItem>
        <MenuItem value="deActive">비활성화된 로케이션만 보기</MenuItem>
      </Select>
    </FormControl>
  );
});

import { useCallback, useState } from "react";

export default function useDialog(params?: {
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
    params?.onOpen?.();
  }, [params]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    params?.onClose?.();
  }, [params]);

  return [isOpen, handleOpen, handleClose] as const;
}

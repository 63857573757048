import { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { AdminMemo } from "types/common";
import { toFormattedDate } from "utils/date";
import TextField from "components/TextField";

const formatTextWithLineBreaks = (memo: string) =>
  memo.split("\n").map((text, index) => (
    <Fragment key={index}>
      {text.trim()}
      <br />
    </Fragment>
  ));

export default function AdminMemoModal({
  requestId,
  adminMemoList,
  memo,
  onMemoChange,
  onClose,
  onMemoAdd,
  ResponseHandlerOfAdminMemo,
}: {
  requestId: number;
  adminMemoList: AdminMemo[] | undefined;
  memo: string;
  onMemoChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClose: () => void;
  onMemoAdd: () => void;
  ResponseHandlerOfAdminMemo: React.ReactNode;
}) {
  return (
    <>
      <Dialog open={!!requestId} onClose={onClose} maxWidth={false}>
        <DialogTitle>메모</DialogTitle>

        <DialogContent>
          <Stack flexDirection="row" gap={2}>
            <TextField fullWidth value={memo} onChange={onMemoChange} />

            <Button variant="contained" disabled={!memo} onClick={onMemoAdd}>
              저장
            </Button>
          </Stack>

          {!!adminMemoList?.length && (
            <Paper variant="outlined" sx={{ minWidth: 1440, mt: 2 }}>
              <List>
                {[...(adminMemoList ?? [])]
                  .reverse()
                  .map((memo, index, arr) => (
                    <Fragment key={memo.createdAt}>
                      <ListItem>
                        <ListItemIcon>{memo.managerName}</ListItemIcon>

                        <ListItemText
                          primary={
                            <Typography component="p">
                              {formatTextWithLineBreaks(memo.memo)}
                            </Typography>
                          }
                          secondary={toFormattedDate(
                            memo.createdAt,
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        />
                      </ListItem>

                      {index !== arr.length - 1 && <Divider />}
                    </Fragment>
                  ))}
              </List>
            </Paper>
          )}
        </DialogContent>
      </Dialog>

      {ResponseHandlerOfAdminMemo}
    </>
  );
}

import { ExcelListHeaderProps } from "headlessComponents/excel/useExcelListHeader";
import { COLOR } from "styles/constants";
import ToggleFilter from "components/ToggleFilter";

import Styled from "./index.styles";

export default function ExcelListHeader<T>({
  selectedToggleFilter,
  setSelectedToggleFilter,
  countOfSuccessItems,
  countOfWarningItems,
  countOfFailureItems,
  isShipping = false,
}: ExcelListHeaderProps<T> &
  (
    | {
        warningExcelTableList: T[];
        isShipping?: true;
      }
    | {
        warningExcelTableList?: never;
        isShipping?: false;
      }
  )) {
  return (
    <>
      <Styled.filterList>
        <ToggleFilter
          label={`정상(${countOfSuccessItems})`}
          checked={selectedToggleFilter === "success"}
          iconColor={COLOR.pointSuccess}
          iconBgColor={COLOR.pointSuccess_40}
          handleClick={() => {
            setSelectedToggleFilter((prev) =>
              prev === "success" ? "none" : "success"
            );
          }}
        />

        {isShipping && (
          <ToggleFilter
            label={`경고(${countOfWarningItems ?? 0})`}
            checked={selectedToggleFilter === "warning"}
            iconColor={COLOR.amazoneYellow}
            iconBgColor={COLOR.amazoneYellow_60}
            handleClick={() => {
              setSelectedToggleFilter((prev) =>
                prev === "warning" ? "none" : "warning"
              );
            }}
          />
        )}

        <ToggleFilter
          label={`에러(${countOfFailureItems})`}
          checked={selectedToggleFilter === "failure"}
          iconColor={COLOR.pointWarning}
          iconBgColor={COLOR.pointWarning_60}
          handleClick={() => {
            setSelectedToggleFilter((prev) =>
              prev === "failure" ? "none" : "failure"
            );
          }}
        />
      </Styled.filterList>

      <Styled.excelUploadDesc>
        {/* ・한 번에 등록할 수 있는 최대 갯 수는 ***개 입니다. // 기획 미정
        <br />
        ・에러가 있을 경우(붉은색으로 표시) 에러를 원본 엑셀파일에서 수정한 후
        다시 업로드해 주세요. (에러가 발생하는 이유는 “?”를 통해 확인 할 수
        있습니다.)
        <br />
        ・에러가 없을 경우 등록 완료 버튼이 활성화 됩니다. */}
      </Styled.excelUploadDesc>
    </>
  );
}

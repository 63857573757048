import styled from "styled-components";

import { COLOR } from "styles/constants";

const columnBlock = styled.span<{ isValid: boolean; hasValue: boolean }>`
  color: ${({ isValid, hasValue }) =>
    !isValid
      ? hasValue
        ? COLOR.pointWarning
        : COLOR.grayScale_500
      : COLOR.grayScale_700};

  font-style: ${({ isValid, hasValue }) =>
    !isValid && !hasValue ? "italic" : "normal"};
`;

export default {
  columnBlock,
};

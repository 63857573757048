import { ChangeEvent, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";

import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";

export default function AddParcelDetailModal({
  shippingId,
  parcelCompany,
  invoiceNo,
  showsAddParcelDetailModal,
  onAddParcelDetailModalClose,
}: {
  shippingId: number | undefined;
  parcelCompany: string | undefined;
  invoiceNo: string | undefined;
  showsAddParcelDetailModal: boolean;
  onAddParcelDetailModalClose: () => void;
}) {
  const [parcelDetail, setParcelDetail] = useState({
    parcelCompany: parcelCompany ?? "",
    invoiceNo: invoiceNo ?? "",
  });

  const queryClient = useQueryClient();

  const {
    mutate: addParcelDetail,
    ResponseHandler: ResponseHandlerOfAddParcelDetail,
  } = SHIPPING_QUERY.useAddParcelDetail();

  const handleParcelCompanyChange = (e: SelectChangeEvent) => {
    setParcelDetail((prev) => ({
      ...prev,
      parcelCompany: e.target.value,
    }));
  };

  const handleInvoiceNoChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParcelDetail((prev) => ({ ...prev, invoiceNo: e.target.value }));
  };

  const handleParcelDetailAdd = () => {
    if (!shippingId) {
      return;
    }

    addParcelDetail(
      { pathParams: { shippingId }, ...parcelDetail },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping())
            .then(onAddParcelDetailModalClose);
        },
      }
    );
  };

  const isValid = !!parcelDetail.parcelCompany && !!parcelDetail.invoiceNo;

  return (
    <>
      <Dialog
        open={showsAddParcelDetailModal}
        onClose={onAddParcelDetailModalClose}
      >
        <DialogTitle>배송정보 수기 입력</DialogTitle>

        <DialogContent>
          <Stack direction="column" alignItems="center" gap={2} sx={{ mt: 1 }}>
            <FormControl required variant="outlined" fullWidth>
              <InputLabel id="parcel-company-select-label">택배사</InputLabel>
              <Select
                labelId="parcel-company-select-label"
                id="parcel-company-select"
                value={parcelDetail.parcelCompany}
                label="운송수단"
                onChange={handleParcelCompanyChange}
              >
                <MenuItem value="kunyoung">건영택배</MenuItem>
                <MenuItem value="kd">경동택배</MenuItem>
                <MenuItem value="daesin">대신택배</MenuItem>
              </Select>
            </FormControl>

            <TextField
              required
              label="송장번호"
              value={parcelDetail.invoiceNo}
              onChange={handleInvoiceNoChange}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={handleParcelDetailAdd}
            disabled={!isValid}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfAddParcelDetail}
    </>
  );
}

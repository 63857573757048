import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { ShippingDeliveryType } from "types/shipping";

const filterOptionList: TableHeadFilterOption<
  Extract<ShippingDeliveryType, "airExpress" | "oceanExpress">
>[] = [
  { label: "항공특송", value: "airExpress" },
  { label: "해상특송", value: "oceanExpress" },
];

export default function useOverseasExpressDeliveryTypeFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: OverseasExpressDeliveryTypeFilterPanel,
    filterList: overseasExpressDeliveryTypeFilterList,
    resetFilterState: resetOverseasExpressDeliveryTypeFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    OverseasExpressDeliveryTypeFilterPanel,
    overseasExpressDeliveryTypePayloadListByFilter:
      overseasExpressDeliveryTypeFilterList,
    resetOverseasExpressDeliveryTypeFilter,
  };
}

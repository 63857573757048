import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GET_ADMIN_LIST_REQ_SEARCH_KIND,
  GET_ADMIN_LIST_RES,
} from "api-interfaces/inventory";
import INVENTORY_QUERY from "queries/INVENTORY_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useGetCSVDownloadInfoFromQuery from "hooks/useGetCSVDownloadInfoFromQuery";
import useSearchWithTerm, { TermSearchType } from "hooks/useSearchWithTerm";
import { InventoryListItem } from "types/inventory";
import { toFormattedDate } from "utils/date";
import { getFormattedSingleSkuId, getPayloadByFilter } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import { removeSKUIdPrefix } from "utils/sku";

import Table, {
  TableBodyRow,
  TableExtraFooterDataItem,
  TableHeadCell,
} from "components/Table";

import useInventoryDetailModal from "./useInventoryDetailModal";
import useManagementDateFilter from "pages/product/list/useManagementDateFilter";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "companyName",
    },
    {
      label: "skuID",
      value: "skuId",
    },
    {
      label: "LOC ID",
      value: "locationName",
      placeHolder: "검색어 (부분검색 가능)",
    },
    {
      label: "상품명",
      value: "itemName",
    },
    {
      label: "상품바코드",
      value: "barCode",
    },
  ];

type CellId = keyof InventoryListItem;

function InventoryList() {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const resetCurrentPage = useCallback(() => setCurrentPage(0), []);

  const { ManagementDateFilterPanel, managementDatePayloadListByFilter } =
    useManagementDateFilter({ resetCurrentPage });

  // 검색어가 바뀌면 페이징 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm]);

  // 페이지당 데이터 수 변환시 현재 페이지 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [perPage]);

  const {
    ResponseHandler: ResponseHandlerOfInventoryList,
    data: inventoryList,
    refetch: refetchInventoryList,
  } = INVENTORY_QUERY.useGetSkuAdminList({
    page: currentPage,
    perPage,
    ...(termSearchType?.value && debouncedSearchTerm
      ? {
          searchKind: termSearchType?.value,
          searchTerm:
            termSearchType?.value === "skuId"
              ? removeSKUIdPrefix(debouncedSearchTerm)
              : debouncedSearchTerm,
        }
      : {}),

    ...getPayloadByFilter({
      key: "managementDateToggleFilter",
      payloadList: managementDatePayloadListByFilter,
    }),
  });

  // managementDatePayloadListByFilter가 변경될 때 API 호출
  useEffect(() => {
    refetchInventoryList();
  }, [managementDatePayloadListByFilter, refetchInventoryList]);

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: INVENTORY_QUERY.useGetAdminListForCSVDownload,
    queryArgs: [
      {
        ...(termSearchType?.value && debouncedSearchTerm
          ? {
              searchKind: termSearchType?.value,
              searchTerm:
                termSearchType?.value === "skuId"
                  ? removeSKUIdPrefix(debouncedSearchTerm)
                  : debouncedSearchTerm,
            }
          : {}),
      },
    ],
  });

  const { openInventoryDetail, InventoryDetailModal } = useInventoryDetailModal(
    { refetchInventoryList }
  );

  const headCells: TableHeadCell<CellId>[] = useMemo(
    () => [
      {
        id: "warehouseName",
        disablePadding: false,
        label: "창고",
        width: 110,
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "회사명",
        width: 140,
      },
      {
        id: "teamName",
        disablePadding: false,
        label: "팀명",
        width: 140,
      },
      {
        id: "skuId",
        disablePadding: false,
        label: "SKU ID",
        width: 110,
      },
      {
        id: "skuBarCode",
        disablePadding: false,
        label: "상품 바코드",
        width: 140,
      },
      {
        id: "managementKind",
        disablePadding: false,
        label: "관리기준",
        width: 140,
        filter: ManagementDateFilterPanel,
      },
      {
        id: "productName",
        disablePadding: false,
        label: "상품명",
        width: 200,
      },
      {
        id: "productCode",
        disablePadding: false,
        label: "상품관리 코드",
        width: 140,
      },
      {
        id: "managementCode",
        disablePadding: false,
        label: "옵션관리 코드",
        width: 140,
      },
      {
        id: "availableQty",
        disablePadding: false,
        label: "가용 수량",
        width: 120,
        numeric: true,
      },
      {
        id: "inWorkingQty",
        disablePadding: false,
        label: "작업중 수량",
        width: 120,
        numeric: true,
      },
      {
        id: "faultyQty",
        disablePadding: false,
        label: "불량",
        width: 100,
        numeric: true,
      },
      {
        id: "totalQty",
        disablePadding: false,
        label: "총 수량",
        width: 120,
        numeric: true,
      },
    ],
    [ManagementDateFilterPanel]
  );

  const mapDataForTable = useCallback(
    (data: GET_ADMIN_LIST_RES | undefined) => {
      if (!data?.list) return [];

      return data?.list.map((v) => {
        const row: TableBodyRow<CellId> = {
          warehouseName: v.warehouseName,
          companyName: v.companyName ?? "-",
          teamName: v.teamName ?? "-",
          skuId: getFormattedSingleSkuId(v.skuId),
          skuBarCode: v.skuBarCode ?? "-",
          managementKind: v.managementKind ? "Y" : "N",
          productName: v.productName,
          productCode: v.productCode ?? "-",
          managementCode: v.managementCode ?? "-",
          availableQty: toThousandUnitFormat(v.availableQty),
          inWorkingQty: toThousandUnitFormat(v.inWorkingQty),
          faultyQty: toThousandUnitFormat(v.faultyQty),
          totalQty: toThousandUnitFormat(v.totalQty),
          handleRowClick: () => openInventoryDetail(v.skuId),
        };

        return row;
      });
    },
    [openInventoryDetail]
  );

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload);
  }, [dataForCSVDownload, mapDataForTable]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(inventoryList);
  }, [inventoryList, mapDataForTable]);

  const extraFooterData: TableExtraFooterDataItem[] = useMemo(() => {
    if (inventoryList?.totalInventories) {
      // TODO:
    }

    return [
      {
        label: "총 수량(계)",
        value: inventoryList?.totalInventories?.totalQty
          ? toThousandUnitFormat(inventoryList?.totalInventories?.totalQty)
          : "-",
      },
      {
        label: "가용 수량(계)",
        value: inventoryList?.totalInventories?.availableQty
          ? toThousandUnitFormat(inventoryList?.totalInventories?.availableQty)
          : "-",
      },
      {
        label: "작업중 수량(계)",
        value: inventoryList?.totalInventories?.inWorkingQty
          ? toThousandUnitFormat(inventoryList?.totalInventories?.inWorkingQty)
          : "-",
      },
      {
        label: "불량(계)",
        value: inventoryList?.totalInventories?.faultyQty
          ? toThousandUnitFormat(inventoryList?.totalInventories?.faultyQty)
          : "-",
      },
    ];
  }, [inventoryList?.totalInventories]);

  return (
    <Layout>
      <Table
        title="재고 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: inventoryList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
        toolbarItems={{ left: [TermSearchPanel] }}
        csvDownloadInfo={{
          filename: `재고 리스트(${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          scope: "all",
          isCSVDownloadRequested,
          setIsCSVDownloadRequested,
          rowsForCSVDownload,
          ResponseHandlerOfFetchDataForCSVDownload,
          statusOfQueryOfFetchDataForCSVDownload,
          removeQueryOfFetchDataForCSVDownload,
        }}
        extraFooterData={extraFooterData}
      />

      {ResponseHandlerOfInventoryList}

      {InventoryDetailModal}
    </Layout>
  );
}

export default withRequireAuth(InventoryList);

import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import Button from "components/sds-v1/button/Button";
import Icon from "components/sds-v1/Icon";
import { PARCEL_COMPANY_OPTION_LIST } from "constants/receiving";
import { InputSelectOption } from "headlessComponents/input/useInputSelect";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { COLOR } from "styles/constants";
import { FulfillmentParcelCompany } from "types/fulfillment";
import { ShippingItemDetail } from "types/shipping";
import {
  getValueAsNumberOnly,
  getValueAsNumberOrLetter,
} from "utils/validation";
import InputSelect from "components/sds-v1/input/InputSelect";
import InputText from "components/sds-v1/input/InputText";

import Styled from "./index.styles";

interface ParcelDeliveryDetail {
  parcelCompany: FulfillmentParcelCompany | undefined;
  packings: {
    id: number | null;
    invoiceNo: string;
    canDelete: boolean;
  }[];
}

export default function InputParcelModal({
  shippingId,
  shippingDetailData,
  onModalClose,
}: {
  shippingId: number;
  shippingDetailData: ShippingItemDetail;
  onModalClose: () => void;
}) {
  const [parcelDeliveryDetail, setParcelDeliveryDetail] =
    useState<ParcelDeliveryDetail>(() => ({
      parcelCompany: shippingDetailData.parcelCompany,
      packings: (shippingDetailData.packings ?? []).map(
        ({ id, invoiceNo }) => ({
          id,
          invoiceNo,
          canDelete: false,
        })
      ),
    }));

  const queryClient = useQueryClient();

  const {
    mutate: updateParcelDeliveryDetail,
    ResponseHandler: ResponseHandlerOfUpdatingParcelDeliveryDetail,
  } = SHIPPING_QUERY.useUpdateParcelDeliveryDetail({ shippingId });

  const handleParcelCompanySelect = useCallback(
    (selectedParcelCompany: InputSelectOption<FulfillmentParcelCompany>) => {
      setParcelDeliveryDetail((prev) => ({
        ...prev,
        parcelCompany: selectedParcelCompany.value,
      }));
    },
    []
  );

  const handleInvoiceChange = useCallback(
    ({
        selectedIndex,
        selectedParcelCompany,
      }: {
        selectedIndex: number;
        selectedParcelCompany: FulfillmentParcelCompany | undefined;
      }) =>
      (invoiceNo: string) => {
        setParcelDeliveryDetail((prev) => ({
          ...prev,
          packings: prev.packings.map((packing, index) =>
            index === selectedIndex
              ? {
                  ...packing,
                  invoiceNo:
                    selectedParcelCompany === "ups" ||
                    selectedParcelCompany === "fedex"
                      ? getValueAsNumberOrLetter(invoiceNo)
                      : getValueAsNumberOnly(invoiceNo),
                }
              : packing
          ),
        }));
      },
    []
  );

  const handleInvoiceItemAdd = useCallback(() => {
    setParcelDeliveryDetail((prev) => ({
      ...prev,
      packings: [
        ...prev.packings,
        { id: null, invoiceNo: "", canDelete: true },
      ],
    }));
  }, []);

  const handleInvoiceItemDelete = useCallback(
    (selectedIndex: number) => () => {
      setParcelDeliveryDetail((prev) => ({
        ...prev,
        packings: prev.packings.filter(
          (packing, index) => index !== selectedIndex
        ),
      }));
    },
    []
  );

  const isValidate = useMemo(() => {
    const isFilledParcelCompany = !!parcelDeliveryDetail.parcelCompany;
    const isFilledInvoiceList = parcelDeliveryDetail.packings.every(
      (packing) => !!packing.invoiceNo
    );
    const hasNoDuplicateInvoiceList =
      new Set(parcelDeliveryDetail.packings.map(({ invoiceNo }) => invoiceNo))
        .size === parcelDeliveryDetail.packings.length;

    return (
      isFilledParcelCompany && isFilledInvoiceList && hasNoDuplicateInvoiceList
    );
  }, [parcelDeliveryDetail.packings, parcelDeliveryDetail.parcelCompany]);

  const handleParcelDeliveryDetailSave = useCallback(() => {
    updateParcelDeliveryDetail(
      {
        // validation 검사를 하기 때문에 값이 존재
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parcelCompany: parcelDeliveryDetail.parcelCompany!,
        packings: parcelDeliveryDetail.packings.map(({ id, invoiceNo }) => ({
          id,
          invoiceNo: String(invoiceNo),
        })),
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping())
            .then(onModalClose);
        },
      }
    );
  }, [
    onModalClose,
    parcelDeliveryDetail.packings,
    parcelDeliveryDetail.parcelCompany,
    queryClient,
    updateParcelDeliveryDetail,
  ]);

  const canDeleteLastIndex = (shippingDetailData.packings ?? []).length - 1;

  return (
    <>
      <Dialog open onClose={onModalClose}>
        <DialogTitle>배송정보 수기 입력</DialogTitle>

        <DialogContent dividers>
          <Styled.inputParcelModalBody>
            <InputSelect
              isRequired
              label="택배사"
              uiType="outline"
              optionList={PARCEL_COMPANY_OPTION_LIST}
              selectedOption={PARCEL_COMPANY_OPTION_LIST.find(
                ({ value }) => value === parcelDeliveryDetail.parcelCompany
              )}
              handleSelect={handleParcelCompanySelect}
            />

            <Styled.invoiceList>
              {parcelDeliveryDetail.packings.map(
                ({ id, invoiceNo, canDelete }, index) => (
                  <Styled.invoiceItem key={Number(id) + index}>
                    <InputText
                      isRequired
                      width={22.75}
                      label="송장번호"
                      borderType="outline"
                      valueType="string"
                      value={invoiceNo}
                      setValue={handleInvoiceChange({
                        selectedIndex: index,
                        selectedParcelCompany:
                          parcelDeliveryDetail.parcelCompany,
                      })}
                    />
                    {canDeleteLastIndex === index ? (
                      <Icon
                        type="circleFilledAdd"
                        color={COLOR.grayScale_600}
                        size={1.5}
                        onClick={handleInvoiceItemAdd}
                      />
                    ) : canDelete ? (
                      <Icon
                        type="circleFilledRemove"
                        color={COLOR.pointWarning_60}
                        size={1.5}
                        onClick={handleInvoiceItemDelete(index)}
                      />
                    ) : null}
                  </Styled.invoiceItem>
                )
              )}
            </Styled.invoiceList>
          </Styled.inputParcelModalBody>
        </DialogContent>

        <DialogActions>
          <Button
            label="저장"
            size="block"
            theme="primary"
            handleClick={handleParcelDeliveryDetailSave}
            disabled={!isValidate}
          />
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfUpdatingParcelDeliveryDetail}
    </>
  );
}

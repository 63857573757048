import { memo } from "react";
import { Print } from "@mui/icons-material";
import { Button } from "@mui/material";

import { toThousandUnitFormat } from "utils/number";

import usePrintLocationLabelWithDialog from "../usePrintLocationLabelWithDialog";

export default memo(function PrintLocationLabel({
  checkedIds,
}: {
  checkedIds: number[];
}) {
  const { handlePrintLocationLabelDialogOpen, PrintLocationLabelDialog } =
    usePrintLocationLabelWithDialog({ locationIds: checkedIds });

  return (
    <>
      <Button
        color="success"
        variant="outlined"
        startIcon={<Print />}
        onClick={handlePrintLocationLabelDialogOpen}
      >
        라벨 출력 ({toThousandUnitFormat(checkedIds.length)})
      </Button>

      {PrintLocationLabelDialog}
    </>
  );
});

import { ChangeEvent, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Alert,
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import { EDIT_CUSTOMER_DETAIL_REQ } from "api-interfaces/shipping";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { ShippingItemDetail } from "types/shipping";

export default function EditCustomerDetailModal({
  shippingDetail,
  showsEditCustomerDetailModal,
  onEditCustomerDetailModalClose,
}: {
  shippingDetail: ShippingItemDetail;
  showsEditCustomerDetailModal: boolean;
  onEditCustomerDetailModalClose: () => void;
}) {
  const [
    { customerPostalCode, customerAddress, customerDetailAddress },
    setCustomerDetail,
  ] = useState<EDIT_CUSTOMER_DETAIL_REQ>({
    customerPostalCode: shippingDetail.customerPostalCode ?? "",
    customerAddress: shippingDetail.customerAddress ?? "",
    customerDetailAddress: shippingDetail.customerDetailAddress ?? "",
  });
  const [refineAddressAlert, setRefineAddressAlert] = useState<AlertColor>();

  const queryClient = useQueryClient();

  const {
    data: refineAddressData,
    refetch: refineAddress,
    reset: resetRefineAddress,
    ResponseHandler: ResponseHandlerOfRefineAddress,
  } = SHIPPING_QUERY.useRefineAddress({
    address: customerAddress ?? "",
    enabled: false,
  });

  const {
    mutate: editCustomerDetail,
    ResponseHandler: ResponseHandlerOfEditCustomerDetail,
  } = SHIPPING_QUERY.useEditCustomerDetail({
    shippingId: shippingDetail?.id,
  });

  const handleTextFiledChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.name === "customerAddress") {
      resetRefineAddress();
    }

    setCustomerDetail((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddressRefine = () => {
    refineAddress().then(({ data }) => {
      data?.data.code === "S"
        ? setRefineAddressAlert("success")
        : setRefineAddressAlert("error");
    });
  };

  const handleRefineAddressAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setRefineAddressAlert(undefined);
  };

  const handleEditCustomerDetailModalClose = () => {
    onEditCustomerDetailModalClose();
    resetRefineAddress();
  };

  const handleCustomerDetailEdit = () => {
    editCustomerDetail(
      {
        customerPostalCode,
        customerAddress,
        customerDetailAddress,
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping())
            .then(handleEditCustomerDetailModalClose);
        },
      }
    );
  };

  const isRefineAddressSuccess = refineAddressData?.code === "S";

  const isValid =
    isRefineAddressSuccess &&
    !!customerPostalCode &&
    !!customerAddress &&
    !!customerDetailAddress;

  return (
    <>
      <Dialog
        open={showsEditCustomerDetailModal}
        onClose={handleEditCustomerDetailModalClose}
      >
        <DialogTitle>배송 정보 수정</DialogTitle>

        <DialogContent dividers>
          <Stack direction="column" spacing={2} mt={1} sx={{ minWidth: 500 }}>
            <TextField
              required
              fullWidth
              label="우편번호"
              name="customerPostalCode"
              value={customerPostalCode}
              onChange={handleTextFiledChange}
            />

            <Stack direction="row" spacing={1}>
              <TextField
                required
                fullWidth
                label="받는 분 주소"
                name="customerAddress"
                value={customerAddress}
                onChange={handleTextFiledChange}
              />
              <Button
                sx={{ width: 80 }}
                size="small"
                variant="contained"
                onClick={handleAddressRefine}
                disabled={isRefineAddressSuccess}
              >
                주소 분석
              </Button>
            </Stack>

            <TextField
              required
              fullWidth
              label="받는 분 상세 주소"
              name="customerDetailAddress"
              value={customerDetailAddress}
              onChange={handleTextFiledChange}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button
            variant="outlined"
            onClick={handleEditCustomerDetailModalClose}
          >
            취소
          </Button>

          <Button
            variant="contained"
            onClick={handleCustomerDetailEdit}
            disabled={!isValid}
          >
            수정
          </Button>
        </DialogActions>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={!!refineAddressAlert && !!refineAddressData}
          autoHideDuration={6000}
          onClose={handleRefineAddressAlertClose}
        >
          <Alert
            onClose={handleRefineAddressAlertClose}
            severity={refineAddressAlert}
          >
            {isRefineAddressSuccess
              ? "주소 분석에 성공하였습니다."
              : refineAddressData?.message}
          </Alert>
        </Snackbar>
      </Dialog>

      {ResponseHandlerOfRefineAddress}
      {ResponseHandlerOfEditCustomerDetail}
    </>
  );
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import RECEIVING_QUERY from "queries/RECEIVING_QUERY";
import { useCallback, useMemo, useState } from "react";

import DetailInfo from "./DetailInfo";
import InspectionInfo from "./InspectionInfo";

type CellId =
  | "checkbox"
  | "order"
  | "itemName"
  | "skuId"
  | "barCode"
  | "productCode"
  | "managementCode"
  | "quantity"
  | "actualQty"
  | "placeQty";

type ItemInfoType = "requests" | "tasks" | "completed" | "canceled";
type ReceivingDetailModalTabsType = "detailInfo" | "inspectionInfo";

export default function useReceivingDetailModal(type: ItemInfoType) {
  const [receivingId, setReceivingId] = useState(0);
  const [currentTab, setCurrentTab] =
    useState<ReceivingDetailModalTabsType>("detailInfo");

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: ReceivingDetailModalTabsType
  ) => {
    setCurrentTab(newValue);
  };

  const {
    data: receivingData,
    ResponseHandler: ResponseHandlerOfGetReceivingData,
  } = RECEIVING_QUERY.useGetManagerReceivingDetail({
    enabled: !!receivingId,
    receivingId,
    onError: () => {
      setReceivingId(0);
    },
  });

  const ReceivingDetailModal = useMemo(
    () => (
      <>
        <Dialog
          open={!!receivingData}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={() => setReceivingId(0)}
        >
          <DialogTitle>
            상세 보기
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            ></Stack>
          </DialogTitle>

          <DialogContent dividers>
            {/* <UpperSummary returningDetail={returningDetailData} /> */}

            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="returning-detail-tabs"
            >
              <Tab label="입고정보" value="detailInfo"></Tab>
              <Tab label="검수정보" value="inspectionInfo"></Tab>
            </Tabs>

            {currentTab === "detailInfo" && receivingData && (
              <DetailInfo receivingData={receivingData} type={type} />
            )}
            {currentTab === "inspectionInfo" && receivingData && (
              <InspectionInfo id={receivingData.receiving.id} />
            )}
          </DialogContent>
        </Dialog>

        {ResponseHandlerOfGetReceivingData}
      </>
    ),
    [ResponseHandlerOfGetReceivingData, receivingData, type]
  );

  const showReceivingDetailModal = useCallback((receivingId: number) => {
    setReceivingId(receivingId);
  }, []);

  return {
    showReceivingDetailModal,
    ReceivingDetailModal,
  };
}

import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import INVENTORY_QUERY from "queries/INVENTORY_QUERY";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

interface ModifyFaultyInventoryForm {
  skuId: string;
  barCode: string;
  qty: string;
  isIncreasing: "" | "faultyQty" | "availableQty";
}

function InventoryFaultyInventoryModify() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ModifyFaultyInventoryForm>({
    mode: "onChange",
    defaultValues: {
      skuId: "",
      barCode: "",
      qty: "",
      isIncreasing: "",
    },
  });

  const {
    mutate: modifyFaultyInventory,
    ResponseHandler: ResponseHandlerOfModifyFaultyInventory,
  } = INVENTORY_QUERY.useModifyFaultyInventory();

  const handleFaultyInventoryModify = ({
    skuId,
    barCode,
    qty,
    isIncreasing,
  }: ModifyFaultyInventoryForm) => {
    modifyFaultyInventory(
      {
        skuId: Number(skuId),
        barCode,
        qty: Number(qty),
        isIncreasing: isIncreasing === "faultyQty",
      },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          불량 재고 수정
        </Typography>

        <form onSubmit={handleSubmit(handleFaultyInventoryModify)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="skuId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="요청 SKU ID"
                  error={!!errors.skuId}
                  helperText={errors.skuId && "숫자만 입력해주세요."}
                />
              )}
            />

            <Controller
              control={control}
              name="barCode"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField {...field} label="로케이션 바코드" />
              )}
            />

            <Controller
              control={control}
              name="qty"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="변경할 수량"
                  error={!!errors.qty}
                  helperText={errors.qty && "숫자만 입력해주세요."}
                />
              )}
            />

            <Controller
              control={control}
              name="isIncreasing"
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="is-increasing">재고 타입</InputLabel>
                  <Select {...field} labelId="is-increasing" label="재고 타입">
                    <MenuItem value="faultyQty">불량재고로 수정</MenuItem>
                    <MenuItem value="availableQty">가용재고로 수정</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandlerOfModifyFaultyInventory}
    </Layout>
  );
}

export default withRequireAuth(InventoryFaultyInventoryModify);

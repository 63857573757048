import { useCallback, useMemo, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import { GET_LOCATION_LABEL_PRINTING_DATA_REQ } from "api-interfaces/location";
import LOCATION_QUERY from "queries/LOCATION_QUERY";
import PRINT_QUERY from "queries/PRINT_QUERY";

export default function usePrintLocationLabel({
  onSettled,
  ...params
}: GET_LOCATION_LABEL_PRINTING_DATA_REQ & {
  onSettled?: () => void;
}) {
  const [showsResultSnackbar, setShowsResultSnackbar] = useState(false);

  const {
    refetch: getLocationLabelPrintingData,
    ResponseHandler: ResponseHandlerOfGettingLocationLabelPrintingData,
  } = LOCATION_QUERY.useGetLocationLabelPrintingData({
    enabled: false,
    onSuccess: (res) => print(res),
    ...params,
  });

  const {
    mutate: print,
    ResponseHandler: ResponseHandlerOfPrinting,
    data: resultOfPrinting,
  } = PRINT_QUERY.usePrint({
    onSuccess: () => {
      setShowsResultSnackbar(true);

      if (onSettled) {
        onSettled();
      }
    },
  });

  const printLocationLabel = () => {
    getLocationLabelPrintingData();
  };

  const handleResultClose = useCallback(() => {
    setShowsResultSnackbar(false);
  }, []);

  const ResultSnackbarOfPrintingLocationLabel = useMemo(() => {
    const isSuccess = resultOfPrinting?.RESULT_CD === "1";

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={6000}
        open={showsResultSnackbar}
        onClose={handleResultClose}
      >
        <Alert
          severity={isSuccess ? "info" : "error"}
          onClose={handleResultClose}
        >
          {isSuccess ? (
            "로케이션 라벨 출력을 요청했습니다."
          ) : (
            <>
              로케이션 라벨 출력 요청에 실패했습니다. <br />
              {resultOfPrinting?.RESULT_MSG ?? "-"}
            </>
          )}
        </Alert>
      </Snackbar>
    );
  }, [
    handleResultClose,
    resultOfPrinting?.RESULT_CD,
    resultOfPrinting?.RESULT_MSG,
    showsResultSnackbar,
  ]);

  return {
    printLocationLabel,
    ResultHandlerOfPrintLocationLabel: (
      <>
        {ResultSnackbarOfPrintingLocationLabel}

        {ResponseHandlerOfGettingLocationLabelPrintingData}
        {ResponseHandlerOfPrinting}
      </>
    ),
  };
}

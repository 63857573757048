/**
 * 숫자 천단위마다 ','로 구분된 문자열을 반환
 * 소수점은 처리 안 되는 것에 유의할 것
 */
function toThousandUnitFormat(val?: number | string | null) {
  if (!val) {
    return "0";
  }

  if (typeof val === "number") {
    val = removeUnnecessaryDecimalPlaces(val);
  }

  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * (주의: 결과시 불필요한 소수점을 삭제하는데만 사용할 것. 계산 자체의 정밀도를 위해서는 bignumber등의 계산전용 라이브러리 사용을 고려해야함)
 *
 * 2진 산술연산의 한계로 발생하는 불필요한 소수점을 버림
 * - 특정 10진수들은(ex. 0.1) 2진수로는 무한으로밖에 표현되지 않는데, 프로그래밍 언어의 최대 표현숫자에 한계가 있으므로 긴 소수점으로 표시됨 (ex. 0.1 + 0.2의 결과가 0.3이아닌 0.30000000000000004으로 나옴)
 */
function removeUnnecessaryDecimalPlaces(val: number) {
  return Number(val.toFixed(12));
}

/** 값의 차이를 계산 */
const calculateDifference = (a?: number | null, b?: number | null) => {
  return Math.abs((a ?? 0) - (b ?? 0));
};

/** 값의 차이에 의한 미만/초과 문자열을 반환 */
const getStringForValueRange = (a?: number | null, b?: number | null) => {
  const resultValue = (a ?? 0) - (b ?? 0);

  return resultValue > 0 ? "부족" : "초과";
};

export {
  toThousandUnitFormat,
  removeUnnecessaryDecimalPlaces,
  calculateDifference,
  getStringForValueRange,
};

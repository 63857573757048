import styled from "styled-components";

const modalBodyOfPrintingProductLabel = styled.div`
  min-width: 300px;

  > .info {
    margin-top: 20px;

    > .item {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 4px 0;

      > .label {
        min-width: 100px;
        font-weight: bold;
      }
    }
  }

  > .input-size {
    margin-top: 14px;

    > * {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  > .input-qty {
    margin-top: 20px;
  }

  > .options {
    margin-top: 14px;

    > label {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  > .submit {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
`;

export default {
  modalBodyOfPrintingProductLabel,
};

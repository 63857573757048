import useToolTip, { ToolTipPropsV2 } from "headlessComponents/useToolTip";

import useCheckIsMobile from "hooks/useCheckIsMobile";

import ToolTipBody from "./ToolTipBody";
import Styled from "./index.styles";

export default function Tooltip({
  title,
  desc,
  width,
  disabled,
  position,
  children,
  className,
  tooltipBodyStyle,
}: ToolTipPropsV2) {
  const {
    isVisibleToolTipBody,
    setIsVisibleToolTipBody,
    triggerRef,
    triggerTargetInfo,
    checkTriggerTarget,
    handleMouseEnter,
    handleMouseLeave,
    handleToolTipClick,
  } = useToolTip(disabled);

  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.container
      className={`${className ? className : ""} tooltip`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        if (isMobile) {
          // 모바일의 경우는 close버튼으로만 닫히게 함
          return;
        }
        handleMouseLeave();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleToolTipClick();
      }}
      disabled={disabled}
    >
      <div
        ref={triggerRef}
        className="trigger"
        onMouseEnter={checkTriggerTarget}
        onClick={(e) => {
          e.preventDefault();
          checkTriggerTarget();
        }}
      >
        {children}
      </div>

      {isVisibleToolTipBody && (
        <ToolTipBody
          isVisibleToolTipBody={isVisibleToolTipBody}
          setIsVisibleToolTipBody={setIsVisibleToolTipBody}
          triggerTargetInfo={triggerTargetInfo}
          checkTriggerTarget={checkTriggerTarget}
          title={title}
          desc={desc}
          width={width}
          position={position}
          tooltipBodyStyle={tooltipBodyStyle}
        />
      )}
    </Styled.container>
  );
}

import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";
import { globalReset } from "styles/global";

const container = styled.section`
  /* TODO: shipda-admin에서 side-effect때문에 global로 reset할 수 없는 이슈가 있어 직접 추가 */
  ${globalReset}

  background-color: ${COLOR.bgColor_1};
  border-radius: 16px;
  box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.12);
  display: flex;
  position: relative;
  max-width: 800px;
  min-height: 420px;

  .left-side {
    /* width: 400px; */
    width: 464px;
    padding: 40px 24px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      ${setFontStyle("Head4", "Bold")};
      color: ${COLOR.primaryBlue};
      margin-bottom: 40px;
    }

    > form {
      width: 100%;

      .input-text {
        margin-bottom: 40px;
      }
    }
  }

  .right-side {
  }
`;

const background = styled.div`
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLOR.bk_60};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {
  container,
  background,
};

import { useMemo } from "react";

import { ReceivingItem } from "types/receiving";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import { replaceEmptyToDash } from "utils/string";

import Table from "components/sds-v1/table/Table";

import Styled from "./index.styles";

export default function NoProblemList({
  list,
  isDecidedQuantityStatus,
}: {
  list: ReceivingItem[];
  isDecidedQuantityStatus: boolean;
}) {
  const noProblemTableList = useMemo(() => {
    /**
     * 관리일자를 기준으로 나뉘어진 items를 SKU ID 기준으로 묶어서 하나의 행으로 만듦
     * id는 첫 번째 상품의 id로 지정 (rowKey로 사용되기 때문에 중복되지만 않으면 됨)
     */
    const groupedListBySkuId = list.reduce<ReceivingItem[]>((acc, cur) => {
      const existingItemIndex = acc.findIndex(
        (item) => item.skuId === cur.skuId
      );
      if (existingItemIndex !== -1) {
        const existingItem = acc[existingItemIndex];

        const updatedItem = {
          ...existingItem,
          quantity: (existingItem.quantity ?? 0) + (cur.quantity ?? 0),
          actualQty: (existingItem.actualQty ?? 0) + (cur.actualQty ?? 0),
          placeQty: (existingItem.placeQty ?? 0) + (cur.placeQty ?? 0),
        };

        return [
          ...acc.slice(0, existingItemIndex),
          updatedItem,
          ...acc.slice(existingItemIndex + 1),
        ];
      }

      return [...acc, cur];
    }, []);

    return groupedListBySkuId.map((item) => {
      return {
        rowKey: item.id,

        skuId: getFormattedSingleSkuId(item.skuId),
        itemName: (
          <Styled.limitedWidthEllipsisText maxWidth="450px">
            {item.sku.itemName}
          </Styled.limitedWidthEllipsisText>
        ),
        barCode: (
          <Styled.limitedWidthEllipsisText maxWidth="80px">
            {replaceEmptyToDash(item.sku.barCode)}
          </Styled.limitedWidthEllipsisText>
        ),
        receivingQuantity: replaceEmptyToDash(
          toThousandUnitFormat(item.quantity),
          true
        ),
        inspectionQuantity: replaceEmptyToDash(
          toThousandUnitFormat(item.actualQty),
          true
        ),
        totalReceivingQuantity: isDecidedQuantityStatus
          ? replaceEmptyToDash(toThousandUnitFormat(item.placeQty), true)
          : "-",
      };
    });
  }, [isDecidedQuantityStatus, list]);

  return (
    <>
      <Table
        rowMinHeight="54px"
        columnInfo={{
          skuId: {
            label: "SKU ID",
            portion: 1,
          },
          itemName: {
            label: "상품명",
            portion: 5,
          },
          barCode: {
            label: "상품 바코드",
            portion: 1,
          },
          receivingQuantity: {
            label: "입고요청수량(PCS)",
            portion: 1.5,
          },
          inspectionQuantity: {
            label: "검수수량(PCS)",
            portion: 1.2,
          },
          totalReceivingQuantity: {
            label: "입고수량(PCS)",
            portion: 1.5,
          },
        }}
        dataList={noProblemTableList}
      />
    </>
  );
}

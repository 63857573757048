import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { BooleanString } from "types/common";

const filterOptionList: TableHeadFilterOption<BooleanString>[] = [
  { label: "Y", value: "true" },
  { label: "N", value: "false" },
];

export default function useIsShipmentFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: IsShipmentFilterPanel,
    filterList: isShipmentFilterList,
    resetFilterState: resetIsShipmentFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    IsShipmentFilterPanel,
    isShipmentPayloadListByFilter: isShipmentFilterList,
    resetIsShipmentFilter,
  };
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { DomesticShippingDeliveryType } from "types/shipping";

export default function DeliveryTypeModal({
  deliveryType,
  onDeliveryTypeChange,
  isDeliveryTypeModalOpen,
  onDeliveryTypeModalClose,
  onDeliveryTypeConfirm,
}: {
  deliveryType: DomesticShippingDeliveryType | "";
  onDeliveryTypeChange: (e: SelectChangeEvent) => void;
  isDeliveryTypeModalOpen: boolean;
  onDeliveryTypeModalClose: () => void;
  onDeliveryTypeConfirm: () => void;
}) {
  return (
    <Dialog open={isDeliveryTypeModalOpen} onClose={onDeliveryTypeModalClose}>
      <DialogTitle>변경할 운송수단 유형을 선택하세요.</DialogTitle>

      <DialogContent>
        <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
          <InputLabel id="delivery-type-select-label">운송수단</InputLabel>
          <Select
            labelId="delivery-type-select-label"
            id="delivery-type-select"
            value={deliveryType}
            label="운송수단"
            onChange={onDeliveryTypeChange}
          >
            <MenuItem value="parcel">일반택배</MenuItem>
            <MenuItem value="freight">화물택배</MenuItem>
            <MenuItem value="truck">화물차량</MenuItem>
            <MenuItem value="selfCollect">직접수령</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={onDeliveryTypeConfirm}
          disabled={!deliveryType}
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

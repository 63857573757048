import { useEffect, useMemo, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";

import AUTH_QUERY from "queries/AUTH_QUERY";
import Modal from "components/Modal";

const initialPasswordInput = {
  password: "",
  newPassword: "",
};

export default function useChangePassword() {
  const [active, setActive] = useState(false);

  const [passwordInput, setPasswordInput] = useState<{
    password?: string;
    newPassword?: string;
  }>(initialPasswordInput);

  const {
    mutate: changePassword,
    ResponseHandler: ResponseHandlerOfChangePassword,
  } = AUTH_QUERY.useChangePassword({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setActive(false);
      },
      customizeMessage: () => ({
        title: "비밀변호가 변경되었습니다",
      }),
    },
  });

  useEffect(() => {
    if (!active) setPasswordInput(initialPasswordInput);
  }, [active, setPasswordInput]);

  const ModalForChangePassword = useMemo(() => {
    if (!active) return null;

    const canSubmit = !!(passwordInput?.password && passwordInput?.newPassword);

    return (
      <>
        <Modal
          isOpened
          handleClose={() => setActive(false)}
          modalBody={
            <>
              <Typography variant="h6" component="div">
                비밀번호 변경
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="현재 비밀번호"
                    size="medium"
                    fullWidth
                    value={passwordInput?.password}
                    onChange={(e) =>
                      setPasswordInput({
                        ...passwordInput,
                        password: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="새 비밀번호"
                    size="medium"
                    fullWidth
                    value={passwordInput?.newPassword}
                    onChange={(e) =>
                      setPasswordInput({
                        ...passwordInput,
                        newPassword: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    disabled={!canSubmit}
                    onClick={() => {
                      if (
                        passwordInput?.password &&
                        passwordInput?.newPassword
                      ) {
                        changePassword({
                          oldPassword: passwordInput.password,
                          newPassword: passwordInput.newPassword,
                        });
                      }
                    }}
                  >
                    확인
                  </Button>
                </Grid>
              </Grid>
            </>
          }
        />

        {ResponseHandlerOfChangePassword}
      </>
    );
  }, [active, passwordInput, ResponseHandlerOfChangePassword, changePassword]);

  return { changePassword: () => setActive(true), ModalForChangePassword };
}

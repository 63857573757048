import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ShippingItemDetail } from "types/shipping";
import { InventoryStatus, SkuPickingInfo } from "../utils";
import { PickingList } from "./PickingList";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

function UpdateShippingPickingListForm({
  shippingDetail,
  initialSkuPickingInfoList,
  initialInventoryStatus,
  onUpdateSuccess,
}: {
  shippingDetail: ShippingItemDetail;
  initialSkuPickingInfoList: SkuPickingInfo[];
  initialInventoryStatus: InventoryStatus[];
  onUpdateSuccess: () => void;
}) {
  const [skuPickingInfoList, setSkuPickingInfoList] = useState<
    SkuPickingInfo[]
  >(initialSkuPickingInfoList);

  const [inventoryStatus, setInventoryStatus] = useState<InventoryStatus[]>(
    initialInventoryStatus
  );

  const onPickingListChange = () => {
    setSkuPickingInfoList([...skuPickingInfoList]);
  };

  const modifyPickingLocationQuantity = (
    skuId: number,
    barCode: string,
    newQuantity: number
  ) => {
    const foundSkuPickingInfo = skuPickingInfoList.find(
      (v) => v.skuId === skuId
    );
    const foundPicking = foundSkuPickingInfo?.pickingList.find(
      (picking) => picking.barCode === barCode
    );

    if (foundPicking) {
      foundPicking.quantity = newQuantity;
      onPickingListChange();
    } else {
      alert("피킹로케이션 상태 추적 불가");
    }
  };

  const deletePickingLocation = (skuId: number, barCode: string) => {
    const foundSkuPickingInfo = skuPickingInfoList.find(
      (v) => v.skuId === skuId
    );
    if (foundSkuPickingInfo) {
      foundSkuPickingInfo.pickingList = foundSkuPickingInfo.pickingList.filter(
        (v) => v.barCode !== barCode
      );
      onPickingListChange();
    } else {
      alert("피킹로케이션 상태 추적 불가");
    }
  };

  const addPickingLocation = (
    skuId: number,
    barCode: string,
    locationId: number
  ) => {
    const foundSkuPickingInfo = skuPickingInfoList.find(
      (v) => v.skuId === skuId
    );
    if (foundSkuPickingInfo) {
      foundSkuPickingInfo.pickingList.push({
        barCode,
        locationId,
        quantity: 1,
      });
      onPickingListChange();
    } else {
      alert("피킹로케이션 상태 추적 불가");
    }
  };

  const updateButtonEnabled = skuPickingInfoList.every(
    (v) =>
      v.totalQuantity ===
      v.pickingList.reduce((prev, curr) => curr.quantity + prev, 0)
  );

  const {
    mutate: changeShippingItemsLocations,
    ResponseHandler: ResponseHandlerOfPatchShippingItemsLocations,
  } = SHIPPING_QUERY.useChangeShippingItemsLocations({
    onSuccess: () => {
      onUpdateSuccess();
    },
  });

  const onUpdateButtonClick = () => {
    changeShippingItemsLocations({
      shippings: [
        {
          shippingId: shippingDetail.id,

          shippingItemInfos: skuPickingInfoList
            .map((skuPickingInfo) =>
              skuPickingInfo.pickingList.map((picking) => ({
                skuId: skuPickingInfo.skuId,
                locationBarCode: picking.barCode,
                qty: picking.quantity,
              }))
            )
            .flat(),
        },
      ],
    });
  };

  return (
    <>
      <Paper
        style={{
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          피킹 로케이션 수정 폼
        </Typography>
        <Stack gap={"10px"}>
          <TextField
            label="센터번호"
            size="small"
            value={shippingDetail.warehouseId}
            disabled={true}
          />
          <TextField
            label="주문번호"
            size="small"
            value={shippingDetail.orderNo || " "}
            disabled={true}
          />
          <TextField
            label="받는분 이름"
            size="small"
            value={shippingDetail.customerName}
            disabled={true}
          />

          <br />

          <span>피킹 로케이션 정보 </span>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"120px"}>SKU ID</TableCell>
                  <TableCell width={"150px"}>주문 수량</TableCell>
                  <TableCell width={"500px"}>피킹 로케이션</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {skuPickingInfoList.map((skuPickingInfo, keyInner) => (
                  <PickingList
                    skuPickingInfo={skuPickingInfo}
                    candidateInventories={inventoryStatus.filter(
                      (inven) => inven.skuId === skuPickingInfo.skuId
                    )}
                    addPickingLocation={addPickingLocation}
                    deletePickingLocation={deletePickingLocation}
                    modifyPickingLocationQuantity={
                      modifyPickingLocationQuantity
                    }
                    key={keyInner}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ display: "flex", justifyContent: "stretch" }}>
            <Button
              variant="contained"
              onClick={onUpdateButtonClick}
              fullWidth={true}
              disabled={!updateButtonEnabled}
            >
              수정하기
            </Button>
          </div>

          <br />
          <br />
          <div>skuPickingInfoList[dev]</div>
          <textarea
            style={{
              backgroundColor: "black",
              color: "greenyellow",
              resize: "none",
              width: "100%",
              height: "500px",
            }}
            readOnly={true}
            rows={Object.keys(skuPickingInfoList).length}
            value={JSON.stringify(skuPickingInfoList, null, 2)}
          />
        </Stack>
      </Paper>
      {ResponseHandlerOfPatchShippingItemsLocations}
    </>
  );
}

export default UpdateShippingPickingListForm;

import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Print } from "@mui/icons-material";
import { Button, SelectChangeEvent } from "@mui/material";
import { useRecoilValue } from "recoil";

import {
  GET_ADMIN_SHIPPING_LIST_RES,
  INVOICE_NUMBERING_BY_PARCEL_COMPANY_REQ,
} from "api-interfaces/shipping";
import { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "states/auth";
import { DeliveryCategory } from "types/shipping";
import ConfirmModal from "components/ConfirmModal";

import SelectParcelCompanyModal from "./SelectParcelCompanyModal";
import useInvoiceNumberingAndPrintingModal from "./useInvoiceNumberingAndPrintingModal";
import useInvoicePrintingModal from "./useInvoicePrintingModal";

export type ParcelCompanyListForPrint =
  | INVOICE_NUMBERING_BY_PARCEL_COMPANY_REQ["parcelCompany"]
  | "default";

export type PrintInvoiceDeliveryCategory = Extract<
  DeliveryCategory,
  "domesticParcel" | "overseasExpress"
>;

export default function PrintInvoice({
  deliveryCategory,
  selectedShippingIds,
  shippingList,
  needsNumberingWhenPrintingInvoice,
  onModalClose,
}: {
  deliveryCategory: PrintInvoiceDeliveryCategory;
  selectedShippingIds: number[];
  shippingList: GET_ADMIN_SHIPPING_LIST_RES | undefined;
  needsNumberingWhenPrintingInvoice: boolean;
  onModalClose?: () => void;
}) {
  const [isSelectParcelCompanyModalOpen, setIsSelectParcelCompanyModalOpen] =
    useState(false);
  const [parcelCompany, setParcelCompany] =
    useState<ParcelCompanyListForPrint>("default");
  const [confirmModalTitle, setConfirmModalTitle] = useState("");

  const currentManagerAuthority = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.authority;

  const canPrintInvoice =
    currentManagerAuthority === "admin" ||
    currentManagerAuthority === "whMaster" ||
    currentManagerAuthority === "manager";

  const hasMixedParcelCompany = useMemo(() => {
    if (deliveryCategory === "overseasExpress") {
      return false;
    }

    return (
      new Set(
        shippingList?.list
          ?.filter((shipping) => selectedShippingIds.includes(shipping.id))
          .map(({ deliveryType }) => deliveryType)
      ).size > 1
    );
  }, [deliveryCategory, selectedShippingIds, shippingList?.list]);

  const handleSelectParcelCompanyModalOpen = () => {
    if (!canPrintInvoice) {
      setConfirmModalTitle("송장을 출력할 권한이 없습니다.");
      return;
    }

    if (hasMixedParcelCompany) {
      setConfirmModalTitle(
        "일반, 화물택배를 혼합하여 송장출력 할 수 없습니다."
      );
      return;
    }

    setIsSelectParcelCompanyModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setConfirmModalTitle("");
  };

  const handleSelectParcelCompanyModalClose = () => {
    setIsSelectParcelCompanyModalOpen(false);
    setParcelCompany("default");
  };

  const handleParcelCompanyChange = (
    event: SelectChangeEvent<ParcelCompanyListForPrint>
  ) => {
    setParcelCompany(event.target.value as ParcelCompanyListForPrint);
  };

  const handlePrint = (parcelCompany?: ParcelCompanyListForPrint) => () => {
    if (needsNumberingWhenPrintingInvoice) {
      openModalToNumberingAndPrintInvoice({
        shippingIds: selectedShippingIds,
        parcelCompany,
        handleSelectParcelCompanyModalClose,
      });
      return;
    }

    printInvoice({ shippingIds: selectedShippingIds });
  };

  const queryClient = useQueryClient();

  const {
    canStartNumberingAndPrintingInvoice,
    openModalToNumberingAndPrintInvoice,
    ModalToNumberingAndPrintInvoice,
  } = useInvoiceNumberingAndPrintingModal({
    shippingIds: selectedShippingIds,
    onModalClose:
      onModalClose ||
      (() => {
        // 출고 리스트 리프레시
        queryClient.invalidateQueries(
          SHIPPING_QUERY_KEY_GEN.getAdminShippingList({})
        );
      }),
  });

  const { canStartPrintingInvoice, printInvoice, ModalOfPrintingInvoice } =
    useInvoicePrintingModal({
      shippingIds: selectedShippingIds,
      onModalClose: handleSelectParcelCompanyModalClose,
    });

  const canPrintClick = needsNumberingWhenPrintingInvoice
    ? canStartNumberingAndPrintingInvoice
    : canStartPrintingInvoice;

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Print />}
        onClick={handleSelectParcelCompanyModalOpen}
        disabled={!canPrintClick}
      >
        송장 출력
      </Button>

      <SelectParcelCompanyModal
        deliveryCategory={deliveryCategory}
        selectedShippingCount={selectedShippingIds.length}
        isSelectParcelCompanyModalOpen={isSelectParcelCompanyModalOpen}
        onSelectParcelCompanyModalClose={handleSelectParcelCompanyModalClose}
        needsNumberingWhenPrintingInvoice={needsNumberingWhenPrintingInvoice}
        parcelCompany={parcelCompany}
        onParcelCompanyChange={handleParcelCompanyChange}
        onPrint={handlePrint}
      />

      <ConfirmModal
        title={confirmModalTitle}
        isConfirmModalOpen={!!confirmModalTitle}
        onConfirmModalClose={handleConfirmModalClose}
        actionPositive={{
          label: "확인",
          onClick: handleConfirmModalClose,
        }}
      />

      {ModalToNumberingAndPrintInvoice}
      {ModalOfPrintingInvoice}
    </>
  );
}

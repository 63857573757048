import { Stack } from "@mui/material";

import { ReturningListItem } from "types/returning";

import ReturningOrderDetailList from "./ReturningOrderDetailList";
import ReturningShippingDetailList from "./ReturningShippingDetailList";
import Summary from "./Summary";

export default function DetailInfo({
  returningDetailData,
}: {
  returningDetailData: ReturningListItem;
}) {
  return (
    <>
      <Stack spacing={2}>
        <ReturningOrderDetailList returningDetailData={returningDetailData} />

        <ReturningShippingDetailList
          returningDetailData={returningDetailData}
        />

        <Summary returningDetailData={returningDetailData} />
      </Stack>
    </>
  );
}

import { COLOR } from "styles/constants";

import ListItem from "components/sds-v1/ListItem";
import SubdirectoryIcon from "_sds-v2/components/svgIcons/SubdirectoryIcon";

import Styled from "./index.styles";

export type ValueType = "default" | "normal" | "warning";

export default function IconListItem({
  depth = 1,
  label,
  value,
  valueType = "default",
  buttonInfo,
}: {
  depth?: number;
  label: string;
  value: React.ReactNode;
  valueType?: ValueType;
  buttonInfo?: {
    label: string;
    handleClick: () => void;
  };
}) {
  return (
    <Styled.container depth={depth} valueType={valueType}>
      <SubdirectoryIcon
        width={16}
        height={16}
        color={COLOR.grayScale_500}
        className="icon"
      />

      {buttonInfo ? (
        <ListItem
          type={"withButton"}
          label={label}
          value={value}
          button={{
            label: buttonInfo.label,
            handleClick: buttonInfo.handleClick,
          }}
          className="custom-list-item"
        />
      ) : (
        <ListItem
          type={"withContentAlignRight"}
          label={label}
          value={value}
          className="custom-list-item"
        />
      )}
    </Styled.container>
  );
}

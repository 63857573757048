import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Description, Print } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useRecoilValue } from "recoil";

import { GET_ADMIN_SHIPPING_LIST_RES } from "api-interfaces/shipping";
import { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "states/auth";
import { toThousandUnitFormat } from "utils/number";
import ConfirmModal from "components/ConfirmModal";

import usePrintTransactionStatement from "./usePrintTransactionStatement";
import useUpdateStatusAndPrintTransactionStatement from "./useUpdateStatusAndPrintTransactionStatement";

export type ShippingListForPrinting = GET_ADMIN_SHIPPING_LIST_RES["list"];

export default function PrintTransactionStatement({
  isUsedInsteadOfPrintingInvoice,
  selectedShippingIds,
  shippingList,
  needsStateUpdateWhenPrintingTransactionStatement,
}: {
  /**  화물택배의 송장출력 시에는 거래명세서 출력이 대신 동작함 */
  isUsedInsteadOfPrintingInvoice?: boolean;
  selectedShippingIds: number[];
  shippingList: GET_ADMIN_SHIPPING_LIST_RES | undefined;
  needsStateUpdateWhenPrintingTransactionStatement: boolean;
}) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [messageConfirmModalTitle, setMessageConfirmModalTitle] = useState("");

  const currentManagerAuthority = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.authority;

  const canPrintInvoice =
    isUsedInsteadOfPrintingInvoice &&
    (currentManagerAuthority === "admin" ||
      currentManagerAuthority === "whMaster" ||
      currentManagerAuthority === "manager");

  const handleConfirmModalOpen = () => {
    if (isUsedInsteadOfPrintingInvoice && !canPrintInvoice) {
      setMessageConfirmModalTitle("송장을 출력할 권한이 없습니다.");
      return;
    }

    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleMessageConfirmModalClose = () => {
    setMessageConfirmModalTitle("");
  };

  const selectedShippings = useMemo(() => {
    if (!shippingList?.list) {
      return [];
    }

    return selectedShippingIds.map((v) => {
      // 체크박스는 shippingId로 만들기 떄문에 존재함을 보장할 수 있음
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return shippingList.list.find((s) => s.id === v)!;
    });
  }, [shippingList?.list, selectedShippingIds]);

  const queryClient = useQueryClient();

  const {
    updateStatusAndPrintTransactionStatement,
    ModalToUpdateStatus,
    canUpdateStatus,
  } = useUpdateStatusAndPrintTransactionStatement({
    shippings: selectedShippings,
    onModalClose: () => {
      queryClient.invalidateQueries(
        SHIPPING_QUERY_KEY_GEN.getAdminShippingList({})
      );
    },
  });

  const { printTransactionStatement, canPrint } = usePrintTransactionStatement({
    shippingsToPrint: selectedShippings,
  });

  const handleTransactionStatementPrint = useCallback(() => {
    handleConfirmModalClose();

    if (needsStateUpdateWhenPrintingTransactionStatement) {
      updateStatusAndPrintTransactionStatement();
      return;
    }

    printTransactionStatement();
  }, [
    needsStateUpdateWhenPrintingTransactionStatement,
    printTransactionStatement,
    updateStatusAndPrintTransactionStatement,
  ]);

  const canPrintClick = needsStateUpdateWhenPrintingTransactionStatement
    ? canUpdateStatus
    : canPrint;

  return (
    <>
      <Button
        variant="outlined"
        startIcon={isUsedInsteadOfPrintingInvoice ? <Print /> : <Description />}
        onClick={handleConfirmModalOpen}
        disabled={!canPrintClick}
      >
        {isUsedInsteadOfPrintingInvoice ? "송장 출력" : "거래명세서 출력"}
      </Button>

      <ConfirmModal
        isConfirmModalOpen={isConfirmModalOpen}
        onConfirmModalClose={handleConfirmModalClose}
        title={
          <>
            출고 {toThousandUnitFormat(selectedShippingIds.length)}건의
            거래명세서를 출력하시겠습니까?
            {needsStateUpdateWhenPrintingTransactionStatement && (
              <Typography sx={{ color: red[700] }}>
                (출력시 '피킹 상태'로 변경됩니다.)
              </Typography>
            )}
          </>
        }
        actionPositive={{
          label: `${
            needsStateUpdateWhenPrintingTransactionStatement
              ? "다음"
              : "출력하기"
          } `,
          onClick: handleTransactionStatementPrint,
        }}
      />

      {isUsedInsteadOfPrintingInvoice && (
        <ConfirmModal
          title={messageConfirmModalTitle}
          isConfirmModalOpen={!!messageConfirmModalTitle}
          onConfirmModalClose={handleMessageConfirmModalClose}
          actionPositive={{
            label: "확인",
            onClick: handleMessageConfirmModalClose,
          }}
        />
      )}

      {ModalToUpdateStatus}
    </>
  );
}

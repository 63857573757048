import {
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { InventoryStatus, SkuPickingInfo } from "../utils";
import { getHighlightedLabel } from "utils/string";

export function PickingList({
  skuPickingInfo,
  candidateInventories,
  addPickingLocation,
  deletePickingLocation,
  modifyPickingLocationQuantity,
}: {
  skuPickingInfo: SkuPickingInfo;
  candidateInventories: InventoryStatus[];
  addPickingLocation: (
    skuId: number,
    barCode: string,
    locationId: number
  ) => void;
  deletePickingLocation: (skuId: number, barCode: string) => void;
  modifyPickingLocationQuantity: (
    skuId: number,
    barCode: string,
    newQuantity: number
  ) => void;
}) {
  const [selectedLocationBarcode, setSelectedLocationBarcode] =
    useState<string>();

  const onSelectLocationBarcode = (e: SelectChangeEvent<string>) => {
    setSelectedLocationBarcode(e.target.value);
  };

  return (
    <TableRow>
      <TableCell>{skuPickingInfo.skuId}</TableCell>
      <TableCell>
        {skuPickingInfo.totalQuantity}{" "}
        {skuPickingInfo.totalQuantity !==
          skuPickingInfo.pickingList.reduce(
            (prev, curr) => curr.quantity + prev,
            0
          ) &&
          getHighlightedLabel({
            label: `(갯수 맞지 않음: 현재합(${skuPickingInfo.pickingList.reduce(
              (prev, curr) => curr.quantity + prev,
              0
            )}))`,
            isHighlighted: true,
          })}
      </TableCell>
      <TableCell>
        <Stack flexDirection={"column"} gap={"3px"}>
          {skuPickingInfo.pickingList.map((picking, keyOuter) => (
            <Stack
              flexDirection={"row"}
              key={keyOuter}
              alignItems={"center"}
              width={"500px"}
              justifyContent={"space-between"}
              gap={"3px"}
            >
              <span style={{ width: "100px" }}>{picking.barCode}</span>
              <OutlinedInput
                size="small"
                type={"number"}
                inputMode="numeric"
                value={picking.quantity}
                onChange={(e) => {
                  const newQuantity = Number.isNaN(Number(e.target.value))
                    ? picking.quantity
                    : Number(e.target.value) || 1;
                  modifyPickingLocationQuantity(
                    skuPickingInfo.skuId,
                    picking.barCode,
                    newQuantity
                  );
                }}
                endAdornment={
                  <InputAdornment position="end">qty</InputAdornment>
                }
              />

              <Button
                onClick={() => {
                  deletePickingLocation(skuPickingInfo.skuId, picking.barCode);
                }}
              >
                제거
              </Button>
            </Stack>
          ))}

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            gap={"3px"}
            width={"500px"}
            justifyContent={"space-between"}
          >
            <FormControl size="small">
              <Select
                value={selectedLocationBarcode || ""}
                onChange={onSelectLocationBarcode}
              >
                <MenuItem value={""}>{"선택안함"}</MenuItem>
                {candidateInventories.map((inven, locIndex) => (
                  <MenuItem
                    value={inven.locationBarCode}
                    key={locIndex}
                    disabled={skuPickingInfo.pickingList
                      .map((v) => v.barCode)
                      .includes(inven.locationBarCode)}
                  >
                    {inven.locationBarCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              disabled={!selectedLocationBarcode}
              onClick={() => {
                const foundInventory = candidateInventories.find(
                  (inven) => inven.locationBarCode === selectedLocationBarcode
                );
                if (foundInventory) {
                  addPickingLocation(
                    skuPickingInfo.skuId,
                    foundInventory.locationBarCode,
                    foundInventory.locationId
                  );
                }
                setSelectedLocationBarcode("");
              }}
            >
              추가
            </Button>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useQueryString from "hooks/useQueryString";
import { AdminShippingListTabStatus, DeliveryCategory } from "types/shipping";

import ParcelList from "./ParcelList";
import TruckList from "./TruckList";

export type DomesticCategory = "PARCEL" | "TRUCK";

function ShippingDomesticList() {
  const location = useLocation();

  const [deliveryCategory, setDeliveryCategory] = useState<DeliveryCategory>(
    location.search.includes("category=PARCEL")
      ? "domesticParcel"
      : "domesticTruck"
  );

  const history = useHistory();
  const { setQuery } = useQueryString<{
    category: DomesticCategory;
    status: AdminShippingListTabStatus;
  }>(history);

  const handleDeliveryCategoryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeliveryCategory(e.target.value as DeliveryCategory);
    setQuery({
      category: e.target.value === "domesticParcel" ? "PARCEL" : "TRUCK",
      status: "WAITING_TODAY",
    });
  };

  const showsParcelList = deliveryCategory === "domesticParcel";
  const showsTruckList = deliveryCategory === "domesticTruck";

  return (
    <Layout>
      <RadioGroup
        sx={{ pl: 2 }}
        row
        aria-labelledby="delivery-category"
        name="delivery-category"
        value={deliveryCategory}
        onChange={handleDeliveryCategoryChange}
      >
        <FormControlLabel
          value="domesticParcel"
          control={<Radio size="small" />}
          label="택배"
        />
        <FormControlLabel
          value="domesticTruck"
          control={<Radio size="small" />}
          label="화물차량/직접수령"
        />
      </RadioGroup>

      {showsParcelList && <ParcelList deliveryCategory={deliveryCategory} />}
      {showsTruckList && <TruckList deliveryCategory={deliveryCategory} />}
    </Layout>
  );
}

export default withRequireAuth(ShippingDomesticList);

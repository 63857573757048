import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilter";
import { ReturningListFilterStatus } from "types/returning";
import { RETURNING_LIST_FILTER_STATUS_DICT } from "utils/returning";

const filterOptions: TableHeadFilterOption<ReturningListFilterStatus>[] =
  Object.keys(RETURNING_LIST_FILTER_STATUS_DICT).map((filterStatus) => ({
    label:
      RETURNING_LIST_FILTER_STATUS_DICT[
        filterStatus as ReturningListFilterStatus
      ],
    value: filterStatus as ReturningListFilterStatus,
  }));

export default function useFilterStatusFilter() {
  const { FilterPanel: FilterStatusFilterPanel, filter: filterStatus } =
    useTableHeadFilter({
      filterOptions,
    });

  return { FilterStatusFilterPanel, filterStatus };
}

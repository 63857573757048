import { AxiosError } from "axios";
import QueryResponseHandler, {
  QueryResponseHandlerConfirmModalStatus,
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "components/sds-v1/QueryResponseHandler";
import { useCallback, useMemo, useState } from "react";
import {
  DefaultOptions,
  MutationCache,
  MutationOptions,
  Query,
  QueryCache,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  ResponseFailureInfo,
  SendRequestOptions,
  SendRequestOptionsData,
  SendRequestOptionsDataCustomOptions,
  SendRequestOptionsForMutation,
  SendRequestOptionsWithQueryKeyFactory,
} from "types/common";
import { sendRequest } from "./request";
import {
  DAY_AS_MILLISECONDS,
  HOUR_AS_MILLISECONDS,
  MINUTE_AS_MILLISECONDS,
} from "utils/date";

/**
 * react-query client 공통 설정값
 */
const REACT_QUERY_CLIENT_CONFIG: {
  queryCache?: QueryCache;
  mutationCache?: MutationCache;
  defaultOptions?: DefaultOptions;
} = {
  defaultOptions: {
    queries: {
      /**
       * focus 될때마다 fetch하는 동작을 막음
       */
      refetchOnWindowFocus: false,
      /**
       * 요청실패시 재요청하는 동작을 막음
       */
      retry: false,
    },
  },
};

type MutationCustomSuccessHandler<RequestPayload, ResponseData> = (
  data: ResponseData,
  variables: RequestPayload
  // TODO: 추후 필요 시 추가
  // context: unknown
) => void;

/**
 * mutation의 side effect 타입
 */
type MutationSideEffectType<RequestPayload, ResponseData> = Pick<
  MutationOptions<
    { data: ResponseData },
    AxiosError<ResponseFailureInfo>,
    RequestPayload
  >,
  "onMutate" | "onSettled" | "retry"
> & {
  onSuccess?: MutationCustomSuccessHandler<RequestPayload, ResponseData>;
};

/**
 * PathParams 제너릭을 넣으면 동적으로 path를 설정할 수 있음 (mutate시 pathParams을 보내도록 강제됨)
 */
function useAppMutation<RequestPayload, ResponseData, PathParams = void>({
  requestOptions,
  onError,
  onSettled,
  onSuccess,
  onMutate,
  retry,
  successModalInfo,
  failureModalInfo,
  loadingLabel,
  hidesLoading,
}: {
  requestOptions: SendRequestOptionsForMutation<PathParams>;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<ResponseData>;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  onError?: (
    failureInfo: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
  loadingLabel?: React.ReactNode;
  hidesLoading?: boolean;
} & MutationSideEffectType<RequestPayload, ResponseData>) {
  const [confirmModalStatus, setConfirmModalStatus] =
    useState<QueryResponseHandlerConfirmModalStatus>();

  const [hidesFailureModal, setHidesFailureModal] = useState(false);

  const handleSuccess = useCallback(
    (
      data: {
        data: ResponseData;
      },
      variables: RequestPayload
    ) => {
      if (onSuccess) {
        onSuccess(data?.data, variables);
      }

      setConfirmModalStatus("success");
    },
    [onSuccess]
  );

  const handleError = useCallback(
    (error: AxiosError<ResponseFailureInfo, unknown>) => {
      if (onError) {
        onError(error?.response?.data, () => setHidesFailureModal(true));
      }

      setConfirmModalStatus("error");
    },
    [onError]
  );

  const initConfirmModalStatus = useCallback(() => {
    setConfirmModalStatus(undefined);
  }, []);

  const mutation = useMutation<
    { data: ResponseData },
    AxiosError<ResponseFailureInfo>,
    RequestPayload &
      (SendRequestOptionsDataCustomOptions | void) &
      (PathParams extends void ? unknown : { pathParams: PathParams })
  >(
    (payload) =>
      sendRequest({
        ...requestOptions,
        data: payload as SendRequestOptionsData,
        path:
          typeof requestOptions.path === "function"
            ? requestOptions.path(
                // SendRequestOptionsForMutation에서 체크를 해주는데, 이 부분에서는 자동으로 타입체크되는 방법이 잘 안 찾아져서 이렇게 처리
                (payload as unknown as { pathParams: PathParams }).pathParams
              )
            : requestOptions.path,
      }),
    {
      onError: handleError,
      onSettled,
      onMutate,
      onSuccess: handleSuccess,
      retry,
    }
  );

  const ResponseHandler = useMemo(() => {
    return (
      <QueryResponseHandler<ResponseData>
        isLoading={mutation.isLoading}
        loadingLabel={loadingLabel}
        hidesLoading={hidesLoading}
        hidesFailureModal={hidesFailureModal}
        successResData={mutation.data?.data}
        initQuery={() => {
          mutation.reset();
        }}
        successModalInfo={successModalInfo}
        failureModalInfo={failureModalInfo}
        failureInfo={
          mutation.status === "error"
            ? mutation.error?.response?.data
            : undefined
        }
        confirmModalStatus={confirmModalStatus}
        initConfirmModalStatus={initConfirmModalStatus}
      />
    );
  }, [
    mutation,
    loadingLabel,
    hidesLoading,
    hidesFailureModal,
    successModalInfo,
    failureModalInfo,
    confirmModalStatus,
    initConfirmModalStatus,
  ]);

  return {
    ...mutation,
    data: mutation.data?.data,
    ResponseHandler,
  };
}

/**
 * useQuery를 사용할때 팀에서 사용하는 공통 설정과 반복 작업들을 캠슐화한 hook
 *
 * select를 사용할때 return type이 달라진다면 SelectedData 타입을 명시해줘야 함
 */
function useAppQueryWithQueryKeyFactory<
  QueryKeyType extends readonly [unknown],
  ResponseData,
  SelectedData = ResponseData
>({
  queryKey,
  requestOptions,
  enabled = true,
  keepPreviousData = false,
  staleTime,
  cacheTime,
  successModalInfo,
  failureModalInfo,
  onSuccess,
  onSettled,
  onError,
  select,
  loadingLabel,
  hidesLoading,
  refetchOnMount,
  retry,
  refetchInterval,
}: {
  /**
   * api를 호출할때 필요한 변수들은 모두 배열요소에 있어야 이후 refetch등이 정상작동함에 유의
   * 특히 params에 들어가는 변수들은 무조건 queryKey의 배열에 포함되어야한다(그렇지 않으면 캐시데이터를 리턴됨)
   */
  queryKey: QueryKeyType;
  requestOptions: SendRequestOptionsWithQueryKeyFactory<QueryKeyType>;
  /**
   * useQuery는 기본적으로 auto fetch 되는데, 이 작동을 비활성화 할 수 있다
   * 다만 비활성화하면 자동으로 fetch해주는 모든 것들이 비활성화되므로 공식문서의 주의사항을 반드시 보고 사용한다
   * (주의사항 https://react-query.tanstack.com/guides/disabling-queries)
   */
  enabled?: boolean;
  keepPreviousData?: boolean;
  /**
   * 데이터가 stale상태로 변경되기까지 걸리는 시간. (기본값 0)
   * stale되기 전에 호출되는 요청은 호출되지 않고 cache를 사용
   */
  staleTime?: number;
  /**
   * cache 데이터를 유지하는 시간 (기본값 5분)
   * cache를 사용하는 인스턴스가 없을때부터 이 시간이 경과하면 캐시는 삭제됨
   */
  cacheTime?: number;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<SelectedData>;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  onSuccess?: (data: SelectedData) => void;
  onSettled?: () => void;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
  select?: (data: ResponseData) => SelectedData;
  loadingLabel?: React.ReactNode;
  hidesLoading?: boolean;
  refetchOnMount?: boolean | "always";
  retry?: boolean | number;
  refetchInterval?:
    | number
    | false
    | ((
        data:
          | {
              data: SelectedData;
            }
          | undefined,
        query: Query<
          {
            data: ResponseData;
          },
          AxiosError<ResponseFailureInfo, unknown>,
          {
            data: ResponseData;
          },
          QueryKey
        >
      ) => number | false);
}) {
  const [confirmModalStatus, setConfirmModalStatus] =
    useState<QueryResponseHandlerConfirmModalStatus>();

  const [hidesFailureModal, setHidesFailureModal] = useState(false);

  const handleSuccess = useCallback(
    (data: { data: SelectedData }) => {
      if (onSuccess) {
        onSuccess(data?.data);
      }

      setConfirmModalStatus("success");
    },
    [onSuccess]
  );

  const handleError = useCallback(
    (error: AxiosError<ResponseFailureInfo, unknown>) => {
      if (onError) {
        onError(error?.response?.data, () => setHidesFailureModal(true));
      }

      setConfirmModalStatus("error");
    },
    [onError]
  );

  const initConfirmModalStatus = useCallback(() => {
    setConfirmModalStatus(undefined);
  }, []);

  const queryResult = useQuery<
    { data: ResponseData },
    AxiosError<ResponseFailureInfo>,
    { data: SelectedData }
  >(queryKey, ({ signal }) => sendRequest({ ...requestOptions, signal }), {
    enabled,
    keepPreviousData,
    staleTime,
    cacheTime: cacheTime,
    onSuccess: handleSuccess,
    onSettled,
    onError: handleError,
    select: select
      ? (data) => {
          return { data: select(data?.data) };
        }
      : undefined,

    refetchOnMount,
    ...(typeof retry === "boolean" || typeof retry === "number"
      ? { retry }
      : {}),

    refetchInterval,
  });

  const queryClient = useQueryClient();

  const ResponseHandler = useMemo(() => {
    return (
      <QueryResponseHandler<SelectedData>
        isLoading={queryResult.isLoading || queryResult.isFetching}
        loadingLabel={loadingLabel}
        hidesLoading={hidesLoading}
        hidesFailureModal={hidesFailureModal}
        successResData={queryResult.data?.data}
        successModalInfo={successModalInfo}
        failureModalInfo={failureModalInfo}
        failureInfo={
          queryResult.status === "error"
            ? queryResult.error?.response?.data
            : undefined
        }
        confirmModalStatus={confirmModalStatus}
        initConfirmModalStatus={initConfirmModalStatus}
      />
    );
  }, [
    queryResult.isLoading,
    queryResult.isFetching,
    queryResult.data?.data,
    queryResult.status,
    queryResult.error?.response?.data,
    loadingLabel,
    hidesLoading,
    hidesFailureModal,
    successModalInfo,
    failureModalInfo,
    confirmModalStatus,
    initConfirmModalStatus,
  ]);

  return {
    ...queryResult,
    data: queryResult.data?.data, // query.data로 오는것이 data라기보다는 http response라 기존 팀에서 사용하는 data의 의미에 맞게 수정
    reset: () => queryClient.resetQueries(queryKey, { exact: true }),
    ResponseHandler,
  };
}

const QUERY_STALE_TIME = {
  fiveMinutes: 5 * MINUTE_AS_MILLISECONDS,
  halfHour: 30 * MINUTE_AS_MILLISECONDS,
  oneHour: HOUR_AS_MILLISECONDS,
  oneDay: DAY_AS_MILLISECONDS,
  forever: Infinity,
};

/**
 * useQuery를 사용할때 팀에서 사용하는 공통 설정과 반복 작업들을 캠슐화한 hook
 *
 * select를 사용할때 return type이 달라진다면 SelectedData 타입을 명시해줘야 함
 */
function useAppQuery<ResponseData, SelectedData = ResponseData>({
  queryKey,
  requestOptions,
  enabled = true,
  keepPreviousData = false,
  staleTime,
  cacheTime,
  successModalInfo,
  failureModalInfo,
  onSuccess,
  onError,
  select,
  hidesLoading,
  loadingLabel,
}: {
  /**
   * api를 호출할때 필요한 변수들은 모두 배열요소에 있어야 이후 refetch등이 정상작동함에 유의
   * 특히 params에 들어가는 변수들은 무조건 queryKey의 배열에 포함되어야한다(그렇지 않으면 캐시데이터를 리턴됨)
   */
  queryKey: QueryKey;
  requestOptions: SendRequestOptions;
  /**
   * useQuery는 기본적으로 auto fetch 되는데, 이 작동을 비활성화 할 수 있다
   * 다만 비활성화하면 자동으로 fetch해주는 모든 것들이 비활성화되므로 공식문서의 주의사항을 반드시 보고 사용한다
   * (주의사항 https://react-query.tanstack.com/guides/disabling-queries)
   */
  enabled?: boolean;
  keepPreviousData?: boolean;
  /**
   * 데이터가 stale상태로 변경되기까지 걸리는 시간. (기본값 0)
   * stale되기 전에 호출되는 요청은 호출되지 않고 cache를 사용
   */
  staleTime?: number;
  /**
   * cache 데이터를 유지하는 시간 (기본값 5분)
   * cache를 사용하는 인스턴스가 없을때부터 이 시간이 경과하면 캐시는 삭제됨
   */
  cacheTime?: number;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<SelectedData>;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  onSuccess?: (data: SelectedData) => void;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
  select?: (data: ResponseData) => SelectedData;
  hidesLoading?: boolean;
  loadingLabel?: React.ReactNode;
}) {
  const [confirmModalStatus, setConfirmModalStatus] =
    useState<QueryResponseHandlerConfirmModalStatus>();

  const [hidesFailureModal, setHidesFailureModal] = useState(false);

  const handleSuccess = useCallback(
    (data: { data: SelectedData }) => {
      if (onSuccess) {
        onSuccess(data?.data);
      }

      setConfirmModalStatus("success");
    },
    [onSuccess]
  );

  const handleError = useCallback(
    (error: AxiosError<ResponseFailureInfo, unknown>) => {
      if (onError) {
        onError(error?.response?.data, () => setHidesFailureModal(true));
      }

      setConfirmModalStatus("error");
    },
    [onError]
  );

  const initConfirmModalStatus = useCallback(() => {
    setConfirmModalStatus(undefined);
  }, []);

  const queryResult = useQuery<
    { data: ResponseData },
    AxiosError<ResponseFailureInfo>,
    { data: SelectedData }
  >(queryKey, ({ signal }) => sendRequest({ ...requestOptions, signal }), {
    enabled,
    keepPreviousData,
    staleTime,
    cacheTime: cacheTime,
    onSuccess: handleSuccess,
    onError: handleError,
    select: select
      ? (data) => {
          return { data: select(data?.data) };
        }
      : undefined,
  });

  const queryClient = useQueryClient();

  const ResponseHandler = useMemo(() => {
    return (
      <QueryResponseHandler<SelectedData>
        isLoading={queryResult.isLoading || queryResult.isFetching}
        loadingLabel={loadingLabel}
        hidesLoading={hidesLoading}
        hidesFailureModal={hidesFailureModal}
        successResData={queryResult.data?.data}
        successModalInfo={successModalInfo}
        failureModalInfo={failureModalInfo}
        failureInfo={
          queryResult.status === "error"
            ? (queryResult.error?.response
                ?.data as unknown as ResponseFailureInfo)
            : undefined
        }
        confirmModalStatus={confirmModalStatus}
        initConfirmModalStatus={initConfirmModalStatus}
      />
    );
  }, [
    queryResult.isLoading,
    queryResult.isFetching,
    queryResult.data?.data,
    queryResult.status,
    queryResult.error?.response?.data,
    loadingLabel,
    hidesLoading,
    hidesFailureModal,
    successModalInfo,
    failureModalInfo,
    confirmModalStatus,
    initConfirmModalStatus,
  ]);

  return {
    ...queryResult,
    data: queryResult.data?.data, // query.data로 오는것이 data라기보다는 http response라 기존 팀에서 사용하는 data의 의미에 맞게 수정
    reset: () => queryClient.resetQueries(queryKey, { exact: true }),
    ResponseHandler,
  };
}

export type { MutationSideEffectType };

export {
  REACT_QUERY_CLIENT_CONFIG,
  QUERY_STALE_TIME,
  useAppMutation,
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
};

import { useCallback, useMemo } from "react";
import { Button, Typography } from "@mui/material";

import { ShippingItem, ShippingItemDetail } from "types/shipping";
import { ShippingSKUItem } from "types/sku";
import { toThousandUnitFormat } from "utils/number";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { getAdminStatus } from "utils/shipping";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import useSkuImageModal from "hooks/useSkuImageModal";
import { toFormattedDate } from "utils/date";

type CellId =
  | Extract<keyof ShippingItem, "skuId" | "managementDate">
  | Extract<keyof ShippingSKUItem, "itemName" | "barCode">
  | "shippingQuantity"
  | "image";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 200,
  },
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 100,
  },
  {
    id: "barCode",
    disablePadding: false,
    label: "상품 바코드",
    width: 100,
  },
  {
    id: "managementDate",
    disablePadding: false,
    label: "관리일자",
    width: 110,
  },
  {
    id: "shippingQuantity",
    disablePadding: false,
    label: "출고수량(PCS)",
    width: 120,
  },
  {
    id: "image",
    disablePadding: false,
    label: "이미지",
    width: 50,
  },
];

export default function OrderDetailList({
  shippingDetailData,
}: {
  shippingDetailData: ShippingItemDetail | undefined;
}) {
  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();

  const handleItemImageSelect = useCallback(
    (skuData: ShippingSKUItem) => () => {
      handleSkuForImageModalSelect({
        skuData: { ...skuData, type: "single" },
        imageType: "item",
      });
    },
    [handleSkuForImageModalSelect]
  );

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!shippingDetailData) {
      return [];
    }

    return shippingDetailData.items.map((shippingItem) => ({
      itemName: shippingItem.sku.itemName ?? "-",
      skuId: getFormattedSingleSkuId(shippingItem.skuId),
      barCode: shippingItem.sku.barCode ?? "-",
      managementDate: shippingItem.managementDate
        ? toFormattedDate(shippingItem.managementDate, "YYYY-MM-DD")
        : "-",
      orderQuantity: toThousandUnitFormat(shippingItem.quantity),
      image: (
        <Button
          variant="outlined"
          size="small"
          onClick={handleItemImageSelect(shippingItem.sku)}
        >
          보기
        </Button>
      ),
    }));
  }, [handleItemImageSelect, shippingDetailData]);

  return (
    <>
      <Table<CellId>
        title="출고정보"
        description={
          <Typography variant="body2">
            출고 상태: {getAdminStatus(shippingDetailData)}
          </Typography>
        }
        headCells={headCells}
        rows={rows}
      />

      <SkuImageModal />
    </>
  );
}

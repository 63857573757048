import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import RETURNING_QUERY from "queries/RETURNING_QUERY";

import AddReturningInvoice from "./AddReturningInvoice";
import DetailInfo from "./DetailInfo";
import InspectionInfo from "./InspectionInfo";
import UpperSummary from "./UpperSummary";
import BeforeCompleteInspection from "./BeforeCompleteInspection";

type ReturningDetailModalTabsType = "detailInfo" | "inspectionInfo";
export default function useReturningDetailModal() {
  const [returningId, setReturningId] = useState<number>();

  const [currentTab, setCurrentTab] =
    useState<ReturningDetailModalTabsType>("detailInfo");

  const [showsAddReturningInvoiceModal, setShowsAddReturningInvoiceModal] =
    useState(false);

  const handleAddReturningInvoiceModalOpen = useCallback(() => {
    setShowsAddReturningInvoiceModal(true);
  }, []);

  const handleAddReturningInvoiceModalClose = useCallback(() => {
    setShowsAddReturningInvoiceModal(false);
  }, []);

  const {
    ResponseHandler: ResponseHandlerOfReturningDetail,
    data: returningDetailData,
  } = RETURNING_QUERY.useGetAdminReturningDetail({
    enabled: !!returningId,
    returningId: returningId || 0,
  });

  const isReadyToShow = !!returningDetailData;

  const isBeforeCompleteInspectionStatus =
    returningDetailData?.status === "beforeReturning" ||
    returningDetailData?.status === "waitingInspection" ||
    returningDetailData?.status === "inspecting";

  const openReturningDetailModal = useCallback((returningId: number) => {
    setReturningId(returningId);
  }, []);

  const handleClose = useCallback(() => {
    setReturningId(undefined);
    setCurrentTab("detailInfo");
  }, []);

  const ReturningDetailModal = useMemo(() => {
    if (!returningId) return null;

    const isBeforeReturningStatus =
      returningDetailData?.status === "beforeReturning";

    const handleTabChange = (
      event: React.SyntheticEvent,
      newValue: ReturningDetailModalTabsType
    ) => {
      setCurrentTab(newValue);
    };

    return (
      <>
        {isReadyToShow && (
          <>
            <Dialog
              open={!!returningId}
              fullWidth={true}
              maxWidth={"lg"}
              onClose={handleClose}
            >
              <DialogTitle>
                상세 보기
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    onClick={handleAddReturningInvoiceModalOpen}
                  >
                    반송장 신청/취소
                  </Button>
                </Stack>
              </DialogTitle>

              <DialogContent dividers>
                <UpperSummary returningDetail={returningDetailData} />

                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="returning-detail-tabs"
                >
                  <Tab label="반품정보" value="detailInfo"></Tab>
                  {!isBeforeReturningStatus && (
                    <Tab label="검수정보" value="inspectionInfo"></Tab>
                  )}
                </Tabs>

                {currentTab === "detailInfo" && (
                  <DetailInfo returningDetailData={returningDetailData} />
                )}
                {currentTab === "inspectionInfo" &&
                  (isBeforeCompleteInspectionStatus ? (
                    <BeforeCompleteInspection />
                  ) : (
                    <InspectionInfo returningId={returningDetailData.id} />
                  ))}
              </DialogContent>
            </Dialog>

            <AddReturningInvoice
              key={String(showsAddReturningInvoiceModal)}
              returningDetailData={returningDetailData}
              showsAddReturningInvoiceModal={showsAddReturningInvoiceModal}
              onAddReturningInvoiceModalClose={
                handleAddReturningInvoiceModalClose
              }
            />
          </>
        )}

        {ResponseHandlerOfReturningDetail}
      </>
    );
  }, [
    ResponseHandlerOfReturningDetail,
    handleAddReturningInvoiceModalClose,
    handleAddReturningInvoiceModalOpen,
    handleClose,
    returningDetailData,
    returningId,
    showsAddReturningInvoiceModal,
    currentTab,
    isReadyToShow,
  ]);

  return { openReturningDetailModal, ReturningDetailModal };
}

import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { TextField } from "@mui/material";

function SimpleInputTextField<T>({
  columnName,
  label,
  disabled,
  shippingInputForm,
  setShippingInputForm,
}: {
  columnName: keyof T;
  label: string;
  disabled: boolean;
  shippingInputForm: T;
  setShippingInputForm: Dispatch<SetStateAction<T>>;
}) {
  return (
    <TextField
      label={label}
      size="small"
      value={shippingInputForm[columnName] || ""}
      onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setShippingInputForm({
          ...shippingInputForm,
          [columnName]: e.target.value,
        });
      }}
      disabled={disabled}
    />
  );
}
export default SimpleInputTextField;

import { ShippingItemDetail, ShippingLocation } from "types/shipping";

export type SkuPickingInfo = {
  skuId: number;
  totalQuantity: number;
  pickingList: {
    locationId: number;
    barCode: string;
    quantity: number;
  }[];
};

export const compositeSkuPickingInfoList = (
  shippingDetail: ShippingItemDetail,
  locations: ShippingLocation[]
): { data: SkuPickingInfo[]; hasError: boolean } => {
  const targetSkuIds = [...new Set(shippingDetail.items.map((v) => v.skuId))];
  let hasError = false;

  const data: SkuPickingInfo[] = targetSkuIds.map((skuId) => {
    const targetShippingItems = shippingDetail.items.filter(
      (v) => v.skuId === skuId
    );
    return {
      skuId,
      totalQuantity: targetShippingItems.reduce(
        (prev, curr) => prev + curr.quantity,
        0
      ),
      pickingList: targetShippingItems.map((targetShippingItem) => {
        const foundLocation = locations.find(
          (location) => location.id === targetShippingItem.locationId
        );
        if (!foundLocation) {
          hasError = true;
        }
        return {
          locationId: targetShippingItem.locationId,
          barCode: foundLocation?.barCode || "ERROR",
          quantity: targetShippingItem.quantity,
        };
      }),
    };
  });

  return { data, hasError };
};

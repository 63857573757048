import { useQueryClient } from "react-query";
import {
  COLLECT_OMS_ORDERS_REQ,
  CONFIRM_OAUTH_SHOPPING_MALL_REQ,
  CONFIRM_OAUTH_SHOPPING_MALL_RES,
  GET_CAFE24_MULTI_SHOPPING_MALL_LIST_RES,
  GET_LINKED_SHIPPING_MALL_LIST_RES,
  GET_ORDER_ONE_REQ,
  GET_ORDER_ONE_RES,
  GET_SELLER_CREDENTIAL_AUTH_KEY,
  GET_SELLER_CREDENTIAL_DETAIL_RES,
  GET_SELLER_CREDENTIAL_LIST_RES,
  GET_SELLER_LIST_REQ,
  GET_SELLER_ORDER_DETAIL_REQ_PATH_PARAMS,
  GET_SELLER_ORDER_DETAIL_RES,
  GET_SELLER_ORDER_LIST_REQ,
  GET_SELLER_ORDER_LIST_RES,
  GET_SELLER_SKU_LIST_REQ,
  GET_SELLER_SKU_LIST_RES,
  LINK_SELLER_CREDENTIAL_REQ,
  LINK_SELLER_CREDENTIAL_RES,
  LINK_SELLER_SKU_REQ,
  LINK_SELLER_SKU_REQ_PATH_PARAMS,
  REFRESH_SELLER_SKU_LIST_RES,
  UNLINK_SELLER_SKU_RES,
  UPDATE_SELLER_CREDENTIAL_REQ,
  UPDATE_SELLER_CREDENTIAL_RES,
} from "api-interfaces/order";

import { useAppMutation, useAppQueryWithQueryKeyFactory } from "services/query";
import { LinkedItem, MallOptions } from "types/order";

export const ORDER_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/ORDER_QUERY" }] as const,
  sellerCredentialList: () => [{ ...ORDER_QUERY_KEY_GEN.all()[0] }] as const,
  getSellerCredentialList: (params: GET_SELLER_LIST_REQ) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.sellerCredentialList()[0],
        ...params,
        entity: "getSellerCredentialList",
      },
    ] as const,
  getSellerCredentialDetail: (params: { id: string | null }) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.sellerCredentialList()[0],
        ...params,
        entity: "getSellerCredentialDetail",
      },
    ] as const,
  getSellerCredentialAuthKey: (params: { mallType: MallOptions }) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getSellerCredentialAuthKey",
      },
    ] as const,
  getLinkedShoppingMallList: (params: { teamId: number | undefined }) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getLinkedShoppingMallList",
      },
    ] as const,
  getCafe24MultiShoppingMallList: (params: { mallId: string }) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getCafe24MultiShoppingMallList",
      },
    ] as const,
  getSellerSkuList: (params: GET_SELLER_SKU_LIST_REQ) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getSellerSkuList",
      },
    ] as const,
  getSellerOrderList: (params: GET_SELLER_ORDER_LIST_REQ) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getSellerOrderList",
      },
    ] as const,
  getSellerOrderDetail: (params: GET_SELLER_ORDER_DETAIL_REQ_PATH_PARAMS) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getSellerOrderDetail",
      },
    ] as const,
  getOrderOne: (params: GET_ORDER_ONE_REQ) =>
    [
      {
        ...ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getOrderOne",
      },
    ] as const,
};

function useGetSellerCredentialList(params: GET_SELLER_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getSellerCredentialList>,
    GET_SELLER_CREDENTIAL_LIST_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getSellerCredentialList(params),
    requestOptions: {
      method: "get",
      path: "/seller-credential",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "쇼핑몰 연동 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetSellerCredentialDetail({
  id,
  onSuccess,
}: {
  id: string | null;
  onSuccess?: (res: GET_SELLER_CREDENTIAL_DETAIL_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getSellerCredentialDetail>,
    GET_SELLER_CREDENTIAL_DETAIL_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getSellerCredentialDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/seller-credential/${id}`,
      apiType: "BofulDefault",
    },
    enabled: !!id,

    onSuccess: (res) => onSuccess && onSuccess(res),
  });

  return { ...queryResult };
}

function useLinkSellerCredential() {
  const queryClient = useQueryClient();

  const successMessage = {
    messageType: "titleAndBody" as const,
    title: "쇼핑몰 연동 성공",
    body: (
      <>
        정상적으로 연동되었습니다.
        <br />
        상품연동/해제 탭에서 쇼핑몰에서 등록한 상품과
        <br />
        쉽다에서 등록한 상품을 연동해보세요.
      </>
    ),
  };

  const onSuccess = () => {
    queryClient.invalidateQueries(ORDER_QUERY_KEY_GEN.sellerCredentialList());
  };

  const mutation = useAppMutation<
    LINK_SELLER_CREDENTIAL_REQ,
    LINK_SELLER_CREDENTIAL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/seller-credential",
      apiType: "BofulDefault",
    },

    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        onSuccess();
      },

      customizeMessage: () => successMessage,
    },

    failureModalInfo: {
      customizeMessage: () => ({
        messageType: "titleAndBody" as const,
        title: "쇼핑몰 연동 실패",
        body: (
          <>
            채널 연동 요청 중 오류가 발생했습니다.
            <br />
            고객센터에 문의해주세요.
          </>
        ),
        actionPositiveLabel: "재입력",
        isWebNegativeActionPositive: true,
      }),
    },
  });

  return { ...mutation };
}

function useUnLinkSellerCredential({ id }: { id: string | null }) {
  const mutation = useAppMutation<void, LinkedItem>({
    requestOptions: {
      method: "delete",
      path: `/seller-credential/${id}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "쇼핑몰 연동 해제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useUpdateSellerCredential({ id }: { id: string | null }) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<
    UPDATE_SELLER_CREDENTIAL_REQ,
    UPDATE_SELLER_CREDENTIAL_RES
  >({
    requestOptions: {
      method: "put",
      path: `/seller-credential/${id}`,
      apiType: "BofulDefault",
    },

    successModalInfo: {
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "정상적으로 수정완료되었습니다.",
      }),

      handleConfirmSuccess: (initQuery) => {
        initQuery();

        queryClient.invalidateQueries(
          ORDER_QUERY_KEY_GEN.sellerCredentialList()
        );
      },
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "쇼핑몰 정보 수정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetSellerCredentialAuthKey({
  mallType,
}: {
  mallType: MallOptions;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getSellerCredentialAuthKey>,
    GET_SELLER_CREDENTIAL_AUTH_KEY
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getSellerCredentialAuthKey({ mallType }),
    requestOptions: {
      method: "get",
      path: `/seller-credential/${mallType}/authKey`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "쇼핑몰 연동 정보 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetLinkedShoppingMallList({
  teamId,
  enabled,
}: {
  teamId: number | undefined;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getLinkedShoppingMallList>,
    GET_LINKED_SHIPPING_MALL_LIST_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getLinkedShoppingMallList({ teamId }),
    requestOptions: {
      method: "get",
      path: `/seller-credential/integration/status/${teamId}`,
      apiType: "BofulDefault",
    },

    enabled,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "쇼핑몰 연동 현황 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetCafe24MultiShoppingMallList({
  mallId,
  enabled,
  onSuccess,
}: {
  mallId: string;
  enabled?: boolean;
  onSuccess?: (res: GET_CAFE24_MULTI_SHOPPING_MALL_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getCafe24MultiShoppingMallList>,
    GET_CAFE24_MULTI_SHOPPING_MALL_LIST_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getCafe24MultiShoppingMallList({ mallId }),
    requestOptions: {
      method: "get",
      path: `/seller-credential/cafe24/mall`,
      apiType: "BofulDefault",
      params: { mallId },
    },

    enabled,

    onSuccess: (res) => onSuccess && onSuccess(res),

    failureModalInfo: {
      customizeMessage: () => ({
        title: "Cafe24 멀티 쇼핑몰 연동 현황 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useConfirmOAuthShoppingMall() {
  const mutation = useAppMutation<
    CONFIRM_OAUTH_SHOPPING_MALL_REQ,
    CONFIRM_OAUTH_SHOPPING_MALL_RES
  >({
    requestOptions: {
      method: "patch",
      path: "/seller-credential/integration",
      apiType: "BofulDefault",
    },

    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
      },
    },
  });

  return { ...mutation };
}

function useGetSellerSkuList({ params }: { params: GET_SELLER_SKU_LIST_REQ }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getSellerSkuList>,
    GET_SELLER_SKU_LIST_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getSellerSkuList({ ...params }),
    requestOptions: {
      method: "get",
      path: "/seller-sku",
      apiType: "BofulDefault",
      params,
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: "상품 연동 목록 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useRefreshSellerSkuList({
  isOverseas,
  onSuccess,
}: {
  isOverseas: boolean;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<void, REFRESH_SELLER_SKU_LIST_RES>({
    requestOptions: {
      method: "post",
      // body 요청이 아니라 쿼리파람 요청이라서 프론트에서 맞춰줌
      path: `/seller-sku/update?isOverseas=${isOverseas}`,
      apiType: "BofulDefault",
    },
    onSuccess,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "상품 업데이트에 실패했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useLinkSellerSku({ onSuccess }: { onSuccess: () => void }) {
  const mutation = useAppMutation<
    LINK_SELLER_SKU_REQ,
    REFRESH_SELLER_SKU_LIST_RES,
    LINK_SELLER_SKU_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: (pathParams) => `/seller-sku/link/${pathParams.id}`,
      apiType: "BofulDefault",
    },
    onSuccess,
    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 404) {
          return { title: "존재하지 않는 상품입니다." };
        }

        if (failureInfo?.code === 400) {
          return {
            title: "이미 연동된 SKU가 있습니다.",
          };
        }

        return {
          title: "SKU ID 연동 중에 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

function useUnLinkSellerSku({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<unknown, UNLINK_SELLER_SKU_RES>({
    requestOptions: {
      method: "patch",
      path: `/seller-sku/unlink/${id}`,
      apiType: "BofulDefault",
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ORDER_QUERY_KEY_GEN.getSellerSkuList({}));
      onClose();
    },
    failureModalInfo: {
      handleConfirmFailure: onClose,
      customizeMessage: () => ({
        title: "SKU ID 연동 해제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetSellerOrderList({
  params,
}: {
  params: GET_SELLER_ORDER_LIST_REQ;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getSellerOrderList>,
    GET_SELLER_ORDER_LIST_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getSellerOrderList({ ...params }),
    requestOptions: {
      method: "get",
      path: `/seller-order`,
      apiType: "BofulDefault",
      params,
    },
    failureModalInfo: {
      customizeMessage() {
        return {
          title: "주문 조회에 실패했습니다.",
        };
      },
    },
  });

  return { ...queryResult };
}

function useGetSellerOrderDetail({
  id,
  onClose,
}: {
  id: string;
  onClose: () => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getSellerOrderDetail>,
    GET_SELLER_ORDER_DETAIL_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getSellerOrderDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/seller-order/${id}`,
      apiType: "BofulDefault",
    },
    failureModalInfo: {
      handleConfirmFailure: onClose,
      customizeMessage() {
        return {
          title: "주문 상세 조회에 실패했습니다.",
        };
      },
    },
  });

  return { ...queryResult };
}

function useRefreshSellerOrderList({
  handleRetryAfter,
}: {
  handleRetryAfter: (second: number) => void;
}) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<unknown, { result: "ok" }>({
    requestOptions: {
      method: "post",
      path: "/seller-order",
      apiType: "BofulDefault",
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: "주문 목록을 새로고침 하는 중에 오류가 발생했습니다.",
      }),
    },
    onError: (failureInfo, hideFailureModal) => {
      const { retryAfter } = failureInfo as unknown as { retryAfter: number };

      if (!retryAfter) return;

      handleRetryAfter(retryAfter);
      hideFailureModal();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ORDER_QUERY_KEY_GEN.getSellerOrderList({}));
    },
  });

  return { ...mutation };
}

function useGetOrderOne({
  mall,
  teamId,
  orderId,
  enabled,
}: GET_ORDER_ONE_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ORDER_QUERY_KEY_GEN.getOrderOne>,
    GET_ORDER_ONE_RES
  >({
    queryKey: ORDER_QUERY_KEY_GEN.getOrderOne({ mall, teamId, orderId }),
    requestOptions: {
      method: "get",
      path: `/seller-order/order`,
      apiType: "BofulDefault",
      params: {
        mall,
        teamId,
        orderId,
      },
    },
    enabled,
    failureModalInfo: {
      customizeMessage(failureInfo) {
        const message =
          failureInfo?.code === 404 &&
          failureInfo?.error === "requested seller does not exists"
            ? "요청하신 쇼핑몰을 연동중인 화주가 아닙니다"
            : failureInfo?.code === 404 &&
              failureInfo?.error === "requested order does not exists"
            ? "해당 주문번호에 해당하는 주문이 수집되지않았습니다"
            : "주문 단건 조회에 실패하였습니다";
        return {
          title: message,
        };
      },
    },
  });

  return { ...queryResult };
}

function useCollectOmsOrders() {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<COLLECT_OMS_ORDERS_REQ, void>({
    requestOptions: {
      method: "post",
      path: `/seller-order/collect-by-date`,
      apiType: "BofulDefault",
    },
    failureModalInfo: {
      customizeMessage: (err) => {
        console.log(err);
        return err?.code === 429
          ? {
              title: "같은 화주쇼핑몰은 5분 안에 두번 요청할 수 없습니다.",
            }
          : {
              title: "주문 수집 요청 중에 오류가 발생했습니다.",
            };
      },
    },
  });

  return { ...mutation };
}

const ORDER_QUERY = {
  useGetSellerCredentialList,
  useGetSellerCredentialDetail,
  useLinkSellerCredential,
  useUnLinkSellerCredential,
  useUpdateSellerCredential,
  useGetSellerCredentialAuthKey,
  useGetLinkedShoppingMallList,
  useGetCafe24MultiShoppingMallList,
  useConfirmOAuthShoppingMall,
  useGetSellerSkuList,
  useRefreshSellerSkuList,
  useLinkSellerSku,
  useUnLinkSellerSku,
  useGetSellerOrderList,
  useGetSellerOrderDetail,
  useRefreshSellerOrderList,
  useGetOrderOne,
  useCollectOmsOrders,
};
export default ORDER_QUERY;

import styled from "styled-components";

import { setFontStyleV2 } from "_sds-v2/styles/typography";

import { setFontStyle } from "styles/typography";
import { COLOR } from "styles/constants";

const shippingOrder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const warningMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${setFontStyleV2("Body8")};
  color: ${COLOR.pointWarning};
`;

const managementDate = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const shippingOrderInputContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 4px;
`;

const shippingOrderInput = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  // 디자인 요구사항
  .custom-button {
    min-width: 0;
  }
`;

const shippingOrderCell = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  // 디자인 요구사항
  .custom-button {
    min-width: 0;
  }
`;

const changedItem = styled.strong`
  ${setFontStyle("SubHead3", "Bold")};
  color: ${COLOR.primaryBlue};
`;

export default {
  shippingOrder,
  warningMessage,
  managementDate,
  shippingOrderInputContainer,
  shippingOrderInput,
  shippingOrderCell,
  changedItem,
};

import { Box, Button, Paper } from "@mui/material";
import styled from "styled-components";
import { setFontStyle } from "styles/typography";
import { ReturningListItem } from "types/returning";
import { toFormattedDate } from "utils/date";
import { getReturningStatusLabel } from "utils/returning";
import useRequestPickupUncollected from "../useRequestPickupUncollected";

const Styled = {
  SummaryBlock: styled.div`
    em {
      margin-left: 4px;
      ${setFontStyle("Body6", "Bold")};
    }

    ul {
      display: flex;
    }

    ul > li {
      &::after {
        content: "|";
        padding: 0 16px;
      }

      &:last-child::after {
        display: none;
      }
    }
  `,

  StatusAndDateContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    ${setFontStyle("SubHead2", "Bold")};

    .status {
      > span {
        margin-left: 8px;
        margin-right: 4px;
      }
    }

    .create-at {
      ${setFontStyle("SubHead3")};
    }
  `,

  /* background-color: ${COLOR.grayScale_100}; */
  IdAndQuantityContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    padding: 16px;
    border-radius: 2px;
    gap: 8px;

    ${setFontStyle("SubHead2")};
  `,
};

export default function UpperSummary({
  returningDetail,
}: {
  returningDetail: ReturningListItem;
}) {
  const { createdAt, id, shippingId } = returningDetail;

  const {
    openConfirmToRequestPickupUncollected,
    ConfirmToRequestPickupUncollected,
  } = useRequestPickupUncollected();

  const formattedReturningStatus = getReturningStatusLabel(returningDetail);

  const totalOrderQuantity =
    returningDetail.shipping?.orders.reduce(
      (prev, curr) => curr.quantity + prev,
      0
    ) || 0;

  const totalShippingQuantity =
    returningDetail.shipping?.items.reduce(
      (prev, curr) => curr.quantity + prev,
      0
    ) || 0;

  const returningQuantity =
    returningDetail.items.reduce((prev, curr) => curr.quantity + prev, 0) || 0;

  const getFontThemeByStatus = (returningStatus: string) => {
    switch (returningStatus) {
      case "입고완료":
        return "primary";

      case "검수완료(이슈)":
      case "전담매니저 확인 중":
      case "미집화":
        return "warning";

      default:
        return "default";
    }
  };

  return (
    <Styled.SummaryBlock>
      <Styled.StatusAndDateContainer>
        <div className="status">
          반품현황
          <Box
            component={"span"}
            sx={{
              color: `${getFontThemeByStatus(formattedReturningStatus)}.main`,
            }}
          >
            {formattedReturningStatus}
          </Box>
          {formattedReturningStatus === "미집화" && (
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                openConfirmToRequestPickupUncollected({
                  returningId: id,
                  shippingId,
                })
              }
            >
              수거 재요청
            </Button>
          )}
        </div>

        <span className="create-at">
          반품접수일: {toFormattedDate(createdAt, "YYYY-MM-DD")}
        </span>
      </Styled.StatusAndDateContainer>

      <Paper elevation={2}>
        <Styled.IdAndQuantityContainer>
          <ul>
            <li>
              반품요청번호:<em>R{id}</em>
            </li>

            <li>
              출고요청번호:<em>{shippingId}</em>
            </li>
          </ul>

          <ul>
            <li>
              전체 주문수량(ORDER):<em>{totalOrderQuantity}</em>
            </li>

            <li>
              전체 출고수량(PCS):<em>{totalShippingQuantity}</em>
            </li>

            <li>
              반품요청수량(PCS):<em>{returningQuantity}</em>
            </li>
          </ul>
        </Styled.IdAndQuantityContainer>
      </Paper>
      {ConfirmToRequestPickupUncollected}
    </Styled.SummaryBlock>
  );
}

import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const detailInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 1184px;
`;

const imageAndQuantityListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin: 8px 0 40px 0;
`;

const quantityListContainer = styled.div`
  width: 572px;
  display: flex;
  flex-direction: column;

  > .divider {
    width: 100%;
    border-bottom: 1px solid ${COLOR.grayScale_300};
    margin: 8px 0;
  }

  .label-and-tooltip {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .warning-quantity {
    color: ${COLOR.pointWarning};
  }
`;

const problemQuantityAndSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR.primaryBlue_10};
  padding: 16px;
  margin-bottom: 8px;

  .problem-quantity {
    display: flex;
    gap: 8px;

    .label {
      ${setFontStyle("SubHead3", "Bold")};
      color: ${COLOR.grayScale_700};
    }

    em {
      ${setFontStyle("Body7")};
      color: ${COLOR.pointWarning};
    }
  }

  .select-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const inspectionProblemLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  time {
    ${setFontStyle("Body7")};
    color: ${COLOR.grayScale_700};
  }
`;

const inspectionProblemComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .warning-message {
    display: flex;
    align-items: center;
    gap: 4px;

    ${setFontStyle("Body7")};
    color: ${COLOR.pointWarning};
  }
`;

export default {
  detailInfoContainer,
  imageAndQuantityListContainer,
  quantityListContainer,
  problemQuantityAndSelect,
  inspectionProblemLabel,
  inspectionProblemComplete,
};

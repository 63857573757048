import { DOMESTIC_MALL_OPTION_LIST } from "constants/shipping";

import useTableHeadFilter from "hooks/useTableHeadFilterForBofulAdminOnly";

export default function useDomesticMallFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: DomesticMallFilterPanel,
    filterList: domesticMallFilterList,
    resetFilterState: resetDomesticMallFilter,
  } = useTableHeadFilter({
    filterOptionList: DOMESTIC_MALL_OPTION_LIST,
    resetCurrentPage,
  });

  return {
    DomesticMallFilterPanel,
    domesticMallPayloadListByFilter: domesticMallFilterList,
    resetDomesticMallFilter,
  };
}

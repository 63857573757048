import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import MATERIAL_QUERY from "queries/MATERIAL_QUERY";
import { useCallback, useMemo, useState } from "react";

import { BofulTeam } from "types/user";
import {
  getFormattedMaterialId,
  getPackageCategoryName,
  getPackageTypeName,
} from "utils/fulfillment";

import { PackageCategory, PackageType } from "types/fulfillment";
import Detail, { DetailProps } from "../Detail/Detail";
import useMaterialImageModal from "../useMaterialImageModal";

export default function usePackageDetailModal() {
  const [materialId, setMaterialId] = useState<number>(0);
  const [team, setTeam] = useState<BofulTeam | null>(null);

  const active = !!materialId;

  const { data: materialDetail } = MATERIAL_QUERY.useGetMaterialDetail({
    enabled: !!materialId,
    materialId,
  });

  const handleModalClose = useCallback(() => {
    setMaterialId(0);
    setTeam(null);
  }, []);

  const showPackageDetailModal = useCallback(
    (materialId: number, team: BofulTeam) => {
      setMaterialId(materialId);
      setTeam(team);
    },
    []
  );

  const { handleMaterialImageModalOpen, MaterialImageModal } =
    useMaterialImageModal();
  const PackagetDetailModal = useMemo(() => {
    const basicDetail: DetailProps = {
      title: "기본정보",
      detailList: [
        {
          label: "부자재명",
          value: materialDetail?.material.name,
        },
        {
          label: "회사명",
          value: team?.company,
        },
        {
          label: "팀명",
          value: team?.name,
        },
        {
          label: "구분/종류",
          value: materialDetail?.material.typeDirectInput
            ? materialDetail?.material.typeDirectInput
            : `${getPackageCategoryName(
                materialDetail?.material.packageCategory as PackageCategory
              )}/${getPackageTypeName(
                materialDetail?.material.packageType as PackageType
              )}`,
        },
        {
          label: "판매자부자재코드",
          value: materialDetail?.material.materialCode,
        },
        {
          label: "옵션관리코드",
          value: materialDetail?.material.managementCode,
        },
        {
          label: "부자재이미지",
          value: (
            <Button
              variant="outlined"
              size="small"
              sx={{ ml: 1 }}
              disabled={
                !materialDetail?.attachment ||
                materialDetail?.attachment.length === 0
              }
              onClick={() => {
                if (materialDetail?.material) {
                  handleMaterialImageModalOpen({
                    ...materialDetail.material,
                    attachment: materialDetail.attachment,
                  });
                }
              }}
            >
              이미지
            </Button>
          ),
        },
      ],
    };

    const additionalDetail: DetailProps = {
      title: "부가정보",
      detailList: [
        {
          label: "가로",
          value: materialDetail?.material.width || "-",
        },
        {
          label: "세로",
          value: materialDetail?.material.length || "-",
        },
        {
          label: "높이/날개",
          value: materialDetail?.material.height || "-",
        },
        {
          label: "최대적재중량",
          value: materialDetail?.material.maximumLoadWeight,
        },
      ],
    };

    const buyingDetail: DetailProps = {
      title: "구매처 정보",
      detailList: [
        {
          label: "구매처",
          value: materialDetail?.material.buyer?.company,
        },
        {
          label: "구매처 URL",
          value: materialDetail?.material.buyingURL,
        },
      ],
    };

    return (
      <>
        <Dialog
          open={active}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleModalClose}
        >
          <DialogContent dividers>
            <Stack spacing={4}>
              <Typography variant="h6">
                {materialDetail?.material
                  ? getFormattedMaterialId(materialDetail?.material)
                  : ""}{" "}
                상세 정보
              </Typography>
              <Divider />
              <Stack gap={3}>
                <Detail {...basicDetail} />
                <Detail {...additionalDetail} />
                <Detail {...buyingDetail} />
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        <MaterialImageModal />
      </>
    );
  }, [
    MaterialImageModal,
    active,
    handleMaterialImageModalOpen,
    handleModalClose,
    materialDetail,
    team,
  ]);

  return {
    showPackageDetailModal,
    PackagetDetailModal,
  };
}

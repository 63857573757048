import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Stack, TextField } from "@mui/material";

import { UPDATE_TEAM_SENDING_DETAIL_REQ } from "api-interfaces/user";
import USER_QUERY, { USER_QUERY_KEY_GEN } from "queries/USER_QUERY";

import Detail, { DetailProps } from "pages/user/Detail";

export default function Sending({ teamId }: { teamId: number | undefined }) {
  const [
    {
      shipperName,
      shipperEngName,
      shipperAddress,
      shipperPhone,
      shipperDetailAddress,
      shipperPostalCode,
    },
    setTeamSendingDetail,
  ] = useState<UPDATE_TEAM_SENDING_DETAIL_REQ>({
    shipperName: "",
    shipperEngName: "",
    shipperAddress: "",
    shipperPhone: "",
    shipperDetailAddress: "",
    shipperPostalCode: "",
  });
  const [isEditable, setIsEditable] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: teamSendingDetail,
    ResponseHandler: ResponseHandlerOfTeamSendingDetail,
  } = USER_QUERY.useGetTeamSendingDetail({
    teamId,
    enabled: !!teamId,
  });

  const {
    mutate: updateTeamSendingDetail,
    ResponseHandler: ResponseHandlerOfUpdateTeamSendingDetail,
  } = USER_QUERY.useUpdateTeamSendingDetail();

  const setDefaultTeamSendingDetail = useCallback(() => {
    if (teamSendingDetail) {
      setTeamSendingDetail({
        shipperName: teamSendingDetail.shipperName ?? "",
        shipperEngName: teamSendingDetail.shipperEngName ?? "",
        shipperAddress: teamSendingDetail.shipperAddress ?? "",
        shipperPhone: teamSendingDetail.shipperPhone ?? "",
        shipperDetailAddress: teamSendingDetail.shipperDetailAddress ?? "",
        shipperPostalCode: teamSendingDetail.shipperPostalCode ?? "",
      });
    }
  }, [teamSendingDetail]);

  useEffect(() => {
    setDefaultTeamSendingDetail();
  }, [setDefaultTeamSendingDetail]);

  const handleEditModeOpen = () => {
    setIsEditable(true);
  };

  const handleEditModeClose = () => {
    setIsEditable(false);

    setDefaultTeamSendingDetail();
  };

  const handleItemChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTeamSendingDetail((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTeamSendingDetailUpdate = () => {
    updateTeamSendingDetail(
      {
        pathParams: { teamId },
        shipperName,
        shipperEngName,
        shipperAddress,
        shipperPhone,
        shipperDetailAddress,
        shipperPostalCode,
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(
              USER_QUERY_KEY_GEN.getTeamSendingDetail({ teamId })
            )
            .then(() => setIsEditable(false));
        },
      }
    );
  };

  const sendingDetail: DetailProps = {
    title: "발송 정보",
    description: "반품 수령 시 해당 정보가 수취인 정보로 기입됩니다.",
    detailList: [
      {
        label: "발송인",
        value: shipperName,
        editComponent: (
          <TextField
            size="small"
            value={shipperName}
            name="shipperName"
            onChange={handleItemChange}
          />
        ),
      },
      {
        label: "우편번호",
        value: shipperPostalCode,
        editComponent: (
          <TextField
            size="small"
            value={shipperPostalCode}
            name="shipperPostalCode"
            onChange={handleItemChange}
          />
        ),
      },
      {
        label: "발송인 영문명",
        value: shipperEngName,
        editComponent: (
          <TextField
            size="small"
            value={shipperEngName}
            name="shipperEngName"
            onChange={handleItemChange}
          />
        ),
      },
      {
        label: "주소",
        value: shipperAddress,
        editComponent: (
          <TextField
            size="small"
            value={shipperAddress}
            name="shipperAddress"
            onChange={handleItemChange}
          />
        ),
      },

      {
        label: "연락처",
        value: shipperPhone,
        editComponent: (
          <TextField
            size="small"
            value={shipperPhone}
            name="shipperPhone"
            onChange={handleItemChange}
          />
        ),
      },
      {
        label: "상세주소",
        value: shipperDetailAddress,
        editComponent: (
          <TextField
            size="small"
            value={shipperDetailAddress}
            name="shipperDetailAddress"
            onChange={handleItemChange}
          />
        ),
      },
    ],
    isEditable,
  };

  return (
    <>
      <Detail {...sendingDetail} />

      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        sx={{ mt: 2 }}
      >
        {isEditable ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEditModeClose}
            >
              취소
            </Button>
            <Button variant="contained" onClick={handleTeamSendingDetailUpdate}>
              저장
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={handleEditModeOpen}>
            수정
          </Button>
        )}
      </Stack>

      {ResponseHandlerOfTeamSendingDetail}
      {ResponseHandlerOfUpdateTeamSendingDetail}
    </>
  );
}

import Modal from "components/sds-v1/Modal";

import { ImageInfo } from "../index";
import ImageDetailWithTitle from "./ImageDetailWithTitle";
import OnlyImageDetail from "./OnlyImageDetail";
import Styled from "./index.styles";

export default function DetailModal({
  imageList,
  currentSlideKey,
  opensImageDetailModal,
  closeDetailModal,
}: {
  imageList: ImageInfo[];
  currentSlideKey: string;
  opensImageDetailModal: boolean;
  closeDetailModal: () => void;
}) {
  const hasTitle = imageList.some((item) => item.title);

  return (
    <Modal
      uiType="contentWithCustomBody"
      active={opensImageDetailModal}
      title={"사진 자세히 보기"}
      body={
        <Styled.detailContainer hasTitle={hasTitle}>
          {hasTitle ? (
            <ImageDetailWithTitle
              imageList={imageList}
              currentSlideKey={currentSlideKey}
            />
          ) : (
            <OnlyImageDetail
              imageList={imageList}
              currentSlideKey={currentSlideKey}
            />
          )}
        </Styled.detailContainer>
      }
      onClose={closeDetailModal}
    />
  );
}

import { BofulAuthority } from "types/auth";
import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
  LOCAL_PRINTER_URL,
  NETWORK_PRINTER_FOR_CJ_URL,
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
} from "../constants/common";
import { toFormattedDate } from "./date";
import { SellernoteAPIType } from "types/common";
import {
  MaterialItem,
  PackageCategory,
  PackageType,
  ProviderType,
} from "types/fulfillment";
import { Dispatch, SetStateAction } from "react";

function printBuildInfo() {
  if (IS_UNDER_PRODUCTION) return;

  if (!APP_BUILD_INFO) return;

  console.debug("####################### BUILD INFO #######################");
  console.debug(
    `####### BuiltAt: ${toFormattedDate(
      APP_BUILD_INFO.builtAt,
      "YYYY.MM.DD HH:mm:ss Z"
    )}`
  );
  console.debug(`####### Branch: ${APP_BUILD_INFO.gitBranch}`);
  console.debug(`####### Commit ${APP_BUILD_INFO.gitCommitSha}`);
  console.debug("##########################################################");
}

/**
 * 현재는 Contents의 경우만 baseUrl이 구분되지만, 추후 더 늘아늘 것이라고 함
 */
function getBaseURLByAPIType(apiType?: SellernoteAPIType) {
  if (apiType === "LocalPrinter") {
    return LOCAL_PRINTER_URL;
  }

  if (apiType === "NetworkPrinterForHanjinAndOverseas") {
    return NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL;
  }

  if (apiType === "NetworkPrinterForCJ") {
    return NETWORK_PRINTER_FOR_CJ_URL;
  }

  return process.env.REACT_APP_API_URL;
}

/**
 * 빈 객체나 빈 배열 체크.
 */
function isEmptyObjectOrArray(param: Array<any> | { [key: string]: any }) {
  return Object.keys(param).length === 0;
}

function isNotEmptyObjectOrArray(param: Array<any> | { [key: string]: any }) {
  return !isEmptyObjectOrArray(param);
}

const noop = () => {};

function getGroupedSearchListByProperty({
  pageType,
  searchList,
}: {
  pageType: "singleSku" | "groupSku" | "material" | "receiving" | "shipping";
  searchList: {
    searchKind: string;
    searchTerm: string;
  }[];
}) {
  return searchList.reduce<{
    [key: string]: string[];
  }>((acc, obj) => {
    const searchKind = (() => {
      if (obj.searchKind === "company") {
        return "companyNames";
      }

      if (pageType === "receiving" && obj.searchKind === "itemName") {
        return "productNames";
      }

      return `${obj.searchKind}s`;
    })();

    if (!acc[searchKind]) {
      return {
        ...acc,
        [searchKind]: [obj.searchTerm],
      };
    }

    return {
      ...acc,
      [searchKind]: [...acc[searchKind], obj.searchTerm],
    };
  }, {});
}

/**
 * Form에 InputText가 여러 개 있는 경우 setValue할 때 사용하기 위한 유틸함수
 */
const handleFormChange =
  <FormState, Key extends keyof FormState>(
    setFormState: Dispatch<SetStateAction<FormState>>
  ) =>
  (key: Key) =>
  (value: FormState[Key]) =>
    setFormState((prevFormState) => ({ ...prevFormState, [key]: value }));

/**
 * source) https://sindresorhus.com/multi-download/
 */
const downloadMultiFile = async (urlList: string[]) => {
  for (const [index, url] of urlList.entries()) {
    // 다중 파일 다운로드 시 가장 마지막 요청 이외에는 취소되는 이슈로 인해 딜레이를 줌
    await delay(index * 1000);
    downloadFile(url);
  }
};

const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const downloadFile = async (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.style.display = "none";

  document.body.append(a);
  a.click();

  // multi-download 라이브러리 기준 크롬 이슈로 인해 딜레이를 줌
  await delay(100);

  a.remove();
};

/**
 * 객체의 빈 속성 제거
 */
function removeEmptyPropertiesOfObject(object: { [key: string]: any }) {
  Object.keys(object).forEach((key) => {
    getTypeToString(object[key]) === "Object"
      ? isEmptyObjectOrArray(object[key]) && delete object[key]
      : !object[key] && delete object[key];
  });

  return object;
}

/**
 * 타입을 문자열로 반환
 * 'String', 'Number', 'Boolean', 'Object'.. 등등 앞글자 대문자로 반환
 */
function getTypeToString(value: any) {
  return Object.prototype.toString.call(value).slice(8, -1);
}

/**
 * 총 페이지 개수를 계산하여 반환
 * @param pageUnit - 페이지 당 아이템 개수
 * @param totalSize - 총 아이템 개수
 * @returns 총 페이지 개수
 */
function getPageSize(pageUnit: number, totalSize?: number) {
  if (pageUnit && totalSize) {
    let pageSize = totalSize / pageUnit;

    if (pageSize % 1 > 0) {
      pageSize = Math.floor(totalSize / pageUnit) + 1;
    }

    return pageSize;
  }

  return 1;
}

const checkUsesManagementDate = (item: { managementDate?: string }) =>
  Boolean(item.managementDate);

export {
  getTypeToString,
  removeEmptyPropertiesOfObject,
  printBuildInfo,
  getBaseURLByAPIType,
  isNotEmptyObjectOrArray,
  isEmptyObjectOrArray,
  noop,
  getGroupedSearchListByProperty,
  handleFormChange,
  downloadFile,
  delay,
  downloadMultiFile,
  getPageSize,
  checkUsesManagementDate,
};

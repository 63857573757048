import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";

import LocationHistory from "./LocationHistory";
import PrintLabel from "./PrintLabel";
import Summary from "./Summary";

export interface LocationSummaryData {
  locationId: number;
  barCode: string;
  warehouse: string;
}

export default function LocationDetail({
  locationData,
  onLocationDetailClose,
}: {
  locationData: LocationSummaryData | undefined;
  onLocationDetailClose: () => void;
}) {
  return (
    <Dialog
      open={!!locationData}
      maxWidth={false}
      onClose={onLocationDetailClose}
    >
      <DialogTitle>상세 보기</DialogTitle>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        p={2}
        pt={0}
      >
        <PrintLabel locationId={locationData?.locationId} />
      </Stack>

      <DialogContent dividers>
        <Summary
          barcode={locationData?.barCode}
          warehouse={locationData?.warehouse}
        />

        <LocationHistory locationData={locationData} />
      </DialogContent>
    </Dialog>
  );
}

import React from "react";

import Icon from "components/sds-v1/Icon";
import { ToggleFilterProps } from "headlessComponents/useToggleFilter";

import Styled from "./index.styles";

export default function ToggleFilter({
  label,
  checked,
  iconColor,
  iconBgColor,
  handleClick,
  className,
}: ToggleFilterProps) {
  return (
    <Styled.container
      className={`${className ? className : ""} filter`}
      onClick={handleClick}
    >
      {checked ? (
        <Icon
          type="checkStrokeCircleChecked"
          size={2}
          color={iconColor}
          bgColor={iconBgColor}
        />
      ) : (
        <Icon
          type="checkStrokeCircle"
          size={2}
          color={iconColor}
          bgColor={iconBgColor}
        />
      )}
      <span className="filter-label">{label}</span>
    </Styled.container>
  );
}

import { Fragment, memo } from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import { GroupedHeadRow, PinnedColumn, TableHeadCell } from "..";
import CheckboxHeadCell from "./CheckboxHeadCell";
import MemoizedHeadCell from "./MemoizedHeadCell";

function MemoizedTableHead<CellId extends string, GroupedHeadId = void>({
  groupedHeadRow,
  headCells,
  pinnedColumnList,

  isAllSelected,
  selectedCount,
  selectAll,
  unSelectAll,
}: {
  groupedHeadRow?: GroupedHeadRow<GroupedHeadId, CellId>;
  headCells: TableHeadCell<CellId>[];
  pinnedColumnList: PinnedColumn<CellId>[] | undefined;

  isAllSelected: boolean;
  selectedCount: number;
  selectAll: () => void;
  unSelectAll: () => void;
}) {
  return (
    <TableHead>
      {groupedHeadRow && (
        <TableRow>
          {/* 체크박스 컬럼의 그룹헤더 */}
          <TableCell
            align="center"
            sx={{
              position: "sticky",
              left: 0,
              zIndex: 10,
              backgroundColor: blue[50],
            }}
          />

          {groupedHeadRow.map((row, index) => {
            if (!row.headCellIds.length) {
              return null;
            }

            const hasPinned = row.headCellIds.some((headCellId) =>
              (pinnedColumnList ?? []).some(
                (pinnedColumn) => pinnedColumn.id === headCellId
              )
            );
            if (hasPinned) {
              if (!pinnedColumnList) {
                return null;
              }

              const leftPosition = pinnedColumnList.reduce((acc, cur, idx) => {
                const isPinnedGroupedHeadCell = pinnedColumnList.some(
                  (pinned) => pinned.id === row.headCellIds[idx]
                );
                if (isPinnedGroupedHeadCell && idx <= index) {
                  return acc + cur.prevColumnWidth;
                }

                return acc;
              }, 0);

              return (
                <Fragment key={`${row.id}`}>
                  <TableCell
                    align="center"
                    colSpan={pinnedColumnList.length}
                    sx={{
                      backgroundColor: blue[50],

                      position: "sticky",
                      left: leftPosition,
                      zIndex: 10,
                      borderRight: `1px solid ${grey[300]}`,
                    }}
                  />
                  <TableCell
                    align="center"
                    colSpan={row.headCellIds.length - pinnedColumnList.length}
                    sx={{
                      backgroundColor: blue[50],
                      fontWeight: 700,

                      borderRight: `1px solid ${grey[300]}`,
                    }}
                  >
                    {row.label}
                  </TableCell>
                </Fragment>
              );
            }

            return (
              <TableCell
                align="center"
                colSpan={row.headCellIds.length}
                key={`${row.id}`}
                sx={{
                  backgroundColor: blue[50],
                  fontWeight: 700,

                  borderRight: `1px solid ${grey[300]}`,
                }}
              >
                {row.label}
              </TableCell>
            );
          })}
        </TableRow>
      )}

      <TableRow>
        <CheckboxHeadCell
          hasGroupedHeadRow={!!groupedHeadRow}
          isAllSelected={isAllSelected}
          selectedCount={selectedCount}
          selectAll={selectAll}
          unSelectAll={unSelectAll}
        />

        {headCells.map((headCell, index) => {
          const leftPosition = (pinnedColumnList ?? []).reduce(
            (acc, cur, idx) => {
              const isPinnedHeadCell = pinnedColumnList?.some(
                (pinnedColumn) => pinnedColumn.id === headCell.id
              );
              if (isPinnedHeadCell && idx <= index) {
                return acc + cur.prevColumnWidth;
              }

              return acc;
            },
            0
          );

          const isPinned = !!leftPosition;

          const isLastPinnedCell =
            (pinnedColumnList ?? []).length - 1 === index;

          return isPinned ? (
            <MemoizedHeadCell
              key={headCell.id}
              label={headCell.label}
              disablePadding={headCell.disablePadding}
              width={headCell.width}
              filter={headCell.filter}
              hasGroupedHeadRow={!!groupedHeadRow}
              isPinned={isPinned}
              leftPosition={leftPosition}
              isLastPinnedCell={isLastPinnedCell}
            />
          ) : (
            <MemoizedHeadCell
              key={headCell.id}
              label={headCell.label}
              disablePadding={headCell.disablePadding}
              width={headCell.width}
              filter={headCell.filter}
              hasGroupedHeadRow={!!groupedHeadRow}
              isPinned={isPinned}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default memo(MemoizedTableHead);

import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ORDER_QUERY from "queries/ORDER_QUERY";
import USER_QUERY from "queries/USER_QUERY";

import Layout from "containers/Layout";
import { MallOptions } from "types/order";
import { toFormattedDate } from "utils/date";

import Detail from "./Detail/Detail";

type SearchOrderFormType = {
  teamId: number;
  mall: MallOptions;
  orderId: string;
};

const supportedMalls: MallOptions[] = [
  "smartStore",
  "coupang",
  "eleven",
  "imweb",
  "cafe24",
  "godomall",
  "kakaoStore",
  "10x10",
  "Shopify",
  "gmarket",
  "auction",
];

export default function OmsSingleOrderPage() {
  const orderSearchForm = useForm<SearchOrderFormType>();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const { data: teamList, ResponseHandler: ResponseHandlerOfGetTeamList } =
    USER_QUERY.useGetTeamList();

  const toggleIsEnabled = () => {
    setIsEnabled(!isEnabled);
  };

  const { data: order, ResponseHandler: ResponseHandlerOfGetOrderOne } =
    ORDER_QUERY.useGetOrderOne({
      ...orderSearchForm.getValues(),
      enabled: isEnabled,
    });

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.secondary"
        >
          OMS 주문 단건조회
        </Typography>

        {teamList && (
          <Stack gap={"10px"}>
            <Controller
              control={orderSearchForm.control}
              name={"teamId"}
              rules={{ required: true }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  onChange={(_, data) => onChange(data?.teamId)}
                  options={teamList.map((team) => ({
                    label: `${team.teamCompany || team.name}-${team.id}`,
                    teamId: team.id,
                  }))}
                  renderInput={(params) => <TextField {...params} label="팀" />}
                  disabled={isEnabled}
                />
              )}
            />

            <Controller
              control={orderSearchForm.control}
              name={"mall"}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="mall-input-label">쇼핑몰</InputLabel>
                  <Select
                    {...field}
                    labelId="mall-input-label"
                    label="쇼핑몰"
                    size={"medium"}
                    disabled={isEnabled}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {supportedMalls.map((mall) => (
                      <MenuItem value={mall} key={mall}>
                        {mall}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              control={orderSearchForm.control}
              name={"orderId"}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="orderId"
                  variant="outlined"
                  disabled={isEnabled}
                />
              )}
            />
            {isEnabled ? (
              <>
                <Button variant="outlined" onClick={toggleIsEnabled}>
                  초기화
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={toggleIsEnabled}
                  disabled={!orderSearchForm.formState.isValid}
                >
                  조회
                </Button>
              </>
            )}
          </Stack>
        )}
      </Paper>

      {order && isEnabled && (
        <Paper>
          <Detail
            title={"주문정보"}
            description={"OMS가 수집한 주문정보"}
            isEditable={false}
            detailList={[
              { label: "쇼핑몰", value: order.detailOrderState.mall },
              { label: "주문번호", value: order.detailOrderState.orderId },

              { label: "받는분", value: order.detailOrderState.receiver },
              {
                label: "받는분번호",
                value: order.detailOrderState.receiverPhone,
              },
              {
                label: "받는분주소",
                value: order.detailOrderState.receiverAddress,
              },
              { label: "국가코드", value: order.detailOrderState.countryCode },
              {
                label: "주문시간",
                value: toFormattedDate(
                  order.meta.orderedAt,
                  "YYYY-MM-DD HH:mm:ss"
                ),
              },
              { label: "", value: "" },
              {
                label: "수집시간",
                value: toFormattedDate(
                  order.meta.createdAt,
                  "YYYY-MM-DD HH:mm:ss"
                ),
              },
              { label: "", value: "" },
              {
                label: "최신화시간",
                value: toFormattedDate(
                  order.meta.updatedAt,
                  "YYYY-MM-DD HH:mm:ss"
                ),
              },
            ]}
          />
          <br />
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>상품명</TableCell>
                  <TableCell align="right">옵션명</TableCell>
                  <TableCell align="right">productId</TableCell>
                  <TableCell align="right">optionKey</TableCell>
                  <TableCell align="right">qty</TableCell>
                  <TableCell align="right">상태</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.detailOrderState.items.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.productName}
                    </TableCell>
                    <TableCell align="right">{row.optionName}</TableCell>
                    <TableCell align="right">{row.productId}</TableCell>
                    <TableCell align="right">{row.optionKey}</TableCell>
                    <TableCell align="right">{row.qty}</TableCell>
                    <TableCell align="right">{row.orderStatusName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />
          <span>원본 주문 데이터[dev]</span>
          <textarea
            style={{
              backgroundColor: "black",
              color: "greenyellow",
              width: "100%",
              resize: "none",
            }}
            readOnly={true}
            rows={Object.keys(order.meta.rawOrderSchema).length}
            value={JSON.stringify(order.meta.rawOrderSchema, null, 2)}
          />
        </Paper>
      )}

      {ResponseHandlerOfGetTeamList}
      {ResponseHandlerOfGetOrderOne}
    </Layout>
  );
}

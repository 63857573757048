import { useMemo } from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import usePrintInvoiceForList from "hooks/usePrintInvoiceForList";

export default function useInvoicePrintingModal({
  shippingIds,
  onModalClose,
}: {
  shippingIds?: number[];
  onModalClose: () => void;
}) {
  const canStartPrintingInvoice = !shippingIds?.length ? false : true;

  const {
    printInvoice,
    ResponseHandlerOfPrintInvoice,
    isRequestPrintingComplete,
    resetPrintInvoice,
  } = usePrintInvoiceForList();

  const ResultModalOfPrintInvoice = useMemo(() => {
    return (
      <Dialog open={isRequestPrintingComplete}>
        <DialogTitle>송장 출력 요청이 완료되었습니다.</DialogTitle>

        <DialogActions>
          <Button
            onClick={() => {
              onModalClose();
              resetPrintInvoice();
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [isRequestPrintingComplete, onModalClose, resetPrintInvoice]);

  const ModalOfPrintingInvoice = useMemo(() => {
    return (
      <>
        {ResultModalOfPrintInvoice}
        {ResponseHandlerOfPrintInvoice}
      </>
    );
  }, [ResponseHandlerOfPrintInvoice, ResultModalOfPrintInvoice]);

  return {
    canStartPrintingInvoice,
    printInvoice,
    ModalOfPrintingInvoice,
  };
}

import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { ShoppingCart } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import INVENTORY_QUERY from "queries/INVENTORY_QUERY";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { Inventory } from "types/sku";

import useAdjustTable from "./useAdjustTable";

const WAREHOUSE_ID = 1;

interface Props {
  shippingId: number | undefined;
  handleDetailModalClose: () => void;
}

const dialogTitleHeaderStyle: SxProps = {
  fontWeight: "bold",
  fontSize: "20px",
};

const dialogActionsStyle: SxProps = {
  display: "flex",
  justifyContent: "center",
  paddingBottom: "20px",
};

export default function useForceShipping({
  shippingId,
  handleDetailModalClose,
}: Props) {
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false);
  const [isVisibleForceShippingModal, setIsVisibleForceShippingModal] =
    useState(false);

  const [adminMemo, setAdminMemo] = useState("");

  const [locationIds, setLocationIds] = useState<number[]>([]);

  const queryClient = useQueryClient();

  const {
    data: shippingDetail,
    refetch: refetchShippingDetail,
    ResponseHandler: ResponseHandlerOfGetShippingDetail,
  } = SHIPPING_QUERY.useGetShippingDetail({
    id: shippingId,
    enabled: false,
    onSuccess: (shippingDetail) => {
      const locationIds = new Set<number>();

      shippingDetail.items?.forEach((shippingItem) => {
        shippingItem.sku.inventory.forEach((inventoryItem) => {
          if (!inventoryItem.locationId) {
            return;
          }

          locationIds.add(inventoryItem.locationId);
        });
      });

      setLocationIds([...locationIds]);
    },
  });

  const shippingItems = useMemo(() => shippingDetail?.items, [shippingDetail]);

  const {
    data: locationDetails,
    ResponseHandler: ResponseHandlerOfGetLocationDetails,
  } = INVENTORY_QUERY.useGetLocationDetailsByLocationIds({
    warehouseId: WAREHOUSE_ID,
    locationIds,
    enabled: !!locationIds.length,
    onSuccess: (locationDetails) => {
      createTableRows(locationDetails);
      handleForceShippingModalOpen();
    },
  });

  const {
    mutate: completeForceShipping,
    ResponseHandler: ResponseHandlerOfPostForceShipping,
  } = SHIPPING_QUERY.useForceShipping({
    onSuccess: () => {
      handleForceShippingModalClose();
      handleDetailModalClose();

      queryClient.invalidateQueries(
        SHIPPING_QUERY_KEY_GEN.getAdminShippingList({})
      );
    },
  });

  const {
    mutate: changeShippingItemsLocations,
    ResponseHandler: ResponseHandlerOfPatchShippingItemsLocations,
  } = SHIPPING_QUERY.useChangeShippingItemsLocations({
    onSuccess: () => {
      if (!shippingId) return;
      completeForceShipping({ shippingId, adminMemo });
    },
  });

  const {
    AdjustTable,
    AdjustTableTotal,
    createTableRows,
    resetTable,
    allInputData,
  } = useAdjustTable({
    shippingItems,
    locationDetails,
  });

  const handleForceShippingModalOpen = useCallback(() => {
    setIsVisibleForceShippingModal(true);
  }, []);

  const handleForceShippingModalClose = useCallback(() => {
    setIsVisibleForceShippingModal(false);
    setAdminMemo("");
    setLocationIds([]);
    resetTable();
  }, [resetTable]);

  const handleConfirmModalOpen = useCallback(() => {
    setIsVisibleConfirmModal(true);
  }, []);

  const handleConfirmModalClose = useCallback(() => {
    setIsVisibleConfirmModal(false);
  }, []);

  const handleForceShippingButtonClick = useCallback(() => {
    refetchShippingDetail();
  }, [refetchShippingDetail]);

  const handleSubmit = useCallback(() => {
    if (!shippingId) return;

    const validateLocationChange = () => {
      let hasLocationChanged = false;

      const skuIds = [
        ...new Set(shippingItems?.map((shippingItem) => shippingItem.skuId)),
      ];

      skuIds.forEach((skuId) => {
        if (hasLocationChanged) return;

        const matchingInputDataArray = allInputData.filter(
          (inputData) => inputData.skuId === skuId
        );
        const matchingShippingItems = shippingItems?.filter(
          (shippingItem) => shippingItem.skuId === skuId
        );

        matchingShippingItems?.forEach((matchingShippingItem) => {
          if (hasLocationChanged) return;

          const matchingInputData = matchingInputDataArray.find(
            (inputData) =>
              inputData.locationBarCode ===
              matchingShippingItem.location?.barCode
          );

          if (matchingInputData?.qty !== matchingShippingItem.quantity)
            hasLocationChanged = true;
        });
      });

      return hasLocationChanged;
    };

    const hasLocationChanged = validateLocationChange();

    if (hasLocationChanged) {
      const newRequestData = {
        shippings: [
          {
            shippingId,
            shippingItemInfos: allInputData,
          },
        ],
      };

      changeShippingItemsLocations(newRequestData);
      handleConfirmModalClose();
      return;
    }

    if (!hasLocationChanged) {
      completeForceShipping({ shippingId });
      handleConfirmModalClose();
    }
  }, [
    allInputData,
    changeShippingItemsLocations,
    completeForceShipping,
    handleConfirmModalClose,
    shippingId,
    shippingItems,
  ]);

  const getButtonDisabledState = useCallback(() => {
    const validateItemQuantity = () => {
      const quantityBySkuId: { [skuId: number]: number } = {};

      shippingItems?.forEach(({ skuId, quantity }) => {
        if (skuId in quantityBySkuId) {
          quantityBySkuId[skuId] += quantity;
        } else {
          quantityBySkuId[skuId] = quantity;
        }
      });

      allInputData.forEach(({ skuId, qty }) => (quantityBySkuId[skuId] -= qty));

      return Object.values(quantityBySkuId).every(
        (leftQuantity) => leftQuantity === 0
      );
    };

    const validateInputQuantity = () => {
      const getInventoryItemByLocationBarCode = (
        locationBarCode: string
      ): Inventory | undefined => {
        let matchingInventoryItem: Inventory | undefined;

        shippingItems?.forEach((shippingItem) => {
          if (matchingInventoryItem) return;
          matchingInventoryItem = shippingItem.sku.inventory.find(
            (inventoryItem) =>
              locationBarCode ===
              locationDetails?.find(
                (locationDetail) =>
                  locationDetail.id === inventoryItem.locationId
              )?.barCode
          );
        });

        return matchingInventoryItem;
      };

      let isInputQuantityValid = true;

      allInputData.forEach((inputData) => {
        const matchingInventoryItem = getInventoryItemByLocationBarCode(
          inputData.locationBarCode
        );

        if (!matchingInventoryItem) return;

        if (inputData.qty < 0) isInputQuantityValid = false;

        const matchingShippingItem = shippingItems?.find(
          (shippingItem) =>
            shippingItem.locationId === matchingInventoryItem.locationId
        );

        if (matchingShippingItem) {
          if (
            matchingInventoryItem?.availableQty +
              matchingShippingItem.quantity <
            inputData.qty
          )
            isInputQuantityValid = false;
        } else {
          if (matchingInventoryItem?.availableQty < inputData.qty)
            isInputQuantityValid = false;
        }
      });

      return isInputQuantityValid;
    };

    const isValidInputQuantity = validateInputQuantity();
    const isValidItemQuantity = validateItemQuantity();

    if (isValidInputQuantity && isValidItemQuantity && adminMemo) return false;
    else return true;
  }, [adminMemo, allInputData, locationDetails, shippingItems]);

  const ForceShippingButton = useMemo(() => {
    return (
      <Button
        startIcon={<ShoppingCart />}
        variant="outlined"
        onClick={handleForceShippingButtonClick}
      >
        강제 출고
      </Button>
    );
  }, [handleForceShippingButtonClick]);

  const ForceShippingModal = useMemo(() => {
    if (!isVisibleForceShippingModal) {
      return (
        <>
          {ResponseHandlerOfGetShippingDetail}
          {ResponseHandlerOfGetLocationDetails}
        </>
      );
    }

    const isButtonDisabled = getButtonDisabledState();

    return (
      <>
        <Dialog open onClose={handleForceShippingModalClose} maxWidth="lg">
          <DialogTitle>
            <Typography
              sx={dialogTitleHeaderStyle}
              component="header"
              color={blue[700]}
              gutterBottom
            >
              강제출고
            </Typography>
            <Typography variant="body2" color={grey[700]} gutterBottom>
              강제출고 시, 즉시 패킹마감 처리됩니다.
              <br />각 SKU의 확정/변경 수량이 일치 시 강제출고 가능합니다.
            </Typography>
            {AdjustTableTotal}
          </DialogTitle>

          <DialogContent>
            <Stack direction="column" gap={2}>
              {AdjustTable}

              <TextField
                id="admin-memo"
                label="사유"
                value={adminMemo}
                onChange={(e) => setAdminMemo(e.target.value)}
              />
            </Stack>
          </DialogContent>

          <DialogActions sx={dialogActionsStyle}>
            <Button
              variant="contained"
              onClick={handleConfirmModalOpen}
              disabled={isButtonDisabled}
            >
              확인
            </Button>
          </DialogActions>

          <Dialog
            open={isVisibleConfirmModal}
            onClose={handleConfirmModalClose}
          >
            <DialogContent>
              <DialogContentText>
                강제 출고를 진행하시겠습니까?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleConfirmModalClose}>아니오</Button>
              <Button onClick={handleSubmit}>네</Button>
            </DialogActions>
          </Dialog>
        </Dialog>

        {ResponseHandlerOfPostForceShipping}
        {ResponseHandlerOfPatchShippingItemsLocations}
      </>
    );
  }, [
    isVisibleForceShippingModal,
    getButtonDisabledState,
    handleForceShippingModalClose,
    AdjustTableTotal,
    AdjustTable,
    adminMemo,
    handleConfirmModalOpen,
    isVisibleConfirmModal,
    handleConfirmModalClose,
    handleSubmit,
    ResponseHandlerOfPostForceShipping,
    ResponseHandlerOfPatchShippingItemsLocations,
    ResponseHandlerOfGetShippingDetail,
    ResponseHandlerOfGetLocationDetails,
  ]);

  return {
    ForceShippingButton,
    ForceShippingModal,
  };
}

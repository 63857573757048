import { Button, Checkbox, Stack } from "@mui/material";
import SKU_QUERY, { SKU_QUERY_KEY_GEN } from "queries/SKU_QUERY";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import useMultiSelect from "hooks/useMultiSelect";
import useSearchWithTerm, {
  TermSearchType,
} from "hooks/useSearchWithTermForBufulAdminOnly";
import useSkuImageModal, {
  SkuImageModalImageType,
} from "hooks/useSkuImageModal";
import useHeteromorphicFilter from "pages/product/list/useHeteromorphicFilter";
import useMaterialPackageTypeFilter from "pages/product/list/useMaterialPackageTypeFilter";
import usePrintProductLabelModal from "pages/receiving/useProductLabelPrintModal";
import { CommonSKU } from "types/sku";
import { getGroupedSearchListByProperty } from "utils/common";
import { toFormattedDate } from "utils/date";
import {
  getFormattedSingleSkuId,
  getMaterialPackageType,
  getPayloadByFilter,
} from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import { getDimension } from "utils/receiving";

import MemoizedTable, {
  TableBodyRow,
  TableHeadCell,
  TableTabInfo,
} from "components/MemoizedTable";

import useDimensionModal from "../useDimensionModal";
import useSingleProductDetailModal from "./useSingleProductDetailModal";
import useManagementDateFilter from "../useManagementDateFilter";

interface SingleProductTableProps {
  tableTabInfo: TableTabInfo;
}

type CellIdForSingleProduct =
  | "dimension"
  | "companyName"
  | "teamName"
  | Extract<
      keyof CommonSKU,
      | "itemName"
      | "barCode"
      | "productCode"
      | "managementCode"
      | "managementKind"
      | "materialPackageType"
      | "isHeteromorphic"
      | "hsCode"
      | "value"
      | "safetyQty"
      | "weight"
    >
  | "label"
  | "checkbox"
  | "skuId";

const termSearchTypeOptionsForSingleProduct: TermSearchType<
  | "id"
  | "company"
  | "itemName"
  | "barCode"
  | "productCode"
  | "managementCode"
  | "managementKind"
>[] = [
  {
    label: "SKU ID",
    value: "id",
  },
  {
    label: "회사명",
    value: "company",
  },
  {
    label: "상품명",
    value: "itemName",
  },
  {
    label: "상품바코드",
    value: "barCode",
  },
  {
    label: "판매자상품코드",
    value: "productCode",
  },
  {
    label: "옵션관리코드",
    value: "managementCode",
  },
  { label: "유통기한/관리일자", value: "managementKind" },
];

function SingleProductTable({ tableTabInfo }: SingleProductTableProps) {
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedSku, setSelectedSku] = useState<{
    skuId: number;
    imageType: SkuImageModalImageType;
  }>();

  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  const { openPrintingProductLabel, ModalOfPrintingProductLabel } =
    usePrintProductLabelModal();

  const queryClient = useQueryClient();

  const resetCurrentPage = useCallback(() => setCurrentPage(0), []);

  const { TermSearchPanel: TermSearchPanelForSingleProduct, searchList } =
    useSearchWithTerm({
      pageType: "singleSku",
      termSearchTypeOptions: termSearchTypeOptionsForSingleProduct,
      resetCurrentPage,
    });

  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();

  const {
    MaterialPackageTypeFilterPanel,
    materialPackageTypePayloadListByFilter,
  } = useMaterialPackageTypeFilter({ resetCurrentPage });

  const { HeteromorphicFilterPanel, heteromorphicPayloadListByFilter } =
    useHeteromorphicFilter({ resetCurrentPage });

  const { ManagementDateFilterPanel, managementDatePayloadListByFilter } =
    useManagementDateFilter({ resetCurrentPage });

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
    resetCurrentPage();
  };

  const {
    data: singleProductList,
    refetch: refetchSingleProductList,
    ResponseHandler: ResponseHandlerOfSingleProductList,
  } = SKU_QUERY.useGetSingleSkuListV2({
    page: currentPage,
    perPage: perPage,

    ...getGroupedSearchListByProperty({ pageType: "singleSku", searchList }),

    ...getPayloadByFilter({
      key: "materialPackageType",
      payloadList: materialPackageTypePayloadListByFilter,
    }),

    ...getPayloadByFilter({
      key: "isHeteromorphic",
      payloadList: heteromorphicPayloadListByFilter,
    }),

    ...getPayloadByFilter({
      key: "managementKind",
      payloadList: managementDatePayloadListByFilter,
    }),

    onSuccess: ({ list }) => {
      initSelectionDict(list.map(({ id }) => id));
    },
  });

  const {
    mutate: changeHeteromorphic,
    ResponseHandler: ResponseHandlerOfChangeHeteromorphic,
  } = SKU_QUERY.useChangeHeteromorphic({
    type: "single",
    onSuccess: () => {
      queryClient.invalidateQueries(SKU_QUERY_KEY_GEN.getSingleSkuListV2({}));
    },
  });

  const { ResponseHandler: ResponseHandlerOfGetSingleSkuDetail } =
    SKU_QUERY.useGetSingleSkuDetail({
      skuId: selectedSku?.skuId,
      enabled: !!selectedSku,
      onSuccess: (data) => {
        if (!data || !selectedSku?.imageType) return;

        const refinedSku = {
          ...data.sku,
          attachment: [...data.attachment],
          type: "single" as const,
        };

        handleSkuForImageModalSelect({
          skuData: refinedSku,
          imageType: selectedSku.imageType,
        });
        setSelectedSku(undefined);
      },
      onError: () => setSelectedSku(undefined),
    });

  const { handleDimensionModalOpen, DimensionModal } = useDimensionModal({
    refetchList: () => refetchSingleProductList(),
  });

  const handleSkuImageModalOpen =
    ({
      skuId,
      imageType,
    }: {
      skuId: number;
      imageType: SkuImageModalImageType;
    }) =>
    () => {
      setSelectedSku({ skuId, imageType });
    };

  const handlePrintingProductLabelModalOpen = useCallback(
    (skuItem: CommonSKU) => () => {
      openPrintingProductLabel([
        {
          skuId: skuItem.id,
          barCode: skuItem.barCode,
          itemName: skuItem?.itemName,
          companyName: skuItem.team?.company ?? "",
          teamName: skuItem.team?.name ?? "",
        },
      ]);
    },
    [openPrintingProductLabel]
  );

  const { SingleProductDetailModal, showSingleProductDetailModal } =
    useSingleProductDetailModal();

  const singleProductHeadCells: TableHeadCell<CellIdForSingleProduct>[] =
    useMemo(
      () => [
        {
          id: "skuId",
          label: "SKU ID",
          disablePadding: false,
          width: 120,
        },
        {
          id: "companyName",
          label: "회사명",
          disablePadding: false,
          width: 150,
        },
        {
          id: "teamName",
          label: "팀명",
          disablePadding: false,
          width: 150,
        },
        {
          id: "itemName",
          label: "상품명",
          disablePadding: false,
          width: 250,
        },
        {
          id: "barCode",
          label: "상품바코드",
          disablePadding: false,
          width: 120,
        },
        {
          id: "productCode",
          label: "판매자상품코드",
          disablePadding: false,
          width: 120,
        },
        {
          id: "managementCode",
          label: "옵션관리코드",
          disablePadding: false,
          width: 120,
        },
        {
          id: "managementKind",
          label: "유통기한/제조일자",
          disablePadding: false,
          width: 120,
          filter: ManagementDateFilterPanel,
        },
        {
          id: "materialPackageType",
          label: "부자재",
          disablePadding: false,
          width: 180,
          filter: MaterialPackageTypeFilterPanel,
        },
        {
          id: "safetyQty",
          label: "안전재고",
          disablePadding: false,
          width: 120,
        },
        {
          id: "hsCode",
          label: "HS코드",
          disablePadding: false,
          width: 120,
        },
        {
          id: "value",
          label: "인보이스금액",
          disablePadding: false,
          width: 120,
        },
        {
          id: "dimension",
          label: "Dimension",
          disablePadding: false,
          width: 220,
        },
        {
          id: "weight",
          label: "Weight",
          disablePadding: false,
          width: 120,
        },
        {
          id: "isHeteromorphic",
          label: "중량/이형화물",
          disablePadding: false,
          width: 130,
          filter: HeteromorphicFilterPanel,
        },
        {
          id: "label",
          label: "라벨",
          disablePadding: false,
          width: 100,
        },
      ],
      [
        ManagementDateFilterPanel,
        HeteromorphicFilterPanel,
        MaterialPackageTypeFilterPanel,
      ]
    );

  const createSingleProductRows = () => {
    if (!singleProductList) return [];

    const newSingleProductRows: TableBodyRow<CellIdForSingleProduct>[] =
      singleProductList.list.map((skuItem: CommonSKU) => {
        const isNoneMaterialPackageType =
          skuItem.materialPackageType === "none";

        return {
          id: skuItem.id,
          skuId: getFormattedSingleSkuId(skuItem.id),
          company: skuItem.team?.company,
          team: skuItem.team?.name,
          itemName: skuItem.itemName,
          barCode: skuItem.barCode,
          productCode: skuItem.productCode,
          managementCode: skuItem.managementCode,
          managementKind: skuItem.managementKind ? "Y" : "N",
          materialPackageType: (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>{getMaterialPackageType(skuItem.materialPackageType)}</span>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSkuImageModalOpen({
                    skuId: skuItem.id,
                    imageType: "package",
                  })();
                }}
                disabled={isNoneMaterialPackageType}
              >
                확인
              </Button>
            </Stack>
          ),
          safetyQty: skuItem.safetyQty ?? "-",
          hsCode: skuItem.hsCode,
          value: skuItem.value ? toThousandUnitFormat(skuItem.value) : "",
          dimension: (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                {getDimension({
                  width: skuItem.width,
                  length: skuItem.length,
                  height: skuItem.height,
                })}
              </div>

              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDimensionModalOpen({
                    width: skuItem.width,
                    length: skuItem.length,
                    height: skuItem.height,
                    weight: skuItem.weight,
                    isSku: true,
                    skuId: skuItem.id,
                  })();
                }}
              >
                수정
              </Button>
            </Stack>
          ),
          weight: skuItem.weight,
          isHeteromorphic: (
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                changeHeteromorphic({
                  pathParams: {
                    skuId: skuItem.id,
                  },
                  heteromorphic: !skuItem.isHeteromorphic,
                });
              }}
              checked={skuItem.isHeteromorphic}
            />
          ),
          label: (
            <Button
              variant="outlined"
              size="small"
              color="info"
              onClick={(e) => {
                e.stopPropagation();
                handlePrintingProductLabelModalOpen(skuItem)();
              }}
            >
              출력
            </Button>
          ),
          handleRowClick: () => {
            showSingleProductDetailModal(skuItem.id);
          },
        };
      });

    return newSingleProductRows;
  };

  const createSingleProductRowsForCSVDownload = () => {
    if (!singleProductList) return [];

    const newSingleProductRowsForCSVDownload: TableBodyRow<CellIdForSingleProduct>[] =
      singleProductList.list
        .filter(({ id }) => selectedIdList.includes(id))
        .map((skuItem: CommonSKU) => ({
          id: skuItem.id,

          skuId: getFormattedSingleSkuId(skuItem.id),
          companyName: skuItem.team?.company,
          teamName: skuItem.team?.name,
          itemName: skuItem.itemName,
          barCode: skuItem.barCode,
          productCode: skuItem.productCode,
          managementCode: skuItem.managementCode,
          managementKind:
            skuItem.managementKind === "expirationDate"
              ? "유통기한"
              : skuItem.managementKind === "manufacturingDate"
              ? "제조일자"
              : "-",
          materialPackageType: getMaterialPackageType(
            skuItem.materialPackageType
          ),
          safetyQty: skuItem.safetyQty ?? "-",
          hsCode: skuItem.hsCode,
          value: skuItem.value ? toThousandUnitFormat(skuItem.value) : "",
          isHeteromorphic: skuItem.isHeteromorphic ? "Y" : "N",
        }));

    return newSingleProductRowsForCSVDownload;
  };

  const singleProductRows: TableBodyRow<CellIdForSingleProduct>[] = useMemo(
    createSingleProductRows,
    [
      changeHeteromorphic,
      handleDimensionModalOpen,
      handlePrintingProductLabelModalOpen,
      singleProductList,
    ]
  );
  const singleProductRowsForCSVDownload: TableBodyRow<CellIdForSingleProduct>[] =
    useMemo(createSingleProductRowsForCSVDownload, [
      selectedIdList,
      singleProductList,
    ]);

  return (
    <>
      <MemoizedTable
        title="상품관리"
        tableTabInfo={tableTabInfo}
        headCells={singleProductHeadCells}
        rows={singleProductRows}
        toolbarItems={{
          left: [TermSearchPanelForSingleProduct],
        }}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: singleProductList?.total ?? 0,
          perPage,
          setPerPage: handlePerPageChange,
          currentPage,
          setCurrentPage,
        }}
        csvDownloadForSelectedRowsInfo={{
          filename: `상품관리 리스트_단일상품 (${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          idsToExceptForExcelDownload: ["checkbox", "label"],
          rowsForCSVDownload: singleProductRowsForCSVDownload,
        }}
        selectionDict={selectionDict}
        select={select}
        unSelect={unSelect}
        selectAll={selectAll}
        unSelectAll={unSelectAll}
        selectedCount={selectedCount}
        isAllSelected={isAllSelected}
      />

      <SkuImageModal />

      {ModalOfPrintingProductLabel}

      {DimensionModal}

      {SingleProductDetailModal}

      {ResponseHandlerOfSingleProductList}
      {ResponseHandlerOfGetSingleSkuDetail}
      {ResponseHandlerOfChangeHeteromorphic}
    </>
  );
}

export default SingleProductTable;

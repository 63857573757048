import TextField from "components/TextField";

import { InventoryDetailModifyForm } from "./useFormState";

export default function ItemInputForAdjustmentQty({
  locationId,
  formState,
  onAdjustmentQtyChange,
}: {
  locationId: number | string;
  formState: InventoryDetailModifyForm | null;
  onAdjustmentQtyChange: ({
    locationId,
    adjustmentQty,
  }: {
    locationId: number | string;
    adjustmentQty: number;
  }) => void;
}) {
  return (
    <TextField
      size="small"
      value={formState?.inventory.get(locationId)?.adjustmentQty || 0}
      onChange={(e) => {
        onAdjustmentQtyChange({
          locationId,
          adjustmentQty: Number(e.target.value),
        });
      }}
    />
  );
}

import { FlattenInterpolation, css } from "styled-components";
import { COLOR, MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from "./constants";

/**
 * 세로 스크롤바를 항상 표시한다
 */
function showVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar:vertical {
      width: 8px;
      background-color: transparent;
      display: block;
    }
    &::-webkit-scrollbar-thumb:vertical {
      background-color: ${COLOR.grayScale_400};
      border: 3px solid transparent;
      border-radius: 9px;
    }
  `;
}

/** 모바일 size용 스타일 */
function mobile(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${MOBILE_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 *
 * showVerticalScrollBarAlways의 효과를 무효화
 */
function disableSettingOfShowVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar:vertical {
      display: none;
    }
  `;
}

/**
 * @deprecated 태블릿 사이즈는 지원하지 않으므로 사용하지 말것
 * TODO: 사용코드가 없어지면 삭제
 * 태블릿 size용 스타일
 */
function tablet(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${TABLET_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 * 말줄임표 처리
 */
const formatEllipsis = () =>
  css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

export {
  showVerticalScrollBarAlways,
  mobile,
  disableSettingOfShowVerticalScrollBarAlways,
  tablet,
  formatEllipsis,
};

import { memo, ReactNode } from "react";
import { TableBody } from "@mui/material";

import { KeyType, SelectionDict } from "hooks/useMultiSelect";

import { PinnedColumn, TableBodyRow } from "..";
import MemoizedTableRow from "./MemoizedTableRow";

function MemoizedTableBody<CellId extends string>({
  rows,
  pinnedColumnList,
  extraTableBody,

  selectionDict,
  select,
  unSelect,
}: {
  rows: TableBodyRow<CellId>[];
  pinnedColumnList: PinnedColumn<CellId>[] | undefined;
  extraTableBody: ReactNode | undefined;

  selectionDict: SelectionDict;
  select: (key: KeyType) => void;
  unSelect: (key: KeyType) => void;
}) {
  return (
    <TableBody>
      {rows.map((row) => {
        const isChecked = !!selectionDict[row.id];

        return (
          <MemoizedTableRow
            key={row.id}
            row={row}
            pinnedColumnList={pinnedColumnList}
            isChecked={isChecked}
            select={select}
            unSelect={unSelect}
          />
        );
      })}

      {extraTableBody}
    </TableBody>
  );
}

export default memo(MemoizedTableBody);

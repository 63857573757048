import { useMemo } from "react";
import { ShippingItemDetail } from "types/shipping";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";
import { Button } from "@mui/material";
import useDeletePackingModal from "./useDeletePackingModal";
import MATERIAL_QUERY from "queries/MATERIAL_QUERY";
import useUpdatePackingModal from "./useUpdatePackingModal";

type CellId = "no" | "invoiceNo" | "outerPackage" | "update";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "no",
    disablePadding: false,
    label: "포장번호",
    width: 60,
  },
  {
    id: "invoiceNo",
    disablePadding: false,
    label: "송장번호",
    width: 180,
  },
  {
    id: "outerPackage",
    disablePadding: false,
    label: "외포장재",
    width: 200,
  },
  {
    id: "update",
    disablePadding: false,
    label: "변경 작업",
    width: 70,
  },
];

export default function PackingDetailList({
  shippingDetailData,
}: {
  shippingDetailData: ShippingItemDetail | undefined;
}) {
  const { showDeletePackingModal, DeletePackingModal } =
    useDeletePackingModal();

  const { showUpdatePackingModal, UpdatePackingModal } =
    useUpdatePackingModal();

  const { data: materialListForTotalPicking } =
    MATERIAL_QUERY.useGetMaterialListForTotalPicking({
      teamId: shippingDetailData?.teamId,
      enabled: !!shippingDetailData,
    });

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!shippingDetailData?.packings) {
      return [];
    }

    return shippingDetailData.packings.map((packing, index) => ({
      no: index + 1,
      invoiceNo: packing.invoiceNo,
      outerPackage: packing.outerPackages?.name,
      update: (
        <>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              showUpdatePackingModal(
                packing.invoiceNo,
                packing.shippingId,
                materialListForTotalPicking
              );
            }}
          >
            수정
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              showDeletePackingModal(packing.invoiceNo, packing.shippingId);
            }}
          >
            삭제
          </Button>
        </>
      ),
    }));
  }, [
    materialListForTotalPicking,
    shippingDetailData,
    showDeletePackingModal,
    showUpdatePackingModal,
  ]);

  return (
    <>
      {!!shippingDetailData?.packings?.length && (
        <Table title="포장정보" headCells={headCells} rows={rows} />
      )}
      {DeletePackingModal}
      {UpdatePackingModal}
    </>
  );
}

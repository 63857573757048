import React, { Fragment } from "react";
import newId from "utils/newId";

/**
 * SVG요소 내부를 재귀적으로 순회하며 id를 변경
 */
function updateId(
  target: React.ReactElement<SVGElement>,
  id: string
): React.ReactElement<SVGElement> {
  return React.cloneElement(target, {
    ...target.props,
    ...(target.props.id && { id }),
    // SVG에 대한 타입지원이 좋지 않아 any로 처리
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...((target.props as any).clipPath && { clipPath: getClipPath(id) }),
    children: (() => {
      if (!target.props.children) return;

      if (!Array.isArray(target.props.children))
        // SVG에 대한 타입지원이 좋지 않아 any로 처리
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return updateId(target.props.children as any, id);

      return target.props.children.map((child) => {
        return <Fragment key={newId(`${id}-`)}>{updateId(child, id)}</Fragment>;
      });
    })(),
  });
}

function getClipPath(id: string) {
  return `url(#${id})`;
}

/**
 * SVG내부에서 사용되는 id(id, clipPath)를 unique하게 변경
 * - 동일한 SVG아이콘이 동일한 페이지에 여러개 존재할 경우, id가 중복되어 충돌이나는데 이를 방지하기위해 사용.
 * - 기존 id, clipPath는 새 것으로 대체됨.
 */
function applyUniqueId(svgElement: React.ReactElement<SVGElement>) {
  const uniqueId = newId("svg-icon-");

  const target = React.cloneElement(svgElement);

  return updateId(target, uniqueId);
}

/**
 * props로 전달받은 svg custom 속성을 적용
 */
function applyCustomProps(
  src: React.ReactElement<SVGElement>,
  // SVG에 데한 타입지원이 좋지 않아 any로 처리
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customProps: any
) {
  return React.cloneElement(src, {
    ...src.props,
    ...customProps,
  });
}

export { applyCustomProps, applyUniqueId };

import styled from "styled-components";

const container = styled.section``;

const metaInfoOnItemInfo = styled.div`
  > .item {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 8px 0;

    > .label {
      min-width: 168px;
      font-weight: bold;
    }
  }
`;

const skuItemName = styled.div`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
`;

const submitReturningInvoiceNoButtonWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  justify-content: flex-end;
  align-items: center;
`;

export default {
  container,
  metaInfoOnItemInfo,
  skuItemName,
  submitReturningInvoiceNoButtonWrapper,
};

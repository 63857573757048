import { useMemo, useState } from "react";

import InputRadio from "components/sds-v1/input/InputRadio";

import { ImageInfo } from "../index";
import { CommonDetailSlider } from "./CommonDetailSlider";

// 하나의 id (카테고리별 id로 구분) 에 여러 이미지가 있는 타입
type ImageWithTitleDetailInfo = {
  id: number;
  title: string;
  keyList: string[];
  urlList: string[];
};

export default function ImageDetailWithTitle({
  imageList,
  currentSlideKey,
}: {
  imageList: ImageInfo[];
  currentSlideKey: string;
}) {
  // 같은 id 를 가진 이미지를 묶은 리스트
  const imageWithTitleDetailList = useMemo(() => {
    return imageList.reduce(
      (acc: ImageWithTitleDetailInfo[], cur: ImageInfo) => {
        const existingItem = acc.find((item) => item.id === cur.id);

        if (existingItem)
          return acc.map((item) =>
            item.id === cur.id
              ? {
                  ...item,
                  keyList: [...item.keyList, cur.key],
                  urlList: [...item.urlList, cur.url],
                }
              : item
          );

        return [
          ...acc,
          {
            id: cur.id,
            title: cur.title ?? "",
            keyList: [cur.key],
            urlList: [cur.url],
          },
        ];
      },
      []
    );
  }, [imageList]);

  const [selectedImageId, setSelectedImageId] = useState<number | undefined>(
    imageWithTitleDetailList.find(({ keyList }) =>
      keyList.includes(currentSlideKey)
    )?.id
  );

  // 초기에는 클릭한 이미지를 보여주고, 옵션이 바뀌면 옵션 카테고리의 첫 이미지를 보여준다.
  const [activeSlideUrl, setActiveSlideUrl] = useState<string | undefined>(
    imageList.find((item) => item.key === currentSlideKey)?.url
  );

  const imageTitleOptionList =
    imageWithTitleDetailList.map(({ id, title }) => ({
      label: title ?? "",
      value: id ?? "",
    })) ?? [];

  const visibleImageList = useMemo(() => {
    // 카테고리 id (타이틀 노출) 로 분류된 이미지 리스트는 선택된 id 에 해당하는 image 만 노출
    return imageWithTitleDetailList.find((item) => item.id === selectedImageId)
      ?.urlList;
  }, [imageWithTitleDetailList, selectedImageId]);

  const activeSlideIndexForVisibleList =
    visibleImageList?.findIndex((url) => url === activeSlideUrl) ?? 0;

  return (
    <>
      <InputRadio<number>
        direction="row"
        selectedValue={selectedImageId}
        selectCallback={(v) => {
          setSelectedImageId(v);

          const activeUrl = imageWithTitleDetailList.find(
            (item) => item.id === v
          )?.urlList?.[0];

          setActiveSlideUrl(activeUrl);
        }}
        optionList={imageTitleOptionList}
        isFlexWrap
      />

      <CommonDetailSlider
        key={`image-detail-slider-${selectedImageId}`}
        imageUrlList={visibleImageList ?? []}
        currentSlideIndex={activeSlideIndexForVisibleList}
      />
    </>
  );
}

import { InspectProblem } from "types/returning";
import {
  calculateDifference,
  getStringForValueRange,
  toThousandUnitFormat,
} from "utils/number";

import Styled from "./index.styles";

export default function ProblemMemo({
  quantity,
  actualQty,
  placeQty,
  problemList,
  isDecidedQuantityStatus,
}: {
  quantity: number;
  actualQty?: number;
  placeQty?: number | null;
  problemList?: InspectProblem[];
  isDecidedQuantityStatus: boolean;
}) {
  const inspectionDifferenceQuantityMemo = (() => {
    if (quantity && quantity !== actualQty) {
      return `입고요청 수량대비 검수수량 ${toThousandUnitFormat(
        calculateDifference(quantity, actualQty)
      )} PCS ${getStringForValueRange(quantity, actualQty)}`;
    }

    return;
  })();

  const inspectionProblemMemo = (() => {
    if (problemList && problemList.length > 0) {
      const problemQuantity = problemList.reduce(
        (acc, cur) => acc + cur.quantity,
        0
      );

      return `검수한 수량에서 ${toThousandUnitFormat(
        problemQuantity
      )} PCS 문제발생`;
    }

    return;
  })();

  const warehousingDifferenceQuantityMemo = (() => {
    if (!isDecidedQuantityStatus) return;

    if (actualQty !== placeQty) {
      return `검수수량 대비 입고수량 ${calculateDifference(
        actualQty,
        placeQty
      )} PCS ${getStringForValueRange(actualQty, placeQty)}`;
    }

    return;
  })();

  return (
    <Styled.problemMemo>
      {warehousingDifferenceQuantityMemo ||
      inspectionDifferenceQuantityMemo ||
      inspectionProblemMemo ? (
        <>
          <span>{inspectionDifferenceQuantityMemo}</span>

          <span>{inspectionProblemMemo}</span>

          <span>{warehousingDifferenceQuantityMemo}</span>
        </>
      ) : (
        "-"
      )}
    </Styled.problemMemo>
  );
}

import {
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { SkuPickingInfo } from "../utils";
import useUpdateQuantityModal from "./useUpdateQuantityModal";

export function PickingList({
  shippingId,
  skuPickingInfo,
  onUpdateSuccess,
}: {
  shippingId: number;
  skuPickingInfo: SkuPickingInfo;
  onUpdateSuccess: () => void;
}) {
  const { UpdateQuantityModal, showUpdateQuantityModal } =
    useUpdateQuantityModal({ onUpdateSuccess });

  return (
    <TableRow>
      <TableCell>{skuPickingInfo.skuId}</TableCell>
      <TableCell>{skuPickingInfo.totalQuantity}</TableCell>
      <TableCell>
        <Stack flexDirection={"column"} gap={"3px"}>
          {skuPickingInfo.pickingList.map((picking, keyOuter) => (
            <Stack
              flexDirection={"row"}
              key={keyOuter}
              alignItems={"center"}
              width={"500px"}
              justifyContent={"space-between"}
              gap={"3px"}
            >
              <span style={{ width: "100px" }}>{picking.barCode}</span>
              <OutlinedInput
                size="small"
                type={"number"}
                disabled={true}
                value={picking.quantity}
                endAdornment={
                  <InputAdornment position="end">qty</InputAdornment>
                }
              />

              <Button
                onClick={() => {
                  showUpdateQuantityModal({
                    shippingId,
                    skuId: skuPickingInfo.skuId,
                    barCode: picking.barCode,
                    locationId: picking.locationId,
                    quantity: picking.quantity,
                  });
                }}
              >
                수정하기(차감)
              </Button>
            </Stack>
          ))}
        </Stack>
      </TableCell>
      {UpdateQuantityModal}
    </TableRow>
  );
}

import { useSetRecoilState } from "recoil";

import { useAppMutation } from "services/query";

import { CurrentManager } from "types/auth";

import { QueryResponseHandlerSuccessModalInfo } from "components/sds-v1/QueryResponseHandler";

import { LOGIN_FOR_ADMIN_REQ, LOGIN_REQ, LOGIN_RES } from "api-interfaces/auth";
import { FULFILLMENT_AUTH_ATOMS } from "states/auth";

function useWorkerLogin() {
  const setLoggedIn = useSetRecoilState(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

  const mutation = useAppMutation<LOGIN_REQ, LOGIN_RES>({
    requestOptions: {
      method: "post",
      path: `/auth/login`,
      apiType: "BofulDefault",
    },

    onSuccess: (data) => {
      setLoggedIn(true);

      const { accessToken, refreshToken, user, loggedInAt, deviceName } = data;

      // 보안을 고려하여 선택적으로 저장
      const currentUser: CurrentManager = {
        loggedInAt,
        deviceName,
        id: user.id,
        accountId: user.accountId,
        email: user.email,
        name: user.name,
        authority: user.authority,
        memo: user.memo,
        warehouseId: user.warehouseId,
      };

      window.localStorage.setItem("currentUser", JSON.stringify(currentUser));
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        let title = "";

        if (failureInfo) {
          const { code, error } = failureInfo;

          switch (code) {
            case 400: {
              if (error === "Can not login with forwarder id") {
                title = "물류사 ID입니다.";
              } else {
                title = "이메일 또는 패스워드가 틀렸습니다.";
              }
              break;
            }
            case 404: {
              title = "가입되지 않은 아이디입니다.";
              break;
            }
            default: {
              title = "로그인을 할 수 없습니다";
              break;
            }
          }
        }

        return {
          title,
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function useAdminLogin() {
  const setLoggedIn = useSetRecoilState(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

  const mutation = useAppMutation<LOGIN_FOR_ADMIN_REQ, LOGIN_RES>({
    requestOptions: {
      method: "post",
      path: "/auth/web/login",
      apiType: "BofulDefault",
    },

    onSuccess: (data) => {
      setLoggedIn(true);

      const { accessToken, refreshToken, user, loggedInAt, deviceName } = data;

      // 보안을 고려하여 선택적으로 저장
      const currentUser: CurrentManager = {
        loggedInAt,
        deviceName,
        id: user.id,
        accountId: user.accountId,
        email: user.email,
        name: user.name,
        authority: user.authority,
        memo: user.memo,
        warehouseId: user.warehouseId,
      };

      window.localStorage.setItem("currentUser", JSON.stringify(currentUser));
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        let title = "";

        if (failureInfo) {
          const { code, error } = failureInfo;

          switch (code) {
            case 400: {
              if (error === "Can not login with forwarder id") {
                title = "물류사 ID입니다.";
              } else {
                title = "이메일 또는 패스워드가 틀렸습니다.";
              }
              break;
            }
            case 404: {
              title = "가입되지 않은 아이디입니다.";
              break;
            }
            default: {
              title = "로그인을 할 수 없습니다";
              break;
            }
          }
        }

        return {
          title,
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function useChangePassword(props?: {
  successModalInfo: QueryResponseHandlerSuccessModalInfo;
}) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<
    { oldPassword: string; newPassword: string },
    unknown
  >({
    requestOptions: {
      method: "put",
      path: "/auth/modify/password",
      apiType: "BofulDefault",
    },
    successModalInfo,
  });

  return { ...mutation };
}

const AUTH_QUERY = {
  useWorkerLogin,
  useAdminLogin,
  useChangePassword,
};
export default AUTH_QUERY;

import { useState } from "react";
import { TableDataListItem } from "headlessComponents/table/useTable";
import INVENTORY_QUERY from "queries/INVENTORY_QUERY";

import { getPageSize } from "utils/common";
import { toThousandUnitFormat } from "utils/number";
import { replaceEmptyToDash } from "utils/string";

import Paging from "components/sds-v1/Paging";
import Table from "components/sds-v1/table/Table";

type InventoryHistoryItem = {
  historyDate: string;
  receivingQty: string;
  shippingQty: string;
  availableQty: string;
  inWorkingQty: string;
  faultyQty: string;
  totalWithoutFaultyQty: string;
  warehouseName: string;
};

const PER_PAGE = 5;

export default function HistorySection({ skuId }: { skuId: number }) {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<"ASC" | "DESC">();

  const {
    data: inventoryHistoryList,
    ResponseHandler: ResponseHandlerOfInventoryHistoryList,
  } = INVENTORY_QUERY.useGetInventoryHistoryList({
    enabled: !!skuId,
    skuId,
    page,
    perPage: PER_PAGE,
    orderBy,
  });

  const dataList: TableDataListItem<InventoryHistoryItem>[] =
    inventoryHistoryList?.list?.map((history: any, index: any) => ({
      rowKey: index,

      historyDate: history.completeAt,
      receivingQty: history.inQuantity
        ? `+${toThousandUnitFormat(history.inQuantity)}`
        : "-",
      shippingQty: history.outQuantity
        ? `-${toThousandUnitFormat(history.outQuantity)}`
        : "-",
      availableQty: toThousandUnitFormat(
        replaceEmptyToDash(history.availableQty)
      ),
      inWorkingQty: toThousandUnitFormat(
        replaceEmptyToDash(history.inWorkingQty)
      ),
      faultyQty: toThousandUnitFormat(replaceEmptyToDash(history.faultyQty)),
      totalWithoutFaultyQty: toThousandUnitFormat(
        replaceEmptyToDash(history.totalWithoutFaultyQty)
      ),
      warehouseName: `인천1센터`,
    })) ?? [];

  return (
    <>
      <Paging
        pageSize={getPageSize(PER_PAGE, inventoryHistoryList?.total)}
        currentPage={page}
        currentPageData={
          <Table<InventoryHistoryItem>
            columnInfo={{
              historyDate: {
                label: "입출고 일자",
                portion: 150,
              },
              receivingQty: {
                label: "입고수량(PCS)",
                portion: 130,
              },
              shippingQty: {
                label: "출고수량(PCS)",
                portion: 180,
              },
              availableQty: {
                label: "가용재고",
                portion: 90,
              },
              inWorkingQty: {
                label: "작업중재고",
                portion: 90,
              },
              faultyQty: {
                label: "불량품",
                portion: 80,
              },
              totalWithoutFaultyQty: {
                label: "총재고(불량품 제외)",
                portion: 180,
              },
              warehouseName: {
                label: "입출고지",
                portion: 70,
              },
            }}
            dataList={dataList}
          />
        }
        handleClickPage={setPage}
        isZeroBasedPage
      />

      {ResponseHandlerOfInventoryHistoryList}
    </>
  );
}

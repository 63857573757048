import { Controller, useForm } from "react-hook-form";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import RETURNING_QUERY from "queries/RETURNING_QUERY";

import Layout from "containers/Layout";

interface ModifyReturningForm {
  returningId: string;
}

export function UpdateReturningStatusForTest() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ModifyReturningForm>({
    mode: "onChange",
    defaultValues: {
      returningId: "",
    },
  });

  const { mutate: modifyReturningForTest } =
    RETURNING_QUERY.useModifyReturningForTest({
      successModalInfo: {
        handleConfirmSuccess: (initQuery) => {
          initQuery();
          reset();
        },
        customizeMessage: () => ({
          messageType: "titleOnly",
          title: "반품정보 수정 완료",
        }),
      },
    });

  const handleUpdateReturning = ({ returningId }: ModifyReturningForm) => {
    if (!returningId) {
      return;
    }

    modifyReturningForTest({
      pathParams: { returningId: parseInt(returningId) },
    });
  };

  return (
    <Layout>
      <Paper sx={{ maxWidth: "300px", padding: "20px" }}>
        <Typography color="text.primary">반품 상태 변경</Typography>
        <form onSubmit={handleSubmit(handleUpdateReturning)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="returningId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="반품의뢰번호"
                  error={!!errors.returningId}
                  helperText={errors.returningId && "숫자만 입력해주세요."}
                />
              )}
            />
            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>
    </Layout>
  );
}

import { atom } from "recoil";

import {
  ShippingDeliveryType,
  ShippingItem,
  ShippingListItem,
  TransactionStatementData,
} from "types/shipping";
import { transformUTCDateToLocalDateTime } from "utils/date";

import { GET_SHIPPING_LIST_V2_REQ } from "api-interfaces/shipping";

/** Confirm */
const SHIPPING_LIST = atom<ShippingListItem[]>({
  key: "fulfillment/shipping/atoms/SHIPPING_LIST",
  default: [],
});

/** Picking */
const SCANNED_PICKING_INVOICE = atom<{
  shippingId: number;
  invoiceNo: string;
  deliveryType: ShippingDeliveryType | undefined;
  items: ShippingItem[];
  teamId: number;
  companyName: string;
  teamName: string;
}>({
  key: "fulfillment/shipping/atoms/SCANNED_PICKING_INVOICE",
  default: {
    shippingId: 0,
    invoiceNo: "",
    deliveryType: undefined,
    items: [],
    teamId: 0,
    companyName: "",
    teamName: "",
  },
});

const IS_NO_NEED_TO_PACKING = atom<boolean>({
  key: "fulfillment/shipping/atoms/IS_NO_NEED_TO_PACKING",
  default: false,
});

/** 거래 명세서 데이터 */
const TRANSACTION_STATEMENT_DATA = atom<TransactionStatementData[]>({
  key: "fulfillment/shipping/atoms/TRANSACTION_STATEMENT_DATA",
  default: [],
});

/** 출고신청/취소 국내 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_DOMESTIC = atom<
  Omit<GET_SHIPPING_LIST_V2_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_DOMESTIC",
  default: {
    isDone: false,
    page: 0,
    perPage: 10,
    dateKind: "createdAt",
    startDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    endDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고신청/취소 해외 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_OVERSEAS = atom<
  Omit<GET_SHIPPING_LIST_V2_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_OVERSEAS",
  default: {
    isDone: false,
    page: 0,
    perPage: 10,
    dateKind: "createdAt",
    startDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    endDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고마감내역 국내 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_DOMESTIC = atom<
  Omit<GET_SHIPPING_LIST_V2_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE",
  default: {
    isDone: true,
    page: 0,
    perPage: 10,
    dateKind: "endedPackingAt",
    startDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    endDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고마감내역 해외 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_OVERSEAS = atom<
  Omit<GET_SHIPPING_LIST_V2_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_OVERSEAS",
  default: {
    isDone: true,
    page: 0,
    perPage: 10,
    dateKind: "endedPackingAt",
    startDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    endDate:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

export default {
  SHIPPING_LIST,

  SCANNED_PICKING_INVOICE,
  IS_NO_NEED_TO_PACKING,

  TRANSACTION_STATEMENT_DATA,

  SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_DOMESTIC,
  SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_OVERSEAS,
  SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_DOMESTIC,
  SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_OVERSEAS,
};

import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { ShippingTruckType } from "types/shipping";

const filterOptionList: TableHeadFilterOption<ShippingTruckType>[] = [
  { label: "오토바이", value: "motorcycle" },
  { label: "다마스", value: "damas" },
  { label: "라보", value: "labo" },
  { label: "벤", value: "van" },
  { label: "1톤 트럭", value: "1ton" },
  { label: "1.2톤 & 1.4톤 트럭", value: "ton1_2_and_ton1_4" },
  { label: "2.5톤 & 3.5톤 트럭", value: "ton2_5_and_ton3_5" },
  { label: "5톤 트럭", value: "5ton" },
  { label: "7.5톤 트럭", value: "ton7_5" },
  { label: "11톤 트럭", value: "11ton" },
  { label: "14톤 트럭", value: "14ton" },
  { label: "25톤 트럭", value: "25ton" },
  { label: "1톤 윙바디 트럭", value: "1ton_wingbody" },
  { label: "2.5톤 & 3.5톤 윙바디 트럭", value: "ton2_5_and_ton3_5_wingbody" },
  { label: "5톤 윙바디 트럭", value: "5ton_wingbody" },
  { label: "5톤 축차", value: "5ton_rotor" },
];

export default function useTruckTypeFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: TruckTypeFilterPanel,
    filterList: truckTypeFilterList,
    resetFilterState: resetTruckTypeFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    TruckTypeFilterPanel,
    truckTypePayloadListByFilter: truckTypeFilterList,
    resetTruckTypeFilter,
  };
}

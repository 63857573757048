import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  AccountCircle,
  AssignmentReturnOutlined,
  CardGiftcardOutlined,
  ContentPasteSearch,
  Group,
  Inventory2Outlined,
  Place,
  PrecisionManufacturingOutlined,
  Receipt,
  WarehouseOutlined,
} from "@mui/icons-material";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { Tooltip } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  FULFILLMENT_AUTH_ATOMS,
  FULFILLMENT_AUTH_SELECTORS,
} from "states/auth";

import { AdminMenuTree } from "types/adminMenu";
import { AuthorityLabelDict } from "utils/fulfillment";

import LayoutForAdmin, {
  MENU_ICON_COLOR_STYLE,
} from "components/LayoutForAdmin";

const MENU_TREE: AdminMenuTree = [
  {
    label: "창고 관리",
    mainMenuList: [
      {
        type: "parent",
        icon: (
          <CardGiftcardOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "상품 관리",
        subMenuList: [
          {
            type: "link",
            label: "상품 리스트",
            link: "/product",
          },
        ],
      },
      {
        type: "parent",
        icon: (
          <WarehouseOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "입고 관리",
        subMenuList: [
          {
            type: "link",
            label: "입고 요청 리스트",
            link: "/receiving/requests",
          },
          {
            type: "link",
            label: "입고 작업 리스트",
            link: "/receiving/tasks",
          },
          {
            type: "link",
            label: "입고 완료 리스트",
            link: "/receiving/completed",
          },
          {
            type: "link",
            label: "입고 취소/회송 리스트",
            link: "/receiving/canceled",
          },
        ],
      },
      {
        type: "parent",
        icon: (
          <PrecisionManufacturingOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "출고 관리",
        subMenuList: [
          {
            type: "link",
            label: "국내 출고",
            link: "/shipping/domestic-beta?category=PARCEL&status=WAITING_TODAY",
          },
          {
            type: "link",
            label: "해외 출고",
            link: "/shipping/overseas?category=EXPRESS&status=WAITING_TODAY",
          },
          {
            type: "link",
            label: "피킹 리스트",
            link: "/shipping/pickings",
          },
          {
            type: "link",
            label: "국내 출고 (이전 버전)",
            link: "/shipping/domestic?category=PARCEL&status=WAITING_TODAY",
          },
        ],
      },
      {
        type: "parent",
        icon: (
          <Inventory2Outlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "재고 관리",
        subMenuList: [
          {
            type: "link",
            label: "재고 리스트",
            link: "/inventory/list",
          },
          {
            type: "link",
            label: "재고 리스트(SKU기준)",
            link: "/inventory/list/new",
          },
          {
            type: "link",
            label: "재고 관리 내역",
            link: "/inventory/adjustment/list",
          },
        ],
      },
      {
        type: "parent",
        icon: (
          <AssignmentReturnOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "반품 관리",
        subMenuList: [
          {
            type: "link",
            label: "반품 리스트",
            link: "/returning/list",
          },
        ],
      },
      {
        type: "parent",
        icon: <Group fontSize="small" sx={{ ...MENU_ICON_COLOR_STYLE }} />,
        label: "사용자 관리",
        subMenuList: [
          {
            type: "link",
            label: "매니저 리스트",
            link: "/user/manager/list",
          },
          {
            type: "link",
            label: "팀 리스트",
            link: "/user/team/list",
          },
          {
            type: "link",
            label: "고객 리스트",
            link: "/user/customer/list",
          },
        ],
      },
      {
        type: "parent",
        icon: <Place fontSize="small" sx={{ ...MENU_ICON_COLOR_STYLE }} />,
        label: "로케이션 관리",
        subMenuList: [
          {
            type: "link",
            label: "로케이션 리스트",
            link: "/location/list",
          },
        ],
      },
    ],
  },
  {
    label: "유틸리티",
    mainMenuList: [
      {
        type: "link",
        icon: (
          <ContentPasteSearch
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "주소 조회",
        link: "/utility/address-check",
      },

      {
        type: "parent",
        icon: <Receipt fontSize="small" sx={{ ...MENU_ICON_COLOR_STYLE }} />,
        label: "송장 관리",
        subMenuList: [
          {
            type: "link",
            label: "송장 출력 테스트",
            link: "/utility/invoice/test-issue",
          },
          {
            type: "link",
            label: "추가 송장 생성",
            link: "/utility/invoice/add",
          },
        ],
      },

      {
        type: "parent",
        icon: (
          <WarehouseOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "입고 관리",
        subMenuList: [
          {
            type: "link",
            label: "입고 상태 되돌리기 (센터용)",
            link: "/utility/receiving/revert-status",
          },
          {
            type: "link",
            label: "입고 도착일 수정",
            link: "/utility/receiving/expected-date-modify",
          },
          {
            type: "link",
            label: "입고 내역 조회",
            link: "/utility/receiving/sku-detail",
          },
        ],
      },

      {
        type: "parent",
        icon: (
          <PrecisionManufacturingOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "출고 관리",
        subMenuList: [
          {
            type: "link",
            label: "합포장",
            link: "/utility/shipping/packing-combine",
          },
          {
            type: "link",
            label: "출고 내역 받기",
            link: "/utility/shipping/sku-detail",
          },
          {
            type: "link",
            label: "강제 피킹 처리",
            link: "/utility/shipping/force-picking/create",
          },
          {
            type: "link",
            label: "출고 상태 변경",
            link: "/utility/shipping/update-dev",
          },
          {
            type: "link",
            label: "출고 정보 수정",
            link: "/utility/shipping/update-shipping",
          },
          {
            type: "link",
            label: "피킹 로케이션 변경",
            link: "/utility/shipping/update-picking-location",
          },
          {
            type: "link",
            label: "출고 상품 수량 수정(차감)",
            link: "/utility/shipping/update-item-quantity",
          },
        ],
      },

      {
        type: "parent",
        icon: (
          <Inventory2Outlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "재고 관리",
        subMenuList: [
          {
            type: "link",
            label: "불량 재고 수정",
            link: "/utility/inventory/faulty-inventory-modify",
          },
          {
            type: "link",
            label: "유통 상품 재고 등록",
            link: "/utility/inventory/force-create-inventory",
          },
        ],
      },
      {
        type: "parent",
        icon: (
          <AssignmentReturnOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "반품 관리",
        subMenuList: [
          {
            type: "link",
            label: "반품 상태 되돌리기 (센터용)",
            link: "/utility/returning/revert-status",
          },
          {
            type: "link",
            label: "반품 수거방식 수정",
            link: "/utility/returning/patch-is-already-applied",
          },
          {
            type: "link",
            label: "반품 상태 변경 (개발환경)",
            link: "/utility/returning/moon-test",
          },
        ],
      },
      {
        type: "parent",
        icon: (
          <AssignmentReturnOutlined
            fontSize="small"
            sx={{ ...MENU_ICON_COLOR_STYLE }}
          />
        ),
        label: "OMS 관리",
        subMenuList: [
          {
            type: "link",
            label: "OMS 주문 단건조회",
            link: "/utility/oms/single-order",
          },
          {
            type: "link",
            label: "OMS 주문 범위 수집",
            link: "/utility/oms/collect-orders",
          },
        ],
      },
    ],
  },
];

function Layout({
  title,
  breadcrumbs,
  children,
}: {
  title?: string;
  breadcrumbs?: string[];
  children: React.ReactNode;
}) {
  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const history = useHistory();

  const setLoggedIn = useSetRecoilState(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

  const handleLogout = useCallback(() => {
    history.replace("/");
    window.localStorage.clear();
    setLoggedIn(false);
  }, [history, setLoggedIn]);

  return (
    <LayoutForAdmin
      type="boful"
      menuTree={MENU_TREE}
      logo={
        <>
          <img src="/images/logo_boful_web.svg" alt="logo" />
          Boful 관리자
        </>
      }
      headerItems={[
        <div className="name" key={0}>
          {currentUser?.name} (
          {AuthorityLabelDict[currentUser?.authority || ""]})
        </div>,
        <Tooltip title="내 정보" key={1}>
          <div className="mypage" onClick={() => history.push("/mypage")}>
            <AccountCircle sx={{ color: "#9e9e9e", fontSize: "24px" }} />
          </div>
        </Tooltip>,
      ]}
      onLogout={handleLogout}
      title={title}
      breadcrumbs={breadcrumbs}
    >
      {children}
    </LayoutForAdmin>
  );
}

export default Layout;

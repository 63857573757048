import { FulfillmentParcelCompany } from "types/fulfillment";

import { InputSelectOption } from "headlessComponents/input/useInputSelect";

const PARCEL_COMPANY_OPTION_LIST: InputSelectOption<FulfillmentParcelCompany>[] =
  [
    { label: "CJ대한통운", value: "cj" },
    { label: "우체국택배", value: "post" },
    { label: "한진택배", value: "hanjin" },
    { label: "대신택배", value: "daesin" },
    { label: "건영택배", value: "kunyoung" },
    { label: "천일택배", value: "chunil" },
    { label: "경동택배", value: "kd" },
    { label: "UPS", value: "ups" },
    { label: "FEDEX", value: "fedex" },
  ];

const RECEIVING_PARCEL_COMPANY_OPTION_LIST: InputSelectOption<
  FulfillmentParcelCompany | "directInput"
>[] = [
  ...PARCEL_COMPANY_OPTION_LIST,
  { label: "롯데택배", value: "lotte" },
  { label: "로젠택배", value: "logen" },
  { label: "직접입력", value: "directInput" },
];

export { PARCEL_COMPANY_OPTION_LIST, RECEIVING_PARCEL_COMPANY_OPTION_LIST };

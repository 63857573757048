import Portal from "components/Portal";
import Barcode from "components/sds-v1/Barcode";
import Button from "components/sds-v1/button/Button";
import Icon from "components/sds-v1/Icon";
import useModal, { ModalPropsV1 } from "headlessComponents/useModal";
import { COLOR } from "styles/constants";

import ConfirmBeforeCloseModal from "./ConfirmBeforeCloseModal";
import Styled from "./index.styles";

/**
 * - contentWithCustomBody: 타이틀만 있고, 내용은 자유롭게
 * TODO: Modal은 _sds-v1으로 이전 중 이슈가 있어 headless인 'useModal'까지만 만들어둔 상태 (Task: AB#7044)
 */
function ModalComponent({
  active,
  title,
  onClose,
  allowsOverflow,
  barcodeValues, // Boful > 패킹화면의 Barcode 형식의 모달용
  needConfirmBeforeCloseModal,
  needConfirmBeforeCloseModalInfo,
  usePortal,
  className,
  ...propsByType
}: ModalPropsV1) {
  const {
    handleClickCloseModal,
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  } = useModal({
    active,
    onClose,
    needConfirmBeforeCloseModal,
  });

  if (!active) {
    return null;
  }

  const closeIconSize = propsByType.uiType === "formInput" ? 1.5 : 2;

  return (
    <>
      <Styled.containerWrapper
        className={`${className ? className : ""} modal`}
        onClick={(e) => e.stopPropagation()}
      >
        <Styled.container
          uiType={propsByType.uiType}
          onScroll={(e) => {
            e.stopPropagation();
          }}
          allowsOverflow={allowsOverflow}
          hasBorder={
            propsByType.uiType === "formInput" && propsByType.hasBorder
          }
          disableMaxHeight={
            propsByType.uiType === "formInput" && propsByType.disableMaxHeight
          }
          isWebNegativeTitle={
            propsByType.uiType === "titleOnly" && propsByType.isWebNegativeTitle
          }
          isWebNegativeActionPositive={
            propsByType.uiType === "titleOnly" &&
            propsByType.isWebNegativeActionPositive
          }
          className="modal-contents"
        >
          <div className="header">
            <div className="title">{title}</div>

            {propsByType.uiType === "formInput" && (
              <div className="desc">{propsByType.desc}</div>
            )}

            {onClose && (
              <Icon
                onClick={handleClickCloseModal}
                type="clear"
                size={closeIconSize}
                color={COLOR.grayScale_800}
              />
            )}
          </div>

          {(propsByType.uiType === "webNegative" ||
            propsByType.uiType === "content" ||
            propsByType.uiType === "contentWithCustomBody" ||
            propsByType.uiType === "formInput") &&
            propsByType.body && <div className="body">{propsByType.body}</div>}

          {propsByType.uiType !== "formInput" &&
            !barcodeValues &&
            (propsByType.actionPositive || propsByType.actionNegative) && (
              <div className="actions">
                {propsByType.actionNegative && (
                  <div
                    className="negative"
                    onClick={(e) => {
                      if (propsByType.actionNegative) {
                        propsByType.actionNegative.handleClick();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {propsByType.actionNegative.label}
                  </div>
                )}

                {propsByType.actionPositive && (
                  <div
                    className="positive"
                    onClick={(e) => {
                      if (propsByType.actionPositive) {
                        propsByType.actionPositive.handleClick();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {propsByType.actionPositive.label}
                  </div>
                )}
              </div>
            )}

          {propsByType.uiType === "formInput" && propsByType.submitButton && (
            <div className="submit-button-container">
              {propsByType.submitButton}
            </div>
          )}

          {propsByType.uiType !== "formInput" &&
            !!barcodeValues &&
            (propsByType.actionPositive || propsByType.actionNegative) && (
              <div className="barcode-actions">
                {barcodeValues.actionNegative && propsByType.actionNegative && (
                  <div className="barcode-action-container">
                    <Barcode
                      value={barcodeValues.actionNegative}
                      options={{
                        displayValue: false,
                        margin: 0,
                      }}
                    />
                    <Button
                      theme="dangerStroke"
                      size="block"
                      label={propsByType.actionNegative.label}
                      handleClick={(e) => {
                        if (propsByType.actionNegative) {
                          propsByType.actionNegative.handleClick();
                          e.stopPropagation();
                        }
                      }}
                    />
                  </div>
                )}

                {barcodeValues.actionPositive && propsByType.actionPositive && (
                  <div className="barcode-action-container">
                    <Barcode
                      value={barcodeValues.actionPositive}
                      options={{
                        displayValue: false,
                        margin: 0,
                      }}
                    />
                    <Button
                      theme="secondary"
                      size="block"
                      label={propsByType.actionPositive.label}
                      handleClick={(e) => {
                        if (propsByType.actionPositive) {
                          propsByType.actionPositive.handleClick();
                          e.stopPropagation();
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
        </Styled.container>

        <Styled.dimmedBackground onClick={handleClickCloseModal} />
      </Styled.containerWrapper>

      {onClose && isVisibleConfirmBeforeCloseModal && (
        <ConfirmBeforeCloseModal
          setIsVisibleModal={setIsVisibleConfirmBeforeCloseModal}
          actionPositiveHandleClick={() => onClose()}
          needConfirmBeforeCloseModalInfo={needConfirmBeforeCloseModalInfo}
        />
      )}
    </>
  );
}

export default function Modal(props: Parameters<typeof ModalComponent>[0]) {
  return props.usePortal ? (
    <Portal selector="#app-portal">
      <ModalComponent {...props} />
    </Portal>
  ) : (
    <ModalComponent {...props} />
  );
}

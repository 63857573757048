import { LocationActionType, LocationKind } from "types/location";

function getLocationKindLabel(locationKind: LocationKind | undefined) {
  switch (locationKind) {
    case "receiving":
      return "입고";
    case "shipping":
      return "출고";
    case "discard":
      return "폐기";
    case "cancel":
      return "취소";
    case "putAway":
      return "재고보관";
    default:
      return "-";
  }
}

function getLocationActionType(
  locationActionType: LocationActionType | undefined
) {
  switch (locationActionType) {
    case "receiving":
      return "입고";
    case "shipping":
      return "출고";
    case "inventoryAdjustment":
      return "조정";
    case "returning":
      return "반품";
    default:
      return "-";
  }
}

function getFormattedActionId({
  actionId,
  actionType,
}: {
  actionId: string | undefined;
  actionType: LocationActionType | undefined;
}) {
  if (!actionId) {
    return "";
  }

  if (actionType === "returning") {
    return `R${actionId}`; // 백엔드 요청으로 반품의 경우에만 프론트에서 prefix를 붙여줌
  }

  return actionId;
}

export { getLocationKindLabel, getLocationActionType, getFormattedActionId };

import { atom } from "recoil";

import { BofulWorker } from "types/auth";
import { BofulLocation } from "types/location";

const WORKER_LIST = atom<BofulWorker[]>({
  key: "fulfillment/common/atoms/WORKER_LIST",
  default: [],
});

const LOCATION_LIST_OF_WAREHOUSE = atom<BofulLocation[]>({
  key: "common/atoms/LOCATION_LIST_OF_WAREHOUSE",
  default: [],
});

const CAN_NOT_LEAVE_PAGE = atom<boolean>({
  key: "common/atoms/CAN_NOT_LEAVE_PAGE",
  default: false,
});

export default {
  WORKER_LIST,
  LOCATION_LIST_OF_WAREHOUSE,
  CAN_NOT_LEAVE_PAGE,
};

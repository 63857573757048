import React from "react";

import useButton, { ButtonPropsV1 } from "headlessComponents/button/useButton";
import Icon from "../../Icon";
import Styled from "./index.styles";

// TODO: 아이콘이 있을때 & 남은 Theme(input, text)
export default function Button({
  theme,
  size,
  label,
  handleClick,
  disabled,
  icon,
  className,
}: ButtonPropsV1) {
  const { iconSize } = useButton({ size });

  return (
    <Styled.container
      className={`${className ? className : ""} button`}
      theme={theme}
      size={size}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled) {
          handleClick(e);
        }
      }}
      iconPosition={icon?.position}
    >
      {icon?.position === "left" && <Icon type={icon.type} size={iconSize} />}

      <div className="label">{label}</div>

      {icon?.position === "right" && <Icon type={icon.type} size={iconSize} />}
    </Styled.container>
  );
}

import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { SendRequestOptions, SendRequestOptionsData } from "types/common";
import { MINUTE_AS_MILLISECONDS } from "utils/date";
import { getBaseURLByAPIType } from "utils/common";

const API = axios.create({
  baseURL: getDefaultBaseURLByAppName(),
  timeout: MINUTE_AS_MILLISECONDS,
  responseType: "json",
});

API.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, requestErrorHandler);

function requestErrorHandler(error: AxiosError<unknown>) {
  if (error.response?.status === 401) {
    return noAuthHandler(error);
  }

  return Promise.reject(error);
}

async function noAuthHandler(error: AxiosError<unknown>) {
  const isAppNeedToRefreshToken = getIsAppNeedToRefreshToken();

  const newToken = isAppNeedToRefreshToken ? await getNewToken() : null;

  if (!newToken) {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");

    window.sessionStorage.removeItem("accessToken");
    window.sessionStorage.removeItem("refreshToken");

    const backTo = window?.history?.state?.as;

    if (backTo) {
      window.location.href = `/login?backTo=${backTo}`;
    } else {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }

  const { config } = error;

  const failedRequestConfig: AxiosRequestConfig = {
    baseURL: config.baseURL,
    method: config.method,
    params: config.params,
    data: config.data,
    url: config.url,
    headers: {
      ...config.headers,
      authorization: `Bearer ${newToken}`,
    },
  };

  return API(failedRequestConfig);
}

async function getNewToken() {
  const isAutoLogin = window.localStorage.getItem("accessToken");

  const accessToken =
    window.localStorage.getItem("accessToken") ||
    window.sessionStorage.getItem("accessToken");
  const refreshToken =
    window.localStorage.getItem("refreshToken") ||
    window.sessionStorage.getItem("refreshToken");

  if (!accessToken || !refreshToken) {
    return null;
  }

  try {
    const { data } = await axios.post(
      `${getDefaultBaseURLByAppName()}/auth/refresh`,
      {
        accessToken,
        refreshToken,
      }
    );

    if (isAutoLogin) {
      window.localStorage.setItem("accessToken", data.accessToken);
    } else {
      window.sessionStorage.setItem("accessToken", data.accessToken);
    }

    // 초대내역 모달을 띄우기 위한 flag
    window.localStorage.setItem("needCheckTeamInvitation", "TRUE");

    return data.accessToken;
  } catch (e) {
    return null;
  }
}

export async function sendRequest(options: SendRequestOptions) {
  const headers: {
    authorization?: string;
    pragma?: string;
    locale?: string;
    "Content-Type"?: string;
  } = {
    pragma: "no-cache",
    ...(options.contentType
      ? {
          "Content-Type": options.contentType,
        }
      : {}),
  };

  const accessToken =
    typeof window !== "undefined" &&
    (window.localStorage.getItem("accessToken") ||
      window.sessionStorage.getItem("accessToken"));

  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
  }

  const config: AxiosRequestConfig = {
    method: options.method,
    params: options.params,
    /**
     * TODO: _customPath사용하는 곳 없어지면 삭제 (pathParams를 사용하는 방식으로 수정중)
     * request할때 _customPath를 보내면 그것을 사용
     */
    url: options.data?._customPath || options.path,
    data: getValidRequestData(options.data),
    headers,

    responseType: options.responseType ?? "json",
    signal: options.signal,
  };

  if (options.isLocalMiddleWare) {
    delete config?.headers?.authorization;
    delete config?.headers?.pragma;
    delete config?.headers?.locale;
  }

  // apiType을 특정하는 경우 baseURL을 바꿔준다
  if (options.apiType) {
    config.baseURL = getBaseURLByAPIType(options.apiType);
  }

  return API(config);
}

function getValidRequestData(requestData?: SendRequestOptionsData) {
  if (!requestData) return;

  // 실제 요청 payload와 관계없는 속성은 삭제
  delete requestData.pathParams;
  // TODO: _customPath사용하는 곳 없어지면 삭제 (pathParams를 사용하는 방식으로 수정중)
  delete requestData._customPath;

  return requestData;
}

/**
 * App별로 기본 BaseURL를 가져온다
 */
function getDefaultBaseURLByAppName(): string {
  return process.env.REACT_APP_API_URL || "";
}

/**
 * 자동로그인이 되면 안되는 앱은 새 토큰을 받아서 재요청하는 처리를 하지 않는다.
 */
function getIsAppNeedToRefreshToken(): boolean {
  return false;
}

import { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { CancelOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import ConfirmModal from "components/ConfirmModal";

import ResultMessage from "./ResultMessage";

export default function CancelShipping({
  selectedShippingIdList,
  ...paramsByType
}: {
  selectedShippingIdList: number[];
} & (
  | {
      type: "detail";
      onAfterCancel: () => void;
    }
  | {
      type: "list";
    }
)) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isResultMessageOpen, setIsResultMessageOpen] = useState(false);

  const messageList = useRef<{ shippingId: number; message: string }[]>([]);

  const queryClient = useQueryClient();

  const {
    mutateAsync: cancelShipping,
    ResponseHandler: ResponseHandlerOfCancelShipping,
  } = SHIPPING_QUERY.useCancelShippingForAdmin({
    onError: (err, hideFailureModal) => {
      hideFailureModal();

      const errorMessage =
        err?.code === 422
          ? err?.error ?? "해당 의뢰는 취소할 수 없는 상태입니다."
          : "출고 취소 중에 오류가 발생했습니다.";

      messageList.current = messageList.current.map((messageItem) =>
        messageItem.message === "emptyMessage"
          ? { ...messageItem, message: errorMessage }
          : messageItem
      );

      handleConfirmModalClose();
      handleResultMessageOpen();
    },
  });

  const handleShippingCancel = async () => {
    for (const shippingId of selectedShippingIdList) {
      messageList.current = [
        ...messageList.current,
        { shippingId, message: "emptyMessage" },
      ];

      const {
        data: { result },
      } = await cancelShipping({ shippingId });

      messageList.current = messageList.current.map((messageItem) =>
        messageItem.shippingId === shippingId
          ? { ...messageItem, message: result }
          : messageItem
      );
    }

    handleConfirmModalClose();
    handleResultMessageOpen();
  };

  const handleConfirmModalOpen = () => setIsConfirmModalOpen(true);

  const handleConfirmModalClose = () => setIsConfirmModalOpen(false);

  const handleResultMessageOpen = () => setIsResultMessageOpen(true);

  const handleResultMessageClose = () => {
    queryClient
      .invalidateQueries(SHIPPING_QUERY_KEY_GEN.getAdminShippingList({}))
      .then(() => {
        setIsResultMessageOpen(false);
        messageList.current = [];
      });

    if (paramsByType.type === "detail") {
      paramsByType.onAfterCancel();
    }
  };

  return (
    <>
      <Button
        startIcon={<CancelOutlined />}
        variant="outlined"
        color="error"
        onClick={handleConfirmModalOpen}
        disabled={!selectedShippingIdList.length}
      >
        출고 취소
      </Button>

      <ConfirmModal
        isConfirmModalOpen={isConfirmModalOpen}
        title="선택한 출고 건을 취소하시겠습니까?"
        onConfirmModalClose={handleConfirmModalClose}
        actionPositive={{
          label: "확인",
          onClick: handleShippingCancel,
        }}
        actionNegative={{
          label: "취소",
          onClick: handleConfirmModalClose,
        }}
      />

      <Dialog open={isResultMessageOpen} onClose={handleResultMessageClose}>
        <DialogTitle>출고 취소 결과</DialogTitle>

        <DialogContent dividers>
          {messageList.current.map(({ shippingId, message }) => (
            <ResultMessage
              key={shippingId}
              shippingId={shippingId}
              message={message}
            />
          ))}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleResultMessageClose}>
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfCancelShipping}
    </>
  );
}

import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Paper,
  Stack,
} from "@mui/material";

import { ReceivingItem, ReceivingStatus } from "types/receiving";
import {
  getFormattedSingleSkuId,
  getReceivingAndInspectionProblemString,
} from "utils/fulfillment";

interface ProblemsData {
  status: ReceivingStatus;
  receivingItems: ReceivingItem[];
}

export default function useProblemsModal() {
  const [currentPage, setCurrentPage] = useState(0);
  const [problemsData, setProblemsData] = useState<ProblemsData>();

  const problemsList = useMemo(() => {
    if (!problemsData) {
      return [];
    }

    return problemsData.receivingItems.filter((item) => {
      const hasInspectionProblem = item.inspectionProblem !== "none";
      const hasReceivingProblem = item.receivingProblem !== "none";

      return hasInspectionProblem || hasReceivingProblem;
    });
  }, [problemsData]);

  const showProblemsModal = useCallback(
    ({
      status,
      receivingItems,
    }: {
      status: ReceivingStatus;
      receivingItems: ReceivingItem[];
    }) => {
      setProblemsData({ status, receivingItems });
    },
    []
  );

  const handleClose = useCallback(() => {
    setProblemsData(undefined);
    setCurrentPage(0);
  }, []);

  const handleCurrentPageChange = useCallback(
    (e: ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page - 1);
    },
    []
  );

  const ProblemsModal = useMemo(() => {
    if (!problemsList.length) {
      return null;
    }

    // 사유는 최신 업데이트된 내역이 노출
    const hasWarehousingProblem =
      problemsList[currentPage].receivingProblem !== "none";
    const problemStatus = hasWarehousingProblem
      ? problemsList[currentPage].receivingProblem
      : problemsList[currentPage].inspectionProblem;
    const directInput = hasWarehousingProblem
      ? problemsList[currentPage].receivingProblemDirectInput
      : problemsList[currentPage].inspectionProblemDirectInput;

    return (
      <Dialog open={!!problemsList.length} onClose={handleClose}>
        <DialogTitle>보고내역</DialogTitle>

        <DialogContent>
          <Stack flexDirection="column" alignItems="center">
            <Paper variant="outlined" sx={{ minWidth: 360 }}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="상품명"
                    secondary={problemsList[currentPage].sku?.itemName}
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText primary="유형" secondary="상품" />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary="SKU ID"
                    secondary={getFormattedSingleSkuId(
                      problemsList[currentPage].skuId
                    )}
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary="상품바코드"
                    secondary={problemsList[currentPage].sku?.barCode ?? "-"}
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary="판매자상품코드"
                    secondary={
                      problemsList[currentPage].sku?.productCode ?? "-"
                    }
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary="옵션관리코드"
                    secondary={
                      problemsList[currentPage].sku?.managementCode ?? "-"
                    }
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary="입고요청수량(PCS)"
                    secondary={problemsList[currentPage].quantity}
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary="검수수량(PCS)"
                    secondary={problemsList[currentPage].actualQty ?? "-"}
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary="사유"
                    secondary={getReceivingAndInspectionProblemString(
                      problemStatus,
                      directInput
                    )}
                  />
                </ListItem>
              </List>
            </Paper>

            <Pagination
              sx={{ mt: 2 }}
              count={problemsList?.length}
              page={currentPage + 1}
              onChange={handleCurrentPageChange}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }, [currentPage, handleClose, handleCurrentPageChange, problemsList]);

  return {
    showProblemsModal,
    ProblemsModal,
  };
}

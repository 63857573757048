import { useMemo, useState } from "react";

import USER_QUERY from "queries/USER_QUERY";
import { Team, TeamMasterInfo } from "types/user";
import { toFormattedDate } from "utils/date";
import { toThousandUnitFormat } from "utils/number";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import TeamDetailModal from "./TeamDetailModal";

type CellId =
  | Extract<
      keyof Team,
      | "id"
      | "teamCompany"
      | "name"
      | "fulfillmentManagerName"
      | "appliedFulfillmentAt"
    >
  | keyof TeamMasterInfo;

const headCellList: TableHeadCell<CellId>[] = [
  {
    id: "id",
    disablePadding: false,
    label: "팀 코드",
    width: 100,
  },
  {
    id: "teamCompany",
    disablePadding: false,
    label: "회사명",
    width: 120,
  },
  {
    id: "name",
    disablePadding: false,
    label: "팀명",
    width: 120,
  },
  {
    id: "masterName",
    disablePadding: false,
    label: "마스터 이름",
    width: 120,
  },
  {
    id: "masterPhone",
    disablePadding: false,
    label: "연락처",
    width: 150,
  },
  {
    id: "masterEmail",
    disablePadding: false,
    label: "이메일",
    width: 250,
  },
  {
    id: "fulfillmentManagerName",
    disablePadding: false,
    label: "풀필먼트 매니저",
    width: 120,
  },
  {
    id: "appliedFulfillmentAt",
    disablePadding: false,
    label: "풀필먼트 신청일",
    width: 120,
  },
];

function TeamList() {
  const [selectedTeamId, setSelectedTeamId] = useState<number>();

  const { data: teamList, ResponseHandler: ResponseHandlerOfTeamList } =
    USER_QUERY.useGetTeamList();

  const rowList: TableBodyRow<CellId>[] = useMemo(() => {
    if (!teamList) return [];

    return teamList.map((v) => {
      const row: TableBodyRow<CellId> = {
        id: v.id,
        teamCompany: v.teamCompany,
        name: v.name,
        masterName: v.masterInfo.masterName,
        masterPhone: v.masterInfo.masterPhone,
        masterEmail: v.masterInfo.masterEmail,
        fulfillmentManagerName: v.fulfillmentManagerName,
        appliedFulfillmentAt: toFormattedDate(
          v.appliedFulfillmentAt,
          `YYYY.MM.DD`
        ),

        handleRowClick: () => setSelectedTeamId(v.id),
      };

      return row;
    });
  }, [teamList]);

  const handleTeamDetailModalClose = () => setSelectedTeamId(undefined);

  return (
    <Layout>
      <Table
        title={`팀 리스트 (총 ${toThousandUnitFormat(teamList?.length)})`}
        headCells={headCellList}
        rows={rowList}
      />

      <TeamDetailModal
        key={selectedTeamId}
        teamId={selectedTeamId}
        onClose={handleTeamDetailModalClose}
      />

      {ResponseHandlerOfTeamList}
    </Layout>
  );
}

export default withRequireAuth(TeamList);

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GET_ADMIN_RETURNING_LIST_REQ_DATE_KIND,
  GET_ADMIN_RETURNING_LIST_REQ_SEARCH_KIND,
  GET_ADMIN_RETURNING_LIST_RES,
} from "api-interfaces/returning";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import COMMON_QUERY from "queries/COMMON_QUERY";
import RETURNING_QUERY from "queries/RETURNING_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useGetCSVDownloadInfoFromQuery from "hooks/useGetCSVDownloadInfoFromQuery";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "hooks/useSearchWithDate";
import useSearchWithTerm, { TermSearchType } from "hooks/useSearchWithTerm";
import { sendRequest } from "services/request";
import { ReturningListItem } from "types/returning";
import { ProductGroupIdItem } from "types/sku";
import { toFormattedDate } from "utils/date";
import {
  getDeliveryName,
  getDeliveryNumberOfShipping,
  getFulfillmentItemTitle,
  getSkuIdListForAdmin,
} from "utils/fulfillment";
import {
  checkNeedToDisplayReturningInvoice,
  getDeliveryNumberOfReturning,
  getReturningType,
  getUnitedStatus,
  RETURNING_LIST_FILTER_STATUS_DICT,
  RETURNING_PROCESS_STATUS_DICT,
  toDeliveryTypeLabel,
} from "utils/returning";
import { translateSaleChannel } from "utils/shipping";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import ProcessStatus from "./ProcessStatus";
import UnitedStatus from "./UnitedStatus";
import useChangeProcessStatus from "./useChangeProcessStatus";
import useFilterStatusFilter from "./useFilterStatusFilter";
import useRequestPickupUncollected from "./useRequestPickupUncollected";
import useReturningDetailModal from "./useReturningDetailModal";
import useSubmitReturningInvoiceNo from "./useSubmitReturningInvoiceNo";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_RETURNING_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "customerName",
    },
    {
      label: "반품요청번호",
      value: "id",
    },
    {
      label: "원송장번호(화물차종)",
      value: "originInvoiceNo",
    },
    {
      label: "반품송장번호(차량번호)",
      value: "invoiceNo",
    },
    {
      label: "반품 보낸 분",
      value: "applicantName",
    },
    {
      label: "SKU ID",
      value: "skuId",
    },
  ];

type CellId =
  | Extract<
      keyof ReturningListItem,
      | "id"
      | "createdAt"
      | "receivedAt"
      | "applicantName"
      | "parcelCompany"
      | "invoiceNo"
      | "processStatus"
      | "requests"
    >
  | "companyName"
  | "teamName"
  | "warehouse"
  | "originalParcelCompany"
  | "originalInvoiceNo"
  | "shippingId"
  | "itemsName"
  | "unitedStatus"
  | "skuIdList"
  | "orderQuantity"
  | "shippingQuantity"
  | "returnShippingQuantity"
  | "channel"
  | "orderNo"
  | "isAlreadyApplied";

const dateSearchTypeOptions: SearchWithDateTypeOption<GET_ADMIN_RETURNING_LIST_REQ_DATE_KIND>[] =
  [
    {
      label: "반품신청일",
      value: "createdAt",
    },
    {
      label: "수령완료일",
      value: "receivedAt",
    },
    {
      label: "반품입고완료일",
      value: "completeAt",
    },
  ];

function ReturningList() {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions,
    });

  const { FilterStatusFilterPanel, filterStatus } = useFilterStatusFilter();

  const downloadReturningListExcel = () => {
    sendRequest({
      method: "POST",
      path: "/returning/admin/excel/download",
      apiType: "BofulDefault",
      responseType: "blob",
      params: {
        ...(termSearchType?.value && debouncedSearchTerm
          ? {
              searchKind: termSearchType?.value,
              searchTerm: debouncedSearchTerm,
            }
          : {}),
        ...(dateSearchType && startDate && endDate
          ? {
              dateKind: dateSearchType,
              dateFrom: new Date(startDate),
              dateTo: new Date(endDate),
            }
          : {}),
        ...(filterStatus ? { filterStatus } : {}),
      },
    }).then((response) => {
      const blobData = new Blob([response.data]);
      const filename = `반품_리스트 (${dayjs()
        .add(9, "hours")
        .format("YYYY-MM-DD-HHmmss")}).xlsx`;

      fileDownload(blobData, filename);
      return;
    });
  };

  // 검색어가 바뀌면 페이징 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm]);

  // 페이지당 데이터 수 변환시 현재 페이지 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [perPage]);

  const headCells = useMemo((): TableHeadCell<CellId>[] => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "반품요청번호",
        width: 110,
      },
      {
        id: "shippingId",
        disablePadding: false,
        label: "출고요청번호",
        width: 110,
      },
      {
        id: "channel",
        disablePadding: false,
        label: "판매채널",
        width: 130,
      },
      {
        id: "orderNo",
        disablePadding: false,
        label: "주문번호",
        width: 200,
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "회사명",
        width: 160,
      },
      {
        id: "teamName",
        disablePadding: false,
        label: "팀명",
        width: 160,
      },
      {
        id: "unitedStatus",
        disablePadding: false,
        label: "반품현황",
        width: 200,
        filter: FilterStatusFilterPanel,
      },
      {
        id: "isAlreadyApplied",
        disablePadding: false,
        label: "택배수거방식",
        width: 120,
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "반품 신청일",
        width: 110,
      },
      {
        id: "receivedAt",
        disablePadding: false,
        label: "수령완료일",
        width: 110,
      },
      {
        id: "processStatus",
        disablePadding: false,
        label: "처리 결과",
        width: 180,
      },
      {
        id: "itemsName",
        disablePadding: false,
        label: "상품명",
        width: 200,
      },
      {
        id: "warehouse",
        disablePadding: false,
        label: "입고지",
        width: 110,
      },
      {
        id: "applicantName",
        disablePadding: false,
        label: "반품 보낸 분",
        width: 120,
      },
      {
        id: "parcelCompany",
        disablePadding: false,
        label: "반품택배사(화물차종)",
        width: 180,
      },
      {
        id: "invoiceNo",
        disablePadding: false,
        label: "반품송장번호(차량번호)",
        width: 240,
      },
      {
        id: "originalParcelCompany",
        disablePadding: false,
        label: "원택배사(화물차종)",
        width: 180,
      },
      {
        id: "originalInvoiceNo",
        disablePadding: false,
        label: "원송장번호(차량번호)",
        width: 240,
      },
      {
        id: "skuIdList",
        disablePadding: false,
        label: "SKU ID",
        width: 150,
      },
      {
        id: "orderQuantity",
        disablePadding: false,
        label: "주문수량(ORDER)",
        width: 120,
      },
      {
        id: "shippingQuantity",
        disablePadding: false,
        label: "출고수량(PCS)",
        width: 120,
      },
      {
        id: "returnShippingQuantity",
        disablePadding: false,
        label: "반품요청수량(PCS)",
        width: 120,
      },
      {
        id: "requests",
        disablePadding: false,
        label: "요청사항",
        width: 200,
      },
    ];
  }, [FilterStatusFilterPanel]);

  const { openReturningDetailModal, ReturningDetailModal } =
    useReturningDetailModal();

  const { openConfirmToChangeProcessStatus, ConfirmToChangeProcessStatus } =
    useChangeProcessStatus();

  const { openFormToSubmitReturningInvoiceNo, FormToSubmitReturningInvoiceNo } =
    useSubmitReturningInvoiceNo();

  const {
    openConfirmToRequestPickupUncollected,
    ConfirmToRequestPickupUncollected,
  } = useRequestPickupUncollected();

  const { channelDict } = COMMON_QUERY.useGetChannelList();

  const {
    ResponseHandler: ResponseHandlerOfReturningList,
    data: returningList,
    refetch: refetchReturningList,
  } = RETURNING_QUERY.useGetAdminReturningList({
    page: currentPage,
    perPage,
    ...(termSearchType?.value && debouncedSearchTerm
      ? {
          searchKind: termSearchType?.value,
          searchTerm: debouncedSearchTerm,
        }
      : {}),
    ...(dateSearchType && startDate && endDate
      ? {
          dateKind: dateSearchType,
          dateFrom: new Date(startDate),
          dateTo: new Date(endDate),
        }
      : {}),
    ...(filterStatus ? { filterStatus } : {}),
  });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: RETURNING_QUERY.useGetAdminReturningListForCSVDownload,
    queryArgs: [
      {
        ...(termSearchType?.value && debouncedSearchTerm
          ? {
              searchKind: termSearchType?.value,
              searchTerm: debouncedSearchTerm,
            }
          : {}),
        ...(dateSearchType && startDate && endDate
          ? {
              dateKind: dateSearchType,
              dateFrom: new Date(startDate),
              dateTo: new Date(endDate),
            }
          : {}),
        ...(filterStatus ? { filterStatus } : {}),
      },
    ],
  });

  const getShippingQuantityOfProductGroup = (
    groupInfo?: ProductGroupIdItem
  ) => {
    return groupInfo
      ? groupInfo.groupItems.reduce((prev, curr) => prev + curr.qty, 0)
      : 0;
  };

  const mapDataForTable = useCallback(
    (data: GET_ADMIN_RETURNING_LIST_RES | undefined, isForCSV?: boolean) => {
      if (!data?.list) return [];
      const list = isForCSV
        ? data.list
            .map(
              (returning) =>
                // csv 에는 출고 주문 상품 기준으로 행이 생겨야 한다
                returning.shipping?.orders.map((shippingOrder) => {
                  return {
                    shippingOrders: [shippingOrder],
                    returning: { ...returning },
                  };
                }) ?? []
            )
            .flat()
        : data.list.map((returning) => ({
            shippingOrders: returning.shipping?.orders ?? [],
            returning: { ...returning },
          }));

      return list.map((v) => {
        const needToDisplayReturningInvoice =
          checkNeedToDisplayReturningInvoice(v.returning.invoiceNo);

        const originalParcelCompany = getDeliveryName({
          deliveryType: v.returning.shipping?.deliveryType,
          parcelCompany: v.returning.shipping?.parcelCompany,
          truckType: v.returning.shipping?.trucking?.truckType,
        });

        const unitedStatus = getUnitedStatus({
          status: v.returning.status,
          deliveringStatus: v.returning.deliveringStatus,
          invoiceNo: v.returning.invoiceNo,
        });

        const itemsForTitle = v.shippingOrders.map((shippingOrder) => ({
          sku: { itemName: shippingOrder.itemName },
          quantity: shippingOrder.quantity,
        }));

        const row: TableBodyRow<CellId> = {
          id: `R${v.returning.id}`,
          shippingId: v.returning.shipping?.id,
          channel: translateSaleChannel(v.returning.shipping?.saleChannel),
          orderNo: v.returning.shipping?.orderNo,
          companyName: v.returning.team?.company,
          teamName: v.returning.team?.name,
          unitedStatus: isForCSV ? (
            unitedStatus ? (
              RETURNING_LIST_FILTER_STATUS_DICT[unitedStatus]
            ) : (
              ""
            )
          ) : (
            <UnitedStatus
              returningId={v.returning.id}
              shippingId={v.returning.shipping?.id}
              status={v.returning.status}
              unitedStatus={unitedStatus}
              originalParcelCompany={originalParcelCompany}
              shippingPackings={v.returning.shipping?.packings}
              openFormToSubmitReturningInvoiceNo={
                openFormToSubmitReturningInvoiceNo
              }
              openConfirmToRequestPickupUncollected={
                openConfirmToRequestPickupUncollected
              }
            />
          ),
          isAlreadyApplied: getReturningType(v.returning.isAlreadyApplied),
          createdAt: toFormattedDate(v.returning.createdAt, "YYYY-MM-DD"),
          receivedAt: toFormattedDate(v.returning.receivedAt, "YYYY-MM-DD"),
          processStatus: isForCSV ? (
            v.returning.processStatus ? (
              RETURNING_PROCESS_STATUS_DICT[v.returning.processStatus]
            ) : (
              ""
            )
          ) : (
            <ProcessStatus
              status={v.returning.status}
              deliveringStatus={v.returning.deliveringStatus}
              returningId={v.returning.id}
              returningProcessStatus={v.returning.processStatus}
              openConfirmToChangeProcessStatus={
                openConfirmToChangeProcessStatus
              }
            />
          ),
          itemsName: getFulfillmentItemTitle(itemsForTitle),
          warehouse: "인천1센터", // 한 동안 고정
          applicantName: v.returning.applicantName,
          parcelCompany: needToDisplayReturningInvoice
            ? v.returning.returningPackings
                ?.map((returningPacking) =>
                  getDeliveryName({
                    deliveryType: toDeliveryTypeLabel(v.returning.delivery),
                    parcelCompany: returningPacking.parcelCompany,
                    truckType: v.returning.driver?.freightType,
                  })
                )
                .join(", ")
            : "",
          invoiceNo: needToDisplayReturningInvoice
            ? getDeliveryNumberOfReturning({
                deliveryType: toDeliveryTypeLabel(v.returning.delivery),
                packings: v.returning.returningPackings,
                invoiceNo: v.returning.invoiceNo,
                truckNo: v.returning.driver?.truckNo,
                isFullList: true,
              })
            : "",
          originalParcelCompany,
          originalInvoiceNo: getDeliveryNumberOfShipping({
            deliveryType: toDeliveryTypeLabel(v.returning.delivery),
            packings: v.returning.shipping?.packings,
            trucking: v.returning.shipping?.trucking,
            invoiceNo: v.returning.invoiceNo,
            isFullList: true,
          }),
          skuIdList: getSkuIdListForAdmin(
            v.shippingOrders.map((order) => ({
              skuId: order.skuId ?? order.productGroupId,
              isGroup: !!order.productGroupId,
            }))
          ),
          orderQuantity: v.shippingOrders.reduce(
            (prev, curr) => prev + curr.quantity,
            0
          ),
          shippingQuantity: v.returning.shipping?.items.reduce(
            (prev, curr) => prev + curr.quantity,
            0
          ),
          returnShippingQuantity: v.returning.items.reduce(
            (prev: number, curr) => prev + curr.quantity,
            0
          ),
          requests: v.returning.requests,

          handleRowClick: () => openReturningDetailModal(v.returning.id),
        };

        return row;
      });
    },
    [
      openConfirmToChangeProcessStatus,
      openConfirmToRequestPickupUncollected,
      openFormToSubmitReturningInvoiceNo,
      openReturningDetailModal,
    ]
  );

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload, true);
  }, [dataForCSVDownload, mapDataForTable]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(returningList);
  }, [returningList, mapDataForTable]);

  return (
    <Layout>
      <Table
        title="반품 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100],
          totalCount: returningList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
        csvDownloadForSelectedRowsInfo={{
          filename: "",
          rowsForCSVDownload: [],
          onXlsxDownloadButtonClicked: downloadReturningListExcel,
          xlsxDownloadButtonDisabled: !returningList,
        }}
        toolbarItems={{
          left: [TermSearchPanel, <DateSearchPanel key="dateSearch" />],
        }}
      />

      {ReturningDetailModal}

      {ConfirmToChangeProcessStatus}

      {FormToSubmitReturningInvoiceNo}

      {ResponseHandlerOfReturningList}

      {ConfirmToRequestPickupUncollected}
    </Layout>
  );
}

export default withRequireAuth(ReturningList);

import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "queries/RECEIVING_QUERY";

import AdminMemoModal from "hooks/useAdminMemoModal/AdminMemoModal";
import useAdminMemoModal from "hooks/useAdminMemoModal/useAdminMemoModal";

export default function useReceivingAdminMemo() {
  const {
    showAdminMemoModal,

    handleClose,
    handleMemoChange,

    resetRequestId,
    resetMemo,

    requestId,
    memo,
  } = useAdminMemoModal();

  const queryClient = useQueryClient();

  const {
    data: receivingDetail,
    ResponseHandler: ResponseHandlerOfGetReceivingDetail,
  } = RECEIVING_QUERY.useGetManagerReceivingDetail({
    enabled: !!requestId,
    receivingId: requestId,
    onError: () => {
      resetRequestId();
    },
  });

  const {
    mutate: addAdminMemo,
    ResponseHandler: ResponseHandlerOfAddAdminMemo,
  } = RECEIVING_QUERY.useUpdateAdminReceivingDetail({
    failureModalInfo: {
      customizeMessage: () => ({
        title: "메모 등록 중에 오류가 발생했습니다.",
      }),
    },
  });

  const ResponseHandlerOfAdminMemo = useMemo(
    () => (
      <>
        {ResponseHandlerOfGetReceivingDetail}
        {ResponseHandlerOfAddAdminMemo}
      </>
    ),
    [ResponseHandlerOfGetReceivingDetail, ResponseHandlerOfAddAdminMemo]
  );

  const handleMemoAdd = useCallback(() => {
    addAdminMemo(
      { adminMemo: { memo }, pathParams: { receivingId: requestId } },
      {
        onSuccess: () => {
          resetMemo();

          queryClient.invalidateQueries(
            RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
              receivingId: requestId,
            })
          );
        },
      }
    );
  }, [memo, queryClient, requestId, resetMemo, addAdminMemo]);

  const ReceivingAdminModal = useMemo(
    () => (
      <AdminMemoModal
        requestId={requestId}
        adminMemoList={receivingDetail?.receiving.adminMemo}
        memo={memo}
        onMemoChange={handleMemoChange}
        onClose={handleClose}
        onMemoAdd={handleMemoAdd}
        ResponseHandlerOfAdminMemo={ResponseHandlerOfAdminMemo}
      />
    ),
    [
      ResponseHandlerOfAdminMemo,
      handleClose,
      handleMemoChange,
      handleMemoAdd,
      memo,
      receivingDetail?.receiving.adminMemo,
      requestId,
    ]
  );

  return {
    showAdminMemoModal,
    ReceivingAdminModal,
  };
}

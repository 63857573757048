import styled, { css } from "styled-components";

import { StateBadgeSize, StateBadgeType } from "./index";

const container = styled.div<{
  type: StateBadgeType;
  color: string;
  borderColor: string;
  backgroundColor: string;
  hasBorder: boolean;
  size: StateBadgeSize;
}>`
  display: inline-flex;
  border-radius: 62px;
  color: ${({ color }) => color};
  padding: ${({ size, hasBorder }) => {
    if (size === "normal") {
      return hasBorder ? "5px 7px" : "6px 8px";
    }
    if (size === "small") {
      return hasBorder ? "3px 7px" : "4px 8px";
    }
  }};
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ hasBorder, borderColor }) => {
    if (hasBorder) {
      return css`
        border: 1px solid ${borderColor};
      `;
    }
  }};

  > .icon {
    margin-right: 4px;
  }

  > .label {
    white-space: nowrap;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    font-size: ${({ size, type }) => {
      if (type === "withIcon") return "14px";

      return size === "normal" ? "14px" : "12px";
    }};
  }

  > .tooltip {
    margin-left: 4px;
  }
`;

export default {
  container,
};

import { useEffect, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { ADD_MANAGER_REQ } from "api-interfaces/user";
import USER_QUERY from "queries/USER_QUERY";
import { BofulAuthority } from "types/auth";
import { removeEmptyPropertiesOfObject } from "utils/common";
import { AuthorityLabelDict } from "utils/fulfillment";
import Modal from "components/Modal";

const initialFormInput: Partial<ADD_MANAGER_REQ> = {};

export default function useAddManager({
  authority,
  refetchManagerList,
}: {
  authority?: BofulAuthority;
  refetchManagerList: () => void;
}) {
  const canAddManager = useMemo(() => {
    return authority === "admin";
  }, [authority]);

  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);

  const { mutate: addManager, ResponseHandler: ResponseHandlerOfAddManager } =
    USER_QUERY.useAddManager({
      successModalInfo: {
        handleConfirmSuccess: (initQuery) => {
          initQuery();
          setIsVisibleFormModal(false);
          refetchManagerList();
        },
        customizeMessage: () => ({
          title: "매니저가 추가되었습니다.",
        }),
      },
    });

  const [formInput, setFormInput] =
    useState<Partial<ADD_MANAGER_REQ>>(initialFormInput);

  const isFormInputValidated = useMemo(() => {
    if (!formInput.accountId) return false;
    if (!formInput.authority) return false;
    if (!formInput.name) return false;
    if (!formInput.password) return false;
    if (!formInput.warehouseId) return false;

    return true;
  }, [formInput]);

  useEffect(() => {
    if (!isVisibleFormModal) setFormInput(initialFormInput);
  }, [isVisibleFormModal, setFormInput]);

  const FormModalToAddManager = useMemo(() => {
    if (!isVisibleFormModal) return null;

    return (
      <>
        <Modal
          isOpened
          handleClose={() => setIsVisibleFormModal(false)}
          modalBody={
            <>
              <Typography variant="h6" component="div">
                매니저 추가
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: 1, maxWidth: 400 }}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label="accountId"
                    size="small"
                    fullWidth
                    value={formInput?.accountId ?? ""}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        accountId: e.target.value.trim(),
                      })
                    }
                    required
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel id="authority-select-label">권한</InputLabel>
                    <Select
                      labelId="authority-select-label"
                      value={formInput?.authority ?? ""}
                      label="권한"
                      onChange={(e: SelectChangeEvent) => {
                        setFormInput({
                          ...formInput,
                          authority: e.target.value as BofulAuthority,
                        });
                      }}
                    >
                      {(
                        [
                          {
                            value: "admin",
                            label: AuthorityLabelDict["admin"],
                          },
                          {
                            value: "whMaster",
                            label: AuthorityLabelDict["whMaster"],
                          },
                          {
                            value: "manager",
                            label: AuthorityLabelDict["manager"],
                          },
                          {
                            value: "partTimer",
                            label: AuthorityLabelDict["partTimer"],
                          },
                        ] as {
                          value: BofulAuthority;
                          label: string;
                        }[]
                      ).map((v) => {
                        return (
                          <MenuItem value={v.value} key={v.value}>
                            {AuthorityLabelDict[v.value]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label="이름"
                    size="small"
                    fullWidth
                    value={formInput?.name ?? ""}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        name: e.target.value.trim(),
                      })
                    }
                    required
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="number"
                    label="warehouseId"
                    size="small"
                    fullWidth
                    value={formInput?.warehouseId ?? ""}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        warehouseId: Number(e.target.value),
                      })
                    }
                    required
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="password"
                    size="small"
                    fullWidth
                    value={formInput?.password ?? ""}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        password: e.target.value.trim(),
                      })
                    }
                    required
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="email"
                    label="email"
                    size="small"
                    fullWidth
                    value={formInput?.email ?? ""}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        email: e.target.value.trim(),
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label="memo"
                    size="small"
                    multiline
                    rows={3}
                    fullWidth
                    value={formInput?.memo ?? ""}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        memo: e.target.value,
                      })
                    }
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    disabled={!isFormInputValidated}
                    onClick={() => {
                      const payload = removeEmptyPropertiesOfObject(formInput);
                      addManager(payload as ADD_MANAGER_REQ);
                    }}
                  >
                    확인
                  </Button>
                </Grid>
              </Grid>
            </>
          }
        />

        {ResponseHandlerOfAddManager}
      </>
    );
  }, [
    isVisibleFormModal,
    ResponseHandlerOfAddManager,
    formInput,
    isFormInputValidated,
    addManager,
  ]);

  return {
    canAddManager,
    FormModalToAddManager,
    showFormToAddManager: () => setIsVisibleFormModal(true),
  };
}

import styled, { css } from "styled-components";

import { tablet } from "styles/functions";
import { COLOR } from "styles/constants";
import { setFontStyle } from "styles//typography";

const IMG_SIZE = {
  desktop: "320px",
  mobile: "200px",
};

const ARROW_SIZE = {
  desktop: "40px",
  mobile: "24px",
};

const sliderContainer = styled.div<{ isNotEmptyImage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .slider-title {
    background-color: ${COLOR.primaryBlue_10};
    padding: 8px;
    border-radius: 4px;
    ${setFontStyle("Body7")};

    ${({ isNotEmptyImage }) =>
      isNotEmptyImage
        ? css`
            margin-bottom: 16px;
          `
        : css`
            margin-bottom: 28px;
          `}
  }

  .slick-slider {
    padding-bottom: 32px;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;

    ${tablet(css`
      padding-bottom: 24px;
    `)}
  }

  .slick-slide {
    height: ${IMG_SIZE.desktop};
    display: flex;
    justify-content: center;
    align-items: center;

    ${tablet(css`
      height: ${IMG_SIZE.mobile};
    `)}
  }

  .slick-list {
    border: 1px solid ${COLOR.grayScale_400};
    border-radius: 8px;
    width: ${IMG_SIZE.desktop};
    height: ${IMG_SIZE.desktop};

    ${tablet(css`
      width: ${IMG_SIZE.mobile};
      height: ${IMG_SIZE.mobile};
    `)}
  }

  .slick-arrow {
    z-index: 1;
  }

  .slick-arrow::before {
    display: none;
  }

  .slick-prev,
  .slick-next {
    top: 160px;
    width: ${ARROW_SIZE.desktop};
    height: ${ARROW_SIZE.desktop};

    ${tablet(css`
      top: 100px;
      width: ${ARROW_SIZE.mobile};
      height: ${ARROW_SIZE.mobile};
    `)}
  }

  .slick-prev {
    left: -60px;

    ${tablet(css`
      left: -32px;
    `)}
  }

  .slick-next {
    right: -60px;

    ${tablet(css`
      right: -32px;
    `)}
  }

  .slick-dots {
    bottom: 2px;

    ${tablet(css`
      bottom: 0px;
    `)}
    li {
      width: 8px;
      height: 8px;
      margin: 0 2px;
    }

    button {
      width: 8px;
      height: 8px;
      padding: 0;
    }

    button::before {
      width: 8px;
      height: 8px;
      opacity: 1;
      line-height: 8px;
      color: ${COLOR.primaryBlue_40};
    }

    .slick-active button::before {
      width: 8px;
      height: 8px;
      opacity: 1;
      line-height: 8px;
      color: ${COLOR.primaryBlue};
    }
  }

  img {
    max-width: ${IMG_SIZE.desktop};
    max-height: ${IMG_SIZE.desktop};

    ${tablet(css`
      max-width: ${IMG_SIZE.mobile};
      max-height: ${IMG_SIZE.mobile};
    `)}
  }
`;

const imageDetailsContainer = styled.div<{ hasImagePaths: boolean }>`
  width: 100%;

  ${({ hasImagePaths }) =>
    hasImagePaths &&
    css`
      margin-bottom: 16px;
    `};
`;

const deleteButtonWrapper = styled.div<{ hasMultipleImage: boolean }>`
  ${({ hasMultipleImage }) =>
    hasMultipleImage &&
    css`
      margin-top: 16px;
    `}
`;

const deleteAllButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

export default {
  sliderContainer,
  imageDetailsContainer,
  deleteButtonWrapper,
  deleteAllButtonWrapper,
};

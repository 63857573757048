import { ChangeEvent, useState } from "react";
import { Button, Paper, TextField, Typography } from "@mui/material";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import UpdateShippingForm from "./Form";
import { getHighlightedLabel } from "utils/string";

function UpdateShipping() {
  const [shippingId, setShippingId] = useState<number>(0);
  const [isFetchShippingButtonClicked, setIsFetchShippingButtonClicked] =
    useState<boolean>(false);

  const {
    data: shippingDetail,
    ResponseHandler: ResponseHandlerOfGetAdminDetail,
    remove,
    refetch,
  } = SHIPPING_QUERY.useGetAdminDetail({
    enabled: !!shippingId && isFetchShippingButtonClicked,
    shippingId: shippingId || 0,
    onSuccess: (data) => {},
    onError: () => {
      setIsFetchShippingButtonClicked(false);
    },
  });

  const onUpdateSuccess = () => {
    refetch();
  };

  return (
    <Layout>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        {getHighlightedLabel({
          label:
            "[경고문] 내품수량 수정 요청에 대응하는 임시 기능입니다. 정식 기능이 아닙니다.",
          isHighlighted: true,
        })}
      </Paper>
      <Paper
        style={{
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          출고 정보 수정
        </Typography>

        <TextField
          id="standard-basic"
          label="출고번호"
          size="small"
          value={shippingId || 0}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setIsFetchShippingButtonClicked(false);
            setShippingId(Number.parseInt(e.target.value));
          }}
          type="number"
          disabled={isFetchShippingButtonClicked}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={isFetchShippingButtonClicked}
          onClick={() => {
            shippingId && setIsFetchShippingButtonClicked(true);
          }}
        >
          출고 정보 가져오기
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={!isFetchShippingButtonClicked}
          onClick={() => {
            remove();
            setIsFetchShippingButtonClicked(false);
          }}
        >
          리셋
        </Button>
      </Paper>

      {shippingDetail && isFetchShippingButtonClicked && (
        <UpdateShippingForm
          shippingDetail={shippingDetail}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
      {ResponseHandlerOfGetAdminDetail}
    </Layout>
  );
}

export default withRequireAuth(UpdateShipping);

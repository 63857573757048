import TextField from "components/TextField";

import Styled from "../index.styles";

export default function InputForReason({
  reason,
  updateReason,
}: {
  reason?: string;
  updateReason: (v: string) => void;
}) {
  return (
    <Styled.reason>
      <TextField
        label="조정 사유"
        placeholder="조정 사유를 적어주세요"
        size="medium"
        fullWidth
        value={reason || ""}
        onChange={(e) => {
          updateReason(e.target.value);
        }}
        multiline
      />
    </Styled.reason>
  );
}

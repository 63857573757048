import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import RECEIVING_QUERY from "queries/RECEIVING_QUERY";
import { ReceivingKind } from "types/receiving";
import { toFormattedDate } from "utils/date";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

interface ModifyExpectedDateForm {
  receivingOrBidId: string;
  expectedDate: string;
  receivingKind: "" | ReceivingKind;
}

function ReceivingExpectedDateModify() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ModifyExpectedDateForm>({
    mode: "onChange",
    defaultValues: {
      receivingOrBidId: "",
      expectedDate: dayjs().format("YYYY-MM-DD"),
      receivingKind: "",
    },
  });

  const {
    mutate: modifyExpectedDate,
    ResponseHandler: ResponseHandlerOfCombinePacking,
  } = RECEIVING_QUERY.useModifyExpectedDate({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        reset();
      },
      customizeMessage: (res) => ({
        messageType: "titleAndBody",
        title: "입고 도착일이 수정되었습니다.",
        body: (
          <>
            입고의뢰번호:{" "}
            {res?.receivingKind === "import" ? res.bidId : `A${res?.id}`} <br />
            수정된 입고 도착일:{" "}
            {toFormattedDate(res?.expectedDate, "YYYY-MM-DD")}
          </>
        ),
      }),
    },
  });

  const handleExpectedDateModify = ({
    receivingOrBidId,
    expectedDate,
    receivingKind,
  }: ModifyExpectedDateForm) => {
    if (receivingKind === "") {
      return;
    }

    modifyExpectedDate({
      pathParams: { receivingOrBidId: Number(receivingOrBidId) },
      expectedDate,
      receivingKind,
    });
  };

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          입고 도착일 수정
        </Typography>

        <form onSubmit={handleSubmit(handleExpectedDateModify)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="receivingOrBidId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="입고의뢰번호"
                  error={!!errors.receivingOrBidId}
                  helperText={errors.receivingOrBidId && "숫자만 입력해주세요."}
                />
              )}
            />

            <Controller
              control={control}
              name="expectedDate"
              rules={{ required: true }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="입고 도착일"
                    {...field}
                    renderInput={(props: TextFieldProps) => (
                      <TextField
                        {...props}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    )}
                    inputFormat="YYYY-MM-DD"
                    mask="____-__-__"
                  />
                </LocalizationProvider>
              )}
            />

            <Controller
              control={control}
              name="receivingKind"
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="receiving-kind">입고 타입</InputLabel>
                  <Select {...field} labelId="receiving-kind" label="입고 타입">
                    <MenuItem value="import">해외</MenuItem>
                    <MenuItem value="domestic">국내</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandlerOfCombinePacking}
    </Layout>
  );
}

export default withRequireAuth(ReceivingExpectedDateModify);

import SeverErrorImage from "./server_error.png";
import Styled from "./index.styles";
import { useHistory } from "react-router-dom";

export default function ServerError({
  handleClickGoBackButton,
}: {
  handleClickGoBackButton: () => void;
}) {
  const history = useHistory();

  return (
    <>
      <Styled.container>
        <Styled.img>
          <img src={SeverErrorImage} alt="server-error-page" />
        </Styled.img>
        <Styled.textGroup>
          <div className="text-bold">SERVER ERROR</div>
          <div className="text-normal">시스템 오류가 발생했습니다.</div>
        </Styled.textGroup>
      </Styled.container>
      <Styled.backAction>
        <button onClick={handleClickGoBackButton}>돌아가기</button>
      </Styled.backAction>
    </>
  );
}

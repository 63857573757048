import React, { useMemo } from "react";

import Icon from "components/sds-v1/Icon";
import useToolTip, { ToolTipProps } from "headlessComponents/useToolTip";
import { COLOR } from "styles/constants";
import useDeviceDetect from "hooks/useDeviceDetect";

import Styled from "./index.styles";

/**
 * - 툴팁 트리거로 사용하고자 하는 대상을 감싸는 식으로 사용한다.
 */
export default function ToolTip({
  displayType,
  contentForDesktop,
  contentForMobile,
  children,
  toolTipBodyRef,
  className,
}: ToolTipProps) {
  const { isMobile } = useDeviceDetect();

  const {
    isVisibleToolTipBody,
    setIsVisibleToolTipBody,
    triggerRef,
    triggerTargetInfo,
    checkTriggerTarget,
    handleMouseEnter,
    handleMouseLeave,
    handleToolTipClick,
  } = useToolTip();

  const ToolTipBody = useMemo(() => {
    if (!isVisibleToolTipBody) {
      return null;
    }

    if (isMobile) {
      return (
        <Styled.bodyForMobile
          onClick={(e) => {
            e.stopPropagation();
            setIsVisibleToolTipBody(false);
          }}
        >
          <div
            className="body-container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon
              type="clear"
              size={1}
              color={COLOR.grayScale_700}
              onClick={(e) => {
                e.stopPropagation();
                setIsVisibleToolTipBody(false);
              }}
            />
            <div className="content">
              {contentForMobile.isSameToDesktop && (
                <div className="item">
                  {(contentForDesktop.content.type === "titleOnly" ||
                    contentForDesktop.content.type === "titleAndDesc") && (
                    <div className="title">
                      ・{contentForDesktop.content.title}
                    </div>
                  )}

                  {(contentForDesktop.content.type === "descOnly" ||
                    contentForDesktop.content.type === "titleAndDesc") && (
                    <div className="desc">{contentForDesktop.content.desc}</div>
                  )}
                </div>
              )}

              {!contentForMobile.isSameToDesktop &&
                contentForMobile.content &&
                contentForMobile.content.map((v, i) => (
                  <div className="item" key={i}>
                    {(v.type === "titleOnly" || v.type === "titleAndDesc") && (
                      <div className="title">・{v.title}</div>
                    )}

                    {(v.type === "descOnly" || v.type === "titleAndDesc") && (
                      <div className="desc">{v.desc}</div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Styled.bodyForMobile>
      );
    } else {
      return (
        <Styled.bodyForDesktop
          ref={toolTipBodyRef}
          position={contentForDesktop.position}
          triggerTargetInfo={triggerTargetInfo}
          width={contentForDesktop.bodyWidth}
          type={contentForDesktop.content.type}
          className={`body-for-desktop ${
            contentForDesktop.position === "bottomLeft" ? "bottom-left" : ""
          }`}
        >
          {(contentForDesktop.content.type === "titleOnly" ||
            contentForDesktop.content.type === "titleAndDesc") && (
            <div className="title">{contentForDesktop.content.title}</div>
          )}
          {(contentForDesktop.content.type === "descOnly" ||
            contentForDesktop.content.type === "titleAndDesc") && (
            <div className="desc">{contentForDesktop.content.desc}</div>
          )}
        </Styled.bodyForDesktop>
      );
    }
  }, [
    isVisibleToolTipBody,
    isMobile,
    contentForMobile.isSameToDesktop,
    contentForMobile.content,
    contentForDesktop.content,
    contentForDesktop.position,
    contentForDesktop.bodyWidth,
    setIsVisibleToolTipBody,
    toolTipBodyRef,
    triggerTargetInfo,
  ]);

  return (
    <Styled.container
      className={`${className ? className : ""} tool-tip`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleToolTipClick}
      displayType={displayType}
    >
      <div
        ref={triggerRef}
        className="trigger"
        onMouseEnter={checkTriggerTarget}
        onClick={checkTriggerTarget}
      >
        {children}
      </div>

      {ToolTipBody}
    </Styled.container>
  );
}

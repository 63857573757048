import { atom } from "jotai";

import { FULFILLMENT_INSPECTION_ATOMS } from ".";

/** 반품 상세 > 검수 > 선택한 검수 상품의 동의 정보 */
const AGREEMENT_INFO_OF_SELECTED_INSPECTION_ITEM = atom((get) => {
  return (id: number) =>
    get(FULFILLMENT_INSPECTION_ATOMS.AGREEMENT_INFO_LIST_FOR_INSPECTION).find(
      (item) => item.itemId === id
    );
});

export default { AGREEMENT_INFO_OF_SELECTED_INSPECTION_ITEM };

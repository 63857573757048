import { ImageInfo } from "../index";
import { CommonDetailSlider } from "./CommonDetailSlider";

export default function OnlyImageDetail({
  imageList,
  currentSlideKey,
}: {
  imageList: ImageInfo[];
  currentSlideKey: string;
}) {
  const currentSlideIndex = imageList.findIndex(
    ({ key }) => key === currentSlideKey
  );

  const imageUrlList = imageList.map(({ url }) => url);

  return (
    <CommonDetailSlider
      key={`image-detail-slider-${currentSlideIndex}`}
      imageUrlList={imageUrlList}
      currentSlideIndex={currentSlideIndex}
    />
  );
}

import { useCallback } from "react";
import COMMON_QUERY from "queries/COMMON_QUERY";

import { InventoryDetailModifyForm } from "./useFormState";

export type LocationOption = {
  label: string;
  id: number;
  barCode: string;
};

export default function useLocationOptions({
  skuId,
  formState,
}: {
  skuId: number | undefined;
  formState: InventoryDetailModifyForm | null;
}) {
  const {
    data: locationList,
    ResponseHandler: ResponseHandlerOfGetLocationListOfWarehouse,
  } = COMMON_QUERY.useGetLocationListOfWarehouse({
    enabled: !!skuId,
    isAvailable: true,
    skuId,
    warehouseId: 1,
  });

  const getLocationOptions = useCallback(
    (selectedLocationId: string | number) => {
      if (!locationList) return [];

      const selectedLocations = new Map(formState?.inventory || []);

      // 해당 Input에서 선택한 option은 제외함
      selectedLocations.delete(selectedLocationId);

      return locationList
        .filter((f) => !selectedLocations.get(f.id))
        .map((v) => {
          const option: LocationOption = {
            label: `${v.barCode} 인천1센터 `, // 당분간은 인천1센터밖에 없으므로 고정
            id: v.id,
            barCode: v.barCode,
          };

          return option;
        });
    },
    [formState?.inventory, locationList]
  );

  return { getLocationOptions, ResponseHandlerOfGetLocationListOfWarehouse };
}

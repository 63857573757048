import { useCallback, useMemo, useState } from "react";
import { NoteAlt } from "@mui/icons-material";
import Button from "@mui/material/Button";

import { ShippingDeliveryType, ShippingItemDetail } from "types/shipping";

import InputParcelModal from "./InputParcelModal";

export default function useInputDeliveryDetail({
  shippingId,
  deliveryType,
  shippingDetailData,
}: {
  shippingId: number | undefined;
  deliveryType: ShippingDeliveryType | undefined;
  shippingDetailData: ShippingItemDetail | undefined;
}) {
  const [
    isVisibleInputDeliveryDetailModal,
    setIsVisibleInputDeliveryDetailModal,
  ] = useState(false);

  const handleInputDeliveryDetailModalOpen = useCallback(() => {
    setIsVisibleInputDeliveryDetailModal(true);
  }, []);

  const handleInputDeliveryDetailModalClose = useCallback(() => {
    setIsVisibleInputDeliveryDetailModal(false);
  }, []);

  const DeliveryDetailButton = useMemo(
    () => (
      <Button
        startIcon={<NoteAlt />}
        variant="outlined"
        onClick={handleInputDeliveryDetailModalOpen}
      >
        배송정보 수기 입력
      </Button>
    ),
    [handleInputDeliveryDetailModalOpen]
  );

  const InputDeliveryDetailModal = useMemo(() => {
    if (!shippingId || !isVisibleInputDeliveryDetailModal) return null;

    if (deliveryType === "airExpress" && !!shippingDetailData) {
      return (
        <InputParcelModal
          shippingId={shippingId}
          shippingDetailData={shippingDetailData}
          onModalClose={handleInputDeliveryDetailModalClose}
        />
      );
    }
  }, [
    deliveryType,
    handleInputDeliveryDetailModalClose,
    isVisibleInputDeliveryDetailModal,
    shippingDetailData,
    shippingId,
  ]);

  return {
    DeliveryDetailButton,
    InputDeliveryDetailModal,
  };
}

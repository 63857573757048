import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, Paper, Stack, Typography } from "@mui/material";

import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "queries/RECEIVING_QUERY";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import ReceivingDetailBySkuId from "./ReceivingDetailBySkuId";

interface ReceivingSkuDetailForm {
  skuId: string;
}

function ReceivingSkuDetail() {
  const [skuId, setSkuId] = useState<number>();

  const {
    data: receivingDetailBySkuId,
    ResponseHandler: ResponseHandlerOfGetReceivingDetailBySkuId,
    status: receivingDetailBySkuIdStatus,
  } = RECEIVING_QUERY.useGetReceivingDetailBySkuId({
    skuId: skuId ?? 0,
    enabled: !!skuId,
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ReceivingSkuDetailForm>({
    mode: "onChange",
    defaultValues: {
      skuId: "",
    },
  });

  const queryClient = useQueryClient();

  const handleSkuDetailFetch = ({ skuId }: ReceivingSkuDetailForm) => {
    setSkuId(Number(skuId));
  };

  const handleTextFieldClear = () => {
    reset();
    setSkuId(undefined);
    queryClient.removeQueries(
      RECEIVING_QUERY_KEY_GEN.getReceivingDetailBySkuId({ skuId })
    );
  };

  const isSuccess = !!receivingDetailBySkuId;
  const isEmptyData = isSuccess && receivingDetailBySkuId.length === 0;

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          입고 내역 조회
        </Typography>

        <form onSubmit={handleSubmit(handleSkuDetailFetch)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="skuId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="SKU ID"
                  handleTextFieldClear={handleTextFieldClear}
                  error={!!errors.skuId}
                  helperText={errors.skuId && "숫자만 입력해주세요."}
                />
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {isEmptyData && (
        <Typography
          style={{ fontSize: 20, marginTop: "20px" }}
          color="text.primary"
        >
          조회된 내역이 없습니다.
        </Typography>
      )}

      {isSuccess &&
        receivingDetailBySkuId.map((data, index) => (
          <ReceivingDetailBySkuId key={index} data={data} />
        ))}

      {ResponseHandlerOfGetReceivingDetailBySkuId}
    </Layout>
  );
}

export default withRequireAuth(ReceivingSkuDetail);

import { useEffect, useMemo, useState } from "react";

interface ClientSidePagingFilter<T> {
  /**
   * keyof T 만쓰려고 했는데, generic T마다 동적으로 타입을 체크할 방법을 찾지못해 string[]를 포함시킴
   * 특정 타입에 없는 key가 추가되도 에러가 나지 않으므로 일단 이렇게 사용
   */
  key: (keyof T)[] | string[];
  type: "includes" | "includesCaseSensitive";
  term?: string;
}

export type { ClientSidePagingFilter };

/**
 * 클라이언트 사이드에서 페이징 (서버로부터 데이터를 한 번에 모두 받고 페이징)을 할때 사용
 * page가 1부터 시작함에 유의
 */
export default function useClientSidePaging<T>(
  sizePerPage: number,
  dataSource: T[]
) {
  const [pageNum, setPageNum] = useState(1);

  const [filter, setFilter] = useState(
    null as unknown as ClientSidePagingFilter<T> | null
  );

  const targetData = useMemo(getTargetData, [dataSource, filter]);

  const { totalCount, pageSize } = useMemo(getPageInfo, [targetData]);

  const pageData = useMemo(getPageData, [pageNum, targetData]);

  useEffect(() => {
    if (!dataSource?.length) {
      setPageNum(1);
    }
  }, [dataSource]);

  return {
    totalCount,
    pageSize,
    pageNum,
    setPageNum: handleSetPageNum,
    pageData,
    filter,
    setFilter,
  };

  function getPageData() {
    const startIndex = (pageNum - 1) * sizePerPage;
    const endIndex = startIndex + sizePerPage;

    return targetData.slice(startIndex, endIndex);
  }

  function handleSetPageNum(page: number) {
    if (page > 0 && page <= pageSize) {
      setPageNum(page);
    }
  }

  function getPageInfo() {
    const totalCount = targetData.length;
    const pageSize = Math.ceil(totalCount / sizePerPage) || 0;

    return {
      totalCount,
      pageSize,
    };
  }

  function getTargetData() {
    let result = dataSource || [];

    if (filter?.term) {
      result = dataSource.filter((v: any) => {
        if (filter.type === "includes") {
          return filter.key.some(
            (f: keyof T | string) =>
              v[f] && v[f].toUpperCase().includes(filter.term!.toUpperCase())
          );
        }

        if (filter.type === "includesCaseSensitive") {
          return filter.key.some(
            (f: keyof T | string) => v[f] && v[f].includes(filter.term)
          );
        }
      });
    }

    return result;
  }
}

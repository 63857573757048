import { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function ConfirmModal({
  title,
  content,
  isConfirmModalOpen,
  onConfirmModalClose,
  actionPositive,
  actionNegative,
}: {
  title: ReactNode;
  content?: ReactNode;
  isConfirmModalOpen: boolean;
  onConfirmModalClose: () => void;
  actionPositive: {
    label?: string;
    onClick: () => void;
  };
  actionNegative?: {
    label?: string;
    onClick: () => void;
  };
}) {
  return (
    <Dialog open={isConfirmModalOpen} onClose={onConfirmModalClose}>
      <DialogTitle>{title}</DialogTitle>

      {content && <DialogContent>{content}</DialogContent>}

      <DialogActions sx={{ mt: 4 }}>
        {actionNegative && (
          <Button onClick={actionNegative.onClick}>
            {actionNegative.label ?? "아니요"}
          </Button>
        )}
        <Button onClick={actionPositive.onClick}>
          {actionPositive.label ?? "예"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  GET_ADMIN_LOCATION_LIST_REQ,
  GET_ADMIN_LOCATION_LIST_REQ_SEARCH_KIND,
  GET_ADMIN_LOCATION_LIST_RES,
} from "api-interfaces/location";
import LOCATION_QUERY from "queries/LOCATION_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useCheckBox from "hooks/useCheckBox";
import useGetCSVDownloadInfoFromQuery from "hooks/useGetCSVDownloadInfoFromQuery";
import { TermSearchType } from "hooks/useSearchWithTerm";
import useSearchWithTerm from "hooks/useSearchWithTerm";
import { LocationItem, LocationListItem } from "types/location";
import { LocationKind } from "types/location";
import { toFormattedDate } from "utils/date";
import { getLocationKindLabel } from "utils/location";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import AddLocation from "./AddLocation";
import ChangeLocationActivation from "./ChangeLocationActivation";
import LocationDetail, { LocationSummaryData } from "./LocationDetail";
import LocationFilter from "./LocationFilter";
import PrintLocationLabel from "./PrintLocationLabel";
import { getFormattedSingleSkuId } from "utils/fulfillment";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_LOCATION_LIST_REQ_SEARCH_KIND>[] =
  [
    { label: "LOC ID", value: "barCode" },
    { label: "SKU ID", value: "skuId" },
  ];

export type CheckBoxAction =
  | "activateLocation"
  | "deactivateLocation"
  | "printLocationLabel";

type CellId =
  | Extract<keyof LocationItem, "name" | "kind" | "barCode">
  | "warehouse"
  | "checkbox"
  | "skuId";

export type LocationFilterValue =
  | "all"
  | "empty"
  | "active"
  | "deActive"
  | LocationKind;

type FilterStates = Pick<
  GET_ADMIN_LOCATION_LIST_REQ,
  "kind" | "isActive" | "isEmpty"
> & {
  filterValue: LocationFilterValue;
};

const activeCheckBoxTypes: CheckBoxAction[] = [
  "deactivateLocation",
  "activateLocation",
  "printLocationLabel",
];

const checkCanDeactivateLocation = (item: LocationListItem) => {
  // 로케이션에 보관된 상품이 없는 경우에만 선택 가능
  const isEmptyLocation =
    item.inventory?.length === 0 ||
    item.inventory?.every((item) => item.totalQty === 0);

  if (item.isActive && isEmptyLocation) {
    return true;
  }

  return false;
};

const checkCanActivateLocation = (item: LocationListItem) => {
  // 비활성화된 로케이션만 선택 가능
  if (!item.isActive) {
    return true;
  }

  return false;
};

function LocationList() {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const [filterStates, setFilterStates] = useState<FilterStates>();

  const [selectedLocationData, setSelectedLocationData] =
    useState<LocationSummaryData>();

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  // 검색어가 바뀌면 페이징 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm]);

  // 페이지당 데이터 수 변환시 현재 페이지 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [perPage]);

  const {
    data: locationList,
    ResponseHandler: ResponseHandlerOfGettingLocationList,
    refetch: refetchLocationList,
  } = LOCATION_QUERY.useGetAdminList({
    perPage,
    page: currentPage,

    ...(termSearchType?.value && debouncedSearchTerm
      ? {
          searchKind: termSearchType?.value,
          searchTerm: debouncedSearchTerm,
        }
      : {}),
    isActive: filterStates?.isActive,
    kind: filterStates?.kind,
    isEmpty: filterStates?.isEmpty,

    onSuccess: () => {
      // 페이지 정보가 변하면 checked 정보도 초기화
      initCheckBoxSet();
    },
  });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: LOCATION_QUERY.useGetAdminListForCSVDownload,
    queryArgs: [
      {
        ...(termSearchType?.value && debouncedSearchTerm
          ? {
              searchKind: termSearchType?.value,
              searchTerm: debouncedSearchTerm,
            }
          : {}),
        isActive: filterStates?.isActive,
        kind: filterStates?.kind,
        isEmpty: filterStates?.isEmpty,
      },
    ],
  });

  const {
    CheckBoxPanel,
    CheckBoxForAll,
    CheckBoxForItem,
    hasCheckBoxType,
    initCheckBoxSet,
  } = useCheckBox<CheckBoxAction, LocationListItem>({
    activeCheckBoxTypes,
    data: locationList?.list,
    checkBoxTypeOptions: [
      {
        label: "로케이션 비활성화",
        value: "deactivateLocation",
        renderAction: ({ checkBoxArr }) => (
          <ChangeLocationActivation
            type="deactivate"
            checkedIds={checkBoxArr}
            refetchLocationList={refetchLocationList}
          />
        ),
        checkCanCheck: checkCanDeactivateLocation,
        getIdForCheckBox: ({ id }) => id,
      },
      {
        label: "로케이션 활성화",
        value: "activateLocation",
        renderAction: ({ checkBoxArr }) => (
          <ChangeLocationActivation
            type="activate"
            checkedIds={checkBoxArr}
            refetchLocationList={refetchLocationList}
          />
        ),
        checkCanCheck: checkCanActivateLocation,
        getIdForCheckBox: ({ id }) => id,
      },
      {
        label: "라벨 출력",
        value: "printLocationLabel",
        renderAction: ({ checkBoxArr }) => (
          <PrintLocationLabel checkedIds={checkBoxArr} />
        ),
        checkCanCheck: (item) => {
          return true;
        },
        getIdForCheckBox: ({ id }) => id,
      },
    ],
  });

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    const result: TableHeadCell<CellId>[] = [
      ...(hasCheckBoxType
        ? [
            {
              id: "checkbox",
              disablePadding: false,
              label: <CheckBoxForAll />,
              width: 80,
            } as const,
          ]
        : []),
      {
        id: "barCode",
        disablePadding: false,
        label: "LOC ID",
        width: 140,
      },
      {
        id: "kind",
        disablePadding: false,
        label: "구분",
        width: 100,
      },
      {
        id: "warehouse",
        disablePadding: false,
        label: "센터",
        width: 100,
      },
      {
        id: "name",
        disablePadding: false,
        label: "타입",
        width: 140,
      },
      {
        id: "skuId",
        disablePadding: false,
        label: "SKU ID",
        width: 120,
      },
    ];

    return result;
  }, [CheckBoxForAll, hasCheckBoxType]);

  const mapDataForTable = useCallback(
    (data: GET_ADMIN_LOCATION_LIST_RES | undefined): TableBodyRow<CellId>[] => {
      if (!data?.list) {
        return [];
      }

      return data.list.map((item) => {
        const { id, barCode, name, kind, isActive } = item;

        return {
          ...(hasCheckBoxType
            ? {
                checkbox: <CheckBoxForItem item={item} />,
              }
            : {}),

          barCode,
          kind: getLocationKindLabel(kind),
          warehouse: "인천1센터", // TODO: 창고 이전 전에는 고정해 둠
          name,
          skuId: getFormattedSingleSkuId(item.inventory[0]?.skuId),

          backgroundColor: !isActive ? grey[200] : undefined,

          ...(hasCheckBoxType
            ? {}
            : {
                handleRowClick: () =>
                  setSelectedLocationData({
                    locationId: id,
                    barCode,
                    warehouse: "인천1센터", // TODO: 창고 이전 전에는 고정해 둠
                  }),
              }),
        };
      });
    },
    [CheckBoxForItem, hasCheckBoxType]
  );

  const rows = useMemo(() => {
    return mapDataForTable(locationList);
  }, [locationList, mapDataForTable]);

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload);
  }, [dataForCSVDownload, mapDataForTable]);

  const handleLocationFilterChange = useCallback((e: SelectChangeEvent) => {
    switch (e.target.value as LocationFilterValue) {
      case "all":
        setFilterStates({ filterValue: "all" });
        break;
      case "empty":
        setFilterStates({
          filterValue: "empty",
          isActive: true,
          isEmpty: true,
        });
        break;
      case "receiving":
        setFilterStates({
          filterValue: "receiving",
          isActive: true,
          kind: "receiving",
        });
        break;
      case "shipping":
        setFilterStates({
          filterValue: "shipping",
          isActive: true,
          kind: "shipping",
        });
        break;
      case "discard":
        setFilterStates({
          filterValue: "discard",
          isActive: true,
          kind: "discard",
        });
        break;
      case "cancel":
        setFilterStates({
          filterValue: "cancel",
          isActive: true,
          kind: "cancel",
        });
        break;
      case "putAway":
        setFilterStates({
          filterValue: "putAway",
          isActive: true,
          kind: "putAway",
        });
        break;
      case "active":
        setFilterStates({ filterValue: "active", isActive: true });
        break;
      case "deActive":
        setFilterStates({ filterValue: "deActive", isActive: false });
        break;
      default:
        setFilterStates(undefined);
    }
  }, []);

  const handleLocationDetailClose = () => {
    setSelectedLocationData(undefined);
  };

  return (
    <Layout>
      <Table<CellId>
        title="로케이션 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: locationList?.total ?? 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
        toolbarItems={{
          left: [
            TermSearchPanel,
            <LocationFilter
              key={filterStates?.filterValue}
              locationFilterValue={filterStates?.filterValue}
              handleLocationFilterChange={handleLocationFilterChange}
            />,
            <CheckBoxPanel key="checkBox" style={{ marginLeft: 8 }} />,
          ],
          right: [<AddLocation key="plus" />],
        }}
        csvDownloadInfo={{
          filename: `로케이션 리스트(${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          idsToExceptForExcelDownload: ["checkbox"],
          scope: "all",
          isCSVDownloadRequested,
          setIsCSVDownloadRequested,
          rowsForCSVDownload,
          ResponseHandlerOfFetchDataForCSVDownload,
          statusOfQueryOfFetchDataForCSVDownload,
          removeQueryOfFetchDataForCSVDownload,
        }}
      />

      <LocationDetail
        locationData={selectedLocationData}
        onLocationDetailClose={handleLocationDetailClose}
      />

      {ResponseHandlerOfGettingLocationList}
    </Layout>
  );
}

export default withRequireAuth(LocationList);

import React from "react";

import useIcon, { IconPropsV1 } from "headlessComponents/useIcon";
import DATA, { IconType } from "./DATA";
import Styled from "./index.styles";

/**
 * lat과 lng은 이 컴포넌트에서는 쓰지 않지만, GoogleMap 컴포넌트 내부에서 아이콘으로 사용될때 GoogleMap 컴포넌트에 의해 사용된다.
 */
export default function Icon({
  type,
  size,
  color,
  bgColor,
  className,
  onClick,
  style,
  lat,
  lng,
}: IconPropsV1<IconType>) {
  const { combinedStyle, handelClick } = useIcon({
    size,
    onClick,
    style,
  });

  const getIcon = DATA[type];

  return (
    <Styled.container
      onClick={handelClick}
      style={combinedStyle}
      className={`icon icon-${type} ${className ? className : ""}`}
    >
      {getIcon({ width: size, color, bgColor })}
    </Styled.container>
  );
}

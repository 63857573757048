import styled from "styled-components";

import { COLOR } from "styles/constants";
import { formatEllipsis } from "styles/functions";
import { setFontStyle } from "styles/typography";

const inspectionContainer = styled.div``;

const title = styled.div`
  ${setFontStyle("SubHead3", "Bold")};
  color: ${COLOR.grayScale_700};
  text-align: left;
`;

const guide = styled.p`
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_800};

  > em {
    color: ${COLOR.pointWarning};
  }
`;

const inspectResultGuide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

const processStatusContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const processStatusText = styled.span`
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_500};
`;

const inspectConfirmContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const inspectTotalQuantity = styled.div<{ isBackgroundColorBlue: boolean }>`
  padding: 16px;
  background-color: ${({ isBackgroundColorBlue }) =>
    isBackgroundColorBlue ? COLOR.primaryBlue_10 : COLOR.grayScale_100};

  > .divider {
    width: 100%;
    border-bottom: 1px solid ${COLOR.grayScale_300};
    margin: 16px 0;
  }
`;

const badgeAndQuantity = styled.div`
  display: flex;
  justify-content: space-between;
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_800};

  &:not(last-child) {
    margin-bottom: 8px;
  }
`;

const totalQuantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .label {
    display: flex;
    align-items: center;
    gap: 4px;
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.grayScale_700};
  }

  .guide {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    ${setFontStyle("SubHead3")};
    color: ${COLOR.grayScale_600};
  }

  .quantity {
    ${setFontStyle("Body6", "Bold")};
    color: ${COLOR.grayScale_800};
  }
`;

const limitedWidthEllipsisText = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  ${formatEllipsis()};
`;

const problemStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export default {
  inspectionContainer,
  title,
  guide,
  inspectResultGuide,
  processStatusContainer,
  processStatusText,
  inspectConfirmContainer,
  inspectTotalQuantity,
  badgeAndQuantity,
  totalQuantity,
  limitedWidthEllipsisText,
  problemStatus,
};

import styled, { css } from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const summary = styled.div`
  width: 100%;
  border-radius: 2px;
  padding: 16px;
  background-color: ${COLOR.grayScale_100};
  color: ${COLOR.grayScale_800};
  text-align: left;
`;

const summaryItem = styled.dl<{
  hasBottomDivider?: boolean;
  isItemName?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px 0;

  ${({ hasBottomDivider }) => {
    return hasBottomDivider
      ? css`
          border-bottom: 1px solid ${COLOR.grayScale_300};
          padding-bottom: 16px;
          margin-bottom: 16px;
        `
      : css`
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        `;
  }}

  .data-item {
    display: flex;
    align-items: ${({ isItemName }) => (isItemName ? "flex-start" : "center")};

    &:not(:last-child)::after {
      display: block;
      content: "|";
      padding: 0 16px 2px 16px;
    }
  }

  dt {
    display: flex;
    align-items: center;
    ${setFontStyle("SubHead2")};
    word-break: keep-all;
  }

  dd {
    margin-left: 4px;
    ${setFontStyle("Body6", "Bold")};
  }
`;

export default {
  summary,
  summaryItem,
};

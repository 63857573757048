import {
  MutationSideEffectType,
  useAppMutation,
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
} from "services/query";

import { BofulManager } from "types/auth";
import { MINUTE_AS_MILLISECONDS } from "utils/date";

import { QueryResponseHandlerSuccessModalInfo } from "components/sds-v1/QueryResponseHandler";

import {
  ADD_MANAGER_REQ,
  CHANGE_MANAGER_PASSWORD_REQ,
  GET_CUSTOMER_DETAIL_RES,
  GET_CUSTOMER_LIST_RES,
  GET_CUSTOMER_OTP_REQ,
  GET_CUSTOMER_OTP_RES,
  GET_TEAM_BASIC_DETAIL_RES,
  GET_TEAM_DETAIL_RES,
  GET_TEAM_LIST_RES,
  GET_TEAM_SEARCH_SUGGESTION_LIST_REQ,
  GET_TEAM_SEARCH_SUGGESTION_LIST_RES,
  GET_TEAM_SENDING_DETAIL_RES,
  UPDATE_TEAM_SENDING_DETAIL_REQ,
  UPDATE_TEAM_SENDING_DETAIL_REQ_PATH_PARAMS,
} from "api-interfaces/user";

export const USER_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/USER_QUERY" }] as const,

  getTeamSearchSuggestionList: (params: GET_TEAM_SEARCH_SUGGESTION_LIST_REQ) =>
    [
      {
        ...USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "teamSearchSuggestionList",
      },
    ] as const,

  getTeamDetail: () =>
    [
      {
        ...USER_QUERY_KEY_GEN.all()[0],
        entity: "teamDetail",
      },
    ] as const,

  getTeamList: () =>
    [
      {
        ...USER_QUERY_KEY_GEN.all()[0],
        entity: "teamList",
      },
    ] as const,
  getTeamBasicDetail: (params: { teamId: number | undefined }) =>
    [
      {
        ...USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "teamBasicDetail",
      },
    ] as const,
  getTeamSendingDetail: (params: { teamId: number | undefined }) =>
    [
      {
        ...USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "teamSendingDetail",
      },
    ] as const,

  getCustomerList: () =>
    [
      {
        ...USER_QUERY_KEY_GEN.all()[0],
        entity: "customerList",
      },
    ] as const,
  getCustomerDetail: (params: { userId: number | undefined }) =>
    [
      {
        ...USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "customerDetail",
      },
    ] as const,
};

function useGetMangerList() {
  const queryResult = useAppQuery<BofulManager[]>({
    queryKey: ["fulfillment", "warehouseManager", "list"],
    requestOptions: {
      method: "get",
      path: "/warehouseManager/list",
    },
  });

  return { ...queryResult };
}

function useAddManager(props?: {
  successModalInfo: QueryResponseHandlerSuccessModalInfo;
}) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<ADD_MANAGER_REQ, unknown>({
    requestOptions: {
      method: "post",
      path: "/warehouseManager",
      apiType: "BofulDefault",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useChangeManagerPassword(props?: {
  successModalInfo: QueryResponseHandlerSuccessModalInfo;
}) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<CHANGE_MANAGER_PASSWORD_REQ, unknown>({
    requestOptions: {
      method: "put",
      path: "/auth/modify/admin/password",
      apiType: "BofulDefault",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useGetTeamSearchSuggestionList({
  enabled,
  ...params
}: {
  enabled?: boolean;
} & GET_TEAM_SEARCH_SUGGESTION_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getTeamSearchSuggestionList>,
    GET_TEAM_SEARCH_SUGGESTION_LIST_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getTeamSearchSuggestionList(params),

    requestOptions: {
      method: "get",
      path: "/search/suggestion/team",
      apiType: "BofulDefault",
      params,
    },

    enabled,
  });

  return { ...queryResult };
}

function useGetTeamDetail({
  enabled,
  onSuccess,
}: {
  enabled?: boolean;
  onSuccess?: (data: GET_TEAM_DETAIL_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getTeamDetail>,
    GET_TEAM_DETAIL_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getTeamDetail(),

    requestOptions: {
      method: "get",
      path: `/team/detail`,
      apiType: "BofulDefault",
    },

    enabled,

    onSuccess,

    staleTime: 10 * MINUTE_AS_MILLISECONDS,
    cacheTime: 10 * MINUTE_AS_MILLISECONDS,
  });

  return queryResult;
}

function useGetTeamList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getTeamList>,
    GET_TEAM_LIST_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getTeamList(),

    requestOptions: {
      method: "get",
      path: "/team",
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "팀 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return queryResult;
}

function useGetTeamBasicDetail({
  teamId,
  enabled,
}: {
  teamId: number | undefined;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getTeamBasicDetail>,
    GET_TEAM_BASIC_DETAIL_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getTeamBasicDetail({ teamId }),

    requestOptions: {
      method: "get",
      path: `/team/detail/${teamId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "팀 기본 정보 조회 중에 오류가 발생했습니다.",
      }),
    },

    enabled,
  });

  return queryResult;
}

function useGetTeamSendingDetail({
  teamId,
  enabled,
}: {
  teamId: number | undefined;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getTeamSendingDetail>,
    GET_TEAM_SENDING_DETAIL_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getTeamSendingDetail({ teamId }),

    requestOptions: {
      method: "get",
      path: `/team/info/${teamId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "팀 발송 정보 조회 중에 오류가 발생했습니다.",
      }),
    },

    enabled,
  });

  return queryResult;
}

function useUpdateTeamSendingDetail() {
  const mutation = useAppMutation<
    UPDATE_TEAM_SENDING_DETAIL_REQ,
    unknown,
    UPDATE_TEAM_SENDING_DETAIL_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ teamId }) => `/team/info/${teamId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "팀 발송 정보 수정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useGetCustomerList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getCustomerList>,
    GET_CUSTOMER_LIST_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getCustomerList(),

    requestOptions: {
      method: "get",
      path: `/users/fulfillment`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "고객 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return queryResult;
}

function useGetCustomerDetail({
  userId,
  enabled,
}: {
  userId: number | undefined;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getCustomerDetail>,
    GET_CUSTOMER_DETAIL_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getCustomerDetail({ userId }),

    requestOptions: {
      method: "get",
      path: `/users/fulfillment/${userId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "고객 상세 정보 조회 중에 오류가 발생했습니다.",
      }),
    },

    enabled,
  });

  return queryResult;
}

function useGetCustomerOTP(
  sideEffectOptions?: MutationSideEffectType<
    GET_CUSTOMER_OTP_REQ,
    GET_CUSTOMER_OTP_RES
  >
) {
  const mutation = useAppMutation<GET_CUSTOMER_OTP_REQ, GET_CUSTOMER_OTP_RES>({
    requestOptions: {
      method: "post",
      path: `/users/otp`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

const USER_QUERY = {
  useGetMangerList,
  useAddManager,
  useChangeManagerPassword,

  useGetTeamSearchSuggestionList,

  useGetTeamDetail,

  useGetTeamList,
  useGetTeamBasicDetail,
  useGetTeamSendingDetail,
  useUpdateTeamSendingDetail,

  useGetCustomerList,
  useGetCustomerDetail,

  useGetCustomerOTP,
};
export default USER_QUERY;

import { useEffect, useMemo, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";

import USER_QUERY from "queries/USER_QUERY";
import Modal from "components/Modal";

export default function useChangePassword({
  managerId,
}: {
  managerId?: number;
}) {
  const [active, setActive] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    if (!active) {
      setNewPassword("");
    }
  }, [active]);

  const {
    mutate: changeManagerPassword,
    ResponseHandler: ResponseHandlerOfChangeManagerPassword,
  } = USER_QUERY.useChangeManagerPassword({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        setActive(false);
      },
      customizeMessage: () => ({
        title: "비밀번호가 변경되었습니다.",
      }),
    },
  });

  const FormModal = useMemo(() => {
    if (!active) return null;

    return (
      <>
        <Modal
          isOpened
          handleClose={() => setActive(false)}
          modalBody={
            <>
              <Typography variant="h5" component="div">
                비밀번호 변경
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="새 비밀번호"
                    size="medium"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={(e) => {
                      if (managerId && newPassword) {
                        changeManagerPassword({ managerId, newPassword });
                      }
                    }}
                    disabled={!newPassword}
                  >
                    확인
                  </Button>
                </Grid>
              </Grid>

              {ResponseHandlerOfChangeManagerPassword}
            </>
          }
        />
      </>
    );
  }, [
    active,
    managerId,
    changeManagerPassword,
    ResponseHandlerOfChangeManagerPassword,
    newPassword,
  ]);

  return {
    showChangePasswordModal: () => setActive(true),
    ChangePasswordModal: FormModal,
  };
}

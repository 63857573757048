import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { LocalShipping } from "@mui/icons-material";
import { Button, SelectChangeEvent } from "@mui/material";

import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { DomesticShippingDeliveryType } from "types/shipping";
import ConfirmModal from "components/ConfirmModal";

import useDialog from "hooks/useDialog";
import { DomesticCategory } from "pages/shipping/domestic";
import { OverseasCategory } from "pages/shipping/overseas";

import { CalendarInputValue } from "components/Calendar";

import DueDateModal from "../EditDueDate/DueDateModal";
import DeliveryTypeModal from "./DeliveryTypeModal";

export default function EditDeliveryType({
  pageCategory,
  selectedShippingIds,
}: {
  selectedShippingIds: number[];
  pageCategory: DomesticCategory | OverseasCategory;
}) {
  const [deliveryType, setDeliveryType] = useState<
    DomesticShippingDeliveryType | ""
  >("");
  const [dueDate, seDueDate] = useState<CalendarInputValue>(null);

  const resetDeliveryType = useCallback(() => {
    setDeliveryType("");
  }, []);

  const resetDueDate = useCallback(() => {
    seDueDate(null);
  }, []);

  const resetDeliveryState = useCallback(() => {
    resetDeliveryType();
    resetDueDate();
  }, [resetDeliveryType, resetDueDate]);

  const [
    isDeliveryTypeModalOpen,
    handleDeliveryTypeModalOpen,
    handleDeliveryTypeModalClose,
  ] = useDialog({
    onClose: resetDeliveryState,
  });
  const [isConfirmModalOpen, handleConfirmModalOpen, handleConfirmModalClose] =
    useDialog();
  const [isDueDateModalOpen, handleDueDateModalOpen, handleDueDateModalClose] =
    useDialog({ onClose: resetDueDate });

  const queryClient = useQueryClient();

  const {
    mutate: editDeliveryInfo,
    ResponseHandler: ResponseHandlerOfEditDeliveryInfo,
  } = SHIPPING_QUERY.useEditDeliveryInfo();

  const handleDeliveryTypeChange = (e: SelectChangeEvent) => {
    setDeliveryType(e.target.value as DomesticShippingDeliveryType);
  };

  const needsTimePicker =
    deliveryType === "truck" || deliveryType === "selfCollect";

  const isSkipConfirmModal = pageCategory === "PARCEL" && needsTimePicker;

  const handleDeliveryTypeConfirm = () => {
    if (isSkipConfirmModal) {
      handleDueDateModalOpen();
      return;
    }

    handleConfirmModalOpen();
  };

  const handleEditDueDateConfirm = () => {
    handleConfirmModalClose();

    handleDueDateModalOpen();
  };

  const handleDeliveryInfoEdit = () => {
    if (!deliveryType) {
      return;
    }

    editDeliveryInfo(
      {
        ids: selectedShippingIds,
        deliveryType,
        ...(isDueDateModalOpen && dueDate && { dueDate }),
      },
      {
        onSuccess: () => {
          if (isConfirmModalOpen) {
            handleConfirmModalClose();
          }

          if (isDueDateModalOpen) {
            handleDueDateModalClose();
          }

          handleDeliveryTypeModalClose();

          queryClient.invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping());
        },
      }
    );
  };

  const handleDueDateChange = (date: CalendarInputValue) => {
    seDueDate(date);
  };

  // 해외특송에서는 운송수단 변경을 비활성화 (추후 해상특송 추가 시 해제)
  const isDisabled = pageCategory === "EXPRESS";

  return (
    <>
      <Button
        startIcon={<LocalShipping />}
        variant="outlined"
        onClick={handleDeliveryTypeModalOpen}
        disabled={isDisabled || !selectedShippingIds.length}
      >
        운송수단 변경
      </Button>

      <DeliveryTypeModal
        deliveryType={deliveryType}
        onDeliveryTypeChange={handleDeliveryTypeChange}
        isDeliveryTypeModalOpen={isDeliveryTypeModalOpen}
        onDeliveryTypeModalClose={handleDeliveryTypeModalClose}
        onDeliveryTypeConfirm={handleDeliveryTypeConfirm}
      />

      <ConfirmModal
        title={`${
          needsTimePicker ? "발송희망일시를" : "발송희망일을"
        } 변경하시겠습니까?`}
        isConfirmModalOpen={isConfirmModalOpen}
        onConfirmModalClose={handleConfirmModalClose}
        actionPositive={{
          onClick: handleEditDueDateConfirm,
        }}
        actionNegative={{
          onClick: handleDeliveryInfoEdit,
        }}
      />

      <DueDateModal
        dueDate={dueDate}
        onDueDateChange={handleDueDateChange}
        selectedShippingCount={selectedShippingIds.length}
        isDueDateModalOpen={isDueDateModalOpen}
        onDueDateModalClose={handleDueDateModalClose}
        onDueDateModalConfirm={handleDeliveryInfoEdit}
        needsTimePicker={needsTimePicker}
      />

      {ResponseHandlerOfEditDeliveryInfo}
    </>
  );
}

import { useCallback, useMemo, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/material";
import { GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/receiving";
import { GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/shipping";
import {
  GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ,
  GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ,
} from "api-interfaces/sku";
import { GET_TEAM_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/user";

import useDebounce from "hooks/useDebounce";
import { getFormattedSearchItem } from "utils/fulfillment";

import useSearchSuggestion from "./useSearchSuggestion";

export type SearchPageType =
  | "singleSku"
  | "groupSku"
  | "material"
  | "receiving"
  | "shipping"
  | "product"
  | "team";

export interface TermSearchType<
  T extends TermSearchTypeForBoful<SearchPageType>
> {
  label: string;
  value: T;
  placeHolder?: string;
}

const getSearchSuggestionType = <
  T extends TermSearchTypeForBoful<SearchPageType>
>({
  pageType,
  termSearchType,
}: {
  pageType: SearchPageType;
  termSearchType: TermSearchType<T>;
}) => {
  if (termSearchType.value === "company") {
    return "team";
  }

  if (pageType === "singleSku") {
    return "singleSku";
  }

  if (pageType === "groupSku") {
    return "groupSku";
  }

  if (pageType === "material") {
    return "material";
  }

  if (pageType === "receiving") {
    if (
      termSearchType.value === "skuId" ||
      termSearchType.value === "barCode" ||
      termSearchType.value === "productCode" ||
      termSearchType.value === "managementCode" ||
      termSearchType.value === "itemName"
    ) {
      return "singleSku";
    }

    return "receiving";
  }

  if (pageType === "shipping") {
    if (
      termSearchType.value === "skuId" ||
      termSearchType.value === "barCode" ||
      termSearchType.value === "productCode" ||
      termSearchType.value === "managementCode" ||
      termSearchType.value === "itemName"
    ) {
      return "product";
    }

    return "shipping";
  }

  if (pageType === "team") {
    return "team";
  }
};

const getSearchKind = <T extends TermSearchTypeForBoful<SearchPageType>>(
  value: T
): Exclude<TermSearchTypeForBoful<SearchPageType>, "skuId"> => {
  if (value === "skuId") {
    return "id";
  }

  return value;
};

export type TermSearchTypeForBoful<T> = T extends "singleSku"
  ? GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ["searchKind"] | "company"
  : T extends "groupSku"
  ? GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ["searchKind"] | "company"
  : T extends "material"
  ? GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ["searchKind"] | "company"
  : T extends "receiving"
  ?
      | GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ["searchKind"]
      | "company"
      | "skuId"
      | "barCode"
      | "productCode"
      | "managementCode"
      | "managementKind"
      | "itemName"
  : T extends "shipping"
  ?
      | GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ["searchKind"]
      | "company"
      | "skuId"
      | "barCode"
      | "productCode"
      | "managementCode"
      | "managementKind"
      | "itemName"
  : T extends "team"
  ? GET_TEAM_SEARCH_SUGGESTION_LIST_REQ["searchKind"]
  : never;

export interface SearchItem<T extends TermSearchTypeForBoful<SearchPageType>> {
  searchKind: T;
  searchKindLabel: string;
  searchTerm: string;
}

/**
 * 모노리포에서 useSearchWithTerm라는 hook이 2개가 있어 불가피하게 네이밍을 길게 수정
 */
export default function useSearchWithTermForBufulAdminOnly<
  T extends TermSearchTypeForBoful<SearchPageType>
>({
  pageType,
  termSearchTypeOptions,
  searchListInitialValue,
  resetCurrentPage,
}: {
  pageType: SearchPageType;
  termSearchTypeOptions: TermSearchType<T>[];
  searchListInitialValue?: SearchItem<T>[];
  resetCurrentPage?: () => void;
}) {
  const [termSearchType, setTermSearchType] = useState<TermSearchType<T>>(
    termSearchTypeOptions[0]
  );

  const [searchTermInput, setSearchTermInput] = useState("");
  const [searchList, setSearchList] = useState<SearchItem<T>[]>(
    searchListInitialValue && searchListInitialValue.length > 0
      ? searchListInitialValue
      : []
  );

  const debouncedSearchTermInput = useDebounce(searchTermInput, 300);

  const { searchSuggestionList } = useSearchSuggestion({
    // FIXME: any 타입 제거
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: getSearchSuggestionType({ pageType, termSearchType }) as any,
    searchKind: getSearchKind(termSearchType.value),
    searchTerm: debouncedSearchTermInput,
  });

  const handleTermSearchTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const target = termSearchTypeOptions.find(
        (st) => st.value === event.target.value
      );

      if (target) {
        setTermSearchType(target);
      }
    },
    [termSearchTypeOptions]
  );

  const handleSearchInputChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
      setSearchTermInput(newInputValue);
    },
    []
  );

  const updateSearchList = useCallback(
    (newValue: string) => {
      setSearchList((prev) => {
        const isExist = prev.find(
          (item) =>
            item.searchKind === termSearchType.value &&
            item.searchTerm === newValue
        );

        if (isExist) {
          return prev;
        }

        return [
          ...prev,
          {
            searchKind: termSearchType.value,
            searchKindLabel: termSearchType.label,
            searchTerm: newValue,
          },
        ];
      });
    },
    [termSearchType.label, termSearchType.value]
  );

  const searchData = useCallback(
    (value: string | null) => {
      if (!value) {
        return;
      }

      updateSearchList(value.trim());

      setSearchTermInput("");

      resetCurrentPage && resetCurrentPage();
    },
    [resetCurrentPage, updateSearchList]
  );

  const handleSearchTermChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
      searchData(newValue);
    },
    [searchData]
  );

  const handleSearch = useCallback(() => {
    searchData(searchTermInput);
  }, [searchData, searchTermInput]);

  const handleSearchItemDelete = useCallback(
    (selectedItem: SearchItem<T>) => () => {
      setSearchList((prev) =>
        prev.filter(
          (item) =>
            getFormattedSearchItem(item) !==
            getFormattedSearchItem(selectedItem)
        )
      );

      resetCurrentPage && resetCurrentPage();
    },
    [resetCurrentPage]
  );

  const handleSearchItemReset = useCallback(() => {
    setSearchList([]);

    resetCurrentPage && resetCurrentPage();
  }, [resetCurrentPage]);

  const SelectForSearchType = useMemo(
    () => (
      <FormControl className="search-type" size="small" sx={{ width: 150 }}>
        <InputLabel id="search-term-select-label">검색 대상</InputLabel>
        <Select
          labelId="search-term-select-label"
          id="search-term-select"
          value={termSearchType?.value}
          label="검색 대상"
          onChange={handleTermSearchTypeChange}
        >
          {termSearchTypeOptions.map((st, i) => (
            <MenuItem value={st.value} key={i}>
              {st.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [termSearchType, termSearchTypeOptions, handleTermSearchTypeChange]
  );

  const SearchInput = useMemo(
    () => (
      <Autocomplete
        id="search-term-input"
        options={searchSuggestionList}
        value={null}
        inputValue={searchTermInput}
        onChange={handleSearchTermChange}
        onInputChange={handleSearchInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{ width: 250 }}
            label="검색어"
          />
        )}
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        includeInputInList
      />
    ),
    [
      handleSearchInputChange,
      handleSearchTermChange,
      searchSuggestionList,
      searchTermInput,
    ]
  );

  const SearchList = useMemo(
    () => (
      <>
        {searchList.length > 0 && (
          <Paper
            sx={{
              maxHeight: 120,
              overflow: "auto",
              display: "flex",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {searchList.map((item, index) => {
              return (
                <ListItem
                  key={`${item.searchKind}_${item.searchTerm}_${index}`}
                  sx={{ pl: 0.5, pr: 0.5, width: "auto" }}
                >
                  <Chip
                    label={`${item.searchKindLabel}: ${item.searchTerm}`}
                    onDelete={handleSearchItemDelete(item)}
                  />
                </ListItem>
              );
            })}
          </Paper>
        )}
      </>
    ),
    [handleSearchItemDelete, searchList]
  );

  const TermSearchPanel = useMemo(() => {
    return (
      <Stack flexDirection="column" sx={{ width: 576 }}>
        <Paper variant="outlined" sx={{ p: 1, bgcolor: grey[50] }}>
          <Stack
            flexDirection="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            {SelectForSearchType}

            {SearchInput}

            <Button variant="contained" onClick={handleSearch}>
              검색
            </Button>

            <Button variant="outlined" onClick={handleSearchItemReset}>
              초기화
            </Button>
          </Stack>
        </Paper>

        {SearchList}
      </Stack>
    );
  }, [
    SearchInput,
    SearchList,
    SelectForSearchType,
    handleSearch,
    handleSearchItemReset,
  ]);

  return {
    TermSearchPanel,
    searchList,
  };
}

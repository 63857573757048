import React, { ComponentProps, useCallback, useState } from "react";

import ToastBase from "components/Toast";

type ToastData = Pick<
  ComponentProps<typeof ToastBase>,
  "color" | "message" | "autoHideDuration"
>;

/**
 * @default
 * color: error
 * autoHideDuration: 6000
 */
export default function useToast({
  message,
  color = "error",
  autoHideDuration = 6000,
}: ToastData) {
  const [showsToast, setShowsToast] = useState(false);
  const [
    {
      message: customMessage,
      color: customColor,
      autoHideDuration: customAutoHideDuration,
    },
    setCustomToast,
  ] = useState<ToastData>({});

  const handleToastClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setShowsToast(false);
    setCustomToast({});
  };

  const openToast = useCallback((customToastData?: ToastData) => {
    setShowsToast(true);

    if (customToastData) {
      setCustomToast(customToastData);
    }
  }, []);

  const Toast = () => (
    <ToastBase
      showsToast={showsToast}
      onToastClose={handleToastClose}
      message={customMessage ?? message}
      color={customColor ?? color}
      autoHideDuration={customAutoHideDuration ?? autoHideDuration}
    />
  );

  return { openToast, Toast };
}

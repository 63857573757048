import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import { useState } from "react";
import { DeliveryArea, DeliveryCategory } from "types/shipping";
import OrderList from "./PickingList";
import { useHistory } from "react-router-dom";
import useQueryString from "hooks/useQueryString";

function ShippingPickings() {
  const history = useHistory();
  const { addQuery, queries } = useQueryString<{
    area: DeliveryArea;
    category: DeliveryCategory;
  }>(history);

  let deliveryArea = queries.area;
  let deliveryCategory = queries.category;

  if (!deliveryArea || !deliveryCategory) {
    deliveryArea = "domestic";
    deliveryCategory = "domesticParcel";
    addQuery({
      area: deliveryArea,
      category: deliveryCategory,
    });
  }

  const handleDeliveryAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as DeliveryArea;
    addQuery({
      area: value,
      category: value === "overseas" ? "overseasExpress" : "domesticParcel",
    });
  };

  const handleDeliveryCategoryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value as DeliveryCategory;
    addQuery({
      area: deliveryArea,
      category: value,
    });
  };

  return (
    <Layout>
      <Grid container alignItems={"center"}>
        <Grid item xs={1}>
          <Typography fontWeight={800} variant="body1">
            국내외 구분
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <RadioGroup
            sx={{ pl: 2 }}
            row
            aria-labelledby="delivery-area"
            name="delivery-area"
            value={deliveryArea}
            onChange={handleDeliveryAreaChange}
          >
            <FormControlLabel
              value="domestic"
              control={<Radio size="small" />}
              label="국내"
            />
            <FormControlLabel
              value="overseas"
              control={<Radio size="small" />}
              label="해외"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={1}>
          <Typography fontWeight={800} variant="body1">
            운송수단
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <RadioGroup
            sx={{ pl: 2 }}
            row
            aria-labelledby="delivery-category"
            name="delivery-category"
            value={deliveryCategory}
            onChange={handleDeliveryCategoryChange}
          >
            <FormControlLabel
              value={
                deliveryArea === "domestic"
                  ? "domesticParcel"
                  : "overseasExpress"
              }
              control={<Radio size="small" />}
              label="택배"
            />
            <FormControlLabel
              value={
                deliveryArea === "domestic"
                  ? "domesticTruck"
                  : "overseasFreight"
              }
              disabled={deliveryArea !== "domestic"}
              control={<Radio size="small" />}
              label="화물차량/직접수령"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <OrderList pickingListType={{ deliveryArea, deliveryCategory }} />
    </Layout>
  );
}

export default withRequireAuth(ShippingPickings);

import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { FulfillmentParcelCompany } from "types/fulfillment";

const filterOptionList: TableHeadFilterOption<FulfillmentParcelCompany>[] = [
  { label: "UPS", value: "ups" },
  { label: "FEDEX", value: "fedex" },
  { label: "DHL", value: "dhl" },
  { label: "ACROSS_B", value: "across_b" },
  { label: "LINK_PORT", value: "link_port" },
  { label: "기타", value: "etc" },
];

export default function useOverseasParcelCompanyFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: OverseasParcelCompanyFilterPanel,
    filterList: overseasParcelCompanyFilterList,
    resetFilterState: resetOverseasParcelCompanyFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    OverseasParcelCompanyFilterPanel,
    overseasParcelCompanyPayloadListByFilter: overseasParcelCompanyFilterList,
    resetOverseasParcelCompanyFilter,
  };
}

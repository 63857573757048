import { useMemo } from "react";
import { PersonAdd } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useRecoilValue } from "recoil";

import USER_QUERY from "queries/USER_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "states/auth";
import { BofulManager } from "types/auth";
import { toThousandUnitFormat } from "utils/number";
import { AuthorityLabelDict } from "utils/fulfillment";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import useAddManager from "./useAddManager";
import useManagerDetail from "./useManagerDetail";

type CellId = keyof BofulManager;

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "accountId",
    disablePadding: false,
    label: "ID",
    width: 110,
  },
  {
    id: "name",
    disablePadding: false,
    label: "이름",
    width: 110,
  },
  {
    id: "authority",
    disablePadding: false,
    label: "권한",
    width: 110,
  },
];

function ManagerList() {
  const {
    data: managerList,
    ResponseHandler: ResponseHandlerOfManagerList,
    refetch,
  } = USER_QUERY.useGetMangerList();

  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const { setManagerDetailModalData, ManagerDetailModal } = useManagerDetail();

  const { canAddManager, FormModalToAddManager, showFormToAddManager } =
    useAddManager({
      authority: currentUser?.authority,
      refetchManagerList: refetch,
    });

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!managerList) return [];

    return managerList?.map((v) => {
      const row: TableBodyRow<CellId> = {
        accountId: v.accountId,
        name: v.name,
        authority: AuthorityLabelDict[v.authority],
        handleRowClick: () => setManagerDetailModalData(v),
      };

      return row;
    });
  }, [managerList, setManagerDetailModalData]);

  return (
    <Layout>
      <Table
        title={`매니저 리스트 (총 ${toThousandUnitFormat(
          managerList?.length
        )})`}
        headCells={headCells}
        rows={rows}
        toolbarItems={{
          right: [
            <Button
              key={0}
              variant="outlined"
              size="medium"
              startIcon={<PersonAdd />}
              onClick={() => showFormToAddManager()}
              disabled={!canAddManager}
            >
              매니저 추가
            </Button>,
          ],
        }}
      />

      {ResponseHandlerOfManagerList}

      {FormModalToAddManager}

      {ManagerDetailModal}
    </Layout>
  );
}

export default withRequireAuth(ManagerList);

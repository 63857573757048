import styled from "styled-components";
import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const beforeCompleteInspectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;

  > .title {
    margin-top: 16px;
    color: ${COLOR.grayScale_700};
    ${setFontStyle("SubHead1", "Bold")};
  }

  > .desc {
    margin-top: 8px;
    color: ${COLOR.grayScale_800};
    ${setFontStyle("Body7")};
  }
`;

export default {
  beforeCompleteInspectionContainer,
};

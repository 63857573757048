import { List, ListItem, ListItemText, Paper } from "@mui/material";

export default function Summary({
  barcode,
  warehouse,
}: {
  barcode: string | undefined;
  warehouse: string | undefined;
}) {
  return (
    <Paper variant="outlined">
      <List>
        <ListItem>
          <ListItemText
            primary="LOC ID / 센터명"
            secondary={`${barcode ?? "-"} / ${warehouse ?? "-"}`}
          />
        </ListItem>
      </List>
    </Paper>
  );
}

import { ChangeEvent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { LocationPrintType } from "types/location";

import usePrintLocationLabel from "hooks/usePrintLocationLabel";

export default function usePrintLocationLabelWithDialog({
  locationIds,
}: {
  locationIds: number[];
}) {
  const [showsPrintLocationLabelDialog, setShowsPrintLocationLabelDialog] =
    useState(false);
  const [selectedPrintType, setSelectedPrintType] =
    useState<LocationPrintType>("barCode");

  const { printLocationLabel, ResultHandlerOfPrintLocationLabel } =
    usePrintLocationLabel({
      warehouseId: 1, // TODO: 창고 이전 전에는 고정해 둠
      printType: selectedPrintType,
      locationIds,

      onSettled: () => {
        handlePrintLocationLabelDialogClose();
      },
    });

  const handlePrintLocationLabelDialogOpen = () => {
    setShowsPrintLocationLabelDialog(true);
  };

  const handlePrintLocationLabelDialogClose = () => {
    setShowsPrintLocationLabelDialog(false);
  };

  const handlePrintTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPrintType(e.target.value as LocationPrintType);
  };

  const PrintLocationLabelDialog = (
    <>
      <Dialog
        open={showsPrintLocationLabelDialog}
        onClose={handlePrintLocationLabelDialogClose}
      >
        <DialogTitle>
          출력하실 로케이션 ID 라벨 타입을 선택해주세요.
        </DialogTitle>

        <DialogContent dividers>
          <RadioGroup
            row
            name="printType"
            defaultValue="barCode"
            value={selectedPrintType}
            onChange={handlePrintTypeChange}
          >
            <FormControlLabel
              value="barCode"
              control={<Radio />}
              label="바코드"
            />
            <FormControlLabel
              value="qrCode"
              control={<Radio />}
              label="QR코드"
            />
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={printLocationLabel}>
            출력
          </Button>
        </DialogActions>
      </Dialog>

      {ResultHandlerOfPrintLocationLabel}
    </>
  );

  return { handlePrintLocationLabelDialogOpen, PrintLocationLabelDialog };
}

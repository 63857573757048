import { useCallback, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, FormControl, MenuItem, Select } from "@mui/material";

import RETURNING_QUERY, {
  RETURNING_QUERY_KEY_GEN,
} from "queries/RETURNING_QUERY";
import { FulfillmentParcelCompany } from "types/fulfillment";
import { ReturningInvoice } from "types/returning";
import { PARCEL_COMPANY_OPTION_LIST } from "utils/returning";
import Modal from "components/Modal";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";
import TextField from "components/TextField";

import Styled from "../index.styles";

type CellId =
  | "originalParcelCompany"
  | "originalInvoiceNo"
  | "parcelCompany"
  | "invoiceNo";

export interface SubmitReturningInvoiceNoData {
  returningId: number;
  shippingInvoices: {
    originalParcelCompany: string;
    originalInvoiceNo: string;
  }[];
}

interface SubmitReturningInvoiceNoFormType {
  invoices: {
    parcelCompany: FulfillmentParcelCompany | "";
    invoiceNo: string;
  }[];
}

export default function useSubmitReturningInvoiceNo() {
  const [data, setData] = useState<SubmitReturningInvoiceNoData>();

  const {
    handleSubmit,
    control,
    reset: resetForm,
    formState,
  } = useForm<SubmitReturningInvoiceNoFormType>({
    mode: "onChange",
  });

  const queryClient = useQueryClient();

  const openFormToSubmitReturningInvoiceNo = useCallback(
    ({ returningId, shippingInvoices }: SubmitReturningInvoiceNoData) => {
      setData({
        returningId,
        shippingInvoices,
      });
    },
    [setData]
  );

  const handleSuccess = useCallback(() => {
    resetForm();
    queryClient.invalidateQueries(
      RETURNING_QUERY_KEY_GEN.getAdminReturningList({})
    );
    setData(undefined);
  }, [queryClient, resetForm]);

  const {
    mutate: submitReturningInvoiceNo,
    ResponseHandler: ResponseHandlerOfSubmitReturningInvoiceNo,
  } = RETURNING_QUERY.useSubmitReturningInvoiceNo({
    handleConfirmSuccess: handleSuccess,
  });

  const handleRequest: SubmitHandler<SubmitReturningInvoiceNoFormType> =
    useCallback(
      (formData) => {
        if (!data) return;

        const invoiceInfos: ReturningInvoice[] = formData.invoices.map((v) => ({
          invoiceNo: v.invoiceNo,
          parcelCompany: v.parcelCompany as FulfillmentParcelCompany,
        }));

        submitReturningInvoiceNo({
          pathParams: { returningId: data.returningId },
          invoiceInfos,
        });
      },
      [submitReturningInvoiceNo, data]
    );

  const handleCancel = useCallback(() => {
    setData(undefined);
    resetForm();
  }, [resetForm]);

  const headCells = useMemo((): TableHeadCell<CellId>[] => {
    return [
      {
        id: "originalParcelCompany",
        disablePadding: false,
        label: "원택배사",
        width: 100,
      },
      {
        id: "originalInvoiceNo",
        disablePadding: false,
        label: "원송장번호",
        width: 140,
      },
      {
        id: "parcelCompany",
        disablePadding: false,
        label: "반품택배사",
        width: 160,
      },
      {
        id: "invoiceNo",
        disablePadding: false,
        label: "반품송장번호",
        width: 200,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    return (
      data?.shippingInvoices?.map((v, i) => {
        // TODO: 고도화 전까지 송장입력은 하나만 할 수 있도록 수정
        const isDisabled = i !== 0;

        const row: TableBodyRow<CellId> = {
          originalParcelCompany: v.originalParcelCompany,
          originalInvoiceNo: v.originalInvoiceNo,
          parcelCompany: isDisabled ? (
            <Select
              labelId={`parcel-company-select-label-${i}`}
              id={`parcel-company-select-${i}`}
              displayEmpty
              size="small"
              disabled
            >
              {PARCEL_COMPANY_OPTION_LIST.map((option, i) => (
                <MenuItem value={option.value} key={i}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Controller
              name={`invoices.${i}.parcelCompany`}
              rules={{ required: true }}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <Select
                    {...field}
                    ref={null}
                    labelId={`parcel-company-select-label-${i}`}
                    id={`parcel-company-select-${i}`}
                    displayEmpty
                    size="small"
                  >
                    {PARCEL_COMPANY_OPTION_LIST.map((option, i) => (
                      <MenuItem value={option.value} key={i}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          ),
          invoiceNo: isDisabled ? (
            <TextField size="small" disabled />
          ) : (
            <Controller
              name={`invoices.${i}.invoiceNo`}
              rules={{ required: true }}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} ref={null} size="small" />
              )}
            />
          ),
        };

        return row;
      }) || []
    );
  }, [control, data?.shippingInvoices]);

  const FormToSubmitReturningInvoiceNo = useMemo(() => {
    if (!data) return null;

    return (
      <>
        <Modal
          isOpened
          handleClose={handleCancel}
          modalBody={
            <div style={{ maxHeight: "80vh", overflow: "scroll" }}>
              <Table
                title={"쇼핑몰에서 생성된 반품송장번호를 입력 해 주세요."}
                headCells={headCells}
                rows={rows}
                sx={{
                  // Table컴포넌트에서 자동으로 maxHeight를 계산하지만, 이 경우는 모달내부라 직접 설정
                  maxHeight: "35vh",
                }}
              />

              <Styled.submitReturningInvoiceNoButtonWrapper>
                <Button
                  onClick={handleSubmit(handleRequest)}
                  variant="contained"
                  size="large"
                  disabled={!formState.isValid}
                >
                  확인
                </Button>
              </Styled.submitReturningInvoiceNoButtonWrapper>
            </div>
          }
        />

        {ResponseHandlerOfSubmitReturningInvoiceNo}
      </>
    );
  }, [
    data,
    handleCancel,
    headCells,
    rows,
    handleSubmit,
    handleRequest,
    formState.isValid,
    ResponseHandlerOfSubmitReturningInvoiceNo,
  ]);

  return { openFormToSubmitReturningInvoiceNo, FormToSubmitReturningInvoiceNo };
}

const COLOR = {
  primaryBlue: "#5482D6",
  primaryBlue_80: "#769BDE",
  primaryBlue_60: "#98B4E6",
  primaryBlue_40: "#BBCDEF",
  primaryBlue_10: "#EEF3FB",

  primaryPurple: "#7160D7",
  primaryPurple_80: "#8D80DF",
  primaryPurple_60: "#AAA0E7",
  primaryPurple_40: "#C6BFEF",
  primaryPurple_10: "#F1F0FB",

  secondaryDeepBlue: "#4062A3",
  secondaryDeepBlue_80: "#6681B5",
  secondaryDeepBlue_60: "#8CA1C8",
  secondaryDeepBlue_40: "#B3C0DA",
  secondaryDeepBlue_10: "#ECF0F6",

  secondaryDeepPurple: "#483C90",
  secondaryDeepPurple_80: "#6D63A6",
  secondaryDeepPurple_60: "#918ABC",
  secondaryDeepPurple_40: "#B6B1D3",
  secondaryDeepPurple_10: "#EDECF4",

  grayScale_50: "#FAFAFA",
  grayScale_100: "#F5F5F5",
  grayScale_200: "#EEEEEE",
  grayScale_300: "#E0E0E0",
  grayScale_400: "#BDBDBD",
  grayScale_500: "#9E9E9E",
  grayScale_600: "#757575",
  grayScale_700: "#616161",
  grayScale_800: "#424242",
  grayScale_900: "#212121",

  bgColor_1: "#FCFCFC",
  bgColor_2: "#F7F7F7",
  bgColor_3: "#F0F2F5",

  bk: "#000000",
  bk_80: "rgba(0, 0, 0, 0.8)",
  bk_60: "rgba(0, 0, 0, 0.6)",
  bk_40: "rgba(0, 0, 0, 0.4)",

  white: "#ffffff",
  white_40: "rgba(255, 255, 255, 0.4)",

  pointSuccess: "#77CC84",
  pointSuccess_60: "#ADE0B5",
  pointSuccess_40: "#C9EBCE",
  pointSuccess_20: "#E4F5E6",

  pointWarning: "#eb5757",
  pointWarning_60: "#F39A9A",
  pointWarning_40: "#F7BCBC",
  pointWarning_20: "#FDEFEF",

  amazoneYellow: "#FF9900",
  amazoneYellow_60: "#FFC266",
  amazoneYellow_40: "#FFD699",
  amazoneYellow_20: "#FFEBCC",

  amazoneBlue: "#146EB4",
  amazoneBlue_60: "#72A8D2",
  amazoneBlue_20: "#D0E2F0",

  goodYellow: "#FFD600",
  goodYellow_20: "rgba(255, 214, 0, 0.4)",
};

/**
 * 모바일 최대 너비
 * Small devices (landscape phones, less than 768px)
 */
const MOBILE_MAX_WIDTH = 767.98;

/**
 * @deprecated 태블릿 사이즈는 지원하지 않으므로 사용하지 말것
 * TODO: 사용코드가 없어지면 삭제
 * 태블릿 최대 너비
 * Medium devices (tablets, less than 992px)
 */
const TABLET_MAX_WIDTH = 991.98;

export { COLOR, MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH };

import { useEffect, useState } from "react";

/**
 * @deprecated useCheckIsMobile으로 대체
 * TODO: 모두 대체되면 삭제
 */
export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      // eslint-disable-next-line no-undef
      typeof window.navigator === "undefined" ? "" : window.navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);
  }, []);

  return { isMobile };
}

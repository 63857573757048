import { useRecoilValue } from "recoil";

import { COMMON_SELECTORS } from "states/common";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

function Home() {
  const managerWorkerList = useRecoilValue(
    COMMON_SELECTORS.MANAGER_WORKER_LIST
  );

  return (
    <Layout>
      <div>Boful 관리자 메인페이지</div>

      {managerWorkerList.map((v, i) => (
        <div key={i}>{v.name}</div>
      ))}
    </Layout>
  );
}

export default withRequireAuth(Home);

import { useCallback, useEffect, useMemo, useState } from "react";
import { SxProps, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { GET_LOCATION_DETAILS_BY_LOCATION_IDS_RES } from "api-interfaces/inventory";
import { ShippingItem, ShippingLocation } from "types/shipping";
import { Inventory } from "types/sku";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import Table, { TableHeadCell } from "components/Table";

import AdjustInput from "./AdjustInput";

type CellId =
  | "order"
  | "itemNameAndSkuId"
  | "locationBarCode"
  | "releaseQuantity"
  | "availableQuantity"
  | "adjustmentQuantity"
  | "availableQuantityAfterRelease";

interface TableData {
  order: number | string;
  itemNameAndSkuId: string;
  locationBarCode: string | undefined;
  releaseQty: number | string;
  availableQty: number;
  adjustQuantity: JSX.Element;
  availableQtyAfterRelease: number;
}

export interface InputData {
  skuId: number;
  qty: number;
  locationBarCode: string;
}

interface Props {
  shippingItems: ShippingItem[] | undefined;
  locationDetails: GET_LOCATION_DETAILS_BY_LOCATION_IDS_RES | undefined;
}

const tableStyle: SxProps = {
  maxHeight: "40vh",
  overflow: "auto",
};

const tableHeader: TableHeadCell<CellId>[] = [
  {
    id: "order",
    label: "순서",
    disablePadding: false,
    width: 60,
  },
  {
    id: "itemNameAndSkuId",
    label: "상품명 / SKU ID",
    disablePadding: false,
  },
  {
    id: "locationBarCode",
    label: "위치",
    disablePadding: false,
  },
  {
    id: "availableQuantity",
    label: (
      <>
        가용재고
        <br />
        (PCS)
      </>
    ),
    disablePadding: false,
    width: 120,
  },
  {
    id: "releaseQuantity",
    label: (
      <>
        확정 출고수량
        <br />
        (PCS)
      </>
    ),
    disablePadding: false,
    width: 120,
  },
  {
    id: "adjustmentQuantity",
    label: (
      <>
        변경할 출고수량
        <br />
        (PCS)
      </>
    ),
    disablePadding: false,
    width: 130,
  },
  {
    id: "availableQuantityAfterRelease",
    label: (
      <>
        출고 후 가용재고
        <br />
        (PCS)
      </>
    ),
    disablePadding: false,
    width: 130,
  },
];

export default function useAdjustTable({
  shippingItems,
  locationDetails,
}: Props) {
  const [tableRows, setTableRows] = useState<TableData[]>([]);
  const [allInputData, setAllInputData] = useState<InputData[]>([]);
  const [totalReleaseQuantity, setTotalReleaseQuantity] = useState<number>(0);

  const createTableRows = useCallback(
    (locationDetails: ShippingLocation[]) => {
      if (!shippingItems) return;

      const getSkuIds = () => [
        ...new Set(shippingItems?.map((shippingItem) => shippingItem.skuId)),
      ];

      const skuIds = getSkuIds();

      const getSkuIdToReleaseQuantityDict = () => {
        const skuIdToReleaseQuantityDict: { [skuId: number]: number } = {};
        skuIds.forEach((skuId) => {
          const releaseQuantity = shippingItems
            .filter((shippingItem) => shippingItem.skuId === skuId)
            .reduce((sum, shippingItem) => sum + shippingItem.quantity, 0);
          skuIdToReleaseQuantityDict[skuId] = releaseQuantity;
        });
        return skuIdToReleaseQuantityDict;
      };

      const getLocationIdToReleaseQuantityDict = () => {
        const locationIdToReleaseQuantityDict: {
          [locationId: number]: number;
        } = {};
        skuIds.forEach((skuId) => {
          shippingItems
            .filter((shippingItem) => shippingItem.skuId === skuId)
            .forEach((shippingItem) => {
              if (locationIdToReleaseQuantityDict[shippingItem.locationId]) {
                locationIdToReleaseQuantityDict[shippingItem.locationId] +=
                  shippingItem.quantity;
              } else {
                locationIdToReleaseQuantityDict[shippingItem.locationId] =
                  shippingItem.quantity;
              }
            });
        });
        return locationIdToReleaseQuantityDict;
      };

      const getSkuIdToInventoryItemsDict = () => {
        const skuIdToInventoryItemsDict: {
          [skuId: number]: Inventory[] | undefined;
        } = {};
        skuIds.forEach((skuId) => {
          const inventoryItem = shippingItems.find(
            (shippingItem) => shippingItem.skuId === skuId
          )?.sku.inventory;
          skuIdToInventoryItemsDict[skuId] = inventoryItem;
        });
        return skuIdToInventoryItemsDict;
      };

      const getTotalReleaseQuantity = () => {
        return shippingItems.reduce(
          (sum, shippingItem) => sum + shippingItem.quantity,
          0
        );
      };

      const getLocationBarCodeByLocationId = (locationId: number) => {
        return locationDetails.find(
          (locationDetail) => locationDetail.id === locationId
        )?.barCode;
      };

      const getItemNameAndSkuId = (skuId: number) => {
        return `${
          shippingItems?.find((shippingItem) => shippingItem.skuId === skuId)
            ?.sku.itemName
        } / ${getFormattedSingleSkuId(skuId)}`;
      };

      const skuIdToInventoryItemsDict = getSkuIdToInventoryItemsDict();
      const skuIdToReleaseQuantityDict = getSkuIdToReleaseQuantityDict();
      const locationIdToReleaseQuantityDict =
        getLocationIdToReleaseQuantityDict();

      const getAvailableQtyByInventory = (inventoryItem: Inventory) => {
        if (locationIdToReleaseQuantityDict[inventoryItem.locationId]) {
          return (
            inventoryItem.availableQty +
            locationIdToReleaseQuantityDict[inventoryItem.locationId]
          );
        } else {
          return inventoryItem.availableQty;
        }
      };

      const getNewTableRows = () => {
        const newTableRows: TableData[] = [];

        skuIds.forEach((skuId, skuIndex) => {
          let inventoryIndex = 0;
          const inventoryItems = skuIdToInventoryItemsDict[skuId];

          inventoryItems?.forEach((inventoryItem) => {
            const locationBarCode = getLocationBarCodeByLocationId(
              inventoryItem.locationId
            );
            if (!locationBarCode) return;

            const order = skuIndex + 1;
            const itemNameAndSkuId = getItemNameAndSkuId(skuId);
            const releaseQty = skuIdToReleaseQuantityDict[skuId];
            const availableQty = getAvailableQtyByInventory(inventoryItem);
            const inputMax =
              availableQty < releaseQty ? availableQty : releaseQty;
            const adjustQuantity = (
              <AdjustInput
                skuId={skuId}
                locationBarCode={locationBarCode}
                setAllInputData={setAllInputData}
                min={0}
                max={inputMax}
              />
            );
            const availableQtyAfterRelease =
              availableQty -
              (allInputData.find(
                (inputData) =>
                  inputData.skuId === skuId &&
                  inputData.locationBarCode === locationBarCode
              )?.qty ?? 0);

            const isFirstInventory = inventoryIndex === 0;
            inventoryIndex++;

            const newTableRow: TableData = {
              order: isFirstInventory ? order : "",
              itemNameAndSkuId: isFirstInventory ? itemNameAndSkuId : "",
              locationBarCode,
              availableQty,
              releaseQty: isFirstInventory ? releaseQty : "",
              adjustQuantity,
              availableQtyAfterRelease,
            };

            newTableRows.push(newTableRow);
          });
        });

        return newTableRows;
      };

      const newTableRows = getNewTableRows();
      setTableRows(newTableRows);

      const totalReleaseQuantity = getTotalReleaseQuantity();
      setTotalReleaseQuantity(totalReleaseQuantity);
    },
    [allInputData, shippingItems]
  );

  /**
   * 기존 코드가 createTableRows를 한 번만 호출하고 input값만 별도로 변경시키도록 개발되어 있기 때문에
   * 출고 후 가용 재고를 위해 테이블을 다시 렌더링하는 부분은 useEffect를 사용함
   */
  useEffect(() => {
    if (locationDetails) {
      createTableRows(locationDetails);
    }
  }, [allInputData, createTableRows, locationDetails]);

  const resetTable = useCallback(() => {
    setTableRows([]);
    setAllInputData([]);
    setTotalReleaseQuantity(0);
  }, []);

  const getTotalAdjustQuantity = useCallback(() => {
    const newTotalAdjustQuantity = allInputData.reduce(
      (sum, inputData) => sum + inputData.qty,
      0
    );

    return newTotalAdjustQuantity;
  }, [allInputData]);

  const totalAdjustQuantity = getTotalAdjustQuantity();

  const AdjustTable = useMemo(() => {
    return <Table headCells={tableHeader} rows={tableRows} sx={tableStyle} />;
  }, [tableRows]);

  const AdjustTableTotal = useMemo(() => {
    return (
      <section>
        <Typography
          variant="subtitle2"
          component="div"
          color={grey[700]}
          gutterBottom
          sx={{ paddingTop: "10px" }}
        >
          확정 출고 수량 총계: {totalReleaseQuantity} 건 / 변경할 출고수량 총계:{" "}
          {totalAdjustQuantity} 건
        </Typography>
      </section>
    );
  }, [totalAdjustQuantity, totalReleaseQuantity]);

  return {
    AdjustTable,
    AdjustTableTotal,
    createTableRows,
    resetTable,
    allInputData,
  };
}

import React, { ChangeEvent, Dispatch, useState } from "react";
import { TextField } from "@mui/material";

import { InputData } from "..";

interface AdjustInputProps {
  skuId: number;
  locationBarCode: string;
  setAllInputData: Dispatch<React.SetStateAction<InputData[]>>;
  min: number;
  max: number;
}

export default function AdjustInput({
  skuId,
  locationBarCode,
  setAllInputData,
  min,
  max,
}: AdjustInputProps) {
  const [isError, setIsError] = useState(false);

  const handleAdjustInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newAdjustQuantity = Number(e.target.value);

    if (newAdjustQuantity > max || newAdjustQuantity < min) setIsError(true);
    else setIsError(false);

    const getNewAllInputData = (prevAllInputData: InputData[]) => {
      const isNewAdjustQuantityZero = newAdjustQuantity === 0;
      const hasInputData = !!prevAllInputData.find(
        (inputData) => inputData.locationBarCode === locationBarCode
      );

      if (isNewAdjustQuantityZero && hasInputData) {
        const newAllInputData = prevAllInputData.filter(
          (inputData) => inputData.locationBarCode !== locationBarCode
        );

        return newAllInputData;
      }

      if (!isNewAdjustQuantityZero && hasInputData) {
        const newAllInputData = prevAllInputData.map((inputData) => {
          if (inputData.locationBarCode === locationBarCode)
            return { ...inputData, qty: newAdjustQuantity };
          else return inputData;
        });

        return newAllInputData;
      }

      if (!isNewAdjustQuantityZero && !hasInputData) {
        const newAllInputData = [
          ...prevAllInputData,
          {
            skuId,
            locationBarCode,
            qty: newAdjustQuantity,
          },
        ];

        return newAllInputData;
      }

      return prevAllInputData;
    };

    setAllInputData(getNewAllInputData);
  };

  return (
    <TextField
      size="small"
      placeholder="0"
      error={isError}
      fullWidth={true}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min, max }}
      onChange={handleAdjustInputChange}
    />
  );
}

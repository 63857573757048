import { useCallback, useMemo } from "react";
import { Button } from "@mui/material";

import useSkuImageModal from "hooks/useSkuImageModal";
import { FulfillmentAttachment } from "types/fulfillment";
import { ReturningListItem } from "types/returning";
import { ShippingOrderItem } from "types/shipping";
import { getFormattedSkuId } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

type CellId =
  | "skuId"
  | "itemName"
  | "barCode"
  | "orderQuantity"
  | "returningOrderQuantity"
  | "image";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 200,
  },
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 100,
  },
  {
    id: "barCode",
    disablePadding: false,
    label: "상품 바코드",
    width: 100,
  },
  {
    id: "orderQuantity",
    disablePadding: false,
    label: "주문수량(ORDER)",
    width: 150,
  },
  {
    id: "returningOrderQuantity",
    disablePadding: false,
    label: "반품주문수량(ORDER)",
    width: 170,
  },
  {
    id: "image",
    disablePadding: false,
    label: "이미지",
    width: 50,
  },
];

export default function ReturningOrderDetailList({
  returningDetailData,
}: {
  returningDetailData: ReturningListItem | undefined;
}) {
  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();

  const handleItemImageSelect = useCallback(
    (
        skuData: {
          id: number;
          attachment: FulfillmentAttachment[];
        } & (
          | { type: "single"; itemName: string }
          | {
              type: "group";
              groupName: string;
            }
        )
      ) =>
      () => {
        handleSkuForImageModalSelect({
          skuData,
          imageType: "item",
        });
      },
    [handleSkuForImageModalSelect]
  );

  // 반품주문수량 계산
  const calculateReturnOrderQuantity = (
    returningListItem: ReturningListItem,
    orderItem: Pick<ShippingOrderItem, "productGroupId" | "skuId">
  ) =>
    orderItem.productGroupId && returningListItem.groups
      ? returningListItem.groups
          .find((group) => group.id === orderItem.productGroupId)
          ?.groupItems.map(
            (groupItem) =>
              (returningListItem.items.find(
                (returningItem) =>
                  returningItem.skuId === groupItem.skuId &&
                  returningItem.productGroupId === groupItem.productGroupId
              )?.quantity ?? 0) / groupItem.qty
          )
          // 그룹의 반품주문수량 - 딱 맞아 떨어지지않으면 - 로 표시한다
          .reduce(
            (prev, curr, _, array) =>
              prev !== null && Number.isInteger(curr) && curr === array[0]
                ? curr
                : null,
            0 as number | null
          ) ?? "-"
      : returningListItem.items
          .filter(
            (returningItem) =>
              returningItem.skuId === orderItem.skuId &&
              !returningItem.productGroupId
          )
          .reduce((prev, curr) => prev + curr.quantity, 0);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!returningDetailData) {
      return [];
    }

    const returningOrderItem: {
      itemName: string;
      skuId: number | undefined;
      productGroupId: number | undefined;
      quantity: number;
    }[] =
      returningDetailData.shipping?.orders.map((so) => ({
        itemName: so.itemName,
        skuId: so.skuId,
        productGroupId: so.productGroupId,
        quantity: so.quantity,
      })) || [];

    return returningOrderItem.map((orderItem) => {
      const returningOrderQuantity = calculateReturnOrderQuantity(
        returningDetailData,
        orderItem
      );

      return {
        itemName: orderItem.itemName ?? "-",
        skuId: getFormattedSkuId({
          skuId: orderItem.skuId ?? orderItem.productGroupId,
          isGroup: !!orderItem.productGroupId,
        }),
        barCode:
          returningDetailData.shipping?.items?.find(
            (shippingItem) => shippingItem.skuId === orderItem.skuId
          )?.sku?.barCode ?? "-",
        orderQuantity: toThousandUnitFormat(orderItem.quantity),
        returningOrderQuantity: toThousandUnitFormat(returningOrderQuantity),
        image: (
          <Button
            variant="outlined"
            size="small"
            onClick={handleItemImageSelect({
              id: orderItem.skuId ?? orderItem.productGroupId ?? 0,
              attachment:
                (orderItem.skuId
                  ? returningDetailData.items.find(
                      (returningItem) => returningItem.skuId === orderItem.skuId
                    )?.sku?.attachment
                  : []) ?? [],
              type: orderItem.skuId ? "single" : "group",
              itemName: orderItem.itemName,
              groupName: orderItem.itemName,
            })}
          >
            보기
          </Button>
        ),
      };
    });
  }, [handleItemImageSelect, returningDetailData]);

  return (
    <>
      <Table<CellId> title="반품주문정보" headCells={headCells} rows={rows} />

      <SkuImageModal />
    </>
  );
}

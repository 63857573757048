import { useMemo, useState } from "react";
import { Button, Divider, Stack } from "@mui/material";

import { TEAM_ROLE } from "constants/user";
import USER_QUERY from "queries/USER_QUERY";
import { TeamAccessDetail, TeamMember } from "types/user";
import { toFormattedDate } from "utils/date";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import Detail, { DetailProps } from "pages/user/Detail";

import AccessDetailModal from "./AccessDetailModal";

type CellId = Exclude<keyof TeamMember, "county">;

const headCellList: TableHeadCell<CellId>[] = [
  {
    id: "userId",
    disablePadding: false,
    label: "회원번호",
    width: 80,
  },
  {
    id: "permission",
    disablePadding: false,
    label: "권한",
    width: 120,
  },
  {
    id: "name",
    disablePadding: false,
    label: "담당자 이름",
    width: 120,
  },
  {
    id: "phone",
    disablePadding: false,
    label: "연락처",
    width: 120,
  },
  {
    id: "email",
    disablePadding: false,
    label: "이메일",
    width: 200,
  },
  {
    id: "joinedAt",
    disablePadding: false,
    label: "초대수락일자",
    width: 150,
  },
];

export default function Basic({ teamId }: { teamId: number | undefined }) {
  const [accessDetail, setAccessDetail] = useState<TeamAccessDetail>();

  const {
    data: {
      id,
      masterName,
      teamName,
      masterPhone,
      fulfillmentManagerName,
      masterEmail,

      company,
      BRN,
      engCompany,
      invoiceEmail,
      representativeName,
      phone,

      members,
    } = {},
    ResponseHandler: ResponseHandlerOfTeamBasicDetail,
  } = USER_QUERY.useGetTeamBasicDetail({
    teamId,
    enabled: !!teamId,
  });

  const teamDetail: DetailProps = {
    title: "팀 정보",
    detailList: [
      {
        label: "팀 번호",
        value: id,
      },
      {
        label: "마스터 이름",
        value: masterName,
      },
      {
        label: "팀 이름",
        value: teamName,
      },
      {
        label: "마스터 연락처",
        value: masterPhone,
      },
      {
        label: "풀필먼트 담당자",
        value: fulfillmentManagerName,
      },
      {
        label: "마스터 이메일",
        value: masterEmail,
      },
    ],
  };

  const companyDetail: DetailProps = {
    title: "회사 정보",
    detailList: [
      {
        label: "회사 이름",
        value: company,
      },
      {
        label: "사업자등록번호",
        value: BRN,
      },
      {
        label: "회사 이름 영문명",
        value: engCompany,
      },
      {
        label: "세금계산서 이메일",
        value: invoiceEmail,
      },
      {
        label: "대표자",
        value: representativeName,
      },
      {
        label: "대표번호",
        value: phone,
      },
    ],
  };

  const rowList = useMemo((): TableBodyRow<CellId>[] => {
    if (!members) {
      return [];
    }

    return members.map(
      ({
        userId,
        permission: { teamRole, detail },
        name,
        phone,
        email,
        joinedAt,
      }) => ({
        userId,
        permission: (
          <Stack direction="column" gap={1}>
            {teamRole ? TEAM_ROLE[teamRole] : "-"}
            <Button
              size="small"
              variant="outlined"
              onClick={() => setAccessDetail(detail)}
            >
              상세보기
            </Button>
          </Stack>
        ),
        name,
        phone,
        email,
        joinedAt: toFormattedDate(joinedAt, `YYYY.MM.DD HH:mm`),
      })
    );
  }, [members]);

  const handleAccessDetailModalClose = () => setAccessDetail(undefined);

  return (
    <>
      <Stack gap={3} divider={<Divider flexItem />}>
        <Detail {...teamDetail} />

        <Detail {...companyDetail} />

        <Table title="팀 멤버" headCells={headCellList} rows={rowList} />
      </Stack>

      <AccessDetailModal
        accessDetail={accessDetail}
        onClose={handleAccessDetailModalClose}
      />

      {ResponseHandlerOfTeamBasicDetail}
    </>
  );
}

import { useState } from "react";
import { NoteAlt } from "@mui/icons-material";
import { Button } from "@mui/material";

import AddParcelDetailModal from "./AddParcelDetailModal";

export default function AddParcelDetail({
  shippingId,
  parcelCompany,
  invoiceNo,
}: {
  shippingId: number | undefined;
  parcelCompany: string | undefined;
  invoiceNo: string | undefined;
}) {
  const [showsAddParcelDetailModal, setShowsAddParcelDetailModal] =
    useState(false);

  const handleAddParcelDetailModalOpen = () => {
    setShowsAddParcelDetailModal(true);
  };

  const handleAddParcelDetailModalClose = () => {
    setShowsAddParcelDetailModal(false);
  };

  return (
    <>
      <Button
        startIcon={<NoteAlt />}
        variant="outlined"
        onClick={handleAddParcelDetailModalOpen}
      >
        배송정보 수기 입력
      </Button>

      <AddParcelDetailModal
        key={String(showsAddParcelDetailModal)}
        shippingId={shippingId}
        parcelCompany={parcelCompany}
        invoiceNo={invoiceNo}
        showsAddParcelDetailModal={showsAddParcelDetailModal}
        onAddParcelDetailModalClose={handleAddParcelDetailModalClose}
      />
    </>
  );
}

import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { ShippingDeliveryType } from "types/shipping";

const filterOptionList: TableHeadFilterOption<
  Extract<ShippingDeliveryType, "truck" | "selfCollect">
>[] = [
  { label: "화물차량", value: "truck" },
  { label: "직접수령", value: "selfCollect" },
];

export default function useDomesticTruckDeliveryTypeFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: DomesticTruckDeliveryTypeFilterPanel,
    filterList: domesticTruckDeliveryTypeFilterList,
    resetFilterState: resetDomesticTruckDeliveryTypeFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    DomesticTruckDeliveryTypeFilterPanel,
    domesticTruckDeliveryTypePayloadListByFilter:
      domesticTruckDeliveryTypeFilterList,
    resetDomesticTruckDeliveryTypeFilter,
  };
}

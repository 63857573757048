import { TeamAccessKind, TeamRole } from "types/user";

const TEAM_ROLE: Record<TeamRole, string> = {
  master: "팀마스터",
  manager: "팀관리자",
  staff: "팀원",
};

const TEAM_ACCESS_KIND: Record<TeamAccessKind, string> = {
  write: "등록/편집하기",
  read: "보기",
  none: "권한없음",
};

export { TEAM_ROLE, TEAM_ACCESS_KIND };

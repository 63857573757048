import { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import SKU_QUERY from "queries/SKU_QUERY";

import useSkuImageModal from "hooks/useSkuImageModal";
import {
  getFormattedGroupSkuId,
  getFormattedMaterialId,
  getFormattedSingleSkuId,
  getMaterialPackageType,
  getPackageCategoryName,
  getPackageTypeName,
  getReturningConditionName,
} from "utils/fulfillment";

import Table, { TableHeadCell } from "components/Table";

import Detail, { DetailProps } from "../Detail/Detail";

type CellId = "skuId" | "itemName" | "barCode" | "quantity";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 120,
  },
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 250,
  },
  {
    id: "barCode",
    disablePadding: false,
    label: "상품 바코드",
    width: 150,
  },
  {
    id: "quantity",
    disablePadding: false,
    label: "수량",
    width: 120,
  },
];

export default function useGroupDetailModal() {
  const [selectedSkuId, setSelectedSkuId] = useState<number>();
  const [groupId, setGroupId] = useState<number>(0);
  const active = !!groupId;

  const showGroupDetailModal = (productGroupId: number) => {
    setGroupId(productGroupId);
  };

  const handleGroupDetailModalClose = () => {
    setGroupId(0);
  };

  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();

  const { data: groupSkuDetail } = SKU_QUERY.useGetGroupSkuDetail({
    groupSkuId: groupId,
    enabled: !!groupId,
  });

  const { ResponseHandler: ResponseHandlerOfGetSingleSkuDetail } =
    SKU_QUERY.useGetSingleSkuDetail({
      skuId: selectedSkuId,
      enabled: !!selectedSkuId,
      onSuccess: (data) => {
        if (!data) return;

        const refinedSku = {
          ...data.sku,
          attachment: [...data.attachment],
          type: "single" as const,
        };

        handleSkuForImageModalSelect({
          skuData: refinedSku,
          imageType: "item",
        });
        setSelectedSkuId(undefined);
      },
      onError: () => setSelectedSkuId(undefined),
    });

  const handleSkuImageModalOpen = (skuId: number) => () => {
    setSelectedSkuId(skuId);
  };

  const handleGroupImageModalOpen = () => {
    if (groupSkuDetail?.id) {
      handleSkuForImageModalSelect({
        skuData: { ...groupSkuDetail, type: "group" }, // groupSkuDetail.id,
        imageType: "item",
      });
    }
  };

  const groupDetailList = useMemo(
    () =>
      (groupSkuDetail?.groupItems ?? []).map((groupItem) => ({
        skuId: getFormattedSingleSkuId(groupItem.skuId),
        itemName: (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>{groupItem.sku?.itemName}</span>
            <Button
              sx={{ ml: 1 }}
              variant="outlined"
              size="small"
              onClick={handleSkuImageModalOpen(groupItem.skuId)}
            >
              이미지
            </Button>
          </Stack>
        ),
        barCode: groupItem.sku?.barCode,
        quantity: `${groupItem.qty}PCS`,
      })),
    [groupSkuDetail]
  );

  const totalQuantity = (groupSkuDetail?.groupItems ?? []).reduce(
    (acc, cur) => acc + cur.qty,
    0
  );

  const GroupDetailModal = useMemo(() => {
    const basicDetail: DetailProps = {
      title: "기본정보",
      detailList: [
        {
          label: "상품명",
          value: groupSkuDetail?.groupName,
        },
        {
          label: "상품이미지",
          value: (
            <Button
              variant="outlined"
              size="small"
              sx={{ ml: 1 }}
              onClick={handleGroupImageModalOpen}
              disabled={!groupSkuDetail || !groupSkuDetail.attachment?.length}
            >
              이미지
            </Button>
          ),
        },
        {
          label: "회사명",
          value: groupSkuDetail?.team?.company,
        },
        {
          label: "판매자상품코드",
          value: groupSkuDetail?.productCode,
        },
        {
          label: "팀명",
          value: groupSkuDetail?.team?.name,
        },
        {
          label: "옵션관리코드",
          value: groupSkuDetail?.managementCode,
        },
      ],
    };

    const subsidiaryDetail: DetailProps = {
      title: "부자재",
      detailList: [
        {
          label: "포장방법",
          value: groupSkuDetail?.materialPackageType
            ? getMaterialPackageType(groupSkuDetail?.materialPackageType)
            : "",
        },
        ...(groupSkuDetail
          ? groupSkuDetail.packages?.map((v) => {
              return {
                label: getPackageCategoryName(v.packageCategory),

                value: `${getPackageTypeName(
                  v.packageType
                )}(${getFormattedMaterialId(v)})`,
              };
            }) ?? []
          : []),
      ],
    };

    const returningDetail: DetailProps = {
      title: "반품조건",
      detailList: [
        {
          label: "반품조건",
          value: `${
            getReturningConditionName(groupSkuDetail?.returningCondition).label
          } (${groupSkuDetail?.returningCondition})`,
        },
      ],
    };

    return (
      <>
        <Dialog
          open={active}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleGroupDetailModalClose}
        >
          <DialogContent dividers>
            <Stack spacing={4}>
              <Typography variant="h6">
                {getFormattedGroupSkuId(groupSkuDetail?.id)} 상세 정보
              </Typography>
              <Divider />
              <Stack gap={3}>
                <Detail {...basicDetail} />
                <Detail {...subsidiaryDetail} />
                <Detail {...returningDetail} />

                <Table
                  title="구성상품"
                  headCells={headCells}
                  rows={groupDetailList}
                  extraRows={
                    <TableRow
                      sx={{
                        td: { padding: 2, fontSize: 16, fontWeight: 700 },
                      }}
                    >
                      <TableCell colSpan={3}>총계</TableCell>
                      <TableCell>{totalQuantity}PCS</TableCell>
                    </TableRow>
                  }
                />
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
        <SkuImageModal />
        {ResponseHandlerOfGetSingleSkuDetail}
      </>
    );
  }, [
    ResponseHandlerOfGetSingleSkuDetail,
    SkuImageModal,
    active,
    groupDetailList,
    groupSkuDetail,
    totalQuantity,
  ]);

  return { GroupDetailModal, showGroupDetailModal };
}

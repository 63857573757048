import { OVERSEAS_MALL_OPTION_LIST } from "constants/shipping";

import useTableHeadFilter from "hooks/useTableHeadFilterForBofulAdminOnly";

export default function useOverseasMallFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: OverseasMallFilterPanel,
    filterList: overseasMallFilterList,
    resetFilterState: resetOverseasMallFilter,
  } = useTableHeadFilter({
    filterOptionList: OVERSEAS_MALL_OPTION_LIST,
    resetCurrentPage,
  });

  return {
    OverseasMallFilterPanel,
    overseasMallPayloadListByFilter: overseasMallFilterList,
    resetOverseasMallFilter,
  };
}

import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const detailInfoContainer = styled.div<{ isVisibleSkuList: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: ${({ isVisibleSkuList }) => (isVisibleSkuList ? "1184px" : "572px")};

  > .custom-list-item-memo {
    align-self: stretch;
  }
`;

const guideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 8px;
`;

const imageAndSkuListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 40px;
`;

const imageContainer = styled.div`
  width: 572px;
`;

const skuListContainer = styled.div`
  width: 572px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .item-name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;

const skuSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  > .label {
    flex: 0 0 80px;
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.grayScale_700};
    word-break: keep-all;
  }
`;

const selectProcessStatusContainer = styled.div`
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default {
  detailInfoContainer,
  guideContainer,
  imageAndSkuListContainer,
  imageContainer,
  skuListContainer,
  skuSearchContainer,
  selectProcessStatusContainer,
};

import { ReactNode } from "react";
import { Typography } from "@mui/material";
import Grid, { GridSize } from "@mui/material/Grid";

import DetailItem from "./DetailItem";

export type DetailProps = {
  title?: ReactNode;
  description?: ReactNode;
  detailList: {
    label: ReactNode;
    value: ReactNode;
    gridSize?: boolean | GridSize;
    editComponent?: ReactNode;
  }[];
  isEditable?: boolean;
};

export default function Detail({
  title,
  description,
  detailList,
  isEditable = false,
}: DetailProps) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        {title && (
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
      </Grid>

      <Grid item container spacing={0.5}>
        {detailList.map(
          ({ label, value, gridSize = 6, editComponent }, index) => (
            <Grid item xs={gridSize} key={index}>
              <DetailItem
                label={label}
                value={value}
                editComponent={editComponent}
                isEditable={isEditable}
              />
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
}

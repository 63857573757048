import { useMemo } from "react";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import useNetworkPrint from "./useNetworkPrint";

/**
 * 복수 출고건에 대한 송장 출력 단계 중 '출력 데이터 요청, 출력 요청'을 처리
 */
export default function usePrintInvoiceForList(params?: {
  hidesLoading?: boolean;
}) {
  const {
    mutate: getInvoicePrintingData,
    ResponseHandler: ResponseHandlerOfGetInvoicePrintingData,
    isLoading: isLoadingOfGetInvoicePrintingData,
    reset: resetGetInvoicePrintingData,
  } = SHIPPING_QUERY.useGetInvoicePrintingData({
    onSuccess: (res) => {
      print(res);
    },

    hidesLoading: params?.hidesLoading,
  });

  const {
    print,
    LoadingToNetworkPrint,
    isRequestPrintingComplete,
    isLoadingOfNetworkPrint,
    resetPrint,
  } = useNetworkPrint({ hidesLoading: params?.hidesLoading });

  const ResponseHandlerOfPrintInvoice = useMemo(() => {
    return (
      <>
        {ResponseHandlerOfGetInvoicePrintingData}
        {LoadingToNetworkPrint}
      </>
    );
  }, [LoadingToNetworkPrint, ResponseHandlerOfGetInvoicePrintingData]);

  return {
    printInvoice: getInvoicePrintingData,
    ResponseHandlerOfPrintInvoice,
    isRequestPrintingComplete,
    isLoadingOfPrintInvoice:
      isLoadingOfGetInvoicePrintingData || isLoadingOfNetworkPrint,
    resetPrintInvoice: () => {
      resetGetInvoicePrintingData();
      resetPrint();
    },
  };
}

import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useAtom } from "jotai";
import { FULFILLMENT_INSPECTION_ATOMS } from "jotaiStates/inspection";
import RECEIVING_QUERY from "queries/RECEIVING_QUERY";

import { ProcessStatus } from "types/returning";
import { checkForProblemItem, checkForUnverifiedItem } from "utils/fulfillment";
import { getFormattedReceivingId } from "utils/receiving";

import Button from "components/sds-v1/button/Button";

import InspectionListExcludingUnverifiedItem from "./InspectionListExcludingUnverifiedItem";
import InspectQuantityConfirm from "./InspectQuantityConfirm";
import NoProblemList from "./NoProblemList";
import UnverifiedList from "./UnverifiedList";
import useInspectionList from "./useInspectionList";
import Styled from "./index.styles";

export default function InspectionInfo({ id }: { id: number }) {
  const queryClient = useQueryClient();

  const [agreementInfoList, setAgreementInfoList] = useAtom(
    FULFILLMENT_INSPECTION_ATOMS.AGREEMENT_INFO_LIST_FOR_INSPECTION
  );

  const [opensConfirmModalType, setOpensConfirmModalType] = useState<
    "tempSave" | "finalSave"
  >();

  const {
    data: receivingInspectDetail,
    ResponseHandler: ResponseHandlerOfReceivingInspectDetail,
  } = RECEIVING_QUERY.useGetReceivingInspectDetail({
    id,
    onSuccess: (res) => {
      // 이미 선택한 항목이 있으면, 값을 덮어쓰지 않도록 처리.
      // if (agreementInfoList.length > 0) return;

      const isWarehousingCompleteStatus = res.receiving.status === "done";

      setAgreementInfoList(
        res.receiving.items.map((item) => {
          const isProblemItem = checkForProblemItem(item);

          const isUnverifiedItem = checkForUnverifiedItem(item);

          const problemQuantity = item.inspectProblems.reduce(
            (acc, cur) => acc + cur.quantity,
            0
          );

          return {
            type: isProblemItem
              ? "problem"
              : isUnverifiedItem
              ? "unverified"
              : "normal",
            itemId: item.id,
            skuId: item.skuId,
            processStatus: item.processStatus,
            actualQty: item.actualQty,
            placeQty: item.placeQty,
            ...(isProblemItem && {
              problemQuantity,
              normalQuantity:
                ((isWarehousingCompleteStatus
                  ? item.placeQty
                  : item.actualQty) ?? 0) - problemQuantity,
            }),
            managementDate: item.managementDate,
          };
        })
      );
    },
  });

  // const {
  //   mutate: updateAgreementForInspection,
  //   ResponseHandler: ResponseHandlerOfUpdateAgreementForInspection,
  // } = RECEIVING_QUERY.useUpdateAgreementForInspection({
  //   receivingId: id,
  //   onSuccess: (_, req) => {
  //     setOpensConfirmModalType(req.isDoneAgree ? "finalSave" : "tempSave");

  //     queryClient.invalidateQueries(
  //       RECEIVING_QUERY_KEY_GEN.getReceivingList({})
  //     );
  //   },
  // });

  const detailData = receivingInspectDetail?.receiving;

  const primaryId = getFormattedReceivingId(detailData);

  // 검수완료 (이슈) 상태에서만 선택 및 입고처리 가능.
  const canSelectedProcessStatus =
    detailData?.status === "completeInspection" &&
    detailData?.inspectionStatus === "hold";

  // 입고 중 이후 상태인지.
  const isWarehousingStatus =
    detailData?.status === "putAway" ||
    detailData?.status === "hold" ||
    detailData?.status === "done";

  const isManagerConfirmStatus = detailData?.status === "hold";

  const isWarehousingCompleteStatus = detailData?.status === "done";

  // 수량이 확정된 상태인지 - 전담매니저 확인 중, 입고완료에 해당.
  const isDecidedQuantityStatus =
    isManagerConfirmStatus || isWarehousingCompleteStatus;

  const { inspectionListExcludingUnverifiedItem, unverifiedList, hasProblem } =
    useInspectionList({ items: detailData?.items, isDecidedQuantityStatus });

  const updatedList = useMemo(() => {
    const prevAgreementInfoList = detailData?.items
      .filter(
        (item) => checkForProblemItem(item) || checkForUnverifiedItem(item)
      )
      .map((item) => ({
        itemId: item.id,
        skuId: item.skuId,
        processStatus: item.processStatus,
      }));

    if (!prevAgreementInfoList || !agreementInfoList) return [];

    return agreementInfoList
      .filter((newItem) => {
        const prevItem = prevAgreementInfoList.find(
          (prevInfo) => newItem.itemId === prevInfo.itemId
        );

        if (
          !newItem.processStatus ||
          !newItem.skuId ||
          newItem.processStatus === "normal"
        )
          return false;

        return (
          newItem.processStatus !== prevItem?.processStatus ||
          newItem.skuId !== prevItem?.skuId
        );
      })
      .map((item) => ({
        itemId: item.itemId,
        skuId: item.skuId,
        processStatus: item.processStatus as ProcessStatus, // 위 filter 에서 processStatus 의 값이 있는 경우만 필터링함.
      }));
  }, [agreementInfoList, detailData?.items]);

  const listForUpdate = useMemo(() => {
    if (!agreementInfoList) return [];

    return agreementInfoList
      .filter((newItem) => {
        if (
          !newItem.processStatus ||
          !newItem.skuId ||
          newItem.processStatus === "normal"
        )
          return false;

        return true;
      })
      .map((item) => ({
        itemId: item.itemId,
        skuId: item.skuId,
        processStatus: item.processStatus as ProcessStatus, // 위 filter 에서 processStatus 의 값이 있는 경우만 필터링함.
      }));
  }, [agreementInfoList]);

  const validForUpdate = updatedList.length > 0;

  // 리스트 중, 선택이 필요한 항목에 모두 선택이 되었는지 여부
  const canAgreeToWarehousing = useMemo(() => {
    return agreementInfoList
      .filter(({ type }) => type !== "normal")
      .every(
        (item) =>
          (item.processStatus === "stock" || item.processStatus === "faulty") &&
          item.skuId
      );
  }, [agreementInfoList]);

  const hasProblemAndUnverifiedItem = hasProblem || !!unverifiedList.length;

  return (
    <Styled.inspectionContainer>
      <Styled.inspectResultGuide>
        <Styled.title>검수 결과</Styled.title>

        <Styled.guide>
          입고요청된 상품의 검수 결과 입니다.{" "}
          {canSelectedProcessStatus &&
            "검수 이슈가 발생한 상품은 정상입고, 불량입고 처리를 해주세요."}
        </Styled.guide>
      </Styled.inspectResultGuide>

      {detailData && (
        <>
          {!!inspectionListExcludingUnverifiedItem.length &&
            (hasProblem ? (
              <InspectionListExcludingUnverifiedItem
                primaryId={primaryId}
                list={inspectionListExcludingUnverifiedItem}
                isWarehousingStatus={isWarehousingStatus}
                isDecidedQuantityStatus={isDecidedQuantityStatus}
              />
            ) : (
              <NoProblemList
                list={inspectionListExcludingUnverifiedItem}
                isDecidedQuantityStatus={isDecidedQuantityStatus}
              />
            ))}

          {!!unverifiedList.length && (
            <UnverifiedList
              primaryId={primaryId}
              teamId={detailData.teamId}
              list={unverifiedList}
              isWarehousingStatus={isWarehousingStatus}
              isDecidedQuantityStatus={isDecidedQuantityStatus}
            />
          )}
        </>
      )}

      {hasProblemAndUnverifiedItem && (
        <InspectQuantityConfirm
          canAgreeToWarehousing={canAgreeToWarehousing}
          canSelectedProcessStatus={canSelectedProcessStatus}
          isDecidedQuantityStatus={isDecidedQuantityStatus}
        />
      )}

      {canSelectedProcessStatus && hasProblemAndUnverifiedItem && (
        <div className="tab-submit-button-container">
          <Button
            size="normal"
            theme="secondary"
            label="임시 저장"
            handleClick={() => {
              // updateAgreementForInspection({
              //   inspectItems: listForUpdate,
              //   isDoneAgree: false,
              // });
            }}
            // disabled={!validForUpdate}
            disabled={true}
          />

          <Button
            size="normal"
            theme="primary"
            label="입고동의"
            handleClick={() => {
              // updateAgreementForInspection({
              //   inspectItems: listForUpdate,
              //   isDoneAgree: true,
              // });
            }}
            // disabled={!canAgreeToWarehousing}
            disabled={true}
          />
        </div>
      )}

      {/* <Modal
        uiType="titleOnly"
        active={!!opensConfirmModalType}
        title={
          opensConfirmModalType === "finalSave" ? (
            "입고 동의 완료되었습니다."
          ) : (
            <>
              임시저장 되었습니다.
              <br />
              입고 동의 시 최종 입고가 진행됩니다.
            </>
          )
        }
        actionPositive={{
          label: "확인",
          handleClick: onDetailModalClose,
        }}
      /> */}

      {ResponseHandlerOfReceivingInspectDetail}
    </Styled.inspectionContainer>
  );
}

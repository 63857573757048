import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { toThousandUnitFormat } from "utils/number";

import Calendar, { CalendarInputValue } from "components/Calendar";

export default function DueDateModal({
  dueDate,
  onDueDateChange,
  selectedShippingCount,
  isDueDateModalOpen,
  onDueDateModalClose,
  onDueDateModalConfirm,
  needsTimePicker,
}: {
  dueDate: CalendarInputValue;
  onDueDateChange: (date: CalendarInputValue) => void;
  selectedShippingCount: number;
  isDueDateModalOpen: boolean;
  onDueDateModalClose: () => void;
  onDueDateModalConfirm: () => void;
  needsTimePicker?: boolean;
}) {
  return (
    <Dialog open={isDueDateModalOpen} onClose={onDueDateModalClose}>
      <DialogTitle>
        출고요청 {toThousandUnitFormat(selectedShippingCount)}건의{" "}
        {needsTimePicker ? "발송희망일시를" : "발송희망일을"} 변경합니다.
      </DialogTitle>

      <DialogContent>
        <Calendar
          sx={{ mt: 1 }}
          fullWidth
          type={needsTimePicker ? "withTimePicker" : "datePicker"}
          value={dueDate}
          onChange={onDueDateChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={onDueDateModalConfirm}
          disabled={!dueDate}
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

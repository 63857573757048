import { useCallback, useMemo, useRef, useState } from "react";
import { GET_ADMIN_LOCATION_DETAIL_RES } from "api-interfaces/location";
import dayjs from "dayjs";
import LOCATION_QUERY from "queries/LOCATION_QUERY";

import useGetCSVDownloadInfoFromQuery from "hooks/useGetCSVDownloadInfoFromQuery";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "hooks/useSearchWithDate";
import { LocationHistoryItem, LocationHistorySku } from "types/location";
import { toFormattedDate, transformUTCDateToLocalDateTime } from "utils/date";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { getFormattedActionId, getLocationActionType } from "utils/location";
import { toThousandUnitFormat } from "utils/number";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import { LocationSummaryData } from ".";

type CellId =
  | Exclude<keyof LocationHistoryItem, "sku">
  | Exclude<keyof LocationHistorySku, "id">
  | "skuId";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "actionType",
    disablePadding: false,
    label: "구분",
    width: 100,
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "날짜",
    width: 120,
  },
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 120,
  },
  {
    id: "companyName",
    disablePadding: false,
    label: "회사명",
    width: 140,
  },
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 200,
  },
  {
    id: "barCode",
    disablePadding: false,
    label: "상품바코드",
    width: 140,
  },
  {
    id: "productCode",
    disablePadding: false,
    label: "상품관리코드",
    width: 140,
  },
  {
    id: "managementCode",
    disablePadding: false,
    label: "옵션관리코드",
    width: 140,
  },
  {
    id: "quantity",
    disablePadding: false,
    label: "수량",
    width: 120,
  },
  {
    id: "actionId",
    disablePadding: false,
    label: "의뢰번호",
    width: 120,
  },
];

const dateSearchTypeOptions: SearchWithDateTypeOption<"createdAt">[] = [
  {
    label: "날짜",
    value: "createdAt",
  },
];

export default function LocationHistory({
  locationData,
}: {
  locationData: LocationSummaryData | undefined;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  // 날짜 필터 지정을 하지 않은 경우 최근 30일 데이터를 리스팅
  const defaultDate = useRef<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: dayjs(
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "start",
      })
    )
      .subtract(30, "d")
      .toDate(),
    endDate: transformUTCDateToLocalDateTime({
      utcDateTime: new Date(),
      timeZone: "Asia/Seoul",
      when: "end",
    }),
  });

  const { DateSearchPanel, startDate, endDate } = useSearchWithDate({
    dateSearchTypeOptions,
  });

  const {
    data: adminDetailData,
    ResponseHandler: ResponseHandlerOfGettingAdminDetail,
  } = LOCATION_QUERY.useGetAdminDetail({
    enabled: !!locationData,
    locationId: locationData?.locationId,
    perPage,
    page: currentPage,
    startDate: startDate ? new Date(startDate) : defaultDate.current.startDate,
    endDate: endDate ? new Date(endDate) : defaultDate.current.endDate,
  });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: LOCATION_QUERY.useGetAdminDetailForCSVDownload,
    queryArgs: [
      {
        locationId: locationData?.locationId,
        startDate: startDate
          ? new Date(startDate)
          : defaultDate.current.startDate,
        endDate: endDate ? new Date(endDate) : defaultDate.current.endDate,
      },
    ],
  });

  const mapDataForTable = useCallback(
    (
      data: GET_ADMIN_LOCATION_DETAIL_RES | undefined
    ): TableBodyRow<CellId>[] => {
      if (!data?.list) {
        return [];
      }

      return data.list.map((item) => {
        const {
          actionType,
          createdAt,
          companyName,
          sku: { id: skuId, itemName, barCode, productCode, managementCode },
          quantity,
          actionId,
        } = item;

        return {
          actionType: getLocationActionType(actionType),
          createdAt: toFormattedDate(createdAt, "YYYY.M.DD"),
          skuId: getFormattedSingleSkuId(skuId),
          companyName,
          itemName,
          barCode,
          productCode,
          managementCode,
          quantity: toThousandUnitFormat(quantity),
          actionId: getFormattedActionId({ actionId, actionType }),
        };
      });
    },
    []
  );

  const rows = useMemo(() => {
    return mapDataForTable(adminDetailData);
  }, [adminDetailData, mapDataForTable]);

  const rowsForCSVDownload = useMemo(() => {
    return mapDataForTable(dataForCSVDownload);
  }, [dataForCSVDownload, mapDataForTable]);

  return (
    <>
      <Table<CellId>
        title="로케이션 히스토리"
        headCells={headCells}
        sx={{ maxHeight: "40vh" }}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: adminDetailData?.total ?? 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
        csvDownloadInfo={{
          filename: `로케이션 히스토리(${locationData?.barCode ?? ""}_${
            locationData?.warehouse ?? ""
          }, ${toFormattedDate(new Date(), "YYYY-MM-DD-HHmmss")})`,
          scope: "all",
          isCSVDownloadRequested,
          setIsCSVDownloadRequested,
          rowsForCSVDownload,
          ResponseHandlerOfFetchDataForCSVDownload,
          statusOfQueryOfFetchDataForCSVDownload,
          removeQueryOfFetchDataForCSVDownload,
        }}
        toolbarItems={{
          left: [<DateSearchPanel key="dateSearch" />],
        }}
      />

      {ResponseHandlerOfGettingAdminDetail}
    </>
  );
}

import { FileCopyOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import useToast from "hooks/useToast";

export default function CopyOTPButton({ OTPValue }: { OTPValue: string }) {
  const { openToast, Toast } = useToast({
    message: "OTP가 복사되었습니다.",
    color: "info",
  });

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(OTPValue);

      openToast();
    } catch (error) {
      openToast({ message: "OTP 복사에 실패했습니다.", color: "error" });
    }
  };

  return (
    <>
      <IconButton onClick={handleCopy}>
        <FileCopyOutlined />
      </IconButton>

      <Toast />
    </>
  );
}

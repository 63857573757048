import { useState } from "react";
import { useQueryClient } from "react-query";
import { RestartAlt } from "@mui/icons-material";
import { Button } from "@mui/material";

import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import ConfirmModal from "components/ConfirmModal";

/**
 * 피킹 중(피킹시작여부 N)' 에서만 노출 (의뢰대기 상태로 되돌림)
 */
export default function ResetShippingStatus({
  shippingId,
}: {
  shippingId: number;
}) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const {
    mutate: resetShippingStatus,
    ResponseHandler: ResponseHandlerOfResetShippingStatus,
  } = SHIPPING_QUERY.useResetShippingStatus();

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleShippingStatusReset = () => {
    resetShippingStatus(
      { pathParams: { shippingId } },
      {
        onSuccess: () => {
          handleConfirmModalClose();

          queryClient.invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping());
        },
      }
    );
  };

  return (
    <>
      <Button
        startIcon={<RestartAlt />}
        variant="outlined"
        onClick={handleConfirmModalOpen}
      >
        상태 되돌리기
      </Button>

      <ConfirmModal
        title="의뢰대기 상태로 되돌리시겠습니까?"
        isConfirmModalOpen={isConfirmModalOpen}
        onConfirmModalClose={handleConfirmModalClose}
        actionPositive={{
          onClick: handleShippingStatusReset,
        }}
        actionNegative={{
          onClick: handleConfirmModalClose,
        }}
      />

      {ResponseHandlerOfResetShippingStatus}
    </>
  );
}

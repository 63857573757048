import { ProcessStatus as ProcessStatusType } from "types/returning";
import { getProblemStatus } from "./utils";

import TextButton from "components/sds-v1/button/TextButton";

import Styled from "./index.styles";

export default function ProcessStatus({
  processStatusList,
  handleDetailModalOpen,
  passedInspection,
  isOnlyDifferentQuantity,
  isWarehousingStatus,
}: {
  processStatusList: ProcessStatusType[];
  handleDetailModalOpen: () => void;
  passedInspection?: boolean;
  isOnlyDifferentQuantity?: boolean;
  isWarehousingStatus: boolean;
}) {
  if (passedInspection && !isOnlyDifferentQuantity)
    return (
      <Styled.processStatusText>검수 결과 이상 없음</Styled.processStatusText>
    );

  if (isOnlyDifferentQuantity)
    return <Styled.processStatusText>수량 불일치</Styled.processStatusText>;

  // 관리일자 여러 개로 나뉘어진 경우 모든 processStatus를 임시저장 없이 한꺼번에 지정하기 때문에 첫 번째 요소만 확인하면 됨
  const isSelected =
    processStatusList[0] === "stock" || processStatusList[0] === "faulty";

  const processStatus = (() => {
    const problemStatusSet = new Set<ProcessStatusType>(processStatusList);

    const isOnlyOneProblem = problemStatusSet.size === 1;
    if (isOnlyOneProblem) {
      return processStatusList[0];
    }

    return "all";
  })();

  return (
    <Styled.processStatusContainer>
      {getProblemStatus(processStatus)}

      {isWarehousingStatus ? (
        <TextButton
          label="확인"
          size="14px"
          theme="gray"
          icon="arrowRight"
          handleClick={handleDetailModalOpen}
        />
      ) : (
        <TextButton
          label={isSelected ? "수정" : "선택하기"}
          size="14px"
          theme={isSelected ? "gray" : "withIcon"}
          icon="arrowRight"
          handleClick={handleDetailModalOpen}
        />
      )}
    </Styled.processStatusContainer>
  );
}

import { TextField, TextFieldProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { InventoryDetailModifyForm } from "./useFormState";

export default function ItemInputForManagementDate({
  locationId,
  formState,
  onManagementDateChange,
}: {
  locationId: number | string;
  formState: InventoryDetailModifyForm | null;
  onManagementDateChange: ({
    locationId,
    managementDate,
  }: {
    locationId: number | string;
    managementDate: Date;
  }) => void;
}) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={formState?.inventory.get(locationId)?.managementDate}
          onChange={(value: dayjs.Dayjs | null) => {
            value &&
              onManagementDateChange({
                locationId,
                managementDate: value.tz("Asia/Seoul").startOf("day").toDate(),
              });
          }}
          renderInput={(props: TextFieldProps) => <TextField {...props} />}
          inputFormat="YYYY-MM-DD"
          mask="____-__-__"
        />
      </LocalizationProvider>
    </>
  );
}

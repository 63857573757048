import { GET_ADMIN_SHIPPING_LIST_REQ } from "api-interfaces/shipping";
import Button from "components/sds-v1/button/Button";
import Modal from "components/sds-v1/Modal";
import { noop } from "utils/common";

import ExcelInvoiceList from "./ExcelInvoiceList";
import useUploadShippingInvoiceExcel from "./useUploadShippingInvoiceExcel";
import Styled from "./index.styles";

export default function UploadShippingInvoiceExcelModal({
  isOpen,
  onModalClose,
  ...params
}: {
  isOpen: boolean;
  onModalClose: () => void;
} & GET_ADMIN_SHIPPING_LIST_REQ) {
  const {
    handleExcelUpload,

    inputFileRef,
    handleFileInputChange,

    shippingExcelInvoiceList,
    successExcelList,
    failureExcelList,

    handleShippingInvoiceListSave,
    isValidated,
    handleModalClose,

    LoadingToUpload,
    FailureModal,

    ResponseHandlerValidatingShippingExcelInvoiceList,
    ResponseHandlerOfSavingShippingExcelInvoiceList,
  } = useUploadShippingInvoiceExcel({ onModalClose, ...params });

  return (
    <>
      <Modal
        active={isOpen}
        uiType="formInput"
        title="송장번호 대량업로드"
        desc={
          <>
            엑셀 업로드를 위해 엑셀 파일의 내용을 확인한 후 양식에 맞게 입력한
            후 업로드해 주세요.
            <br />
            에러가 있을 경우(붉은색 표시) 내용은 "?"를 통해 확인하고, 수정 후
            다시 업로드해 주세요.
            <br />
            에러가 없을 경우 등록 완료 버튼이 활성화됩니다.
            <br />
            <Styled.excelUploadButtonContainer>
              <a
                href="https://sellernote.s3.ap-northeast-2.amazonaws.com/guide/%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%80%E1%85%A9_%E1%84%83%E1%85%A2%E1%84%85%E1%85%A3%E1%86%BC%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8(%E1%84%89%E1%85%A9%E1%86%BC%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%87%E1%85%A5%E1%86%AB%E1%84%92%E1%85%A9).xlsx"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  label="양식 다운로드"
                  theme="tertiary"
                  size="normal"
                  handleClick={noop}
                />
              </a>

              <Button
                label="엑셀 업로드"
                theme="secondary"
                size="normal"
                handleClick={handleExcelUpload}
              />
            </Styled.excelUploadButtonContainer>
            <input
              style={{ display: "none" }}
              ref={inputFileRef}
              type="file"
              name="file-upload"
              id="fileUpload"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12"
              onChange={handleFileInputChange}
            />
          </>
        }
        body={
          <ExcelInvoiceList
            excelList={shippingExcelInvoiceList}
            successExcelList={successExcelList}
            failureExcelList={failureExcelList}
          />
        }
        hasBorder={true}
        submitButton={
          <Styled.excelUploadButtonContainer>
            <Button
              label="취소"
              theme="secondary"
              size="normal"
              handleClick={handleModalClose}
            />

            <Button
              label="등록 완료"
              theme="primary"
              size="normal"
              handleClick={handleShippingInvoiceListSave}
              disabled={!isValidated}
            />
          </Styled.excelUploadButtonContainer>
        }
        onClose={handleModalClose}
        disableMaxHeight={true}
      />

      {LoadingToUpload}
      {FailureModal}

      {ResponseHandlerValidatingShippingExcelInvoiceList}
      {ResponseHandlerOfSavingShippingExcelInvoiceList}
    </>
  );
}

import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, Paper, Stack, Typography } from "@mui/material";

import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { toFormattedDate } from "utils/date";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

interface ShippingSkuDetailForm {
  skuId: string;
}

function ShippingSkuDetail() {
  const [skuId, setSkuId] = useState<number>();
  const downloadRef = useRef<HTMLDivElement>(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ShippingSkuDetailForm>({
    mode: "onChange",
    defaultValues: {
      skuId: "",
    },
  });

  const queryClient = useQueryClient();

  const {
    data: shippingListBySkuId,
    ResponseHandler: ResponseHandlerOfGetShippingListBySkuId,
  } = SHIPPING_QUERY.useGetShippingListBySkuId({
    skuId: skuId ?? 0,
    enabled: !!skuId,
    onError: () => setSkuId(undefined),
  });

  const handleSkuDetailDownload = ({ skuId }: ShippingSkuDetailForm) => {
    setSkuId(Number(skuId));
  };

  const handleTextFieldClear = () => {
    reset();
    setSkuId(undefined);
    queryClient.removeQueries(
      SHIPPING_QUERY_KEY_GEN.getShippingListBySkuId({ skuId })
    );
  };

  const csvData = (() => {
    if (!shippingListBySkuId) {
      return [];
    }

    const headRow = [
      "출고요청번호",
      "출고상태",
      "작업상태",
      "SKU ID",
      "LOCATION ID",
      "로케이션 바코드",
      "출고 수량",
      "취소 작업 중 수량",
      "취소 완료 수량",
      "반품 완료 수량",
      "받는 분 이름",
      "받는 분 주소",
      "송장번호",
      "출고생성일자",
      "출고일자",
    ];

    const dataRows = shippingListBySkuId.map(
      ({
        id,
        shippingStatus,
        wmsStatus,
        skuId,
        locationId,
        barCode,
        quantity,
        cancelWorkingQuantity,
        cancelDoneQuantity,
        returnedQuantity,
        customerName,
        customerAddress,
        invoiceNo,
        createdAt,
        dueDate,
      }) => {
        return [
          id,
          shippingStatus,
          wmsStatus,
          skuId,
          locationId,
          barCode,
          quantity,
          cancelWorkingQuantity,
          cancelDoneQuantity,
          returnedQuantity,
          customerName,
          customerAddress,
          invoiceNo,
          createdAt,
          dueDate,
        ];
      }
    );

    return [headRow, ...dataRows];
  })();

  useEffect(() => {
    if (csvData.length === 0) {
      return;
    }

    downloadRef.current?.click();
  }, [csvData]);

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          출고 내역 받기
        </Typography>

        <form onSubmit={handleSubmit(handleSkuDetailDownload)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="skuId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="SKU ID"
                  handleTextFieldClear={handleTextFieldClear}
                  error={!!errors.skuId}
                  helperText={errors.skuId && "숫자만 입력해주세요."}
                />
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      <CSVLink
        data={csvData}
        filename={`출고 내역-${
          shippingListBySkuId?.[0].skuId
        }(${toFormattedDate(new Date(), "YYYY-MM-DD-HHmmss")}).csv`}
      >
        <div ref={downloadRef} />
      </CSVLink>

      {ResponseHandlerOfGetShippingListBySkuId}
    </Layout>
  );
}

export default withRequireAuth(ShippingSkuDetail);

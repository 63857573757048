import { useCallback, useEffect, useMemo, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import DatePicker from "./DatePicker";
import Styled from "./index.styles";

export type SearchWithDateTypeOption<T extends string | number> = {
  label: string;
  value: T;
};

/**
 * 일단은 '날짜 검색' 전용
 */
export default function useSearchWithDate<T extends string | number>({
  dateSearchTypeOptions,
  startLabel,
  endLabel,
}: {
  dateSearchTypeOptions: SearchWithDateTypeOption<T>[];
  startLabel?: string;
  endLabel?: string;
}) {
  const [dateSearchType, setDateSearchType] = useState<T>(
    dateSearchTypeOptions[0].value
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // 날짜 검색 타입이 변경되면 날짜도 초기화
  useEffect(() => {
    setStartDate("");
  }, [dateSearchType]);

  // 시작날짜가 정해지면 종료날짜도 자동으로 셋팅
  useEffect(() => {
    if (!startDate) {
      setEndDate("");
    }

    setEndDate(startDate);
  }, [startDate]);

  const hasValue = useMemo(() => {
    return !!startDate || !!endDate;
  }, [startDate, endDate]);

  const SearchDate = useCallback(() => {
    return (
      <Styled.container>
        <DatePicker
          label={startLabel || "시작일"}
          when="start"
          date={startDate}
          setDate={setStartDate}
        />

        <DatePicker
          label={endLabel || "종료일"}
          when="end"
          date={endDate}
          setDate={(v) => {
            if (startDate > v) {
              setEndDate(startDate);
            } else {
              setEndDate(v);
            }
          }}
          disabled={!startDate}
          {...(hasValue
            ? {
                reset: () => {
                  // startDate만 리셋하면 endDate도 리셋되므로 이렇게만
                  setStartDate("");
                },
              }
            : {})}
        />
      </Styled.container>
    );
  }, [startDate, endDate, hasValue, endLabel, startLabel]);

  const SelectForSearchType = useCallback(() => {
    return (
      <FormControl className="date-search-type" variant="outlined">
        <InputLabel id="date-search-type-select-label">날짜 타입</InputLabel>
        <Select
          labelId="date-search-type-select-label"
          id="date-search-type-select"
          value={dateSearchType}
          label="날짜 타입"
          defaultValue={dateSearchType}
          onChange={(e) => {
            setDateSearchType(e.target.value as T);
          }}
          size="small"
        >
          {dateSearchTypeOptions.map((st, i) => (
            <MenuItem value={st.value} key={i}>
              {st.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [dateSearchType, dateSearchTypeOptions]);

  const DateSearchPanel = useCallback(
    ({ style }: { style?: React.CSSProperties }) => {
      return (
        <Styled.dateSearchPanelWrapper style={style}>
          <Styled.dateSearchPanel>
            <SelectForSearchType />

            <SearchDate />
          </Styled.dateSearchPanel>
        </Styled.dateSearchPanelWrapper>
      );
    },
    [SelectForSearchType, SearchDate]
  );

  return { DateSearchPanel, dateSearchType, startDate, endDate, setStartDate };
}

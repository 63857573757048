import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";

import AdminMemoModal from "hooks/useAdminMemoModal/AdminMemoModal";
import useAdminMemoModal from "hooks/useAdminMemoModal/useAdminMemoModal";

export default function useShippingAdminMemo() {
  const {
    showAdminMemoModal,

    handleClose,
    handleMemoChange,

    resetRequestId,
    resetMemo,

    requestId,
    memo,
  } = useAdminMemoModal();

  const queryClient = useQueryClient();

  const {
    data: shippingDetail,
    ResponseHandler: ResponseHandlerOfGetAdminDetail,
  } = SHIPPING_QUERY.useGetAdminDetail({
    enabled: !!requestId,
    shippingId: requestId,
    onError: () => {
      resetRequestId();
    },
  });

  const {
    mutate: addAdminMemo,
    ResponseHandler: ResponseHandlerOfAddAdminMemo,
  } = SHIPPING_QUERY.useAddShippingAdminMemo();

  const ResponseHandlerOfAdminMemo = useMemo(
    () => (
      <>
        {ResponseHandlerOfGetAdminDetail}
        {ResponseHandlerOfAddAdminMemo}
      </>
    ),
    [ResponseHandlerOfGetAdminDetail, ResponseHandlerOfAddAdminMemo]
  );

  const handleMemoAdd = useCallback(() => {
    addAdminMemo(
      { memo, pathParams: { shippingId: requestId } },
      {
        onSuccess: () => {
          resetMemo();

          queryClient.invalidateQueries(
            SHIPPING_QUERY_KEY_GEN.getAdminDetail({ shippingId: requestId })
          );
        },
      }
    );
  }, [addAdminMemo, memo, queryClient, requestId, resetMemo]);

  const ShippingAdminModal = useMemo(
    () => (
      <AdminMemoModal
        requestId={requestId}
        adminMemoList={shippingDetail?.adminMemo}
        memo={memo}
        onMemoChange={handleMemoChange}
        onClose={handleClose}
        onMemoAdd={handleMemoAdd}
        ResponseHandlerOfAdminMemo={ResponseHandlerOfAdminMemo}
      />
    ),
    [
      ResponseHandlerOfAdminMemo,
      handleClose,
      handleMemoChange,
      handleMemoAdd,
      memo,
      requestId,
      shippingDetail?.adminMemo,
    ]
  );

  return {
    showAdminMemoModal,
    ShippingAdminModal,
  };
}

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GET_ADMIN_ADJUSTMENT_LIST_REQ_SEARCH_KIND,
  GET_ADMIN_ADJUSTMENT_LIST_RES,
} from "api-interfaces/inventory";
import INVENTORY_QUERY from "queries/INVENTORY_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useGetCSVDownloadInfoFromQuery from "hooks/useGetCSVDownloadInfoFromQuery";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "hooks/useSearchWithDate";
import useSearchWithTerm, { TermSearchType } from "hooks/useSearchWithTerm";
import { InventoryAdjustment } from "types/inventory";
import { toFormattedDate } from "utils/date";
import { toThousandUnitFormat } from "utils/number";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_ADJUSTMENT_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "companyName",
    },
    {
      label: "skuID",
      value: "skuId",
    },
    {
      label: "LOC ID",
      value: "locationName",
      placeHolder: "검색어 (부분검색 가능)",
    },
  ];

type SearchDateType = "createdAt";
const dateSearchTypeOptions: SearchWithDateTypeOption<SearchDateType>[] = [
  {
    label: "날짜",
    value: "createdAt",
  },
];

type CellId =
  | keyof InventoryAdjustment
  | "companyName"
  | "teamName"
  | "itemName"
  | "locationBarcode"
  | "skuBarcode"
  | "productCode"
  | "managementCode"
  | "managerName";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "createdAt",
    disablePadding: false,
    label: "날짜",
    width: 110,
  },
  {
    id: "companyName",
    disablePadding: false,
    label: "회사명",
    width: 140,
  },
  {
    id: "teamName",
    disablePadding: false,
    label: "팀명",
    width: 140,
  },
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 140,
  },
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 110,
  },
  {
    id: "skuBarcode",
    disablePadding: false,
    label: "상품바코드",
    width: 120,
  },
  {
    id: "productCode",
    disablePadding: false,
    label: "상품관리코드",
    width: 120,
  },
  {
    id: "managementCode",
    disablePadding: false,
    label: "옵션관리코드",
    width: 120,
  },
  {
    id: "locationBarcode",
    disablePadding: false,
    label: "LOC ID",
    width: 110,
  },
  {
    id: "beforeQty",
    disablePadding: false,
    label: "기존 재고",
    width: 110,
    numeric: true,
  },
  {
    id: "afterQty",
    disablePadding: false,
    label: "조정 재고",
    width: 110,
    numeric: true,
  },
  {
    id: "difference",
    disablePadding: false,
    label: "차이",
    width: 110,
    numeric: true,
  },
  {
    id: "managerName",
    disablePadding: false,
    label: "담당자",
    width: 110,
  },
  {
    id: "reason",
    disablePadding: false,
    label: "비고",
    width: 300,
  },
];

function InventoryAdjustmentList() {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  // 검색어가 바뀌면 페이징 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm]);

  // 페이지당 데이터 수 변환시 현재 페이지 초기화
  useEffect(() => {
    setCurrentPage(0);
  }, [perPage]);

  const { DateSearchPanel, startDate, endDate } = useSearchWithDate({
    dateSearchTypeOptions,
  });

  const {
    ResponseHandler: ResponseHandlerOfAdjustmentList,
    data: adjustmentList,
  } = INVENTORY_QUERY.useGetAdminAdjustmentList({
    page: currentPage,
    perPage,
    ...(termSearchType?.value && debouncedSearchTerm
      ? {
          searchKind: termSearchType?.value,
          searchTerm: debouncedSearchTerm,
        }
      : {}),
    ...(startDate && endDate
      ? {
          dateFrom: new Date(startDate),
          dateTo: new Date(endDate),
        }
      : {}),
  });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: INVENTORY_QUERY.useGetAdminAdjustmentListForCSVDownload,
    queryArgs: [
      {
        ...(termSearchType?.value && debouncedSearchTerm
          ? {
              searchKind: termSearchType?.value,
              searchTerm: debouncedSearchTerm,
            }
          : {}),
        ...(startDate && endDate
          ? {
              dateFrom: new Date(startDate),
              dateTo: new Date(endDate),
            }
          : {}),
      },
    ],
  });

  const mapDataForTable = useCallback(
    (data: GET_ADMIN_ADJUSTMENT_LIST_RES | undefined, isForCSV?: boolean) => {
      if (!data?.list) return [];

      return data?.list.map((v) => {
        const row: TableBodyRow<CellId> = {
          createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD"),
          companyName: v.team?.company,
          teamName: v.team?.name,
          itemName: v.sku?.itemName,
          skuId: v.skuId ? `S${v.skuId}` : "",
          skuBarcode: v.sku.barCode,
          productCode: v.sku.productCode,
          managementCode: v.sku.managementCode,
          locationBarcode: v.location?.barCode,
          beforeQty: toThousandUnitFormat(v.beforeQty),
          afterQty: toThousandUnitFormat(v.afterQty),
          difference: toThousandUnitFormat(v.difference),
          managerName: v.manager?.name,

          reason: isForCSV ? (
            v.reason
          ) : (
            <>
              {v.reason?.split("\n").map((line, i) => {
                return (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                );
              })}
            </>
          ),
        };

        return row;
      });
    },
    []
  );

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload, true);
  }, [dataForCSVDownload, mapDataForTable]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(adjustmentList);
  }, [adjustmentList, mapDataForTable]);

  return (
    <Layout>
      <Table
        title="재고 관리 내역"
        headCells={headCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: adjustmentList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
        toolbarItems={{
          left: [TermSearchPanel, <DateSearchPanel key="dateSearch" />],
        }}
        csvDownloadInfo={{
          filename: `재고 관리 내역(${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          scope: "all",
          isCSVDownloadRequested,
          setIsCSVDownloadRequested,
          rowsForCSVDownload,
          ResponseHandlerOfFetchDataForCSVDownload,
          statusOfQueryOfFetchDataForCSVDownload,
          removeQueryOfFetchDataForCSVDownload,
        }}
      />

      {ResponseHandlerOfAdjustmentList}
    </Layout>
  );
}

export default withRequireAuth(InventoryAdjustmentList);

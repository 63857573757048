import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ORDER_QUERY from "queries/ORDER_QUERY";
import USER_QUERY from "queries/USER_QUERY";

import Layout from "containers/Layout";
import { MallOptions } from "types/order";
import { getHighlightedLabel } from "utils/string";

type CollectOmsOrdersFormType = {
  teamId: number;
  mall: MallOptions;
  startOrderedDate: dayjs.Dayjs;
  endOrderedDate: dayjs.Dayjs;
};

const supportedMalls: MallOptions[] = [
  "smartStore",
  "coupang",
  "eleven",
  "imweb",
  "cafe24",
  "godomall",
  "kakaoStore",
  "10x10",
  "Shopify",
  "gmarket",
  "auction",
];

export default function OmsCollectOrdersPage() {
  const collectOmsOrderForm = useForm<CollectOmsOrdersFormType>();

  const { data: teamList, ResponseHandler: ResponseHandlerOfGetTeamList } =
    USER_QUERY.useGetTeamList();

  const {
    mutate: collectOmsOrder,
    ResponseHandler: ResponseHandlerOfCollectOmsOrders,
  } = ORDER_QUERY.useCollectOmsOrders();

  const onClickCollectButton = () => {
    const payload = collectOmsOrderForm.getValues();
    collectOmsOrder({
      mall: payload.mall,
      teamId: payload.teamId,
      startOrderedDate: payload.startOrderedDate.toDate(),
      endOrderedDate: payload.endOrderedDate.toDate(),
    });
  };
  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.secondary"
        >
          OMS 주문 범위 수집
        </Typography>
        {getHighlightedLabel({
          label: "7일 이내의 날짜범위만 수집 가능합니다.",
          isHighlighted: true,
        })}
        <br />
        {getHighlightedLabel({
          label:
            "미수집된 주문이 수집되더라도 출고가 반드시 생성되는 것은 아닙니다.",
          isHighlighted: true,
        })}

        {teamList && (
          <Stack gap={"10px"}>
            <Controller
              control={collectOmsOrderForm.control}
              name={"teamId"}
              rules={{ required: true }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  onChange={(_, data) => onChange(data?.teamId)}
                  options={teamList.map((team) => ({
                    label: `${team.teamCompany || team.name}-${team.id}`,
                    teamId: team.id,
                  }))}
                  renderInput={(params) => <TextField {...params} label="팀" />}
                />
              )}
            />

            <Controller
              control={collectOmsOrderForm.control}
              name={"mall"}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="mall-input-label">쇼핑몰</InputLabel>
                  <Select
                    {...field}
                    labelId="mall-input-label"
                    label="쇼핑몰"
                    size={"medium"}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {supportedMalls.map((mall) => (
                      <MenuItem value={mall} key={mall}>
                        {mall}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              control={collectOmsOrderForm.control}
              name={"startOrderedDate"}
              rules={{
                required: true,
                validate: (value, formValue) => {
                  return (
                    value &&
                    formValue.endOrderedDate &&
                    formValue.endOrderedDate.unix() > value.unix() &&
                    formValue.endOrderedDate.diff(value, "day") < 7
                  );
                },
              }}
              defaultValue={dayjs()}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    {...field}
                    renderInput={(props: TextFieldProps) => (
                      <TextField {...props} />
                    )}
                    inputFormat="YYYY-MM-DD HH:mm:ss"
                    mask="____-__-__ __:__:__"
                  />
                </LocalizationProvider>
              )}
            />

            <Controller
              control={collectOmsOrderForm.control}
              name={"endOrderedDate"}
              rules={{
                required: true,
                validate: (value, formValue) => {
                  return (
                    value &&
                    formValue.startOrderedDate &&
                    value.unix() > formValue.startOrderedDate.unix() &&
                    value.diff(formValue.startOrderedDate, "day") < 7
                  );
                },
              }}
              defaultValue={dayjs()}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    {...field}
                    renderInput={(props: TextFieldProps) => (
                      <TextField {...props} />
                    )}
                    inputFormat="YYYY-MM-DD HH:mm:ss"
                    mask="____-__-__ __:__:__"
                  />
                </LocalizationProvider>
              )}
            />

            <Button
              variant="outlined"
              onClick={onClickCollectButton}
              disabled={!collectOmsOrderForm.formState.isValid}
            >
              수집시작
            </Button>
          </Stack>
        )}
      </Paper>

      {ResponseHandlerOfGetTeamList}
      {ResponseHandlerOfCollectOmsOrders}
    </Layout>
  );
}

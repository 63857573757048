import { useState } from "react";
import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import Modal from "components/sds-v1/Modal";
import usePrintInvoiceForIndividual from "hooks/usePrintInvoiceForIndividual";
import { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { toFormattedDate } from "utils/date";

import Styled from "./index.styles";

export default function usePrintInvoiceModal({
  shippingId,
  printedAt,
  printerWorkerName,
  printCount,
}: {
  shippingId: number;
  printedAt?: string;
  printerWorkerName?: string;
  printCount?: number;
}) {
  const [needConfirm, setNeedConfirm] = useState(false);

  const queryClient = useQueryClient();

  const handleCloseConfirmModal = useCallback(() => {
    setNeedConfirm(false);
  }, []);

  const {
    printInvoice,
    ResponseHandlerOfPrintInvoice,
    isRequestPrintingComplete,
    resetPrintInvoice,
  } = usePrintInvoiceForIndividual();

  const ResultModalOfPrint = useMemo(() => {
    if (!isRequestPrintingComplete) return null;

    return (
      <Modal
        uiType="titleOnly"
        title="송장 출력 요청이 완료되었습니다."
        active
        actionPositive={{
          label: "확인",
          handleClick: () => {
            queryClient
              .invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping())
              .then(() => {
                resetPrintInvoice();
                setNeedConfirm(false);
              });
          },
        }}
      />
    );
  }, [isRequestPrintingComplete, queryClient, resetPrintInvoice]);

  const ConfirmModalForRePrinting = useMemo(() => {
    if (!needConfirm) return null;

    return (
      <Modal
        uiType="content"
        title="송장을 출력한 이력이 있습니다."
        body={
          <Styled.printConfirmModalBody>
            • 출력일시 : {toFormattedDate(printedAt, "YYYY-MM-DD HH:mm") || "-"}{" "}
            <br />• 출력자 : {printerWorkerName || "-"} <br />• 출력횟수 :{" "}
            {printCount || "-"}
          </Styled.printConfirmModalBody>
        }
        active
        actionPositive={{
          label: "송장 출력",
          handleClick: () => {
            printInvoice({ shippingIds: [shippingId] });
          },
        }}
        actionNegative={{
          label: "닫기",
          handleClick: handleCloseConfirmModal,
        }}
      />
    );
  }, [
    shippingId,
    printInvoice,
    needConfirm,
    handleCloseConfirmModal,
    printedAt,
    printerWorkerName,
    printCount,
  ]);

  const PrintInvoiceModal = useMemo(() => {
    return (
      <>
        {ConfirmModalForRePrinting}
        {ResponseHandlerOfPrintInvoice}
        {ResultModalOfPrint}
      </>
    );
  }, [
    ConfirmModalForRePrinting,
    ResponseHandlerOfPrintInvoice,
    ResultModalOfPrint,
  ]);

  const startPrintingInvoice = useCallback(() => {
    if (printerWorkerName) {
      setNeedConfirm(true);
      return;
    }

    printInvoice({ shippingIds: [shippingId] });
  }, [printerWorkerName, shippingId, printInvoice]);

  return { startPrintingInvoice, PrintInvoiceModal };
}

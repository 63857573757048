import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";

const filterOptionList: TableHeadFilterOption<boolean>[] = [
  { label: "Y", value: true },
  { label: "N", value: false },
];

export default function useManagementDateFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: ManagementDateFilterPanel,
    filterList: managementDateFilterList,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    ManagementDateFilterPanel,
    managementDatePayloadListByFilter: managementDateFilterList,
  };
}

import { useCallback, useEffect, useMemo, useReducer } from "react";
import { GET_INVENTORY_DETAIL_BY_SKU_ID_RES } from "api-interfaces/inventory";

type InventoryDetailModifyFormInventory = Map<
  number | string,
  {
    locationBarCode: string | null; // 새로 추가한 item인 경우는 null
    locationId: number | null; // 새로 추가한 item인 경우는 null
    warehouseId: number;
    totalQty: number;
    inWorkingQty: number;
    originQty: number;
    adjustmentQty: number;
    isDraft?: boolean;
    managementDate: Date | null;
    priorityRank: number | null;
  }
>;

export interface InventoryDetailModifyForm {
  inventory: InventoryDetailModifyFormInventory;
  reason?: string;
}

function formStateReducer(
  state: InventoryDetailModifyForm | null,
  action:
    | { type: "init"; payload: InventoryDetailModifyForm | null }
    | {
        type: "updateAdjustmentQty";
        payload: {
          locationId: number;
          adjustmentQty: number;
        };
      }
    | {
        type: "updateLocation";
        payload: {
          prevLocationId: string | number;
          newLocationId: string | number;
          locationBarcode: string;
        };
      }
    | {
        type: "updateManagementDate";
        payload: {
          locationId: number;
          managementDate: Date;
        };
      }
    | { type: "updateReason"; payload: string }
    | { type: "addItem" }
    | { type: "removeItem"; payload: number | string }
) {
  switch (action.type) {
    case "init": {
      return action.payload;
    }

    case "updateAdjustmentQty": {
      if (!state) return state;

      const draft = { ...state };

      if (draft.inventory && state.inventory.get(action.payload.locationId)) {
        const newInventory = new Map(draft.inventory);

        // 위에서 확인 됨
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const target = newInventory.get(action.payload.locationId)!;

        target.adjustmentQty = action.payload.adjustmentQty;

        draft.inventory = newInventory;
      }
      return draft;
    }

    case "updateReason": {
      if (!state) return state;

      const draft = { ...state };

      draft.reason = action.payload;

      return draft;
    }

    case "updateLocation": {
      if (!state) return state;

      const draft = { ...state };

      const newInventory = new Map(draft.inventory);

      const prevLocation = newInventory.get(action.payload.prevLocationId);
      if (!prevLocation) return state;

      const newLocation = {
        ...prevLocation,
        locationBarCode: action.payload.locationBarcode,
        locationId: Number(action.payload.newLocationId),
      };

      newInventory.set(action.payload.newLocationId, newLocation);

      // draft로 만들어진 legacy location삭제
      newInventory.delete(action.payload.prevLocationId);

      draft.inventory = newInventory;

      return draft;
    }

    case "updateManagementDate": {
      if (!state) return state;

      const draft = { ...state };

      if (draft.inventory && state.inventory.get(action.payload.locationId)) {
        const newInventory = new Map(draft.inventory);

        // 위에서 확인 됨
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const target = newInventory.get(action.payload.locationId)!;

        target.managementDate = action.payload.managementDate;

        draft.inventory = newInventory;
      }
      return draft;
    }

    case "addItem": {
      if (!state) return state;

      const draft = { ...state };

      const newKey = `draft-inventory-${Math.random()}`;
      const newInventory = new Map(draft.inventory);

      newInventory.set(newKey, {
        locationBarCode: null,
        locationId: null,
        totalQty: 0,
        inWorkingQty: 0,
        originQty: 0,
        adjustmentQty: 0,
        warehouseId: 1, // 당분간은 인천1센터밖에 없으므로 1로 고정
        isDraft: true,
        managementDate: null,
        priorityRank: null,
      });

      draft.inventory = newInventory;

      return draft;
    }

    case "removeItem": {
      if (!state) return state;

      const draft = { ...state };

      const newInventory = new Map(draft.inventory);

      newInventory.delete(action.payload);

      draft.inventory = newInventory;

      return draft;
    }

    default:
      throw new Error("Unhandled action");
  }
}

export default function useFormState({
  inventoryDetailData,
}: {
  inventoryDetailData: GET_INVENTORY_DETAIL_BY_SKU_ID_RES | undefined;
}) {
  const [formState, dispatchFormState] = useReducer(formStateReducer, null);

  const totalQty = useMemo(() => {
    if (!inventoryDetailData?.length) return 0;

    return inventoryDetailData.reduce((a, c) => {
      a += c.totalQty || 0;
      return a;
    }, 0);
  }, [inventoryDetailData]);

  const { totalAvailableQty, newTotalAvailableQty } = useMemo(() => {
    const result = {
      totalAvailableQty: 0,
      newTotalAvailableQty: 0,
    };

    if (!formState?.inventory) return result;

    formState.inventory.forEach((v) => {
      result.totalAvailableQty += v.originQty;
      result.newTotalAvailableQty += v.adjustmentQty;
    });

    return result;
  }, [formState?.inventory]);

  const initFormState = useCallback(
    () =>
      dispatchFormState({
        type: "init",
        payload: {
          inventory: inventoryDetailData
            ? inventoryDetailData
                .sort((a, b) => a.priorityRank - b.priorityRank)
                .reduce((a, c) => {
                  a.set(c.locationId, {
                    locationBarCode: c.location?.barCode,
                    locationId: c.locationId,
                    totalQty: c.totalQty,
                    inWorkingQty: c.inWorkingQty,
                    originQty: c.availableQty,
                    adjustmentQty: c.availableQty,
                    warehouseId: c.warehouseId,
                    managementDate: c.managementDate,
                    priorityRank: c.priorityRank,
                  });

                  return a;
                }, new Map() as InventoryDetailModifyFormInventory)
            : (new Map() as InventoryDetailModifyFormInventory),
        },
      }),
    [inventoryDetailData]
  );

  const standardInventory = useMemo(() => {
    // 모든 리스트의 SKU가 같으므로 첫번째 요소의 데이터만 사용
    return inventoryDetailData ? inventoryDetailData[0] : null;
  }, [inventoryDetailData]);

  useEffect(() => {
    initFormState();
  }, [initFormState]);

  const updateInventory = useCallback(() => {
    // TODO: api 연결

    console.log("@@@ formState", formState);
  }, [formState]);

  const addInventoryItem = useCallback(() => {
    dispatchFormState({ type: "addItem" });
  }, []);

  const removeInventoryItem = useCallback((locationId: number | string) => {
    dispatchFormState({ type: "removeItem", payload: locationId });
  }, []);

  return {
    initFormState: initFormState,
    formState: formState,
    totalQty,
    dispatchFormState,
    totalAvailableQty,
    newTotalAvailableQty,
    updateInventory,
    addInventoryItem,
    removeInventoryItem,
    standardInventory,
  };
}

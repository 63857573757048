import { useMemo, useState } from "react";
import { Search } from "@mui/icons-material";
import { Button, Card, Paper, Typography } from "@mui/material";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

function AddressCheck() {
  const [tempAddress, setTempAddress] = useState("");
  const [address, setAddress] = useState("");

  const isValidated = useMemo(() => {
    return !!tempAddress?.trim();
  }, [tempAddress]);

  const { data, ResponseHandler } = SHIPPING_QUERY.useRefineAddress({
    address,
  });

  const isInputChanged = address !== tempAddress;

  const handleSubmit = () => {
    if (isValidated) {
      const trimmed = tempAddress.trim();
      setAddress(trimmed);
      setTempAddress(trimmed);
    }
  };

  const addressStatus = useMemo(() => {
    if (!data?.code) return "-";

    switch (data.code) {
      case "S": {
        return "정상";
      }
      case "-20002": {
        return "행정 구역을 찾을 수 없음";
      }
      case "-20009": {
        return "CJ 정지 (파업, 천재 지변 등)";
      }
      default: {
        return data.code;
      }
    }
  }, [data?.code]);

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "700px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          주소 조회
        </Typography>

        <TextField
          label="주소"
          size="medium"
          fullWidth
          value={tempAddress}
          onChange={(e) => setTempAddress(e.target.value)}
          placeholder="주소를 입력하세요"
          handleEnterKeyPress={handleSubmit}
        />

        <Button
          variant="contained"
          size="medium"
          startIcon={<Search />}
          style={{ marginTop: "10px" }}
          disabled={!isValidated}
          onClick={handleSubmit}
        >
          검색
        </Button>

        {!isInputChanged && data && (
          <Card
            variant="outlined"
            style={{
              padding: "20px",
              marginTop: "10px",
              backgroundColor: "#f7f9fc",
            }}
          >
            <div>
              <b>확인된 주소(요약 주소):</b>&nbsp;
              {data?.address}
            </div>
            <br />

            <div>
              <b>상태:</b>&nbsp;
              {addressStatus}
            </div>

            <br />

            <div>
              <hr />
              <b>message:</b>&nbsp;{data?.message}
            </div>
          </Card>
        )}
      </Paper>

      {ResponseHandler}
    </Layout>
  );
}

export default withRequireAuth(AddressCheck);

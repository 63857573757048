import React, { Fragment, ReactNode } from "react";
import {
  Chip,
  Paper,
  Stack,
  SxProps,
  Tab,
  Table as MUITable,
  TableContainer,
  TablePagination,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";

import { KeyType, SelectionDict } from "hooks/useMultiSelect";

import CSVDownloadForSelectedRows, {
  TableCSVDownloadForSelectedRowsInfo,
} from "./CSVDownloadForSelectedRows";
import MemoizedTableBody from "./MemoizedTableBody";
import MemoizedTableHead from "./MemoizedTableHead";

export type GroupedHeadRow<GroupedHeadId, CellId> = GroupedHeadRowItem<
  GroupedHeadId,
  CellId
>[];
export interface GroupedHeadRowItem<GroupedHeadId, CellId> {
  id: GroupedHeadId;
  label: string;
  headCellIds: CellId[];
}

export interface TableTabInfo {
  activeTabIndex: number;
  setActiveTabIndex: (val: number) => void;
  tabList: { label: ReactNode }[];
}

export type TableHeadCell<CellId> = {
  id: CellId;
  label: React.ReactNode;
  /**
   * label이 JSX인 경우 대체할 값
   */
  labelForCSV?: string | number;
  disablePadding: boolean;
  width?: number;
  filter?: React.ReactNode;
};

export type TableBodyRow<CellId extends string> = {
  [K in CellId]?: string | number | React.ReactNode;
} & {
  /** id 는 화면에 표시하지 않는 경우도 있어서 제외, 사용 시에는 별도의 key값을 부여(ex. shippingId) */
  id: number | string;
  handleRowClick?: () => void;
  handleRowAdd?: () => void;
  handleRowRemove?: () => void;
  backgroundColor?: string;
};

export interface TableExtraData {
  label: string | number;
  value: string | number;
  onClick?: () => void;
}

export type PinnedColumn<CellId extends string> = {
  id: CellId;
  prevColumnWidth: number;
};

/**
 * 체크박스가 기본적으로 포함된 경량화 버전 테이블(Boful에서 사용)
 * useMultiSelect와 함께 사용하며, 각 행에는 id가 있어야 함
 */
export default function MemoizedTable<
  CellId extends string,
  GroupedHeadId = void
>({
  title,
  groupedHeadRow,
  headCells,
  rows,
  extraTableBody,
  pagination,
  toolbarItems,
  tableTabInfo,
  csvDownloadForSelectedRowsInfo,

  pinnedColumnList,

  extraDataList,

  sx,
  minWidth,
  className,

  selectionDict,
  select,
  unSelect,
  selectAll,
  unSelectAll,
  selectedCount,
  isAllSelected,
}: {
  title?: React.ReactNode;
  /**
   * 헤더의 상위헤더(그루핑된 헤더)
   * 일단은 1depth만 있다고 가정. (2depth 이상 되면 개선 필요)
   */
  groupedHeadRow?: GroupedHeadRow<GroupedHeadId, CellId>;
  headCells: TableHeadCell<CellId>[];
  rows: TableBodyRow<CellId>[];
  /**
   * 테이블 하단에 추가로 표시할 행 (ex. 총합계)
   */
  extraTableBody?: ReactNode;
  pagination?: {
    rowsPerPageOptions?:
      | (
          | number
          | {
              value: number;
              label: string;
            }
        )[]
      | undefined;
    totalCount: number;
    perPage: number;
    setPerPage: (perPage: number) => void;
    currentPage: number;
    setCurrentPage: (page: number) => void;
  };
  toolbarItems?: {
    left?: React.ReactNode[];
    right?: React.ReactNode[];
    rightBottom?: React.ReactNode[];
  };
  tableTabInfo?: TableTabInfo;
  /**
   * 선택된 행만 csv download 기능이 필요할때만 사용.
   * cell 값이 string | number 외의 형태면 이상작동할 수 있음에 유의
   */
  csvDownloadForSelectedRowsInfo?: TableCSVDownloadForSelectedRowsInfo<
    CellId,
    GroupedHeadId
  >;
  minWidth?: number;

  sx?: SxProps<Theme> | undefined;
  className?: string;

  /**
   * 고정 컬럼 목록(좌측부터 순서대로 고정)
   */
  pinnedColumnList?: PinnedColumn<CellId>[];

  extraDataList?: TableExtraData[];

  /**
   * useMultiSelect와 함께 사용
   */
  selectionDict: SelectionDict;
  select: (key: KeyType) => void;
  unSelect: (key: KeyType) => void;
  selectAll: () => void;
  unSelectAll: () => void;
  selectedCount: number;
  isAllSelected: boolean;
}) {
  const hasToolbarItem = !!toolbarItems || !!csvDownloadForSelectedRowsInfo;

  const tableHeight = (() => {
    if (tableTabInfo && pagination) {
      return "calc(100vh - 368px)";
    }

    if (!tableTabInfo && !pagination) {
      return "calc(100vh - 220px)";
    }

    return "calc(100vh - 272px)";
  })();

  return (
    <Paper
      className={className ? `table ${className}` : ""}
      sx={{ position: "relative" }}
    >
      {extraDataList && (
        <Paper
          sx={{ p: 1, position: "absolute", top: -56, left: 0, zIndex: 999 }}
        >
          <Stack direction="row" gap={2}>
            {extraDataList.map((data) => (
              <Chip
                key={data.label}
                label={
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="body1">{data.label}:</Typography>
                    <Typography fontWeight={800} variant="body1">
                      {data.value}
                    </Typography>
                  </Stack>
                }
                onClick={data.onClick}
              />
            ))}
          </Stack>
        </Paper>
      )}

      {title && (
        <Toolbar
          sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}
        >
          <Typography variant="h6" component="h1">
            {title}
          </Typography>

          <Stack justifyContent="flex-end" gap={1}>
            <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
              {toolbarItems?.right?.map((ToolBarItem, i) => (
                <Fragment key={i}>{ToolBarItem}</Fragment>
              ))}

              {csvDownloadForSelectedRowsInfo && (
                <CSVDownloadForSelectedRows
                  headCells={headCells}
                  groupedHeadRow={groupedHeadRow}
                  csvDownloadInfo={csvDownloadForSelectedRowsInfo}
                />
              )}
            </Stack>

            <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
              {toolbarItems?.rightBottom?.map((ToolBarItem, i) => (
                <Fragment key={i}>{ToolBarItem}</Fragment>
              ))}
            </Stack>
          </Stack>
        </Toolbar>
      )}

      {hasToolbarItem && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Stack direction="row" alignItems="flex-start" ml={2} gap={1}>
            {toolbarItems?.left?.map((ToolBarItem, i) => (
              <Fragment key={i}>{ToolBarItem}</Fragment>
            ))}
          </Stack>
        </Stack>
      )}

      {tableTabInfo && (
        <Tabs
          value={tableTabInfo.activeTabIndex}
          onChange={(e: React.SyntheticEvent, newValue: number) => {
            e.stopPropagation();
            tableTabInfo.setActiveTabIndex(newValue);
          }}
        >
          {tableTabInfo.tabList.map((v, i) => {
            return <Tab key={i} label={v.label} />;
          })}
        </Tabs>
      )}

      <TableContainer sx={{ height: tableHeight, ...sx }}>
        <MUITable
          stickyHeader
          sx={{ minWidth: typeof minWidth === "number" ? minWidth : 750 }}
          size="small"
        >
          <MemoizedTableHead
            groupedHeadRow={groupedHeadRow}
            headCells={headCells}
            pinnedColumnList={pinnedColumnList}
            isAllSelected={isAllSelected}
            selectAll={selectAll}
            unSelectAll={unSelectAll}
            selectedCount={selectedCount}
          />

          <MemoizedTableBody
            rows={rows}
            pinnedColumnList={pinnedColumnList}
            extraTableBody={extraTableBody}
            selectionDict={selectionDict}
            select={select}
            unSelect={unSelect}
          />
        </MUITable>
      </TableContainer>

      {pagination && (
        <TablePagination
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          component="div"
          count={pagination.totalCount ?? 0}
          rowsPerPage={pagination.perPage}
          page={pagination.currentPage}
          onPageChange={(e, page) => {
            pagination.setCurrentPage(page);
          }}
          showFirstButton
          showLastButton
          onRowsPerPageChange={(e) =>
            pagination.setPerPage(Number(e.target.value) || pagination.perPage)
          }
          labelRowsPerPage="페이지당 데이터 수"
        />
      )}
    </Paper>
  );
}

import { Alert, AlertColor, Snackbar } from "@mui/material";

/**
 * @default
 * color: error
 * autoHideDuration: 6000
 */
export default function Toast({
  showsToast,
  onToastClose,
  message,
  color = "error",
  autoHideDuration = 6000,
}: {
  showsToast: boolean;
  onToastClose: () => void;
  message?: string;
  color?: AlertColor;
  autoHideDuration?: number;
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={showsToast}
      autoHideDuration={autoHideDuration}
      onClose={onToastClose}
    >
      <Alert onClose={onToastClose} severity={color}>
        {message}
      </Alert>
    </Snackbar>
  );
}

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import useSet from "hooks/useSet";

import Styled from "./index.styles";

type CheckBoxTypeOption<T extends string | number, K> = {
  label: string;
  value: T;
  /**
   * 체크된 항목들로 수행할 액션(버튼)을 정의
   */
  renderAction: ({
    checkBoxArr,
    initCheckBoxSet,
  }: {
    checkBoxArr: number[];
    initCheckBoxSet: (values?: number[] | undefined) => void;
  }) => React.ReactNode;
  /**
   * 체크할 가능한 아이템인지 확인하는 함수
   */
  checkCanCheck: (item: K) => boolean;
  /**
   * 체크박스 항목에 사용할 id를 지정하는 함수
   */
  getIdForCheckBox: (item: K) => number;
};

export default function useCheckBox<T extends string | number, K>({
  checkBoxTypeOptions,
  activeCheckBoxTypes,
  data,
}: {
  /**
   * 체크박스 타입 옵션
   */
  checkBoxTypeOptions: CheckBoxTypeOption<T, K>[];
  /**
   * 활성화된(선택가능한) 체크박스 타입 옵션
   * useEffect의 deps가 되기 떄문에 반드시 메모이징되거나 외부에 선언되어 참조값이 변하지 않는 값이어야 함
   */
  activeCheckBoxTypes: T[];
  /**
   * 체크박스를 사용하는 list 데이터
   */
  data?: K[];
}) {
  const {
    array: checkBoxArr,
    set: checkBoxSet,
    toggle: toggleCheckBox,
    init: initCheckBoxSet,
  } = useSet<number>(); // 보통 id로 체크하니 일단 number만 구현

  const optionNotSelected = useMemo(
    () => ({
      label: activeCheckBoxTypes.length ? "선택안함" : "해당없음",
      value: "none",
    }),
    [activeCheckBoxTypes.length]
  );

  const [checkBoxType, setCheckBoxType] = useState<string | number>(
    optionNotSelected.value
  );

  const selectedCheckBoxTypeOption = useMemo(() => {
    return checkBoxTypeOptions.find((v) => v.value === checkBoxType);
  }, [checkBoxType, checkBoxTypeOptions]);

  const checkIsActiveCheckBoxType = useCallback(
    (checkBoxType: T) => {
      return activeCheckBoxTypes.findIndex((v) => v === checkBoxType) > -1;
    },
    [activeCheckBoxTypes]
  );

  // 선택가능한 옵션의 종류가 달라지만 선택된 옵션을 초기화
  useEffect(() => {
    setCheckBoxType(optionNotSelected.value);
  }, [activeCheckBoxTypes, optionNotSelected.value]);

  // 체크박스 타입이 바뀌면 체크내역을 초기화
  useEffect(() => {
    initCheckBoxSet();
  }, [checkBoxType, initCheckBoxSet]);

  const optionsCanSelect = useMemo(() => {
    // renderAction 등 select와 관계없는 속성들을 제외한 값
    const plainOptions = checkBoxTypeOptions
      .filter((v) => checkIsActiveCheckBoxType(v.value))
      .map((v) => ({
        label: v.label,
        value: v.value,
      }));

    return [optionNotSelected, ...plainOptions];
  }, [checkBoxTypeOptions, checkIsActiveCheckBoxType, optionNotSelected]);

  const SelectForCheckBoxType = useMemo(() => {
    return (
      <FormControl className="check-box-type" variant="outlined">
        <InputLabel id="check-box-type-select-label">체크박스 타입</InputLabel>
        <Select
          labelId="check-box-type-select-label"
          id="check-box-type-select"
          value={checkBoxType}
          label="체크박스 타입"
          defaultValue={optionNotSelected.value}
          onChange={(e) => {
            setCheckBoxType(e.target.value);
          }}
          size="small"
          disabled={!activeCheckBoxTypes.length}
        >
          {optionsCanSelect.map((st, i) => (
            <MenuItem value={st.value} key={i}>
              {st.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [
    checkBoxType,
    optionsCanSelect,
    activeCheckBoxTypes.length,
    optionNotSelected.value,
  ]);

  const ActionForCheckBox = useMemo(() => {
    if (checkBoxType === optionNotSelected.value) {
      return null;
    }

    const Action = (() => {
      const target = checkBoxTypeOptions.find((v) => v.value === checkBoxType);

      if (!target) return null;

      if (!checkIsActiveCheckBoxType(target.value)) return null;

      return target.renderAction({
        checkBoxArr,
        initCheckBoxSet,
      });
    })();

    return Action;
  }, [
    checkBoxTypeOptions,
    checkBoxType,
    checkBoxArr,
    initCheckBoxSet,
    checkIsActiveCheckBoxType,
    optionNotSelected,
  ]);

  const CheckBoxPanel = useCallback(
    ({ style }: { style?: React.CSSProperties }) => {
      return (
        <Styled.checkBoxPanelWrapper style={style}>
          <Styled.checkBoxPanel>
            {SelectForCheckBoxType}
            {ActionForCheckBox}
          </Styled.checkBoxPanel>
        </Styled.checkBoxPanelWrapper>
      );
    },
    [SelectForCheckBoxType, ActionForCheckBox]
  );

  const checkCanCheck = useCallback(
    (item: K) => {
      if (!selectedCheckBoxTypeOption) return false;

      return selectedCheckBoxTypeOption.checkCanCheck(item);
    },
    [selectedCheckBoxTypeOption]
  );

  const checkAllItems = useCallback(() => {
    if (!data || !selectedCheckBoxTypeOption) return;

    const idsToCheck = (() => {
      return (
        data
          .filter((v) => checkCanCheck(v))
          .map((v) => selectedCheckBoxTypeOption.getIdForCheckBox(v)) || []
      );
    })();

    initCheckBoxSet(idsToCheck);
  }, [selectedCheckBoxTypeOption, checkCanCheck, data, initCheckBoxSet]);

  const CheckBoxForAll = useCallback(() => {
    const isSomethingChecked = !!checkBoxSet.size;

    return (
      <Checkbox
        onClick={() => {
          if (isSomethingChecked) {
            initCheckBoxSet();
          } else {
            checkAllItems();
          }
        }}
        checked={isSomethingChecked}
      />
    );
  }, [initCheckBoxSet, checkAllItems, checkBoxSet.size]);

  const CheckBoxForItem = useCallback(
    ({ item }: { item: K }) => {
      if (!selectedCheckBoxTypeOption) return null;

      const id = selectedCheckBoxTypeOption.getIdForCheckBox(item);

      return (
        <Checkbox
          checked={checkBoxSet.has(id)}
          onClick={(e) => {
            e.stopPropagation();
            toggleCheckBox(id);
          }}
          disabled={!selectedCheckBoxTypeOption.checkCanCheck(item)}
        />
      );
    },
    [selectedCheckBoxTypeOption, checkBoxSet, toggleCheckBox]
  );

  return {
    /**
     * 체크박스 타입 선택 & 액션이 있는 UI컴포넌트
     */
    CheckBoxPanel,
    /**
     * 체크박스 전체 값을 선택/해제 하는 체크박스
     */
    CheckBoxForAll,
    /**
     * 개별 체크박스
     */
    CheckBoxForItem,
    /**
     * 선택된 체크박스 타입이 있는지 여부
     */
    hasCheckBoxType: checkBoxType !== optionNotSelected.value,
    /**
     * 체크박스 선택 내역을 초기화
     */
    initCheckBoxSet,
  };
}

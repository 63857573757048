import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { TEAM_ACCESS_KIND } from "constants/user";
import { TeamAccessDetail } from "types/user";

import Detail, { DetailProps } from "pages/user/Detail";

export default function AccessDetailModal({
  accessDetail,
  onClose,
}: {
  accessDetail: TeamAccessDetail | undefined;
  onClose: () => void;
}) {
  if (!accessDetail) {
    return null;
  }

  const teamAccessDetail: DetailProps = {
    detailList: [
      {
        label: "발주관리",
        value: TEAM_ACCESS_KIND[accessDetail.po],
        gridSize: 12,
      },
      {
        label: "운송관리",
        value: TEAM_ACCESS_KIND[accessDetail.transit],
        gridSize: 12,
      },
      {
        label: "센터관리",
        value: TEAM_ACCESS_KIND[accessDetail.center],
        gridSize: 12,
      },
    ],
  };

  return (
    <Dialog open={!!accessDetail} onClose={onClose}>
      <DialogTitle>권한 상세</DialogTitle>

      <DialogContent>
        <Detail {...teamAccessDetail} />
      </DialogContent>
    </Dialog>
  );
}

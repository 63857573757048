import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Description, Print } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useSetRecoilState } from "recoil";

import COMMON_QUERY from "queries/COMMON_QUERY";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "states/shipping";
import {
  AdminShippingListTabStatus,
  PartialShippingListItem,
} from "types/shipping";
import { toFormattedDate } from "utils/date";
import { getChannelName } from "utils/fulfillment";

import CancelShipping from "pages/shipping/CancelShipping";
import EditDeliveryInfo from "pages/shipping/EditDeliveryInfo";

import { OverseasCategory } from "..";
import DownloadCI from "../DownloadCI";
import DownloadPackingInfo from "./DownloadPackingInfo";
import OrderDetailList from "./OrderDetailList";
import PackingDetailList from "./PackingDetailList";
import ResetShippingStatus from "./ResetShippingStatus";
import ShippingDetailList from "./ShippingDetailList";
import Summary from "./Summary";
import useInputDeliveryDetail from "./useInputDeliveryDetail";
import usePrintInvoiceModal from "./usePrintInvoiceModal";
import { MALL_OPTION_TYPE } from "constants/shipping";
import { translateSaleChannel } from "utils/shipping";

export default function useShippingDetailModal({
  status,
  pageCategory,
  needsStateUpdateWhenPrintingTransactionStatement,
}: {
  status: AdminShippingListTabStatus | undefined;
  pageCategory: OverseasCategory;
  needsStateUpdateWhenPrintingTransactionStatement: boolean;
}) {
  const history = useHistory();

  const [shippingId, setShippingId] = useState<number>(0);
  const [active, setActive] = useState(false);

  const [adminShippingDetail, setAdminShippingDetail] =
    useState<PartialShippingListItem>();
  const [isVisibleUpdateStatusFailure, setIsVisibleUpdateStatusFailure] =
    useState(false);

  const { channelDict } = COMMON_QUERY.useGetChannelList();

  const {
    mutate: updateStatus,
    ResponseHandler: ResponseHandlerOfUpdateStatus,
  } = SHIPPING_QUERY.useUpdateStatusWhenPrintingTransactionStatement();

  const {
    data: shippingDetailData,
    ResponseHandler: ResponseHandlerOfGetDetail,
  } = SHIPPING_QUERY.useGetAdminDetail({
    enabled: !!shippingId,
    shippingId: shippingId,
  });

  const { startPrintingInvoice, PrintInvoiceModal } = usePrintInvoiceModal({
    shippingId,
    printerWorkerName: shippingDetailData?.printerWorker?.name,
    printCount: shippingDetailData?.printCount,
    printedAt: shippingDetailData?.printedAt,
  });

  const { DeliveryDetailButton, InputDeliveryDetailModal } =
    useInputDeliveryDetail({
      shippingId,
      deliveryType: shippingDetailData?.deliveryType,
      shippingDetailData,
    });

  const handleModalClose = useCallback(() => {
    setShippingId(0);
    setActive(false);
  }, []);

  const showShippingDetailModal = useCallback((shippingId: number) => {
    setShippingId(shippingId);
    setActive(true);
  }, []);

  const ResponseHandlerOfShippingDetail = useMemo(() => {
    return <>{ResponseHandlerOfGetDetail}</>;
  }, [ResponseHandlerOfGetDetail]);

  const setTransactionStatementData = useSetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.TRANSACTION_STATEMENT_DATA
  );

  const handleTransactionStatementPrint = useCallback(() => {
    setTransactionStatementData([
      {
        invoiceNo: adminShippingDetail?.invoiceNo ?? "",
        customerName: adminShippingDetail?.customerName ?? "",
        customerAddress: adminShippingDetail?.customerAddress ?? "",
        customerPhone: adminShippingDetail?.customerPhone ?? "",
        orderNo: adminShippingDetail?.orderNo ?? "",
        senderPhone: adminShippingDetail?.senderPhone ?? "",
        teamBRN: adminShippingDetail?.team?.BRN ?? "",
        teamRepresentativeName:
          adminShippingDetail?.team?.representativeName ?? "",
        teamCompany: adminShippingDetail?.team?.company ?? "",
        teamAddress: adminShippingDetail?.team?.address ?? "",
        items: (adminShippingDetail?.items ?? []).map((item) => ({
          printDate: toFormattedDate(new Date(), "MM/DD"),
          productName: item.sku?.itemName ?? "",
          quantity: item.quantity ?? 0,
        })),
        requestMemo: (adminShippingDetail?.memo ?? [])
          .filter(({ category }) => category === "customer")
          .map((memoItem) => (
            <div key={memoItem.createdAt}>{memoItem.memo}</div>
          )),
      },
    ]);

    history?.push("/shipping/domestic/print-transaction-statement");
  }, [adminShippingDetail, history, setTransactionStatementData]);

  const handleUpdateStatusFailureOpen = useCallback(() => {
    setIsVisibleUpdateStatusFailure(true);
  }, []);

  const handleUpdateStatusFailureClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setIsVisibleUpdateStatusFailure(false);
    },
    []
  );

  const checkIfNeedToUpdateState = useCallback(() => {
    if (needsStateUpdateWhenPrintingTransactionStatement) {
      updateStatus([shippingId], {
        onSuccess: ({ data }) => {
          if (data.success === 1) {
            handleTransactionStatementPrint();
            return;
          }

          handleUpdateStatusFailureOpen();
        },
      });
      return;
    }

    handleTransactionStatementPrint();
  }, [
    needsStateUpdateWhenPrintingTransactionStatement,
    handleTransactionStatementPrint,
    updateStatus,
    shippingId,
    handleUpdateStatusFailureOpen,
  ]);

  const ShippingDetailModalBody = useMemo(
    () => (
      <Stack spacing={2}>
        <Typography variant="h6">
          출고요청번호: {shippingDetailData?.id}
        </Typography>

        <Stack direction="column" gap={1}>
          <Typography variant="body2">
            판매채널: {translateSaleChannel(shippingDetailData?.saleChannel)}
          </Typography>
          <Typography variant="body2">
            주문번호: {shippingDetailData?.orderNo}
          </Typography>
        </Stack>

        <OrderDetailList shippingDetailData={shippingDetailData} />

        <ShippingDetailList shippingDetailData={shippingDetailData} />

        <Summary status={status} shippingDetailData={shippingDetailData} />

        <PackingDetailList shippingDetailData={shippingDetailData} />
      </Stack>
    ),
    [channelDict, shippingDetailData, status]
  );

  const ShippingDetailModal = useMemo(() => {
    const showsPrintInvoiceAndDownloadCIButton =
      status === "WAITING_EXPECTED" ||
      status === "WAITING_TODAY" ||
      status === "INSPECT" ||
      status === "PICKING" ||
      status === "PACKING" ||
      status === "SHIPMENT_READY" ||
      status === "SHIPMENT_DONE";

    const showsPrintTransactionStatementButton =
      showsPrintInvoiceAndDownloadCIButton || status === "DONE";

    const showsDeliveryDetailButton =
      status === "SHIPMENT_READY" ||
      status === "SHIPMENT_DONE" ||
      status === "DONE";

    const showsDownloadPackingInfo =
      status === "SHIPMENT_READY" ||
      status === "SHIPMENT_DONE" ||
      status === "DONE";

    const showsResetShippingStatusButton =
      /**
       * 상세 조회 API는 endedPickingAt 정보가 없기 때문에 리스트에서 가져온 정보를 사용
       * PICKING 인데, 피킹시작여부가 N
       */
      status === "PICKING" && !adminShippingDetail?.startedPickingAt;

    const showsCancelShippingButton =
      status === "WAITING_EXPECTED" ||
      status === "WAITING_TODAY" ||
      status === "INSPECT" ||
      status === "PICKING" ||
      status === "PACKING" ||
      status === "SHIPMENT_READY";

    return (
      <>
        <Dialog open={active} maxWidth={false} onClose={handleModalClose}>
          <DialogTitle>
            상세 보기
            <Stack justifyContent="flex-end" gap={1}>
              <Stack direction="row" justifyContent="flex-end" gap={1}>
                {showsPrintInvoiceAndDownloadCIButton && (
                  <DownloadCI
                    shippingList={[
                      {
                        id: shippingId,
                        attachment: adminShippingDetail?.attachment,
                      },
                    ]}
                    selectedShippingIdList={[shippingId]}
                  />
                )}

                <EditDeliveryInfo
                  status={status}
                  pageCategory={pageCategory}
                  selectedShippingIds={[shippingId]}
                />

                {showsPrintInvoiceAndDownloadCIButton && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<Print />}
                      onClick={startPrintingInvoice}
                    >
                      송장 출력
                    </Button>
                    {PrintInvoiceModal}
                  </>
                )}

                {/* TODO: 해외 운송사 API 정상 적용 전까지 거래명세서로 작업, 이후에 onClick핸들러를 handleTransactionStatementPrint로 변경 */}
                {showsPrintTransactionStatementButton && (
                  <Button
                    startIcon={<Description />}
                    variant="outlined"
                    onClick={checkIfNeedToUpdateState}
                  >
                    거래명세서 출력
                  </Button>
                )}

                {showsDeliveryDetailButton && DeliveryDetailButton}

                {showsDownloadPackingInfo && (
                  <DownloadPackingInfo data={shippingDetailData} />
                )}

                {showsResetShippingStatusButton && (
                  <ResetShippingStatus shippingId={shippingId} />
                )}
              </Stack>

              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                {showsCancelShippingButton && (
                  <CancelShipping
                    type="detail"
                    onAfterCancel={handleModalClose}
                    selectedShippingIdList={[shippingId]}
                  />
                )}
              </Stack>
            </Stack>
          </DialogTitle>

          <DialogContent dividers>{ShippingDetailModalBody}</DialogContent>
        </Dialog>

        {InputDeliveryDetailModal}

        {ResponseHandlerOfUpdateStatus}

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={isVisibleUpdateStatusFailure}
          autoHideDuration={6000}
          onClose={handleUpdateStatusFailureClose}
        >
          <Alert onClose={handleUpdateStatusFailureClose} severity="warning">
            거래명세서 상태 변경에 실패하였습니다.
          </Alert>
        </Snackbar>
      </>
    );
  }, [
    status,
    adminShippingDetail?.startedPickingAt,
    adminShippingDetail?.attachment,
    active,
    handleModalClose,
    shippingId,
    pageCategory,
    startPrintingInvoice,
    PrintInvoiceModal,
    checkIfNeedToUpdateState,
    DeliveryDetailButton,
    shippingDetailData,
    ShippingDetailModalBody,
    InputDeliveryDetailModal,
    ResponseHandlerOfUpdateStatus,
    isVisibleUpdateStatusFailure,
    handleUpdateStatusFailureClose,
  ]);

  return {
    setAdminShippingDetail,
    showShippingDetailModal,
    ShippingDetailModal,
    ResponseHandlerOfShippingDetail,
  };
}

import { ShippingItemDetail, ShippingLocation } from "types/shipping";

export type SkuPickingInfo = {
  skuId: number;
  totalQuantity: number;
  pickingList: {
    locationId: number;
    barCode: string;
    quantity: number;
  }[];
};

export type InventoryStatus = {
  locationId: number;
  locationBarCode: string;
  skuId: number;
  availableQty: number;
};

export const compositeSkuPickingInfoList = (
  shippingDetail: ShippingItemDetail,
  locations: ShippingLocation[]
): { data: SkuPickingInfo[]; hasError: boolean } => {
  const targetSkuIds = [...new Set(shippingDetail.items.map((v) => v.skuId))];
  let hasError = false;

  const data: SkuPickingInfo[] = targetSkuIds.map((skuId) => {
    const targetShippingItems = shippingDetail.items.filter(
      (v) => v.skuId === skuId
    );
    return {
      skuId,
      totalQuantity: targetShippingItems.reduce(
        (prev, curr) => prev + curr.quantity,
        0
      ),
      pickingList: targetShippingItems.map((targetShippingItem) => {
        const foundLocation = locations.find(
          (location) => location.id === targetShippingItem.locationId
        );
        if (!foundLocation) {
          hasError = true;
        }
        return {
          locationId: targetShippingItem.locationId,
          barCode: foundLocation?.barCode || "ERROR",
          quantity: targetShippingItem.quantity,
        };
      }),
    };
  });

  return { data, hasError };
};

export const compositeInventoryStatus = (
  shippingDetail: ShippingItemDetail,
  locations: ShippingLocation[]
): { data: InventoryStatus[]; hasError: boolean } => {
  const targetSkuIds = [...new Set(shippingDetail.items.map((v) => v.skuId))];
  let hasError = false;

  const data: InventoryStatus[] = shippingDetail.items
    .flatMap((v) => v.sku.inventory)
    .filter((v) => !!v.locationId)
    .reduce((prev, curr) => {
      const foundLocation = locations.find(
        (location) => location.id === curr.locationId
      );
      if (!foundLocation) {
        hasError = true;
        return prev;
      }

      if (prev.every((v) => v.locationId !== curr.locationId)) {
        prev.push({
          skuId: curr.skuId as unknown as number,
          availableQty: curr.availableQty,
          locationId: curr.locationId,
          locationBarCode: foundLocation.barCode,
        });
      }
      return prev;
    }, [] as InventoryStatus[]);

  return { data, hasError };
};

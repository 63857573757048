import {
  Button,
  Dialog,
  DialogContent,
  OutlinedInput,
  Stack,
} from "@mui/material";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import { useCallback, useMemo, useState } from "react";
import { getHighlightedLabel } from "utils/string";

type ItemInfo = {
  shippingId: number;
  skuId: number;
  locationId: number;
  barCode: string;
  quantity: number;
};
export default function useUpdateQuantityModal({
  onUpdateSuccess,
}: {
  onUpdateSuccess: () => void;
}) {
  const [active, setActive] = useState(false);
  const [newQuantity, setNewQuantity] = useState(0);
  const [itemInfo, setItemInfo] = useState<ItemInfo>();

  const showUpdateQuantityModal = (itemInfo: ItemInfo) => {
    setActive(true);
    setNewQuantity(itemInfo.quantity || 0);
    setItemInfo(itemInfo);
  };

  const handleModalClose = useCallback(() => {
    setActive(false);
    setNewQuantity(0);
    setItemInfo(undefined);
  }, []);

  const onUpdateShipingItemSuccess = () => {
    onUpdateSuccess();
    handleModalClose();
  };

  const {
    mutate: updateShippingItemQuantity,
    ResponseHandler: ResponseHandlerOfUpdateShippingItemQuantity,
  } = SHIPPING_QUERY.useUpdateShippingItemQuantity(
    itemInfo?.shippingId || 0,
    onUpdateShipingItemSuccess
  );

  const isReadyToShow = active && itemInfo;
  const UpdateQuantityModal = useMemo(() => {
    const onUpdateButtonClick = (
      skuId: number,
      locationId: number,
      newQuantity: number
    ) => {
      updateShippingItemQuantity({
        skuId,
        locationId,
        newQuantity,
      });
    };
    return (
      <>
        {isReadyToShow && (
          <Dialog
            open={active}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleModalClose}
          >
            <DialogContent dividers>
              <Stack gap={"20px"}>
                <h1>수정하기(차감)</h1>

                <span>{itemInfo.skuId + " | " + itemInfo.barCode}</span>
                <span>
                  {itemInfo.quantity + " => "}
                  <OutlinedInput
                    size="small"
                    type={"number"}
                    value={newQuantity}
                    onChange={(e) => {
                      setNewQuantity(Number(e.target.value));
                    }}
                  />
                </span>

                {getHighlightedLabel({
                  label: "아직은 차감만 지원합니다",
                  isHighlighted: true,
                })}

                <Button
                  variant="outlined"
                  disabled={newQuantity >= itemInfo.quantity}
                  onClick={() => {
                    onUpdateButtonClick(
                      itemInfo.skuId,
                      itemInfo.locationId,
                      newQuantity
                    );
                  }}
                >
                  수정
                </Button>
              </Stack>
            </DialogContent>
            {ResponseHandlerOfUpdateShippingItemQuantity}
          </Dialog>
        )}
      </>
    );
  }, [
    ResponseHandlerOfUpdateShippingItemQuantity,
    active,
    handleModalClose,
    isReadyToShow,
    itemInfo,
    newQuantity,
    updateShippingItemQuantity,
  ]);

  return { UpdateQuantityModal, showUpdateQuantityModal };
}

import { ChangeEvent, useState } from "react";
import { Button, Paper, TextField, Typography } from "@mui/material";
import INVENTORY_QUERY from "queries/INVENTORY_QUERY";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import { getHighlightedLabel } from "utils/string";
import { compositeSkuPickingInfoList } from "./utils";

import UpdateItemQuantityForm from "./Form";

function UpdateItemQuantity() {
  const [shippingId, setShippingId] = useState<number>(0);
  const [isFetchShippingButtonClicked, setIsFetchShippingButtonClicked] =
    useState<boolean>(false);

  const {
    data: shippingDetail,
    ResponseHandler: ResponseHandlerOfGetShippingDetail,
    remove,
    refetch,
  } = SHIPPING_QUERY.useGetShippingDetail({
    enabled: !!shippingId && isFetchShippingButtonClicked,
    id: shippingId || 0,
    onSuccess: (data) => {},
    onError: () => {
      setIsFetchShippingButtonClicked(false);
    },
  });

  const {
    data: locationDetails,
    ResponseHandler: ResponseHandlerOfGetLocationDetailsByLocationIds,
  } = INVENTORY_QUERY.useGetLocationDetailsByLocationIds({
    enabled: !!shippingDetail,
    warehouseId: shippingDetail?.warehouseId,
    locationIds:
      shippingDetail?.items
        .flatMap((v) => v.sku.inventory)
        .filter((v) => !!v.locationId)
        .map((v) => v.locationId) || [],
  });

  const onUpdateSuccess = () => {
    refetch();
  };

  const { data: skuPickingInfoList, hasError: skuPickingInfoListHasError } =
    (shippingDetail &&
      locationDetails &&
      compositeSkuPickingInfoList(shippingDetail, locationDetails)) ||
    {};

  const isProperStatusForThisJob =
    shippingDetail?.shippingStatus === "waiting" ||
    shippingDetail?.shippingStatus === "inProgress";

  return (
    <Layout>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        {getHighlightedLabel({
          label: "출고 상품 갯수를 줄이는 유틸리티입니다",
          isHighlighted: true,
        })}
        <br />
        {getHighlightedLabel({
          label: "의뢰대기/피킹/패킹 상태의 출고를 처리합니다",
          isHighlighted: true,
        })}
      </Paper>
      <Paper
        style={{
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          출고 상품 수량 수정(차감)
        </Typography>

        <TextField
          id="standard-basic"
          label="출고번호"
          size="small"
          value={shippingId || 0}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setIsFetchShippingButtonClicked(false);
            setShippingId(Number.parseInt(e.target.value));
          }}
          type="number"
          disabled={isFetchShippingButtonClicked}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={isFetchShippingButtonClicked}
          onClick={() => {
            shippingId && setIsFetchShippingButtonClicked(true);
          }}
        >
          출고 정보 가져오기
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={!isFetchShippingButtonClicked}
          onClick={() => {
            remove();
            setIsFetchShippingButtonClicked(false);
          }}
        >
          리셋
        </Button>
      </Paper>

      {shippingDetail &&
        !isProperStatusForThisJob &&
        getHighlightedLabel({
          label: "처리 가능한 출고 상태가 아닙니다.",
          isHighlighted: true,
        })}

      {shippingDetail &&
        isProperStatusForThisJob &&
        isFetchShippingButtonClicked &&
        locationDetails &&
        skuPickingInfoList &&
        !skuPickingInfoListHasError && (
          <UpdateItemQuantityForm
            shippingDetail={shippingDetail}
            initialSkuPickingInfoList={skuPickingInfoList}
            onUpdateSuccess={onUpdateSuccess}
          />
        )}
      {ResponseHandlerOfGetShippingDetail}
      {ResponseHandlerOfGetLocationDetailsByLocationIds}
    </Layout>
  );
}

export default withRequireAuth(UpdateItemQuantity);

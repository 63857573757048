import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";

export default function useDeletePackingModal() {
  const [invoiceNo, setInvoiceNo] = useState<string>("");
  const [shippingId, setShippingId] = useState<number>(0);

  const {
    mutateAsync: deletePacking,
    ResponseHandler: ResponseHandlerOfDeletePacking,
  } = SHIPPING_QUERY.useDeletePacking({ invoiceNo });

  const queryClient = useQueryClient();

  const showDeletePackingModal = (invoiceNo: string, shippingId: number) => {
    setInvoiceNo(invoiceNo);
    setShippingId(shippingId);
  };

  const handleDeletePackingModalClose = () => {
    setInvoiceNo("");
    setShippingId(0);
  };

  const DeletePackingModal = useMemo(() => {
    const handleDeletePacking = async () => {
      await deletePacking({
        invoiceNo,
      });
      queryClient.invalidateQueries(
        SHIPPING_QUERY_KEY_GEN.getAdminDetail({ shippingId })
      );
      handleDeletePackingModalClose();
    };

    const handleDeletePackingOff = () => {
      handleDeletePackingModalClose();
    };

    return (
      <>
        <Dialog open={!!invoiceNo} onClose={handleDeletePackingModalClose}>
          <DialogTitle>운송장번호 {invoiceNo}를 제거합니다.</DialogTitle>

          <DialogActions>
            <Button
              variant="contained"
              onClick={handleDeletePacking}
              disabled={!invoiceNo}
            >
              요청
            </Button>
            <Button
              variant="contained"
              onClick={handleDeletePackingOff}
              disabled={!invoiceNo}
            >
              취소
            </Button>
          </DialogActions>
        </Dialog>
        {ResponseHandlerOfDeletePacking}
      </>
    );
  }, [
    ResponseHandlerOfDeletePacking,
    deletePacking,
    invoiceNo,
    queryClient,
    shippingId,
  ]);

  return { showDeletePackingModal, DeletePackingModal };
}

import { useMemo } from "react";
import { AssignmentReturned } from "@mui/icons-material";
import { Button } from "@mui/material";

import COMMON_QUERY from "queries/COMMON_QUERY";
import { FulfillmentAttachment } from "types/fulfillment";
import { downloadMultiFile } from "utils/common";

export default function DownloadCI({
  selectedShippingIdList,
  shippingList,
}: {
  selectedShippingIdList: number[];
  shippingList:
    | { id: number; attachment?: FulfillmentAttachment }[]
    | undefined;
}) {
  const CIKeyList = useMemo(() => {
    if (!shippingList) {
      return [];
    }

    return (
      shippingList
        .filter((shipping) => {
          const isSelected = selectedShippingIdList.includes(shipping.id);
          const hasCI = !!shipping.attachment?.key;

          return isSelected && hasCI;
        })
        // 위에서 확인
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((shipping) => shipping.attachment!.key)
    );
  }, [selectedShippingIdList, shippingList]);

  const {
    refetch: getFileUrlList,
    ResponseHandler: ResponseHandlerOfFileUrlList,
  } = COMMON_QUERY.useGetFileUrlList({
    key: CIKeyList,
    enabled: false,
  });

  const canDownload = !!selectedShippingIdList.length;

  const handleCIDownload = () => {
    if (!canDownload) {
      return;
    }

    getFileUrlList().then(({ data }) => {
      downloadMultiFile((data?.data ?? []).map(({ url }) => url));
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<AssignmentReturned />}
        onClick={handleCIDownload}
        disabled={!canDownload}
      >
        상업송장 다운로드
      </Button>

      {ResponseHandlerOfFileUrlList}
    </>
  );
}

import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import { SETTINGS } from "../index";
import Styled from "./index.styles";

const DetailImage = ({ url }: { url: string }) => {
  const [zoom, setZoom] = useState<boolean>(false);

  return (
    <Styled.detailImage src={url} onClick={() => setZoom(!zoom)} zoom={zoom} />
  );
};

export const CommonDetailSlider = ({
  key,
  imageUrlList,
  currentSlideIndex,
}: {
  key: string;
  imageUrlList: string[];
  currentSlideIndex: number;
}) => {
  const sliderRef = useRef<Slider>(null);

  // 원 슬라이드와 상세 슬라이드의 동기화
  useEffect(() => {
    if (currentSlideIndex === undefined) return;

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentSlideIndex, true);
    }
  }, [currentSlideIndex]);

  return (
    <Slider ref={sliderRef} {...SETTINGS} key={key}>
      {imageUrlList.map((url) => (
        <div key={url}>
          <Styled.detailImageContainer>
            <DetailImage url={url} />
          </Styled.detailImageContainer>
        </div>
      ))}
    </Slider>
  );
};

import { useMemo } from "react";
import { CSVLink } from "react-csv";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

import { ShippingItemDetail } from "types/shipping";
import { toFormattedDate } from "utils/date";
import { getFormattedSingleSkuId } from "utils/fulfillment";

export default function DownloadPackingInfo({
  data,
}: {
  data: ShippingItemDetail | undefined;
}) {
  const csvData = useMemo(() => {
    const headRow = [
      "포장번호",
      "송장번호",
      "상품명",
      "SKU ID",
      "상품바코드",
      "판매자상품코드",
      "옵션관리코드",
      "수량",
    ];

    const dataRows = (() => {
      const result: (string | number)[][] = [];

      data?.packings?.forEach((packing, packingIndex) => {
        packing.packingToSku.forEach((item) => {
          const targetItemFromShippingItems = data?.items.find(
            (siv) => siv.skuId === item.stockUnitId
          );

          const packingData = [
            packingIndex + 1,
            packing.invoiceNo,
            item.stockUnit?.itemName || "",
            getFormattedSingleSkuId(item.stockUnitId),
            targetItemFromShippingItems?.sku.barCode || "",
            targetItemFromShippingItems?.sku.productCode || "",
            targetItemFromShippingItems?.sku.managementCode || "",
            item.qty,
          ];

          result.push(
            packingData.map((v) => {
              if (typeof v === "string") {
                if (v.includes('"')) {
                  return v.replace(/"/g, '""');
                }

                // CSV에서 string을 강제로 number로 바꾸는 오류가 있어서 이 작업을 해줌 (ex. 알파벳 E를 exponent로 인식 등)
                return '=""' + v + '""';
              }

              return v;
            })
          );
        });
      });

      return result;
    })();

    return [headRow, ...dataRows];
  }, [data?.items, data?.packings]);

  return (
    <CSVLink
      data={csvData}
      filename={`패킹정보-${data?.id}(${toFormattedDate(
        new Date(),
        "YYYY-MM-DD-HHmmss"
      )}).csv`}
      className="button"
    >
      <Button startIcon={<FileDownloadOutlined />} variant="outlined">
        패킹정보 받기
      </Button>
    </CSVLink>
  );
}

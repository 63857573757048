import React from "react";

import DefaultLoadingGif from "./loading.gif";
import BofulLoadingLogo from "./loading_logo_boful.png";
import Styled from "./index.styles";
import { COLOR } from "styles/constants";
import { ImageProps } from "types/common";

export default function Loading({
  label,
  active,
  Image,
  className,
  uiType = "fullscreen",
}: {
  active: boolean;
  label?: React.ReactNode;
  Image?: ({ src, alt, layout, priority }: ImageProps) => JSX.Element;
  /**
   * boful만 로딩바가 달라서 이렇게 처리함.
   */
  className?: string;
  /**
   * uiType을 전달하지 않을 경우 기본값 > fullscreen
   *
   * @example
   * fullscreen - 전체화면으로 적용 (fixed, 100vw, 100vh)
   * parentBy - 부모 기준으로 적용 (absolute, 100%, 100%)
   */
  uiType?: "fullscreen" | "parentBy";
}) {
  if (!active) {
    return null;
  }
  return (
    <Styled.bofulContainer
      active={active}
      uiType={uiType}
      className={`${className ? className : ""} loading loading-boful`}
    >
      <img src={BofulLoadingLogo} alt="loading" />

      <Styled.bofulLoader />
      {/* TODO: API 순차 호출 시 병목점이 어디인지 확인을 위하여 임시 라벨을 추가함 */}
      <div style={{ color: COLOR.white, marginTop: 20 }}>{label}</div>
    </Styled.bofulContainer>
  );
}

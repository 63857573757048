import { useCallback, useMemo } from "react";
import { TableDataListItem } from "headlessComponents/table/useTable";

import { COLOR } from "styles/constants";
import {
  ExcelItem,
  MaterialExcelItem,
  StockUnitExcelItem,
} from "types/fulfillment";
import { ValidateSkuExcelItem } from "types/receiving";
import {
  DomesticShippingExcelItem,
  KyoungDongShippingInvoiceExcelItem,
  OverseasShippingExcelItem,
  ShippingInvoiceExcelItem,
} from "types/shipping";

import Icon from "components/sds-v1/Icon";

import Styled from "./index.styles";

export interface SKUExcelTableItem {
  id: number;
  result: string;
  itemName?: JSX.Element;
  barCode?: JSX.Element;
  productCode?: JSX.Element;
  managementCode?: JSX.Element;
  packageType?: JSX.Element;
  outerPackage?: JSX.Element;
  buffer?: JSX.Element;
  tape?: JSX.Element;
  hsCode?: JSX.Element;
  value?: JSX.Element;
  returnPolicy?: JSX.Element;
}

export interface MaterialExcelTableItem {
  id: number;
  result: string;
  name?: JSX.Element;
  packageCategory?: JSX.Element;
  packageType?: JSX.Element;
  width?: JSX.Element;
  length?: JSX.Element;
  height?: JSX.Element;
  maximumLoadWeight?: JSX.Element;
  materialCode?: JSX.Element;
  managementCode?: JSX.Element;
}

export interface DomesticShippingExcelTableItem {
  id: number;
  result: string;
  channel?: JSX.Element; // 채널 직접입력
  orderNo?: JSX.Element;
  warehouse?: JSX.Element;
  skuId?: JSX.Element;
  quantity?: JSX.Element; // 주문수량
  shippingQty?: JSX.Element; // 출고수량
  availableQty?: JSX.Element; // 재고수량
  senderName?: JSX.Element;
  senderPhone?: JSX.Element;
  customerName?: JSX.Element;
  customerPhone?: JSX.Element;
  customerAddress?: JSX.Element;
  customerDetailAddress?: JSX.Element;
  customerPostalCode?: JSX.Element;
  deliveryType?: JSX.Element;
  dueDate?: JSX.Element; // 발송희망일시
  memo?: JSX.Element; // 요청사항
}

export interface OverseasShippingExcelTableItem {
  id: number;
  result: string;
  channel: JSX.Element;
  orderNo: JSX.Element;
  warehouse: JSX.Element;
  skuId: JSX.Element;
  globalSales: JSX.Element;
  quantity: JSX.Element;
  shippingQty: JSX.Element;
  availableQty: JSX.Element;
  senderName: JSX.Element;
  senderPhone: JSX.Element;
  customerName: JSX.Element;
  customerPhone: JSX.Element;
  customerCountryCode: JSX.Element;
  customerStateCode: JSX.Element;
  customerDetailAddress: JSX.Element;
  customerAddress: JSX.Element;
  customerPostalCode: JSX.Element;
  deliveryType: JSX.Element;
  dueDate: JSX.Element;
  memo: JSX.Element;
}

export interface ShippingInvoiceExcelTableItem {
  id: number;
  result: string;
  shippingId?: JSX.Element;
  parcelCompany?: JSX.Element;
  invoiceNo?: JSX.Element;
  note?: JSX.Element;
}

export interface KyoungDongShippingInvoiceExcelTableItem {
  id: number;
  result: string;
  customerPostalCode?: JSX.Element;
  customerName?: JSX.Element;
  customerPhone?: JSX.Element;
  customerAddress?: JSX.Element;
  customerDetailAddress?: JSX.Element;
  itemName?: JSX.Element;
  quantity?: JSX.Element;
  packingType?: JSX.Element;
  memo1?: JSX.Element;
  memo2?: JSX.Element;
  memo3?: JSX.Element;
  memo15?: JSX.Element;
  note?: JSX.Element;
}

export type ValidationExcelItem =
  | DomesticShippingExcelItem
  | OverseasShippingExcelItem
  | MaterialExcelItem
  | Omit<StockUnitExcelItem, "engCategory">
  | ShippingInvoiceExcelItem
  | KyoungDongShippingInvoiceExcelItem
  | ValidateSkuExcelItem;

type ExcelDataTableItem<T extends ValidationExcelItem> = {
  [P in keyof T]?: JSX.Element;
};

type ExcelDataItem<T extends ValidationExcelItem> = [
  keyof T,
  ExcelItem<string>
];

type ExcelTableItem =
  | SKUExcelTableItem
  | MaterialExcelTableItem
  | DomesticShippingExcelTableItem
  | OverseasShippingExcelTableItem
  | ShippingInvoiceExcelTableItem
  | KyoungDongShippingInvoiceExcelTableItem;

export default function useExcelTableList<T extends ValidationExcelItem>({
  excelList,
  successExcelList,
  warningExcelList,
  failureExcelList,
  shouldShowDetailAddress,
  cellOrderList,
}: {
  excelList: T[] | undefined;
  successExcelList: T[] | undefined;
  warningExcelList?: T[] | undefined;
  failureExcelList: T[] | undefined;
  shouldShowDetailAddress?: boolean;
  cellOrderList?: string[];
}) {
  // excel data list - data 원본 list
  // excel table list - table data 형식에 맞춘 list

  const getTableList = useCallback(
    (
      excelDataList?: T[],
      shouldShowDetailAddress?: boolean,
      cellOrderList?: string[]
    ): TableDataListItem<ExcelTableItem>[] => {
      if (!excelDataList) return [];

      return excelDataList.map((excelDataItem, i) => {
        const { hasError } = excelDataItem;
        const isWarning = (
          excelDataItem as DomesticShippingExcelItem | OverseasShippingExcelItem
        ).existOrderNo;

        const excelDataTableItem: ExcelDataTableItem<T> = {};

        // excel item 객체를 key, value 모두 순회하기 위해서 배열로 변환
        const excelDataArrayList = Object.entries(
          excelDataItem
        ) as unknown as ExcelDataItem<T>[];

        // cellOrderList에서 'id', 'result'는 제외 시킴(API data와 관련된 컬럼만 처리하기 위함)
        const dataCellOrderList = cellOrderList?.filter(
          (key) => !["id", "result"].includes(key)
        );

        // cellOrderList가 있는 경우 해당 순서대로 정렬
        const sortedExcelDataList: ExcelDataItem<T>[] = dataCellOrderList
          ? dataCellOrderList.map((key) => {
              const excelDataItem = excelDataArrayList.find(
                ([excelDataKey]) => excelDataKey === key
              );

              if (!excelDataItem) {
                return [key, { isValid: false, value: "" }] as ExcelDataItem<T>;
              }

              return excelDataItem;
            })
          : excelDataArrayList;

        // data list 를 table item 에 맞는 형식으로 변환
        sortedExcelDataList.forEach(([key, { isValid, value }]) => {
          if (key === "hasError") return;
          // 구매처 임시 삭제
          if (key === "buyerDirectInput") {
            return;
          }
          // 상세주소 삭제(단, 해외출고의 경우 도시명이므로 삭제하면 안 됨)
          if (!shouldShowDetailAddress && key === "customerDetailAddress") {
            return;
          }

          if (key === "availableQty") {
            excelDataTableItem[key] = (
              <>
                <Styled.columnBlock isValid={isValid} hasValue={!!value}>
                  {value}
                </Styled.columnBlock>

                {!isValid && (
                  <Icon
                    type="circleFilledError"
                    color={COLOR.pointWarning}
                    size={1}
                    className="available-qty-warning-icon"
                  />
                )}
              </>
            );
            return;
          }

          if (key === "globalSales") {
            excelDataTableItem[key] = (
              <Styled.columnBlock isValid={isValid} hasValue={!!value}>
                {value === "Y" ? "Y" : "N"}
              </Styled.columnBlock>
            );
            return;
          }

          if (key === "channel" || key === "orderNo") {
            const isValidChannelAndOrderNo = !isWarning && !!value;

            excelDataTableItem[key] = (
              <Styled.columnBlock
                isValid={isValidChannelAndOrderNo}
                hasValue={!!value}
              >
                {value}
              </Styled.columnBlock>
            );
            return;
          }

          excelDataTableItem[key] = (
            <Styled.columnBlock isValid={isValid} hasValue={!!value}>
              {value || (isValid ? "" : "(내용없음)")}
            </Styled.columnBlock>
          );
        });

        if (hasError) {
          return {
            rowKey: i,
            id: i + 1,
            result: "에러",
            ...excelDataTableItem,
            rowBackgroundColor: COLOR.pointWarning_20,
          };
        } else if (isWarning) {
          return {
            rowKey: i,
            id: i + 1,
            result: "경고",
            ...excelDataTableItem,
            rowBackgroundColor: COLOR.amazoneYellow_20,
          };
        } else {
          return {
            rowKey: i,
            id: i + 1,
            result: "정상",
            ...excelDataTableItem,
            rowBackgroundColor: COLOR.pointSuccess_20,
          };
        }
      });
    },
    []
  );

  const excelTableList = useMemo(
    () => getTableList(excelList, shouldShowDetailAddress, cellOrderList),
    [cellOrderList, excelList, getTableList, shouldShowDetailAddress]
  );

  const successExcelTableList = useMemo(
    () =>
      getTableList(successExcelList, shouldShowDetailAddress, cellOrderList),
    [cellOrderList, getTableList, shouldShowDetailAddress, successExcelList]
  );

  const warningExcelTableList = useMemo(
    () =>
      getTableList(warningExcelList, shouldShowDetailAddress, cellOrderList),
    [cellOrderList, getTableList, shouldShowDetailAddress, warningExcelList]
  );

  const failureExcelTableList = useMemo(
    () =>
      getTableList(failureExcelList, shouldShowDetailAddress, cellOrderList),
    [cellOrderList, failureExcelList, getTableList, shouldShowDetailAddress]
  );

  return {
    excelTableList,
    successExcelTableList,
    warningExcelTableList,
    failureExcelTableList,
  };
}

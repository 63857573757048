import { useMemo } from "react";

import { toThousandUnitFormat } from "utils/number";

import { InventoryDetailModifyForm } from "./useFormState";

export default function ItemDifference({
  locationId,
  formState,
}: {
  locationId: number | string;
  formState: InventoryDetailModifyForm | null;
}) {
  const target = formState?.inventory.get(locationId);

  const { difference, isPositive, fontColor } = useMemo(() => {
    const value = (target?.adjustmentQty || 0) - (target?.originQty || 0);

    const isPositive = value > 0;
    const isNegative = value < 0;
    const isSame = value === 0;

    const difference = isPositive ? `+${value}` : `${value}`;

    const fontColor = (() => {
      if (isPositive) return "blue";

      if (isNegative) return "red";

      return "black";
    })();

    return { difference, isSame, isNegative, isPositive, fontColor };
  }, [target?.adjustmentQty, target?.originQty]);

  if (!target) return null;

  return (
    <div style={{ color: fontColor }}>{toThousandUnitFormat(difference)}</div>
  );
}

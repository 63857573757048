import { useMemo } from "react";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import useNetworkPrint from "./useNetworkPrint";

/**
 * 단일 출고건에 대한 송장 출력 단계 중 '채번, 출력 데이터 요청, 출력 요청'을 처리
 */
export default function usePrintInvoiceForIndividual(params?: {
  hidesLoading?: boolean;
}) {
  const {
    mutate: numberInvoice,
    ResponseHandler: ResponseHandlerOfInvoiceNumbering,
    reset: resetInvoiceNumbering,
  } = SHIPPING_QUERY.useInvoiceNumbering({
    onSuccess: (res) =>
      getInvoicePrintingData({ shippingIds: res.shippingIds }),
  });

  const {
    mutate: getInvoicePrintingData,
    ResponseHandler: ResponseHandlerOfGetInvoicePrintingData,
    reset: resetGetInvoicePrintingData,
  } = SHIPPING_QUERY.useGetInvoicePrintingData({
    onSuccess: (res) => {
      print(res);
    },
  });

  const {
    print,
    LoadingToNetworkPrint,
    isRequestPrintingComplete,
    resetPrint,
  } = useNetworkPrint({
    hidesLoading: params?.hidesLoading,
  });

  const ResponseHandlerOfPrintInvoice = useMemo(() => {
    return (
      <>
        {ResponseHandlerOfInvoiceNumbering}
        {ResponseHandlerOfGetInvoicePrintingData}
        {LoadingToNetworkPrint}
      </>
    );
  }, [
    ResponseHandlerOfInvoiceNumbering,
    ResponseHandlerOfGetInvoicePrintingData,
    LoadingToNetworkPrint,
  ]);

  return {
    printInvoice: numberInvoice,
    ResponseHandlerOfPrintInvoice,
    isRequestPrintingComplete,
    resetPrintInvoice: () => {
      resetInvoiceNumbering();
      resetGetInvoicePrintingData();
      resetPrint();
    },
  };
}

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";

import USER_QUERY from "queries/USER_QUERY";

import Detail, { DetailProps } from "pages/user/Detail";
import CopyOTPButton from "./CopyOTPButton";

export default function CustomerDetailModal({
  userId,
  onClose,
}: {
  userId: number | undefined;
  onClose: () => void;
}) {
  const {
    data: { id, email, name, phone, teams, company } = {},
    ResponseHandler: ResponseHandlerOfCustomerDetail,
  } = USER_QUERY.useGetCustomerDetail({
    userId,
    enabled: !!userId,
  });

  const {
    mutate: getCustomerOTP,
    data: customerOTP,
    ResponseHandler: ResponseHandlerOfGetCustomerOTP,
    isLoading: isLoadingOfGetCustomerOTP,
  } = USER_QUERY.useGetCustomerOTP();

  const handleIssueOTPClick = () => {
    getCustomerOTP({ targetEmail: email ?? "" });
  };

  const customerDetail: DetailProps = {
    title: "고객 정보",
    detailList: [
      {
        label: "소속회사",
        value: company,
      },
      {
        label: "고객 번호",
        value: id,
      },
      {
        label: "이메일",
        value: email,
      },
      {
        label: "이름",
        value: name,
      },
      {
        label: (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={handleIssueOTPClick}
              disabled={
                !email || !!customerOTP?.token || isLoadingOfGetCustomerOTP
              }
            >
              OTP 발급
            </Button>
            <CopyOTPButton OTPValue={customerOTP?.token ?? ""} />
          </>
        ),
        value: customerOTP?.token ?? "",
      },
      {
        label: "연락처",
        value: phone,
      },
    ],
  };

  const teamDetailList: DetailProps[] = (teams ?? []).map(
    ({
      id,
      masterName,
      name,
      masterPhone,
      fulfillmentManagerName,
      masterEmail,
    }) => ({
      title: "소속팀 정보",
      detailList: [
        {
          label: "팀 번호",
          value: id,
        },
        {
          label: "마스터 이름",
          value: masterName,
        },
        {
          label: "팀 이름",
          value: name,
        },
        {
          label: "마스터 연락처",
          value: masterPhone,
        },
        {
          label: "풀필먼트 담당자",
          value: fulfillmentManagerName,
        },
        {
          label: "마스터 이메일",
          value: masterEmail,
        },
      ],
    })
  );

  return (
    <>
      <Dialog open={!!userId} onClose={onClose} maxWidth={"lg"}>
        <DialogTitle>고객 상세 정보</DialogTitle>

        <DialogContent dividers>
          <Stack gap={3} divider={<Divider flexItem />}>
            <Detail {...customerDetail} />

            {teamDetailList.map((teamDetail, index) => (
              <Detail key={index} {...teamDetail} />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>

      {ResponseHandlerOfCustomerDetail}
      {ResponseHandlerOfGetCustomerOTP}
    </>
  );
}

import { useMemo } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";

import {
  GET_RECEIVING_DETAIL_BY_SKU_ID_RES_ITEM,
  RECEIVING_DETAIL_BY_SKU_ID_PLACE_ITEM,
} from "api-interfaces/receiving";
import { toThousandUnitFormat } from "utils/number";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

type CellId = keyof RECEIVING_DETAIL_BY_SKU_ID_PLACE_ITEM;

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "barCode",
    disablePadding: false,
    label: "로케이션 바코드",
    width: 120,
  },
  {
    id: "placeQty",
    disablePadding: false,
    label: "입고수량",
    width: 100,
  },
];

export default function ReceivingDetailBySkuId({
  data,
}: {
  data: GET_RECEIVING_DETAIL_BY_SKU_ID_RES_ITEM;
}) {
  const mapDataForTable = (
    data: RECEIVING_DETAIL_BY_SKU_ID_PLACE_ITEM[] | undefined
  ): TableBodyRow<CellId>[] => {
    if (!data) {
      return [];
    }

    return data.map(({ barCode, placeQty }) => {
      return { barCode, placeQty: toThousandUnitFormat(placeQty) };
    });
  };

  const rows = useMemo(() => {
    return mapDataForTable(data.placeItems);
  }, [data.placeItems]);

  return (
    <>
      <Paper variant="outlined" sx={{ mt: 2, p: 2, maxWidth: "500px" }}>
        <Stack spacing={2}>
          <List>
            <ListItem>
              <ListItemText
                primary="의뢰번호"
                secondary={data.bidId || `A${data.receivingId}`}
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="SKU ID"
                secondary={getFormattedSingleSkuId(data.skuId)}
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="입고 수량"
                secondary={toThousandUnitFormat(data.placeQty)}
              />
            </ListItem>

            <Divider />
          </List>
          <Table<CellId> headCells={headCells} rows={rows} minWidth={0} />
        </Stack>
      </Paper>
    </>
  );
}

import { CustomArrowProps } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { COLOR } from "styles/constants";

import FourChevronLeftIcon from "_sds-v2/components/svgIcons/FourChevronLeftIcon";
import FourChevronRightIcon from "_sds-v2/components/svgIcons/FourChevronRightIcon";

export const PrevArrow = (props?: CustomArrowProps) => {
  if (!props) return null;

  const { className, onClick, currentSlide } = props;

  const isFirstSlide = currentSlide === 0;

  return (
    <div className={className} style={{ display: "block" }} onClick={onClick}>
      <FourChevronLeftIcon
        color={isFirstSlide ? COLOR.grayScale_300 : COLOR.primaryBlue}
        width={16}
        height={16}
      />
    </div>
  );
};

export const NextArrow = (props?: CustomArrowProps) => {
  if (!props) return null;

  const { className, onClick, currentSlide, slideCount = 1 } = props;

  const isLastSlide = currentSlide === slideCount - 1;

  return (
    <div className={className} style={{ display: "block" }} onClick={onClick}>
      <FourChevronRightIcon
        color={isLastSlide ? COLOR.grayScale_300 : COLOR.primaryBlue}
        width={16}
        height={16}
      />
    </div>
  );
};

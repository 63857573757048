import { useMemo } from "react";
import SKU_QUERY from "queries/SKU_QUERY";

import { COLOR } from "styles/constants";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { replaceEmptyToDash } from "utils/string";

import Icon from "components/sds-v1/Icon";

import CommonStyled from "../../index.styles";

export default function useSkuList({
  page,
  perPage,
  teamId,
  searchTerm,
  selectedSkuId,
  handleSkuSelect,
  enabled,
}: {
  page: number;
  perPage: number;
  teamId: number;
  searchTerm?: string;
  selectedSkuId?: number;
  handleSkuSelect: (id: number) => void;
  enabled: boolean;
}) {
  const { data: skuData, ResponseHandler: ResponseHandlerOfSkuList } =
    SKU_QUERY.useGetSingleSkuSearchList({
      page,
      perPage,
      teamId,
      ...(searchTerm ? { id: searchTerm } : {}),
      enabled,
    });

  const skuTableList = useMemo(() => {
    if (!skuData) return [];

    return skuData.list.map((sku) => {
      const selected = selectedSkuId === sku.id;

      return {
        rowKey: sku.id,
        select: (
          <Icon
            type={selected ? "radioSelected" : "radio"}
            color={selected ? COLOR.primaryBlue : COLOR.grayScale_400}
            size={1.5}
            onClick={() => handleSkuSelect(sku.id)}
          />
        ),
        skuId: getFormattedSingleSkuId(sku.id),
        itemName: <div className="item-name">{sku.itemName}</div>,
        barCode: (
          <CommonStyled.limitedWidthEllipsisText maxWidth="110px">
            {replaceEmptyToDash(sku.barCode)}
          </CommonStyled.limitedWidthEllipsisText>
        ),
      };
    });
  }, [handleSkuSelect, selectedSkuId, skuData]);

  return {
    skuTableList,
    skuTotalCount: skuData?.total,
    ResponseHandlerOfSkuList,
  };
}

import styled from "styled-components";

import { ValueType } from ".";
import { COLOR } from "styles/constants";

const container = styled.div<{ depth: number; valueType: ValueType }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: ${({ depth }) => (depth === 1 ? "8px" : "32px")};

  > .list-item {
    flex: 1;
  }

  .custom-list-item .value {
    align-items: center;

    color: ${({ valueType }) => {
      switch (valueType) {
        case "normal":
          return COLOR.primaryBlue;
        case "warning":
          return COLOR.pointWarning;
        default:
          return COLOR.grayScale_800;
      }
    }};

    // ListItem 컴포넌트 내에서 버튼 스타일이 커스텀 되어있어, 세로 사이즈를 해당 컴포넌트에 맞추기 위해 조정함.
    > .button {
      height: 24px;
    }
  }
`;

export default {
  container,
};

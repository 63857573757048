import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import ExpressList from "./ExpressList";

export type OverseasCategory = "EXPRESS";

function ShippingOverseasList() {
  return (
    <Layout>
      <RadioGroup
        row
        aria-labelledby="delivery-category"
        name="delivery-category"
        value={"overseasExpress"}
      >
        <FormControlLabel
          value="overseasExpress"
          control={<Radio size="small" />}
          label="특송"
        />
      </RadioGroup>

      <ExpressList deliveryCategory="overseasExpress" />
    </Layout>
  );
}

export default withRequireAuth(ShippingOverseasList);

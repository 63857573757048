import { useMemo, useState } from "react";
import { useAtom } from "jotai";
import { FULFILLMENT_INSPECTION_ATOMS } from "jotaiStates/inspection";

import { COLOR } from "styles/constants";
import { Overwrite } from "types/customUtilityTypes";
import { UserReturningDetailItem } from "types/returning";
import { ProcessStatus as ProcessStatusType } from "types/returning";
import { checkUsesManagementDate } from "utils/common";
import {
  checkForProblemItem,
  getFormattedSingleSkuId,
} from "utils/fulfillment";
import { calculateDifference, toThousandUnitFormat } from "utils/number";
import { replaceEmptyToDash } from "utils/string";

import Table from "components/sds-v1/table/Table";

import CommonStyled from "../index.styles";
import ProblemMemo from "../ProblemMemo";
import ProcessStatus from "../ProcessStatus";
import ProblemInfoModal from "./ProblemInfoModal";

type ReceivingItemForInspectionTable = Overwrite<
  UserReturningDetailItem,
  // 하나의 SKU에 여러 개의 관리일자가 존재할 수 있기 때문에 배열로 지정
  { processStatus: ProcessStatusType[] }
>;
// 비정상 상품
export default function InspectionListExcludingUnverifiedItem({
  primaryId,
  list,
  isWarehousingStatus,
  isDecidedQuantityStatus,
}: {
  primaryId: string | number;
  list: UserReturningDetailItem[];
  isWarehousingStatus: boolean;
  isDecidedQuantityStatus: boolean;
}) {
  const [agreementInfoList, setAgreementInfoList] = useAtom(
    FULFILLMENT_INSPECTION_ATOMS.AGREEMENT_INFO_LIST_FOR_INSPECTION
  );

  const [problemDetailInfoList, setProblemDetailInfoList] = useState<
    UserReturningDetailItem[]
  >([]);

  const usesManagementDate = problemDetailInfoList.some(
    checkUsesManagementDate
  );

  const openProblemDetailModal =
    (itemListBySkuId: UserReturningDetailItem[]) => () => {
      setProblemDetailInfoList(itemListBySkuId);
    };

  const closeProblemDetailModal = () => {
    setProblemDetailInfoList([]);
  };

  // 검수 리스트 중 비정상 상품을 같이 표기한 테이블 리스트.
  const highlightedProblemsInspectionTableList = useMemo(() => {
    /**
     * 관리일자를 기준으로 나뉘어진 items를 SKU ID 기준으로 묶어서 하나의 행으로 만듦
     * id는 첫 번째 상품의 id로 지정 (rowKey로 사용되기 때문에 중복되지만 않으면 됨)
     * processStatus는 변수 네이밍 컨벤션과는 다르지만, 여러 값을 묶기 위해 배열로 변경함
     */
    const groupedListBySkuId: ReceivingItemForInspectionTable[] = list.reduce<
      ReceivingItemForInspectionTable[]
    >((acc, cur) => {
      const existingItemIndex = acc.findIndex(
        (item) => item.skuId === cur.skuId
      );
      const isProblemItem = checkForProblemItem(cur);

      if (existingItemIndex !== -1) {
        const existingItem = acc[existingItemIndex];

        const updatedItem = {
          ...existingItem,
          quantity: (existingItem.quantity ?? 0) + (cur.quantity ?? 0),
          actualQty: (existingItem.actualQty ?? 0) + (cur.actualQty ?? 0),
          placeQty: (existingItem.placeQty ?? 0) + (cur.placeQty ?? 0),
          // processStatus 뱃지는 '비정상 상품'을 기준으로 표시한다.
          processStatus: isProblemItem
            ? [...existingItem.processStatus, cur.processStatus]
            : existingItem.processStatus,
          inspectProblems: [
            ...existingItem.inspectProblems,
            ...cur.inspectProblems,
          ],
        };

        return [
          ...acc.slice(0, existingItemIndex),
          updatedItem,
          ...acc.slice(existingItemIndex + 1),
        ];
      }

      return [
        ...acc,
        {
          ...cur,
          // id는 첫 번째 상품의 id로 지정됨
          // processStatus 뱃지는 '비정상 상품'을 기준으로 표시한다.
          processStatus: isProblemItem ? [cur.processStatus] : [],
        },
      ];
    }, []);

    return groupedListBySkuId.map((groupedItem) => {
      const selectedAgreementInfoProcessStatus = agreementInfoList
        .filter(
          // processStatus 뱃지는 '비정상 상품'을 기준으로 표시한다.
          (item) => item.type === "problem" && item.skuId === groupedItem.skuId
        )
        // 리스팅 할 때에는 processStatus값이 지정되어 있음
        .map((item) => item.processStatus as ProcessStatusType);

      const currentProcessStatusList =
        selectedAgreementInfoProcessStatus || groupedItem.processStatus;

      // 관리일자 여러 개로 나뉘어진 경우 모든 processStatus를 임시저장 없이 한꺼번에 지정하기 때문에 첫 번째 요소만 확인하면 됨
      const isSelected =
        currentProcessStatusList[0] === "stock" ||
        currentProcessStatusList[0] === "faulty";

      const problemList = groupedItem.inspectProblems;

      const isDifferentQuantity = isDecidedQuantityStatus
        ? !!calculateDifference(groupedItem.quantity, groupedItem.actualQty) ||
          !!calculateDifference(groupedItem.actualQty, groupedItem.placeQty)
        : !!calculateDifference(groupedItem.quantity, groupedItem.actualQty);

      const passedInspection = !problemList.length;

      // 수량만 불일치하고, 검수결과에는 이상없는 경우. (비정상 상품 없음)
      const isOnlyDifferentQuantity =
        isDifferentQuantity && !problemList.length;

      // 검수 결과 이상이 있는 비정상 상품이거나 아직 처리방법을 선택하지 않은 경우 하이라이팅.
      const isHighlighted = !(passedInspection || isSelected);

      // 비정상 처리 모달에는 '비정상 상품 문제 보고'가 된 상품만 표시
      const itemListBySkuId = list.filter((item) => {
        const isSameSkuId = item.skuId === groupedItem.skuId;
        const hasProblem = item.inspectProblems.length > 0;

        return isSameSkuId && hasProblem;
      });

      return {
        rowKey: groupedItem.id,
        rowBackgroundColor: isHighlighted ? COLOR.pointWarning_20 : undefined,

        problemStatus: (
          <ProcessStatus
            processStatusList={currentProcessStatusList}
            handleDetailModalOpen={openProblemDetailModal(itemListBySkuId)}
            isWarehousingStatus={isWarehousingStatus}
            passedInspection={passedInspection}
            isOnlyDifferentQuantity={isOnlyDifferentQuantity}
          />
        ),
        skuId: getFormattedSingleSkuId(groupedItem.skuId),
        itemName: (
          <CommonStyled.limitedWidthEllipsisText maxWidth="238px">
            {groupedItem.sku?.itemName}
          </CommonStyled.limitedWidthEllipsisText>
        ),
        barCode: (
          <CommonStyled.limitedWidthEllipsisText maxWidth="80px">
            {replaceEmptyToDash(groupedItem.sku?.barCode)}
          </CommonStyled.limitedWidthEllipsisText>
        ),
        receivingQuantity: replaceEmptyToDash(
          toThousandUnitFormat(groupedItem.quantity),
          true
        ),
        inspectionQuantity: replaceEmptyToDash(
          toThousandUnitFormat(groupedItem.actualQty),
          true
        ),
        totalReceivingQuantity: isDecidedQuantityStatus
          ? replaceEmptyToDash(toThousandUnitFormat(groupedItem.placeQty), true)
          : "-",
        problemMemo: (
          <ProblemMemo
            quantity={groupedItem.quantity}
            actualQty={groupedItem.actualQty}
            placeQty={groupedItem.placeQty}
            problemList={problemList}
            isDecidedQuantityStatus={isDecidedQuantityStatus}
          />
        ),
      };
    });
  }, [agreementInfoList, isDecidedQuantityStatus, isWarehousingStatus, list]);

  return (
    <>
      <Table
        rowMinHeight="54px"
        columnInfo={{
          problemStatus: {
            label: "비정상 상품 처리",
            fixedWidth: 176,
          },
          skuId: {
            label: "SKU ID",
            fixedWidth: 96,
          },
          itemName: {
            label: "상품명",
            fixedWidth: 274,
          },
          barCode: {
            label: "상품 바코드",
            fixedWidth: 96,
          },
          receivingQuantity: {
            label: "입고요청수량(PCS)",
            fixedWidth: 136,
          },
          inspectionQuantity: {
            label: "검수수량(PCS)",
            fixedWidth: 136,
          },
          totalReceivingQuantity: {
            label: "입고수량(PCS)",
            fixedWidth: 136,
          },
          problemMemo: {
            label: "문제발생",
            fixedWidth: 300,
          },
        }}
        dataList={highlightedProblemsInspectionTableList}
      />

      {problemDetailInfoList.length > 0 && (
        <ProblemInfoModal
          usesManagementDate={usesManagementDate}
          primaryId={primaryId}
          problemDetailInfoList={problemDetailInfoList}
          closeProblemDetailModal={closeProblemDetailModal}
          isWarehousingStatus={isWarehousingStatus}
          isDecidedQuantityStatus={isDecidedQuantityStatus}
        />
      )}
    </>
  );
}

import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "queries/RECEIVING_QUERY";

import Calendar, { CalendarInputValue } from "components/Calendar";

export default function ChangeDueDate({
  receivingId,
  expectedDate,
  dueDate,
}: {
  receivingId: number;
  expectedDate: string;
  dueDate: string | null;
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState(dueDate);
  const [isValid, setIsValid] = useState(false);

  const queryClient = useQueryClient();

  const {
    mutate: changeDueDate,
    ResponseHandler: ResponseHandlerOfChangeDueDate,
  } = RECEIVING_QUERY.useUpdateAdminReceivingDetail({
    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고기한 수정 중에 오류가 발생했습니다.",
      }),
    },
  });

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setSelectedDueDate(dueDate);
    setIsValid(false);
  };

  const handleDueDateSelect = (selectedDueDate: CalendarInputValue) => {
    setSelectedDueDate(selectedDueDate);
  };

  const handleDueDateChange = () => {
    if (!selectedDueDate) {
      return;
    }

    changeDueDate(
      {
        dueDate: selectedDueDate,
        pathParams: { receivingId },
      },
      {
        onSuccess: () => {
          setIsOpened(false);

          queryClient.invalidateQueries(
            RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
              receivingId,
            })
          );
        },
      }
    );
  };

  return (
    <>
      <Button sx={{ ml: 2 }} variant="outlined" onClick={handleOpen}>
        변경
      </Button>

      <Dialog open={isOpened} onClose={handleClose}>
        <DialogTitle>입고기한 변경</DialogTitle>

        <DialogContent>
          <Calendar
            type="datePicker"
            value={selectedDueDate}
            onChange={handleDueDateSelect}
            minDate={expectedDate}
            holidayOption="disabled"
            setIsValid={setIsValid}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            disabled={!isValid}
            onClick={handleDueDateChange}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfChangeDueDate}
    </>
  );
}

import { useCallback, useMemo, useState } from "react";
import { Button } from "@mui/material";
import {
  GET_ADMIN_RECEIVING_LIST_REQ_DATE_KIND,
  GET_ADMIN_RECEIVING_LIST_REQ_SEARCH_KIND,
} from "api-interfaces/receiving";
import RECEIVING_QUERY from "queries/RECEIVING_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useMultiSelect from "hooks/useMultiSelect";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "hooks/useSearchWithDateForBofulAdminOnly";
import { TermSearchType } from "hooks/useSearchWithTermForBufulAdminOnly";
import useSearchWithTerm from "hooks/useSearchWithTermForBufulAdminOnly";
import { ReceivingListItem } from "types/receiving";
import { getGroupedSearchListByProperty } from "utils/common";
import { toFormattedDate } from "utils/date";
import { getPayloadByFilter, getSKUItemTypeQuantity } from "utils/fulfillment";
import { getReceivingStatusString } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import {
  getAdminMemo,
  getAdminTransportNumber,
  getBLNumber,
  getFormattedReceivingId,
  getReceivingDeliveryTitle,
  getReceivingExpectedDate,
  getReceivingItemTitle,
  getReceivingKindLabel,
  getTransportMethodTitle,
  getTransportNumber,
  makeCbmAndWeight,
  makeInvoiceNoLabels,
  makeInvoiceNoToDisplay,
} from "utils/receiving";

import MemoizedTable, {
  GroupedHeadRow,
  TableBodyRow,
  TableHeadCell,
} from "components/MemoizedTable";

import TransportNumberWithTooltip from "../TransportNumberWithTooltip";
import useMemoModal from "../useMemoModal";
import useReceivingAdminMemo from "../useReceivingAdminMemo";
import useReceivingDetailModal from "../useReceivingDetailModal";
import useReceivingKindFilter from "../useReceivingKindFilter";
import useStatusFilter from "../useStatusFilter";

const pinnedColumnList = [{ id: "receivingId", prevColumnWidth: 74 }];

const termSearchTypeOptions: TermSearchType<GET_ADMIN_RECEIVING_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "SKU ID",
      value: "skuId",
    },
    {
      label: "상품바코드",
      value: "barCode",
    },
    {
      label: "판매자상품코드",
      value: "productCode",
    },
    {
      label: "옵션관리코드",
      value: "managementCode",
    },
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "상품명",
      value: "itemName",
    },
    {
      label: "의뢰번호",
      value: "id",
    },
    {
      label: "송장번호(택배)",
      value: "invoiceNo",
    },
    {
      label: "차량번호(화물차량)",
      value: "truckNo",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<GET_ADMIN_RECEIVING_LIST_REQ_DATE_KIND>[] =
  [
    {
      label: "등록일시",
      value: "createdAt",
    },
    {
      label: "도착요청일",
      value: "expectedDate",
    },
    {
      label: "도착완료일",
      value: "receivedAt",
    },
    {
      label: "입고기한",
      value: "dueDate",
    },
  ];

type CellId =
  | keyof ReceivingListItem
  | "companyName"
  | "teamName"
  | "customerCode"
  | "transportMethod"
  | "transportType"
  | "transportNumber"
  | "itemName"
  | "skuQty"
  | "productType"
  | "cbm"
  | "weight"
  | "checkbox"
  | "receivingId";

type GroupedHeadId =
  | "checkbox"
  | "default"
  | "transport"
  | "packing"
  | "product"
  | "inspection"
  | "warehousing"
  | "problems"
  | "memo"
  | "adminMemo";

const groupedHeadRow: GroupedHeadRow<GroupedHeadId, CellId> = [
  {
    id: "default",
    label: "기본정보",
    headCellIds: [
      "receivingId",
      "receivingKind",
      "createdAt",
      "expectedDate",
      "dueDate",
      "companyName",
      "teamName",
      "customerCode",
      "status",
    ],
  },
  {
    id: "transport",
    label: "운송정보",
    headCellIds: [
      "warehouse",
      "transportMethod",
      "transportType",
      "transportNumber",
      "receivedAt",
    ],
  },
  {
    id: "packing",
    label: "포장정보",
    headCellIds: ["packingUnitPallet", "packingUnitBox", "packingUnitOther"],
  },
  {
    id: "product",
    label: "상품정보",
    headCellIds: ["itemName", "skuQty", "productType", "cbm", "weight"],
  },
  { id: "inspection", label: "검수정보", headCellIds: ["quantity"] },
  { id: "memo", label: "요청사항", headCellIds: ["memo"] },
  { id: "adminMemo", label: "메모", headCellIds: ["adminMemo"] },
];

function ReceivingRequests() {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  const resetCurrentPage = useCallback(() => setCurrentPage(0), []);

  const { TermSearchPanel, searchList } = useSearchWithTerm({
    pageType: "receiving",
    termSearchTypeOptions,
    resetCurrentPage,
  });

  const { DateSearchPanel, dateSearchType, dateToSearch } = useSearchWithDate({
    dateSearchTypeOptions: dateSearchTypeOptions,
    resetCurrentPage,
  });

  const { ReceivingKindFilterPanel, receivingKindPayloadListByFilter } =
    useReceivingKindFilter(resetCurrentPage);
  const { StatusFilterPanel, statusPayloadListByFilter } = useStatusFilter({
    listType: "requests",
    resetCurrentPage,
  });

  const { showReceivingDetailModal, ReceivingDetailModal } =
    useReceivingDetailModal("requests");

  const { setMemoModalData, MemoModal } = useMemoModal();

  const { showAdminMemoModal, ReceivingAdminModal } = useReceivingAdminMemo();

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
    resetCurrentPage();
  };

  const {
    data: receivingList,
    ResponseHandler: ResponseHandlerOfReceivingList,
  } = RECEIVING_QUERY.useGetAdminReceivingList({
    isDone: false,

    perPage,
    page: currentPage,

    ...getGroupedSearchListByProperty({ pageType: "receiving", searchList }),

    ...(dateSearchType && dateToSearch?.startDate && dateToSearch?.endDate
      ? {
          dateKind: dateSearchType,
          dateFrom: dateToSearch.startDate,
          dateTo: dateToSearch.endDate,
        }
      : {}),

    ...getPayloadByFilter({
      key: "receivingKind",
      payloadList: receivingKindPayloadListByFilter,
    }),
    ...getPayloadByFilter({
      key: "status",
      payloadList: statusPayloadListByFilter,
    }),

    onSuccess: (data) => {
      initSelectionDict(data.list.map((item) => item.id));
    },
  });

  const headCells = useMemo((): TableHeadCell<CellId>[] => {
    return [
      {
        id: "receivingId",
        disablePadding: false,
        label: "의뢰번호",
        width: 110,
      },
      {
        id: "receivingKind",
        disablePadding: false,
        label: "구분",
        width: 100,
        filter: ReceivingKindFilterPanel,
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "등록일시",
        width: 160,
      },
      {
        id: "expectedDate",
        disablePadding: false,
        label: "도착요청일",
        width: 140,
      },
      {
        id: "dueDate",
        disablePadding: false,
        label: "입고기한",
        width: 120,
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "회사명",
        width: 170,
      },
      {
        id: "teamName",
        disablePadding: false,
        label: "팀명",
        width: 170,
      },
      {
        id: "customerCode",
        disablePadding: false,
        label: "고객사코드",
        width: 120,
      },
      {
        id: "status",
        disablePadding: false,
        label: "입고현황",
        width: 170,
        filter: StatusFilterPanel,
      },

      {
        id: "warehouse",
        disablePadding: false,
        label: "입고지",
        width: 110,
      },
      {
        id: "transportMethod",
        disablePadding: false,
        label: "입고수단",
        width: 150,
      },
      {
        id: "transportType",
        disablePadding: false,
        label: "입고방식",
        width: 170,
      },
      {
        id: "transportNumber",
        disablePadding: false,
        label: (
          <>
            송장(차량)번호
            <br />
            B/L(AWB)
          </>
        ),
        labelForCSV: "송장(차량)번호 | B/L(AWB)",
        width: 200,
      },
      {
        id: "receivedAt",
        disablePadding: false,
        label: "도착완료일",
        width: 150,
      },

      {
        id: "packingUnitPallet",
        disablePadding: false,
        label: "팔레트",
        width: 120,
      },
      {
        id: "packingUnitBox",
        disablePadding: false,
        label: "박스",
        width: 120,
      },
      {
        id: "packingUnitOther",
        disablePadding: false,
        label: "기타",
        width: 120,
      },

      {
        id: "itemName",
        disablePadding: false,
        label: "상품명",
        width: 200,
      },
      {
        id: "skuQty",
        disablePadding: false,
        label: "종류(SKU)",
        width: 110,
      },
      {
        id: "productType",
        disablePadding: false,
        label: "유형",
        width: 150,
      },
      {
        id: "cbm",
        disablePadding: false,
        label: "부피",
        width: 100,
      },
      {
        id: "weight",
        disablePadding: false,
        label: "중량",
        width: 100,
      },

      {
        id: "quantity",
        disablePadding: false,
        label: "입고예정수량",
        width: 110,
      },

      {
        id: "memo",
        disablePadding: false,
        label: "요청사항",
        width: 120,
      },

      {
        id: "adminMemo",
        disablePadding: false,
        label: "메모",
        width: 120,
      },

      // TODO: 등록자는 아직 api와 기획에서 구현협의중
      // {
      //   id: "registrant",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "등록자",
      //   minWidth: 110,
      // },
    ];
  }, [ReceivingKindFilterPanel, StatusFilterPanel]);

  const mapDataForTable = useCallback(
    (data: ReceivingListItem[] | undefined, isForCSV?: boolean) => {
      if (!data) return [];

      return data.map((v) => {
        const transportNumber = getTransportNumber({
          receivingKind: v.receivingKind,
          delivery: v.delivery,
          invoiceNo: makeInvoiceNoToDisplay(v.invoiceNo, isForCSV),
          BL: getBLNumber(v.bid, true),
          driver: v.driver,
        });

        const { cbm, weight } = makeCbmAndWeight(v.bid?.invoiceCbm);

        const row: TableBodyRow<CellId> = {
          id: v.id,

          receivingId: getFormattedReceivingId(v),
          receivingKind: getReceivingKindLabel(v.receivingKind),
          createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD HH:mm"),
          expectedDate: getReceivingExpectedDate(v),
          dueDate: toFormattedDate(v.dueDate, "YYYY-MM-DD"),
          companyName: v.team?.company,
          teamName: v.team?.name,
          customerCode: v.team?.id,
          status: getReceivingStatusString(v.status, v.inspectionStatus),

          warehouse: v.warehouse?.name,
          transportMethod: getTransportMethodTitle({
            freightType: v.bid?.freightType,
            delivery: v.delivery,
            noNeedTransportNumber: true,
          }),
          transportType: getReceivingDeliveryTitle(v.delivery),
          transportNumber: getAdminTransportNumber({
            isForCSV,
            invoiceNo: v.invoiceNo,
            transportNumber,
            TransportNumberWithTooltip: (
              <TransportNumberWithTooltip
                invoiceNoLabels={makeInvoiceNoLabels(v.invoiceNo)}
                transportNumber={transportNumber}
              />
            ),
          }),
          receivedAt: toFormattedDate(v.receivedAt, "YYYY-MM-DD"),

          packingUnitPallet: v.packingUnitPallet ?? "-",
          packingUnitBox: v.packingUnitBox ?? "-",
          packingUnitOther: v.packingUnitOther ?? "-",

          itemName: getReceivingItemTitle({ items: v.items }),
          skuQty: toThousandUnitFormat(getSKUItemTypeQuantity(v.items)),
          productType: "상품", // TODO: 현재 상품만 존재
          cbm,
          weight,

          quantity: toThousandUnitFormat(v.quantity),

          memo: getAdminMemo({
            memo: v.memo,
            MemoButton: (
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setMemoModalData(v.memo);
                }}
              >
                요청사항
              </Button>
            ),
          }),
          adminMemo: (
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                showAdminMemoModal(v.id);
              }}
            >
              메모
            </Button>
          ),
          // registrant: "TODO:",
          handleRowClick: () => {
            showReceivingDetailModal(v.id);
          },
        };

        return row;
      });
    },
    [setMemoModalData, showAdminMemoModal, showReceivingDetailModal]
  );

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(receivingList?.list);
  }, [receivingList, mapDataForTable]);

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(
      receivingList?.list.filter(({ id }) => selectedIdList.includes(id)),
      true
    );
  }, [mapDataForTable, receivingList, selectedIdList]);

  return (
    <Layout>
      <MemoizedTable
        title="입고 요청 리스트"
        groupedHeadRow={groupedHeadRow}
        headCells={headCells}
        rows={rows}
        pinnedColumnList={pinnedColumnList}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: receivingList?.total || 0,
          perPage,
          setPerPage: handlePerPageChange,
          currentPage,
          setCurrentPage,
        }}
        toolbarItems={{
          left: [TermSearchPanel, DateSearchPanel],
        }}
        csvDownloadForSelectedRowsInfo={{
          filename: `입고 요청 리스트(${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          idsToExceptForExcelDownload: ["checkbox", "memo", "adminMemo"],
          groupedHeadRowIdsToExceptForExcelDownload: [
            "checkbox",
            "memo",
            "adminMemo",
          ],
          rowsForCSVDownload,
        }}
        selectionDict={selectionDict}
        select={select}
        unSelect={unSelect}
        selectAll={selectAll}
        unSelectAll={unSelectAll}
        selectedCount={selectedCount}
        isAllSelected={isAllSelected}
      />

      {ResponseHandlerOfReceivingList}

      {ReceivingDetailModal}

      {MemoModal}

      {ReceivingAdminModal}
    </Layout>
  );
}

export default withRequireAuth(ReceivingRequests);

import { useCallback, useMemo } from "react";

import { toThousandUnitFormat } from "utils/number";

import Styled from "../index.styles";

export default function useTotalDifference({
  totalAvailableQty,
  newTotalAvailableQty,
}: {
  totalAvailableQty: number;
  newTotalAvailableQty: number;
}) {
  const { difference, isSame, isNegative, isPositive } = useMemo(() => {
    const value = (newTotalAvailableQty || 0) - (totalAvailableQty || 0);

    const isPositive = value > 0;
    const isNegative = value < 0;
    const isSame = value === 0;

    const difference = isPositive ? `+${value}` : `${value}`;

    return { difference, isSame, isNegative, isPositive };
  }, [newTotalAvailableQty, totalAvailableQty]);

  const Summary = useCallback(() => {
    return (
      <Styled.totalDifference>
        <div className="item">
          <div className="label">기존 가용 수량 합:</div>
          <div className="value">{toThousandUnitFormat(totalAvailableQty)}</div>
        </div>

        <div className="item">
          <div className="label">조정 후 수량 합:</div>
          <div className="value">
            {toThousandUnitFormat(newTotalAvailableQty)}
          </div>
        </div>

        <div className="total">
          {isSame && <span>동일</span>}

          {isPositive && (
            <span style={{ color: "blue" }}>
              {toThousandUnitFormat(difference)}
            </span>
          )}

          {isNegative && (
            <span style={{ color: "red" }}>
              {toThousandUnitFormat(difference)}
            </span>
          )}
        </div>
      </Styled.totalDifference>
    );
  }, [
    difference,
    isNegative,
    isPositive,
    isSame,
    newTotalAvailableQty,
    totalAvailableQty,
  ]);

  return { difference, isSame, isNegative, isPositive, Summary };
}

import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
} from "@mui/material";

import { ReturningListItem } from "types/returning";
import { toFormattedDate } from "utils/date";
import {
  getDeliveryName,
  getDeliveryNumberOfShipping,
} from "utils/fulfillment";
import {
  checkNeedToDisplayReturningInvoice,
  getDeliveryNumberOfReturning,
  getReturningType,
  toDeliveryTypeLabel,
} from "utils/returning";

import EditSenderDetail from "../EditSenderDetail";

export default function Summary({
  returningDetailData,
}: {
  returningDetailData: ReturningListItem | undefined;
}) {
  if (!returningDetailData) return null;

  const {
    createdAt,
    shipping,
    delivery,
    driver,
    returningPackings,
    invoiceNo,
    applicantName,
    mobile,
    status,
    deliveringStatus,
    requests,
    isAlreadyApplied,
  } = returningDetailData;

  const needToDisplayReturningInvoice =
    checkNeedToDisplayReturningInvoice(invoiceNo);

  // 미집화상태 / 반품접수완료 / 센터 배송중 / 센터도착 상태일 때 수정 가능
  const showsEditSenderDetailButton =
    status === "beforeReturning" &&
    (deliveringStatus === "notSent" ||
      deliveringStatus === "uncollected" ||
      deliveringStatus === "delivering" ||
      deliveringStatus === "done");

  return (
    <Paper variant="outlined" sx={{ mt: 2 }}>
      <List
        subheader={
          <ListSubheader sx={{ typography: "h6", p: 2 }} disableSticky>
            <Stack flexDirection="row" justifyContent="space-between">
              반품배송정보
              {showsEditSenderDetailButton && (
                <EditSenderDetail returningDetail={returningDetailData} />
              )}
            </Stack>
          </ListSubheader>
        }
      >
        <Divider />

        <ListItem>
          <ListItemText primary="출고요청번호" secondary={shipping?.id} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary="반품신청일자"
            secondary={`${toFormattedDate(createdAt, "YYYY-MM-DD")}`}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary="원택배사(화물차종)"
            secondary={getDeliveryName({
              deliveryType: shipping?.deliveryType,
              parcelCompany: shipping?.parcelCompany,
              truckType: shipping?.trucking?.truckType,
            })}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary="원송장번호(차량번호)"
            secondary={getDeliveryNumberOfShipping({
              deliveryType: toDeliveryTypeLabel(delivery),
              packings: shipping?.packings,
              trucking: shipping?.trucking,
              invoiceNo: shipping?.invoiceNo,
              isFullList: true,
            })}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary="택배수거방식"
            secondary={getReturningType(isAlreadyApplied)}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary="반품택배사(화물차종)"
            secondary={
              needToDisplayReturningInvoice
                ? returningPackings
                    ?.map((returningPacking) =>
                      getDeliveryName({
                        deliveryType: toDeliveryTypeLabel(delivery),
                        parcelCompany: returningPacking.parcelCompany,
                        truckType: driver?.freightType,
                      })
                    )
                    .join(", ")
                : ""
            }
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary="반품송장번호(차량번호)"
            secondary={
              needToDisplayReturningInvoice
                ? getDeliveryNumberOfReturning({
                    deliveryType: toDeliveryTypeLabel(delivery),
                    packings: returningPackings,
                    invoiceNo: invoiceNo,
                    truckNo: driver?.truckNo,
                    isFullList: true,
                  })
                : ""
            }
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="반품 보낸 분" secondary={applicantName} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="반품 보낸 분 연락처" secondary={mobile} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="요청사항" secondary={requests} />
        </ListItem>
      </List>
    </Paper>
  );
}

import React, { useCallback, useMemo, useRef } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { GET_ADMIN_SHIPPING_LIST_REQ } from "api-interfaces/shipping";
import useUploadFile from "hooks/useUploadFile";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "states/auth";
import { FileMimeType } from "types/file";
import { getActorKindByUserAuthority } from "utils/file";

export default function useUploadShippingInvoiceExcel({
  onModalClose,
  ...params
}: {
  onModalClose: () => void;
} & GET_ADMIN_SHIPPING_LIST_REQ) {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const currentUserAuthority = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.authority;

  const queryClient = useQueryClient();

  const {
    data: shippingExcelInvoiceList,
    successExcelList,
    failureExcelList,
    mutate: validateShippingExcelInvoiceList,
    reset: resetShippingExcelInvoiceList,
    ResponseHandler: ResponseHandlerValidatingShippingExcelInvoiceList,
  } = SHIPPING_QUERY.useValidateShippingExcelInvoiceList();

  const {
    mutate: saveShippingExcelInvoiceList,
    ResponseHandler: ResponseHandlerOfSavingShippingExcelInvoiceList,
  } = SHIPPING_QUERY.useSaveShippingExcelInvoiceList();

  const [uploadFile, LoadingToUpload, FailureModal] = useUploadFile();

  const handleExcelUpload = useCallback(() => {
    inputFileRef.current?.click();
  }, []);

  const onFileLoaded = useCallback(
    ({
      fileName,
      mimeType,
      fileBase64String,
    }: {
      fileName: string;
      mimeType: FileMimeType;
      fileBase64String: string;
    }) => {
      uploadFile({
        data: {
          fileName,
          fileBase64String: fileBase64String,
          name: fileName,
          domain: "invoice",
          targetId: 0,
          mimeType,
          // 로그인 상태이기 때문에 반드시 존재
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          actorKind: getActorKindByUserAuthority(currentUserAuthority!),
        },
        onUploadSuccess: (fileKey) => {
          if (!fileKey) {
            return;
          }

          validateShippingExcelInvoiceList({
            fileKey,
          });
        },
      });
    },
    [currentUserAuthority, validateShippingExcelInvoiceList, uploadFile]
  );

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const reader = new FileReader();

      const file = e.target.files?.[0];

      if (!file) {
        return;
      }

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        onFileLoaded({
          fileName: file.name,
          mimeType: file.type as FileMimeType,
          fileBase64String: reader.result as string,
        });

        e.target.value = ""; // 동일한 파일 재업로드를 위해서 초기화
      };
    },
    [onFileLoaded]
  );

  const isValidated = useMemo(
    () => shippingExcelInvoiceList?.every(({ hasError }) => !hasError),
    [shippingExcelInvoiceList]
  );

  const handleModalClose = useCallback(() => {
    onModalClose();
    resetShippingExcelInvoiceList();
  }, [onModalClose, resetShippingExcelInvoiceList]);

  const handleShippingInvoiceListSave = useCallback(() => {
    if (!shippingExcelInvoiceList) {
      return;
    }

    saveShippingExcelInvoiceList(
      {
        data: shippingExcelInvoiceList,
      },
      {
        onSuccess: () => {
          handleModalClose();

          queryClient.invalidateQueries(
            SHIPPING_QUERY_KEY_GEN.getAdminShippingList(params)
          );
        },
      }
    );
  }, [
    handleModalClose,
    params,
    queryClient,
    saveShippingExcelInvoiceList,
    shippingExcelInvoiceList,
  ]);

  return {
    handleExcelUpload,

    inputFileRef,
    handleFileInputChange,

    shippingExcelInvoiceList,
    successExcelList,
    failureExcelList,

    handleShippingInvoiceListSave,
    isValidated,
    handleModalClose,

    LoadingToUpload,
    FailureModal,

    ResponseHandlerValidatingShippingExcelInvoiceList,
    ResponseHandlerOfSavingShippingExcelInvoiceList,
  };
}

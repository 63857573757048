import { ChangeEvent, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";

import { EDIT_CUSTOMER_DETAIL_REQ } from "api-interfaces/shipping";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { ShippingItemDetail } from "types/shipping";

export default function EditCustomerDetailModal({
  shippingDetail,
  showsEditCustomerDetailModal,
  onEditCustomerDetailModalClose,
}: {
  shippingDetail: ShippingItemDetail;
  showsEditCustomerDetailModal: boolean;
  onEditCustomerDetailModalClose: () => void;
}) {
  const [
    {
      customerPostalCode,
      customerAddress,
      customerDetailAddress,
      customerCountryCode,
      customerStateCode,
    },
    setCustomerDetail,
  ] = useState<EDIT_CUSTOMER_DETAIL_REQ>({
    customerPostalCode: shippingDetail.customerPostalCode ?? "",
    customerCountryCode: shippingDetail.customerCountryCode ?? "",
    customerStateCode: shippingDetail.customerStateCode ?? "",
    customerAddress: shippingDetail.customerAddress ?? "",
    customerDetailAddress: shippingDetail.customerDetailAddress ?? "",
  });

  const queryClient = useQueryClient();

  const {
    mutate: editCustomerDetail,
    ResponseHandler: ResponseHandlerOfEditCustomerDetail,
  } = SHIPPING_QUERY.useEditCustomerDetail({
    shippingId: shippingDetail?.id,
  });

  const handleTextFiledChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCustomerDetail((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCustomerDetailEdit = () => {
    editCustomerDetail(
      {
        customerPostalCode,
        customerCountryCode,
        customerStateCode,
        customerAddress,
        customerDetailAddress,
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping())
            .then(onEditCustomerDetailModalClose);
        },
      }
    );
  };

  const isValid =
    !!customerPostalCode &&
    !!customerCountryCode &&
    !!customerStateCode &&
    !!customerAddress &&
    !!customerDetailAddress;

  return (
    <>
      <Dialog
        open={showsEditCustomerDetailModal}
        onClose={onEditCustomerDetailModalClose}
      >
        <DialogTitle>배송 정보 수정</DialogTitle>

        <DialogContent dividers>
          <Stack direction="column" spacing={2} mt={1} sx={{ minWidth: 500 }}>
            <TextField
              required
              fullWidth
              label="우편번호"
              name="customerPostalCode"
              value={customerPostalCode}
              onChange={handleTextFiledChange}
            />

            <TextField
              required
              fullWidth
              label="국가코드"
              name="customerCountryCode"
              value={customerCountryCode}
              onChange={handleTextFiledChange}
            />

            <TextField
              required
              fullWidth
              label="주명"
              name="customerStateCode"
              value={customerStateCode}
              onChange={handleTextFiledChange}
            />

            <TextField
              required
              fullWidth
              label="도시명"
              name="customerDetailAddress"
              value={customerDetailAddress}
              onChange={handleTextFiledChange}
            />

            <TextField
              required
              fullWidth
              label="받는 분 주소"
              name="customerAddress"
              value={customerAddress}
              onChange={handleTextFiledChange}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button variant="outlined" onClick={onEditCustomerDetailModalClose}>
            취소
          </Button>

          <Button
            variant="contained"
            onClick={handleCustomerDetailEdit}
            disabled={!isValid}
          >
            수정
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfEditCustomerDetail}
    </>
  );
}

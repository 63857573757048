import styled, { css } from "styled-components";

import { mobile } from "styles/functions";

interface ContainerProps {}
const container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  > .input-text {
    ${mobile(css`
      flex: 1;
    `)}

    > .input-wrapper {
      > input {
        padding: 0.5rem 0.5rem;
        height: 2.5rem;
      }

      > .post-fix {
        top: 13px;
      }
    }
  }

  > .button {
    margin-left: 0.125rem;
  }
`;

export default {
  container,
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";

import { COMPLETE_SHIPMENT_RES } from "api-interfaces/shipping";
import { toThousandUnitFormat } from "utils/number";

export default function CompleteShipmentResponseModal({
  completeShipmentResponse,
  handleCompleteShipmentResponseClose,
}: {
  completeShipmentResponse: COMPLETE_SHIPMENT_RES | undefined;
  handleCompleteShipmentResponseClose: () => void;
}) {
  return (
    <Dialog
      open={!!completeShipmentResponse}
      onClose={handleCompleteShipmentResponseClose}
    >
      <DialogTitle>출하완료를 요청했습니다.</DialogTitle>

      <DialogContent>
        <Paper variant="outlined">
          <List>
            <ListItem>
              <ListItemText
                primary="총 선택"
                secondary={toThousandUnitFormat(
                  completeShipmentResponse?.total
                )}
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="성공"
                secondary={toThousandUnitFormat(
                  completeShipmentResponse?.success
                )}
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="실패"
                secondary={
                  <>
                    {toThousandUnitFormat(completeShipmentResponse?.fail.count)}
                    {completeShipmentResponse?.fail.count
                      ? ` (${completeShipmentResponse?.fail.ids.join(", ")})`
                      : ""}
                  </>
                }
              />
            </ListItem>
          </List>
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCompleteShipmentResponseClose}>확인</Button>
      </DialogActions>
    </Dialog>
  );
}

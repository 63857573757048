import { ReactNode } from "react";
import { Divider, Paper, Stack, Typography } from "@mui/material";

export default function DetailItem({
  label,
  value,
  isEditable = false,
  editComponent,
}: {
  label: ReactNode;
  value: ReactNode;
  isEditable?: boolean;
  editComponent?: ReactNode;
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: 56,
        px: 2,
        py: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Typography
          variant="subtitle1"
          component="span"
          sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
        >
          {label}
        </Typography>

        {isEditable ? (
          editComponent
        ) : (
          <Typography component="span">{value}</Typography>
        )}
      </Stack>
    </Paper>
  );
}

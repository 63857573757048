import { useCallback } from "react";
import { useMemo } from "react";
import * as qs from "query-string";

interface HistoryLocation {
  hash: string;
  key?: string;
  pathname: string; // ex) /shipping/list
  search: string; // ex) ?status=waitingExpected
}

/**
 * react-router-dom의 useHistory hook으로 반환되는 history 객체
 * (react-router-dom에서 타입을 제공하지 않아 필요한 부분만 정의해서 사용)
 */
interface History {
  location: HistoryLocation;
  push: (location: Partial<HistoryLocation>) => void;
}

/**
 * react-router를 사용하는 App에서 query string을 이용한 작업을 쉽게 처리할 수 있는 기능을 제공
 * @param history - react-router-dom의 useHistory hook으로 반환되는 history 객체
 */
export default function useQueryString<
  T extends {
    [K in string]: string;
  }
>(history: History) {
  const setQuery = useCallback(
    (queries: Partial<T>) => {
      const searchStrFromQueries = Object.entries(queries).reduce((a, c) => {
        const divider = a.length ? "&" : "";

        a = a + divider + c[0] + "=" + c[1];

        return a;
      }, "");

      history.push({
        pathname: history.location.pathname,

        ...(searchStrFromQueries
          ? {
              search: `?${searchStrFromQueries}`,
            }
          : {}),
      });
    },
    [history]
  );

  const addQuery = useCallback(
    (queries: Partial<T>) => {
      // 기존 쿼리들을 가져옴
      const existedQueries = qs.parse(history.location.search);

      const result = { ...existedQueries };

      // 기존 쿼리 중 중복된 것은 덮어씀
      Object.keys(queries).forEach((key) => {
        result[key] = queries[key] as string;
      });

      setQuery(result as Partial<T>);
    },
    [history, setQuery]
  );

  const removeQuery = useCallback(
    (queryKeys: (keyof T)[]) => {
      // 기존 쿼리들을 가져옴
      const existedQueries = qs.parse(history.location.search) as Partial<T>;

      const result = { ...existedQueries };

      queryKeys.forEach((key) => {
        delete result[key];
      });

      setQuery(result);
    },
    [history, setQuery]
  );

  const clearQuery = useCallback(() => {
    history.push({ pathname: history.location.pathname });
  }, [history]);

  const queries = useMemo(() => {
    return qs.parse(history.location.search) as Partial<T>;
  }, [history.location.search]);

  return {
    path: history.location.pathname,
    /**
     * 모든 query string을 반환
     */
    queries,
    /**
     * query string을 셋팅(여러 개도 가능)
     * - 기존 query string들을 덮어 씀
     */
    setQuery,
    /**
     * query string을 추가(여러 개도 가능)
     * - 기존 query string는 유지됨
     * - 이미 query string이 있는 경우는 덮어 씀
     */
    addQuery,
    /**
     * 특정 query string을 삭제
     */
    removeQuery,
    /**
     * 모든 query string를 삭제
     */
    clearQuery,
  };
}

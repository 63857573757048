import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { MaterialPackageType } from "types/fulfillment";

const filterOptionList: TableHeadFilterOption<MaterialPackageType>[] = [
  { label: "기본포장", value: "basic" },
  { label: "친환경포장", value: "eco" },
  { label: "커스터마이징", value: "customize" },
  { label: "포장불필요", value: "none" },
];

export default function useMaterialPackageTypeFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: MaterialPackageTypeFilterPanel,
    filterList: materialPackageTypeFilterList,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    MaterialPackageTypeFilterPanel,
    materialPackageTypePayloadListByFilter: materialPackageTypeFilterList,
  };
}

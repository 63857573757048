import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RETURNING_QUERY from "queries/RETURNING_QUERY";

import Layout from "containers/Layout";

interface ReturningPayload {
  id: string;
  isAlreadyApplied: "true" | "false";
}

const returningStatusOptions: {
  value: "true" | "false";
  label: string;
}[] = [
  { value: "true", label: "고객사 측 입고" },
  { value: "false", label: "쉽다 측 수거" },
];

export function PatchIsAlreadyApplied() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ReturningPayload>({
    mode: "onChange",
    defaultValues: {
      id: "",
    },
  });

  const { mutate: updateReturningIsAlreadyApplied, ResponseHandler } =
    RETURNING_QUERY.usePatchReturningIsAlreadyApplied({
      successModalInfo: {
        handleConfirmSuccess: (initQuery) => {
          initQuery();
          reset();
        },
        customizeMessage: () => ({
          messageType: "titleAndBody",
          title: "반품 택배 수거방식 수정 완료",
        }),
      },
    });

  const handleUpdateReturningIsAlreadyApplied = ({
    id,
    isAlreadyApplied,
  }: {
    id: string;
    isAlreadyApplied: "true" | "false";
  }) => {
    updateReturningIsAlreadyApplied({
      pathParams: { id: parseInt(id) },
      isAlreadyApplied: isAlreadyApplied === "true" ? true : false,
    });
  };

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.secondary"
        >
          반품 수거방식 수정
        </Typography>
        <form onSubmit={handleSubmit(handleUpdateReturningIsAlreadyApplied)}>
          <Stack>
            <Controller
              control={control}
              name="id"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="반품요청번호"
                  error={!!errors.id}
                  helperText={errors.id && "숫자만 입력해주세요."}
                />
              )}
            />
          </Stack>

          <Stack spacing={2} sx={{ marginBottom: "10px" }}></Stack>
          <Box
            sx={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}
          >
            <Typography variant="h6" sx={{ marginRight: "10px" }}>
              수거방식
            </Typography>
            <Controller
              control={control}
              name="isAlreadyApplied"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="수거방식"
                  error={!!errors.isAlreadyApplied}
                  sx={{ flexGrow: 1 }}
                >
                  {returningStatusOptions.map((option) => (
                    <MenuItem key={String(option.value)} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>

          <Stack spacing={2} sx={{ marginBottom: "10px" }}>
            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandler}
    </Layout>
  );
}

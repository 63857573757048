import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { FULFILLMENT_SHIPPING_ATOMS } from "states/shipping";
import { toFormattedDate } from "utils/date";

import { ShippingListForPrinting } from ".";

export default function usePrintTransactionStatement({
  shippingsToPrint,
}: {
  shippingsToPrint?: ShippingListForPrinting;
}) {
  const history = useHistory();

  const canPrint = !!shippingsToPrint?.length;

  const setTransactionStatementData = useSetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.TRANSACTION_STATEMENT_DATA
  );

  const printTransactionStatement = useCallback(() => {
    if (!shippingsToPrint?.length) {
      return;
    }

    setTransactionStatementData(
      shippingsToPrint.map((selectedShippingItem) => ({
        invoiceNo: selectedShippingItem?.invoiceNo ?? "",
        customerName: selectedShippingItem?.customerName ?? "",
        customerAddress: selectedShippingItem?.customerAddress ?? "",
        customerPhone: selectedShippingItem?.customerPhone ?? "",
        orderNo: selectedShippingItem?.orderNo ?? "",
        senderPhone: selectedShippingItem?.senderPhone ?? "",
        teamBRN: selectedShippingItem?.team?.BRN ?? "",
        teamRepresentativeName:
          selectedShippingItem?.team?.representativeName ?? "",
        teamCompany: selectedShippingItem?.team?.company ?? "",
        teamAddress: selectedShippingItem?.team?.address ?? "",
        items: (selectedShippingItem?.items ?? []).map((item) => ({
          printDate: toFormattedDate(new Date(), "MM/DD"),
          productName: item.sku?.itemName ?? "",
          quantity: item.quantity ?? 0,
        })),
        requestMemo: (selectedShippingItem?.memo ?? [])
          .filter(({ category }) => category === "customer")
          .map((memoItem) => (
            <div key={memoItem.createdAt}>{memoItem.memo}</div>
          )),
      }))
    );

    history.push(`${history.location.pathname}/print-transaction-statement`);
  }, [history, setTransactionStatementData, shippingsToPrint]);

  return { printTransactionStatement, canPrint };
}

import { useCallback, useMemo, useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export type TableHeadFilterOption<T extends string | number | boolean> = {
  label: string | number;
  value: T;
};

/**
 * 참고) https://docs.google.com/presentation/d/1BB7u64Kn6yj8KkVS4hXeznqrY9hCHVmIKUO1DxIafSY/edit#slide=id.g1b89939cc57_1_4
 * - 모노리포에서 useTableHeadFilter라는 hook이 2개가 있어 불가피하게 네이밍을 길게 수정
 */
export default function useTableHeadFilterForBofulAdminOnly<
  T extends string | number | boolean
>({
  filterOptionList,
  resetCurrentPage,
  isSingle,
}: {
  filterOptionList: TableHeadFilterOption<T>[];
  resetCurrentPage?: () => void;
  /**
   * 단일 선택만 가능한 경우
   */
  isSingle?: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [filterList, setFilterList] = useState<T[]>([]);
  const [draftFilterList, setDraftFilterList] = useState<T[]>([]);

  const [searchKeyword, setSearchKeyword] = useState("");

  const isFilterOpen = !!anchorEl;

  const isSearchMode = !!searchKeyword;

  const filteredOptionList = useMemo(
    () =>
      isSearchMode
        ? filterOptionList.filter((option) =>
            String(option.label)
              .toLowerCase()
              .includes(searchKeyword.toLowerCase())
          )
        : filterOptionList,
    [isSearchMode, filterOptionList, searchKeyword]
  );

  const hasOptionList = filteredOptionList.length > 0;

  const isAllChecked = useMemo(
    () =>
      isSearchMode
        ? filteredOptionList.every((option) =>
            draftFilterList.includes(option.value)
          )
        : draftFilterList.length === filteredOptionList.length,
    [isSearchMode, draftFilterList, filteredOptionList]
  );

  const indeterminate = useMemo(
    () =>
      isSearchMode
        ? !isAllChecked &&
          filteredOptionList.some((option) =>
            draftFilterList.includes(option.value)
          )
        : draftFilterList.length > 0 &&
          draftFilterList.length < filteredOptionList.length,
    [isSearchMode, draftFilterList, filteredOptionList, isAllChecked]
  );

  const handleAllCheck = useCallback(() => {
    if (isSearchMode) {
      if (isAllChecked) {
        setDraftFilterList((prevDraftFilterList) =>
          prevDraftFilterList.filter(
            (draftFilter) =>
              !filteredOptionList.find((option) => option.value === draftFilter)
          )
        );
        return;
      }

      setDraftFilterList((prevDraftFilterList) => [
        ...prevDraftFilterList,
        ...filteredOptionList
          .filter((option) => !prevDraftFilterList.includes(option.value))
          .map((option) => option.value),
      ]);
      return;
    }

    if (isAllChecked) {
      setDraftFilterList([]);
      return;
    }

    setDraftFilterList(filteredOptionList.map((option) => option.value));
  }, [isSearchMode, isAllChecked, filteredOptionList]);

  const handleCheck = useCallback(
    ({ value, isChecked }: { value: T; isChecked: boolean }) =>
      () => {
        if (isChecked) {
          setDraftFilterList((prevDraftFilterList) =>
            prevDraftFilterList.filter((draftFilter) => draftFilter !== value)
          );
          return;
        }

        setDraftFilterList((prevDraftFilterList) => [
          ...prevDraftFilterList,
          value,
        ]);
      },
    []
  );

  const handleSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDraftFilterList([e.target.value as T]);
  }, []);

  const handleSearchKeywordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKeyword(e.target.value);
    },
    []
  );

  const handleFilterReset = useCallback(() => {
    if (isSearchMode) {
      setSearchKeyword("");
    }

    setDraftFilterList([]);
  }, [isSearchMode]);

  const handleFilterSubmit = useCallback(() => {
    setFilterList(draftFilterList);
    setAnchorEl(null);
    setSearchKeyword("");

    resetCurrentPage?.();
  }, [draftFilterList, resetCurrentPage]);

  const initDraftFilter = useCallback(() => {
    setDraftFilterList(filterList);
  }, [setDraftFilterList, filterList]);

  const handleFilterOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorEl(null);
    setSearchKeyword("");

    initDraftFilter();
  }, [initDraftFilter]);

  const FilterPanel = useMemo(() => {
    return (
      <>
        <IconButton
          size="small"
          color={filterList.length ? "primary" : "default"}
          onClick={isFilterOpen ? handleFilterClose : handleFilterOpen}
        >
          <FilterAlt fontSize="small" />
        </IconButton>

        <Popper
          open={isFilterOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 999 }}
        >
          <ClickAwayListener onClickAway={handleFilterClose}>
            <Paper sx={{ p: 2 }}>
              <Stack>
                <TextField
                  sx={{ mb: 1 }}
                  size="small"
                  placeholder="필터 대상을 검색하세요."
                  value={searchKeyword}
                  onChange={handleSearchKeywordChange}
                />

                <Stack sx={{ mb: 2 }}>
                  {hasOptionList ? (
                    <>
                      {!isSingle && (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isAllChecked}
                                indeterminate={indeterminate}
                                onClick={handleAllCheck}
                                size="small"
                              />
                            }
                            label="전체"
                          />
                          <Divider />
                        </>
                      )}

                      <Stack sx={{ maxHeight: 190, overflow: "auto" }}>
                        {isSingle && (
                          <RadioGroup
                            value={draftFilterList[0] || ""}
                            onChange={handleSelect}
                          >
                            {filteredOptionList.map((option) => (
                              <FormControlLabel
                                key={option.label}
                                control={<Radio size="small" />}
                                value={option.value}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        )}

                        {!isSingle &&
                          filteredOptionList.map((option) => {
                            const isChecked = draftFilterList.includes(
                              option.value
                            );

                            return (
                              <FormControlLabel
                                key={option.label}
                                control={
                                  <Checkbox
                                    checked={isChecked}
                                    onClick={handleCheck({
                                      value: option.value,
                                      isChecked,
                                    })}
                                    size="small"
                                  />
                                }
                                label={option.label}
                              />
                            );
                          })}
                      </Stack>
                    </>
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      검색 결과가 없습니다.
                    </Typography>
                  )}
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={handleFilterReset}
                  >
                    초기화
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleFilterSubmit}
                  >
                    적용
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }, [
    anchorEl,
    draftFilterList,
    filterList.length,
    filteredOptionList,
    handleAllCheck,
    handleCheck,
    handleFilterClose,
    handleFilterOpen,
    handleFilterReset,
    handleFilterSubmit,
    handleSearchKeywordChange,
    handleSelect,
    hasOptionList,
    indeterminate,
    isAllChecked,
    isFilterOpen,
    isSingle,
    searchKeyword,
  ]);

  const resetFilterState = useCallback(() => {
    setAnchorEl(null);
    setFilterList([]);
    setDraftFilterList([]);
    setSearchKeyword("");
  }, []);

  return { FilterPanel, filterList, resetFilterState };
}

import { ChangeEvent, useState } from "react";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ADD_SHIPPING_ITEM_REQ } from "api-interfaces/shipping";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import { ShippingInputForm } from "types/fulfillment";
import { ShippingItemDetail } from "types/shipping";
import { convertShippingDetailToShippingInputForm } from "../utils";

import SimpleInputTextField from "./SimpleInputTextField";
import useGroupSkuSearchModal from "./useGroupSearchModal";
import useSkuSearchModal from "./useSkuSearchModal";
import { getHighlightedLabel, omitWithEllipsis } from "utils/string";

function UpdateShippingForm({
  shippingDetail,
  onUpdateSuccess,
}: {
  shippingDetail: ShippingItemDetail;
  onUpdateSuccess: () => void;
}) {
  const [shippingInputForm, setShippingInputForm] = useState<ShippingInputForm>(
    convertShippingDetailToShippingInputForm(shippingDetail)
  );

  const { showSkuSearchModal, SkuSearchModal } = useSkuSearchModal(
    (skuDetail) => {
      if (
        skuDetail.sku.teamId === shippingDetail.teamId &&
        shippingInputForm.sku.every((v) => v.skuId !== skuDetail.sku.id)
      ) {
        // 성공 및 sku Add
        setShippingInputForm({
          ...shippingInputForm,
          sku: [...shippingInputForm.sku, { skuId: skuDetail.sku.id, qty: 1 }],
        });
      } else {
        // 에러 모달 생성
        alert("해당 팀이 아니거나 이미 추가되어있는 상품입니다");
      }
    }
  );

  const { showGroupSkuSearchModal, GroupSkuSearchModal } =
    useGroupSkuSearchModal((groupDetail) => {
      if (
        groupDetail.teamId === shippingDetail.teamId &&
        shippingInputForm.productGroupsInput.every(
          (v) => v.productGroupId !== groupDetail.id
        )
      ) {
        // 성공 및 sku Add
        setShippingInputForm({
          ...shippingInputForm,
          productGroupsInput: [
            ...shippingInputForm.productGroupsInput,
            { productGroupId: groupDetail.id, qty: 1 },
          ],
        });
      } else {
        // 에러 모달 생성
        alert("해당 팀이 아니거나 이미 추가되어있는 상품입니다");
      }
    });

  const {
    mutate: updateShippingDetail,
    ResponseHandler: ResponseHandlerOfUpdateShippingDetail,
  } = SHIPPING_QUERY.useUpdateShippingDetailAdminV2({
    shippingId: shippingDetail.id,
    onSuccess: onUpdateSuccess,
  });

  const onUpdateButtonClicked = () => {
    updateShippingDetail(shippingInputForm as ADD_SHIPPING_ITEM_REQ);
  };

  return (
    <>
      <Paper
        style={{
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          출고 수정 폼
        </Typography>

        <Stack gap={"10px"}>
          <SimpleInputTextField
            columnName={"warehouseId"}
            label="센터번호"
            disabled={true}
            shippingInputForm={shippingInputForm}
            setShippingInputForm={setShippingInputForm}
          />
          <SimpleInputTextField
            columnName={"orderNo"}
            label="주문번호[주문연동건의 경우 수정시 중복이 발생할 수 있음]"
            disabled={false}
            shippingInputForm={shippingInputForm}
            setShippingInputForm={setShippingInputForm}
          />

          <SimpleInputTextField
            columnName={"customerName"}
            label="받는분 이름"
            disabled={false}
            shippingInputForm={shippingInputForm}
            setShippingInputForm={setShippingInputForm}
          />
          <SimpleInputTextField
            columnName={"customerPhone"}
            label="받는분 전화번호"
            disabled={false}
            shippingInputForm={shippingInputForm}
            setShippingInputForm={setShippingInputForm}
          />
          <SimpleInputTextField
            columnName={"customerAddress"}
            label="받는분 주소"
            disabled={false}
            shippingInputForm={shippingInputForm}
            setShippingInputForm={setShippingInputForm}
          />
          <SimpleInputTextField
            columnName={"customerDetailAddress"}
            label="받는분 상세주소"
            disabled={false}
            shippingInputForm={shippingInputForm}
            setShippingInputForm={setShippingInputForm}
          />
          <SimpleInputTextField
            columnName={"customerPostalCode"}
            label="받는분 우편번호"
            disabled={false}
            shippingInputForm={shippingInputForm}
            setShippingInputForm={setShippingInputForm}
          />

          <br />
          <span>
            주문상품정보{" "}
            {getHighlightedLabel({
              label:
                "(주문상품수량을 변경하지 않더라도 피킹 로케이션이 변경될 수 있습니다)",
              isHighlighted: true,
            })}
          </span>
          <span>
            <Button variant="outlined" onClick={showSkuSearchModal}>
              단일상품 추가
            </Button>
            <Button variant="outlined" onClick={showGroupSkuSearchModal}>
              그룹상품 추가
            </Button>
          </span>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>SKU ID</TableCell>
                  <TableCell>주문상품명</TableCell>
                  <TableCell>주문수량</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shippingInputForm.productGroupsInput.map((group, index) => (
                  <TableRow
                    key={`group${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{"G" + group.productGroupId}</TableCell>
                    <TableCell>
                      {omitWithEllipsis({
                        src:
                          shippingDetail.orders.find(
                            (v) => v.productGroupId === group.productGroupId
                          )?.itemName || "",
                        maxLength: 50,
                        ellipsis: "...",
                      })}
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        value={Number(group.qty).toString()}
                        onChange={(
                          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                        ) => {
                          setShippingInputForm({
                            ...shippingInputForm,
                            productGroupsInput:
                              shippingInputForm.productGroupsInput
                                .map((v) => {
                                  return v.productGroupId ===
                                    group.productGroupId
                                    ? {
                                        productGroupId: v.productGroupId,
                                        qty: Number(e.target.value),
                                      }
                                    : v;
                                })
                                .filter((v) => v.qty > 0),
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {shippingInputForm.sku.map((sku, index) => (
                  <TableRow
                    key={`sku${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{"S" + sku.skuId}</TableCell>
                    <TableCell>
                      {omitWithEllipsis({
                        src:
                          shippingDetail.orders.find(
                            (v) => v.skuId === sku.skuId
                          )?.itemName || "",
                        maxLength: 50,
                        ellipsis: "...",
                      })}
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        value={Number(sku.qty).toString()}
                        onChange={(
                          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                        ) => {
                          setShippingInputForm({
                            ...shippingInputForm,
                            sku: shippingInputForm.sku
                              .map((v) => {
                                return v.skuId === sku.skuId
                                  ? {
                                      skuId: v.skuId,
                                      qty: Number(e.target.value),
                                    }
                                  : v;
                              })
                              .filter((v) => v.qty > 0),
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: "flex", justifyContent: "stretch" }}>
            <Button
              variant="contained"
              onClick={onUpdateButtonClicked}
              fullWidth={true}
            >
              수정하기
            </Button>
          </div>

          <br />
          <br />
          <span>payload[dev]</span>
          <textarea
            style={{
              backgroundColor: "black",
              color: "greenyellow",
              resize: "none",
            }}
            readOnly={true}
            rows={Object.keys(shippingInputForm).length}
            value={JSON.stringify(shippingInputForm, null, 2)}
          />
        </Stack>
      </Paper>
      {SkuSearchModal}
      {GroupSkuSearchModal}
      {ResponseHandlerOfUpdateShippingDetail}
    </>
  );
}

export default UpdateShippingForm;

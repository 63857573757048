import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { ReceivingKind } from "types/receiving";

const filterOptionList: TableHeadFilterOption<ReceivingKind>[] = [
  { label: "국내", value: "domestic" },
  { label: "수입", value: "import" },
];

export default function useReceivingKindFilter(resetCurrentPage?: () => void) {
  const {
    FilterPanel: ReceivingKindFilterPanel,
    filterList: receivingKindFilterList,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    ReceivingKindFilterPanel,
    receivingKindPayloadListByFilter: receivingKindFilterList,
  };
}

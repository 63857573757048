import { useState } from "react";

// TODO: boful-admin 컴포넌트도 리팩터링
/**
 * 새로 데이터를 fetch한 결과로 CSV다운로드할때 필요한 데이터를 가져옴 (ex. Table의 전체자료에 대한 CSV 다운로드)
 */
// TODO: 이후에 data: any 타입 개선
export default function useGetCSVDownloadInfoFromQuery<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any) => ReturnType<T>
>({ query, queryArgs }: { query: T; queryArgs: Parameters<T> }) {
  const [isCSVDownloadRequested, setIsCSVDownloadRequested] = useState(false);

  const params = queryArgs[0];

  const queryResult = query({
    ...params,
    enabled: isCSVDownloadRequested,
  }) as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    ResponseHandler: React.ReactNode;
    remove: () => void;
    status: "idle" | "error" | "loading" | "success";
  };

  return {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload: queryResult.data,
    ResponseHandlerOfFetchDataForCSVDownload: queryResult.ResponseHandler,
    removeQueryOfFetchDataForCSVDownload: queryResult.remove,
    statusOfQueryOfFetchDataForCSVDownload: queryResult.status,
  };
}

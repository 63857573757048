import React, { useEffect } from "react";
import { useRecoilState } from "recoil";

import Loading from "components/sds-v1/Loading";
import { FULFILLMENT_AUTH_ATOMS } from "states/auth";

export default function withRequireAuth(
  WrappedComponent: React.ComponentType<unknown>
) {
  return () => {
    const [loggedIn, setLoggedIn] = useRecoilState(
      FULFILLMENT_AUTH_ATOMS.LOGGED_IN
    );

    // url로 direct로 들어왔을때를 위함
    useEffect(() => {
      // eslint-disable-next-line no-undef
      if (window.localStorage.getItem("accessToken")) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }, [setLoggedIn]);

    useEffect(() => {
      if (typeof loggedIn === "boolean" && !loggedIn) {
        const backTo =
          window?.history?.state?.as ||
          window?.location?.pathname + window?.location?.search;

        if (backTo && backTo !== "/") {
          location.href = `/login?backTo=${backTo}`;
        } else {
          location.href = `/login`;
        }
      }
    }, [loggedIn]);

    if (loggedIn) {
      return <WrappedComponent />;
    } else {
      return <Loading active={true} />;
    }
  };
}

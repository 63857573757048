export default function SwitchCase<Case extends string>({
  value,
  caseBy,
  defaultComponent = null,
}: {
  /**
   * 케이스에 맞게 표시할 컴포넌트
   */
  caseBy: Partial<Record<Case, JSX.Element | null>>;
  /**
   * 현재 상태
   */
  value: Case;
  /**
   * `caseBy[value]` 가 없을때 렌더링할 기본값 컴포넌트
   */
  defaultComponent?: JSX.Element | null;
}) {
  if (value == null) {
    return defaultComponent;
  }

  return caseBy[value] ?? defaultComponent;
}

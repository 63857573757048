import { useState } from "react";
import { HighlightOff } from "@mui/icons-material";
import { Alert, IconButton, InputAdornment, Snackbar } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";

import { getHolidayList, TODAY, TOMORROW } from "utils/date";

type MinDate = "today" | "tomorrow" | Date | string | null;
export type CalendarInputValue = string | null;
type DateValue = Dayjs | null;

interface CommonProps {
  value: CalendarInputValue;
  label?: string;
  disabled?: boolean;
  minDate?: MinDate;
  holidayOption?: "disabled" | "alert";
  setIsValid?: (v: boolean) => void;
  when?: "start" | "end";
  className?: string;
  sx?: TextFieldProps["sx"];
  fullWidth?: boolean;
  size?: "small" | "medium";
  /** Calendar 컴포넌트 외부에서 open/close를 제어하고 싶은 경우 사용 */
  isOpen?: boolean;
  onClear?: () => void;

  /**
   * onChange -> onClose -> onAccept 순으로 실행됨
   */
  onChange: (value: CalendarInputValue) => void;
  onClose?: () => void;
  onAccept?: (value: Dayjs | null) => void;
}

interface OnlyDatePicker {
  type: "datePicker";
}

interface WithTimePicker {
  type: "withTimePicker";
  selectableTime?: {
    startHour: number;
    endHour: number;
    disabledHour?: number;
  };
}

const isWeekendOrHoliday = (selectedDate: Dayjs | null) => {
  if (!selectedDate) {
    return false;
  }

  const isWeekend = selectedDate.day() === 0 || selectedDate.day() === 6;

  const holidayList = getHolidayList().map((date) => dayjs(date));
  const isHoliday = holidayList.some((holiday) => holiday.isSame(selectedDate));

  return isWeekend || isHoliday;
};

const getMinDate = (minDate: MinDate | undefined) => {
  if (!minDate) {
    return;
  }

  if (minDate === "today") {
    return dayjs(TODAY);
  }

  if (minDate === "tomorrow") {
    return dayjs(TOMORROW);
  }

  return dayjs(minDate);
};

export default function Calendar({
  value,
  onChange,
  label,
  disabled,
  minDate,
  holidayOption,
  setIsValid,
  when = "start",
  className,
  sx,
  onClear,
  size = "medium",
  isOpen,
  onClose,
  onAccept,
  fullWidth,
  ...propsByType
}: CommonProps & (OnlyDatePicker | WithTimePicker)) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [isVisibleHolidayGuide, setIsVisibleHolidayGuide] = useState(false);

  const handleOpen = () => {
    if (disabled) {
      return;
    }

    setIsPickerOpen(true);
  };

  const handleChange = (value: DateValue) => {
    const changedDate = (() => {
      if (!value) {
        return null;
      }

      if (when === "end") {
        return dayjs(value).endOf("day");
      }

      return dayjs(value);
    })();

    onChange(changedDate?.toISOString() ?? null);
  };

  const handleClose = () => {
    setIsPickerOpen(false);

    onClose && onClose();
  };

  const handleAccept = (value: Dayjs | null) => {
    if (holidayOption === "alert" && isWeekendOrHoliday(value)) {
      setIsVisibleHolidayGuide(true);
    }

    setIsValid && setIsValid(true);

    onAccept && onAccept(value);
  };

  const handleHolidayGuideClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsVisibleHolidayGuide(false);
  };

  /**
   * Calendar 컴포넌트는 onChange -> onClose -> onAccept 순으로 실행됨
   */
  const commonProps = {
    open: isOpen || isPickerOpen,
    onClose: handleClose,
    renderInput: (props: TextFieldProps) => (
      <TextField
        {...props}
        inputProps={{
          ...props.inputProps,
          placeholder: "",
        }}
        sx={sx}
        fullWidth={fullWidth}
        onKeyDown={(e) => e.preventDefault()}
        onClick={handleOpen}
        size={size}
        InputProps={{
          ...props.InputProps,
          endAdornment: onClear && value && !disabled && (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
              >
                <HighlightOff fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ),
    label,
    value,
    disabled,
    onAccept: handleAccept,
    onChange: handleChange,
    minDate: getMinDate(minDate),
    shouldDisableDate: (day: DateValue) =>
      holidayOption === "disabled" ? isWeekendOrHoliday(day) : false,
    onError: () => setIsValid && setIsValid(false),
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {propsByType.type === "withTimePicker" ? (
          <DateTimePicker
            {...commonProps}
            className={className}
            inputFormat="YYYY-MM-DD hh:mm a"
            mask="____-__-__ __:__ _M"
            minutesStep={10}
            shouldDisableTime={(timeValue, clockType) => {
              const { selectableTime } = propsByType;

              if (!selectableTime) {
                return false;
              }

              const isValidHours =
                timeValue >= selectableTime.startHour &&
                timeValue <= selectableTime.endHour &&
                timeValue !== selectableTime.disabledHour;
              const isNotValidHours = clockType === "hours" && !isValidHours;
              if (isNotValidHours) {
                return true;
              }

              const isEndHour = dayjs(value).hour() === selectableTime.endHour;
              const isNotZeroMinutes = timeValue !== 0;
              const isNotValidMinutes =
                clockType === "minutes" && isEndHour && isNotZeroMinutes;
              if (isNotValidMinutes) {
                return true;
              }

              return false;
            }}
          />
        ) : (
          <DatePicker
            {...commonProps}
            className={className}
            inputFormat="YYYY-MM-DD"
            mask="____-__-__"
          />
        )}
      </LocalizationProvider>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isVisibleHolidayGuide}
        autoHideDuration={6000}
        onClose={handleHolidayGuideClose}
      >
        <Alert onClose={handleHolidayGuideClose} severity="warning">
          주말/공휴일 출고의 경우 기존 견적의 20% 할증됩니다.
        </Alert>
      </Snackbar>
    </>
  );
}

import { IS_UNDER_PRODUCTION } from "constants/common";
import {
  FIRST_GROUP_SKU_ID_IN_DEV,
  FIRST_GROUP_SKU_ID_IN_PROD,
} from "constants/fulfillment";
import { COUNTRY_CODE_TYPE } from "constants/shipping";

import { Dispatch, SetStateAction } from "react";
import { SetterOrUpdater } from "recoil";

import { COLOR } from "styles/constants";
import { CountryCode, StateCode } from "types/address";
import { BofulAuthority, BofulWorker } from "types/auth";
import { ClassifiedGroupItem, ResponseFailureInfo } from "types/common";
import {
  CommonTruckType,
  DeliveryTrackingInfoPageType,
  GroupDataByType,
  MaterialItem,
  MaterialPackageType,
  PackageCategory,
  PackageType,
  ProviderType,
  ReceivingAndInspectionProblem,
  ReturningCondition,
} from "types/fulfillment";
import {
  FulfillmentChanel,
  FulfillmentParcelCompany,
  InspectionStatus,
  ReceivingStatus,
} from "types/fulfillment";
import { BofulLocation } from "types/location";
import { InspectProblem, UserReturningDetailItem } from "types/returning";
import {
  ShippingDeliveryType,
  ShippingItem,
  ShippingPacking,
  ShippingTrucking,
} from "types/shipping";
import { ProductGroup, ProductGroupIdItem } from "types/sku";

/** 매니저일 경우 '이름', 파트타이머일 경우 '계정명'으로 표시 */
function getWorkerNameByAuthority(worker?: BofulWorker) {
  if (!worker) return "";

  return worker.authority === "manager"
    ? worker.name ?? ""
    : worker.accountId ?? "";
}

/** 작업자 리스트에서 담당자명 가져오기 */
function getWorkerNameById(workerList: BofulWorker[], workerId?: number) {
  if (!workerId) return "";

  const worker = workerList.find((worker) => worker.id === workerId);

  if (!worker) {
    return "";
  }
  return getWorkerNameByAuthority(worker);
}

/** 위치 리스트에서 위치명 가져오기 */
function getLocationBarcodeById({
  locationList,
  locationId,
}: {
  locationList: BofulLocation[];
  locationId: number | undefined;
}) {
  return locationList.find((v) => v.id === locationId)?.barCode ?? "";
}

const AuthorityLabelDict: { [key in BofulAuthority]: string } & {
  "": string;
} = {
  admin: "관리자",
  manager: "매니저",
  partTimer: "파트타이머",
  whMaster: "창고 마스터",
  "": "-",
};

/**
 * sku 아이템 종류의 수를 반환
 */
function getSKUItemTypeQuantity(items?: { skuId: number }[]) {
  if (!items) return 0;

  const skuIdSet = new Set(items.map((item) => item.skuId));

  return skuIdSet.size;
}

function getSkuIds(items: { skuId?: number; isGroup?: boolean }[] | undefined) {
  return (items ?? [])
    .map((item) =>
      item.isGroup
        ? getFormattedGroupSkuId(item.skuId)
        : getFormattedSingleSkuId(item.skuId)
    )
    .join(", ");
}

function getPcs(items: { quantity?: number }[]) {
  return items.map((item) => item.quantity).join(", ");
}

/**
 * 그룹상품을 items에 리스팅 하기위한 형태로 변환
 */
function getGroupItems(
  groupDataByType: GroupDataByType
): ClassifiedGroupItem[] {
  if (groupDataByType.type === "list") {
    return (groupDataByType.productGroupIds ?? []).map((productGroupIdItem) => {
      const { productGroupId, qty, productGroupName } = productGroupIdItem;

      return {
        isGroup: true,

        id: productGroupId,
        skuId: productGroupId,
        quantity: qty,
        sku: {
          id: productGroupId,
          itemName: productGroupName,
        },
      };
    });
  }

  if (groupDataByType.type === "detail") {
    return (groupDataByType.groups ?? []).map((groupItem) => {
      const {
        id,
        qty,
        groupName,
        productCode,
        managementCode,
        materialPackageType,
        returningCondition,
        attachment,
        packages,
      } = groupItem;

      return {
        isGroup: true,

        id,
        skuId: id,
        quantity: qty,
        sku: {
          id,
          itemName: groupName,
          productCode,
          managementCode,
          materialPackageType,
          returningCondition,
          attachment,
          packages,
        },
      };
    });
  }

  return [];
}

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 그룹상품을 items에 리스팅 하기위한 형태로 변환
 */
const getGroupItemsV2 = (
  groupList: ProductGroup[] | undefined
): ClassifiedGroupItem[] =>
  (groupList ?? []).map((groupData) => {
    const {
      id,
      qty,
      groupName,
      productCode,
      managementCode,
      materialPackageType,
      returningCondition,
      attachment,
      packages,
    } = groupData;

    return {
      isGroup: true,

      id,
      skuId: id,
      quantity: qty,
      sku: {
        id,
        itemName: groupName,
        productCode,
        managementCode,
        materialPackageType,
        returningCondition,
        attachment,
        packages,
      },
    };
  });

/**
 * 그룹상품의 각 구성상품 정보를 총수량(그룹상품의 수량 * 구성상품의 수량)과 함께 반환
 */
const getGroupItemsWithTotalQuantity = (groupDataByType: GroupDataByType) =>
  getGroupDataByType(groupDataByType).flatMap((groupDataItem) =>
    (groupDataItem.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      qty: groupItem.qty * groupDataItem.qty,
    }))
  );

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 그룹상품의 각 구성상품 정보를 총수량(그룹상품의 수량 * 구성상품의 수량)과 함께 반환
 */
const getGroupItemsWithTotalQuantityV2 = (
  groupList: ProductGroup[] | undefined
) =>
  (groupList ?? []).flatMap((groupData) =>
    (groupData.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      qty: groupItem.qty * groupData.qty,
    }))
  );

// TODO: 최종적으로 반품까지 백엔드 개선 내용이 반영되면 삭제해야 됨
const getGroupDataByType = (groupData: GroupDataByType) => {
  if (groupData.type === "list") {
    return groupData.productGroupIds ?? [];
  }

  if (groupData.type === "detail") {
    return groupData.groups ?? [];
  }

  return [];
};

/**
 * 그룹상품의 구성상품 SKU ID 목록을 반환(구성상품 간 중복이 있을 수 있으므로 Set으로 반환)
 */
const getDetailSkuIdList = (groupItems: { skuId: number }[] | undefined) =>
  new Set((groupItems ?? []).map(({ skuId }) => skuId));

/**
 * items에서 구성상품의 수량(quantity)을 뺀 items를 반환
 */
const getItemsWithoutGroupItemsQuantity = <
  T extends ShippingItem | UserReturningDetailItem
>({
  items,
  detailSkuIdList,
  groupItemsWithTotalQuantity,
}: {
  items: T[] | undefined;
  detailSkuIdList: Set<number>;
  groupItemsWithTotalQuantity: { skuId: number; qty: number }[];
}) => {
  const getItemWithoutGroupItemsQuantity = (item: T) => {
    if (detailSkuIdList.has(item.skuId || 0)) {
      const totalQuantityBySkuId = groupItemsWithTotalQuantity.reduce(
        (totalQuantity, groupItem) => {
          if (item.skuId === groupItem.skuId) {
            return totalQuantity + groupItem.qty;
          }

          return totalQuantity;
        },
        0
      );

      return {
        ...item,
        quantity: item.quantity - totalQuantityBySkuId,
      };
    }

    return item;
  };

  return (items ?? []).map(getItemWithoutGroupItemsQuantity);
};

const accumulateQuantity = (acc: number, item: { quantity?: number }) =>
  acc + (item.quantity ?? 0);

const reduceQuantity = (items: { quantity?: number }[] | undefined) =>
  (items ?? []).reduce(accumulateQuantity, 0);

/**
 * SKU ID가 2개 이상인 경우 '외 n건'으로 표시 ex) S3 외 1건
 */
const getSkuIdListForAdmin = (
  items: { skuId?: number; isGroup?: boolean }[] | undefined
) => {
  const skuIdList = (items ?? []).map(getFormattedSkuId);

  if (skuIdList.length > 1) {
    return `${skuIdList[0]} 외 ${skuIdList.length - 1}개`;
  }

  return skuIdList[0];
};

/**
 * 주문수량(ORDER) - classify된 items를 기준
 */
const getOrderQuantity = (items: { quantity?: number }[] | undefined) =>
  reduceQuantity(items);

type GetShippingQuantityParams = {
  items: { skuId?: number; quantity?: number; isGroup?: boolean }[] | undefined;
} & (
  | {
      type: "shipda";
    }
  | {
      type: "admin";
      productGroupIds: ProductGroupIdItem[] | undefined;
    }
);
/**
 * 출고수량(PCS)
 * - Shipda: 재고가 차감되는 단일상품 기준이므로 classify하기 전 원래 items를 기준
 * - Admin: classify된 items + productGroupIds를 조합
 */
const getShippingQuantity = ({
  items,
  ...paramsByType
}: GetShippingQuantityParams) => {
  if (paramsByType.type === "shipda") {
    return reduceQuantity(items);
  }

  if (paramsByType.type === "admin") {
    const { productGroupIds } = paramsByType;

    return (items ?? []).reduce((acc, item) => {
      if (item.isGroup) {
        const targetGroup = (productGroupIds ?? []).find(
          (productGroupIdItem) =>
            productGroupIdItem.productGroupId === item.skuId
        );

        if (targetGroup) {
          const targetGroupTotalQuantity = (
            targetGroup.groupItems ?? []
          ).reduce(
            (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
            0
          );

          return acc + targetGroupTotalQuantity;
        }

        return acc;
      }

      return acc + (item.quantity ?? 0);
    }, 0);
  }

  return 0;
};

type GetShippingQuantityParamsV2 = {
  items: { skuId?: number; quantity?: number; isGroup?: boolean }[] | undefined;
} & (
  | {
      type: "shipda";
    }
  | {
      type: "admin";
      groupList: ProductGroup[] | undefined;
    }
);
/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 출고수량(PCS)
 * - Shipda: 재고가 차감되는 단일상품 기준이므로 classify하기 전 원래 items를 기준
 * - Admin: classify된 items + groupList를 조합
 */
const getShippingQuantityV2 = ({
  items,
  ...paramsByType
}: GetShippingQuantityParamsV2) => {
  if (paramsByType.type === "shipda") {
    return reduceQuantity(items);
  }

  if (paramsByType.type === "admin") {
    const { groupList } = paramsByType;

    return (items ?? []).reduce((acc, item) => {
      if (item.isGroup) {
        const targetGroup = (groupList ?? []).find(
          (groupData) => groupData.id === item.skuId
        );

        if (targetGroup) {
          const targetGroupTotalQuantity = (
            targetGroup.groupItems ?? []
          ).reduce(
            (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
            0
          );

          return acc + targetGroupTotalQuantity;
        }

        return acc;
      }

      return acc + (item.quantity ?? 0);
    }, 0);
  }

  return 0;
};

const getShippingQuantityForShipdaCSV = ({
  item,
  skuId,
  productGroupIds,
}: {
  item: { quantity?: number; isGroup?: boolean };
  skuId: number;
  productGroupIds: ProductGroupIdItem[] | undefined;
}) => {
  if (!item) {
    return 0;
  }

  if (!item.isGroup) {
    return item.quantity ?? 0;
  }

  const targetGroup = (productGroupIds ?? []).find(
    (group) => group.productGroupId === skuId
  );

  if (!targetGroup) {
    return 0;
  }

  return (targetGroup.groupItems ?? []).reduce(
    (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
    0
  );
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 */
const getShippingQuantityForShipdaCSVV2 = ({
  item,
  skuId,
  groupList,
}: {
  item: { quantity?: number; isGroup?: boolean };
  skuId: number;
  groupList: ProductGroup[] | undefined;
}) => {
  if (!item) {
    return 0;
  }

  if (!item.isGroup) {
    return item.quantity ?? 0;
  }

  const targetGroup = (groupList ?? []).find((group) => group.id === skuId);

  if (!targetGroup) {
    return 0;
  }

  return (targetGroup.groupItems ?? []).reduce(
    (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
    0
  );
};

const getFormattedSkuId = (item: { skuId?: number; isGroup?: boolean }) => {
  if (!item) {
    return "";
  }

  if (item.isGroup) {
    return getFormattedGroupSkuId(item.skuId);
  }

  return getFormattedSingleSkuId(item.skuId);
};

const checkHasQuantity = (item: { quantity?: number }) => {
  if (!item.quantity) {
    return false;
  }

  return item.quantity > 0;
};

/**
 * 수량이 존재하는 아이템만 필터링
 */
const getFilteredItemsByQuantity = <T extends { quantity: number }>(
  items: T[] | undefined
) => (items ?? []).filter(checkHasQuantity);

/**
 * productGroupIds에는 구성상품의 상품명이 없기 때문에 items에서 찾아서 추가
 */
const getProductGroupIdsForTooltip = ({
  productGroupIds,
  items,
}: {
  productGroupIds: ProductGroupIdItem[] | undefined;
  items: { skuId?: number; sku: { itemName?: string } }[] | undefined;
}) =>
  (productGroupIds ?? []).map((productGroupIdItem) => ({
    ...productGroupIdItem,
    groupItems: (productGroupIdItem.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      sku: {
        itemName: (items ?? []).find((item) => item.skuId === groupItem.skuId)
          ?.sku.itemName,
      },
    })),
  }));

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * productGroupIds에는 구성상품의 상품명이 없기 때문에 items에서 찾아서 추가
 */
const getProductGroupIdsForTooltipV2 = ({
  groupList,
  items,
}: {
  groupList: ProductGroup[] | undefined;
  items: { skuId?: number; sku: { itemName?: string } }[] | undefined;
}) =>
  (groupList ?? []).map((groupData) => ({
    ...groupData,
    groupItems: (groupData.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      sku: {
        itemName: (items ?? []).find((item) => item.skuId === groupItem.skuId)
          ?.sku.itemName,
      },
    })),
  }));

const getBarcodeList = (
  items: { sku?: { barCode?: string } }[] | undefined
) => {
  const barcodeList = (items ?? []).map((item) => item.sku?.barCode);
  // barcodeList의 타입이 string | undefined가 아닌 string | null 이지만
  // 이걸 지금 수정하면 타입이 전체적으로 깨질것으로 예상되기 때문에 강제로 (string | null)[] 타입으로 설정
  if ((barcodeList as (string | null)[]).includes(null)) return "-";

  if (barcodeList.length > 2) {
    return `${barcodeList[0]}, ${barcodeList[1]} 외 ${
      barcodeList.length - 2
    }개`;
  }

  return barcodeList.join(", ");
};

const checkIsSupportedParcelCompany = ({
  pageName,
  parcelCompany,
}: {
  pageName: DeliveryTrackingInfoPageType;
  parcelCompany: FulfillmentParcelCompany | undefined;
}) => {
  if (!parcelCompany) {
    return false;
  }

  const isShipping = pageName === "shipping";
  const isReturning = pageName === "returning";

  const isCj = parcelCompany === "cj";
  const isHanjin = parcelCompany === "hanjin";
  const isKunyoung = parcelCompany === "kunyoung";
  const isDaesin = parcelCompany === "daesin";

  if (isShipping) {
    return isCj || isHanjin || isDaesin || isKunyoung;
  }

  if (isReturning) {
    return isCj || isHanjin;
  }

  return false;
};

const checkIsGroupSkuItem = (skuId: number | undefined) => {
  if (!skuId) {
    return false;
  }

  return (
    skuId >=
    (IS_UNDER_PRODUCTION
      ? FIRST_GROUP_SKU_ID_IN_PROD
      : FIRST_GROUP_SKU_ID_IN_DEV)
  );
};

/**
 * skuId가 같더라도 별도의 item으로 존재하는 경우 skuId 기준으로 합쳐주기 위한 함수(ex. location이 다른 경우 등)
 */
const mergeItemsBySkuId = <T extends { skuId: number; quantity: number }>(
  items: T[] | undefined
) =>
  (items ?? []).reduce((prevItems, item) => {
    const existingItemIndex = prevItems.findIndex(
      (prevItem) => prevItem.skuId === item.skuId
    );

    if (existingItemIndex === -1) {
      return [...prevItems, item];
    }

    return prevItems.map((prevItem, index) => {
      if (index === existingItemIndex) {
        return {
          ...prevItem,
          quantity: prevItem.quantity + item.quantity,
        };
      }

      return prevItem;
    });
  }, [] as T[]);

/**
 * 테이블 컬럼 필터 값에 따라 Search Query Params를 업데이트 (필터 값 복수 선택)
 * @param filterKey Query String의 key로 전달되는 컬럼 필터 key 값
 * @param filterValue Query String의 value로 전달되는 컬럼 필터 value 값
 * @param setSearchQueryParams Query String setter 함수
 */
function updateSearchQueryParams<
  RequestType,
  FilterKey extends keyof RequestType,
  FilterValue
>({
  filterKey,
  filterValue,
  setSearchQueryParams,
}: {
  filterKey: FilterKey;
  filterValue: FilterValue[];
  setSearchQueryParams: SetterOrUpdater<RequestType>;
}) {
  filterValue?.length &&
    setSearchQueryParams((prev) => ({
      ...prev,
      [filterKey]: filterValue,
      page: 0, // 필터 값 변경 시 첫 페이지로
    }));

  !filterValue?.length &&
    setSearchQueryParams((prev) => {
      const newSearchQueryParams = { ...prev, page: 0 }; // 필터 값 변경 시 첫 페이지로
      delete newSearchQueryParams[filterKey];
      return newSearchQueryParams;
    });
}
const getFormattedSearchItem = ({
  searchKindLabel,
  searchTerm,
}: {
  searchKindLabel: string;
  searchTerm: string;
}) => `${searchKindLabel}: ${searchTerm}`;

/**
 * SearchSelector 선택 상태 변경 핸들러
 */
function handleSearchStateUpdate<OptionValue, ObjectFormState>({
  key,
  value,
  setState,
}: {
  key: keyof ObjectFormState;
  value: OptionValue | null;
  setState: Dispatch<SetStateAction<ObjectFormState>>;
}) {
  setState((prev) => ({ ...prev, [key]: value }));
}

type DomesticAddress = {
  type: "domestic";
  customerAddress: string | undefined;
  customerDetailAddress: string | undefined;
};
type OverseasAddressForShipda = {
  type: "overseasForShipda";
  /** 해외(상세주소) */
  customerAddress: string | undefined;
  customerCountryCode: CountryCode | undefined;
  customerPostalCode: string | undefined;
};
type OverseasAddressForAdmin = {
  type: "overseasForAdmin";
  /** 해외(상세주소) */
  customerAddress: string | undefined;
  customerStateCode: StateCode | undefined;
  /** 해외(도시명) */
  customerDetailAddress: string | undefined;
};
const getFormattedCustomerAddress = (
  paramsByType:
    | DomesticAddress
    | OverseasAddressForShipda
    | OverseasAddressForAdmin
) => {
  if (paramsByType.type === "domestic") {
    const { customerAddress, customerDetailAddress } = paramsByType;

    return `${customerAddress ?? ""} ${customerDetailAddress ?? ""}`;
  }

  if (paramsByType.type === "overseasForAdmin") {
    const { customerAddress, customerStateCode, customerDetailAddress } =
      paramsByType;

    return `${customerAddress ?? ""}(${`${
      customerStateCode ? `${customerStateCode}, ` : ""
    }`}${customerDetailAddress ?? ""})`;
  }

  if (paramsByType.type === "overseasForShipda") {
    const { customerAddress, customerCountryCode, customerPostalCode } =
      paramsByType;

    return `${customerAddress ?? ""} (${
      customerCountryCode ? COUNTRY_CODE_TYPE[customerCountryCode] : ""
    }, ${customerPostalCode ?? ""})`;
  }

  return "";
};

const getPayloadByFilter = <T extends unknown>({
  payloadList,
  key,
  isSingle,
}: {
  payloadList: T[];
  key: string;
  isSingle?: boolean;
}) => {
  if (!payloadList.length) {
    return {};
  }

  if (isSingle) {
    return {
      [key]: payloadList[0],
    };
  }

  return {
    [key]: payloadList,
  };
};

const getChannelName = ({
  channelDict,
  shippingItem,
}: {
  channelDict: { [x: string]: FulfillmentChanel };
  shippingItem:
    | {
        saleChannel: string;
      }
    | undefined;
}) => {
  if (!shippingItem) {
    return "";
  }

  const { saleChannel } = shippingItem;

  return channelDict[saleChannel]
    ? channelDict[saleChannel].korName
    : saleChannel;
};

const getTeamLabelForBofulWorker = (
  team:
    | {
        id?: number;
        name?: string;
        company?: string;
      }
    | undefined
) => {
  if (!team) {
    return "-";
  }

  return `${team?.company || "-"} (${team?.name || "-"} / ${team?.id || "-"})`;
};

const formatNumberWithComma = (value: string | undefined) => {
  if (!value) {
    return "";
  }

  const [integerPart, decimalPart] = value.split(".");
  const integerWithComma = new Intl.NumberFormat("en-US").format(
    Number(integerPart)
  );

  if (value.endsWith(".")) {
    return integerWithComma + ".";
  }

  return `${integerWithComma}${decimalPart ? `.${decimalPart}` : ""}`;
};

function getReceivingStatusString(
  status: ReceivingStatus,
  inspectionStatus: InspectionStatus
) {
  switch (status) {
    // 입고 요청 리스트(Admin)
    case "beforeReceiving":
      return "도착 전";
    case "waitingInspection":
      return "검수 대기 중";

    // 입고 작업 리스트(Admin)
    case "inspecting":
      return "검수 중";
    // 검수 완료 (정상 / 불일치 / 동의)
    case "completeInspection":
      if (inspectionStatus === "hold") return "검수완료(이슈)";
      return "";
    case "putAway":
      /**
       * 정상 검수인 경우 putAway(inspectingStatus: 기본값 normal)로 상태가 바로 변경됨
       * 고객 동의를 받은 경우에는 putAway + inspectionStatus: consent 로 상태가 변경됨
       */
      return "입고 중";
    case "hold":
      return "전담매니저 확인 중";

    // 입고 완료 리스트(Admin)
    case "done":
      return "입고완료";

    // 입고 취소/회송 리스트(Admin)
    case "canceled":
      return "취소";
    case "returned":
      return "회송";

    default:
      return status;
  }
}

function toParcelCompanyLabel(parcelCompany?: FulfillmentParcelCompany) {
  switch (parcelCompany) {
    case "cj": {
      return "CJ대한통운";
    }
    case "hanjin": {
      return "한진택배";
    }
    case "post": {
      return "우체국택배";
    }
    case "daesin": {
      return "대신택배";
    }
    case "kunyoung": {
      return "건영택배";
    }
    case "chunil": {
      return "천일택배";
    }
    case "kd": {
      return "경동택배";
    }
    case "lotte":
      return "롯데택배";
    case "logen":
      return "로젠택배";
    case "ups":
      return "UPS";
    case "fedex":
      return "FEDEX";
    case "dhl":
      return "DHL";
    case "across_b":
      return "ACROSS_B";
    case "link_port":
      return "LINK_PORT";
    case "etc":
      return "기타";
    default: {
      return parcelCompany ?? "";
    }
  }
}

/**
 * 반품 & 출고 > 택배사(화물차량)을 반환
 */
function getDeliveryName({
  deliveryType,
  parcelCompany,
  truckType,
}: {
  deliveryType?: ShippingDeliveryType;
  parcelCompany?: FulfillmentParcelCompany;
  truckType?: CommonTruckType;
}) {
  if (
    deliveryType === "parcel" ||
    deliveryType === "freight" ||
    deliveryType === "airExpress"
  ) {
    return toParcelCompanyLabel(parcelCompany);
  }

  if (deliveryType === "truck") {
    return toTruckTypeLabel(truckType);
  }

  return "";
}

/**
 * 송장번호(차량번호)를 반환
 */
function getDeliveryNumberOfShipping({
  deliveryType,
  packings,
  trucking,
  invoiceNo,
  isFullList,
  needsArray,
}: {
  deliveryType?: ShippingDeliveryType;
  packings?: ShippingPacking[];
  trucking?: ShippingTrucking;
  invoiceNo?: string;
  isFullList?: boolean;
  needsArray?: boolean;
}) {
  if (
    deliveryType === "parcel" ||
    deliveryType === "freight" ||
    deliveryType === "airExpress"
  ) {
    if (!packings) return "";

    if (packings.length === 0) {
      return invoiceNo ?? "";
    }

    return getPackingsInvoiceNo({ packings, isFullList, needsArray });
  }

  if (deliveryType === "truck") {
    return trucking?.truckNo ?? "";
  }

  return "";
}

/**
 * packings 리스트에서 invoiceNo 반환
 */
function getPackingsInvoiceNo({
  packings,
  isFullList,
  needsArray,
}: {
  packings: ShippingPacking[];
  isFullList?: boolean;
  needsArray?: boolean;
}) {
  if (packings.length === 0) return "";

  if (packings.length === 1) return packings[0].invoiceNo ?? "";

  if (isFullList && needsArray) {
    return packings.map(({ invoiceNo }) => invoiceNo);
  }

  if (isFullList && !needsArray) {
    return packings.map(({ invoiceNo }) => invoiceNo).join(", ");
  }

  return `${packings[0].invoiceNo ?? ""} 외 ${packings.length - 1}건`;
}

/** 출고 & 반품 > 화물차종을 반환 */
function toTruckTypeLabel(truckType?: CommonTruckType) {
  switch (truckType) {
    case "motorcycle": {
      return "오토바이";
    }
    case "damas": {
      return "다마스";
    }
    case "labo": {
      return "라보";
    }
    case "van": {
      return "밴";
    }
    case "1ton": {
      return "1톤 트럭";
    }
    case "1ton_wingbody": {
      return "1톤 윙바디 트럭";
    }
    case "ton1_2_and_ton1_4": {
      return "1.2톤&1.4톤 트럭";
    }
    case "ton2_5_and_ton3_5":
    case "ton3_5_and_ton2_5": {
      return "2.5톤&3.5톤 트럭";
    }
    case "ton2_5_and_ton3_5_wingbody": {
      return "2.5톤&3.5톤 윙바디 트럭";
    }
    case "5ton": {
      return "5톤 트럭";
    }
    case "5ton_rotor": {
      return "5톤 축차";
    }
    case "5ton_wingbody": {
      return "5톤 윙바디 트럭";
    }
    case "ton7_5": {
      return "7.5톤 트럭";
    }
    case "11ton": {
      return "11톤 트럭";
    }
    case "14ton": {
      return "14톤 트럭";
    }
    case "25ton": {
      return "25톤 트럭";
    }
    default: {
      return "";
    }
  }
}

/**
 * 수량이 가장 많은 상품이 대표상품. ㅇㅇ등으로 표기.
 */
function getFulfillmentItemTitle(
  items?: { sku?: { itemName?: string }; quantity?: number }[]
) {
  if (!items || !items.length) return "-";
  if (items.length === 1) return items[0].sku?.itemName;

  const maxQuantityItem = items.reduce((prev, cur) =>
    (prev.quantity ?? 0) > (cur.quantity ?? 0) ? prev : cur
  );

  return `${maxQuantityItem.sku?.itemName} 등`;
}

function getStatusFromFailureInfo(
  failureInfo: ResponseFailureInfo | undefined
) {
  return failureInfo?.error?.split(": ")[1] ?? "";
}

/** shippingStatus 관련 에러 */
function getErrorMessageAsE4000({
  failureInfo,
}: {
  failureInfo: ResponseFailureInfo | undefined;
}) {
  const status = getStatusFromFailureInfo(failureInfo);

  return (
    {
      waiting: "정상적으로 출력되지 않은 송장(QR)입니다.",
      close: (
        // eslint-disable-next-line react/jsx-key
        <div style={{ color: COLOR.pointWarning }}>
          피킹/패킹이 필요없는 송장(QR)입니다.
          <br />
          출하존으로 이동시켜 주세요.
        </div>
      ),
      delivering: "배송 중인 송장(QR)입니다.",
      done: "배송이 완료된 송장(QR)입니다.",
      return: "반품된 송장(QR)입니다.",
      survey: "재고 조사 중인 송장(QR)입니다.",
    }[status] ?? "현재 작업 단계에 맞지 않은 송장(QR)입니다."
  );
}

/** wmsStatus 관련 에러 */
function getErrorMessageAsE4009({
  failureInfo,
}: {
  failureInfo: ResponseFailureInfo | undefined;
}) {
  const status = getStatusFromFailureInfo(failureInfo);

  return (
    {
      // 스캔 가능 상태 - 피킹 시작: waiting, picking
      packing:
        "피킹 작업이 완료되었으나, 패킹 작업이 완료되지 않은 송장(QR)입니다.",
      ready: (
        // eslint-disable-next-line react/jsx-key
        <div style={{ color: COLOR.pointWarning }}>
          피킹/패킹이 필요없는 송장(QR)입니다.
          <br />
          출하존으로 이동시켜 주세요.
        </div>
      ),
      done: "출하 완료된 송장(QR)입니다.",
      return: "반품된 송장(QR)입니다.",
      survey: "재고 조사 중인 송장(QR)입니다.",
    }[status] ?? "현재 작업 단계에 맞지 않은 송장(QR)입니다."
  );
}

function getMaterialPackageType(materialPackageType: MaterialPackageType) {
  switch (materialPackageType) {
    case "basic":
      return "기본포장";
    case "eco":
      return "친환경포장";
    case "customize":
      return "커스터마이징";
    case "none":
      return "포장불필요";
    default:
      return "";
  }
}

/**
 * SKU를 사용자에게 보여주는 형식으로 반환 (앞에 S를 붙임)
 */
function getFormattedSingleSkuId(skuId?: number) {
  if (!skuId) return "";

  return `S${skuId}`;
}

// 부자재 package category 한글로 변환
function getPackageCategoryName(packageCategory: PackageCategory) {
  if (packageCategory === "outside") return "외부포장";
  if (packageCategory === "buffer") return "완충재";
  if (packageCategory === "tape") return "테이프";

  return "-";
}

// 부자재 package type 한글로 변환
function getPackageTypeName(packageType: PackageType) {
  switch (packageType) {
    case "directInput":
      return "직접 입력";
    case "box":
      return "박스";
    case "polybag":
      return "폴리백";
    case "ecoBox":
      return "친환경박스";
    case "bubblePaper":
      return "친환경 버블페이퍼";
    case "airCap":
      return "에어캡";
    case "oppTape":
      return "OPP 테이프";
    case "craft":
      return "친환경 테이프";
    case "none":
      return "-";
    default:
      return packageType;
  }
}

function getMaterialIdTag(
  packageCategory: PackageCategory | undefined,
  packageType: PackageType | undefined,
  provider: ProviderType
) {
  if (provider === "shipda") {
    switch (packageCategory) {
      case "outside": {
        if (packageType === "box") return "EX";
        if (packageType === "ecoBox") return "EF";
        if (packageType === "polybag") return "EP";
        return "-";
      }

      case "buffer": {
        if (packageType === "airCap") return "BA";
        if (packageType === "bubblePaper") return "BP";
        return "-";
      }

      case "tape": {
        if (packageType === "oppTape") return "TO";
        if (packageType === "craft") return "TK";
        return "-";
      }

      default: {
        return "-";
      }
    }
  } else {
    switch (packageCategory) {
      case "outside": {
        return "EC";
      }

      case "buffer": {
        return "BS";
      }

      case "tape": {
        return "TN";
      }

      default: {
        return "-";
      }
    }
  }
}

/**
 * @returns 포장 접두어 Tag가 추가된 포장 ID 반환
 */
const getFormattedMaterialId = (
  packageItem: Pick<
    MaterialItem,
    "id" | "packageCategory" | "packageType" | "provider"
  >
) => {
  const packageCategoryType = ["outside", "buffer", "tape"];

  const isInPackageCategory = packageCategoryType.some(
    (packageCategory) => packageCategory === packageItem.packageCategory
  );

  if (isInPackageCategory) {
    return `${getMaterialIdTag(
      packageItem.packageCategory,
      packageItem.packageType,
      packageItem.provider
    )}${packageItem.id}`;
  }

  return String(packageItem.id);
};

/**
 * 그룹의 SKU ID를 사용자에게 보여주는 형식으로 변환 (앞에 G를 붙임)
 */
function getFormattedGroupSkuId(groupSkuId: number | undefined) {
  if (!groupSkuId) {
    return "";
  }

  return `G${groupSkuId}`;
}

// 입고 & 검수 오류 사유
function getReceivingAndInspectionProblemString(
  problemStatus: ReceivingAndInspectionProblem,
  directInput: string
) {
  switch (problemStatus) {
    case "none":
      return "문제없음";
    case "quantityMismatch":
      return "수량 불일치";
    case "productMismatch":
      return "상품 불일치";
    case "productDamage":
      return "상품 데미지";
    case "tollProcessing":
      return "임가공 문제";
    case "other":
      return "기타";
    case "directInput":
      return directInput;
    default:
      return problemStatus;
  }
}

// 상품 ReturningCondition(반품조건) type 한글로 변환
function getReturningConditionName(returningCondition?: ReturningCondition): {
  label: string;
  desc: string;
} {
  switch (returningCondition) {
    case "notOpenNotUsed":
      return {
        label: "소비자가 상품포장을 개봉하지 않았고, 상품사용 흔적이 없습니다.",
        desc: "센터 담당자가 육안으로 확인 시, 상품포장이 개봉되지 않았고 상품을 사용한 흔적이 없다고 판단하는 경우 정상입고 처리합니다.",
      };
    case "openNotUsed":
      return {
        label: "소비자가 상품포장을 개봉했으나, 상품사용 흔적이 없습니다.",
        desc: "센터 담당자가 육안으로 확인 시, 상품포장은 개봉되었으나 상품을 사용한 흔적이 없다고 판단하는 경우 정상입고 처리합니다.",
      };
    case "openAndUsed":
      return {
        label: "소비자가 상품포장을 개봉했고, 상품사용 흔적이 있습니다.",
        desc: "센터 담당자가 육안으로 확인 시, 상품포장이 개봉되었고 상품을사용한 흔적이 있더라도 정상입고 처리합니다.",
      };
    default:
      return {
        label: returningCondition || "",
        desc: "",
      };
  }
}

/**
 * 비정상 상품 조건 : inspectProblems 에 비정상 상품에 대한 정보가 존재하는 경우. (불일치상품 제외)
 */
const checkForProblemItem = (item: { inspectProblems: InspectProblem[] }) => {
  return (
    !!item.inspectProblems.length &&
    !!item.inspectProblems.every((problem) => problem.problem !== "불일치상품")
  );
};

/**
 * 불일치 상품 조건 : inspectProblems의 객체에 problem:"불일치상품" 이 포함되어있는 경우.
 */
const checkForUnverifiedItem = (
  item:
    | {
        inspectProblems?: InspectProblem[];
      }
    | undefined
) => {
  if (!item || !item.inspectProblems) {
    return false;
  }

  return item.inspectProblems.some(
    (problem) => problem.problem === "불일치상품"
  );
};

/**
 * 검수 작업 중에는 아직 고객이 상품을 매칭하기 전이므로 SKU ID 유무로 불일치 상품을 검사할 수 있음
 */
const checkForUnverifiedItemAsInspection = (item: {
  skuId: number | undefined;
}) => !item.skuId;

/**
 * 검수 작업 중 불일치 상품이 아닌 일반 아이템 여부를 확인
 */
const checkForNormalItemAsInspection = (item: { skuId: number | undefined }) =>
  !!item.skuId;

/**
 * 정상 상품 조건 : inspectProblems의 객체에 data가 없는 경우.
 */
const checkForNormalItem = (item: { inspectProblems: InspectProblem[] }) => {
  return !item.inspectProblems.length;
};

export {
  getReceivingAndInspectionProblemString,
  getFormattedMaterialId,
  getFormattedGroupSkuId,
  getFormattedSingleSkuId,
  getMaterialIdTag,
  getPackageCategoryName,
  getPackageTypeName,
  getMaterialPackageType,
  getStatusFromFailureInfo,
  getErrorMessageAsE4000,
  getErrorMessageAsE4009,
  getFulfillmentItemTitle,
  getDeliveryNumberOfShipping,
  toTruckTypeLabel,
  getDeliveryName,
  getPackingsInvoiceNo,
  getReceivingStatusString,
  AuthorityLabelDict,
  getSKUItemTypeQuantity,
  getWorkerNameByAuthority,
  getLocationBarcodeById,
  getWorkerNameById,
  getSkuIds,
  getPcs,
  getGroupItems,
  getGroupItemsV2,
  getGroupItemsWithTotalQuantity,
  getGroupItemsWithTotalQuantityV2,
  getGroupDataByType,
  getDetailSkuIdList,
  getItemsWithoutGroupItemsQuantity,
  accumulateQuantity,
  reduceQuantity,
  getSkuIdListForAdmin,
  getOrderQuantity,
  getShippingQuantity,
  getShippingQuantityV2,
  getShippingQuantityForShipdaCSV,
  getShippingQuantityForShipdaCSVV2,
  getFormattedSkuId,
  checkHasQuantity,
  getFilteredItemsByQuantity,
  getProductGroupIdsForTooltip,
  getProductGroupIdsForTooltipV2,
  getBarcodeList,
  checkIsSupportedParcelCompany,
  checkIsGroupSkuItem,
  mergeItemsBySkuId,
  updateSearchQueryParams,
  getFormattedSearchItem,
  handleSearchStateUpdate,
  getFormattedCustomerAddress,
  getPayloadByFilter,
  getChannelName,
  getTeamLabelForBofulWorker,
  formatNumberWithComma,
  toParcelCompanyLabel,
  getReturningConditionName,
  checkForProblemItem,
  checkForUnverifiedItem,
  checkForNormalItem,
  checkForUnverifiedItemAsInspection,
  checkForNormalItemAsInspection,
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import { toThousandUnitFormat } from "utils/number";

import { ParcelCompanyListForPrint, PrintInvoiceDeliveryCategory } from ".";

export default function SelectParcelCompanyModal({
  deliveryCategory,
  selectedShippingCount,
  isSelectParcelCompanyModalOpen,
  onSelectParcelCompanyModalClose,
  needsNumberingWhenPrintingInvoice,
  parcelCompany,
  onParcelCompanyChange,
  onPrint,
}: {
  deliveryCategory: PrintInvoiceDeliveryCategory;
  selectedShippingCount: number;
  isSelectParcelCompanyModalOpen: boolean;
  onSelectParcelCompanyModalClose: () => void;
  needsNumberingWhenPrintingInvoice: boolean;
  parcelCompany?: ParcelCompanyListForPrint;
  onParcelCompanyChange?: (
    event: SelectChangeEvent<ParcelCompanyListForPrint>
  ) => void;
  onPrint: (parcelCompany?: ParcelCompanyListForPrint) => () => void;
}) {
  return (
    <Dialog
      open={isSelectParcelCompanyModalOpen}
      onClose={onSelectParcelCompanyModalClose}
    >
      <DialogTitle>
        출고 {toThousandUnitFormat(selectedShippingCount)}건의 송장을
        출력하시겠습니까?
        {needsNumberingWhenPrintingInvoice && (
          <Typography sx={{ color: red[700] }}>
            (출력시 '송장 채번'이 진행됩니다)
          </Typography>
        )}
      </DialogTitle>

      {needsNumberingWhenPrintingInvoice && (
        <DialogContent>
          {deliveryCategory === "domesticParcel" ? (
            <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
              <InputLabel id="parcel-company-select-label">택배사</InputLabel>

              <Select
                labelId="parcel-company-select-label"
                id="parcel-company-select"
                value={parcelCompany}
                label="택배사"
                onChange={onParcelCompanyChange}
              >
                <MenuItem value="default">지정택배사</MenuItem>
                <MenuItem value="cj">CJ대한통운</MenuItem>
                <MenuItem value="hanjin">한진택배</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
              <InputLabel id="parcel-company-select-label">택배사</InputLabel>

              <Select
                labelId="parcel-company-select-label"
                id="parcel-company-select"
                value={parcelCompany}
                label="택배사"
                onChange={onParcelCompanyChange}
              >
                <MenuItem value="default">지정택배사</MenuItem>
                <MenuItem value="fedex">FEDEX</MenuItem>
                <MenuItem value="ups">UPS</MenuItem>
              </Select>
            </FormControl>
          )}
        </DialogContent>
      )}

      <DialogActions>
        {needsNumberingWhenPrintingInvoice ? (
          <Button
            variant="contained"
            onClick={onPrint(parcelCompany)}
            disabled={!parcelCompany}
          >
            다음
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={onPrint()}
            disabled={!parcelCompany}
          >
            출력하기
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

import { memo } from "react";
import { Stack, TableCell } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import { TableHeadCell } from "..";

type PinnedHeadCell = {
  isPinned: true;
  leftPosition: number;
  isLastPinnedCell: boolean;
};

type DefaultHeadCell = {
  isPinned: false;
};

function MemoizedHeadCell<CellId extends string>({
  label,
  disablePadding,
  width,
  filter,

  hasGroupedHeadRow,

  ...propsByIsPinned
}: Omit<TableHeadCell<CellId>, "id"> & {
  hasGroupedHeadRow: boolean;
} & (PinnedHeadCell | DefaultHeadCell)) {
  const { isPinned } = propsByIsPinned;

  if (!isPinned) {
    return (
      <TableCell
        padding={disablePadding ? "none" : "normal"}
        sx={{
          width,
          minWidth: width,
          backgroundColor: blue[50],
          fontWeight: 700,

          ...(hasGroupedHeadRow && { top: 37 }),
        }}
      >
        <Stack flexDirection="row" gap={0.5} alignItems="center">
          {label}

          {filter}
        </Stack>
      </TableCell>
    );
  }

  const { leftPosition, isLastPinnedCell } = propsByIsPinned;

  return (
    <TableCell
      padding={disablePadding ? "none" : "normal"}
      sx={{
        width,
        minWidth: width,
        backgroundColor: blue[50],
        fontWeight: 700,

        ...(hasGroupedHeadRow && { top: 37 }),

        position: "sticky",
        top: hasGroupedHeadRow ? 37 : 0,
        left: leftPosition,
        zIndex: 10,

        ...(isLastPinnedCell && { borderRight: `1px solid ${grey[300]}` }),
      }}
    >
      <Stack flexDirection="row" gap={0.5} alignItems="center">
        {label}

        {filter}
      </Stack>
    </TableCell>
  );
}

export default memo(MemoizedHeadCell);

import { useCallback, useState } from "react";

export default function useAdminMemoModal() {
  const [requestId, setRequestId] = useState(0);
  const [memo, setMemo] = useState("");

  const showAdminMemoModal = useCallback((requestId: number) => {
    setRequestId(requestId);
  }, []);

  const resetRequestId = useCallback(() => {
    setRequestId(0);
  }, []);

  const resetMemo = useCallback(() => {
    setMemo("");
  }, []);

  const handleClose = useCallback(() => {
    setRequestId(0);
    setMemo("");
  }, []);

  const handleMemoChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMemo(e.target.value);
    },
    []
  );

  return {
    showAdminMemoModal,

    handleClose,
    handleMemoChange,

    resetRequestId,
    resetMemo,

    requestId,
    memo,
  };
}

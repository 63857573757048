import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";

const filterOptionList: TableHeadFilterOption<boolean>[] = [
  { label: "O", value: true },
  { label: "X", value: false },
];

export default function useHeteromorphicFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: HeteromorphicFilterPanel,
    filterList: heteromorphicFilterList,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    HeteromorphicFilterPanel,
    heteromorphicPayloadListByFilter: heteromorphicFilterList,
  };
}

import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { GET_TRUCK_DELIVERY_DETAIL_RES } from "api-interfaces/shipping";
import Button from "components/sds-v1/button/Button";
import { InputSelectOption } from "headlessComponents/input/useInputSelect";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { FulfillmentTruckCompany } from "types/fulfillment";
import { ShippingTruckType, TruckCompanyType } from "types/shipping";
import { handleFormChange } from "utils/common";
import { getValueAsNumberOnly } from "utils/validation";
import {
  TRUCK_COMPANY_OPTION_LIST,
  TRUCK_TYPE_OPTION_LIST,
} from "utils/shipping";
import InputSelect from "components/sds-v1/input/InputSelect";
import InputText from "components/sds-v1/input/InputText";

import Styled from "./index.styles";

interface TruckingDeliveryDetail {
  truckCompany: Exclude<TruckCompanyType, "all"> | undefined;
  truckType: ShippingTruckType | undefined;
  driverName: string | undefined;
  driverPhoneNo: string | undefined;
  truckNo: string | undefined;
  purchasePrice: number | undefined;
  billingPrice: number | undefined;
}

export default function InputTruckModal({
  shippingId,
  truckDeliveryDetailData,
  onModalClose,
}: {
  shippingId: number;
  truckDeliveryDetailData: GET_TRUCK_DELIVERY_DETAIL_RES | undefined;
  onModalClose: () => void;
}) {
  const [truckDeliveryDetail, setTruckDeliveryDetail] =
    useState<TruckingDeliveryDetail>(() => ({
      truckCompany: truckDeliveryDetailData?.truckCompany,
      truckType: truckDeliveryDetailData?.truckType,
      driverName: truckDeliveryDetailData?.driverName,
      truckNo: truckDeliveryDetailData?.truckNo,
      driverPhoneNo: truckDeliveryDetailData?.driverPhoneNo,
      purchasePrice: truckDeliveryDetailData?.purchasePrice,
      billingPrice: truckDeliveryDetailData?.billingPrice,
    }));

  const queryClient = useQueryClient();

  const {
    mutate: updateTruckDeliveryDetail,
    ResponseHandler: ResponseHandlerOfUpdatingTruckDeliveryDetail,
  } = SHIPPING_QUERY.useUpdateTruckDeliveryDetail({ shippingId });

  const handleTruckCompanySelect = useCallback(
    (selectedTruckCompany: InputSelectOption<FulfillmentTruckCompany>) => {
      setTruckDeliveryDetail((prev) => ({
        ...prev,
        truckCompany: selectedTruckCompany.value,
      }));
    },
    []
  );

  const handleTruckTypeSelect = useCallback(
    (selectedTruckType: InputSelectOption<ShippingTruckType>) => {
      setTruckDeliveryDetail((prev) => ({
        ...prev,
        truckType: selectedTruckType.value,
      }));
    },
    []
  );

  const handleTruckDeliveryDetailChange = handleFormChange(
    setTruckDeliveryDetail
  );

  const handleDriverPhoneNoChange = useCallback((value: string | undefined) => {
    setTruckDeliveryDetail((prev) => ({
      ...prev,
      driverPhoneNo: getValueAsNumberOnly(value),
    }));
  }, []);

  const isValidate = useMemo(() => {
    const { truckCompany, truckType, driverName, driverPhoneNo, truckNo } =
      truckDeliveryDetail;

    return (
      !!truckCompany &&
      !!truckType &&
      !!driverName &&
      !!driverPhoneNo &&
      !!truckNo
    );
  }, [truckDeliveryDetail]);

  const handleTruckDeliveryDetailSave = useCallback(() => {
    const {
      truckCompany,
      truckType,
      driverName,
      driverPhoneNo,
      truckNo,
      purchasePrice,
      billingPrice,
    } = truckDeliveryDetail;

    updateTruckDeliveryDetail(
      {
        // isValidated에서 검사
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        truckCompany: truckCompany!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        truckType: truckType!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        driverName: driverName!,
        driverPhoneNo: String(driverPhoneNo),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        truckNo: truckNo!,
        purchasePrice,
        billingPrice,
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(SHIPPING_QUERY_KEY_GEN.adminShipping())
            .then(() => {
              // 이전 데이터 삭제 시켜줘야 수정 후 바로 돌아왔을 때 수정된 데이터가 반영된다.
              queryClient.removeQueries(
                SHIPPING_QUERY_KEY_GEN.getTruckDeliveryDetail({ shippingId })
              );
            })
            .then(onModalClose);
        },
      }
    );
  }, [
    onModalClose,
    queryClient,
    shippingId,
    truckDeliveryDetail,
    updateTruckDeliveryDetail,
  ]);

  return (
    <>
      <Dialog open onClose={onModalClose}>
        <DialogTitle>배송정보 수기 입력</DialogTitle>

        <DialogContent dividers>
          <Styled.inputTruckModalBody>
            <InputSelect
              isRequired
              label="운송사"
              uiType="outline"
              optionList={TRUCK_COMPANY_OPTION_LIST}
              selectedOption={TRUCK_COMPANY_OPTION_LIST.find(
                ({ value }) => value === truckDeliveryDetail.truckCompany
              )}
              handleSelect={handleTruckCompanySelect}
            />

            <InputSelect
              isRequired
              label="차량정보"
              uiType="outline"
              optionList={TRUCK_TYPE_OPTION_LIST}
              selectedOption={TRUCK_TYPE_OPTION_LIST.find(
                ({ value }) => value === truckDeliveryDetail.truckType
              )}
              handleSelect={handleTruckTypeSelect}
            />

            <Styled.driverDetail>
              <InputText
                isRequired
                label="기사명"
                borderType="outline"
                valueType="string"
                value={truckDeliveryDetail.driverName}
                setValue={handleTruckDeliveryDetailChange("driverName")}
              />
              <InputText
                isRequired
                label="연락처"
                borderType="outline"
                valueType="string"
                value={truckDeliveryDetail.driverPhoneNo}
                setValue={handleDriverPhoneNoChange}
              />
              <InputText
                isRequired
                label="차량번호"
                borderType="outline"
                valueType="string"
                value={truckDeliveryDetail.truckNo}
                setValue={handleTruckDeliveryDetailChange("truckNo")}
              />
            </Styled.driverDetail>

            <InputText
              label="매입가"
              borderType="outline"
              valueType="int"
              value={truckDeliveryDetail.purchasePrice}
              setValue={handleTruckDeliveryDetailChange("purchasePrice")}
            />
            <InputText
              label="청구가"
              borderType="outline"
              valueType="int"
              value={truckDeliveryDetail.billingPrice}
              setValue={handleTruckDeliveryDetailChange("billingPrice")}
            />
          </Styled.inputTruckModalBody>
        </DialogContent>

        <DialogActions>
          <Button
            label="저장"
            size="block"
            theme="primary"
            handleClick={handleTruckDeliveryDetailSave}
            disabled={!isValidate}
          />
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfUpdatingTruckDeliveryDetail}
    </>
  );
}

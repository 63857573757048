import { ChangeEvent, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";

import RETURNING_QUERY, {
  RETURNING_QUERY_KEY_GEN,
} from "queries/RETURNING_QUERY";
import { ReturningListItem } from "types/returning";
import { FulfillmentParcelCompany } from "types/fulfillment";
import { toParcelCompanyLabel } from "utils/fulfillment";

export default function EditSenderDetailModal({
  returningDetail,
  showsEditSenderDetailModal,
  onEditSenderDetailModalClose,
}: {
  returningDetail: ReturningListItem;
  showsEditSenderDetailModal: boolean;
  onEditSenderDetailModalClose: () => void;
}) {
  const [{ mobile }, setSenderDetail] = useState({
    mobile: returningDetail.mobile ?? "",
  });

  const originalInvoiceNoList = returningDetail.returningPackings?.map(
    ({ id, invoiceNo }) => ({ id, invoiceNo: invoiceNo ?? "" })
  );

  const [invoiceNoList, setInvoiceNoList] = useState(originalInvoiceNoList);
  const [parcelCompany, setParcelCompany] = useState<
    FulfillmentParcelCompany | ""
  >(returningDetail.parcelCompany ?? "");

  const supportedParcelCompanies: FulfillmentParcelCompany[] = [
    "cj",
    "post",
    "hanjin",
    "daesin",
    "kunyoung",
    "chunil",
    "kd",
    "logen",
    "lotte",
    "ups",
    "etc",
  ];

  const queryClient = useQueryClient();

  const {
    mutate: editSenderDetail,
    ResponseHandler: ResponseHandlerOfEditSenderDetail,
  } = RETURNING_QUERY.useEditSenderDetail({
    returningId: returningDetail?.id,
  });

  const handleMobileFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSenderDetail((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleParcelCompanyChange = (e: SelectChangeEvent) => {
    setParcelCompany(e.target.value as FulfillmentParcelCompany);
  };

  const handleInvoiceNoTextFiledChange =
    (id: number) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInvoiceNoList((prev) =>
        prev?.map((item) => {
          if (item.id === id) {
            return { ...item, invoiceNo: e.target.value };
          }

          return item;
        })
      );
    };

  const handleSenderDetailEdit = () => {
    editSenderDetail(
      {
        mobile,
        invoiceNo: invoiceNoList,
        ...(parcelCompany ? { parcelCompany } : {}),
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(RETURNING_QUERY_KEY_GEN.adminReturning())
            .then(onEditSenderDetailModalClose);
        },
      }
    );
  };

  const isChangedMobile = mobile !== returningDetail.mobile;

  const isChangedInvoiceNoList = useMemo(() => {
    return invoiceNoList?.some(({ id, invoiceNo }) => {
      const originalInvoiceNo = originalInvoiceNoList?.find(
        (item) => item.id === id
      )?.invoiceNo;

      return originalInvoiceNo !== invoiceNo;
    });
  }, [invoiceNoList, originalInvoiceNoList]);

  const isChangedParcelCompany =
    returningDetail.parcelCompany &&
    parcelCompany !== returningDetail.parcelCompany;

  const isValid =
    isChangedMobile || isChangedInvoiceNoList || isChangedParcelCompany;

  return (
    <>
      <Dialog
        open={showsEditSenderDetailModal}
        onClose={onEditSenderDetailModalClose}
      >
        <DialogTitle>반품 배송 정보 수정</DialogTitle>

        <DialogContent dividers>
          <Stack direction="column" spacing={2} mt={1} sx={{ minWidth: 500 }}>
            <span>반품 보낸 분 연락처</span>

            <TextField
              fullWidth
              name="mobile"
              value={mobile}
              onChange={handleMobileFieldChange}
            />

            {!!returningDetail.returningPackings?.length && (
              <>
                <span>반품송장번호 (차량번호)</span>

                {invoiceNoList?.map(({ id, invoiceNo }) => (
                  <TextField
                    key={`invoice-no-${id}`}
                    fullWidth
                    name={`invoice-no-${id}`}
                    value={invoiceNo ?? ""}
                    onChange={handleInvoiceNoTextFiledChange(id)}
                  />
                ))}
              </>
            )}

            {!!returningDetail.returningPackings?.length && (
              <>
                <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                  <InputLabel>반품택배사</InputLabel>
                  <Select
                    value={parcelCompany}
                    label="반품택배사"
                    onChange={handleParcelCompanyChange}
                  >
                    {supportedParcelCompanies.map((v) => (
                      <MenuItem value={v} key={`parcel-company-${v}`}>
                        {toParcelCompanyLabel(v)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button variant="outlined" onClick={onEditSenderDetailModalClose}>
            취소
          </Button>

          <Button
            variant="contained"
            onClick={handleSenderDetailEdit}
            disabled={!isValid}
          >
            수정
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfEditSenderDetail}
    </>
  );
}

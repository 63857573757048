import { useState } from "react";
import { Button } from "@mui/material";

import { ShippingItemDetail } from "types/shipping";

import EditCustomerDetailModal from "./EditCustomerDetailModal";

export default function EditCustomerDetail({
  shippingDetail,
}: {
  shippingDetail: ShippingItemDetail | undefined;
}) {
  const [showsEditCustomerDetailModal, setShowsEditCustomerDetailModal] =
    useState(false);

  const handleEditCustomerDetailModalClose = () => {
    setShowsEditCustomerDetailModal(false);
  };

  const handleEditCustomerDetailModalOpen = () => {
    setShowsEditCustomerDetailModal(true);
  };

  if (!shippingDetail) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" onClick={handleEditCustomerDetailModalOpen}>
        배송 정보 수정
      </Button>

      <EditCustomerDetailModal
        key={String(showsEditCustomerDetailModal)}
        shippingDetail={shippingDetail}
        showsEditCustomerDetailModal={showsEditCustomerDetailModal}
        onEditCustomerDetailModalClose={handleEditCustomerDetailModalClose}
      />
    </>
  );
}

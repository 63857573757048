import { useCallback, useMemo } from "react";
import { Button } from "@mui/material";
import { ADMIN_INVENTORY_ADJUSTMENT_REQ } from "api-interfaces/inventory";
import INVENTORY_QUERY from "queries/INVENTORY_QUERY";

import ConfirmWrapper from "components/sds-v1/ConfirmWrapper";

import Styled from "../index.styles";
import { InventoryDetailModifyForm } from "./useFormState";

export default function useUpdateInventory({
  skuId,
  formState,
  TotalDifference,
  hasSameTotal,
  isManagedSku,
  onUpdateSuccess,
}: {
  skuId: number | undefined;
  formState: InventoryDetailModifyForm | null;
  TotalDifference: React.ReactNode;
  hasSameTotal: boolean;
  isManagedSku: boolean;
  onUpdateSuccess: () => void;
}) {
  const {
    mutate: updateInventory,
    ResponseHandler: ResponseHandlerOfUpdateInventory,
  } = INVENTORY_QUERY.useAdminInventoryAdjustment({
    onSuccess: onUpdateSuccess,
  });

  const isValidated = useMemo(() => {
    if (!formState?.inventory) return false;

    if (!formState?.reason) return false;

    const valuesArr = Array.from(formState.inventory.values());

    const hasChangedInventory = valuesArr.some((v) => {
      if (v.isDraft) return true;

      return v.originQty !== v.adjustmentQty;
    });
    if (!hasChangedInventory) return false;

    const everyInventoryIsValid = valuesArr.every((v) => {
      // isDraft면서, 업데이트 수치가 0인것은 없어야함
      if (v.isDraft && !v.adjustmentQty) {
        return false;
      }

      // 관리일자가 사용되는 SKU이면서 isDraft이면서 관리일자가 없는것은 없어야함
      if (isManagedSku && v.isDraft && !v.managementDate) {
        return false;
      }
      return true;
    });

    if (!everyInventoryIsValid) return false;

    return true;
  }, [formState?.inventory, formState?.reason]);

  const handleSubmit = useCallback(() => {
    if (!isValidated) return;

    if (!skuId) return;

    const inventoryPayload: Pick<
      ADMIN_INVENTORY_ADJUSTMENT_REQ,
      "inventoryFrom" | "inventoryTo"
    > = {
      inventoryFrom: [],
      inventoryTo: [],
    };
    formState?.inventory.forEach((v) => {
      const isChanged = v.originQty !== v.adjustmentQty;

      // inventoryFrom에는 변화가 있는 값만 보냄(새로 추가되는 건 안 보냄)
      if (isChanged && !v.isDraft) {
        inventoryPayload.inventoryFrom.push({
          locationId: v.locationId as number,
          quantity: v.originQty,
        });
      }

      // inventoryTo에는 변화가 있는 값만 보냄(새로 추가되는 것도 보냄)
      if (isChanged) {
        inventoryPayload.inventoryTo.push({
          locationId: v.locationId as number,
          quantity: v.adjustmentQty,
          warehouseId: 1, // 당분간은 인천1센터밖에 없으므로 1로 고정
          ...(v.managementDate ? { managementDate: v.managementDate } : {}),
        });
      }
    });

    updateInventory({
      skuId,
      inventoryFrom: inventoryPayload.inventoryFrom,
      inventoryTo: inventoryPayload.inventoryTo,
      reason: formState?.reason || "",
    });
  }, [formState, isValidated, skuId, updateInventory]);

  const UpdateInventoryButton = useCallback(() => {
    return (
      <>
        <ConfirmWrapper
          usePortal
          confirmMessageTitle={"재고조정을 하시겠습니까?"}
          confirmModalUiType="content"
          confirmMessageBody={
            <Styled.confirmBodyForUpdate>
              {!hasSameTotal && (
                <div className="warning">
                  *현재재고의 합과 조정재고의 합이 일치하지 않습니다.
                </div>
              )}

              <div className="difference">{TotalDifference}</div>
            </Styled.confirmBodyForUpdate>
          }
          disabled={!isValidated}
        >
          <Button
            key={"confirm"}
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSubmit}
            disabled={!isValidated}
          >
            수정 완료
          </Button>
        </ConfirmWrapper>

        {ResponseHandlerOfUpdateInventory}
      </>
    );
  }, [
    ResponseHandlerOfUpdateInventory,
    TotalDifference,
    handleSubmit,
    hasSameTotal,
    isValidated,
  ]);

  return { UpdateInventoryButton };
}

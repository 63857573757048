import { memo, useState } from "react";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import AddLocationDialog from "./AddLocationDialog";

export default memo(function AddLocation() {
  const [showsAddLocationDialog, setShowsAddLocationDialog] = useState(false);

  const handleAddLocationDialogOpen = () => {
    setShowsAddLocationDialog(true);
  };

  const handleAddLocationDialogClose = () => {
    setShowsAddLocationDialog(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={handleAddLocationDialogOpen}
      >
        등록
      </Button>

      <AddLocationDialog
        showsAddLocationDialog={showsAddLocationDialog}
        onAddLocationDialogClose={handleAddLocationDialogClose}
      />
    </>
  );
});

import { useState } from "react";

import { TableTabInfo } from "components/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import GroupProductTable from "./GroupProductTable";
import PackageTable from "./PackageTable";
import SingleProductTable from "./SingleProductTable";

const tabList: TableTabInfo["tabList"] = [
  { label: "단일상품" },
  { label: "그룹상품" },
  { label: "부자재" },
];

const SINGLE_PRODUCT_TAB_INDEX = 0;
const GROUP_PRODUCT_TAB_INDEX = 1;
const PACKAGE_TAB_INDEX = 2;

function ProductList() {
  const [activeTabIndex, setActiveTabIndex] = useState(
    SINGLE_PRODUCT_TAB_INDEX
  );

  const tableTabInfo: TableTabInfo = {
    tabList,
    activeTabIndex,
    setActiveTabIndex,
  };

  const showsSingleProductTable = activeTabIndex === SINGLE_PRODUCT_TAB_INDEX;
  const showsGroupProductTable = activeTabIndex === GROUP_PRODUCT_TAB_INDEX;
  const showsPackageTable = activeTabIndex === PACKAGE_TAB_INDEX;

  return (
    <Layout>
      {showsSingleProductTable && (
        <SingleProductTable tableTabInfo={tableTabInfo} />
      )}
      {showsGroupProductTable && (
        <GroupProductTable tableTabInfo={tableTabInfo} />
      )}
      {showsPackageTable && <PackageTable tableTabInfo={tableTabInfo} />}
    </Layout>
  );
}

export default withRequireAuth(ProductList);

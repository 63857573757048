import { ReactElement, useCallback, useEffect, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";

import useWindowResize from "hooks/useWindowResize";

import Styled from "./index.styles";

type Row<T> = ({
  data,
  index,
}: {
  data: T[];
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => ReactElement<any, any> | null;

function WindowedRow<T>({
  data,
  index,
  setSize,
  windowWidth,
  Row,
}: {
  data: T[];
  index: number;
  setSize: (index: number, size: number) => void;
  windowWidth: number;
  Row: Row<T>;
}) {
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSize(index, rowRef.current?.getBoundingClientRect().height ?? 0);
  }, [setSize, index, windowWidth]);

  return (
    <div ref={rowRef}>
      <Row data={data} index={index} />
    </div>
  );
}

export default function ReactWindowList<T extends { rowKey: string | number }>({
  height,
  scrollWidth,
  Row,
  dataList,
  rowKeyToHighlight,
  className,
}: {
  height: number;
  scrollWidth: number;
  Row: Row<T>;
  dataList: T[] | undefined;
  rowKeyToHighlight?: string | number;
  className?: string;
}) {
  const listRef = useRef<VariableSizeList | null>(null);
  const sizeMap = useRef<{ [key: string]: number }>({});

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index);
  }, []);
  const getSize = useCallback(
    (index: number) => sizeMap.current[index] ?? 50,
    []
  );

  const [windowWidth] = useWindowResize();

  useEffect(() => {
    if (!rowKeyToHighlight) return;
    if (!dataList) return;

    const indexToHighlight = dataList.findIndex(
      (v) => v.rowKey === rowKeyToHighlight
    );

    if (indexToHighlight >= 0) {
      listRef.current?.scrollToItem(indexToHighlight, "center");
    }
  }, [rowKeyToHighlight, dataList]);

  return (
    <Styled.container
      className={`${className ? className : ""} react-window-list`}
      style={{ height: `${height}px` }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            ref={listRef}
            itemCount={dataList?.length ?? 0}
            itemData={dataList}
            height={height}
            width={width}
            overscanCount={6}
            itemSize={getSize}
            itemKey={(index, data) => data[index].rowKey}
            className="variable-size-list"
            style={{
              // width를 총 스크롤가능한 width로 고정해줘야 테이블 헤더와 따로 놀지 않는다
              width: `${scrollWidth}px`,
            }}
          >
            {({ data, index, style }) => (
              <div style={style}>
                <WindowedRow
                  data={data}
                  index={index}
                  setSize={setSize}
                  windowWidth={windowWidth}
                  Row={Row}
                />
              </div>
            )}
          </VariableSizeList>
        )}
      </AutoSizer>
    </Styled.container>
  );
}

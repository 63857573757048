import { useCallback, useMemo, useState } from "react";

import COMMON_QUERY from "queries/COMMON_QUERY";
import { FulfillmentAttachment } from "types/fulfillment";
import { CommonSKU } from "types/sku";
import {
  getFormattedGroupSkuId,
  getFormattedMaterialId,
  getFormattedSingleSkuId,
  getPackageCategoryName,
  getPackageTypeName,
} from "utils/fulfillment";
import ImageSliderModal from "components/sds-v1/ImageSliderModal";

import Styled from "./index.styles";

type SkuForImageModalByType = SingleSkuForImageModal | GroupSkuForImageModal;
interface SingleSkuForImageModal {
  type: "single";
  itemName: string;
}
interface GroupSkuForImageModal {
  type: "group";
  groupName: string;
}

type SkuForImageModal = Pick<CommonSKU, "packages" | "id"> & {
  attachment?: FulfillmentAttachment[];
};

export type SkuImageModalImageType = "item" | "package";

export default function useSkuImageModal() {
  const [sku, setSku] = useState<SkuForImageModal & SkuForImageModalByType>();
  const [fileKeyList, setFileKeyList] = useState<string[]>();

  const [imageType, setImageType] = useState<SkuImageModalImageType>();

  const resetLocalState = useCallback(() => {
    setSku(undefined);
    setImageType("item");
    setFileKeyList([]);
  }, []);

  const {
    data: fileUrlList,
    ResponseHandler: ResponseHandlerOfGetFileUrlList,
  } = COMMON_QUERY.useGetFileUrlList({
    key: fileKeyList || [],
    enabled: !!fileKeyList?.length,
  });

  const imageUrlDict = useMemo(() => {
    const dict: { [k in string]: string } = {};

    fileUrlList?.forEach((f) => {
      dict[f.key] = f.url;
    });

    return dict;
  }, [fileUrlList]);

  const handleSkuForImageModalSelect = useCallback(
    ({
      skuData,
      imageType,
    }: {
      // TODO: 출고, 반품 개발 후 필수 인자로 수정해야 함
      skuData?: (SkuForImageModal & SkuForImageModalByType) | undefined;
      imageType?: SkuImageModalImageType | undefined;
    }) => {
      if (!skuData) {
        return;
      }

      // fileUrl이 필요한 key들을 모두 추려냄
      const fileKeyListFromData: string[] = [];

      if (imageType === "item") {
        skuData.attachment?.forEach((itemAttachment) => {
          fileKeyListFromData.push(itemAttachment.key);
        });
      }

      if (imageType === "package") {
        skuData.packages?.forEach((itemPackage) => {
          // 부자재는 첫번째 이미지만 쓴다
          if (itemPackage.attachment && itemPackage.attachment[0]?.key) {
            fileKeyListFromData.push(itemPackage.attachment[0].key);
          }
        });
      }

      if (fileKeyListFromData.length) {
        setFileKeyList(fileKeyListFromData);
      }

      setSku(skuData);
      setImageType(imageType);
    },
    []
  );

  const handleModalClose = useCallback(() => {
    resetLocalState();
  }, [resetLocalState]);

  const SkuImageModal = () => {
    if (!sku) return null;

    const imagePaths: string[] = [];

    if (imageType === "item") {
      sku.attachment?.forEach((a) => {
        const imgUrl = imageUrlDict[a.key];

        if (imgUrl) {
          imagePaths.push(imgUrl);
        }
      });
    }

    if (imageType === "package") {
      sku.packages?.forEach((p) => {
        // 부자재는 첫번째 이미지만 쓴다
        const imgUrl =
          p.attachment && p.attachment[0]
            ? imageUrlDict[p.attachment[0].key]
            : "";

        if (imgUrl) {
          imagePaths.push(imgUrl);
        }
      });
    }

    return (
      <>
        <ImageSliderModal
          active
          initialSlideIndex={0}
          title={
            <Styled.imageSliderHeader>
              <div className="item-name">
                {(() => {
                  if (sku.type === "single") {
                    return getFormattedSingleSkuId(sku.id);
                  }

                  if (sku.type === "group") {
                    return getFormattedGroupSkuId(sku.id);
                  }

                  return "";
                })()}
                <br />
                {(() => {
                  if (sku.type === "single") {
                    return sku.itemName;
                  }

                  if (sku.type === "group") {
                    return sku.groupName;
                  }

                  return "";
                })()}
              </div>

              {imageType === "package" && (
                <div className="package-info">
                  {sku.packages?.map((v, i) => {
                    const packageCategoryName = getPackageCategoryName(
                      v.packageCategory
                    );

                    const hasPackageCategory = packageCategoryName !== "-";

                    return (
                      <div className="item" key={i}>
                        {hasPackageCategory ? (
                          <>
                            {packageCategoryName}:{" "}
                            {`${getPackageTypeName(
                              v.packageType
                            )} (${getFormattedMaterialId(v)})`}
                          </>
                        ) : (
                          <>{v.name}</>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </Styled.imageSliderHeader>
          }
          imagePaths={imagePaths}
          onClose={handleModalClose}
        />

        {ResponseHandlerOfGetFileUrlList}
      </>
    );
  };

  return { handleSkuForImageModalSelect, SkuImageModal };
}

import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
} from "@mui/material";

import { SHIPPING_DELIVERY_TYPE } from "constants/shipping";
import { AdminShippingListTabStatus, ShippingItemDetail } from "types/shipping";
import { toFormattedDate } from "utils/date";
import { toParcelCompanyLabel } from "utils/fulfillment";

import EditCustomerDetail from "./EditCustomerDetail";
import RequestMemoList from "./RequestMemoList";
import COMMON_QUERY from "queries/COMMON_QUERY";

// TODO: 현재는 해외특송만 존재
export default function Summary({
  status,
  shippingDetailData,
}: {
  status: AdminShippingListTabStatus | undefined;
  shippingDetailData: ShippingItemDetail | undefined;
}) {
  const { data: workerList } = COMMON_QUERY.useGetWorkerListById({
    warehouseManagerIds: [
      0,
      shippingDetailData?.pickerId,
      shippingDetailData?.packerId,
    ]
      .filter((v) => v !== undefined && v !== null)
      .map((v) => v as number),
    enabled: !!shippingDetailData,
  });

  if (!shippingDetailData) return null;

  const {
    deliveryType,
    parcelCompany,
    invoiceNo,
    dueDate,
    pickerId,
    packerId,
    startedPickingAt,
    endedPickingAt,
    startedPackingAt,
    endedPackingAt,
    customerName,
    customerPhone,
    customerAddress,
    customerDetailAddress,
    customerCountryCode,
    customerStateCode,
    customerPostalCode,
    memo,
  } = shippingDetailData;

  const deliveryDetail = `${
    SHIPPING_DELIVERY_TYPE[deliveryType]
  } / ${toParcelCompanyLabel(parcelCompany)} / ${invoiceNo ?? ""}`;

  const dueDateAndEndedPackingAtLabel = endedPackingAt
    ? "발송희망일자 / 발송일시"
    : "발송희망일자";

  /** TODO: 발송일시는 임시로 endedPackingAt을 사용  */
  const formattedDueDateAndEndedPackingAt = `${toFormattedDate(
    dueDate,
    "YYYY-MM-DD"
  )}${
    endedPackingAt
      ? ` / ${toFormattedDate(endedPackingAt, "YYYY-MM-DD HH:mm")}`
      : ""
  }`;

  const nameOfPicker = workerList?.find((v) => v.id === pickerId)?.name || "-";
  const nameOfPacker = workerList?.find((v) => v.id === packerId)?.name || "-";

  const formattedStartedPickingAt = startedPickingAt
    ? toFormattedDate(startedPickingAt, "YYYY-MM-DD HH:mm:ss")
    : "-";

  const formattedEndedPickingAt = endedPickingAt
    ? toFormattedDate(endedPickingAt, "YYYY-MM-DD HH:mm:ss")
    : "-";

  const formattedStartedPackingAt = startedPackingAt
    ? toFormattedDate(startedPackingAt, "YYYY-MM-DD HH:mm:ss")
    : "-";

  const formattedEndedPackingAt = endedPackingAt
    ? toFormattedDate(endedPackingAt, "YYYY-MM-DD HH:mm:ss")
    : "-";

  const customerMemo = (memo ?? []).filter(
    (memo) => memo.category === "customer"
  );

  const showsEditCustomerDetail =
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT";

  return (
    <Paper variant="outlined" sx={{ mt: 2 }}>
      <List
        subheader={
          <ListSubheader sx={{ typography: "h6", p: 2 }} disableSticky>
            <Stack flexDirection="row" justifyContent="space-between">
              배송정보
              {showsEditCustomerDetail && (
                <EditCustomerDetail shippingDetail={shippingDetailData} />
              )}
            </Stack>
          </ListSubheader>
        }
      >
        <Divider />

        <ListItem>
          <ListItemText primary="운송 수단" secondary={deliveryDetail} />
        </ListItem>

        <Divider />

        <ListItem>
          {/* TODO: https://sellernotehq.slack.com/archives/C02M89U1E5B/p1683509028155429 */}
          <ListItemText primary="지정운송사" secondary={""} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary={dueDateAndEndedPackingAtLabel}
            secondary={formattedDueDateAndEndedPackingAt}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="받는 분 성함" secondary={customerName} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="받는 분 연락처" secondary={customerPhone} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="국가코드" secondary={customerCountryCode} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="주명" secondary={customerStateCode} />
        </ListItem>

        <Divider />

        <ListItem>
          {/* TODO: */}
          <ListItemText primary="도시명" secondary={customerDetailAddress} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="받는 분 주소" secondary={customerAddress} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="우편번호" secondary={customerPostalCode} />
        </ListItem>

        <Divider />

        <ListItem sx={{ display: "flex" }}>
          <ListItemText
            sx={{ flexBasis: 1 }}
            primary="피킹 작업자"
            secondary={nameOfPicker}
          />
          <ListItemText
            sx={{ flexBasis: 1 }}
            primary="피킹 시작 시간"
            secondary={formattedStartedPickingAt}
          />
          <ListItemText
            sx={{ flexBasis: 1 }}
            primary="피킹 완료 시간"
            secondary={formattedEndedPickingAt}
          />
        </ListItem>

        <Divider />

        <ListItem sx={{ display: "flex" }}>
          <ListItemText
            sx={{ flexBasis: 1 }}
            primary="패킹 작업자"
            secondary={nameOfPacker}
          />
          <ListItemText
            sx={{ flexBasis: 1 }}
            primary="패킹 시작 시간"
            secondary={formattedStartedPackingAt}
          />
          <ListItemText
            sx={{ flexBasis: 1 }}
            primary="패킹 완료 시간"
            secondary={formattedEndedPackingAt}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText
            primary="요청사항"
            secondaryTypographyProps={{ component: "div" }}
            secondary={
              customerMemo.length > 0 ? (
                <RequestMemoList memo={customerMemo} />
              ) : (
                "-"
              )
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
}

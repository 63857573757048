import React, { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";

import { ADD_LOCATION_REQ } from "api-interfaces/location";
import LOCATION_QUERY, { LOCATION_QUERY_KEY_GEN } from "queries/LOCATION_QUERY";

export default function AddLocationDialog({
  showsAddLocationDialog,
  onAddLocationDialogClose,
}: {
  showsAddLocationDialog: boolean;
  onAddLocationDialogClose: () => void;
}) {
  const [locationData, setLocationData] = useState<Partial<ADD_LOCATION_REQ>>(
    {}
  );
  const [isBarCodeError, setIsBarCodeError] = useState(false);

  const queryClient = useQueryClient();

  const {
    mutate: addLocation,
    ResponseHandler: ResponseHandlerOfAddingLocation,
  } = LOCATION_QUERY.useAddLocation();

  const handleAddLocationDataChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    setLocationData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    if (isBarCodeError && e.target.name === "barCode") {
      setIsBarCodeError(false);
    }
  };

  const handleAddLocationDialogClose = () => {
    onAddLocationDialogClose();
    setLocationData({});
    setIsBarCodeError(false);
  };

  const isValid =
    !!locationData.barCode && !!locationData.kind && !!locationData.name;

  const handleLocationAdd = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const { kind, barCode, name } = locationData;

    // isValid에서 값의 존재여부를 검사함
    addLocation(
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        kind: kind!,
        warehouseId: 1,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        barCode: barCode!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        name: name!,
      },
      {
        onSuccess: () => {
          handleAddLocationDialogClose();

          queryClient.invalidateQueries(LOCATION_QUERY_KEY_GEN.adminList());
        },
        onError: () => {
          setIsBarCodeError(true);
        },
      }
    );
  };

  return (
    <>
      <Dialog
        open={showsAddLocationDialog}
        onClose={handleAddLocationDialogClose}
      >
        <DialogTitle>단일등록</DialogTitle>

        <form onSubmit={handleLocationAdd} autoComplete="off">
          <DialogContent dividers>
            <Stack direction="column" spacing={2}>
              <FormControl required>
                <InputLabel id="location-kind-label">구분</InputLabel>
                <Select
                  label="구분"
                  labelId="location-kind-label"
                  id="location-kind"
                  name="kind"
                  value={locationData?.kind ?? ""}
                  onChange={handleAddLocationDataChange}
                >
                  <MenuItem value="receiving">입고</MenuItem>
                  <MenuItem value="shipping">출고</MenuItem>
                  <MenuItem value="discard">폐기</MenuItem>
                  <MenuItem value="cancel">취소</MenuItem>
                  <MenuItem value="putAway">재고보관</MenuItem>
                </Select>
              </FormControl>

              <FormControl disabled required>
                <InputLabel id="warehouse-label">센터</InputLabel>
                <Select
                  label="센터"
                  labelId="warehouse-label"
                  id="warehouse"
                  value={1}
                >
                  <MenuItem value={1}>인천1센터</MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="loc-id"
                label="LOC ID"
                variant="outlined"
                name="barCode"
                value={locationData?.barCode ?? ""}
                onChange={handleAddLocationDataChange}
                required
                error={isBarCodeError}
              />

              <FormControl required>
                <InputLabel id="location-name-label">타입</InputLabel>
                <Select
                  label="타입"
                  labelId="location-name-label"
                  id="location-name"
                  name="name"
                  value={locationData?.name ?? ""}
                  onChange={handleAddLocationDataChange}
                >
                  <MenuItem value="SMALL CELL">SMALL</MenuItem>
                  <MenuItem value="MIDDLE CELL">MEDIUM</MenuItem>
                  <MenuItem value="LARGE CELL">LARGE</MenuItem>
                  <MenuItem value="PALLET CELL">PALLET</MenuItem>
                  <MenuItem value="Rackless">RACKLESS</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" type="submit" disabled={!isValid}>
              확인
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {ResponseHandlerOfAddingLocation}
    </>
  );
}

import styled from "styled-components";

const problemMemo = styled.div`
  > span {
    display: block;
  }
`;

export default {
  problemMemo,
};

import { TFunction } from "react-i18next";

import PageNotFoundImage from "./page_not_found.png";
import Styled from "./index.styles";
import { useHistory } from "react-router-dom";

export default function PageNotFound() {
  const history = useHistory();

  return (
    <>
      <Styled.container>
        <Styled.img>
          <img src={PageNotFoundImage} alt="not-found-page" />
        </Styled.img>
        <Styled.textGroup>
          <div className="text-bold">PAGE NOT FOUND</div>
          <div className="text-normal">페이지를 찾을 수가 없습니다.</div>
        </Styled.textGroup>
      </Styled.container>
      <Styled.backAction>
        <button onClick={() => history.push("/")}>돌아가기</button>
      </Styled.backAction>
    </>
  );
}

import { TABLET_MAX_WIDTH } from "styles/constants";
import { useMediaQuery } from "react-responsive";

/**
 * @deprecated 태블릿 사이즈는 지원하지 않으므로 사용하지 말것
 * TODO: 사용코드가 없어지면 삭제
 * isTablet: CSS 미디어쿼리와 같이 max-width체크
 */
export default function useCheckIsTablet() {
  const isBrowser = Boolean(typeof window !== "undefined");

  const isTablet = useMediaQuery({ maxWidth: TABLET_MAX_WIDTH });

  return {
    isTablet: isBrowser ? isTablet : false,
    isBrowser,
  };
}

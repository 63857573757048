import styled from "styled-components";

import { COLOR } from "styles/constants";

const rightSideElem = styled.div`
  height: 506px;
  flex: 1;
  background-color: ${COLOR.primaryBlue_10};
  border-radius: 0 16px 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  .fullfillment {
    width: 320px;
  }
  .logo {
    position: absolute;
    bottom: 16px;
    right: 19px;
  }
`;

export default {
  rightSideElem,
};

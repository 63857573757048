import { Controller, useForm } from "react-hook-form";
import { Button, Paper, Stack, Typography } from "@mui/material";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

interface CombinePackingForm {
  shippingId: string;
}

function ShippingPackingCombine() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<CombinePackingForm>({
    mode: "onChange",
    defaultValues: {
      shippingId: "",
    },
  });

  const {
    mutate: combinePacking,
    ResponseHandler: ResponseHandlerOfCombinePacking,
  } = SHIPPING_QUERY.useCombinePacking();

  const handlePackingCombine = ({ shippingId }: CombinePackingForm) => {
    combinePacking(
      { pathParams: { shippingId: Number(shippingId) } },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          합포장
        </Typography>

        <form onSubmit={handleSubmit(handlePackingCombine)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="shippingId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="출고요청번호"
                  error={!!errors.shippingId}
                  helperText={errors.shippingId && "숫자만 입력해주세요."}
                />
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandlerOfCombinePacking}
    </Layout>
  );
}

export default withRequireAuth(ShippingPackingCombine);

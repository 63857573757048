import { GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/material";
import { GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/receiving";
import { GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/shipping";
import {
  GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ,
  GET_PRODUCT_SEARCH_SUGGESTION_LIST_REQ,
  GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ,
} from "api-interfaces/sku";
import { GET_TEAM_SEARCH_SUGGESTION_LIST_REQ } from "api-interfaces/user";
import MATERIAL_QUERY from "queries/MATERIAL_QUERY";
import RECEIVING_QUERY from "queries/RECEIVING_QUERY";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import SKU_QUERY from "queries/SKU_QUERY";
import USER_QUERY from "queries/USER_QUERY";

export type SearchSuggestionType =
  | undefined
  | "receiving"
  | "singleSku"
  | "groupSku"
  | "material"
  | "team"
  | "shipping"
  | "product";

interface DefaultSearchSuggestion {
  type: undefined;
  searchKind: undefined;
  searchTerm: undefined;
}

interface SingleSkuSearchSuggestion {
  type: "singleSku";
  searchKind: GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ["searchKind"];
  searchTerm: GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ["searchTerm"];
}

interface GroupSkuSearchSuggestion {
  type: "groupSku";
  searchKind: GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ["searchKind"];
  searchTerm: GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ["searchTerm"];
}

interface MaterialSearchSuggestion {
  type: "material";
  searchKind: GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ["searchKind"];
  searchTerm: GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ["searchTerm"];
}

interface UserSearchSuggestion {
  type: "team";
  searchKind: GET_TEAM_SEARCH_SUGGESTION_LIST_REQ["searchKind"];
  searchTerm: GET_TEAM_SEARCH_SUGGESTION_LIST_REQ["searchTerm"];
}

interface ReceivingSearchSuggestion {
  type: "receiving";
  searchKind: GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ["searchKind"];
  searchTerm: GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ["searchTerm"];
}

interface ShippingSearchSuggestion {
  type: "shipping";
  searchKind: GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ["searchKind"];
  searchTerm: GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ["searchTerm"];
}

interface ProductSearchSuggestion {
  type: "product";
  searchKind: GET_PRODUCT_SEARCH_SUGGESTION_LIST_REQ["searchKind"];
  searchTerm: GET_PRODUCT_SEARCH_SUGGESTION_LIST_REQ["searchTerm"];
}

export default function useSearchSuggestion({
  type,
  searchKind,
  searchTerm,
}:
  | DefaultSearchSuggestion
  | SingleSkuSearchSuggestion
  | GroupSkuSearchSuggestion
  | MaterialSearchSuggestion
  | UserSearchSuggestion
  | ReceivingSearchSuggestion
  | ShippingSearchSuggestion
  | ProductSearchSuggestion) {
  const { data: skuSearchSuggestionList } =
    SKU_QUERY.useGetSingleSkuSearchSuggestionList({
      enabled: type === "singleSku" && !!searchKind && !!searchTerm,
      ...(type === "singleSku" ? { searchKind, searchTerm } : {}),
    });

  const { data: groupSkuSearchSuggestionList } =
    SKU_QUERY.useGetGroupSkuSearchSuggestionList({
      enabled: type === "groupSku" && !!searchKind && !!searchTerm,
      ...(type === "groupSku" ? { searchKind, searchTerm } : {}),
    });

  const { data: materialSearchSuggestionList } =
    MATERIAL_QUERY.useGetMaterialSearchSuggestionList({
      enabled: type === "material" && !!searchKind && !!searchTerm,
      ...(type === "material" ? { searchKind, searchTerm } : {}),
    });

  const { data: teamSearchSuggestionList } =
    USER_QUERY.useGetTeamSearchSuggestionList({
      enabled: type === "team" && !!searchKind && !!searchTerm,
      ...(type === "team" ? { searchKind, searchTerm } : {}),
    });

  const { data: receivingSearchSuggestionList } =
    RECEIVING_QUERY.useGetReceivingSearchSuggestionList({
      enabled: type === "receiving" && !!searchKind && !!searchTerm,
      ...(type === "receiving" ? { searchKind, searchTerm } : {}),
    });

  const { data: shippingSearchSuggestionList } =
    SHIPPING_QUERY.useGetShippingSearchSuggestionList({
      enabled: (() => {
        if (type === "shipping" && !!searchKind && !!searchTerm) {
          if (searchKind === "invoiceNo") {
            return searchTerm.length >= 4;
          }

          return true;
        }

        return false;
      })(),
      ...(type === "shipping" ? { searchKind, searchTerm } : {}),
    });

  const { data: productSearchSuggestionList } =
    SKU_QUERY.useGetProductSearchSuggestionList({
      enabled: type === "product" && !!searchKind && !!searchTerm,
      ...(type === "product" ? { searchKind, searchTerm } : {}),
    });

  const searchSuggestionList = (() => {
    if (!type) {
      return [];
    }

    return (
      {
        singleSku: skuSearchSuggestionList?.list,
        groupSku: groupSkuSearchSuggestionList?.list,
        material: materialSearchSuggestionList?.list,
        team: teamSearchSuggestionList?.list,
        receiving: receivingSearchSuggestionList?.list,
        shipping: shippingSearchSuggestionList?.list,
        product: productSearchSuggestionList?.list,
      }[type] ?? []
    );
  })();

  return {
    searchSuggestionList,
  };
}

import styled from "styled-components";

const imageList = styled.div`
  display: flex;
  margin-top: 13px;
  margin-bottom: 13px;

  > .tool-tip,
  > .image-item {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const printConfirmModalBody = styled.div`
  text-align: left;
`;

const skuItemName = styled.div`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  display: flex;
  align-items: center;
`;

const groupSkuItemName = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const skuImageContainer = styled.div``;

export default {
  imageList,
  printConfirmModalBody,
  groupSkuItemName,
  skuItemName,
  skuImageContainer,
};

import { Stack } from "@mui/material";

import { AdminShippingListTabStatus } from "types/shipping";

import { DomesticCategory } from "../domestic";
import { OverseasCategory } from "../overseas";
import EditDeliveryType from "./EditDeliveryType";
import EditDueDate from "./EditDueDate";

/**
 * 운송수단 변경: 의뢰대기 상태에서만 가능
 * 발송희망일 변경: 의뢰대기, 피킹, 패킹 상태에서 가능
 */
export default function EditDeliveryInfo({
  status,
  pageCategory,
  selectedShippingIds,
}: {
  status: AdminShippingListTabStatus | undefined;
  pageCategory: DomesticCategory | OverseasCategory;
  selectedShippingIds: number[];
}) {
  const showsDeliveryTypeButton =
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT";
  const showsDueDateButton =
    showsDeliveryTypeButton || status === "PICKING" || status === "PACKING";

  if (!showsDeliveryTypeButton && !showsDueDateButton) {
    return null;
  }

  return (
    <Stack flexDirection="row" gap={0.5}>
      {showsDeliveryTypeButton && (
        <EditDeliveryType
          pageCategory={pageCategory}
          selectedShippingIds={selectedShippingIds}
        />
      )}
      {showsDueDateButton && (
        <EditDueDate
          pageCategory={pageCategory}
          selectedShippingIds={selectedShippingIds}
        />
      )}
    </Stack>
  );
}

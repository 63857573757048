import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";

import usePrintInvoiceForList from "hooks/usePrintInvoiceForList";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import { ParcelCompanyListForPrint } from ".";

type InvoicePrintingStatus =
  | "beforeNumbering" // 1. 송장 채번 전
  | "numberingCompleted" // 2. 송장 채번 완료
  | "requestPrinting" // 3. 송장 출력 요청 중
  | "requestPrintingComplete"; // 4. 송장 출력 요청 완료

export default function useInvoiceNumberingAndPrintingModal({
  shippingIds,
  onModalClose,
}: {
  shippingIds: number[];
  onModalClose: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const canStartNumberingAndPrintingInvoice = !shippingIds?.length
    ? false
    : true;

  const {
    mutate: numberInvoice,
    ResponseHandler: ResponseHandlerOfInvoiceNumbering,
    data: resultOfInvoiceNumbering,
    reset: resetInvoiceNumbering,
  } = SHIPPING_QUERY.useInvoiceNumberingByParcelCompany();

  const {
    printInvoice,
    ResponseHandlerOfPrintInvoice,
    isRequestPrintingComplete,
    isLoadingOfPrintInvoice,
    resetPrintInvoice,
  } = usePrintInvoiceForList({
    hidesLoading: true,
  });

  const openModalToNumberingAndPrintInvoice = useCallback(
    ({
      shippingIds,
      parcelCompany,
      handleSelectParcelCompanyModalClose,
    }: {
      shippingIds: number[];
      parcelCompany: ParcelCompanyListForPrint;
      handleSelectParcelCompanyModalClose: () => void;
    }) => {
      if (!shippingIds) {
        return;
      }

      numberInvoice(
        { shippingIds, ...(parcelCompany !== "default" && { parcelCompany }) },
        {
          onSuccess: () => {
            handleSelectParcelCompanyModalClose();
            setIsModalOpen(true);
          },
        }
      );
    },
    [numberInvoice]
  );

  const invoicePrintingStatus = useMemo((): InvoicePrintingStatus => {
    if (isLoadingOfPrintInvoice) {
      return "requestPrinting";
    }

    if (!resultOfInvoiceNumbering) {
      return "beforeNumbering";
    }

    if (resultOfInvoiceNumbering && !isRequestPrintingComplete) {
      return "numberingCompleted";
    }

    if (isRequestPrintingComplete) {
      return "requestPrintingComplete";
    }

    return "beforeNumbering";
  }, [
    resultOfInvoiceNumbering,
    isLoadingOfPrintInvoice,
    isRequestPrintingComplete,
  ]);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    onModalClose();
    resetInvoiceNumbering();
    resetPrintInvoice();
  }, [onModalClose, resetInvoiceNumbering, resetPrintInvoice]);

  const StatusInfoPanel = useMemo(() => {
    if (invoicePrintingStatus === "beforeNumbering") {
      return (
        <>
          <Grid item xs={12} textAlign="center">
            택배 발송을 위한 송장번호 생성 중…
          </Grid>

          <Grid item xs={12} textAlign="center" marginBottom={4}>
            <CircularProgress size={140} color="primary" />
          </Grid>
        </>
      );
    }

    if (invoicePrintingStatus === "numberingCompleted") {
      return (
        <>
          <Grid item xs={12} textAlign="center">
            송장번호 생성이 완료되었습니다.
          </Grid>

          <Grid item xs={12} marginTop={1}>
            <Paper
              sx={{ padding: 2, backgroundColor: blue[50], lineHeight: 2 }}
            >
              • 선택한 출고 수 : {resultOfInvoiceNumbering?.totalCount ?? "-"}건
              <br />• 생성된 출고 수 :{" "}
              <b>{resultOfInvoiceNumbering?.successCount ?? "-"}건</b>
              <br />• 생성되지 않은 출고 수 :{" "}
              {resultOfInvoiceNumbering?.failedCount ?? "-"}건
              <br />
            </Paper>
          </Grid>

          <Grid item xs={12} marginTop={1} marginBottom={2} textAlign="center">
            * 생성된 출고 건에 대하여만 출력이 됩니다.
          </Grid>
        </>
      );
    }

    if (invoicePrintingStatus === "requestPrinting") {
      return (
        <>
          <Grid item xs={12} textAlign="center">
            송장 출력 요청 중…
          </Grid>

          <Grid item xs={12} textAlign="center" marginBottom={4}>
            <CircularProgress size={140} color="primary" />
          </Grid>
        </>
      );
    }

    if (invoicePrintingStatus === "requestPrintingComplete") {
      return (
        <Grid item xs={12} textAlign="center">
          송장 출력 요청이 완료되었습니다.
        </Grid>
      );
    }

    return null;
  }, [invoicePrintingStatus, resultOfInvoiceNumbering]);

  const ModalToNumberingAndPrintInvoice = useMemo(() => {
    return (
      <>
        <Dialog open={isModalOpen} onClose={handleModalClose}>
          <DialogTitle>택배 송장 출력</DialogTitle>

          <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              {StatusInfoPanel}
            </Grid>
          </DialogContent>

          <DialogActions>
            {invoicePrintingStatus === "beforeNumbering" ||
            invoicePrintingStatus === "numberingCompleted" ||
            invoicePrintingStatus === "requestPrinting" ? (
              <Button
                variant="contained"
                onClick={() => printInvoice({ shippingIds })}
                disabled={invoicePrintingStatus !== "numberingCompleted"}
              >
                출력하기
              </Button>
            ) : (
              <Button variant="contained" onClick={handleModalClose}>
                닫기
              </Button>
            )}
          </DialogActions>
          {ResponseHandlerOfPrintInvoice}
        </Dialog>

        {ResponseHandlerOfInvoiceNumbering}
      </>
    );
  }, [
    isModalOpen,
    handleModalClose,
    StatusInfoPanel,
    invoicePrintingStatus,
    ResponseHandlerOfPrintInvoice,
    ResponseHandlerOfInvoiceNumbering,
    shippingIds,
    printInvoice,
  ]);

  return {
    openModalToNumberingAndPrintInvoice,
    ModalToNumberingAndPrintInvoice,
    canStartNumberingAndPrintingInvoice,
  };
}

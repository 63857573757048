import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

// 필요할때마다 더 추가해서 사용
type TimeZone = "Asia/Seoul" | "Asia/Singapore";

/**
 * 시간을 특정 포맷으로 변경해준다.
 * @param value 변환할 원본 시간 데이터
 * @param format 변환하고자 하는 데이터 포맷 (입력하지 않으면 기본: 'YYYY-MM-DD hh:mm:ss a')
 */
function toFormattedDate(
  value?: string | Date | number | null,
  format = "YYYY-MM-DD hh:mm:ss a",
  asUTC?: boolean // timezone이아니라 utc로 받고싶을때
) {
  if (!value) return "";

  const date = dayjs(value);

  if (asUTC) {
    return date.utc().format(format);
  }

  return date.tz("Asia/Seoul").format(format);
}

const TODAY = new Date();

const TOMORROW = (() => {
  const tempDate = new Date();

  tempDate.setDate(TODAY.getDate() + 1);

  return tempDate;
})();

const MINUTE_AS_MILLISECONDS = 1000 * 60;

const HOUR_AS_MILLISECONDS = MINUTE_AS_MILLISECONDS * 60;

const DAY_AS_MILLISECONDS = HOUR_AS_MILLISECONDS * 24;

function getHolidayList() {
  const yearArr = new Array(5)
    .fill(0)
    .map((v, i) => new Date().getFullYear() + i);

  const holidayListWithYear = yearArr
    .map((year) => PUBLIC_HOLIDAY_LIST.map((date) => `${year}/${date}`))
    .reduce((acc, items) => {
      return acc.concat(items);
    }, []);

  return [
    ...holidayListWithYear,
    ...LUNAR_PUBLIC_HOLIDAY_LIST,
    ...SUBSTITUTE_HOLIDAY_LIST,
  ];
}

// TODO: 공휴일들은 Remind해서 매년 수동으로 업데이트해줘야함
// date 관련
// 매년 같은 공휴일
const PUBLIC_HOLIDAY_LIST = [
  "01/01",
  "03/01",
  "05/01", // 근로자의 날
  "05/05",
  "06/06",
  "08/15",
  "10/03",
  "10/09",
  "12/25",
];

// 음력 공휴일, 음력 명절
const LUNAR_PUBLIC_HOLIDAY_LIST = [
  // 2024년
  // 설 연휴
  "2024/02/09",
  "2024/02/10",
  "2024/02/11",
  "2024/05/15", // 석가탄신일
  // 추석연휴
  "2024/09/16",
  "2024/09/17",
  "2024/09/18",

  // 2025년
  // 설 연휴
  "2025/01/28",
  "2025/01/29",
  "2025/01/30",
  // 추석 연휴
  "2025/10/06",
  "2025/10/07",
];

// 대체 공휴일
const SUBSTITUTE_HOLIDAY_LIST = [
  // 2024년
  "2024/02/12", // 설 연휴 대체 휴일
  "2024/04/10", // 22대 국회의원 선거
  "2024/05/06", // 어린이날 대체 휴일

  // 2025년
  "2025/03/03", // 삼일절 대체 휴일
  "2025/05/06", // 어린이날 대체 휴일
  "2025/10/08", // 추석 연휴 대체 휴일
];

/**
 * utc Date나 string(2022-04-18T04:14:05.371Z 포맷)을 보내면
 * 그 중 날짜부분만(시간 제외)고려 하여 timezone을 고려한 Date로 변환한다
 * (시간 부분이 무시됨에 유의한다)
 * @param utcDateTime
 * @param timeZone
 * @param when
 */
function transformUTCDateToLocalDateTime({
  utcDateTime,
  timeZone,
  when,
}: {
  utcDateTime: Date | string;
  timeZone: TimeZone;
  when: "start" | "end";
}): Date | undefined {
  if (!(utcDateTime && timeZone && when)) return;

  const dayByTimeZone = dayjs.tz(utcDateTime, timeZone);

  if (when === "start") {
    return dayByTimeZone.startOf("day").toDate();
  }

  if (when === "end") {
    return dayByTimeZone.endOf("day").toDate();
  }
}

const isSameDay = (date1: dayjs.ConfigType, date2: dayjs.ConfigType) => {
  return dayjs(date1).isSame(date2, "day");
};

const isWeekend = (date: dayjs.ConfigType) => {
  const day = dayjs(date).day();

  return day === 0 || day === 6;
};

function getRemainedDays(from: string | Date, to: string | Date) {
  if (!from || !to) return 0;

  const target = dayjs(to);

  const difference = target.diff(from, "days");

  return difference;
}

const isSameOrBeforeToday = (value: dayjs.ConfigType) => {
  if (!value) {
    return false;
  }

  const target = dayjs(value);
  const today = dayjs().endOf("day");

  return target.isSameOrBefore(today);
};

/**
 * 현재 시간이 해당 날짜의 자정 이전인지 여부를 반환
 */
const isSameOrBeforeEndOfDate = (value: dayjs.ConfigType) => {
  if (!value) {
    return false;
  }

  const now = dayjs();
  const endOfDate = dayjs(value).endOf("date");

  return now.isSameOrBefore(endOfDate);
};

// 로컬 시간을 적용하지 않고 그대로 포맷팅
const toFormattedDateWithoutLocalTime = (
  value: dayjs.ConfigType,
  format = "YYYY-MM-DD hh:mm:ss a"
) => {
  if (!value) {
    return "";
  }

  const date = dayjs.utc(value);

  return date.format(format);
};

function isBeforeToday(value?: string | Date | number) {
  if (!value) return false;

  const target = dayjs(value);
  const now = dayjs();

  const isBefore = target.isBefore(now, "day");

  return isBefore;
}

const isAfterBusinessDays = ({
  baseDate,
  compareDate,
  days,
}: {
  baseDate: dayjs.ConfigType;
  compareDate?: dayjs.ConfigType;
  days: number;
}) => {
  const dateAfterBusinessDays = addBusinessDays({ date: baseDate, days });

  return dayjs(compareDate).isAfter(dateAfterBusinessDays);
};

const addBusinessDays = ({
  date,
  days,
}: {
  date: dayjs.ConfigType;
  days: number;
}) => {
  let result = date;
  let count = 0;

  while (count < days) {
    result = dayjs(result).add(1, "day").toDate();

    if (isBusinessDay(result)) {
      count++;
    }
  }

  return result;
};

const isBusinessDay = (date: Date) => !(isWeekend(date) || isHoliday(date));

const isHoliday = (date: dayjs.ConfigType) =>
  getHolidayList().some((holiday) => isSameDay(date, holiday));

/**
 * 시간을 timezone에 맞는 Date 로 변환.
 * @param value 변환할 원본 시간 데이터
 * @param timeZone
 */
function toFormattedDateToLocaleDate({
  value,
  timeZone,
}: {
  value: string | Date | number | undefined;
  timeZone: TimeZone;
}) {
  if (!value) return "";

  const date = dayjs(value);

  return date.tz(timeZone).toISOString();
}

export type { TimeZone };

export {
  addBusinessDays,
  isBusinessDay,
  isWeekend,
  isHoliday,
  isAfterBusinessDays,
  isBeforeToday,
  toFormattedDateWithoutLocalTime,
  isSameOrBeforeEndOfDate,
  isSameOrBeforeToday,
  toFormattedDate,
  TODAY,
  TOMORROW,
  getHolidayList,
  getRemainedDays,
  MINUTE_AS_MILLISECONDS,
  HOUR_AS_MILLISECONDS,
  DAY_AS_MILLISECONDS,
  transformUTCDateToLocalDateTime,
  isSameDay,
  toFormattedDateToLocaleDate,
};

import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const unverifiedListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;
`;

const unverifiedGuide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 2px;
  background-color: ${COLOR.pointWarning_20};
  color: ${COLOR.pointWarning};

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    ${setFontStyle("SubHead3", "Bold")};
  }

  .guide {
    ${setFontStyle("Body7")};
  }

  .quantity {
    flex: 1;
    text-align: right;
    word-break: keep-all;
    ${setFontStyle("SubHead3", "Bold")};
  }
`;

export default {
  unverifiedListContainer,
  unverifiedGuide,
};

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "pages";
import InventoryAdjustmentList from "pages/inventory/adjustment/list";
import InventoryList from "pages/inventory/list";
import InventoryListNew from "pages/inventory/list-new";
import LocationList from "pages/location/list";
import Login from "pages/login";
import MyPage from "pages/mypage";
import ProductList from "pages/product/list";
import ReceivingCanceled from "pages/receiving/canceled";
import ReceivingCompleted from "pages/receiving/completed";
import ReceivingRequests from "pages/receiving/requests";
import ReceivingTasks from "pages/receiving/tasks";
import ReturningList from "pages/returning/list";
import Sample from "pages/sample";
import ShippingDomesticList from "pages/shipping/domestic";
import ShippingDomesticListPrintTransactionStatement from "pages/shipping/domestic/print-transaction-statement";
import ShippingOverseasList from "pages/shipping/overseas";
import ShippingOverseasListPrintTransactionStatement from "pages/shipping/overseas/print-transaction-statement";
import ShippingPickings from "pages/shipping/pickings";
import UserCustomerList from "pages/user/customer/list";
import UserManagerList from "pages/user/manager/list";
import UserTeamList from "pages/user/team/list";
import UtilityAddressCheck from "pages/utility/address-check";
import UtilityInventoryFaultyInventoryModiFy from "pages/utility/inventory/faulty-inventory-modify";
import UtilityForceCreateInventory from "pages/utility/inventory/force-create-inventory";
import UtilityInvoiceAdd from "pages/utility/invoice/add";
import UtilityInvoiceIssue from "pages/utility/invoice/test-issue";
import UtilityReceivingExpectedDateModify from "pages/utility/receiving/expected-date-modify";
import UtilityReceivingSkuDetail from "pages/utility/receiving/sku-detail";
import { UpdateReturningStatusForTest } from "pages/utility/returning/update-dev";
import { RevertReturningStatusPage } from "pages/utility/returning/revert-status";
import { RevertReceivingStatusPage } from "pages/utility/receiving/revert-status";
import { PatchIsAlreadyApplied } from "pages/utility/returning/patch-is-already-applied";
import UtilityShippingCreateForcePicking from "pages/utility/shipping/force-picking/create";
import UtilityShippingPackingCombine from "pages/utility/shipping/packing-combine";
import UtilityShippingSkuDetail from "pages/utility/shipping/sku-detail";
import UtilityUpdateShippingStatus from "pages/utility/shipping/update-dev";
import UtilityUpdateItemQuantity from "pages/utility/shipping/update-item-quantity";
import UtilityUpdatePickingLocation from "pages/utility/shipping/update-picking-location";
import UtilityUpdateShipping from "pages/utility/shipping/update-shipping";
import UtilityOmsSingleOrder from "pages/utility/oms/single-order";
import UtilityOmsCollectOrders from "pages/utility/oms/collect-orders";

import ErrorBoundary from "components/sds-v1/ErrorBoundary";
import PageNotFound from "components/sds-v1/PageNotFound";

function AppRouter({ children }: { children: React.ReactNode }) {
  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/login" component={Login} exact />

          <Route path="/product" component={ProductList} exact />

          <Route
            path="/receiving/requests"
            component={ReceivingRequests}
            exact
          />
          <Route path="/receiving/tasks" component={ReceivingTasks} exact />
          <Route
            path="/receiving/completed"
            component={ReceivingCompleted}
            exact
          />
          <Route
            path="/receiving/canceled"
            component={ReceivingCanceled}
            exact
          />

          <Route
            path="/shipping/domestic"
            component={ShippingDomesticList}
            exact
          />
          <Route
            path="/shipping/domestic/print-transaction-statement"
            component={ShippingDomesticListPrintTransactionStatement}
            exact
          />
          <Route
            path="/shipping/overseas"
            component={ShippingOverseasList}
            exact
          />
          <Route
            path="/shipping/overseas/print-transaction-statement"
            component={ShippingOverseasListPrintTransactionStatement}
            exact
          />
          <Route path="/shipping/pickings" component={ShippingPickings} />

          <Route
            path="/shipping/domestic-beta"
            component={ShippingDomesticList}
            exact
          />
          <Route
            path="/shipping/domestic-beta/print-transaction-statement"
            component={ShippingDomesticListPrintTransactionStatement}
            exact
          />

          <Route path="/inventory/list" component={InventoryList} exact />
          <Route
            path="/inventory/list/new"
            component={InventoryListNew}
            exact
          />

          <Route
            path="/inventory/adjustment/list"
            component={InventoryAdjustmentList}
            exact
          />

          <Route path="/returning/list" component={ReturningList} exact />

          <Route path="/user/manager/list" component={UserManagerList} exact />
          <Route path="/user/team/list" component={UserTeamList} exact />
          <Route
            path="/user/customer/list"
            component={UserCustomerList}
            exact
          />

          <Route path="/location/list" component={LocationList} exact />

          <Route
            path="/utility/address-check"
            component={UtilityAddressCheck}
            exact
          />

          <Route
            path="/utility/invoice/test-issue"
            component={UtilityInvoiceIssue}
            exact
          />
          <Route
            path="/utility/invoice/add"
            component={UtilityInvoiceAdd}
            exact
          />

          <Route
            path="/utility/receiving/revert-status"
            component={RevertReceivingStatusPage}
            exact
          />

          <Route
            path="/utility/receiving/sku-detail"
            component={UtilityReceivingSkuDetail}
            exact
          />
          <Route
            path="/utility/receiving/expected-date-modify"
            component={UtilityReceivingExpectedDateModify}
            exact
          />

          <Route
            path="/utility/shipping/packing-combine"
            component={UtilityShippingPackingCombine}
            exact
          />
          <Route
            path="/utility/shipping/sku-detail"
            component={UtilityShippingSkuDetail}
            exact
          />
          <Route
            path="/utility/shipping/force-picking/create"
            component={UtilityShippingCreateForcePicking}
            exact
          />
          <Route
            path="/utility/shipping/update-dev"
            component={UtilityUpdateShippingStatus}
            exact
          />
          <Route
            path="/utility/shipping/update-shipping"
            component={UtilityUpdateShipping}
            exact
          />
          <Route
            path="/utility/shipping/update-picking-location"
            component={UtilityUpdatePickingLocation}
            exact
          />
          <Route
            path="/utility/shipping/update-item-quantity"
            component={UtilityUpdateItemQuantity}
            exact
          />

          <Route
            path="/utility/inventory/faulty-inventory-modify"
            component={UtilityInventoryFaultyInventoryModiFy}
            exact
          />

          <Route
            path="/utility/inventory/force-create-inventory"
            component={UtilityForceCreateInventory}
            exact
          />

          <Route
            path="/utility/returning/revert-status"
            component={RevertReturningStatusPage}
            exact
          />

          <Route
            path="/utility/returning/patch-is-already-applied"
            component={PatchIsAlreadyApplied}
            exact
          />

          <Route
            path="/utility/returning/moon-test"
            component={UpdateReturningStatusForTest}
            exact
          />

          <Route
            path="/utility/oms/single-order"
            component={UtilityOmsSingleOrder}
            exact
          />

          <Route
            path="/utility/oms/collect-orders"
            component={UtilityOmsCollectOrders}
            exact
          />

          <Route path="/mypage" component={MyPage} exact />

          <Route path="/sample" component={Sample} exact />

          <Route path="*" component={PageNotFound} />
        </Switch>

        {children}
      </ErrorBoundary>
    </Router>
  );
}

export default AppRouter;

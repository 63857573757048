import { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";

import SwitchCase from "components/SwitchCase";

import Basic from "./Basic";
import Sending from "./Sending";
import ShoppingMall from "./ShoppingMall";

type TeamDetailTab = "basic" | "sending" | "shoppingMall";

export default function TeamDetailModal({
  teamId,
  onClose,
}: {
  teamId: number | undefined;
  onClose: () => void;
}) {
  const [activeTab, setActiveTab] = useState<TeamDetailTab>("basic");

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: TeamDetailTab
  ) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Dialog open={!!teamId} onClose={onClose} maxWidth={false}>
        <DialogTitle>팀 상세 정보</DialogTitle>

        <DialogContent dividers>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="기본 정보" value="basic" />
              <Tab label="발송 정보" value="sending" />
              <Tab label="쇼핑몰 연동 현황" value="shoppingMall" />
            </Tabs>
          </Box>

          <Box sx={{ width: 1000, height: 800 }}>
            <SwitchCase
              value={activeTab}
              caseBy={{
                basic: <Basic teamId={teamId} />,
                sending: <Sending teamId={teamId} />,
                shoppingMall: <ShoppingMall teamId={teamId} />,
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

import { Button, Stack } from "@mui/material";
import MATERIAL_QUERY from "queries/MATERIAL_QUERY";
import { useCallback, useMemo, useState } from "react";

import useMultiSelect from "hooks/useMultiSelect";
import useSearchWithTerm, {
  TermSearchType,
} from "hooks/useSearchWithTermForBufulAdminOnly";
import { MaterialItem } from "types/fulfillment";
import { getGroupedSearchListByProperty } from "utils/common";
import { toFormattedDate } from "utils/date";
import { getFormattedMaterialId } from "utils/fulfillment";
import { getDimension } from "utils/receiving";

import MemoizedTable, {
  TableBodyRow,
  TableHeadCell,
  TableTabInfo,
} from "components/MemoizedTable";

import useDimensionModal from "../useDimensionModal";
import usePackageDetailModal from "./usePackageDetailModal";

interface ProductTableProps {
  tableTabInfo: TableTabInfo;
}

type CellIdForPackage =
  | "dimension"
  | "companyName"
  | "teamName"
  | Extract<
      keyof MaterialItem,
      "name" | "materialCode" | "managementCode" | "weight"
    >
  | "checkbox"
  | "packageId";

const termSearchTypeOptionsForPackage: TermSearchType<
  "id" | "company" | "name" | "materialCode" | "managementCode"
>[] = [
  {
    label: "포장 ID",
    value: "id",
  },
  {
    label: "회사명",
    value: "company",
  },
  {
    label: "부자재명",
    value: "name",
  },
  {
    label: "판매자부자재코드",
    value: "materialCode",
  },
  {
    label: "옵션관리코드",
    value: "managementCode",
  },
];

const packageHeadCells: TableHeadCell<CellIdForPackage>[] = [
  {
    id: "packageId",
    label: "포장 ID",
    disablePadding: false,
    width: 120,
  },
  {
    id: "companyName",
    label: "회사명",
    disablePadding: false,
    width: 150,
  },
  {
    id: "teamName",
    label: "팀명",
    disablePadding: false,
    width: 150,
  },
  {
    id: "name",
    label: "부자재명",
    disablePadding: false,
    width: 250,
  },
  {
    id: "materialCode",
    label: "판매자부자재코드",
    disablePadding: false,
    width: 120,
  },
  {
    id: "managementCode",
    label: "옵션관리코드",
    disablePadding: false,
    width: 120,
  },
  {
    id: "dimension",
    label: "Dimension",
    disablePadding: false,
    width: 220,
  },
  {
    id: "weight",
    label: "Weight",
    disablePadding: false,
    width: 120,
  },
];

function PackageTable({ tableTabInfo }: ProductTableProps) {
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);

  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  const resetCurrentPage = useCallback(() => setCurrentPage(0), []);

  const { TermSearchPanel: TermSearchPanelForPackage, searchList } =
    useSearchWithTerm({
      pageType: "material",
      termSearchTypeOptions: termSearchTypeOptionsForPackage,
      resetCurrentPage,
    });

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
    resetCurrentPage();
  };

  const {
    data: packageList,
    refetch: refetchGroupPackageList,
    ResponseHandler: ResponseHandlerOfPackageList,
  } = MATERIAL_QUERY.useGetManagerMaterialList({
    page: currentPage,
    perPage: perPage,

    ...getGroupedSearchListByProperty({ pageType: "material", searchList }),

    onSuccess: ({ list }) => {
      initSelectionDict(list.map(({ id }) => id));
    },
  });

  const { handleDimensionModalOpen, DimensionModal } = useDimensionModal({
    refetchList: () => refetchGroupPackageList(),
    isMaterialTable: true,
  });

  const { showPackageDetailModal, PackagetDetailModal } =
    usePackageDetailModal();

  const createPackageRows = () => {
    if (!packageList) return [];

    const newPackageRows: TableBodyRow<CellIdForPackage>[] =
      packageList.list.map((materialItem: MaterialItem) => {
        return {
          id: materialItem.id,

          packageId: getFormattedMaterialId(materialItem),
          companyName: materialItem.team?.company,
          teamName: materialItem.team?.name,
          name: (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>{materialItem.name}</span>
            </Stack>
          ),
          materialCode: materialItem.materialCode,
          managementCode: materialItem.managementCode,
          dimension: (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                {getDimension({
                  width: materialItem.width,
                  length: materialItem.length,
                  height: materialItem.height,
                })}
              </div>

              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDimensionModalOpen({
                    width: materialItem.width,
                    height: materialItem.height,
                    length: materialItem.length,
                    weight: materialItem.weight,
                    isSku: false,
                    materialId: materialItem.id,
                  })();
                }}
              >
                수정
              </Button>
            </Stack>
          ),
          weight: materialItem.weight,
          handleRowClick: () => {
            showPackageDetailModal(materialItem.id, materialItem.team);
          },
        };
      });

    return newPackageRows;
  };

  const createPackageRowsForCSVDownload = () => {
    if (!packageList) return [];

    const newPackageRowsForCSVDownload: TableBodyRow<CellIdForPackage>[] =
      packageList.list
        .filter(({ id }) => selectedIdList.includes(id))
        .map((materialItem: MaterialItem) => ({
          id: materialItem.id,

          packageId: getFormattedMaterialId(materialItem),
          companyName: materialItem.team?.company,
          teamName: materialItem.team?.name,
          name: materialItem.name,
          materialCode: materialItem.materialCode,
          managementCode: materialItem.managementCode,
        }));

    return newPackageRowsForCSVDownload;
  };

  const packageRows: TableBodyRow<CellIdForPackage>[] = useMemo(
    createPackageRows,
    [handleDimensionModalOpen, packageList]
  );
  const packageRowsForCSVDownload: TableBodyRow<CellIdForPackage>[] = useMemo(
    createPackageRowsForCSVDownload,
    [packageList, selectedIdList]
  );

  return (
    <>
      <MemoizedTable
        title="상품관리"
        tableTabInfo={tableTabInfo}
        headCells={packageHeadCells}
        rows={packageRows}
        toolbarItems={{
          left: [TermSearchPanelForPackage],
        }}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: packageList ? packageList.total : 0,
          perPage,
          setPerPage: handlePerPageChange,
          currentPage,
          setCurrentPage,
        }}
        csvDownloadForSelectedRowsInfo={{
          filename: `상품관리-부자재 (${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          idsToExceptForExcelDownload: ["checkbox", "label"],
          rowsForCSVDownload: packageRowsForCSVDownload,
        }}
        selectionDict={selectionDict}
        select={select}
        unSelect={unSelect}
        selectAll={selectAll}
        unSelectAll={unSelectAll}
        selectedCount={selectedCount}
        isAllSelected={isAllSelected}
      />

      {DimensionModal}
      {PackagetDetailModal}

      {ResponseHandlerOfPackageList}
    </>
  );
}

export default PackageTable;

import {
  CREATED_FROM_TYPE,
  MALL_OPTION_TYPE,
  SHIPPING_DELIVERY_TYPE,
} from "constants/shipping";

import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UploadFile } from "@mui/icons-material";
import { Button } from "@mui/material";
import { amber, red } from "@mui/material/colors";
import {
  GET_ADMIN_SHIPPING_LIST_REQ_DATE_KIND,
  GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR,
  GET_ADMIN_SHIPPING_LIST_REQ_SEARCH_KIND,
} from "api-interfaces/shipping";
import COMMON_QUERY from "queries/COMMON_QUERY";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import useMultiSelect from "hooks/useMultiSelect";
import useQueryString from "hooks/useQueryString";
import { SearchWithDateTypeOption } from "hooks/useSearchWithDateForBofulAdminOnly";
import useSearchWithDate from "hooks/useSearchWithDateForBofulAdminOnly";
import useSearchWithTerm, {
  TermSearchType,
} from "hooks/useSearchWithTermForBufulAdminOnly";
import CancelShipping from "pages/shipping/CancelShipping";
import CompleteShipment from "pages/shipping/CompleteShipment";
import EditDeliveryInfo from "pages/shipping/EditDeliveryInfo";
import PrintTransactionStatement from "pages/shipping/PrintTransactionStatement";
import SkuIdSearchOperatorSwitch from "pages/shipping/SkuIdSearchOperatorSwitch";
import useCreateFromFilter from "pages/shipping/useCreateFromFilter";
import useIsDelayFilter from "pages/shipping/useIsDelayFilter";
import useIsPackingFilter from "pages/shipping/useIsPackingFilter";
import useIsPickingFilter from "pages/shipping/useIsPickingFilter";
import useIsShipmentFilter from "pages/shipping/useIsShipmentFilter";
import useShippingAdminMemo from "pages/shipping/useShippingAdminMemo";
import useShippingQuantityFilter, {
  checkIsValidShippingQuantityLength,
} from "pages/shipping/useShippingQuantityFilter";
import {
  AdminShippingListTabStatus,
  DeliveryCategory,
  ShippingListItem,
} from "types/shipping";
import { getGroupedSearchListByProperty } from "utils/common";
import { isBeforeToday, toFormattedDate } from "utils/date";
import { getTitleAmongItemListName } from "utils/forwarding";
import {
  getFormattedCustomerAddress,
  getOrderQuantity,
  getPayloadByFilter,
  getShippingQuantityV2,
  getSkuIdListForAdmin,
} from "utils/fulfillment";
import { toTruckTypeLabel } from "utils/fulfillment";
import { classifyShippingItemsV2, translateSaleChannel } from "utils/shipping";
import { getPhoneNumberWithHyphen } from "utils/string";
import { CellId } from "./types";
import {
  getDefaultData,
  getGroupedHeadRow,
  getGroupedHeadRowIdsToExceptForExcelDownload,
  getHeadCells,
  getShipmentAt,
  getTabList,
  getTaskDataList,
  getTruckInfo,
  makeStatusToGetAdminShippingList,
} from "./utils";

import MemoizedTable, {
  TableBodyRow,
  TableHeadCell,
} from "components/MemoizedTable";

import { DomesticCategory } from "..";
import useDomesticMallFilter from "../useDomesticMallFilter";
import useShippingDetailModal from "../useShippingDetailModal";
import useDomesticTruckDeliveryTypeFilter from "./useDomesticTruckDeliveryTypeFilter";
import useTruckTypeFilter from "./useTruckTypeFilter";

const pinnedColumnList = [{ id: "shippingId", prevColumnWidth: 74 }];

const termSearchTypeOptions: TermSearchType<GET_ADMIN_SHIPPING_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "출고요청번호",
      value: "id",
    },
    {
      label: "주문번호",
      value: "orderNo",
    },
    {
      label: "차량번호",
      value: "truckNo",
    },
    {
      label: "받는 분 성함",
      value: "customerName",
    },
    {
      label: "받는 분 연락처",
      value: "customerPhone",
    },
    {
      label: "SKU ID",
      value: "skuId",
    },
    {
      label: "상품바코드",
      value: "barCode",
    },
    {
      label: "판매자상품코드",
      value: "productCode",
    },
    {
      label: "옵션관리코드",
      value: "managementCode",
    },
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "상품명",
      value: "itemName",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<GET_ADMIN_SHIPPING_LIST_REQ_DATE_KIND>[] =
  [
    {
      label: "등록일시",
      value: "createdAt",
    },
    {
      label: "발송희망일시",
      value: "dueDate",
    },
    {
      label: "발송일시",
      value: "endedPackingAt",
    },
  ];

function ShippingDomesticTruckList({
  deliveryCategory,
}: {
  deliveryCategory: DeliveryCategory;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const { pathname } = useLocation();

  const history = useHistory();
  const {
    addQuery,
    queries: { status, operatorInit, skuIdSearchListInit },
  } = useQueryString<{
    category: DomesticCategory;
    status: AdminShippingListTabStatus;
    operatorInit: GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR;
    skuIdSearchListInit: string;
  }>(history);

  const [operator, setOperator] =
    useState<GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR>(operatorInit || "or");

  const handleOperatorChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOperator(e.target.checked ? "and" : "or");
    },
    []
  );

  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  const resetCurrentPage = useCallback(() => setCurrentPage(0), []);

  const { TermSearchPanel, searchList } = useSearchWithTerm({
    pageType: "shipping",
    termSearchTypeOptions,
    resetCurrentPage,
    searchListInitialValue: skuIdSearchListInit?.split(",").map((skuId) => {
      return {
        searchKind: "skuId",
        searchKindLabel:
          termSearchTypeOptions.find((v) => v.value === "skuId")?.label || "",
        searchTerm: skuId,
      };
    }),
  });

  const { DateSearchPanel, dateSearchType, dateToSearch } = useSearchWithDate({
    dateSearchTypeOptions: dateSearchTypeOptions,
    resetCurrentPage,
  });

  const {
    DomesticMallFilterPanel,
    domesticMallPayloadListByFilter,
    resetDomesticMallFilter,
  } = useDomesticMallFilter({ resetCurrentPage });
  const {
    CreateFromFilterPanel,
    createFromPayloadListByFilter,
    resetCreateFromFilter,
  } = useCreateFromFilter({ resetCurrentPage });
  const {
    DomesticTruckDeliveryTypeFilterPanel,
    domesticTruckDeliveryTypePayloadListByFilter,
    resetDomesticTruckDeliveryTypeFilter,
  } = useDomesticTruckDeliveryTypeFilter({ resetCurrentPage });
  const {
    TruckTypeFilterPanel,
    truckTypePayloadListByFilter,
    resetTruckTypeFilter,
  } = useTruckTypeFilter({ resetCurrentPage });
  const {
    IsPickingFilterPanel,
    isPickingPayloadListByFilter,
    resetIsPickingFilter,
  } = useIsPickingFilter({ resetCurrentPage });
  const {
    IsPackingFilterPanel,
    isPackingPayloadListByFilter,
    resetIsPackingFilter,
  } = useIsPackingFilter({ resetCurrentPage });
  const { IsDelayFilterPanel, isDelayPayloadListByFilter, resetIsDelayFilter } =
    useIsDelayFilter({
      resetCurrentPage,
    });
  const {
    IsShipmentFilterPanel,
    isShipmentPayloadListByFilter,
    resetIsShipmentFilter,
  } = useIsShipmentFilter({ resetCurrentPage });

  const {
    ShippingQuantityFilterPanel,
    shippingQuantityPayloadByFilter,
    resetShippingQuantityFilter,
  } = useShippingQuantityFilter({ operator, searchList, resetCurrentPage });

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
    resetCurrentPage();
  };

  const { shippingStatus, wmsStatus } = useMemo(() => {
    return makeStatusToGetAdminShippingList(
      status as AdminShippingListTabStatus
    );
  }, [status]);

  const { data: shippingList, ResponseHandler: ResponseHandlerOfShippingList } =
    SHIPPING_QUERY.useGetAdminShippingList({
      pathname,
      perPage,
      page: currentPage,

      deliveryArea: "domestic",
      deliveryCategory,

      shippingStatus,
      wmsStatus,

      enabled: (() => {
        if (operator === "and") {
          // 출고수량 필터링 이후 검색리스트에서 상품만 하나 지우는 경우 API 요청을 막기 위함(400오류 발생)
          if (shippingQuantityPayloadByFilter) {
            return checkIsValidShippingQuantityLength({
              searchList,
              shippingQuantity: shippingQuantityPayloadByFilter,
            });
          }

          return true;
        }

        return true;
      })(),

      operator,

      ...getGroupedSearchListByProperty({ pageType: "shipping", searchList }),

      ...(dateSearchType && dateToSearch?.startDate && dateToSearch?.endDate
        ? {
            dateKind: dateSearchType,
            startDate: dateToSearch.startDate,
            endDate: dateToSearch.endDate,
          }
        : {}),

      ...getPayloadByFilter({
        key: "malls",
        payloadList: domesticMallPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "createdFrom",
        payloadList: createFromPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "deliveryTypes",
        payloadList: domesticTruckDeliveryTypePayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "truckTypes",
        payloadList: truckTypePayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isPicking",
        payloadList: isPickingPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isPacking",
        payloadList: isPackingPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isShipment",
        payloadList: isShipmentPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isDelay",
        payloadList: isDelayPayloadListByFilter,
      }),

      ...(shippingQuantityPayloadByFilter
        ? { quantities: shippingQuantityPayloadByFilter }
        : {}),

      onSuccess: (data) => {
        initSelectionDict(data.list.map((item) => item.id));
      },
    });

  const tabList = getTabList({
    status,
    totalShippings: shippingList?.totalShippings,
    total: shippingList?.total,
  });
  const activeTabIndex = tabList.findIndex((v) => v.status === status);

  const { channelDict } = COMMON_QUERY.useGetChannelList();
  const { data: workerList } = COMMON_QUERY.useGetWorkerList();

  const { showAdminMemoModal, ShippingAdminModal } = useShippingAdminMemo();

  const needsStateUpdateWhenPrintingTransactionStatement =
    status === "WAITING_EXPECTED" || status === "WAITING_TODAY";

  const {
    setAdminShippingDetail,

    showShippingDetailModal,
    ShippingDetailModal,
    ResponseHandlerOfShippingDetail,
  } = useShippingDetailModal({
    status,
    pageCategory: "TRUCK",
    needsStateUpdateWhenPrintingTransactionStatement,
  });

  const groupedHeadCells = useMemo(() => getGroupedHeadRow(status), [status]);

  const headCells: TableHeadCell<CellId>[] = useMemo(
    () =>
      getHeadCells({
        status,
        columnFilter: {
          deliveryType: DomesticTruckDeliveryTypeFilterPanel,
          truckType: TruckTypeFilterPanel,
          createFrom: CreateFromFilterPanel,
          saleChannel: DomesticMallFilterPanel,
          isPicking: IsPickingFilterPanel,
          isPacking: IsPackingFilterPanel,
          isDelay: IsDelayFilterPanel,
          isShipment: IsShipmentFilterPanel,
          shippingQuantity: ShippingQuantityFilterPanel,
        },
      }),
    [
      CreateFromFilterPanel,
      DomesticMallFilterPanel,
      DomesticTruckDeliveryTypeFilterPanel,
      IsDelayFilterPanel,
      IsPackingFilterPanel,
      IsPickingFilterPanel,
      IsShipmentFilterPanel,
      ShippingQuantityFilterPanel,
      TruckTypeFilterPanel,
      status,
    ]
  );

  const mapDataForTable = useCallback(
    (data: ShippingListItem[] | undefined, isForCSV?: boolean) => {
      if (!data) return [];

      let list = data.map((v) => {
        const classifiedShippingItems = classifyShippingItemsV2({
          items: v.items,
          groupList: v.groupList,
          needsToMerge: true,
        });

        return { ...v, items: classifiedShippingItems };
      });

      if (isForCSV) {
        // CSV다운로드할때는 item별로 row를 분리해해서 표시한다
        const listByItem = list.flatMap((v) => {
          if (!v.items?.length) return [v];

          return v.items.map((item) => {
            return {
              ...v,
              // items에 하나의 item만 오게 함으써 공통 매핑함수를 쓸 수 있게 함.
              items: [item],
            };
          });
        });

        list = listByItem;
      }

      return list.map((v) => {
        const row: TableBodyRow<CellId> = {
          id: v.id,

          /** 기본정보 */
          shippingId: v.id,
          saleChannel: translateSaleChannel(v.saleChannel),
          orderNo: v.orderNo,
          createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD HH:mm"),
          createdFrom: v.createdFrom && CREATED_FROM_TYPE[v.createdFrom],
          ...getDefaultData({ status, shipping: v }),

          /** 상품정보 */
          skuIdList: getSkuIdListForAdmin(v.items),
          orderQuantity: getOrderQuantity(v.items),
          shippingQuantity: getShippingQuantityV2({
            type: "admin",
            items: v.items,
            groupList: v.groupList,
          }),
          itemName: isForCSV
            ? v.items?.[0]?.sku?.itemName ?? ""
            : getTitleAmongItemListName(
                v.items.map((item) => ({
                  name: item.sku?.itemName ?? "",
                })),
                10
              ),

          /** 운송정보 */
          dueDate: toFormattedDate(v.dueDate, "YYYY-MM-DD HH:mm"),
          ...getShipmentAt({ shipping: v, status }),
          deliveryType: SHIPPING_DELIVERY_TYPE[v.deliveryType],
          truckType: toTruckTypeLabel(v.trucking?.truckType),
          truckInfo: getTruckInfo(v.trucking),
          dispatchErrorMessage: "",

          /** 보내는 분 정보 */
          senderCompanyName: v.team?.company,
          senderTeamName: v.team?.name,
          senderName: v.senderName,
          senderPhone: getPhoneNumberWithHyphen(v.senderPhone),

          /** 받는 분 정보 */
          customerCountryCode: "KR",
          customerName: v.customerName,
          customerPhone: getPhoneNumberWithHyphen(v.customerPhone),
          customerAddress: getFormattedCustomerAddress({
            type: "domestic",
            customerAddress: v.customerAddress,
            customerDetailAddress: v.customerDetailAddress,
          }),
          customerPostalCode: v.customerPostalCode,

          /** 작업현황 */
          ...getTaskDataList({
            status,
            shipping: v,
            workerList,
          }),

          /** 요청사항 */
          memo: v.memo
            ?.filter(({ category }) => category === "customer")
            .map((v) => v.memo)
            .join("\n"),

          /** 메모 */
          adminMemo: (
            <Button
              variant={v.adminMemo?.length ? "contained" : "outlined"}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                showAdminMemoModal(v.id);
              }}
            >
              메모
            </Button>
          ),

          handleRowClick: () => {
            showShippingDetailModal(v.id);
            setAdminShippingDetail(v);
          },

          backgroundColor: (() => {
            if (status === "WAITING_EXPECTED" || status === "WAITING_TODAY") {
              if (v.invoiceErrorMessage) {
                return red[300];
              }
            }

            if (status === "WAITING_TODAY") {
              if (isBeforeToday(v.dueDate)) {
                return amber[500];
              }
            }
          })(),
        };

        return row;
      });
    },
    [
      channelDict,
      status,
      workerList,
      showAdminMemoModal,
      showShippingDetailModal,
      setAdminShippingDetail,
    ]
  );

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(
      shippingList?.list.filter(({ id }) => selectedIdList.includes(id)),
      true
    );
  }, [mapDataForTable, shippingList?.list, selectedIdList]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(shippingList?.list);
  }, [shippingList, mapDataForTable]);

  const showsPrintInvoiceButton =
    status === "WAITING_EXPECTED" || status === "WAITING_TODAY";

  const showsPrintTransactionStatementButton =
    showsPrintInvoiceButton ||
    status === "PICKING" ||
    status === "PACKING" ||
    status === "SHIPMENT_READY" ||
    status === "SHIPMENT_DONE" ||
    status === "DONE";

  const showsCompleteShipmentButton = status === "SHIPMENT_READY";

  const showsCancelShippingButton =
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "PICKING" ||
    status === "PACKING" ||
    status === "SHIPMENT_READY";

  const handleTableTabChange = (val: number) => {
    addQuery({ status: tabList[val].status });

    resetCurrentPage();

    unSelectAll();

    resetDomesticMallFilter();
    resetCreateFromFilter();
    resetDomesticTruckDeliveryTypeFilter();
    resetTruckTypeFilter();
    resetIsPickingFilter();
    resetIsPackingFilter();
    resetIsShipmentFilter();
    resetIsDelayFilter();
    resetShippingQuantityFilter();
  };

  return (
    <>
      <SkuIdSearchOperatorSwitch
        operator={operator}
        handleOperatorChange={handleOperatorChange}
      />

      <MemoizedTable
        title="출고 리스트"
        groupedHeadRow={groupedHeadCells}
        headCells={headCells}
        rows={rows}
        pinnedColumnList={pinnedColumnList}
        tableTabInfo={{
          activeTabIndex,
          setActiveTabIndex: handleTableTabChange,
          tabList,
        }}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 200, 250, 500, 1000],
          totalCount: shippingList?.total || 0,
          perPage,
          setPerPage: handlePerPageChange,
          currentPage,
          setCurrentPage,
        }}
        csvDownloadForSelectedRowsInfo={{
          filename: `출고 리스트_국내_화물차량/직접수령(${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          idsToExceptForExcelDownload: ["checkbox", "adminMemo"],
          groupedHeadRowIdsToExceptForExcelDownload:
            getGroupedHeadRowIdsToExceptForExcelDownload(status),
          rowsForCSVDownload,
        }}
        toolbarItems={{
          left: [TermSearchPanel, DateSearchPanel],

          right: [
            <EditDeliveryInfo
              key="editDeliveryInfo"
              status={status}
              pageCategory="TRUCK"
              selectedShippingIds={selectedIdList}
            />,

            <>
              {showsPrintInvoiceButton && (
                <PrintTransactionStatement
                  isUsedInsteadOfPrintingInvoice
                  selectedShippingIds={selectedIdList}
                  shippingList={shippingList}
                  needsStateUpdateWhenPrintingTransactionStatement={
                    needsStateUpdateWhenPrintingTransactionStatement
                  }
                />
              )}
            </>,

            <>
              {showsPrintTransactionStatementButton && (
                <PrintTransactionStatement
                  selectedShippingIds={selectedIdList}
                  shippingList={shippingList}
                  needsStateUpdateWhenPrintingTransactionStatement={false}
                />
              )}
            </>,

            <>
              {showsCompleteShipmentButton && (
                <CompleteShipment
                  key="completeShipment"
                  selectedShippingIdList={selectedIdList}
                />
              )}
            </>,
          ],

          rightBottom: [
            <>
              {showsCancelShippingButton && (
                <CancelShipping
                  key="cancelShipping"
                  type="list"
                  selectedShippingIdList={selectedIdList}
                />
              )}
            </>,
          ],
        }}
        selectionDict={selectionDict}
        select={select}
        unSelect={unSelect}
        selectAll={selectAll}
        unSelectAll={unSelectAll}
        selectedCount={selectedCount}
        isAllSelected={isAllSelected}
      />

      {ResponseHandlerOfShippingList}

      {ShippingDetailModal}
      {ResponseHandlerOfShippingDetail}

      {ShippingAdminModal}
    </>
  );
}

export default ShippingDomesticTruckList;

import { INVENTORY_MANAGEMENT_KIND_MAP } from "constants/fulfillment";

import { useMemo, useState } from "react";
import { useAtom } from "jotai";
import { FULFILLMENT_INSPECTION_ATOMS } from "jotaiStates/inspection";
import COMMON_QUERY from "queries/COMMON_QUERY";

import { COLOR } from "styles/constants";
import { ProcessStatus, UserReturningDetailItem } from "types/returning";
import { toFormattedDate } from "utils/date";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { calculateDifference, toThousandUnitFormat } from "utils/number";
import { replaceEmptyToDash } from "utils/string";

import IconListItem from "components/IconListItem";
import ImageSlider from "components/ImageSlider";
import ModalInfoSummary from "components/ModalInfoSummary";
import Button from "components/sds-v1/button/Button";
import Icon from "components/sds-v1/Icon";
import ListItem from "components/sds-v1/ListItem";
import Modal from "components/sds-v1/Modal";
import ToolTip from "components/sds-v1/ToolTip";
import SelectButton from "_sds-v2/components/form/SelectButton";
import StateBadgeForInspection from "_sds-v2/components/stateBadge/StateBadgeForInspection";

import CommonStyled from "../../index.styles";
import ProblemMemo from "../../ProblemMemo";
import Styled from "../index.styles";
import ManagementList from "./ManagementDateList";

export type ProcessStatusMap = Record<
  number,
  {
    processStatus: ProcessStatus | undefined;
    managementDate: string | undefined;
  }
>;

export default function ProblemInfoModal({
  usesManagementDate,
  primaryId,
  problemDetailInfoList,
  closeProblemDetailModal,
  isWarehousingStatus,
  isDecidedQuantityStatus,
}: {
  usesManagementDate: boolean;
  primaryId: string | number;
  problemDetailInfoList: UserReturningDetailItem[];
  closeProblemDetailModal: () => void;
  isWarehousingStatus: boolean;
  isDecidedQuantityStatus: boolean;
}) {
  const [selectedId, setSelectedId] = useState(problemDetailInfoList[0]?.id);

  const [agreementInfoList, setAgreementInfoList] = useAtom(
    FULFILLMENT_INSPECTION_ATOMS.AGREEMENT_INFO_LIST_FOR_INSPECTION
  );

  const selectedProblemDetailInfo = problemDetailInfoList.find(
    (item) => item.id === selectedId
  );

  const problemList = selectedProblemDetailInfo?.inspectProblems;

  const prevProcessStatusMap = problemDetailInfoList.reduce<ProcessStatusMap>(
    (acc, cur) => {
      const tempData = agreementInfoList.find((item) => item.itemId === cur.id);

      acc[cur.id] = {
        processStatus: tempData?.processStatus || cur.processStatus,
        managementDate: tempData?.managementDate || cur.managementDate,
      };

      return acc;
    },
    {}
  );

  const [processStatusMap, setProcessStatusMap] =
    useState<ProcessStatusMap>(prevProcessStatusMap);

  const [activeImageSliderKey, setActiveImageSliderKey] = useState<string>();

  const overUnderQuantity = (() => {
    if (!selectedProblemDetailInfo) return;

    const { quantity, actualQty = 0 } = selectedProblemDetailInfo;

    if (quantity - actualQty > 0)
      return (
        <span className="warning-quantity">
          {replaceEmptyToDash(
            toThousandUnitFormat(calculateDifference(quantity, actualQty))
          )}{" "}
          (부족)
        </span>
      );

    if (quantity - actualQty < 0)
      return (
        <span className="warning-quantity">
          {replaceEmptyToDash(
            toThousandUnitFormat(calculateDifference(quantity, actualQty))
          )}{" "}
          (과입고)
        </span>
      );

    return "-";
  })();

  const handleSelect = (itemId: number, processStatus: ProcessStatus) =>
    setProcessStatusMap((prev) => ({
      ...prev,
      [itemId]: {
        processStatus,
        managementDate: prev[itemId]?.managementDate,
      },
    }));

  const handleComplete = () => {
    setAgreementInfoList((prev) =>
      prev.map((item) => ({
        ...item,
        processStatus:
          processStatusMap[item.itemId]?.processStatus || item.processStatus,
      }))
    );

    closeProblemDetailModal();
  };

  const validForComplete =
    Object.values(processStatusMap).every(
      ({ processStatus }) => processStatus !== "normal"
    ) &&
    Object.entries(processStatusMap).some(
      ([itemId, { processStatus }]) =>
        prevProcessStatusMap[Number(itemId)].processStatus !== processStatus
    );

  const fileKeyList =
    selectedProblemDetailInfo?.attachment
      ?.filter(({ domain }) => domain === "abnormalSku")
      .map(({ key }) => key) ?? [];

  const {
    data: fileUrlList,
    ResponseHandler: ResponseHandlerOfGetFileUrlList,
  } = COMMON_QUERY.useGetFileUrlList({
    key: fileKeyList,
    enabled: !!fileKeyList.length,
  });

  const imageList = useMemo(() => {
    if (!fileUrlList) return [];

    const itemWithAttachmentList = problemList
      ?.map((item) => ({
        ...item,
        attachments: selectedProblemDetailInfo?.attachment?.filter(
          ({ domain, targetId }) =>
            domain === "abnormalSku" && targetId === item.problemId
        ),
      }))
      .filter(({ attachments }) => attachments?.length);

    return fileUrlList
      .map(({ url, key }) => {
        const item = itemWithAttachmentList?.find((item) =>
          item.attachments?.find((attachment) => attachment.key === key)
        );

        // 필수 입력 값이므로 반드시 있다는 가정하에 정의
        return {
          id: item?.problemId as number,
          title: item?.problem as string,
          url,
          key,
        };
      })
      .sort((a, b) => a.id - b.id); // problemId 순으로 정렬
  }, [fileUrlList, problemList, selectedProblemDetailInfo?.attachment]);

  const moveImageSlider = (problemId: number) => {
    // 같은 problemId 를 가진 사진 중에 가장 첫번째 사진부터 보여준다.
    const activeKey = imageList.find((item) => item.id === problemId)?.key;

    setActiveImageSliderKey(activeKey);
  };

  const problemQuantity =
    problemList?.reduce((acc, cur) => acc + cur.quantity, 0) ?? 0;

  const totalQuantity = isDecidedQuantityStatus
    ? selectedProblemDetailInfo?.placeQty
    : selectedProblemDetailInfo?.actualQty;

  const normalQuantity = (totalQuantity ?? 0) - problemQuantity;

  const canSelected = !isWarehousingStatus;

  const isMultipleManagementDate = problemDetailInfoList.length > 1;

  const selectedManagementDate = isMultipleManagementDate
    ? toFormattedDate(selectedProblemDetailInfo?.managementDate, "YYYY-MM-DD")
    : "";

  const selectedProcessStatusData = processStatusMap[selectedId];

  // SKU ID 기준으로 묶었기 때문에 모든 상품의 기본 정보(sku정보, managementKind)는 동일하므로 첫 번째 요소의 정보를 사용한다.
  const defaultProblemDetail = problemDetailInfoList[0];

  const handleManagementDateSelect = (itemId: number) => {
    setSelectedId(itemId);
  };

  return (
    <Modal
      uiType="contentWithCustomBody"
      active={!!selectedProblemDetailInfo}
      title={"비정상 상품 처리"}
      body={
        <Styled.detailInfoContainer>
          <ModalInfoSummary
            summaryData={[
              {
                list: [
                  {
                    label: "반품요청번호",
                    value: primaryId,
                  },
                ],
                hasBottomDivider: true,
              },
              {
                list: [
                  {
                    label: "상품명",
                    value: defaultProblemDetail.sku?.itemName || "",
                  },
                ],
                isItemName: true,
              },
              {
                list: [
                  {
                    label: "SKU ID",
                    value: getFormattedSingleSkuId(defaultProblemDetail.skuId),
                  },
                  {
                    label: "상품 바코드",
                    value: replaceEmptyToDash(
                      defaultProblemDetail.sku?.barCode
                    ),
                  },
                  {
                    label: "판매자상품코드",
                    value: replaceEmptyToDash(
                      defaultProblemDetail.sku?.productCode
                    ),
                  },
                  {
                    label: "옵션관리코드",
                    value: replaceEmptyToDash(
                      defaultProblemDetail.sku?.managementCode
                    ),
                  },
                  ...(defaultProblemDetail.managementDate
                    ? [
                        {
                          label:
                            INVENTORY_MANAGEMENT_KIND_MAP[
                              defaultProblemDetail.sku?.managementKind ??
                                "expirationDate"
                            ],
                          value: (() => {
                            if (problemDetailInfoList.length === 1) {
                              return toFormattedDate(
                                problemDetailInfoList[0].managementDate,
                                "YYYY-MM-DD"
                              );
                            }

                            const earliestManagementDate =
                              problemDetailInfoList.reduce((acc, cur) =>
                                (acc.managementDate ?? "") <
                                (cur.managementDate ?? "")
                                  ? acc
                                  : cur
                              ).managementDate ?? "";
                            // 외 n건
                            return `${toFormattedDate(
                              earliestManagementDate,
                              "YYYY-MM-DD"
                            )} 외 ${problemDetailInfoList.length - 1}건`;
                          })(),
                        },
                      ]
                    : []),
                ],
              },
              ...(usesManagementDate
                ? [
                    {
                      list: [
                        {
                          label: "전체 입고요청수량(PCS)",
                          value: replaceEmptyToDash(
                            toThousandUnitFormat(
                              problemDetailInfoList.reduce(
                                (acc, cur) => acc + cur.quantity,
                                0
                              )
                            ),
                            true
                          ),
                        },
                      ],
                    },
                  ]
                : []),
            ]}
          />

          {isMultipleManagementDate && (
            <ManagementList
              managementKind={defaultProblemDetail.sku?.managementKind}
              selectedId={selectedId}
              processStatusMap={processStatusMap}
              onManagementDateSelect={handleManagementDateSelect}
            />
          )}

          <div>
            <Styled.inspectionProblemLabel>
              <CommonStyled.title>검수 정보</CommonStyled.title>

              {isMultipleManagementDate && (
                <time dateTime={selectedManagementDate}>
                  {selectedManagementDate}
                </time>
              )}
            </Styled.inspectionProblemLabel>

            <Styled.imageAndQuantityListContainer>
              <ImageSlider
                key={selectedId}
                imageList={imageList}
                width="572px"
                activeSlideKey={activeImageSliderKey}
              />

              <Styled.quantityListContainer>
                {!usesManagementDate && (
                  <>
                    <ListItem
                      type="withContentAlignRight"
                      label="입고요청수량(PCS)"
                      value={replaceEmptyToDash(
                        toThousandUnitFormat(
                          selectedProblemDetailInfo?.quantity
                        ),
                        true
                      )}
                    />

                    <div className="divider" />
                  </>
                )}

                <ListItem
                  type="withContentAlignRight"
                  label="검수수량(PCS)"
                  value={replaceEmptyToDash(
                    toThousandUnitFormat(selectedProblemDetailInfo?.actualQty),
                    true
                  )}
                />

                <IconListItem
                  label="정상수량"
                  valueType="normal"
                  value={replaceEmptyToDash(
                    toThousandUnitFormat(normalQuantity),
                    true
                  )}
                />
                <IconListItem
                  label="비정상수량"
                  valueType="warning"
                  value={replaceEmptyToDash(
                    toThousandUnitFormat(problemQuantity),
                    true
                  )}
                />

                {problemList?.map((v) => (
                  <IconListItem
                    key={v.problemId}
                    depth={2}
                    label={v.problem}
                    valueType="warning"
                    value={replaceEmptyToDash(toThousandUnitFormat(v.quantity))}
                    buttonInfo={{
                      label: "사진보기",
                      handleClick: () => moveImageSlider(v.problemId),
                    }}
                  />
                ))}

                {!usesManagementDate && (
                  <ListItem
                    type="withContentAlignRight"
                    label={
                      <div className="label-and-tooltip">
                        과부족 수량(PCS)
                        <ToolTip
                          displayType="block"
                          contentForDesktop={{
                            content: {
                              type: "descOnly",
                              desc: (
                                <>
                                  해당 의뢰의
                                  <br />
                                  과부족 입고요청수량입니다.
                                </>
                              ),
                            },
                            position: "bottomRight",
                            bodyWidth: 12,
                          }}
                          contentForMobile={{ isSameToDesktop: true }}
                        >
                          <Icon
                            type="circleFilledInformation"
                            size={1}
                            color={COLOR.grayScale_700}
                          />
                        </ToolTip>
                      </div>
                    }
                    value={overUnderQuantity}
                  />
                )}

                <ListItem
                  type="withContentAlignRight"
                  label="비고"
                  value={replaceEmptyToDash(
                    selectedProblemDetailInfo?.problemMemo
                  )}
                />

                {!usesManagementDate && (
                  <ListItem
                    type="withContentAlignRight"
                    label="문제발생"
                    value={
                      <ProblemMemo
                        quantity={selectedProblemDetailInfo?.quantity ?? 0}
                        actualQty={selectedProblemDetailInfo?.actualQty}
                        placeQty={selectedProblemDetailInfo?.placeQty}
                        problemList={problemList}
                        isDecidedQuantityStatus={isDecidedQuantityStatus}
                      />
                    }
                  />
                )}
              </Styled.quantityListContainer>
            </Styled.imageAndQuantityListContainer>

            <div>
              <Styled.problemQuantityAndSelect>
                <div className="problem-quantity">
                  <span className="label">비정상 상품</span>

                  <em>
                    {replaceEmptyToDash(
                      toThousandUnitFormat(problemQuantity),
                      true
                    )}{" "}
                    PCS
                  </em>
                </div>

                <div className="select-container">
                  <SelectButton
                    uiType="radio"
                    size="large"
                    onSelect={(v) => handleSelect(selectedId, v)}
                    label={
                      <StateBadgeForInspection
                        status="stock"
                        label="정상입고"
                      />
                    }
                    value={"stock"}
                    selected={
                      selectedProcessStatusData.processStatus === "stock"
                    }
                    disabled={!canSelected}
                  />
                  <SelectButton
                    uiType="radio"
                    size="large"
                    onSelect={(v) => handleSelect(selectedId, v)}
                    label={
                      <StateBadgeForInspection
                        status="faulty"
                        label="불량입고"
                      />
                    }
                    value={"faulty"}
                    selected={
                      selectedProcessStatusData.processStatus === "faulty"
                    }
                    disabled={!canSelected}
                  />
                </div>
              </Styled.problemQuantityAndSelect>

              <CommonStyled.inspectTotalQuantity isBackgroundColorBlue={true}>
                <CommonStyled.badgeAndQuantity>
                  <StateBadgeForInspection status="stock" label="정상입고" />

                  <span>
                    {selectedProcessStatusData.processStatus === "stock"
                      ? toThousandUnitFormat(normalQuantity + problemQuantity)
                      : selectedProcessStatusData.processStatus === "faulty"
                      ? replaceEmptyToDash(
                          toThousandUnitFormat(normalQuantity),
                          true
                        )
                      : "-"}{" "}
                    PCS
                  </span>
                </CommonStyled.badgeAndQuantity>

                <CommonStyled.badgeAndQuantity>
                  <StateBadgeForInspection status="faulty" label="불량입고" />

                  <span>
                    {selectedProcessStatusData.processStatus === "faulty"
                      ? toThousandUnitFormat(problemQuantity)
                      : "-"}{" "}
                    PCS
                  </span>
                </CommonStyled.badgeAndQuantity>

                <div className="divider" />

                <CommonStyled.totalQuantity>
                  <span className="label">
                    {isDecidedQuantityStatus
                      ? "최종 입고수량"
                      : "입고 예정수량"}

                    <ToolTip
                      displayType="block"
                      contentForDesktop={{
                        position: "bottomRight",
                        content: {
                          type: "descOnly",
                          desc: isDecidedQuantityStatus ? (
                            <>
                              입고 시 최종으로 반영되는 수량입니다.
                              <br />
                              입고 단계에서 특정 문제 발생 시 입고 수량이 변동될
                              수 있습니다.
                            </>
                          ) : (
                            <>
                              입고하기 전 검수완료(이슈) 단계에서
                              <br />
                              정상, 불량 입고 처리를 선택한 수량의 합입니다.
                            </>
                          ),
                        },
                        bodyWidth: isDecidedQuantityStatus ? 26 : 19,
                      }}
                      contentForMobile={{
                        isSameToDesktop: true,
                      }}
                    >
                      <Icon
                        type="circleFilledInformation"
                        color={COLOR.grayScale_800}
                        size={1}
                      />
                    </ToolTip>
                  </span>

                  <span className="quantity">
                    {selectedProcessStatusData.processStatus === "stock" ||
                    selectedProcessStatusData.processStatus === "faulty"
                      ? toThousandUnitFormat(totalQuantity)
                      : "-"}{" "}
                    PCS
                  </span>
                </CommonStyled.totalQuantity>
              </CommonStyled.inspectTotalQuantity>
            </div>
          </div>

          <Styled.inspectionProblemComplete>
            {isMultipleManagementDate && (
              <div className="warning-message">
                <Icon
                  size={1}
                  color={COLOR.pointWarning}
                  type="circleFilledInformation"
                />

                <p>
                  모든 관리일자 항목을 정상입고/불량입고 선택 완료 시 비정상
                  처리 완료할 수 있습니다.
                </p>
              </div>
            )}

            {canSelected && (
              <Button
                size="normal"
                theme="primary"
                label="비정상 처리 완료"
                handleClick={handleComplete}
                disabled={!validForComplete}
                className="complete-button"
              />
            )}
          </Styled.inspectionProblemComplete>

          {ResponseHandlerOfGetFileUrlList}
        </Styled.detailInfoContainer>
      }
      onClose={closeProblemDetailModal}
      needConfirmBeforeCloseModal={validForComplete} // 선택 변경 값이 있는데 닫는경우, 확인 모달
      needConfirmBeforeCloseModalInfo={{
        title: (
          <>
            상품처리를 중단하고 창을 닫으시겠습니까?
            <br />
            선택 완료 하지 않을 시 내용은 저장되지 않습니다.
          </>
        ),
        actionPositiveLabel: "페이지 나가기",
        actionNegativeLabel: "이어서 진행",
      }}
    />
  );
}

import { useState } from "react";

import COMMON_QUERY from "queries/COMMON_QUERY";
import { MaterialItem } from "types/fulfillment";
import { getFormattedMaterialId } from "utils/fulfillment";
import ImageSliderModal from "components/sds-v1/ImageSliderModal";

export default function useMaterialImageModal() {
  const [materialItem, setMaterialItem] = useState<MaterialItem>();

  const fileKeyList = materialItem
    ? materialItem?.attachment.map((attachmentItem) => attachmentItem.key)
    : [];

  const {
    data: fileUrlList,
    ResponseHandler: ResponseHandlerOfGetFileUrlList,
  } = COMMON_QUERY.useGetFileUrlList({
    key: fileKeyList,
    enabled: !!materialItem && !!fileKeyList.length,
  });

  const getImagePaths = () => {
    if (!fileUrlList) return;

    const imagePaths: string[] = fileUrlList.map(
      (fileUrlItem) => fileUrlItem.url
    );

    return imagePaths;
  };

  const resetState = () => {
    setMaterialItem(undefined);
  };

  const handleMaterialImageModalOpen = (newMaterialItem: MaterialItem) => {
    setMaterialItem(newMaterialItem);
  };

  const handleMaterialImageModalClose = () => {
    resetState();
  };

  const MaterialImageModal = () => {
    if (!materialItem) return null;

    const ImageSliderModalTitle: React.ReactNode = (
      <>
        <div>{getFormattedMaterialId(materialItem)}</div>
        <div>{materialItem?.name}</div>
      </>
    );

    const imagePaths = getImagePaths();

    return (
      <>
        <ImageSliderModal
          active
          initialSlideIndex={0}
          imagePaths={imagePaths}
          title={ImageSliderModalTitle}
          onClose={handleMaterialImageModalClose}
        />

        {ResponseHandlerOfGetFileUrlList}
      </>
    );
  };

  return { handleMaterialImageModalOpen, MaterialImageModal };
}

import { ReactNode } from "react";

import { CountryCode, StateCode } from "types/address";
import { PagingResponseForBoard } from "types/common";
import { Overwrite, RecursivePartial } from "types/customUtilityTypes";

import { BofulManager } from "./auth";
import {
  AdminMemo,
  ClassifiedGroupItem,
  ClassifiedGroupItemSku,
} from "./common";
import {
  BofulProblem,
  ExcelItem,
  FulfillmentAttachment,
  FulfillmentParcelCompany,
  FulfillmentTruckCompany,
  WmsStatus,
} from "./fulfillment";
import { ValidateSkuItem } from "./receiving";
import { ReturningListItem } from "./returning";
import {
  ProductGroup,
  ProductGroupIdItem,
  ShippingSKUItem,
  UserFilterSKU,
} from "./sku";
import { BofulTeam } from "./user";

/** 배송 지역 */
export type DeliveryArea =
  /** 국내 */
  | "domestic"
  /** 해외 */
  | "overseas";

/** 어드민 > 출고관리 > 카테고리 */
export type DeliveryCategory =
  /** 국내 택배(일반택배 + 화물택배) */
  | "domesticParcel"
  /** 국내 화물트럭 + 직접수령 */
  | "domesticTruck"
  /** 해외 특송 */
  | "overseasExpress"
  /** 해외 화물 + 직접수령 */
  | "overseasFreight";

/** 출고정보 생성방식 */
export type CreatedFromType =
  /** 엑셀 */
  | "excel"
  /** OMS */
  | "oms"
  /** 유저 등록 */
  | "user"
  /** API */
  | "api";

/** 고객이 보는 현황 */
export type ShippingStatus =
  /** 요청 확인 중 */
  | "waiting"
  /** 작업 중 */
  | "inProgress"
  /** 출고 마감 (배송 준비) */
  | "close"
  /** 배송 중 */
  | "delivering"
  /** 배송 완료 */
  | "done"
  /** 취소 */
  | "cancel"
  /** 반품 중 */
  | "return"
  /** 재고 조사 중 */
  | "survey";

/** 운송 유형 */
export type ShippingDeliveryType =
  /** 일반택배 */
  | "parcel"
  /** 화물택배 */
  | "freight"
  /** 화물차량 */
  | "truck"
  /** 항공특송 */
  | "airExpress"
  /** 해상특송 */
  | "oceanExpress"
  /** 직접수령 */
  | "selfCollect";

export type DomesticShippingDeliveryType = Extract<
  ShippingDeliveryType,
  "parcel" | "freight" | "truck" | "selfCollect"
>;

export type OverseasShippingDeliveryType = Extract<
  ShippingDeliveryType,
  "airExpress" | "oceanExpress"
>;

/** 배송 현황 */
export type ShippingDeliveringStatus =
  /** 아직 배송 전 */
  | "notSent"
  /** 배송 중 */
  | "delivering"
  /** 배송 완료 */
  | "done"
  /** 미집화 */
  | "uncollected";

export interface ShippingListItem {
  /* 출고번호 */
  id: number;
  /* 고객 작업 현황 */
  shippingStatus: ShippingStatus;
  /* WMS 작업 현황 */
  wmsStatus: WmsStatus;
  /* 배송 현황 */
  deliveringStatus: ShippingDeliveringStatus;
  /*  화주 ID */
  userId: number;
  teamId: number;
  /* 담당 매니저 ID */
  managerId: number;
  /* 피커 ID. 매니저도 가능 */
  pickerId?: number;
  /* 패커 ID. 매니저도 가능 */
  packerId?: number;
  /* 출하자 ID. 매니저도 가능 */
  shipperId?: number;
  /* 입고 담당자 ID. 매니저도 가능 */
  restockerId?: number;
  truckingId?: number;
  /* 창고 ID */
  warehouseId: number;
  /* 발송 희망일자 */
  dueDate: string;
  /* 운송방식 */
  deliveryType: ShippingDeliveryType;
  /* 주문번호 */
  orderNo: string;
  /* 판매채널 */
  saleChannel: string;
  /* 받는 분 성함 */
  customerName: string;
  /* 받는 분 연락처 */
  customerPhone: string;
  /** 받는 분 이메일 */
  customerEmail?: string;
  /** 받는 분 국가 코드 */
  customerCountryCode?: CountryCode;
  /** 받는 분 주 코드 */
  customerStateCode?: StateCode;
  /* 받는 분 주소(해외인 경우 상세주소) */
  customerAddress: string;
  /* 받는 분 상세 주소(해외인 경우 도시명) */
  customerDetailAddress: string;
  /* 받는 분 우편번호 */
  customerPostalCode: string;
  /* 보내는 분 */
  senderName: string;
  /* 보내는 분 연락처 */
  senderPhone: string;
  /** 보내는 분 이메일 */
  senderEmail?: string;
  /** 보내는 분 주 코드 */
  senderStateCode?: StateCode;
  /** 보내는 분 국가 코드 */
  senderCountryCode?: CountryCode;
  /* 보내는 분 주소 */
  senderAddress: string;
  /* 보내는 분 상세 주소 */
  senderDetailAddress: string;
  /* 보내는 분 우편번호 */
  senderPostalCode: string;
  /* 택배 - 출고차수  */
  parcelOrder?: "firstOrder" | "secondOrder";
  /* 화물트럭 출고 시 자사가 부여하는 일련번호 */
  invoiceNo: string;
  /* 송장 출력시 발생한 에러메시지 */
  invoiceErrorMessage?: string;
  /* 송장출력 여부 */
  isIssue: boolean;
  /* 출고 지시 */
  isConfirm: boolean;
  /* 패킹 필요 여부 */
  isPacking: boolean;
  /* 발송시간 */
  sentAt?: string;
  /* 택배사 */
  parcelCompany?: FulfillmentParcelCompany;
  /* 화물사 */
  truckCompany?: string;
  /** 피킹시작 일시 */
  startedPickingAt?: string;
  /* 피킹완료리스트 처리일시 */
  endedPickingAt?: string;
  /** 패킹시작 일시 */
  startedPackingAt?: string;
  /* 패킹마감일 */
  endedPackingAt?: string;
  /* 피킹 스캔 시작(상품을 1개라도 정상적으로 피킹한 경우) */
  isStartScanning: boolean;
  /* 메모 */
  memo?: (ShippingMemo | ShipperMemoType)[];
  /* 인수자 서명 */
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  signature?: string;
  items: ShippingItem[];
  orders: ShippingOrderItem[];
  team: BofulTeam;
  trucking?: ShippingTrucking;
  returning?: ReturningListItem[];
  /** 택배 배송의 여러 건으로 나뉘는 경우 */
  packings?: ShippingPacking[];

  // TODO: 기존 타입 groups, productGroupIds는 출고, 반품 모두 개선 후 제거
  /** 그룹상품 정보 (기존) */
  groups?: ProductGroup[];
  /** 그룹상품의 요약 정보 */
  productGroupIds: ProductGroupIdItem[];

  /** 국내 해외 구분자 */
  deliveryArea?: DeliveryArea;
  /** 출고정보 생성방식 */
  createdFrom?: CreatedFromType;
  /** 무게 */
  weight?: number;

  /** 그룹상품 정보 (NEW) */
  groupList?: ProductGroup[];
  /** 어드민용 메모 */
  adminMemo?: AdminMemo[];
  /** 송장출력 횟수 */
  printCount?: number;
  /** 송장출력 시간 */
  printedAt?: string;
  /** 출하완료 시간 */
  dispatchedAt?: string;
  /** 배송완료 시간 */
  deliveredAt?: string;
  /** 취소 시간 */
  canceledAt?: string;

  /** 재출고 존재 여부 */
  hasReOrder: boolean;

  /** FEDEX ETD 제공 여부 */
  isETDSupported: boolean;
  /** FEDEX 상업송장 */
  attachment?: FulfillmentAttachment;
}

export interface ShippingExcelListItem extends ShippingListItem {
  item: ShippingItem | ClassifiedGroupItem;
}
export interface ShippingPacking {
  id: number;
  invoiceNo: string;
  shippingId: number;
  outerPackagesId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  packingToSku: ShippingPackingToSku[];
  outerPackages?: { name: string };
}

export interface ShippingPackingToSku {
  id: number;
  packingId: number;
  /** SKU ID */
  stockUnitId: number;
  stockUnit?: {
    itemName: string;
  };
  qty: number;
  returningPackingId?: number;
}

export interface ShippingTrucking {
  id: number;
  packingId: number;
  /* 박스 수 */
  boxQty: number;
  /* 출고여부 */
  isShippingOut: boolean;
  /* 배차여부 */
  isTruckAppointed: boolean;
  destinationAddr: string;
  truckCompany: FulfillmentTruckCompany;
  driverName: string;
  driverPhoneNo: string;
  truckNo: string;
  truckType: ShippingTruckType;
  purchasePrice?: number;
  billingPrice?: number;
}

/** 차량종류 */
export type ShippingTruckType =
  /** 오토바이 */
  | "motorcycle"
  /** 다마스 */
  | "damas"
  /** 라보 */
  | "labo"
  /** 밴 */
  | "van"
  /** 1톤 트럭 */
  | "1ton"
  /** 1톤 윙바디 트럭 */
  | "1ton_wingbody"
  /** 1.2톤&1.4톤 트럭 */
  | "ton1_2_and_ton1_4"
  /** 2.5톤&3.5톤 트럭 */
  | "ton2_5_and_ton3_5"
  /** 2.5톤&3.5톤 윙바디 트럭 */
  | "ton2_5_and_ton3_5_wingbody"
  /** 5톤 트럭 */
  | "5ton"
  /** 5톤 축차 */
  | "5ton_rotor"
  /** 5톤 윙바디 트럭 */
  | "5ton_wingbody"
  /** 7.5톤 트럭 */
  | "ton7_5"
  /** 11톤 트럭 */
  | "11ton"
  /** 14톤 트럭 */
  | "14ton"
  /** 25톤 트럭 */
  | "25ton";

// Shipping SKU Filter List 에서 선택한 Item
export interface SelectedSKUItem {
  isGroup?: boolean;
  sku:
    | ShippingSKUItem
    | UserFilterSKU
    | ProductGroup
    | ClassifiedGroupItemSku
    | ValidateSkuItem;
  quantity?: number;
  /** 출고 수정 모달에서 ~/search API를 활용해 추가한 상품인지 여부를 확인 */
  isAdded?: boolean;
}

export interface ShippingItem {
  id: number;
  skuId: number;
  shippingId: number;
  locationId: number;
  quantity: number;
  managementDate?: Date;
  currentQty: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  location?: ShippingLocation;
  sku: ShippingSKUItem;

  /** 그룹상품 여부를 프론트에서 확인해주기 위한 값 */
  isGroup?: boolean;
}

export interface ShippingOrderItem {
  id: number;
  skuType: "single" | "group";
  itemName: string;
  quantity: number;
  sku: ShippingSKUItem;
  skuId?: number;
  productGroupId?: number;
  shippingId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface ShippingLocation {
  id: number;
  warehouseId: number;
  name: string;
  kind?: string;
  allocatedUserId?: string;
  barCode: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export type ShipperMemoType = {
  /** 메모 카테고리 */
  category: "shipper";
  /** 요청사항 */
  memo: string;
  /** 작성자 */
  userName: string;
  /** 작성자 ID */
  userId: number;
  /** 작성일시 (key값으로 사용할 유니크 값이 없기 때문에 작성일시를 key값으로 사용) */
  createdAt: string;
  /** 수정일시 */
  updatedAt?: string;
};

export interface ShippingMemo {
  memo: string;
  category: ShippingMemoCategory;
  createdAt: string;
}

/** 출고 요청사항 카테고리 */
export type ShippingMemoCategory =
  /** 보내는 사람 (23-06-20 기준, 재출고 사유 입력에만 사용) */
  | "sender"
  /** 받는 사람 요청 */
  | "customer"
  /** 창고 요청 */
  | "warehouse"
  /** 화주 메모 */
  | "shipper";

export interface ShippingItemDetail extends ShippingListItem {
  printerWorkerId: number;
  printerWorker: BofulManager;
  printCount: number;
  printedAt: string;
}

export type IssueOptionList = "all" | "unIssue" | "issue";

export type ParcelCompanyType = "all" | "cj" | "post" | "hanjin";
export type TruckCompanyType =
  | "all"
  | "gogoVan"
  | "sandy"
  | "happyLogistics"
  | "gana";

export type ShipmentTodayAndOrderFilterType = "all" | "today" | "overdue";
export type ShipmentDelayFilterType = "all" | "today" | "oneday" | "twoday";
export type ShipmentParcelOrderType = "firstOrder" | "secondOrder";

export type ShipmentStatusType =
  | "expected"
  | "completed"
  | "unprocessed"
  | "ready"
  | "done";

export interface ShipmentSummary {
  /** 금일 출하 요청 건 */
  todayRequest: number;
  /** 이전 출하 요청 건 */
  overdueRequest: number;

  /** 현재 마감 건 */
  todayDone: number;

  /** 당일 지연 */
  todayDelay: number;
  /** 1일 지연 */
  onedayDelay: number;
  /** 2일 이상 지연 */
  twodayDelay: number;
}

export interface ShipmentParcelSummary extends ShipmentSummary {
  /** 1번 출고 건 - 출하 준비완료 */
  firstReadyShipment: number;
  /** 1번 출고 건 - 출하 마감 */
  firstDoneShipment: number;
  /** 2번 출고 건 - 출하 준비완료 */
  secondReadyShipment: number;
  /** 2번 출고 건 - 출하 마감 */
  secondDoneShipment: number;
}

export interface TruckingItem {
  id: number;
  shippingId: number;
  /** 박스 수 */
  boxQty: number;
  /** 배차여부 */
  isTruckAppointed: boolean;
  /** 출고여부 */
  isShippingOut: boolean;
  destinationAddr: string;
  deliveryCompany: string;
  driverName: string;
  driverPhoneNo: string;
  truckNo: string;
  purchasePrice?: number;
  billingPrice?: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface PackingItem {
  id: number;
  invoiceNo: string;
  shippingId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: null;
}

export type ShippingList = PagingResponseForBoard<ShippingListItem> & {
  anotherTotal: number;
};

export type CompletedPickingListItem = ShippingListItem & {
  picker: BofulManager;
};

export type CompletedPickingList =
  PagingResponseForBoard<CompletedPickingListItem>;

export type ShippingShipmentTruckPackings = Omit<
  ShippingShipmentListItem,
  "packings"
> & {
  packings: PackingItem[];
};

export type ShippingShipmentListItem = Omit<ShippingListItem, "items">;

export type ShippingShipmentList =
  PagingResponseForBoard<ShippingShipmentListItem>;

export interface PickingSKUInfoForScanning {
  id: number;
  locationId: number;
  locationBarcode: string;
  skuId: number;
  skuBarcode: string | undefined;
  productName: string;
  currentQty: number;
  quantity: number;
}

export interface ReportProblem {
  skuId: number;
  locationId: number;
  workerId: number;
  wmsStatus: WmsStatus;
  kind: BofulProblem;
  directInput?: string;
}

export interface ShippingInvoice {
  /**
   * 분류코드
   */
  classificationCode: string;
  /**
   * 배송정보
   */
  deliveryInfo: string;
  /**
   * 운임
   */
  freight: string;
  /**
   * 운임구분
   */
  freightClassification: number;
  itemName: { sku: { itemName: string } }[];
  receiptDate: string;
  // TODO: 필요할때마다 더 추가
}

export type UnSetRowInfo = {
  id: number;
  companyName: string;
  teamName: string;
  sumOfSKUQuantity: string;
};

export type AdminShippingListStatus =
  | "waitingExpected"
  | "waitingToday"
  | "inspect"
  | "done"
  | "cancel";

export type AdminShippingListWmsStatus =
  | "picking"
  | "packing"
  | "ready"
  | "done";

export interface ShippingUser {
  representativeName: string | null; // 대표자 성명
  address: string | null; // 회사 성명
  company: string; // 회사명
  BRN: string; // 사업자등록번호
}

export interface TransactionStatementData {
  invoiceNo: string;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  orderNo: string;
  senderPhone: string;
  teamBRN: string;
  teamRepresentativeName: string;
  teamCompany: string;
  teamAddress: string;
  items: {
    printDate: string;
    productName: string;
    quantity: number;
  }[];
  requestMemo: ReactNode;
}

export interface ShippingInvoiceExcelItem {
  shippingId: ExcelItem<string>;
  parcelCompany: ExcelItem<string>;
  invoiceNo: ExcelItem<string>;
  note: ExcelItem<string>;
  hasError: boolean;
}

export interface KyoungDongShippingFreightListItem {
  /** 우편번호 */
  customerPostalCode: string;
  /** 도착영업소 */
  arrivalOffice: string | null;
  /** 받는분 */
  customerName: string;
  /** 전화번호 */
  customerPhone: string;
  /** 기타전화번호 */
  otherPhone: string | null;
  /** 주소 */
  customerAddress: string;
  /** 상세주소 */
  customerDetailAddress: string;
  /** 품목명 */
  itemName: string;
  /** 수량 */
  quantity: string;
  /** 포장상태 */
  packingType: string;
  /** 개별단가(만원) */
  unitPrice: string | null;
  /** 배송구분 */
  deliveryType: string;
  /** 운임 */
  freightCharge: string | null;
  /** 별도운임 */
  separateCharge: string;
  /** 기타운임 */
  otherCharge: string;
  /** 메모1 | 구매자요청사항 */
  memo1: string;
  /** 메모2 | 고객사명 */
  memo2: string;
  /** 메모3 */
  memo3: string | null;
  /** 메모4 */
  memo4: string | null;
  /** 메모5 */
  memo5: string | null;
  /** 메모6 */
  memo6: string | null;
  /** 메모7 */
  memo7: string | null;
  /** 메모8 */
  memo8: string | null;
  /** 메모9 */
  memo9: string | null;
  /** 메모10 */
  memo10: string | null;
  /** 메모11 */
  memo11: string | null;
  /** 메모12 */
  memo12: string | null;
  /** 메모13 */
  memo13: string | null;
  /** 메모14 */
  memo14: string | null;
  /** 메모15 | 출고요청번호 */
  memo15: string;
}

export interface KyoungDongShippingInvoiceExcelItem {
  id: number;
  result: string;
  customerPostalCode: ExcelItem<string>;
  customerName: ExcelItem<string>;
  customerPhone: ExcelItem<string>;
  customerAddress: ExcelItem<string>;
  customerDetailAddress: ExcelItem<string>;
  itemName: ExcelItem<string>;
  quantity: ExcelItem<string>;
  packingType: ExcelItem<string>;
  memo1: ExcelItem<string>;
  memo2: ExcelItem<string>;
  memo3: ExcelItem<string>;
  memo15: ExcelItem<string>;
  note: ExcelItem<string>;
  hasError: boolean;
}

export interface AdminShippingListItem {
  id: number;
  invoice: ShippingInvoice;
  shipping: PartialShippingListItem;
  user: ShippingUser;
  isPrinted: boolean;
}

export type PartialShippingItem = RecursivePartial<ShippingItem> & {
  // Admin 대표상품명 표시를 위함
  representativeItemName?: string;
};

export type PartialShippingListItem = Overwrite<
  ShippingListItem,
  { items: PartialShippingItem[] }
>;

export interface ShippingListItemBySkuId {
  /** 출고번호 */
  id: number;
  /** 출고상태 */
  shippingStatus: ShippingStatus;
  /** 작업상태 */
  wmsStatus: WmsStatus;
  skuId: number;
  locationId: number;
  /** 로케이션 바코드 */
  barCode: string;
  /** 출고수량 */
  quantity: number;
  /** 취소 작업 중 수량 */
  cancelWorkingQuantity: number;
  /** 취소 완료 수량 */
  cancelDoneQuantity: number;
  /** 반품 완료 수량 */
  returnedQuantity: number;
  customerName: string;
  customerAddress: string;
  invoiceNo: string;
  /** 출고생성일자 */
  createdAt: string;
  /** 출고일자 */
  dueDate: string;
}

export interface DomesticShippingExcelItem {
  channel: ExcelItem<string>;
  orderNo: ExcelItem<string>;
  warehouse: ExcelItem<string>;
  skuId: ExcelItem<string>;
  /** 주문수량 */
  quantity: ExcelItem<string>;
  /** 출고수량 */
  shippingQty: ExcelItem<string>;
  /** 재고수량 */
  availableQty: ExcelItem<string>;
  senderName: ExcelItem<string>;
  senderPhone: ExcelItem<string>;
  customerName: ExcelItem<string>;
  customerPhone: ExcelItem<string>;
  customerAddress: ExcelItem<string>;
  customerDetailAddress?: ExcelItem<string>;
  customerPostalCode: ExcelItem<string>;
  deliveryType: ExcelItem<string>;
  dueDate: ExcelItem<string>;
  memo: ExcelItem<string>;
  /** 에러가 하나라도 있는지 여부 */
  hasError: boolean;
  /** 중복 주문번호 여부 */
  existOrderNo: boolean;
}

export interface OverseasShippingExcelItem {
  channel: ExcelItem<string>;
  orderNo: ExcelItem<string>;
  warehouse: ExcelItem<string>;
  skuId: ExcelItem<string>;
  /** 수출정보 */
  globalSales: ExcelItem<string>;
  /** 주문수량 */
  quantity: ExcelItem<string>;
  /** 출고수량 */
  shippingQty: ExcelItem<string>;
  /** 재고수량 */
  availableQty: ExcelItem<string>;
  senderName: ExcelItem<string>;
  senderPhone: ExcelItem<string>;
  customerName: ExcelItem<string>;
  customerPhone: ExcelItem<string>;
  customerCountryCode: ExcelItem<string>;
  customerStateCode: ExcelItem<string>;
  /** 받는 분 도시명 */
  customerDetailAddress?: ExcelItem<string>;
  /** 받는 분 주소 */
  customerAddress: ExcelItem<string>;
  customerPostalCode: ExcelItem<string>;
  deliveryType: ExcelItem<string>;
  dueDate: ExcelItem<string>;
  memo: ExcelItem<string>;
  /** 에러가 하나라도 있는지 여부 */
  hasError: boolean;
  /** 중복 주문번호 여부 */
  existOrderNo: boolean;
}

export type AdminShippingLitItem = Overwrite<
  ShippingListItem,
  { items: (ShippingItem | ClassifiedGroupItem)[] }
>;

export type AdminShippingListTabStatus =
  | "ALL"
  | "WAITING_EXPECTED"
  | "WAITING_TODAY"
  | "INSPECT"
  | "PICKING"
  | "PACKING"
  | "SHIPMENT_READY"
  | "SHIPMENT_DONE"
  | "DONE"
  | "CANCEL";

export type ShippingPickingListSummary = {
  /** 회사명 또는 팀명 */
  name: string;
  /** 팀 아이디 */
  teamId: number;
  /** 존재하는 출고 수*/
  count: number;
};

export type ShippingPickingList = {
  /** 상품 정보 */
  itemCombination: Array<{
    /** SKU ID (G포함) */
    skuId: string;
    /** 주문수량 */
    qty: number;
    /** 품목 이름 */
    itemName: string;
  }>;
  /** 품목 가짓수 */
  amount: number;
  /** 주문수량 */
  count: number;
  /** 포함된 출고번호 리스트 */
  shippingIds: number[];
};

export enum ShippingDetailStatus {
  /** 에러 발생 */
  Error = "error",
  /** 운송장 출력 */
  Print = "print",
  /** 피킹 시작 */
  PickingStart = "pickingStart",
  /** 피킹 완료 */
  PickingEnd = "pickingEnd",
  /** 패킹 시작 */
  PackingStart = "packingStart",
  /** 패킹 완료 */
  PackingEnd = "packingEnd",
  /** 출하 완료 */
  CompleteShipment = "completeShipment",
  /** 배송 중 */
  Delivering = "delivering",
  /** 출고 완료 */
  Done = "done",
  /** 취소 */
  Cancel = "cancel",
  /** 반품 중 */
  Return = "return",
  /** 초기화 */
  Reset = "reset",
}

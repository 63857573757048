import { LocationOff, LocationOn } from "@mui/icons-material";
import { Button } from "@mui/material";

import LOCATION_QUERY from "queries/LOCATION_QUERY";
import { toThousandUnitFormat } from "utils/number";

export default function ChangeLocationActivation({
  type,
  checkedIds,
  refetchLocationList,
}: {
  type: "activate" | "deactivate";
  checkedIds: number[];
  refetchLocationList: () => void;
}) {
  const {
    mutate: changeLocationActivation,
    ResponseHandler: ResponseHandlerOfChangingLocationActivation,
  } = LOCATION_QUERY.useChangeLocationActivation();

  const canUpdateStatus = checkedIds.length > 0;

  const isActivateType = type === "activate";

  const handleLocationActivationChange = () => {
    changeLocationActivation(
      { ids: checkedIds, isActive: isActivateType },
      {
        onSuccess: refetchLocationList,
      }
    );
  };

  return (
    <>
      <Button
        color={isActivateType ? "success" : "warning"}
        variant="outlined"
        startIcon={isActivateType ? <LocationOn /> : <LocationOff />}
        onClick={handleLocationActivationChange}
        disabled={!canUpdateStatus}
      >
        로케이션 {isActivateType ? "" : "비"}활성화 (
        {toThousandUnitFormat(checkedIds.length)})
      </Button>

      {ResponseHandlerOfChangingLocationActivation}
    </>
  );
}

import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
import Router from "Router";
import { css } from "styled-components";

import { REACT_QUERY_CLIENT_CONFIG } from "services/query";
import { showVerticalScrollBarAlways } from "styles/functions";
import GlobalStyle from "styles/global";
import { printBuildInfo } from "utils/common";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { IS_UNDER_LOCAL_DEVELOPMENT } from "./constants/common";

const theme = createTheme({
  typography: {
    fontFamily: [
      "'Pretendard Variable'",
      "Pretendard",
      "-apple-system",
      "BlinkMacSystemFont",
      "system-ui",
      "Roboto",
      '"Helvetica Neue"',
      '"Segoe UI"',
      '"Apple SD Gothic Neo"',
      '"Noto Sans KR"',
      '"Malgun Gothic"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "sans-serif",
    ].join(","),
  },
});

function App() {
  useEffect(() => {
    printBuildInfo();
  }, []);

  const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);

  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          {IS_UNDER_LOCAL_DEVELOPMENT && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}

          <Router>
            <GlobalStyle
              appCustomReset={css`
                * {
                  ${showVerticalScrollBarAlways()}
                }
              `}
            />
          </Router>
        </QueryClientProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;

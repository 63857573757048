import { memo } from "react";
import { TableRow } from "@mui/material";

import { KeyType } from "hooks/useMultiSelect";

import { PinnedColumn, TableBodyRow } from "../..";
import CheckBoxCell from "./CheckboxCell";
import MemoizedCell from "./MemoizedCell";

function MemoizedTableRow<CellId extends string>({
  row,
  pinnedColumnList,

  isChecked,
  select,
  unSelect,
}: {
  row: TableBodyRow<CellId>;
  pinnedColumnList: PinnedColumn<CellId>[] | undefined;

  isChecked: boolean;
  select: (key: KeyType) => void;
  unSelect: (key: KeyType) => void;
}) {
  const {
    id,
    handleRowClick,
    handleRowAdd,
    handleRowRemove,
    backgroundColor,
    ...pureRow
  } = row;

  return (
    <TableRow
      hover
      onClick={() => {
        if (window.getSelection()?.toString() !== "") {
          return;
        }

        handleRowClick?.();
      }}
      sx={{
        cursor: handleRowClick ? "pointer" : "default",

        ...(backgroundColor && { backgroundColor }),
      }}
      selected={isChecked}
    >
      <CheckBoxCell
        id={row.id}
        isChecked={isChecked}
        select={select}
        unSelect={unSelect}
      />

      {Object.keys(pureRow).map((rowKey, index) => {
        const rowValue = pureRow[rowKey as keyof typeof pureRow];

        const leftPosition = (pinnedColumnList ?? []).reduce(
          (acc, cur, idx) => {
            const isPinnedCell = pinnedColumnList?.some(
              (pinnedColumn) => pinnedColumn.id === rowKey
            );
            if (isPinnedCell && idx <= index) {
              return acc + cur.prevColumnWidth;
            }

            return acc;
          },
          0
        );

        const isPinned = !!leftPosition;

        const isLastPinnedCell = (pinnedColumnList ?? []).length - 1 === index;

        return isPinned ? (
          <MemoizedCell
            key={rowKey}
            rowValue={rowValue}
            isPinned={isPinned}
            leftPosition={leftPosition}
            isLastPinnedCell={isLastPinnedCell}
          />
        ) : (
          <MemoizedCell key={rowKey} rowValue={rowValue} isPinned={isPinned} />
        );
      })}
    </TableRow>
  );
}

export default memo(MemoizedTableRow);

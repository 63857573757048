import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { BooleanString } from "types/common";

const filterOptionList: TableHeadFilterOption<BooleanString>[] = [
  { label: "Y", value: "true" },
  { label: "N", value: "false" },
];

export default function useIsDelayFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: IsDelayFilterPanel,
    filterList: isDelayFilterList,
    resetFilterState: resetIsDelayFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    IsDelayFilterPanel,
    isDelayPayloadListByFilter: isDelayFilterList,
    resetIsDelayFilter,
  };
}

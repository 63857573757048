import { IS_ON_BROWSER } from "constants/common";
import React from "react";
import { useState } from "react";

/**
 * 특정 dom이 focus되었는지를 확인
 * @param targetRef
 */
export default function useIsTargetFocused(
  targetRef: React.MutableRefObject<any>
) {
  if (!IS_ON_BROWSER) {
    return;
  }

  const [isFocused, setIsFocused] = useState(false);

  const handleFocusIn = () => {
    setIsFocused(targetRef.current === document.activeElement);
  };

  const handleFocusOut = () => {
    setIsFocused(targetRef.current === document.activeElement);
  };

  React.useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  return isFocused;
}

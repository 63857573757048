import { ShippingMemo } from "types/shipping";
import { toFormattedDate } from "utils/date";

export default function RequestMemoList({ memo }: { memo: ShippingMemo[] }) {
  return (
    <ul>
      {memo.map(({ memo, createdAt }, i) => (
        <li key={`memo${i}`}>
          {memo ?? "-"}{" "}
          <span className="memo-date">
            (입력일: {toFormattedDate(createdAt, "YYYY.MM.DD")})
          </span>
        </li>
      ))}
    </ul>
  );
}

import { ProcessStatus } from "types/returning";
import { calculateDifference } from "utils/number";

import StateBadgeForInspection from "_sds-v2/components/stateBadge/StateBadgeForInspection";

import Styled from "./index.styles";

const getProblemStatus = (processStatus: ProcessStatus | "all") => {
  if (processStatus === "all")
    return (
      <Styled.problemStatus>
        <StateBadgeForInspection status="stock" label="정상입고" />
        <StateBadgeForInspection status="faulty" label="불량입고" />
      </Styled.problemStatus>
    );

  if (processStatus === "stock")
    return <StateBadgeForInspection status="stock" label="정상입고" />;

  if (processStatus === "faulty")
    return <StateBadgeForInspection status="faulty" label="불량입고" />;

  if (processStatus === "normal")
    return <StateBadgeForInspection status="unselect" label="미선택" />;

  return <></>;
};

const getIsDifferentQuantity = ({
  isDecidedQuantityStatus,
  quantity,
  actualQty,
  placeQty,
}: {
  isDecidedQuantityStatus: boolean;
  quantity: number;
  actualQty: number | null | undefined;
  placeQty: number | null | undefined;
}) =>
  isDecidedQuantityStatus
    ? !!calculateDifference(quantity, actualQty) ||
      !!calculateDifference(actualQty, placeQty)
    : !!calculateDifference(quantity, actualQty);

export { getProblemStatus, getIsDifferentQuantity };

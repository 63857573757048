import { useMemo } from "react";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import { COLOR } from "styles/constants";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { replaceEmptyToDash } from "utils/string";

import Icon from "components/sds-v1/Icon";

import CommonStyled from "../../index.styles";

export default function useShippingSkuList({
  shippingId,
  page,
  perPage,
  searchTerm,
  selectedSkuId,
  handleSkuSelect,
  enabled,
}: {
  shippingId: number;
  page: number;
  perPage: number;
  searchTerm?: string;
  selectedSkuId?: number;
  handleSkuSelect: (id: number) => void;
  enabled: boolean;
}) {
  const {
    data: shippingSkuData,
    ResponseHandler: ResponseHandlerOfShippingSkuData,
  } = SHIPPING_QUERY.useGetShippingSkuList({
    page,
    perPage,
    shippingId,
    ...(searchTerm ? { skuId: searchTerm } : {}),
    enabled,
  });

  const shippingSkuTableList = useMemo(() => {
    if (!shippingSkuData) return [];

    return shippingSkuData.list.map((item) => {
      const selected = selectedSkuId === item.skuId;

      return {
        rowKey: item.id,
        select: (
          <Icon
            type={selected ? "radioSelected" : "radio"}
            color={selected ? COLOR.primaryBlue : COLOR.grayScale_400}
            size={1.5}
            onClick={() => handleSkuSelect(item.skuId)}
          />
        ),
        skuId: getFormattedSingleSkuId(item.skuId),
        itemName: (
          <div className="item-name">
            {replaceEmptyToDash(item.sku.itemName)}
          </div>
        ),
        barCode: (
          <CommonStyled.limitedWidthEllipsisText maxWidth="110px">
            {replaceEmptyToDash(item.sku.barCode)}
          </CommonStyled.limitedWidthEllipsisText>
        ),
      };
    });
  }, [handleSkuSelect, selectedSkuId, shippingSkuData]);

  return {
    shippingSkuTableList,
    shippingSkuTotalCount: shippingSkuData?.total,
    ResponseHandlerOfShippingSkuData,
  };
}

import { memo } from "react";
import { Checkbox, TableCell } from "@mui/material";
import { blue } from "@mui/material/colors";

function CheckboxHeadCell({
  hasGroupedHeadRow,

  isAllSelected,
  selectedCount,
  selectAll,
  unSelectAll,
}: {
  hasGroupedHeadRow: boolean;

  isAllSelected: boolean;
  selectedCount: number;
  selectAll: () => void;
  unSelectAll: () => void;
}) {
  return (
    <TableCell
      sx={{
        width: 80,
        backgroundColor: blue[50],

        position: "sticky",
        top: hasGroupedHeadRow ? 37 : 0,
        left: 0,
        zIndex: 10,
      }}
    >
      <Checkbox
        onClick={isAllSelected ? unSelectAll : selectAll}
        indeterminate={selectedCount > 0 && !isAllSelected}
        checked={isAllSelected}
      />
    </TableCell>
  );
}

export default memo(CheckboxHeadCell);

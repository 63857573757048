import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RECEIVING_QUERY from "queries/RECEIVING_QUERY";

import Layout from "containers/Layout";
import { ReceivingStatus } from "types/receiving";

interface ReceivingPayload {
  id: string;
  receivingStatus: ReceivingStatus;
}

const receivingStatusOptions: {
  value: ReceivingStatus;
  label: string;
}[] = [
  { value: "canceled", label: "의뢰 삭제" },
  { value: "beforeReceiving", label: "입고 전" },
  { value: "waitingInspection", label: "검수 대기 중" },
  { value: "inspecting", label: "검수 중" },
];

export function RevertReceivingStatusPage() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ReceivingPayload>({
    mode: "onChange",
    defaultValues: {
      id: "",
    },
  });

  const { mutate: updateReceivingStatus, ResponseHandler } =
    RECEIVING_QUERY.useModifyReceivingStatusForce({
      successModalInfo: {
        handleConfirmSuccess: (initQuery) => {
          initQuery();
          reset();
        },
        customizeMessage: () => ({
          messageType: "titleAndBody",
          title: "입고 상태 수정 완료",
        }),
      },
    });

  const handleUpdateReceivingStatusForce = ({
    id,
    receivingStatus,
  }: {
    id: string;
    receivingStatus: ReceivingStatus;
  }) => {
    updateReceivingStatus({
      pathParams: { id: parseInt(id) },
      receivingStatus,
    });
  };

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.secondary"
        >
          입고 상태 되돌리기
        </Typography>
        <form onSubmit={handleSubmit(handleUpdateReceivingStatusForce)}>
          <Stack>
            <Controller
              control={control}
              name="id"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="입고요청번호"
                  error={!!errors.id}
                  helperText={errors.id && "숫자만 입력해주세요."}
                />
              )}
            />
          </Stack>

          <Stack spacing={2} sx={{ marginBottom: "10px" }}></Stack>
          <Box
            sx={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}
          >
            <Typography variant="h6" sx={{ marginRight: "10px" }}>
              돌아갈 입고 상태
            </Typography>
            <Controller
              control={control}
              name="receivingStatus"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="입고 상태"
                  error={!!errors.receivingStatus}
                  sx={{ flexGrow: 1 }}
                >
                  {receivingStatusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>

          <Stack spacing={2} sx={{ marginBottom: "10px" }}>
            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandler}
    </Layout>
  );
}

import { useMediaQuery } from "react-responsive";
import { MOBILE_MAX_WIDTH } from "styles/constants";

/**
 * isMobile: CSS 미디어쿼리와 같이 max-width체크
 */
export default function useCheckIsMobile() {
  const isBrowser = Boolean(typeof window !== "undefined");

  const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

  return {
    isMobile: isBrowser ? isMobile : false,
    isBrowser,
  };
}

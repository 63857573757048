import { useState } from "react";
import { Gavel } from "@mui/icons-material";
import { Button } from "@mui/material";

import MATERIAL_QUERY from "queries/MATERIAL_QUERY";

import TempTotalPickingModal from "./TempTotalPickingModal";

export default function TempTotalPicking({
  selectedShippingIdList,
  teamId,
}: {
  selectedShippingIdList: number[];
  teamId: number | undefined;
}) {
  const [isTempTotalPickingModalOpen, setIsTempTotalPickingModalOpen] =
    useState(false);

  const {
    data: materialListForTotalPicking,
    refetch: refetchMaterialListForTotalPicking,
    ResponseHandler: ResponseHandlerOfMaterialListForTotalPicking,
  } = MATERIAL_QUERY.useGetMaterialListForTotalPicking({
    teamId,
    enabled: false,
  });

  const handleTempTotalPickingModalOpen = () => {
    refetchMaterialListForTotalPicking({
      throwOnError: true,
    }).then(() => setIsTempTotalPickingModalOpen(true));
  };

  const handleTempTotalPickingModalClose = () => {
    setIsTempTotalPickingModalOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<Gavel />}
        variant="outlined"
        onClick={handleTempTotalPickingModalOpen}
        disabled={!selectedShippingIdList.length}
      >
        (임시)토탈피킹
      </Button>

      <TempTotalPickingModal
        key={String(isTempTotalPickingModalOpen)}
        selectedShippingIdList={selectedShippingIdList}
        materialListForTotalPicking={materialListForTotalPicking}
        isTempTotalPickingModalOpen={isTempTotalPickingModalOpen}
        onTempTotalPickingModalClose={handleTempTotalPickingModalClose}
      />

      {ResponseHandlerOfMaterialListForTotalPicking}
    </>
  );
}

import { useCallback, useMemo, useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR,
  GET_ADMIN_SHIPPING_LIST_REQ_SEARCH_KIND,
} from "api-interfaces/shipping";

import { SearchItem } from "hooks/useSearchWithTermForBufulAdminOnly";

export const checkIsValidShippingQuantityLength = ({
  searchList,
  shippingQuantity,
}: {
  searchList: SearchItem<GET_ADMIN_SHIPPING_LIST_REQ_SEARCH_KIND>[];
  shippingQuantity: string;
}) => {
  const skuIdSearchListLength = searchList.filter(
    (search) => search.searchKind === "skuId"
  ).length;
  const quantityFilterLength = shippingQuantity
    ? shippingQuantity.split(",").length
    : 0;

  return skuIdSearchListLength === quantityFilterLength;
};
export default function useShippingQuantityFilter({
  operator,
  searchList,
  resetCurrentPage,
}: {
  operator: GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR;
  searchList: SearchItem<GET_ADMIN_SHIPPING_LIST_REQ_SEARCH_KIND>[];
  resetCurrentPage: () => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [shippingQuantity, setShippingQuantity] = useState("");
  const [draftShippingQuantity, setDraftShippingQuantity] = useState("");

  const isFilterOpen = !!anchorEl;

  const validationMessage = (() => {
    if (draftShippingQuantity) {
      const isValidFormat = draftShippingQuantity.match(/^\d+(,\d+)*$/);
      if (!isValidFormat) {
        return "올바른 형식으로 입력하세요. (예: 1,2,3)";
      }

      const isValidLength = checkIsValidShippingQuantityLength({
        searchList,
        shippingQuantity: draftShippingQuantity,
      });
      if (!isValidLength) {
        return "SKU ID 검색리스트와 출고수량 필터의 개수가 일치해야 합니다.";
      }

      return "";
    }

    return "";
  })();

  const handleDraftShippingQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDraftShippingQuantity(e.target.value);
    },
    []
  );

  const handleFilterReset = useCallback(() => {
    setDraftShippingQuantity("");
  }, []);

  const handleFilterSubmit = useCallback(() => {
    setShippingQuantity(draftShippingQuantity);

    setAnchorEl(null);

    resetCurrentPage();
  }, [draftShippingQuantity, resetCurrentPage]);

  const initDraftFilter = useCallback(() => {
    setDraftShippingQuantity(shippingQuantity);
  }, [shippingQuantity]);

  const handleFilterOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorEl(null);

    initDraftFilter();
  }, [initDraftFilter]);

  const ShippingQuantityFilterPanel = useMemo(
    () => (
      <>
        {operator === "or" ? (
          <Tooltip
            title="SKU ID 검색조건이 'AND'일 때만 사용 가능합니다."
            placement="left"
          >
            <span>
              <IconButton size="small" disabled>
                <FilterAlt fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <IconButton
            size="small"
            color={shippingQuantity ? "primary" : "default"}
            onClick={isFilterOpen ? handleFilterClose : handleFilterOpen}
          >
            <FilterAlt fontSize="small" />
          </IconButton>
        )}

        <Popper
          open={isFilterOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 999 }}
        >
          <ClickAwayListener onClickAway={handleFilterClose}>
            <Paper sx={{ p: 2, width: 264 }}>
              <Stack>
                <TextField
                  sx={{ mb: 4 }}
                  size="small"
                  placeholder="출고수량을 입력하세요."
                  value={draftShippingQuantity}
                  onChange={handleDraftShippingQuantityChange}
                  helperText={validationMessage}
                  error={!!validationMessage}
                />

                <Stack direction="row" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={handleFilterReset}
                  >
                    초기화
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={!!validationMessage}
                    onClick={handleFilterSubmit}
                  >
                    적용
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    ),
    [
      operator,
      shippingQuantity,
      isFilterOpen,
      handleFilterClose,
      handleFilterOpen,
      anchorEl,
      draftShippingQuantity,
      handleDraftShippingQuantityChange,
      validationMessage,
      handleFilterReset,
      handleFilterSubmit,
    ]
  );

  const resetShippingQuantityFilter = useCallback(() => {
    setAnchorEl(null);
    setShippingQuantity("");
    setDraftShippingQuantity("");
  }, []);

  return {
    ShippingQuantityFilterPanel,
    shippingQuantityPayloadByFilter: shippingQuantity,
    resetShippingQuantityFilter,
  };
}

import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .filter-label {
    margin-left: 8px;
    ${setFontStyle("SubHead3", "Bold")}
    color: ${COLOR.grayScale_700};
  }
`;

export default {
  container,
};

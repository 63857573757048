import styled from "styled-components";

const container = styled.div``;

const metaInfoOnItemInfo = styled.div`
  > .item {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 4px 0;

    > .label {
      min-width: 100px;
      font-weight: bold;
    }
  }
`;

const editFormFooter = styled.div`
  text-align: right;
  margin-top: 16px;

  > .summary-and-reason {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-top: 20px;
  }
`;

const totalDifference = styled.div`
  text-align: right;

  > .item {
    display: flex;
    justify-content: space-between;
    padding: 2px 0;

    > .label {
      text-align: right;
      flex: 1;
    }

    > .value {
      width: 60px;
      font-weight: bold;
    }
  }

  > .total {
    font-weight: bold;
    margin-top: 4px;
    border-top: 1px #c3b5b5 solid;
    padding-top: 4px;
  }
`;

const reason = styled.div`
  width: 40%;
`;

const confirmBodyForUpdate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > .warning {
    color: red;
    margin-bottom: 10px;
  }

  > .difference {
  }
`;

export default {
  container,
  metaInfoOnItemInfo,
  editFormFooter,
  totalDifference,
  reason,
  confirmBodyForUpdate,
};

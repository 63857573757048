import { useMemo, useState } from "react";
import { VALIDATE_SHIPPING_EXCEL_INVOICE_LIST_RES } from "api-interfaces/shipping";
import { TableDataListItem } from "headlessComponents/table/useTable";
import { ToggleFilterType } from "headlessComponents/useToggleFilter";

import useClientSidePaging from "hooks/useClientSidePaging";
import useExcelTableList, {
  ShippingInvoiceExcelTableItem,
} from "hooks/useExcelTableList";
import { ShippingInvoiceExcelItem } from "types/shipping";

import ExcelColumnToolTip from "components/sds-v1/excel/ExcelColumnToolTip";
import ExcelListHeader from "components/sds-v1/excel/ExcelListHeader";
import Paging from "components/sds-v1/Paging";
import Table from "components/sds-v1/table/Table";

import Styled from "./index.styles";

const columnInfo = {
  id: {
    label: "NO.",
    portion: 0.5,
  },
  result: {
    label: "등록결과",
  },
  shippingId: {
    label: (
      <>
        출고요청번호
        <ExcelColumnToolTip
          desc={
            <>
              - 필수 입력 항목
              <br />- 출고완료된 출고요청번호 입력
            </>
          }
          bodyWidth={13}
        />
      </>
    ),
  },
  parcelCompany: {
    label: (
      <>
        택배회사명
        <ExcelColumnToolTip
          desc={
            <>
              - 필수 입력 항목
              <br />- 시스템에 존재하는 택배사명 입력
            </>
          }
          bodyWidth={14}
        />
      </>
    ),
  },
  invoiceNo: {
    label: (
      <>
        송장번호
        <ExcelColumnToolTip desc="- 필수 입력 항목" bodyWidth={9} />
      </>
    ),
    portion: 1.5,
  },
  note: {
    label: "비고",
    portion: 3,
  },
};

const cellOrderList = Object.keys(columnInfo);

export default function ExcelInvoiceList({
  excelList,
  successExcelList,
  failureExcelList,
}: {
  excelList: VALIDATE_SHIPPING_EXCEL_INVOICE_LIST_RES | undefined;
  successExcelList: ShippingInvoiceExcelItem[] | undefined;
  failureExcelList: ShippingInvoiceExcelItem[] | undefined;
}) {
  const [selectedToggleFilter, setSelectedToggleFilter] =
    useState<ToggleFilterType>("none");

  const { excelTableList, successExcelTableList, failureExcelTableList } =
    useExcelTableList<ShippingInvoiceExcelItem>({
      excelList,
      successExcelList,
      failureExcelList,
      cellOrderList,
    });

  const visibleExcelTableList = useMemo(() => {
    if (selectedToggleFilter === "success") {
      return successExcelTableList;
    }

    if (selectedToggleFilter === "failure") {
      return failureExcelTableList;
    }

    return excelTableList;
  }, [
    selectedToggleFilter,
    excelTableList,
    successExcelTableList,
    failureExcelTableList,
  ]);

  const paging = useClientSidePaging(10, visibleExcelTableList);

  return (
    <Styled.excelListContainer>
      <ExcelListHeader<TableDataListItem<ShippingInvoiceExcelTableItem>>
        selectedToggleFilter={selectedToggleFilter}
        setSelectedToggleFilter={setSelectedToggleFilter}
        countOfSuccessItems={successExcelTableList?.length ?? 0}
        countOfFailureItems={failureExcelTableList?.length ?? 0}
      />

      <Paging
        currentPage={paging.pageNum}
        pageSize={paging.pageSize}
        currentPageData={
          <Table
            rowMinHeight="77px"
            columnInfo={columnInfo}
            dataList={paging.pageData}
            disabledRowHoverBgColor={true}
          />
        }
        handleClickPage={paging.setPageNum}
      />
    </Styled.excelListContainer>
  );
}

import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { Print } from "@mui/icons-material";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import {
  GET_DETAIL_RES_DATA,
  GET_DETAIL_RES_DATA_RECEIVING,
} from "api-interfaces/receiving";

import useMultiSelect from "hooks/useMultiSelect";
import { toFormattedDate } from "utils/date";
import { getSupplyType, getTitleAmongItemListName } from "utils/forwarding";
import {
  getFormattedSingleSkuId,
  getReceivingStatusString,
  getSKUItemTypeQuantity,
  toParcelCompanyLabel,
} from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import {
  getAdminPlaceQty,
  getBLNumber,
  getFormattedReceivingId,
  getTransportMethodTitle,
} from "utils/receiving";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import usePrintProductLabelModal from "../../useProductLabelPrintModal";
import ChangeDueDate from "./ChangeDueDate";
import Detail from "./Detail/Detail";
import Styled from "./index.styles";

type CellId =
  | "checkbox"
  | "order"
  | "itemName"
  | "skuId"
  | "managementDate"
  | "barCode"
  | "productCode"
  | "managementCode"
  | "quantity"
  | "actualQty"
  | "placeQty";

type ItemInfoType = "requests" | "tasks" | "completed" | "canceled";

export default function DetailInfo({
  receivingData,
  type,
}: {
  receivingData: GET_DETAIL_RES_DATA;
  type: ItemInfoType;
}) {
  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  useEffect(() => {
    initSelectionDict(receivingData.receiving?.items.map((v) => v.id) ?? []);
  }, []);

  const { openPrintingProductLabel, ModalOfPrintingProductLabel } =
    usePrintProductLabelModal();

  const handlePrintingProductLabelModalOpen = useCallback(() => {
    if (!receivingData) {
      return;
    }

    openPrintingProductLabel(
      receivingData.receiving.items
        .filter((item) => selectedIdList.includes(item.id))
        .map((item) => ({
          skuId: item.skuId,
          quantity: item.quantity,
          itemName: item.sku.itemName,
          companyName: receivingData.receiving?.team?.company ?? "",
          teamName: receivingData.receiving?.team?.name ?? "",
        }))
    );
  }, [openPrintingProductLabel, receivingData, selectedIdList]);

  const headCells: TableHeadCell<CellId>[] = useMemo(
    () => [
      {
        id: "checkbox",
        disablePadding: false,
        label: (
          <Checkbox
            onClick={isAllSelected ? unSelectAll : selectAll}
            indeterminate={selectedCount > 0 && !isAllSelected}
            checked={isAllSelected}
          />
        ),
        width: 40,
      },
      {
        id: "order",
        disablePadding: false,
        label: "순서",
        width: 80,
      },
      {
        id: "itemName",
        disablePadding: false,
        label: "상품명",
        width: 200,
      },
      {
        id: "skuId",
        disablePadding: false,
        label: "SKU ID",
        width: 120,
      },
      {
        id: "managementDate",
        disablePadding: false,
        label: "관리일자",
        width: 120,
      },
      {
        id: "barCode",
        disablePadding: false,
        label: "상품바코드",
        width: 150,
      },
      {
        id: "productCode",
        disablePadding: false,
        label: "판매자상품코드",
        width: 150,
      },
      {
        id: "managementCode",
        disablePadding: false,
        label: "옵션관리코드",
        width: 150,
      },
      {
        id: "quantity",
        disablePadding: false,
        label: "입고예정수량",
        width: 120,
      },
      {
        id: "actualQty",
        disablePadding: false,
        label: "검수수량",
        width: 120,
      },
      {
        id: "placeQty",
        disablePadding: false,
        label: "확정수량",
        width: 120,
      },
    ],
    [isAllSelected, selectAll, selectedCount, unSelectAll]
  );

  const mapDataForTable = useCallback(
    (data: GET_DETAIL_RES_DATA_RECEIVING | undefined, isForCSV?: boolean) => {
      if (!data) {
        return [];
      }

      return data.items
        .filter((v) => (isForCSV ? selectedIdList.includes(v.id) : true))
        .map((v, i) => {
          const isChecked = !!selectionDict[v.id];

          const row: TableBodyRow<CellId> = {
            checkbox: (
              <Checkbox
                onClick={(e) => {
                  e.stopPropagation();
                  isChecked ? unSelect(v.id) : select(v.id);
                }}
                checked={isChecked}
              />
            ),

            order: i + 1,
            itemName: v.sku?.itemName,
            skuId: getFormattedSingleSkuId(v.skuId),
            managementDate: v.sku?.managementKind
              ? toFormattedDate(v.managementDate, "YYYY-MM-DD")
              : "-",
            barCode: v.sku?.barCode,
            productCode: v.sku?.productCode,
            managementCode: v.sku?.managementCode,
            quantity: toThousandUnitFormat(v.quantity),
            actualQty: v.actualQty ? toThousandUnitFormat(v.actualQty) : "-",
            placeQty: toThousandUnitFormat(
              getAdminPlaceQty({
                placeQty: v.placeQty,
                status: data.status,
              })
            ),
          };

          return row;
        });
    },
    [select, selectedIdList, selectionDict, unSelect]
  );

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(receivingData?.receiving);
  }, [mapDataForTable, receivingData?.receiving]);

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(receivingData?.receiving, true);
  }, [mapDataForTable, receivingData?.receiving]);

  return (
    <div style={{ maxHeight: "80vh" }}>
      {(() => {
        if (!receivingData?.receiving?.items.length) {
          return (
            <Typography variant="h6" component="div">
              상품이 없습니다
            </Typography>
          );
        }

        const unifiedId = getFormattedReceivingId(receivingData.receiving);

        return (
          <>
            <Stack gap={3}>
              <Styled.metaInfoOnItemInfo
                key={0}
                style={{ alignSelf: "flex-end" }}
              >
                {(() => {
                  const data: {
                    label: string;
                    value: ReactNode;
                  }[] = [
                    {
                      label: "도착요청일",
                      value: toFormattedDate(
                        receivingData.receiving.expectedDate,
                        "YYYY-MM-DD"
                      ),
                    },
                    {
                      label: "도착완료일",
                      value: toFormattedDate(
                        receivingData.receiving.receivedAt,
                        "YYYY-MM-DD"
                      ),
                    },
                    {
                      label: "입고완료일",
                      value: toFormattedDate(
                        receivingData.receiving.completeAt,
                        "YYYY-MM-DD"
                      ),
                    },
                    {
                      label: "입고기한",
                      value: (
                        <>
                          {toFormattedDate(
                            receivingData.receiving.dueDate,
                            "YYYY-MM-DD"
                          )}
                          {type === "requests" && (
                            <ChangeDueDate
                              receivingId={receivingData.receiving.id}
                              expectedDate={
                                receivingData.receiving.expectedDate
                              }
                              dueDate={receivingData.receiving.dueDate ?? null}
                            />
                          )}
                        </>
                      ),
                    },
                  ];
                  return data.map((v, i) => {
                    return (
                      <div className="item" key={i}>
                        <div className="label">{v.label}</div>
                        <div className="value">{v.value}</div>
                      </div>
                    );
                  });
                })()}
              </Styled.metaInfoOnItemInfo>

              <Detail
                title={"기본정보"}
                detailList={[
                  {
                    label: "입고현황",
                    value: getReceivingStatusString(
                      receivingData.receiving.status,
                      receivingData.receiving.inspectionStatus
                    ),
                  },
                  {
                    label: "입고요청수량(PCS)",
                    value: toThousandUnitFormat(
                      receivingData.receiving.quantity
                    ),
                  },
                  {
                    label: "입고요청번호",
                    value: unifiedId,
                  },
                  {
                    label: "검수수량(PCS)",
                    value: toThousandUnitFormat(
                      receivingData.receiving.actualQty
                    ),
                  },
                  {
                    label: "상품종류(SKU)",
                    value: toThousandUnitFormat(
                      getSKUItemTypeQuantity(receivingData.receiving.items)
                    ),
                  },
                  {
                    label: "입고수량(PCS)",
                    value: toThousandUnitFormat(
                      getAdminPlaceQty({
                        placeQty: receivingData.receiving.placeQty,
                        status: receivingData.receiving.status,
                      })
                    ),
                  },
                  {
                    label: "요청사항",
                    value: receivingData.receiving.memo
                      .map((v) => v.memo)
                      .join(","),
                  },
                ]}
              />
              <Detail
                title={"운송정보"}
                detailList={
                  receivingData.receiving.receivingKind === "domestic"
                    ? [
                        {
                          label: "출발지",
                          value: "국내 내륙",
                        },
                        {
                          label: "입고센터",
                          value: receivingData.receiving.warehouse?.name,
                        },
                        {
                          label: "운송수단",
                          value: getTransportMethodTitle({
                            freightType:
                              receivingData.receiving.bid?.freightType,
                            delivery: receivingData.receiving.delivery,
                            noNeedTransportNumber: true,
                          }),
                        },
                        {
                          label: "",
                          value: "",
                        },
                        {
                          label: "택배사(화물차량)",
                          value: (
                            <>
                              {receivingData.receiving.invoiceNo?.map(
                                (v, i) => (
                                  <div key={i}>
                                    {toParcelCompanyLabel(v.parcelCompany)}
                                  </div>
                                )
                              )}
                              {receivingData.receiving.driver &&
                                `${receivingData.receiving.driver.name} / ${receivingData.receiving.driver.phone} / ${receivingData.receiving.driver.truckNo}`}
                            </>
                          ),
                        },
                        {
                          label: "송장번호",
                          value: (
                            <>
                              {receivingData.receiving.invoiceNo?.map(
                                (v, i) => (
                                  <div key={i}>{v.invoiceNo}</div>
                                )
                              )}
                            </>
                          ),
                        },
                        {
                          label: "팔레트/박스/기타",
                          value: `${
                            receivingData.receiving.packingUnitPallet ?? "-"
                          }/${receivingData.receiving.packingUnitBox ?? "-"}/${
                            receivingData.receiving.packingUnitOther ?? "-"
                          }`,
                        },
                      ]
                    : [
                        {
                          label: "출발지",
                          value:
                            receivingData.receiving.bid?.startPort?.name || "-",
                        },
                        {
                          label: "입고센터",
                          value: receivingData.receiving.warehouse?.name,
                        },
                        {
                          label: "운송수단",
                          value: getTransportMethodTitle({
                            freightType:
                              receivingData.receiving.bid?.freightType,
                            delivery: receivingData.receiving.delivery,
                            noNeedTransportNumber: true,
                          }),
                        },
                        {
                          label: "품목",
                          value: getTitleAmongItemListName(
                            receivingData.receiving.bid.items
                          ),
                        },
                        {
                          label: "B/L (AWB)",
                          value: receivingData.receiving.bid
                            ? getBLNumber(receivingData.receiving.bid)
                            : "-",
                        },
                        {
                          label: "물동량",
                          value: receivingData.receiving.bid?.supply
                            ? `${
                                receivingData.receiving.bid.supply
                              } ${getSupplyType(
                                receivingData.receiving.bid.freightType
                              )}`
                            : "-",
                        },
                        {
                          label: "팔레트/박스/기타",
                          value: `${
                            receivingData.receiving.packingUnitPallet ?? "-"
                          }/${receivingData.receiving.packingUnitBox ?? "-"}/${
                            receivingData.receiving.packingUnitOther ?? "-"
                          }`,
                        },
                      ]
                }
              />
            </Stack>
            <Table
              headCells={headCells}
              rows={rows}
              sx={{
                // Table컴포넌트에서 자동으로 maxHeight를 계산하지만, 이 경우는 모달내부라 직접 설정
                maxHeight: "50vh",
              }}
              toolbarItems={{
                left: [
                  <Typography key="title" variant="h6">
                    의뢰내역
                  </Typography>,
                ],
                right: [
                  <Button
                    key="print-label"
                    variant="outlined"
                    startIcon={<Print />}
                    disabled={!selectedIdList.length}
                    onClick={handlePrintingProductLabelModalOpen}
                  >
                    라벨출력
                  </Button>,
                ],
              }}
              csvDownloadForSelectedRowsInfo={{
                metaInfo: [
                  ["의뢰번호", unifiedId],
                  [
                    "도착요청일",
                    toFormattedDate(
                      receivingData.receiving.expectedDate,
                      "YYYY-MM-DD"
                    ),
                  ],
                  [
                    "도착완료일",
                    toFormattedDate(
                      receivingData.receiving.receivedAt,
                      "YYYY-MM-DD"
                    ),
                  ],
                  [
                    "입고기한",
                    toFormattedDate(
                      receivingData.receiving.dueDate,
                      "YYYY-MM-DD"
                    ),
                  ],
                ],
                filename: `의뢰내역-${unifiedId}(${toFormattedDate(
                  new Date(),
                  "YYYY-MM-DD-HHmmss"
                )})`,
                idsToExceptForExcelDownload: ["checkbox"],
                rowsForCSVDownload,
              }}
            />
          </>
        );
      })()}
      {ModalOfPrintingProductLabel}
    </div>
  );
}

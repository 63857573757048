import { SvgIconComponent } from "headlessComponents/useSvgIcon";
import { ToolTipPropsV2 } from "headlessComponents/useToolTip";

import { COLOR } from "../../../styles/colors";

import InfoFilledIcon from "_sds-v2/components/svgIcons/InfoFilledIcon";

import Tooltip from "../../Tooltip";
import Styled from "./index.styles";

type StateBadgeType = "default" | "withIcon" | "withTooltip";

type StateBadgeSize = "normal" | "small";

type StateBadgeTooltipInfo = Pick<
  ToolTipPropsV2,
  "title" | "desc" | "position" | "width" | "tooltipBodyStyle"
>;

type StateBadgeProps = {
  size: StateBadgeSize;
  label: React.ReactNode;
  className?: string;
  /**
   * font, 아이콘 색상에 적용됨
   */
  color?: string;
} & (
  | { hasBorder?: false; borderColor?: never; backgroundColor?: string }
  | { hasBorder: true; borderColor?: string; backgroundColor?: string }
) &
  (
    | { type: "default" }
    | { type: "withIcon"; iconComponent: SvgIconComponent }
    | {
        type: "withTooltip";
        tooltipInfo: StateBadgeTooltipInfo;
      }
  );

export type {
  StateBadgeType,
  StateBadgeSize,
  StateBadgeTooltipInfo,
  StateBadgeProps,
};

export default function StateBadge({
  size,
  color = COLOR.grayScale_500,
  hasBorder = false,
  borderColor = COLOR.grayScale_500,
  backgroundColor = hasBorder ? COLOR.wh : COLOR.grayScale_100,
  className,
  label,
  ...propsByType
}: StateBadgeProps) {
  return (
    <Styled.container
      type={propsByType.type}
      size={size}
      className={`${className ? className : ""} state-badge`}
      color={color}
      backgroundColor={backgroundColor}
      hasBorder={hasBorder}
      borderColor={borderColor}
    >
      {propsByType.type === "withIcon" && (
        <propsByType.iconComponent
          width={12}
          height={12}
          color={color}
          className="icon"
        />
      )}

      <div className="label">{label}</div>

      {propsByType.type === "withTooltip" && propsByType.tooltipInfo && (
        <Tooltip
          title={propsByType.tooltipInfo.title}
          position={propsByType.tooltipInfo.position}
          desc={propsByType.tooltipInfo.desc}
          width={propsByType.tooltipInfo.width}
          tooltipBodyStyle={propsByType.tooltipInfo.tooltipBodyStyle}
        >
          <InfoFilledIcon
            className="tooltip-trigger-question-icon"
            width={12}
            height={12}
            color={color || COLOR.grayScale_500}
          />
        </Tooltip>
      )}
    </Styled.container>
  );
}

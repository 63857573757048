import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { ShippingDeliveryType } from "types/shipping";

const filterOptionList: TableHeadFilterOption<
  Extract<ShippingDeliveryType, "parcel" | "freight">
>[] = [
  { label: "일반택배", value: "parcel" },
  { label: "화물택배", value: "freight" },
];

export default function useDomesticParcelDeliveryTypeFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: DomesticParcelDeliveryTypeFilterPanel,
    filterList: domesticParcelDeliveryTypeFilterList,
    resetFilterState: resetDomesticParcelDeliveryTypeFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    DomesticParcelDeliveryTypeFilterPanel,
    domesticParcelDeliveryTypePayloadListByFilter:
      domesticParcelDeliveryTypeFilterList,
    resetDomesticParcelDeliveryTypeFilter,
  };
}

import { useCallback } from "react";
import { Button } from "@mui/material";

import { ReturningListFilterStatus, ReturningStatus } from "types/returning";
import { ShippingPacking } from "types/shipping";
import { RETURNING_LIST_FILTER_STATUS_DICT } from "utils/returning";

import { SubmitReturningInvoiceNoData } from "./useSubmitReturningInvoiceNo";

export default function UnitedStatus({
  returningId,
  shippingId,
  unitedStatus,
  originalParcelCompany,
  openFormToSubmitReturningInvoiceNo,
  openConfirmToRequestPickupUncollected,
  shippingPackings,
}: {
  returningId: number;
  shippingId?: number;
  status: ReturningStatus;
  unitedStatus: ReturningListFilterStatus | undefined;
  invoiceNo?: string;
  openFormToSubmitReturningInvoiceNo: (
    data: SubmitReturningInvoiceNoData
  ) => void;
  openConfirmToRequestPickupUncollected: ({
    returningId,
    shippingId,
  }: {
    returningId: number;
    shippingId?: number;
  }) => void;
  originalParcelCompany: string;
  shippingPackings?: ShippingPacking[];
}) {
  const handleSubmitInvoiceNoClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      const shippingInvoices =
        shippingPackings?.map((sp) => {
          return { originalParcelCompany, originalInvoiceNo: sp.invoiceNo };
        }) || [];

      openFormToSubmitReturningInvoiceNo({
        returningId,
        shippingInvoices,
      });
    },
    [
      openFormToSubmitReturningInvoiceNo,
      originalParcelCompany,
      returningId,
      shippingPackings,
    ]
  );

  const handleRequestPickUpUncollectedClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      openConfirmToRequestPickupUncollected({
        returningId,
        shippingId,
      });
    },
    [openConfirmToRequestPickupUncollected, returningId, shippingId]
  );

  if (unitedStatus === "emptyInvoice") {
    return (
      <Button
        variant="outlined"
        size="small"
        onClick={handleSubmitInvoiceNoClick}
      >
        {RETURNING_LIST_FILTER_STATUS_DICT[unitedStatus]}
      </Button>
    );
  }

  if (unitedStatus === "uncollected") {
    return (
      <div>
        <span>{RETURNING_LIST_FILTER_STATUS_DICT[unitedStatus]} </span>

        <Button
          variant="outlined"
          size="small"
          onClick={handleRequestPickUpUncollectedClick}
        >
          수거 재요청
        </Button>
      </div>
    );
  }

  return (
    <span>
      {unitedStatus ? RETURNING_LIST_FILTER_STATUS_DICT[unitedStatus] : ""}
    </span>
  );
}

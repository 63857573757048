import { useMemo, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "states/auth";
import { BofulManager } from "types/auth";
import { AuthorityLabelDict } from "utils/fulfillment";
import Modal from "components/Modal";

import useChangePassword from "./useChangePassword";

export default function useManagerDetail() {
  const [manager, setManager] = useState<BofulManager>();

  const { showChangePasswordModal, ChangePasswordModal } = useChangePassword({
    managerId: manager?.id,
  });

  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const ManagerDetailModal = useMemo(() => {
    if (!manager) return null;

    const canEditPassword = currentUser?.authority === "admin";

    return (
      <>
        <Modal
          isOpened
          handleClose={() => setManager(undefined)}
          modalBody={
            <>
              <Typography variant="h5" component="div">
                상세 정보
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={5} color={blue[800]}>
                  ID
                </Grid>
                <Grid item xs={7}>
                  {manager.accountId || "-"}
                </Grid>

                <Grid item xs={5} color={blue[800]}>
                  이름
                </Grid>
                <Grid item xs={7}>
                  {manager.name || "-"}
                </Grid>

                <Grid item xs={5} color={blue[800]}>
                  권한
                </Grid>
                <Grid item xs={7}>
                  {AuthorityLabelDict[manager.authority]}
                </Grid>

                <Grid item xs={5} color={blue[800]}>
                  비밀번호
                </Grid>
                <Grid item xs={7}>
                  {"*********"}{" "}
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      showChangePasswordModal();
                    }}
                    disabled={!canEditPassword}
                    sx={{ marginLeft: 1 }}
                  >
                    비밀번호 변경
                  </Button>
                </Grid>
              </Grid>
            </>
          }
        />

        {ChangePasswordModal}
      </>
    );
  }, [manager, currentUser, ChangePasswordModal, showChangePasswordModal]);

  return {
    setManagerDetailModalData: setManager,
    ManagerDetailModal,
  };
}

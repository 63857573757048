import { Button, Grid, Paper, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "states/auth";
import { AuthorityLabelDict } from "utils/fulfillment";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import useChangePassword from "./useChangePassword";

function MyPage() {
  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const { changePassword, ModalForChangePassword } = useChangePassword();

  return (
    <>
      <Layout>
        {currentUser && (
          <Paper
            sx={{
              maxWidth: "700px",
              padding: "20px",
            }}
            elevation={2}
          >
            <Typography variant="h6" component="div">
              내 정보
            </Typography>

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={4} color={blue[900]}>
                이름
              </Grid>
              <Grid item xs={8}>
                {currentUser.name}
              </Grid>

              <Grid item xs={4} color={blue[900]}>
                ID (accountId)
              </Grid>
              <Grid item xs={8}>
                {currentUser.accountId}
              </Grid>

              <Grid item xs={4} color={blue[900]}>
                비밀번호
              </Grid>
              <Grid item xs={8}>
                {"**********"}{" "}
                <Button
                  variant="contained"
                  size="small"
                  sx={{ marginLeft: 1 }}
                  onClick={changePassword}
                >
                  비밀번호 변경
                </Button>
              </Grid>

              <Grid item xs={4} color={blue[900]}>
                권한
              </Grid>
              <Grid item xs={8}>
                {AuthorityLabelDict[currentUser.authority]} (
                {currentUser.authority})
              </Grid>

              <Grid item xs={4} color={blue[900]}>
                warehouseId
              </Grid>
              <Grid item xs={8}>
                {currentUser.warehouseId || "-"}
              </Grid>

              <Grid item xs={4} color={blue[900]}>
                email
              </Grid>
              <Grid item xs={8}>
                {currentUser.email || "-"}
              </Grid>
            </Grid>
          </Paper>
        )}
      </Layout>

      {ModalForChangePassword}
    </>
  );
}

export default withRequireAuth(MyPage);

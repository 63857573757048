import { useCallback, useMemo } from "react";
import { Button } from "@mui/material";

import useSkuImageModal from "hooks/useSkuImageModal";
import { ReturningListItem } from "types/returning";
import { ReturningSKUItem } from "types/sku";
import { mergeItemsBySkuId } from "utils/fulfillment";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

type CellId =
  | "skuId"
  | "itemName"
  | "barCode"
  | "shippingQuantity"
  | "returningShippingQuantity"
  | "image";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 200,
  },
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 100,
  },
  {
    id: "barCode",
    disablePadding: false,
    label: "상품 바코드",
    width: 100,
  },
  {
    id: "shippingQuantity",
    disablePadding: false,
    label: "출고수량(PCS)",
    width: 150,
  },
  {
    id: "returningShippingQuantity",
    disablePadding: false,
    label: "반품요청수량(PCS)",
    width: 170,
  },
  {
    id: "image",
    disablePadding: false,
    label: "이미지",
    width: 80,
  },
];

export default function ReturningShippingDetailList({
  returningDetailData,
}: {
  returningDetailData: ReturningListItem | undefined;
}) {
  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();

  const handleItemImageSelect = useCallback(
    (skuData: ReturningSKUItem) => () => {
      handleSkuForImageModalSelect({
        skuData: { ...skuData, type: "single" },
        imageType: "item",
      });
    },
    [handleSkuForImageModalSelect]
  );

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!returningDetailData) {
      return [];
    }

    return mergeItemsBySkuId(returningDetailData.shipping?.items ?? []).map(
      (shippingItem) => {
        const targetReturningItemQty = returningDetailData.items
          .filter((returningItem) => returningItem.skuId === shippingItem.skuId)
          .reduce((prev, curr) => prev + curr.quantity, 0);

        return {
          itemName: shippingItem?.sku?.itemName ?? "-",
          skuId: getFormattedSingleSkuId(shippingItem.skuId),
          barCode: shippingItem.sku?.barCode ?? "-",
          shippingQuantity: toThousandUnitFormat(shippingItem.quantity),
          returningShippingQuantity: toThousandUnitFormat(
            targetReturningItemQty
          ),
          image: (
            <Button
              variant="outlined"
              size="small"
              onClick={handleItemImageSelect(shippingItem.sku)}
            >
              보기
            </Button>
          ),
        };
      }
    );
  }, [handleItemImageSelect, returningDetailData]);

  return (
    <>
      <Table<CellId> title="반품출고정보" headCells={headCells} rows={rows} />

      <SkuImageModal />
    </>
  );
}

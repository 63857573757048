import React from "react";

import Icon from "components/sds-v1/Icon";
import { ExcelColumnToolTipProps } from "headlessComponents/excel/useExcelColumnToolTip";
import { COLOR } from "styles/constants";
import ToolTip from "components/sds-v1/ToolTip";

export default function ExcelColumnToolTip({
  desc,
  bodyWidth,
  position,
}: ExcelColumnToolTipProps) {
  return (
    <ToolTip
      displayType="block"
      contentForDesktop={{
        position: position || "bottomRight",
        content: {
          type: "descOnly",
          desc: desc,
        },
        bodyWidth: bodyWidth || 9,
      }}
      contentForMobile={{
        isSameToDesktop: true,
      }}
    >
      <Icon type="circleStrokeQuestion" color={COLOR.grayScale_800} size={1} />
    </ToolTip>
  );
}

import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";
import { FulfillmentParcelCompany } from "types/fulfillment";

const filterOptionList: TableHeadFilterOption<FulfillmentParcelCompany>[] = [
  {
    label: "CJ대한통운",
    value: "cj",
  },
  {
    label: "한진택배",
    value: "hanjin",
  },
  {
    label: "대신택배",
    value: "daesin",
  },
  {
    label: "건영택배",
    value: "kunyoung",
  },
  {
    label: "천일택배",
    value: "chunil",
  },
  {
    label: "경동택배",
    value: "kd",
  },
  {
    label: "기타",
    value: "etc",
  },
];

export default function useDomesticParcelCompanyFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: DomesticParcelCompanyFilterPanel,
    filterList: domesticParcelCompanyFilterList,
    resetFilterState: resetDomesticParcelCompanyFilter,
  } = useTableHeadFilter({ filterOptionList, resetCurrentPage });

  return {
    DomesticParcelCompanyFilterPanel,
    domesticParcelCompanyPayloadListByFilter: domesticParcelCompanyFilterList,
    resetDomesticParcelCompanyFilter,
  };
}

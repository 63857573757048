import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import RETURNING_QUERY, {
  RETURNING_QUERY_KEY_GEN,
} from "queries/RETURNING_QUERY";

export default function useRequestPickupUncollected() {
  const [data, setData] = useState<{
    returningId: number;
    shippingId?: number;
  }>();

  const openConfirmToRequestPickupUncollected = useCallback(
    ({
      returningId,
      shippingId,
    }: {
      returningId: number;
      shippingId?: number;
    }) => {
      if (!returningId || !shippingId) return;

      setData({ returningId, shippingId });
    },
    []
  );

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      RETURNING_QUERY_KEY_GEN.getAdminReturningList({})
    );
    data &&
      queryClient.invalidateQueries(
        RETURNING_QUERY_KEY_GEN.getAdminReturningDetail({
          returningId: data.returningId,
        })
      );
    setData(undefined);
  }, [queryClient, data]);

  const {
    mutate: requestForPickupUncollected,
    ResponseHandler: ResponseHandlerOfRequestForPickupUncollected,
  } = RETURNING_QUERY.useRequestForPickupUncollected({
    onSuccess: handleSuccess,
  });

  const handleConfirm = useCallback(() => {
    if (!data) return;

    requestForPickupUncollected({
      pathParams: { returningId: data.returningId },
    });
  }, [data, requestForPickupUncollected]);

  const handleCancel = useCallback(() => {
    setData(undefined);
  }, []);

  const ConfirmToRequestPickupUncollected = useMemo(() => {
    if (!data) return null;

    return (
      <>
        <Dialog open onClose={() => setData(undefined)}>
          <DialogTitle>출고요청번호 {data.shippingId}</DialogTitle>

          <DialogContent>반품 수거를 재요청하시겠습니까?</DialogContent>

          <DialogActions>
            <Button color="inherit" onClick={handleCancel}>
              아니오
            </Button>
            <Button onClick={handleConfirm}>예</Button>
          </DialogActions>
        </Dialog>

        {ResponseHandlerOfRequestForPickupUncollected}
      </>
    );
  }, [
    data,
    handleCancel,
    handleConfirm,
    ResponseHandlerOfRequestForPickupUncollected,
  ]);

  return {
    openConfirmToRequestPickupUncollected,
    ConfirmToRequestPickupUncollected,
  };
}

import { useMemo, useState } from "react";
import { VALIDATE_KYOUNGDONG_SHIPPING_EXCEL_INVOICE_LIST_RES } from "api-interfaces/shipping";
import { TableDataListItem } from "headlessComponents/table/useTable";
import { ToggleFilterType } from "headlessComponents/useToggleFilter";

import useClientSidePaging from "hooks/useClientSidePaging";
import useExcelTableList, {
  KyoungDongShippingInvoiceExcelTableItem,
} from "hooks/useExcelTableList";
import { KyoungDongShippingInvoiceExcelItem } from "types/shipping";

import ExcelListHeader from "components/sds-v1/excel/ExcelListHeader";
import Paging from "components/sds-v1/Paging";
import Table from "components/sds-v1/table/Table";

import Styled from "./index.styles";

const columnInfo = {
  id: {
    label: "NO.",
    portion: 0.5,
  },
  result: {
    label: "등록결과",
  },
  customerPostalCode: {
    label: "우편번호",
  },
  customerName: {
    label: "받는분",
  },
  customerPhone: {
    label: "전화번호",
    fixedWidth: 100,
  },
  customerAddress: {
    label: "주소",
    fixedWidth: 300,
  },
  customerDetailAddress: {
    label: "상세주소",
    fixedWidth: 150,
  },
  itemName: {
    label: "품목명",
    fixedWidth: 150,
  },
  quantity: {
    label: "수량",
  },
  packingType: {
    label: "포장상태",
  },
  memo1: {
    label: "요청사항",
    fixedWidth: 200,
  },
  memo2: {
    label: "고객사명",
    fixedWidth: 150,
  },
  memo3: {
    label: "운송장번호",
    fixedWidth: 150,
  },
  memo15: {
    label: "출고요청번호",
    fixedWidth: 110,
  },
};

const cellOrderList = Object.keys(columnInfo);

export default function ExcelInvoiceList({
  excelList,
  successExcelList,
  failureExcelList,
}: {
  excelList: VALIDATE_KYOUNGDONG_SHIPPING_EXCEL_INVOICE_LIST_RES | undefined;
  successExcelList: KyoungDongShippingInvoiceExcelItem[] | undefined;
  failureExcelList: KyoungDongShippingInvoiceExcelItem[] | undefined;
}) {
  const [selectedToggleFilter, setSelectedToggleFilter] =
    useState<ToggleFilterType>("none");

  const { excelTableList, successExcelTableList, failureExcelTableList } =
    useExcelTableList<KyoungDongShippingInvoiceExcelItem>({
      excelList,
      successExcelList,
      failureExcelList,
      shouldShowDetailAddress: true,
      cellOrderList,
    });

  const visibleExcelTableList = useMemo(() => {
    if (selectedToggleFilter === "success") {
      return successExcelTableList;
    }

    if (selectedToggleFilter === "failure") {
      return failureExcelTableList;
    }

    return excelTableList;
  }, [
    selectedToggleFilter,
    excelTableList,
    successExcelTableList,
    failureExcelTableList,
  ]);

  const paging = useClientSidePaging(10, visibleExcelTableList);

  return (
    <Styled.excelListContainer>
      <ExcelListHeader<
        TableDataListItem<KyoungDongShippingInvoiceExcelTableItem>
      >
        selectedToggleFilter={selectedToggleFilter}
        setSelectedToggleFilter={setSelectedToggleFilter}
        countOfSuccessItems={successExcelList?.length ?? 0}
        countOfFailureItems={failureExcelList?.length ?? 0}
      />

      <Paging
        currentPage={paging.pageNum}
        pageSize={paging.pageSize}
        currentPageData={
          <Table
            rowMinHeight="77px"
            columnInfo={columnInfo}
            dataList={paging.pageData}
            disabledRowHoverBgColor={true}
          />
        }
        handleClickPage={paging.setPageNum}
      />
    </Styled.excelListContainer>
  );
}

import { useCallback, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import usePrevious from "hooks/usePrevious";

import Calendar, { CalendarInputValue } from "components/Calendar";

export type SearchWithDateTypeOption<T extends string | number> = {
  label: string;
  value: T;
};

/**
 * 일단은 '날짜 검색' 전용
 * - 모노리포에서 useSearchWithDate라는 hook이 2개가 있어 불가피하게 네이밍을 길게 수정
 */
export default function useSearchWithDateForBofulAdminOnly<
  T extends string | number
>({
  dateSearchTypeOptions,
  resetCurrentPage,
  startLabel,
  endLabel,
}: {
  dateSearchTypeOptions: SearchWithDateTypeOption<T>[];
  resetCurrentPage?: () => void;
  startLabel?: string;
  endLabel?: string;
}) {
  const [dateSearchType, setDateSearchType] = useState<T>(
    dateSearchTypeOptions[0].value
  );
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);

  // endDate를 선택하지 않고 캘린더를 닫는 경우 이전 값으로 되돌리기 위한 flag
  const isEndDateChanged = useRef(false);

  // 실제 검색에 사용되는 date (endDate까지 선택해야 검색이 되도록 하기 위함)
  const dateToSearch = useRef<{ startDate: Date; endDate: Date }>();

  const previousDate = usePrevious({ startDate, endDate });

  const handleDateSearchTypeChange = useCallback((e: SelectChangeEvent<T>) => {
    setDateSearchType(e.target.value as T);
  }, []);

  const handleStartDateChange = useCallback(
    (selectedDate: CalendarInputValue) => {
      setStartDate(selectedDate);
      setEndDate(null);

      isEndDateChanged.current = false;

      setIsOpenEndDate(true);
    },
    []
  );

  const handleEndDateChange = useCallback(
    (selectedDate: CalendarInputValue) => {
      if (!startDate || !selectedDate) {
        return;
      }

      setEndDate(selectedDate);
      dateToSearch.current = {
        startDate: new Date(startDate),
        endDate: new Date(selectedDate),
      };

      isEndDateChanged.current = true;

      resetCurrentPage && resetCurrentPage();
    },
    [resetCurrentPage, startDate]
  );

  const handleEndDateClose = useCallback(() => {
    // 종료일을 선택하지 않고 캘린더를 닫은 경우 이전 값으로 되돌리기
    if (!isEndDateChanged.current) {
      setStartDate(previousDate?.startDate || null);
      setEndDate(previousDate?.endDate || null);
    }

    setIsOpenEndDate(false);
  }, [previousDate]);

  const handleSearchDateReset = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    dateToSearch.current = undefined;

    resetCurrentPage && resetCurrentPage();
  }, [resetCurrentPage]);

  const SearchDate = useMemo(
    () => (
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Calendar
          sx={{ width: 120 }}
          type="datePicker"
          size="small"
          label={startLabel || "시작일"}
          value={startDate}
          onChange={handleStartDateChange}
        />

        <Box>~</Box>

        <Calendar
          sx={{ width: 120 }}
          when="end"
          type="datePicker"
          size="small"
          label={endLabel || "종료일"}
          minDate={startDate}
          value={endDate}
          disabled={!startDate}
          isOpen={isOpenEndDate}
          onChange={handleEndDateChange}
          onClose={handleEndDateClose}
        />

        <Button variant="outlined" onClick={handleSearchDateReset}>
          초기화
        </Button>
      </Stack>
    ),
    [
      endDate,
      endLabel,
      handleSearchDateReset,
      handleEndDateChange,
      handleEndDateClose,
      handleStartDateChange,
      isOpenEndDate,
      startDate,
      startLabel,
    ]
  );

  const SelectForSearchType = useMemo(
    () => (
      <FormControl
        className="date-search-type"
        size="small"
        sx={{ width: 150 }}
      >
        <InputLabel id="date-search-type-select-label">날짜타입</InputLabel>
        <Select
          labelId="date-search-type-select-label"
          id="date-search-type-select"
          value={dateSearchType}
          label="날짜타입"
          defaultValue={dateSearchType}
          onChange={handleDateSearchTypeChange}
        >
          {dateSearchTypeOptions.map((st, i) => (
            <MenuItem value={st.value} key={i}>
              {st.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [dateSearchType, dateSearchTypeOptions, handleDateSearchTypeChange]
  );

  const DateSearchPanel = useMemo(
    () => (
      <Paper variant="outlined" sx={{ p: 1, bgcolor: grey[50] }}>
        <Stack flexDirection="row" gap={1} alignItems="center">
          {SelectForSearchType}

          {SearchDate}
        </Stack>
      </Paper>
    ),
    [SelectForSearchType, SearchDate]
  );

  return {
    DateSearchPanel,
    dateSearchType,
    dateToSearch: dateToSearch.current,
  };
}

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { GET_MATERIAL_LIST_FOR_TOTAL_PICKING_RES } from "api-interfaces/material";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";

export default function useUpdatePackingModal() {
  const [invoiceNo, setInvoiceNo] = useState<string>("");
  const [shippingId, setShippingId] = useState<number>(0);
  const [outerPackagesId, setMaterialId] = useState<number | null>(null);
  const [materialListForTotalPicking, setMaterialList] =
    useState<GET_MATERIAL_LIST_FOR_TOTAL_PICKING_RES>();

  const queryClient = useQueryClient();

  const {
    mutateAsync: updatePacking,
    ResponseHandler: ResponseHandlerUpdatePacking,
  } = SHIPPING_QUERY.useUpdatePacking();

  const materialList = (materialListForTotalPicking ?? []).map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const selectedMaterial =
    materialList.find((option) => option.value === outerPackagesId) ?? null;

  const handleMaterialChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: { label: string; value: number } | null
  ) => {
    setMaterialId(value?.value ?? null);
  };

  const showUpdatePackingModal = (
    invoiceNo: string,
    shippingId: number,
    materialListForTotalPicking:
      | GET_MATERIAL_LIST_FOR_TOTAL_PICKING_RES
      | undefined
  ) => {
    setInvoiceNo(invoiceNo);
    setShippingId(shippingId);
    setMaterialList(materialListForTotalPicking);
  };

  const showUpdatePackingModalClose = () => {
    setInvoiceNo("");
    setShippingId(0);
    setMaterialList([]);
  };

  const UpdatePackingModal = useMemo(() => {
    const handleUpdatePacking = async () => {
      await updatePacking({
        invoiceNo,
        outerPackagesId,
      });
      queryClient.invalidateQueries(
        SHIPPING_QUERY_KEY_GEN.getAdminDetail({ shippingId })
      );
      showUpdatePackingModalClose();
    };

    const handleUpdatePackingOff = () => {
      showUpdatePackingModalClose();
    };

    return (
      <>
        <Dialog open={!!invoiceNo} onClose={showUpdatePackingModalClose}>
          <DialogTitle>
            운송장번호 {invoiceNo}의 외포장재를 수정합니다.
          </DialogTitle>

          <DialogContent>
            <Autocomplete
              sx={{ mt: 1 }}
              options={materialList}
              renderInput={(params) => <TextField {...params} label="포장재" />}
              value={selectedMaterial}
              onChange={handleMaterialChange}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              onClick={handleUpdatePacking}
              disabled={!invoiceNo}
            >
              요청
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdatePackingOff}
              disabled={!invoiceNo}
            >
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {ResponseHandlerUpdatePacking}
      </>
    );
  }, [
    ResponseHandlerUpdatePacking,
    invoiceNo,
    outerPackagesId,
    materialList,
    queryClient,
    selectedMaterial,
    shippingId,
    updatePacking,
  ]);

  return { showUpdatePackingModal, UpdatePackingModal };
}

import { useCallback, useMemo } from "react";
import { Button } from "@mui/material";

import useSkuImageModal from "hooks/useSkuImageModal";
import { ClassifiedGroupItemSku } from "types/common";
import { ShippingItem, ShippingItemDetail } from "types/shipping";
import { ShippingSKUItem } from "types/sku";
import { getFormattedSkuId } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import { classifyShippingItemsV2 } from "utils/shipping";

import Table, { TableBodyRow, TableHeadCell } from "components/Table";

type CellId =
  | Extract<keyof ShippingItem, "skuId">
  | Extract<keyof ShippingSKUItem, "itemName" | "barCode">
  | "orderQuantity"
  | "image";

const headCells: TableHeadCell<CellId>[] = [
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 200,
  },
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 100,
  },
  {
    id: "barCode",
    disablePadding: false,
    label: "상품 바코드",
    width: 100,
  },
  {
    id: "orderQuantity",
    disablePadding: false,
    label: "주문수량(ORDER)",
    width: 120,
  },
  {
    id: "image",
    disablePadding: false,
    label: "이미지",
    width: 50,
  },
];

export default function OrderDetailList({
  shippingDetailData,
}: {
  shippingDetailData: ShippingItemDetail | undefined;
}) {
  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();

  const handleItemImageSelect = useCallback(
    ({
        skuData,
        type,
      }: {
        skuData: ShippingSKUItem | ClassifiedGroupItemSku;
        type: "group" | "single";
      }) =>
      () => {
        if (type === "single") {
          handleSkuForImageModalSelect({
            skuData: { ...skuData, type: "single" },
            imageType: "item",
          });
        }

        if (type === "group") {
          const { itemName, ...groupSkuData } = skuData;

          handleSkuForImageModalSelect({
            skuData: { ...groupSkuData, type: "group", groupName: itemName },
            imageType: "item",
          });
        }
      },
    [handleSkuForImageModalSelect]
  );

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!shippingDetailData) {
      return [];
    }

    const classifiedShippingItems = classifyShippingItemsV2({
      items: shippingDetailData.items,
      groupList: shippingDetailData.groupList,
    });

    return classifiedShippingItems.map((shippingItem) => ({
      itemName: shippingItem.sku.itemName ?? "-",
      skuId: getFormattedSkuId(shippingItem),
      barCode: shippingItem.sku.barCode ?? "-",
      orderQuantity: toThousandUnitFormat(shippingItem.quantity),
      image: (
        <Button
          variant="outlined"
          size="small"
          onClick={handleItemImageSelect({
            skuData: shippingItem.sku,
            type: shippingItem.isGroup ? "group" : "single",
          })}
        >
          보기
        </Button>
      ),
    }));
  }, [handleItemImageSelect, shippingDetailData]);

  return (
    <>
      <Table<CellId> title="주문정보" headCells={headCells} rows={rows} />

      <SkuImageModal />
    </>
  );
}

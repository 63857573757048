import Barcode from "components/sds-v1/Barcode";
import { TransactionStatementData } from "types/shipping";

import Styled from "./index.styles";

export default function TransactionStatement({
  data: {
    invoiceNo,
    customerName,
    customerAddress,
    customerPhone,
    orderNo,
    senderPhone,
    teamBRN,
    teamRepresentativeName,
    teamCompany,
    teamAddress,
    items,
    requestMemo,
  },
}: {
  data: TransactionStatementData;
}) {
  return (
    <>
      <Styled.container>
        <thead>
          <tr>
            <th>주문번호: {orderNo}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div className="header">
                <div className="left">
                  <div className="title">거래명세서</div>

                  <div className="customer-info">
                    <div className="customer-name">{customerName}</div>
                    <div className="customer-address">{customerAddress}</div>
                    <div className="customer-phone">☎ {customerPhone}</div>
                  </div>
                </div>

                <div className="right">
                  <div className="code-container">
                    <div className="code">코드: {invoiceNo}</div>
                    <Barcode
                      value={invoiceNo}
                      options={{
                        displayValue: false,
                        margin: 0,
                      }}
                    />
                  </div>

                  <table className="supplier">
                    <tbody>
                      <tr>
                        <td rowSpan={4}>
                          공<br />급<br />자
                        </td>
                        <td>주문번호</td>
                        <td>{orderNo}</td>
                        <td>TEL</td>
                        <td>{senderPhone}</td>
                      </tr>
                      <tr>
                        <td>
                          사업자등록
                          <br />
                          번호
                        </td>
                        <td>{teamBRN}</td>
                        <td>성명</td>
                        <td>{teamRepresentativeName}</td>
                      </tr>
                      <tr>
                        <td>상호</td>
                        <td colSpan={3}>{teamCompany}</td>
                      </tr>
                      <tr>
                        <td>주소</td>
                        <td colSpan={3}>{teamAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <table className="list">
                <thead>
                  <tr>
                    <th className="print-date">월/일</th>
                    <th className="product-name">품명 및 규격</th>
                    <th className="quantity">수량</th>
                    <th className="note">비고</th>
                  </tr>
                </thead>

                <tbody>
                  {items.map((item, idx) => (
                    <tr key={item.productName + idx}>
                      <td className="print-date">{item.printDate}</td>
                      <td>{item.productName}</td>
                      <td className="quantity">{item.quantity}EA</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="footer">
                <table className="summary">
                  <tbody>
                    <tr>
                      <td>수량</td>
                      <td>
                        {items.reduce((acc, curr) => acc + curr.quantity, 0)}
                      </td>
                      <td>인수</td>
                      <td>(인)</td>
                    </tr>
                  </tbody>
                </table>

                <table className="customer-info">
                  <tbody>
                    <tr>
                      <td>수신 연락처</td>
                      <td>{customerPhone}</td>
                      <td>배송지 주소</td>
                      <td>{customerAddress}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="request-memo">
                  <tbody>
                    <tr>
                      <td>요청사항</td>
                      <td>{requestMemo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </Styled.container>

      <Styled.pageBreakWrapper>&nbsp;</Styled.pageBreakWrapper>
    </>
  );
}

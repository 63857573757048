import { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import SKU_QUERY from "queries/SKU_QUERY";

import { SKUDetail } from "types/sku";

export default function useSkuSearchModal(
  onSkuIdSelected: (skuDetail: SKUDetail) => void
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [skuId, setSkuId] = useState<number>();

  const showSkuSearchModal = () => {
    setIsOpen(true);
    setSkuId(undefined);
  };

  const handleSkuSearchModalClose = () => {
    setSkuId(undefined);
    setIsOpen(false);
  };

  const {
    data: singleSkuDetail,
    ResponseHandler: ResponseHandlerOfGetSingleSkuDetail,
  } = SKU_QUERY.useGetSingleSkuDetail({
    skuId,
    enabled: !!skuId && skuId.toString().length === 7,
  });

  const SkuSearchModal = useMemo(() => {
    const handleSkuIdSelected = () => {
      singleSkuDetail && onSkuIdSelected && onSkuIdSelected(singleSkuDetail);
      setSkuId(undefined);
      setIsOpen(false);
    };

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={handleSkuSearchModalClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>단일상품을 추가합니다</DialogTitle>

          <Stack gap={"10px"}>
            <TextField
              label={"skuId"}
              size="small"
              type="number"
              value={Number(skuId).toString()}
              onChange={(e) => {
                setSkuId(Number(e.target.value));
              }}
            />

            {singleSkuDetail && (
              <TextField
                label={"검색된 상품"}
                size="small"
                value={singleSkuDetail.sku.itemName}
                disabled={true}
              />
            )}
          </Stack>

          <DialogActions>
            <Button
              variant="contained"
              onClick={handleSkuIdSelected}
              disabled={!singleSkuDetail}
            >
              추가
            </Button>
          </DialogActions>
        </Dialog>
        {ResponseHandlerOfGetSingleSkuDetail}
      </>
    );
  }, [
    isOpen,
    skuId,
    singleSkuDetail,
    ResponseHandlerOfGetSingleSkuDetail,
    onSkuIdSelected,
  ]);

  return { showSkuSearchModal, SkuSearchModal };
}

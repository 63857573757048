import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import SKU_QUERY, { SKU_QUERY_KEY_GEN } from "queries/SKU_QUERY";

import useMultiSelect from "hooks/useMultiSelect";
import useSearchWithTerm, {
  TermSearchType,
} from "hooks/useSearchWithTermForBufulAdminOnly";
import useSkuImageModal, {
  SkuImageModalImageType,
} from "hooks/useSkuImageModal";
import useHeteromorphicFilter from "pages/product/list/useHeteromorphicFilter";
import useMaterialPackageTypeFilter from "pages/product/list/useMaterialPackageTypeFilter";
import { sendRequest } from "services/request";
import { CommonSKU } from "types/sku";
import { getGroupedSearchListByProperty } from "utils/common";
import {
  getFormattedGroupSkuId,
  getMaterialPackageType,
  getPayloadByFilter,
} from "utils/fulfillment";

import MemoizedTable, {
  TableBodyRow,
  TableHeadCell,
  TableTabInfo,
} from "components/MemoizedTable";

import useGroupDetailModal from "./useGroupDetailModal";

interface GroupProductTableProps {
  tableTabInfo: TableTabInfo;
}

type CellIdForGroupProduct =
  | "companyName"
  | "teamName"
  | Extract<
      keyof CommonSKU,
      | "itemName"
      | "productCode"
      | "managementCode"
      | "materialPackageType"
      | "isHeteromorphic"
    >
  | "checkbox"
  | "skuId";

const termSearchTypeOptionsForGroupProduct: TermSearchType<
  "id" | "company" | "groupName" | "productCode" | "managementCode"
>[] = [
  {
    label: "SKU ID",
    value: "id",
  },
  {
    label: "회사명",
    value: "company",
  },
  {
    label: "상품명",
    value: "groupName",
  },
  {
    label: "판매자상품코드",
    value: "productCode",
  },
  {
    label: "옵션관리코드",
    value: "managementCode",
  },
];

type SelectedSkuType = {
  skuId: number;
  type: "image";
  imageType: SkuImageModalImageType;
};

function GroupProductTable({ tableTabInfo }: GroupProductTableProps) {
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedSku, setSelectedSku] = useState<SelectedSkuType>();

  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  const queryClient = useQueryClient();

  const resetCurrentPage = useCallback(() => setCurrentPage(0), []);

  const { TermSearchPanel: TermSearchPanelForGroupProduct, searchList } =
    useSearchWithTerm({
      pageType: "groupSku",
      termSearchTypeOptions: termSearchTypeOptionsForGroupProduct,
      resetCurrentPage,
    });

  const { handleSkuForImageModalSelect, SkuImageModal } = useSkuImageModal();
  const { showGroupDetailModal, GroupDetailModal } = useGroupDetailModal();

  const {
    MaterialPackageTypeFilterPanel,
    materialPackageTypePayloadListByFilter,
  } = useMaterialPackageTypeFilter({ resetCurrentPage });

  const { HeteromorphicFilterPanel, heteromorphicPayloadListByFilter } =
    useHeteromorphicFilter({ resetCurrentPage });

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
    resetCurrentPage();
  };

  const {
    data: groupProductList,
    refetch: refetchGroupProductList,
    ResponseHandler: ResponseHandlerOfGroupProductList,
  } = SKU_QUERY.useGetGroupSkuListV2({
    page: currentPage,
    perPage: perPage,

    ...getGroupedSearchListByProperty({ pageType: "groupSku", searchList }),

    ...getPayloadByFilter({
      key: "materialPackageType",
      payloadList: materialPackageTypePayloadListByFilter,
    }),
    ...getPayloadByFilter({
      key: "isHeteromorphic",
      payloadList: heteromorphicPayloadListByFilter,
    }),

    onSuccess: ({ list }) => {
      initSelectionDict(list.map(({ id }) => id));
    },
  });

  const {
    mutate: changeHeteromorphic,
    ResponseHandler: ResponseHandlerOfChangeHeteromorphic,
  } = SKU_QUERY.useChangeHeteromorphic({
    type: "group",
    onSuccess: () => {
      queryClient.invalidateQueries(SKU_QUERY_KEY_GEN.getGroupSkuListV2({}));
    },
  });

  const { ResponseHandler: ResponseHandlerOfGetGroupSkuDetail } =
    SKU_QUERY.useGetGroupSkuDetail({
      groupSkuId: selectedSku?.skuId ?? 0,
      enabled: !!selectedSku,
      onSuccess: (data) => {
        if (!data || !selectedSku) return;

        handleSkuForImageModalSelect({
          skuData: { ...data, type: "group" },
          imageType: selectedSku.imageType,
        });
        setSelectedSku(undefined);
      },
      onError: () => setSelectedSku(undefined),
    });

  const handleSkuImageModalOpen =
    ({
      skuId,
      imageType,
    }: {
      skuId: number;
      imageType: SkuImageModalImageType;
    }) =>
    () => {
      setSelectedSku({ type: "image", skuId, imageType });
    };

  const downloadGroupListExcel = () => {
    sendRequest({
      method: "POST",
      path: "/group/download",
      apiType: "BofulDefault",
      responseType: "blob",
      data: { productGroupIds: selectedIdList },
    }).then((response) => {
      const blobData = new Blob([response.data]);
      const filename = `상품관리_리스트_그룹상품 (${dayjs()
        .add(9, "hours")
        .format("YYYY-MM-DD-HHmmss")}).xlsx`;

      fileDownload(blobData, filename);
      return;
    });
  };

  const groupProductHeadCells: TableHeadCell<CellIdForGroupProduct>[] = useMemo(
    () => [
      {
        id: "skuId",
        label: "SKU ID",
        disablePadding: false,
        width: 120,
      },
      {
        id: "companyName",
        label: "회사명",
        disablePadding: false,
        width: 150,
      },
      {
        id: "teamName",
        label: "팀명",
        disablePadding: false,
        width: 150,
      },
      {
        id: "itemName",
        label: "상품명",
        disablePadding: false,
        width: 250,
      },
      {
        id: "productCode",
        label: "판매자상품코드",
        disablePadding: false,
        width: 120,
      },
      {
        id: "managementCode",
        label: "옵션관리코드",
        disablePadding: false,
        width: 120,
      },
      {
        id: "materialPackageType",
        label: "부자재",
        disablePadding: false,
        width: 180,
        filter: MaterialPackageTypeFilterPanel,
      },
      {
        id: "isHeteromorphic",
        label: "중량/이형화물",
        disablePadding: false,
        width: 130,
        filter: HeteromorphicFilterPanel,
      },
    ],
    [MaterialPackageTypeFilterPanel, HeteromorphicFilterPanel]
  );

  const createGroupProductRows = () => {
    if (!groupProductList) return [];

    const newGroupProductRows: TableBodyRow<CellIdForGroupProduct>[] =
      groupProductList.list.map((skuItem) => {
        const isNoneMaterialPackageType =
          skuItem.materialPackageType === "none";

        return {
          id: skuItem.id,

          skuId: getFormattedGroupSkuId(skuItem.id),
          companyName: skuItem.team?.company,
          teamName: skuItem.team?.name,
          itemName: (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" component="span">
                {skuItem.groupName}
              </Typography>
            </Stack>
          ),
          productCode: skuItem.productCode,
          managementCode: skuItem.managementCode,
          materialPackageType: (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>{getMaterialPackageType(skuItem.materialPackageType)}</span>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSkuImageModalOpen({
                    skuId: skuItem.id,
                    imageType: "package",
                  })();
                }}
                disabled={isNoneMaterialPackageType}
              >
                확인
              </Button>
            </Stack>
          ),
          isHeteromorphic: (
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                changeHeteromorphic({
                  pathParams: {
                    skuId: skuItem.id,
                  },
                  heteromorphic: !skuItem.isHeteromorphic,
                });
              }}
              checked={skuItem.isHeteromorphic}
            />
          ),
          handleRowClick: () => {
            showGroupDetailModal(skuItem.id);
          },
        };
      });

    return newGroupProductRows;
  };

  const groupProductRows: TableBodyRow<CellIdForGroupProduct>[] = useMemo(
    createGroupProductRows,
    [changeHeteromorphic, groupProductList]
  );

  return (
    <>
      <MemoizedTable
        title="상품관리"
        tableTabInfo={tableTabInfo}
        headCells={groupProductHeadCells}
        rows={groupProductRows}
        toolbarItems={{
          left: [TermSearchPanelForGroupProduct],
        }}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 250, 500, 1000],
          totalCount: groupProductList?.total ?? 0,
          perPage,
          setPerPage: handlePerPageChange,
          currentPage,
          setCurrentPage,
        }}
        csvDownloadForSelectedRowsInfo={{
          filename: "",
          rowsForCSVDownload: [],
          onXlsxDownloadButtonClicked: downloadGroupListExcel,
          xlsxDownloadButtonDisabled: selectedIdList.length === 0,
        }}
        selectionDict={selectionDict}
        select={select}
        unSelect={unSelect}
        selectAll={selectAll}
        unSelectAll={unSelectAll}
        selectedCount={selectedCount}
        isAllSelected={isAllSelected}
      />

      <SkuImageModal />
      {GroupDetailModal}

      {ResponseHandlerOfGroupProductList}
      {ResponseHandlerOfGetGroupSkuDetail}
      {ResponseHandlerOfChangeHeteromorphic}
    </>
  );
}

export default GroupProductTable;

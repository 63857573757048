import { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import SKU_QUERY from "queries/SKU_QUERY";

import { ProductGroup } from "types/sku";

export default function useGroupSkuSearchModal(
  onGroupSkuIdSelected: (productGroup: ProductGroup) => void
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [groupSkuId, setGroupSkuId] = useState<number>();

  const showGroupSkuSearchModal = () => {
    setIsOpen(true);
    setGroupSkuId(undefined);
  };

  const handleGroupSearchModalClose = () => {
    setGroupSkuId(undefined);
    setIsOpen(false);
  };

  const {
    data: groupSkuDetail,
    ResponseHandler: ResponseHandlerOfGetGroupSkuDetail,
  } = SKU_QUERY.useGetGroupSkuDetail({
    groupSkuId: groupSkuId || 0,
    enabled: !!groupSkuId && groupSkuId.toString().length === 7,
  });

  const GroupSkuSearchModal = useMemo(() => {
    const handleGroupSkuIdSelected = () => {
      groupSkuDetail && onGroupSkuIdSelected(groupSkuDetail);
      setGroupSkuId(undefined);
      setIsOpen(false);
    };

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={handleGroupSearchModalClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>그룹상품을 추가합니다</DialogTitle>

          <Stack gap={"10px"}>
            <TextField
              label={"skuId"}
              size="small"
              type="number"
              value={Number(groupSkuId).toString()}
              onChange={(e) => {
                setGroupSkuId(Number(e.target.value));
              }}
            />

            {groupSkuDetail && (
              <TextField
                label={"검색된 상품"}
                size="small"
                value={groupSkuDetail.groupName}
                disabled={true}
              />
            )}
          </Stack>

          <DialogActions>
            <Button
              variant="contained"
              onClick={handleGroupSkuIdSelected}
              disabled={!groupSkuDetail}
            >
              추가
            </Button>
          </DialogActions>
        </Dialog>
        {ResponseHandlerOfGetGroupSkuDetail}
      </>
    );
  }, [
    ResponseHandlerOfGetGroupSkuDetail,
    groupSkuDetail,
    groupSkuId,
    isOpen,
    onGroupSkuIdSelected,
  ]);

  return { showGroupSkuSearchModal, GroupSkuSearchModal };
}

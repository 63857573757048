import React from "react";

import Styled from "./index.styles";
import { IconType } from "components/sds-v1/Icon/DATA";
import { Link } from "@mui/material";
import Icon from "components/sds-v1/Icon";

export type TextButtonTheme = "default" | "withIcon" | "danger" | "gray";

export interface Props {
  theme: TextButtonTheme;
  size: string;
  label: React.ReactNode;
  url?: string;
  target?: string;
  disabled?: boolean;
  icon?: IconType;
  className?: string;
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function TextButton({
  theme,
  size,
  label,
  url,
  target,
  disabled,
  icon,
  className,
  handleClick,
}: Props) {
  return (
    <Styled.container
      className={`${className ? className : ""} text-button`}
      theme={theme}
      size={size}
      disabled={disabled}
      onClick={(e) => {
        if (handleClick && !disabled) {
          handleClick(e);
        }
      }}
    >
      {url ? (
        <Link href={url}>
          <a target={target}>
            <em className="label">{label}</em>
            {icon && <Icon type={icon} size={0.9} />}
          </a>
        </Link>
      ) : (
        <>
          <em className="label">{label}</em>
          {icon && <Icon type={icon} size={0.9} />}
        </>
      )}
    </Styled.container>
  );
}

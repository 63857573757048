import { Button } from "@mui/material";

import usePrintLocationLabelWithDialog from "../usePrintLocationLabelWithDialog";

export default function PrintLabel({
  locationId,
}: {
  locationId: number | undefined;
}) {
  const { handlePrintLocationLabelDialogOpen, PrintLocationLabelDialog } =
    usePrintLocationLabelWithDialog({
      locationIds: locationId ? [locationId] : [],
    });

  return (
    <>
      <Button variant="contained" onClick={handlePrintLocationLabelDialogOpen}>
        라벨 출력
      </Button>

      {PrintLocationLabelDialog}
    </>
  );
}

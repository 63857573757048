import { GET_ADMIN_SHIPPING_LIST_REQ } from "api-interfaces/shipping";
import Button from "components/sds-v1/button/Button";
import Modal from "components/sds-v1/Modal";

import ExcelInvoiceList from "./ExcelInvoiceList";
import useUploadKyoungDongShippingInvoiceExcel from "./useUploadKyoungDongShippingInvoiceExcel";
import Styled from "./index.styles";

export default function UploadKyoungDongShippingInvoiceExcelModal({
  selectedIdList,
  isOpen,
  onModalClose,
  ...params
}: {
  selectedIdList: number[];
  isOpen: boolean;
  onModalClose: () => void;
} & GET_ADMIN_SHIPPING_LIST_REQ) {
  const {
    handleExcelUpload,

    inputFileRef,
    handleFileInputChange,

    shippingExcelInvoiceList,
    successExcelList,
    failureExcelList,

    handleShippingInvoiceListSave,
    isValidated,
    handleModalClose,

    LoadingToUpload,
    FailureModal,

    downloadExcel,

    ResponseHandlerKyoungdongShippingFreightList,
    ResponseHandlerValidatingShippingExcelInvoiceList,
    ResponseHandlerOfSavingShippingExcelInvoiceList,
  } = useUploadKyoungDongShippingInvoiceExcel({
    selectedIdList,
    isModalOpen: isOpen,
    onModalClose,
    ...params,
  });

  return (
    <>
      <Modal
        active={isOpen}
        uiType="formInput"
        title="경동택배 대량업로드"
        desc={
          <>
            엑셀 업로드를 위해 엑셀 파일의 내용을 확인한 후 양식에 맞게 입력한
            후 업로드해 주세요.
            <br />
            에러가 없을 경우 등록 완료 버튼이 활성화됩니다.
            <br />
            <Styled.excelUploadButtonContainer>
              <Button
                label="양식 다운로드"
                theme="tertiary"
                size="normal"
                handleClick={downloadExcel}
              />

              <Button
                label="엑셀 업로드"
                theme="secondary"
                size="normal"
                handleClick={handleExcelUpload}
              />
            </Styled.excelUploadButtonContainer>
            <input
              style={{ display: "none" }}
              ref={inputFileRef}
              type="file"
              name="file-upload"
              id="fileUpload"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12"
              onChange={handleFileInputChange}
            />
          </>
        }
        body={
          <ExcelInvoiceList
            excelList={shippingExcelInvoiceList}
            successExcelList={successExcelList}
            failureExcelList={failureExcelList}
          />
        }
        hasBorder={true}
        submitButton={
          <Styled.excelUploadButtonContainer>
            <Button
              label="취소"
              theme="secondary"
              size="normal"
              handleClick={handleModalClose}
            />

            <Button
              label="등록 완료"
              theme="primary"
              size="normal"
              handleClick={handleShippingInvoiceListSave}
              disabled={!isValidated}
            />
          </Styled.excelUploadButtonContainer>
        }
        onClose={handleModalClose}
        disableMaxHeight={true}
      />

      {LoadingToUpload}
      {FailureModal}

      {ResponseHandlerKyoungdongShippingFreightList}
      {ResponseHandlerValidatingShippingExcelInvoiceList}
      {ResponseHandlerOfSavingShippingExcelInvoiceList}
    </>
  );
}

import { useMemo, useState } from "react";

import USER_QUERY from "queries/USER_QUERY";
import { AdminCustomer } from "types/user";
import { toFormattedDate } from "utils/date";
import { toThousandUnitFormat } from "utils/number";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import CustomerDetailModal from "./CustomerDetailModal";

type CellId = keyof AdminCustomer;

const headCellList: TableHeadCell<CellId>[] = [
  {
    id: "id",
    disablePadding: false,
    label: "고객 번호",
    width: 80,
  },
  {
    id: "company",
    disablePadding: false,
    label: "회사명",
    width: 120,
  },
  {
    id: "name",
    disablePadding: false,
    label: "이름",
    width: 120,
  },
  {
    id: "phone",
    disablePadding: false,
    label: "연락처",
    width: 120,
  },
  {
    id: "email",
    disablePadding: false,
    label: "이메일",
    width: 120,
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "가입일",
    width: 150,
  },
];

function CustomerList() {
  const [selectedUserId, setSelectedUserId] = useState<number>();

  const { data: customerList, ResponseHandler: ResponseHandlerOfTeamList } =
    USER_QUERY.useGetCustomerList();

  const rowList: TableBodyRow<CellId>[] = useMemo(() => {
    if (!customerList) return [];

    return customerList.map(
      ({ id, name, phone, email, company, createdAt }) => {
        const row: TableBodyRow<CellId> = {
          id,
          company,
          name,
          phone,
          email,
          createdAt: toFormattedDate(createdAt, `YYYY.MM.DD`),

          handleRowClick: () => setSelectedUserId(id),
        };

        return row;
      }
    );
  }, [customerList]);

  const handleCustomerDetailModalClose = () => setSelectedUserId(undefined);

  return (
    <Layout>
      <Table
        title={`고객 리스트 (총 ${toThousandUnitFormat(customerList?.length)})`}
        headCells={headCellList}
        rows={rowList}
      />

      <CustomerDetailModal
        key={selectedUserId}
        userId={selectedUserId}
        onClose={handleCustomerDetailModalClose}
      />

      {ResponseHandlerOfTeamList}
    </Layout>
  );
}

export default withRequireAuth(CustomerList);

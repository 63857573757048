import { Controller, useForm } from "react-hook-form";
import { Button, Paper, Stack, Typography } from "@mui/material";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

interface AddInvoiceForm {
  shippingId: string;
  requiredQty: string;
}

function InvoiceAdd() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<AddInvoiceForm>({
    mode: "onChange",
    defaultValues: {
      shippingId: "",
      requiredQty: "",
    },
  });

  const {
    mutate: addInvoiceNo,
    ResponseHandler: ResponseHandlerOfAddInvoiceNo,
  } = SHIPPING_QUERY.useAddInvoice({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        reset();
      },
      customizeMessage: (res) => ({
        messageType: "titleAndBody",
        title: "추가 송장 생성이 완료되었습니다.",
        body: (
          <>
            {(res ?? []).map((data) => (
              <Typography key={data.invoiceNo}>{data.invoiceNo}</Typography>
            ))}
          </>
        ),
      }),
    },
  });

  const handleInvoiceAdd = ({ shippingId, requiredQty }: AddInvoiceForm) => {
    addInvoiceNo({
      shippingId: Number(shippingId),
      requiredQty: Number(requiredQty),
    });
  };

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          추가 송장 생성
        </Typography>

        <form onSubmit={handleSubmit(handleInvoiceAdd)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="shippingId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="출고 요청 번호"
                  error={!!errors.shippingId}
                  helperText={errors.shippingId && "숫자만 입력해주세요."}
                />
              )}
            />
            <Controller
              control={control}
              name="requiredQty"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="송장 발행 매수"
                  error={!!errors.requiredQty}
                  helperText={errors.requiredQty && "숫자만 입력해주세요."}
                />
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              생성
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandlerOfAddInvoiceNo}
    </Layout>
  );
}

export default withRequireAuth(InvoiceAdd);

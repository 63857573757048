import { useMemo, useState } from "react";
import { useAtom } from "jotai";
import { FULFILLMENT_INSPECTION_ATOMS } from "jotaiStates/inspection";

import { COLOR } from "styles/constants";
import { UserReturningDetailItem } from "types/returning";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import { replaceEmptyToDash } from "utils/string";

import Icon from "components/sds-v1/Icon";
import Table from "components/sds-v1/table/Table";

import ProblemMemo from "../ProblemMemo";
import ProcessStatus from "../ProcessStatus";
import UnverifiedInfoModal from "./UnverifiedInfoModal";
import Styled from "./index.styles";

export type UnverifiedDetailInfo = UserReturningDetailItem & {
  order: number;
};

export default function UnverifiedList({
  teamId,
  primaryId,
  list,
  shippingId,
  isWarehousingStatus,
  isDecidedQuantityStatus,
}: {
  teamId: number;
  primaryId: string | number;
  list: UserReturningDetailItem[];
  shippingId: number;
  isWarehousingStatus: boolean;
  isDecidedQuantityStatus: boolean;
}) {
  const [agreementInfoList, setAgreementInfoList] = useAtom(
    FULFILLMENT_INSPECTION_ATOMS.AGREEMENT_INFO_LIST_FOR_INSPECTION
  );

  const [unverifiedDetailInfo, setUnverifiedDetailInfo] =
    useState<UnverifiedDetailInfo>();

  const openUnverifiedDetailModal = (
    item: UserReturningDetailItem,
    order: number
  ) => {
    setUnverifiedDetailInfo({ ...item, order });
  };

  const unverifiedTableList = useMemo(() => {
    return list.map((item, i) => {
      const selectedAgreementInfo = agreementInfoList.find(
        (selectedInfo) => selectedInfo.itemId === item.id
      );
      const currentProcessStatus =
        selectedAgreementInfo?.processStatus || item.processStatus;

      const isSelected =
        currentProcessStatus === "stock" || currentProcessStatus === "faulty";

      return {
        rowKey: item.id,
        rowBackgroundColor: isSelected ? undefined : COLOR.pointWarning_20,

        unverifiedStatus: (
          <ProcessStatus
            processStatusList={[currentProcessStatus]}
            handleDetailModalOpen={() => openUnverifiedDetailModal(item, i + 1)}
            isWarehousingStatus={isWarehousingStatus}
          />
        ),
        skuId:
          selectedAgreementInfo?.skuId || item.skuId
            ? getFormattedSingleSkuId(
                selectedAgreementInfo?.skuId || item.skuId
              )
            : "-",
        itemName: `불일치 ${i + 1}`,
        inspectionQuantity: replaceEmptyToDash(
          toThousandUnitFormat(item.actualQty),
          true
        ),
        totalReceivingQuantity: isDecidedQuantityStatus
          ? replaceEmptyToDash(toThousandUnitFormat(item.placeQty), true)
          : "-",
        memo: replaceEmptyToDash(item.memo),
        problemMemo: (
          <ProblemMemo
            quantity={item.quantity}
            actualQty={item.actualQty}
            placeQty={item.placeQty}
            isDecidedQuantityStatus={isDecidedQuantityStatus}
          />
        ),
      };
    });
  }, [agreementInfoList, isDecidedQuantityStatus, isWarehousingStatus, list]);

  const unverifiedTotalQuantity = list.reduce((acc, cur) => {
    return acc + (cur.actualQty ?? 0);
  }, 0);

  const closeUnverifiedDetailModal = () => {
    setUnverifiedDetailInfo(undefined);
  };

  return (
    <Styled.unverifiedListContainer>
      <Styled.unverifiedGuide>
        <span className="title">
          <Icon type="warning" color={COLOR.pointWarning} size={1} />
          불일치 상품 발생
        </span>

        <span className="guide">
          반품입고 요청번호에 SKU가 미포함된 상품이 입고되었습니다. 입고요청에
          포함되지 않은 상품으로, 등록한 SKU ID를 매칭해주세요.
        </span>

        <span className="quantity">
          {replaceEmptyToDash(
            toThousandUnitFormat(unverifiedTotalQuantity),
            true
          )}{" "}
          PCS
        </span>
      </Styled.unverifiedGuide>

      <Table
        rowMinHeight="54px"
        columnInfo={{
          unverifiedStatus: {
            label: "불일치 상품 처리",
            portion: 3.2,
          },
          skuId: {
            label: "SKU ID 매칭",
            portion: 1.9,
          },
          itemName: {
            label: "불일치 상품",
            portion: 2.4,
          },
          inspectionQuantity: {
            label: "검수수량(PCS)",
            portion: 1.8,
          },
          totalReceivingQuantity: {
            label: "입고수량(PCS)",
            portion: 1.8,
          },
          memo: {
            label: "비고",
            portion: 4,
          },
          problemMemo: {
            label: "문제발생",
            portion: 4,
          },
        }}
        dataList={unverifiedTableList}
      />

      {unverifiedDetailInfo && (
        <UnverifiedInfoModal
          teamId={teamId}
          primaryId={primaryId}
          shippingId={shippingId}
          unverifiedDetailInfo={unverifiedDetailInfo}
          closeUnverifiedDetailModal={closeUnverifiedDetailModal}
          isWarehousingStatus={isWarehousingStatus}
          isDecidedQuantityStatus={isDecidedQuantityStatus}
        />
      )}
    </Styled.unverifiedListContainer>
  );
}

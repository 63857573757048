import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import RETURNING_QUERY, {
  RETURNING_QUERY_KEY_GEN,
} from "queries/RETURNING_QUERY";
import { ReturningProcessStatus } from "types/returning";
import { RETURNING_PROCESS_STATUS_DICT } from "utils/returning";

export default function useChangeProcessStatus() {
  const [data, setData] = useState<{
    returningId: number;
    processStatus: ReturningProcessStatus;
  }>();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      RETURNING_QUERY_KEY_GEN.getAdminReturningList({})
    );
    setData(undefined);
  }, [queryClient]);

  const {
    mutate: setAdminProcessStatusToDisposal,
    ResponseHandler: ResponseHandlerOfSetAdminProcessStatusToDisposal,
  } = RETURNING_QUERY.useSetAdminProcessStatusToDisposal({
    onSuccess: handleSuccess,
  });

  const {
    mutate: setAdminProcessStatusTooRestock,
    ResponseHandler: ResponseHandlerOfSetAdminProcessStatusTooRestock,
  } = RETURNING_QUERY.useSetAdminProcessStatusToRestock({
    onSuccess: handleSuccess,
  });

  const {
    mutate: setAdminProcessStatusToForward,
    ResponseHandler: ResponseHandlerOfSetAdminProcessStatusToForward,
  } = RETURNING_QUERY.useSetAdminProcessStatusToForward({
    onSuccess: handleSuccess,
  });

  const openConfirmToChangeProcessStatus = useCallback(
    ({
      returningId,
      processStatus,
    }: {
      returningId: number;
      processStatus: ReturningProcessStatus;
    }) => {
      if (!(returningId && processStatus)) return;

      setData({ returningId, processStatus });
    },
    []
  );

  const handleConfirm = useCallback(() => {
    if (!data) return;

    switch (data.processStatus) {
      case "disposal": {
        setAdminProcessStatusToDisposal({
          pathParams: { returningId: data.returningId },
        });
        break;
      }
      case "forward": {
        setAdminProcessStatusToForward({
          pathParams: { returningId: data.returningId },
        });
        break;
      }
      case "restock": {
        setAdminProcessStatusTooRestock({
          pathParams: { returningId: data.returningId },
        });
        break;
      }
    }
  }, [
    data,
    setAdminProcessStatusToDisposal,
    setAdminProcessStatusToForward,
    setAdminProcessStatusTooRestock,
  ]);

  const handleCancel = useCallback(() => {
    setData(undefined);
  }, []);

  const ConfirmToChangeProcessStatus = useMemo(() => {
    if (!data) return null;

    return (
      <>
        <Dialog open onClose={() => setData(undefined)}>
          <DialogTitle>
            정말로 '{RETURNING_PROCESS_STATUS_DICT[data.processStatus]}'
            하시겠습니까?
          </DialogTitle>
          <DialogActions>
            <Button color="inherit" onClick={handleCancel}>
              아니오
            </Button>
            <Button onClick={handleConfirm}>예</Button>
          </DialogActions>
        </Dialog>

        {ResponseHandlerOfSetAdminProcessStatusToDisposal}
        {ResponseHandlerOfSetAdminProcessStatusTooRestock}
        {ResponseHandlerOfSetAdminProcessStatusToForward}
      </>
    );
  }, [
    data,
    handleCancel,
    handleConfirm,
    ResponseHandlerOfSetAdminProcessStatusToDisposal,
    ResponseHandlerOfSetAdminProcessStatusToForward,
    ResponseHandlerOfSetAdminProcessStatusTooRestock,
  ]);

  return { openConfirmToChangeProcessStatus, ConfirmToChangeProcessStatus };
}

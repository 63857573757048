import { useAppMutation, useAppQueryWithQueryKeyFactory } from "services/query";

import {
  ADD_LOCATION_REQ,
  ADD_LOCATION_RES,
  CHANGE_LOCATION_ACTIVATION_REQ,
  GET_ADMIN_LOCATION_DETAIL_FOR_CSV_DOWNLOAD_REQ,
  GET_ADMIN_LOCATION_DETAIL_FOR_CSV_DOWNLOAD_REQ_PATH_PARAMS,
  GET_ADMIN_LOCATION_DETAIL_REQ,
  GET_ADMIN_LOCATION_DETAIL_REQ_PATH_PARAMS,
  GET_ADMIN_LOCATION_DETAIL_RES,
  GET_ADMIN_LOCATION_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_ADMIN_LOCATION_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_ADMIN_LOCATION_LIST_REQ,
  GET_ADMIN_LOCATION_LIST_RES,
  GET_LOCATION_LABEL_PRINTING_DATA_REQ,
  GET_LOCATION_LABEL_PRINTING_DATA_RES,
} from "api-interfaces/location";

export const LOCATION_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/LOCATION_QUERY" }] as const,

  adminList: () =>
    [{ ...LOCATION_QUERY_KEY_GEN.all()[0], subScope: "adminList" }] as const,

  getAdminList: (params: GET_ADMIN_LOCATION_LIST_REQ) =>
    [
      {
        ...LOCATION_QUERY_KEY_GEN.adminList()[0],
        ...params,
        entity: "adminList",
      },
    ] as const,

  getAdminListForCSVDownload: (
    params: GET_ADMIN_LOCATION_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...LOCATION_QUERY_KEY_GEN.adminList()[0],
        ...params,
        entity: "adminListForCSVDownload",
      },
    ] as const,

  getLocationLabelPrintingData: (
    params: GET_LOCATION_LABEL_PRINTING_DATA_REQ
  ) =>
    [
      {
        ...LOCATION_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminListForCSVDownload",
      },
    ] as const,

  getAdminDetail: (
    params: GET_ADMIN_LOCATION_DETAIL_REQ &
      Partial<GET_ADMIN_LOCATION_DETAIL_REQ_PATH_PARAMS>
  ) =>
    [
      {
        ...LOCATION_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminDetail",
      },
    ] as const,

  getAdminDetailForCSVDownload: (
    params: GET_ADMIN_LOCATION_DETAIL_FOR_CSV_DOWNLOAD_REQ &
      Partial<GET_ADMIN_LOCATION_DETAIL_FOR_CSV_DOWNLOAD_REQ_PATH_PARAMS>
  ) =>
    [
      {
        ...LOCATION_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminDetailForCSVDownload",
      },
    ] as const,
};

function useGetAdminList({
  onSuccess,
  ...params
}: GET_ADMIN_LOCATION_LIST_REQ & {
  onSuccess?: (data: GET_ADMIN_LOCATION_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof LOCATION_QUERY_KEY_GEN.getAdminList>,
    GET_ADMIN_LOCATION_LIST_RES
  >({
    queryKey: LOCATION_QUERY_KEY_GEN.getAdminList(params),
    requestOptions: {
      method: "get",
      path: "/location/admin/list",
      params,
    },

    keepPreviousData: true,

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "로케이션 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetAdminListForCSVDownload({
  enabled,
  ...params
}: GET_ADMIN_LOCATION_LIST_FOR_CSV_DOWNLOAD_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof LOCATION_QUERY_KEY_GEN.getAdminListForCSVDownload>,
    GET_ADMIN_LOCATION_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey: LOCATION_QUERY_KEY_GEN.getAdminListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/location/admin/list",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    failureModalInfo: {
      customizeMessage: () => ({
        title: "로케이션 리스트 다운로드 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useAddLocation() {
  const mutation = useAppMutation<ADD_LOCATION_REQ, ADD_LOCATION_RES>({
    requestOptions: {
      method: "post",
      path: `/location`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E5007") {
          return {
            messageType: "titleOnly",
            title: "이미 등록되어 있는 로케이션 입니다.",
          };
        }

        return {
          title: "로케이션 등록 중에 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

function useChangeLocationActivation() {
  const mutation = useAppMutation<
    CHANGE_LOCATION_ACTIVATION_REQ,
    { result: "locations updated" }
  >({
    requestOptions: {
      method: "patch",
      path: `/location/active`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "로케이션 상태 변경 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetLocationLabelPrintingData({
  enabled,
  onSuccess,
  ...params
}: {
  enabled: boolean;
  onSuccess?: (data: GET_LOCATION_LABEL_PRINTING_DATA_RES) => void;
} & GET_LOCATION_LABEL_PRINTING_DATA_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof LOCATION_QUERY_KEY_GEN.getLocationLabelPrintingData>,
    GET_LOCATION_LABEL_PRINTING_DATA_RES
  >({
    queryKey: LOCATION_QUERY_KEY_GEN.getLocationLabelPrintingData(params),
    requestOptions: {
      method: "get",
      path: "/location/print",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "라벨 출력 데이터 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetAdminDetail({
  enabled,
  locationId,
  ...params
}: GET_ADMIN_LOCATION_DETAIL_REQ &
  Partial<GET_ADMIN_LOCATION_DETAIL_REQ_PATH_PARAMS> & {
    enabled?: boolean;
  }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof LOCATION_QUERY_KEY_GEN.getAdminDetail>,
    GET_ADMIN_LOCATION_DETAIL_RES
  >({
    queryKey: LOCATION_QUERY_KEY_GEN.getAdminDetail({ locationId, ...params }),
    requestOptions: {
      method: "get",
      path: `/location/admin/list/${locationId}`,
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "로케이션 상세 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetAdminDetailForCSVDownload({
  enabled,
  locationId,
  ...params
}: GET_ADMIN_LOCATION_DETAIL_FOR_CSV_DOWNLOAD_REQ &
  Partial<GET_ADMIN_LOCATION_DETAIL_FOR_CSV_DOWNLOAD_REQ_PATH_PARAMS> & {
    enabled?: boolean;
  }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof LOCATION_QUERY_KEY_GEN.getAdminDetailForCSVDownload>,
    GET_ADMIN_LOCATION_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey: LOCATION_QUERY_KEY_GEN.getAdminDetailForCSVDownload({
      locationId,
      ...params,
    }),
    requestOptions: {
      method: "get",
      path: `/location/admin/list/${locationId}`,
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    failureModalInfo: {
      customizeMessage: () => ({
        title: "로케이션 히스토리 다운로드 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

const LOCATION_QUERY = {
  useGetAdminList,
  useGetAdminListForCSVDownload,
  useAddLocation,
  useChangeLocationActivation,
  useGetLocationLabelPrintingData,
  useGetAdminDetail,
  useGetAdminDetailForCSVDownload,
};
export default LOCATION_QUERY;

import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import useQueryString from "hooks/useQueryString";
import AUTH_QUERY from "queries/AUTH_QUERY";
import { FULFILLMENT_AUTH_ATOMS } from "states/auth";
import LoginForAdmin from "components/LoginForAdmin";

import Styled from "./index.styles";

export default function Login() {
  const history = useHistory();
  const loggedIn = useRecoilValue(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

  const {
    queries: { backTo },
  } = useQueryString<{ backTo?: string }>(history);

  const {
    mutate: login,
    ResponseHandler: ResponseHandlerOfLogin,
    isLoading,
  } = AUTH_QUERY.useAdminLogin();

  return (
    <LoginForAdmin
      login={({ id, password }) => login({ accountId: id, password: password })}
      isLoadingOfLogin={isLoading}
      isLoggedIn={loggedIn}
      ResponseHandlerOfLogin={ResponseHandlerOfLogin}
      RightSideElem={
        <Styled.rightSideElem>
          <img
            className="fullfillment"
            src="/images/fullfillment.svg"
            alt="fullfillment"
          />
          <img className="logo" src="/images/login_logo.svg" alt="login_logo" />
        </Styled.rightSideElem>
      }
      backTo={backTo}
    />
  );
}

import styled from "styled-components";

const inputParcelModalBody = styled.div`
  > .input-select {
    text-align: left;
    margin-bottom: 24px;
  }
`;

const invoiceList = styled.div`
  margin-bottom: 24px;
`;

const invoiceItem = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;

  > .input-text {
    margin-right: 8px;
  }

  > .icon {
    margin-bottom: 10px;
  }
`;

export default {
  inputParcelModalBody,
  invoiceList,
  invoiceItem,
};

import { Description } from "@mui/icons-material";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import MemoizedTable, { TableBodyRow } from "components/MemoizedTable";
import dayjs from "dayjs";
import useMultiSelect from "hooks/useMultiSelect";
import useQueryString from "hooks/useQueryString";
import fileDownload from "js-file-download";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import { ReactNode, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { sendRequest } from "services/request";
import {
  AdminShippingListTabStatus,
  DeliveryArea,
  DeliveryCategory,
  ShippingPickingList,
} from "types/shipping";
import { toThousandUnitFormat } from "utils/number";
import { omitWithEllipsis } from "utils/string";
import { CellId } from "./types";
import { HeadCell } from "./utils";
import PrintTransactionStatement from "pages/shipping/PrintTransactionStatement";
import PrintInvoice from "pages/shipping/PrintInvoice";

type PickingListTabStatus = Extract<
  AdminShippingListTabStatus,
  "WAITING_EXPECTED" | "WAITING_TODAY"
>;
function PickingList({
  pickingListType,
}: {
  pickingListType: {
    deliveryArea: DeliveryArea;
    deliveryCategory: DeliveryCategory;
  };
}) {
  const [selectedShipperIndex, setSelectedShipperIndex] = useState(0);

  const tabList: Array<{
    label: ReactNode;
    status: PickingListTabStatus;
  }> = [
    {
      label: "의뢰대기(예정)",
      status: "WAITING_EXPECTED",
    },
    {
      label: "의뢰대기(당일)",
      status: "WAITING_TODAY",
    },
  ];

  const handleShipperListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedShipperIndex(index);
  };

  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  const history = useHistory();
  const { addQuery, queries } = useQueryString<{
    status: PickingListTabStatus;
  }>(history);

  const status =
    queries.status ||
    ((): PickingListTabStatus => {
      addQuery({ status: "WAITING_TODAY" });
      return "WAITING_TODAY";
    })();

  const activeTabIndex = tabList.findIndex((v) => v.status === status) || 0;
  const handleTableTabChange = (val: number) => {
    addQuery({ status: tabList[val].status });
  };

  const {
    data: pickingListSummary,
    ResponseHandler: ResponseHandlerOfGetShippingPickingListSummary,
    refetch,
  } = SHIPPING_QUERY.useGetShippingPickingListSummary({
    deliveryCategory: pickingListType.deliveryCategory,
    status: status === "WAITING_TODAY" ? "waitingToday" : "waitingExpected",
    enabled: !!(
      status &&
      pickingListType.deliveryArea &&
      pickingListType.deliveryCategory
    ),
    onSuccess: () => {
      setSelectedShipperIndex(0);
    },
  });

  const selectedShipper =
    pickingListSummary && pickingListSummary[selectedShipperIndex];

  const {
    data: pickingList,
    ResponseHandler: ResponseHandlerOfGetShippingPickingList,
  } = SHIPPING_QUERY.useGetShippingPickingList({
    teamId: selectedShipper?.teamId || 0,
    deliveryCategory: pickingListType.deliveryCategory,
    status: status === "WAITING_TODAY" ? "waitingToday" : "waitingExpected",
    enabled: !!(
      selectedShipper &&
      status &&
      pickingListType.deliveryArea &&
      pickingListType.deliveryCategory
    ),
    onSuccess: (data) => {
      initSelectionDict(data.map((_, i) => i));
    },
  });

  const mapDataForTable = useCallback(
    (data: ShippingPickingList[] | undefined) => {
      if (!data) return [];

      return data.map((v, idx) => {
        const row: TableBodyRow<CellId> = {
          id: idx,
          no: idx + 1,
          skuId: v.itemCombination.map((ic, icid) => (
            <Typography key={icid}>{ic.skuId}</Typography>
          )),
          quantity: v.itemCombination.map((ic, icid) => (
            <Typography key={icid}>{ic.qty}</Typography>
          )),
          itemName: v.itemCombination.map((ic, icid) => (
            <Typography key={icid} noWrap={false} variant="inherit">
              {omitWithEllipsis({
                src: ic.itemName,
                maxLength: 30,
                ellipsis: "...",
              })}
            </Typography>
          )),
          amount: v.amount,
          count: v.count,

          handleRowClick: () => {
            const category = pickingListType.deliveryCategory;
            const skuIdSearchList = v.itemCombination
              .map((comb) => comb.skuId)
              .join(",");

            history.push({
              pathname: `/shipping/${pickingListType.deliveryArea}`,
              search: `?category=${
                category === "domesticParcel"
                  ? "PARCEL"
                  : category === "domesticTruck"
                  ? "TRUCK"
                  : category === "overseasExpress"
                  ? "EXPRESS"
                  : ""
              }&status=${status}&operatorInit=and&skuIdSearchListInit=${skuIdSearchList}`,
            });
          },
        };

        return row;
      });
    },
    [
      history,
      pickingListType.deliveryArea,
      pickingListType.deliveryCategory,
      status,
    ]
  );

  const downloadPickingListExcel = () => {
    if (!(selectedShipper && pickingList)) {
      return;
    }
    for (const selectedId of selectedIdList) {
      sendRequest({
        method: "POST",
        path: "/shipping/admin/picking-list/download",
        apiType: "BofulDefault",
        responseType: "blob",
        data: {
          teamId: selectedShipper.teamId,
          status:
            status === "WAITING_TODAY" ? "waitingToday" : "waitingExpected",
          deliveryCategory: pickingListType.deliveryCategory,
          itemCombination: pickingList[selectedId].itemCombination,
        },
      }).then((response) => {
        const blobData = new Blob([response.data]);
        const filename = `${dayjs()
          .add(9, "hours")
          .format("YYYY-MM-DD HH_mm")}_${
          selectedShipper.name || selectedShipper.teamId
        }_${
          pickingListType.deliveryCategory === "domesticParcel"
            ? "택배"
            : pickingListType.deliveryCategory === "domesticTruck"
            ? "화물차량"
            : pickingListType.deliveryCategory === "overseasExpress"
            ? "특성"
            : ""
        }_${selectedId + 1}.xlsx`;

        fileDownload(blobData, filename);
        return;
      });
    }
  };

  const onPrintInvoiceModalClose = () => {
    refetch();
  };

  return (
    <>
      <Stack flexDirection={"row"}>
        <Paper style={{ flex: 1 }}>
          <ListItem>
            <ListItemText
              primary={<Typography variant="h6">화주 리스트</Typography>}
            />
          </ListItem>
          <List style={{ overflowY: "auto", height: "70vh" }}>
            {pickingListSummary &&
              pickingListSummary.map((summaryCount, index) => (
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    selected={index === selectedShipperIndex}
                    onClick={(e) => handleShipperListItemClick(e, index)}
                  >
                    <ListItemText
                      primary={`${summaryCount.name} (${toThousandUnitFormat(
                        summaryCount.count
                      )})`}
                      secondary={`팀 번호 : ${summaryCount.teamId}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Paper>

        <Stack flex={5}>
          <MemoizedTable
            title="피킹 리스트"
            headCells={HeadCell}
            rows={mapDataForTable(pickingList)}
            tableTabInfo={{
              activeTabIndex: activeTabIndex,
              setActiveTabIndex: handleTableTabChange,
              tabList,
            }}
            toolbarItems={{
              right: [
                <Button
                  variant="outlined"
                  key="picking-list-print-button"
                  startIcon={<Description />}
                  onClick={downloadPickingListExcel}
                  disabled={selectedIdList && selectedIdList.length === 0}
                >
                  피킹 리스트 다운로드
                </Button>,

                <>
                  {/* 화물택배일 경우는 항상 출력버튼 비활성화 */}
                  {pickingListType.deliveryCategory === "domesticTruck" ||
                  pickingListType.deliveryCategory === "overseasFreight" ? (
                    <>
                      <PrintTransactionStatement
                        isUsedInsteadOfPrintingInvoice
                        selectedShippingIds={[]}
                        shippingList={undefined}
                        needsStateUpdateWhenPrintingTransactionStatement={false}
                      />
                    </>
                  ) : (
                    <PrintInvoice
                      deliveryCategory={pickingListType.deliveryCategory}
                      selectedShippingIds={selectedIdList.reduce(
                        (prev, selectedId) => {
                          if (pickingList && pickingList[selectedId]) {
                            return prev.concat(
                              pickingList[selectedId].shippingIds
                            );
                          }
                          return prev;
                        },
                        [] as number[]
                      )}
                      shippingList={undefined}
                      needsNumberingWhenPrintingInvoice={true}
                      onModalClose={onPrintInvoiceModalClose}
                    />
                  )}
                </>,
              ],
            }}
            selectionDict={selectionDict}
            select={select}
            unSelect={unSelect}
            selectAll={selectAll}
            unSelectAll={unSelectAll}
            selectedCount={selectedCount}
            isAllSelected={isAllSelected}
          />
        </Stack>
      </Stack>
      {ResponseHandlerOfGetShippingPickingList}
    </>
  );
}

export default PickingList;

import { INVENTORY_MANAGEMENT_KIND_MAP } from "constants/fulfillment";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { InventoryDetailBySKUId } from "api-interfaces/inventory";
import INVENTORY_QUERY from "queries/INVENTORY_QUERY";
import { useRecoilValue } from "recoil";
import { FULFILLMENT_AUTH_SELECTORS } from "states/auth";

import { toFormattedDate } from "utils/date";
import { toThousandUnitFormat } from "utils/number";

import Modal from "components/Modal";
import ConfirmWrapper from "components/sds-v1/ConfirmWrapper";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

import Styled from "../index.styles";
import HistorySection from "./HistorySection";
import InputForReason from "./InputForReason";
import ItemDifference from "./ItemDifference";
import ItemInputForAdjustmentQty from "./ItemInputForAdjustmentQty";
import ItemInputForLocation from "./ItemInputForLocation";
import ItemInputForManagementDate from "./ItemInputForManagementDate";
import useFormState from "./useFormState";
import useLocationOptions, { LocationOption } from "./useLocationOptions";
import useTotalDifference from "./useTotalDifference";
import useUpdateInventory from "./useUpdateInventory";

type CellId =
  | keyof InventoryDetailBySKUId
  | "location"
  | "managementDate"
  | "priorityRank"
  | "adjustmentQty"
  | "difference"
  | "warehouse";

type InventoryDetailModalMode = "read" | "modify";

export default function useInventoryDetailModal({
  refetchInventoryList,
}: {
  refetchInventoryList: () => void;
}) {
  const [isActive, setIsActive] = useState(false);
  const [skuId, setSkuId] = useState<number>();

  const [mode, setMode] = useState<InventoryDetailModalMode>("read");

  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const headCells = useMemo((): TableHeadCell<CellId>[] => {
    return [
      {
        id: "warehouse",
        disablePadding: false,
        label: "센터위치",
        width: 120,
      },
      {
        id: "priorityRank",
        disablePadding: false,
        label: "출고순위",
        width: 60,
      },
      {
        id: "location",
        disablePadding: false,
        label: "보관위치",
        width: 140,
      },
      {
        id: "managementDate",
        disablePadding: false,
        label: "관리일자",
        width: 190,
      },
      {
        id: "totalQty",
        disablePadding: false,
        label: "총 수량",
        width: 120,
        numeric: true,
      },
      {
        id: "inWorkingQty",
        disablePadding: false,
        label: "작업 중 수량",
        width: 120,
        numeric: true,
      },
      {
        id: "availableQty",
        disablePadding: false,
        label: "가용 수량",
        width: 120,
        numeric: true,
      },
      ...(mode === "modify"
        ? ([
            {
              id: "adjustmentQty",
              disablePadding: false,
              label: "조정 후 수량",
              width: 120,
              numeric: true,
            },
            {
              id: "difference",
              disablePadding: false,
              label: "차이",
              width: 100,
              numeric: true,
            },
          ] as TableHeadCell<CellId>[])
        : []),
    ];
  }, [mode]);

  const {
    data: inventoryDetailData,
    ResponseHandler: ResponseHandlerOfGetInventoryDetail,
  } = INVENTORY_QUERY.useGetInventoryDetailBySkuId({
    skuId: skuId ?? 0,
    enabled: !!skuId,
  });

  const isManagedSku = inventoryDetailData?.some((v) => v.sku.managementKind);

  const openInventoryDetail = useCallback((skuId: number) => {
    setIsActive(true);
    setSkuId(skuId);
  }, []);

  const {
    initFormState,
    formState,
    dispatchFormState,
    totalQty,
    totalAvailableQty,
    newTotalAvailableQty,
    updateInventory,
    addInventoryItem,
    removeInventoryItem,
    standardInventory,
  } = useFormState({
    inventoryDetailData,
  });

  const { isSame, Summary: TotalDifference } = useTotalDifference({
    totalAvailableQty,
    newTotalAvailableQty,
  });

  const { getLocationOptions, ResponseHandlerOfGetLocationListOfWarehouse } =
    useLocationOptions({
      skuId,
      formState,
    });

  const handleClose = useCallback(() => {
    setSkuId(undefined);
    setIsActive(false);
    initFormState();
    setMode("read");
  }, [initFormState]);

  const handleUpdateSuccess = useCallback(() => {
    handleClose();
    refetchInventoryList();
  }, [handleClose, refetchInventoryList]);

  const { UpdateInventoryButton } = useUpdateInventory({
    formState,
    TotalDifference: <TotalDifference />,
    hasSameTotal: isSame,
    skuId: standardInventory?.skuId,
    onUpdateSuccess: handleUpdateSuccess,
    isManagedSku: isManagedSku ?? false,
  });

  const metaInfo: {
    label: string;
    value: string | number;
  }[] = useMemo(() => {
    if (!standardInventory) return [];

    return [
      {
        label: "회사명",
        value: standardInventory.team?.company || "",
      },
      {
        label: "팀명",
        value: standardInventory.team?.name || "",
      },
      {
        label: "상품명",
        value: standardInventory.sku.itemName || "",
      },
      {
        label: "상품바코드",
        value: standardInventory.sku.barCode || "",
      },
      {
        label: "상품 관리코드",
        value: standardInventory.sku.productCode || "",
      },
      {
        label: "옵션 관리코드",
        value: standardInventory.sku.managementCode || "",
      },
      {
        label: "타입",
        value: standardInventory.location?.name || "",
      },
      {
        label: "관리기준",
        value: standardInventory.sku.managementKind
          ? INVENTORY_MANAGEMENT_KIND_MAP[standardInventory.sku.managementKind]
          : "-",
      },
      {
        label: "총 수량",
        value: toThousandUnitFormat(totalQty),
      },
    ];
  }, [standardInventory, totalQty]);

  const handleItemAdjustmentQtyChange = useCallback(
    ({ locationId, adjustmentQty }) => {
      dispatchFormState({
        type: "updateAdjustmentQty",
        payload: {
          locationId: locationId,
          adjustmentQty: adjustmentQty,
        },
      });
    },
    [dispatchFormState]
  );

  const handleManagementDateChange = useCallback(
    ({ locationId, managementDate }) => {
      dispatchFormState({
        type: "updateManagementDate",
        payload: {
          locationId: locationId,
          managementDate: managementDate,
        },
      });
    },
    [dispatchFormState]
  );

  const handleItemLocationChange = useCallback(
    ({
      prevLocationId,
      locationOption,
    }: {
      prevLocationId: string | number;
      locationOption: LocationOption;
    }) => {
      dispatchFormState({
        type: "updateLocation",
        payload: {
          prevLocationId,
          newLocationId: locationOption.id,
          locationBarcode: locationOption.barCode,
        },
      });
    },
    [dispatchFormState]
  );

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    const locationIds = formState?.inventory
      ? Array.from(formState.inventory.keys())
      : [];

    if (!locationIds.length) return [];

    return locationIds.map((locationId, i) => {
      // 위에서 검사함
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const target = formState!.inventory.get(locationId)!;

      const warehouse = (() => {
        if (target.isDraft && !target.locationId) {
          return "";
        }

        // 당분간은 인천1센터밖에 없으므로 1로 고정
        return "인천1센터";
      })();

      const isLast = i === locationIds.length - 1;

      const row: TableBodyRow<CellId> = {
        warehouse,
        priorityRank: target.priorityRank ?? "-",
        location:
          mode === "modify" && target.isDraft ? (
            <ItemInputForLocation
              getLocationOptions={getLocationOptions}
              locationId={locationId}
              setItemLocation={handleItemLocationChange}
            />
          ) : (
            target.locationBarCode
          ),
        managementDate:
          mode === "modify" && target.isDraft && isManagedSku ? (
            <ItemInputForManagementDate
              formState={formState}
              locationId={locationId}
              onManagementDateChange={handleManagementDateChange}
            />
          ) : target.managementDate ? (
            toFormattedDate(target.managementDate, "YYYY-MM-DD")
          ) : (
            "-"
          ),
        totalQty: toThousandUnitFormat(target.totalQty),
        inWorkingQty: toThousandUnitFormat(target.inWorkingQty),
        availableQty: toThousandUnitFormat(target.originQty),
        ...(mode === "modify"
          ? {
              adjustmentQty: (
                <ItemInputForAdjustmentQty
                  locationId={locationId}
                  formState={formState}
                  onAdjustmentQtyChange={handleItemAdjustmentQtyChange}
                />
              ),
              difference: (
                <ItemDifference locationId={locationId} formState={formState} />
              ),
              ...(target.isDraft
                ? {
                    handleRowRemove: () => {
                      removeInventoryItem(locationId);
                    },
                  }
                : {}),
              ...(isLast
                ? {
                    handleRowAdd: addInventoryItem,
                  }
                : {}),
            }
          : {}),
      };

      return row;
    });
  }, [
    addInventoryItem,
    formState,
    getLocationOptions,
    handleItemAdjustmentQtyChange,
    handleItemLocationChange,
    handleManagementDateChange,
    isManagedSku,
    mode,
    removeInventoryItem,
  ]);

  const startModifyMode = useCallback(() => {
    setMode("modify");
  }, []);

  const cancelModifyMode = useCallback(() => {
    setMode("read");
  }, []);

  // mode가 바뀌면 state를 초기화
  useEffect(() => {
    if (mode === "read") {
      initFormState();
    }
  }, [initFormState, mode]);

  const InventoryDetailModal = useMemo(() => {
    if (!isActive) return null;

    const canEditInventory = currentUser?.authority === "admin";

    return (
      <Modal
        isOpened
        handleClose={handleClose}
        disabledBackdropClick={mode === "modify"}
        modalBody={
          <div style={{ maxHeight: "80vh", overflow: "scroll" }}>
            <>
              <Table
                title={`SKU ID: ${standardInventory?.skuId}`}
                headCells={headCells}
                rows={rows}
                sx={{
                  // Table컴포넌트에서 자동으로 maxHeight를 계산하지만, 이 경우는 모달내부라 직접 설정
                  maxHeight: "35vh",
                }}
                toolbarItems={{
                  left: [
                    <Styled.metaInfoOnItemInfo key={"meta-info"}>
                      {metaInfo.map((v, i) => {
                        return (
                          <div className="item" key={i}>
                            <div className="label">{v.label}</div>
                            <div className="value">{v.value}</div>
                          </div>
                        );
                      })}
                    </Styled.metaInfoOnItemInfo>,
                  ],
                  right: [
                    canEditInventory && mode === "read" && (
                      <Button
                        key={"modify"}
                        variant="contained"
                        size="medium"
                        onClick={startModifyMode}
                      >
                        수정
                      </Button>
                    ),
                  ],
                }}
              />

              {mode === "modify" && (
                <Styled.editFormFooter>
                  <div className="summary-and-reason">
                    <TotalDifference />

                    <InputForReason
                      reason={formState?.reason}
                      updateReason={(v) =>
                        dispatchFormState({ type: "updateReason", payload: v })
                      }
                    />
                  </div>

                  <div className="action">
                    <ConfirmWrapper
                      confirmMessageTitle={
                        <>
                          수정을 취소하시겠습니까?
                          <br />
                          입력하셨던 정보는 되돌릴 수 없습니다.
                        </>
                      }
                      confirmModalUiType="titleOnly"
                      usePortal
                    >
                      <Button
                        key={"cancel"}
                        variant="outlined"
                        size="medium"
                        color="error"
                        onClick={cancelModifyMode}
                        style={{ marginRight: "10px" }}
                      >
                        수정 취소
                      </Button>
                    </ConfirmWrapper>

                    <UpdateInventoryButton />
                  </div>
                </Styled.editFormFooter>
              )}
              {standardInventory?.skuId && (
                <HistorySection skuId={standardInventory.skuId} />
              )}
              {ResponseHandlerOfGetInventoryDetail}

              {ResponseHandlerOfGetLocationListOfWarehouse}
            </>
          </div>
        }
      />
    );
  }, [
    ResponseHandlerOfGetInventoryDetail,
    ResponseHandlerOfGetLocationListOfWarehouse,
    TotalDifference,
    UpdateInventoryButton,
    cancelModifyMode,
    dispatchFormState,
    formState?.reason,
    handleClose,
    headCells,
    isActive,
    metaInfo,
    mode,
    rows,
    standardInventory?.skuId,
    startModifyMode,
    currentUser?.authority,
  ]);

  return {
    openInventoryDetail,
    InventoryDetailModal,
  };
}

import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";

import usePrintInBrowser from "hooks/usePrintInBrowser";
import { FULFILLMENT_SHIPPING_ATOMS } from "states/shipping";

import withRequireAuth from "hocs/withRequireAuth";

import TransactionStatement from "./TransactionStatement";

function ShippingDomesticListPrintTransactionStatement() {
  const history = useHistory();

  const transactionStatementData = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.TRANSACTION_STATEMENT_DATA
  );
  const resetTransactionStatementData = useResetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.TRANSACTION_STATEMENT_DATA
  );

  const onAfterPrint = useCallback(() => {
    history.goBack();
    resetTransactionStatementData();
  }, [history, resetTransactionStatementData]);

  const { PageToPrint, handlePrint } = usePrintInBrowser({
    type: "A4",
    list: transactionStatementData.map((data) => (
      <TransactionStatement key={data.invoiceNo} data={data} />
    )),
    onAfterPrint,
  });

  useEffect(() => {
    if (transactionStatementData.length > 0) {
      handlePrint();
    }
  }, [handlePrint, transactionStatementData.length]);

  return <>{PageToPrint}</>;
}

export default withRequireAuth(ShippingDomesticListPrintTransactionStatement);

import styled from "styled-components";

import { setFontStyle } from "styles/typography";

const pageBreakWrapper = styled.div`
  && {
    page-break-after: always;
  }
`;

const container = styled.table`
  width: 100%;

  > thead > tr > th {
    padding: 8px;
    font-size: 9pt;
    text-align: right;
  }

  > tbody > tr > td {
    padding: 1.5cm;
    font-size: 10pt;
    display: block;

    > .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5cm;

      > .left {
        width: 30%;
        display: flex;
        flex-direction: column;

        > .title {
          ${setFontStyle("Head2", "Bold")};
          text-align: center;
          margin-bottom: 0.5cm;
        }

        > .customer-info {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          border: 1px solid;
          padding: 0.5cm;

          > .customer-name {
            font-size: 12pt;
            font-weight: 700;
            margin-bottom: 0.3cm;
          }

          > .customer-address {
            margin-bottom: 0.3cm;
          }
        }
      }

      > .right {
        width: 68%;

        > .code-container {
          margin-bottom: 0.3cm;

          > .code {
            font-size: 12pt;
            font-weight: 700;
          }

          > .barcode {
            width: 5cm;
            height: 1.5cm;
          }
        }

        > table.supplier {
          width: 100%;

          td {
            border: 1px solid;
            padding: 8px;
            vertical-align: middle;
          }

          tr:first-child > td:first-child {
            width: 1cm;
            text-align: center;
            font-weight: 700;
          }

          tr:first-child > td:nth-child(2),
          tr:first-child > td:nth-child(4) {
            width: 2.5cm;
            text-align: center;
            font-weight: 700;
          }

          tr:not(:first-child) > td:nth-child(1),
          tr:not(:first-child) > td:nth-child(3) {
            text-align: center;
            font-weight: 700;
          }
        }
      }
    }

    > table.list {
      width: 100%;
      margin-bottom: 0.5cm;

      th {
        font-weight: 700;
      }

      th,
      td {
        border: 1px solid;
        padding: 8px;
      }

      th.print-date {
        width: 8%;
      }
      th.product-name {
        width: 57%;
      }
      th.quantity {
        width: 10%;
      }
      th.note {
        width: 25%;
      }

      td.print-date {
        text-align: center;
      }

      td.quantity {
        text-align: right;
      }
    }

    > .footer {
      width: 100%;

      table.summary {
        page-break-inside: avoid;

        width: 100%;
        margin-bottom: 0.5cm;

        td {
          border: 1px solid;
          padding: 8px;
          vertical-align: middle;
        }

        td:nth-child(1) {
          font-weight: 700;
          width: 2cm;
          text-align: center;
        }

        td:nth-child(2) {
          width: 4cm;
          text-align: right;
        }

        td:nth-child(3) {
          font-weight: 700;
          width: 2cm;
          text-align: center;
        }

        td:last-child {
          text-align: right;
        }
      }

      table.customer-info {
        page-break-inside: avoid;

        width: 100%;
        margin-bottom: 0.5cm;

        td {
          border: 1px solid;
          padding: 8px;
          vertical-align: middle;
        }

        td:nth-child(1),
        td:nth-child(3) {
          font-weight: 700;
          width: 2.5cm;
          text-align: center;
        }

        td:nth-child(2) {
          width: 3cm;
        }
      }

      table.request-memo {
        page-break-inside: avoid;

        width: 100%;

        td {
          border: 1px solid;
          padding: 8px;
          vertical-align: middle;
        }

        td:first-child {
          font-weight: 700;
          width: 3cm;
          text-align: center;
        }
      }
    }
  }
`;

export default {
  pageBreakWrapper,
  container,
};

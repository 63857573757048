import { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  FULFILLMENT_INSPECTION_ATOMS,
  FULFILLMENT_INSPECTION_SELECTORS,
} from "jotaiStates/inspection";
import { AgreementInfoState } from "jotaiStates/inspection/atoms";
import COMMON_QUERY from "queries/COMMON_QUERY";

import { COLOR } from "styles/constants";
import { ProcessStatus } from "types/returning";
import { getPageSize } from "utils/common";
import { getFormattedSingleSkuId } from "utils/fulfillment";
import { toThousandUnitFormat } from "utils/number";
import { replaceEmptyToDash } from "utils/string";

import ImageSlider from "components/ImageSlider";
import ModalInfoSummary from "components/ModalInfoSummary";
import Button from "components/sds-v1/button/Button";
import Icon from "components/sds-v1/Icon";
import InputRadio from "components/sds-v1/input/InputRadio";
import InputTextWithSearch from "components/sds-v1/input/InputTextWithSearch";
import ListItem from "components/sds-v1/ListItem";
import Modal from "components/sds-v1/Modal";
import Paging from "components/sds-v1/Paging";
import Table from "components/sds-v1/table/Table";
import ToolTip from "components/sds-v1/ToolTip";
import SelectButton from "_sds-v2/components/form/SelectButton";
import StateBadgeForInspection from "_sds-v2/components/stateBadge/StateBadgeForInspection";

import CommonStyled from "../../index.styles";
import ProblemMemo from "../../ProblemMemo";
import { UnverifiedDetailInfo } from "../index";
import useShippingSkuList from "./useShippingSkuList";
import useSkuList from "./useSkuList";
import Styled from "./index.styles";

type SkuListType = "shippingSku" | "sku";

const PAGE_UNIT = 5;

export default function UnverifiedInfoModal({
  teamId,
  primaryId,
  shippingId,
  unverifiedDetailInfo,
  closeUnverifiedDetailModal,
  isWarehousingStatus,
  isDecidedQuantityStatus,
}: {
  teamId: number;
  primaryId: string | number;
  shippingId: number;
  unverifiedDetailInfo: UnverifiedDetailInfo;
  closeUnverifiedDetailModal: () => void;
  isWarehousingStatus: boolean;
  isDecidedQuantityStatus: boolean;
}) {
  const [, setAgreementInfoList] = useAtom(
    FULFILLMENT_INSPECTION_ATOMS.AGREEMENT_INFO_LIST_FOR_INSPECTION
  );

  const selectedAgreementInfo = useAtomValue(
    FULFILLMENT_INSPECTION_SELECTORS.AGREEMENT_INFO_OF_SELECTED_INSPECTION_ITEM
  )(unverifiedDetailInfo.id);

  const prevSelectedSkuId =
    selectedAgreementInfo?.skuId || unverifiedDetailInfo.skuId;
  const prevSelectedProcessStatus =
    selectedAgreementInfo?.processStatus || unverifiedDetailInfo.processStatus;

  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedListType, setSelectedListType] =
    useState<SkuListType>("shippingSku");

  const [selectedSkuId, setSelectedSkuId] = useState<number | undefined>(
    prevSelectedSkuId
  );
  const [selectedProcessStatus, setSelectedProcessStatus] = useState<
    ProcessStatus | undefined
  >(prevSelectedProcessStatus);

  const handleListTypeSelect = (type: SkuListType) => {
    setSelectedListType(type);
    setPage(0);
    setSearchTerm("");
  };

  const handleSkuSelect = (skuId: number) => setSelectedSkuId(skuId);

  const handleAgreementInfoListUpdate = (
    newInfo: Partial<AgreementInfoState>
  ) =>
    setAgreementInfoList((prev) =>
      prev.map((item) =>
        item.itemId === unverifiedDetailInfo.id
          ? {
              ...item,
              ...newInfo,
            }
          : item
      )
    );

  const {
    shippingSkuTableList,
    shippingSkuTotalCount,
    ResponseHandlerOfShippingSkuData,
  } = useShippingSkuList({
    shippingId,
    page,
    perPage: PAGE_UNIT,
    searchTerm,
    selectedSkuId,
    handleSkuSelect,
    enabled: selectedListType === "shippingSku",
  });

  const { skuTableList, skuTotalCount, ResponseHandlerOfSkuList } = useSkuList({
    page,
    perPage: PAGE_UNIT,
    teamId,
    searchTerm,
    selectedSkuId,
    handleSkuSelect,
    enabled: selectedListType === "sku",
  });

  const total =
    selectedListType === "shippingSku" ? shippingSkuTotalCount : skuTotalCount;

  const selectedTableList =
    selectedListType === "shippingSku" ? shippingSkuTableList : skuTableList;

  const handleSelect = (processStatus: ProcessStatus) =>
    setSelectedProcessStatus(processStatus);

  const handleComplete = () => {
    if (!(selectedSkuId && selectedProcessStatus)) return;

    handleAgreementInfoListUpdate({
      skuId: selectedSkuId,
      processStatus: selectedProcessStatus,
    });

    closeUnverifiedDetailModal();
  };

  const validForComplete =
    selectedProcessStatus &&
    selectedSkuId &&
    (prevSelectedProcessStatus !== selectedProcessStatus ||
      prevSelectedSkuId !== selectedSkuId);

  const fileKeyList =
    unverifiedDetailInfo.attachment
      ?.filter(({ domain }) => domain === "unverifiedSku")
      .map(({ key }) => key) ?? [];

  const {
    data: fileUrlList,
    ResponseHandler: ResponseHandlerOfGetFileUrlList,
  } = COMMON_QUERY.useGetFileUrlList({
    key: fileKeyList,
    enabled: !!fileKeyList.length,
  });

  const imageList = fileUrlList?.map(({ url, key }, i) => ({
    url,
    key,
    id: i,
  }));

  const canSelected = !isWarehousingStatus;

  const totalQuantity = isDecidedQuantityStatus
    ? unverifiedDetailInfo.placeQty
    : unverifiedDetailInfo.actualQty;

  return (
    <Modal
      uiType="contentWithCustomBody"
      active={!!unverifiedDetailInfo}
      title={"불일치 상품 처리"}
      body={
        <Styled.detailInfoContainer isVisibleSkuList={canSelected}>
          <ModalInfoSummary
            summaryData={[
              {
                list: [
                  {
                    label: "반품요청번호",
                    value: primaryId,
                  },
                ],
                hasBottomDivider: true,
              },
              ...(isWarehousingStatus
                ? [
                    {
                      list: [
                        {
                          label: "상품명",
                          value: unverifiedDetailInfo.sku?.itemName || "",
                        },
                      ],
                      isItemName: true,
                    },
                  ]
                : []),
              {
                list: [
                  ...(isWarehousingStatus
                    ? [
                        {
                          label: "SKU ID",
                          value: getFormattedSingleSkuId(
                            unverifiedDetailInfo.skuId
                          ),
                        },
                      ]
                    : []),
                  {
                    value: `불일치 ${unverifiedDetailInfo.order}`,
                  },
                  {
                    label: "검수수량(PCS)",
                    value: replaceEmptyToDash(
                      toThousandUnitFormat(unverifiedDetailInfo.actualQty),
                      true
                    ),
                  },
                  {
                    label: "입고수량(PCS)",
                    value: isDecidedQuantityStatus
                      ? replaceEmptyToDash(
                          toThousandUnitFormat(unverifiedDetailInfo.placeQty),
                          true
                        )
                      : "-",
                  },
                ],
              },
            ]}
          />

          {canSelected && (
            <Styled.guideContainer>
              <CommonStyled.title>SKU ID 매칭</CommonStyled.title>

              <CommonStyled.guide>
                상품사진을 확인 후 해당하는 SKU ID를 매칭해주세요.{` `}
                <em>
                  매칭할 수 있는 SKU ID가 없는 경우, 상품등록 후 해당 SKU ID를
                  매칭해주세요.
                </em>
              </CommonStyled.guide>
            </Styled.guideContainer>
          )}

          <div>
            <Styled.imageAndSkuListContainer>
              <Styled.imageContainer>
                <ImageSlider width={"572px"} imageList={imageList ?? []} />
              </Styled.imageContainer>

              {canSelected && (
                <Styled.skuListContainer>
                  <InputRadio<SkuListType>
                    direction="row"
                    optionList={[
                      { label: "원출고 SKU ID", value: "shippingSku" },
                      { label: "전체 SKU ID", value: "sku" },
                    ]}
                    selectedValue={selectedListType}
                    selectCallback={handleListTypeSelect}
                  />

                  <Styled.skuSearchContainer>
                    <span className="label">SKU ID 검색</span>

                    <InputTextWithSearch
                      searchTerm={searchTerm}
                      handleSearch={(v) => {
                        setSearchTerm(v);
                        setPage(0);
                      }}
                      resetSearch={() => setSearchTerm("")}
                      placeholder="검색어를 모두 입력해야 조회가 가능합니다."
                    />
                  </Styled.skuSearchContainer>

                  <Paging
                    pageSize={getPageSize(PAGE_UNIT, total ?? 0)}
                    currentPage={page ?? 0}
                    currentPageData={
                      <Table
                        rowMinHeight="54px"
                        columnInfo={{
                          select: {
                            label: "",
                            portion: 0.5,
                          },
                          skuId: {
                            label: "SKU ID",
                            portion: 1,
                          },
                          itemName: {
                            label: "상품명",
                            portion: 3,
                          },
                          barCode: {
                            label: "상품바코드",
                            portion: 1.5,
                          },
                        }}
                        dataList={selectedTableList}
                      />
                    }
                    handleClickPage={(page) => setPage(page)}
                    isZeroBasedPage
                  />
                </Styled.skuListContainer>
              )}
            </Styled.imageAndSkuListContainer>
          </div>

          {isWarehousingStatus && (
            <>
              <ListItem
                type="withContentAlignRight"
                label="비고"
                value={unverifiedDetailInfo.memo ?? "-"}
                className="custom-list-item-memo"
              />

              <ListItem
                type="withContentAlignRight"
                label="문제발생"
                value={
                  <ProblemMemo
                    quantity={unverifiedDetailInfo.quantity}
                    actualQty={unverifiedDetailInfo.actualQty}
                    placeQty={unverifiedDetailInfo.placeQty}
                    isDecidedQuantityStatus={isDecidedQuantityStatus}
                  />
                }
                className="custom-list-item-memo"
              />
            </>
          )}

          <Styled.selectProcessStatusContainer>
            <CommonStyled.title>불일치 상품 처리</CommonStyled.title>

            <CommonStyled.inspectTotalQuantity isBackgroundColorBlue={true}>
              <CommonStyled.badgeAndQuantity>
                <SelectButton
                  uiType="radio"
                  size="large"
                  onSelect={handleSelect}
                  label={
                    <StateBadgeForInspection status="stock" label="정상입고" />
                  }
                  value={"stock"}
                  selected={selectedProcessStatus === "stock"}
                  // disabled={!selectedSkuId}
                  disabled={true}
                />

                <span>
                  {selectedProcessStatus === "stock"
                    ? replaceEmptyToDash(
                        toThousandUnitFormat(totalQuantity),
                        true
                      )
                    : "-"}{" "}
                  PCS
                </span>
              </CommonStyled.badgeAndQuantity>

              <CommonStyled.badgeAndQuantity>
                <SelectButton
                  uiType="radio"
                  size="large"
                  onSelect={handleSelect}
                  label={
                    <StateBadgeForInspection status="faulty" label="불량입고" />
                  }
                  value={"faulty"}
                  selected={selectedProcessStatus === "faulty"}
                  // disabled={!selectedSkuId}
                  disabled={true}
                />

                <span>
                  {selectedProcessStatus === "faulty"
                    ? replaceEmptyToDash(
                        toThousandUnitFormat(totalQuantity),
                        true
                      )
                    : "-"}{" "}
                  PCS
                </span>
              </CommonStyled.badgeAndQuantity>

              <div className="divider" />

              <CommonStyled.totalQuantity>
                <span className="label">
                  {isDecidedQuantityStatus ? "최종 입고수량" : "입고 예정수량"}

                  <ToolTip
                    displayType="block"
                    contentForDesktop={{
                      position: "bottomRight",
                      content: {
                        type: "descOnly",
                        desc: isDecidedQuantityStatus ? (
                          <>
                            입고 시 최종으로 반영되는 수량입니다.
                            <br />
                            입고 단계에서 특정 문제 발생 시 입고 수량이 변동될
                            수 있습니다.
                          </>
                        ) : (
                          <>
                            입고하기 전 검수완료(이슈) 단계에서
                            <br />
                            정상, 불량 입고 처리를 선택한 수량입니다.
                          </>
                        ),
                      },
                      bodyWidth: isDecidedQuantityStatus ? 26 : 17,
                    }}
                    contentForMobile={{
                      isSameToDesktop: true,
                    }}
                  >
                    <Icon
                      type="circleFilledInformation"
                      color={COLOR.grayScale_800}
                      size={1}
                    />
                  </ToolTip>
                </span>

                <span className="quantity">
                  {selectedSkuId &&
                  (selectedProcessStatus === "stock" ||
                    selectedProcessStatus === "faulty")
                    ? toThousandUnitFormat(totalQuantity)
                    : "-"}{" "}
                  PCS
                </span>
              </CommonStyled.totalQuantity>
            </CommonStyled.inspectTotalQuantity>
          </Styled.selectProcessStatusContainer>

          {canSelected && (
            <Button
              size="normal"
              theme="primary"
              key="reorder"
              label="선택 완료"
              handleClick={handleComplete}
              // disabled={!validForComplete}
              disabled={true}
            />
          )}

          {ResponseHandlerOfSkuList}
          {ResponseHandlerOfShippingSkuData}
          {ResponseHandlerOfGetFileUrlList}
        </Styled.detailInfoContainer>
      }
      onClose={closeUnverifiedDetailModal}
      needConfirmBeforeCloseModal={
        prevSelectedProcessStatus !== selectedProcessStatus ||
        prevSelectedSkuId !== selectedSkuId
      } // 선택 변경 값이 있는데 닫는경우, 확인 모달
      needConfirmBeforeCloseModalInfo={{
        title: (
          <>
            상품처리를 중단하고 창을 닫으시겠습니까?
            <br />
            선택 완료 하지 않을 시 내용은 저장되지 않습니다.
          </>
        ),
        actionPositiveLabel: "페이지 나가기",
        actionNegativeLabel: "이어서 진행",
      }}
    />
  );
}

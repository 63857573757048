import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { NextArrow, PrevArrow } from "./Arrows";
import DetailModal from "./DetailModal/index";
import Styled from "./index.styles";

export type ImageInfo = {
  /** 이미지 카테고리 별 id (id를 기준으로 이미지가 한 카테고리로 묶인다) */
  id: number;
  /** 이미지의 고유한 키 값 */
  key: string;
  title?: string;
  url: string;
};

export const SETTINGS = (() => {
  {
    return {
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      easing: "ease-in",
      rows: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
  }
})();

export default function ImageSlider({
  imageList,
  width,
  activeSlideKey,
}: {
  imageList: ImageInfo[];
  width?: string;
  activeSlideKey?: string;
}) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const [opensImageDetailModal, setOpensImageDetailModal] =
    useState<boolean>(false);

  const currentImageTitle = imageList[currentSlideIndex]?.title;

  const closeDetailModal = () => setOpensImageDetailModal(false);

  const hasTitle = imageList.some((item) => item.title);

  const sliderRef = useRef<Slider>(null);

  // 외부에서 activeSlideIndex 가 변경되었을 때 슬라이드 변경.
  useEffect(() => {
    if (activeSlideKey === undefined) return;

    const activeSlideIndex = imageList.findIndex(
      (item) => item.key === activeSlideKey
    );

    if (activeSlideIndex === -1) return;

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(activeSlideIndex);
    }
  }, [activeSlideKey, imageList]);

  return (
    <Styled.container width={width} hasTitle={hasTitle}>
      {currentImageTitle && (
        <span className="image-title">{currentImageTitle}</span>
      )}

      <Slider
        {...SETTINGS}
        ref={sliderRef}
        beforeChange={(current: number, next: number) =>
          setCurrentSlideIndex(next)
        }
      >
        {imageList.length ? (
          imageList.map(({ url }) => (
            <Styled.block
              key={url}
              imageUrl={url}
              onClick={() => setOpensImageDetailModal(true)}
            />
          ))
        ) : (
          <Styled.noImageBlock>이미지 없음</Styled.noImageBlock>
        )}
      </Slider>

      <DetailModal
        imageList={imageList}
        currentSlideKey={imageList[currentSlideIndex]?.key}
        opensImageDetailModal={opensImageDetailModal}
        closeDetailModal={closeDetailModal}
      />
    </Styled.container>
  );
}

import { INVENTORY_MANAGEMENT_KIND_MAP } from "constants/fulfillment";

import { COLOR } from "styles/constants";
import { ManagementKind } from "types/sku";
import { toFormattedDate } from "utils/date";

import TextButton from "components/sds-v1/button/TextButton";
import Icon from "components/sds-v1/Icon";
import StateBadgeForInspection from "_sds-v2/components/stateBadge/StateBadgeForInspection";

import CommonStyled from "../../../index.styles";
import { ProcessStatusMap } from "..";
import Styled from "./index.styles";

export default function ManagementList({
  managementKind = "expirationDate",
  selectedId,
  processStatusMap,
  onManagementDateSelect,
}: {
  managementKind: ManagementKind | undefined;
  selectedId: number;
  processStatusMap: ProcessStatusMap;
  onManagementDateSelect: (itemId: number) => void;
}) {
  const normalList = Object.entries(processStatusMap).filter(
    ([, { processStatus }]) => processStatus === "normal"
  );
  const stockList = Object.entries(processStatusMap).filter(
    ([, { processStatus }]) => processStatus === "stock"
  );
  const faultyList = Object.entries(processStatusMap).filter(
    ([, { processStatus }]) => processStatus === "faulty"
  );

  const hasNormal = normalList.length > 0;
  const hasStock = stockList.length > 0;
  const hasFaulty = faultyList.length > 0;

  return (
    <Styled.managementDateList>
      <CommonStyled.title>
        {INVENTORY_MANAGEMENT_KIND_MAP[managementKind]} 별 비정상 상품 처리 현황
      </CommonStyled.title>

      <Styled.selectItemContainer>
        <Styled.description>
          <Icon
            size={1}
            color={COLOR.grayScale_700}
            type="circleFilledInformation"
          />
          <p>일자별로 비정상 상품 처리를 선택해주세요.</p>
        </Styled.description>

        <Styled.dateListByType>
          <Styled.dateListWrapper isNormal>
            <StateBadgeForInspection status="unselect" label="미선택" />

            {hasNormal ? (
              <Styled.dateList>
                {normalList.map(([itemId, { managementDate }]) => (
                  <TextButton
                    handleClick={() => onManagementDateSelect(Number(itemId))}
                    icon="arrowRight"
                    key={itemId}
                    label={toFormattedDate(managementDate, "YYYY-MM-DD")}
                    size="14px"
                    theme={selectedId === Number(itemId) ? "withIcon" : "gray"}
                  />
                ))}
              </Styled.dateList>
            ) : (
              <Styled.emptyMessage>모두 선택 완료했습니다.</Styled.emptyMessage>
            )}
          </Styled.dateListWrapper>
        </Styled.dateListByType>

        <Styled.divider />

        <Styled.dateListByType>
          <Styled.dateListWrapper isNormal={false}>
            <StateBadgeForInspection status="stock" label="정상입고" />

            {hasStock ? (
              <Styled.dateList>
                {stockList.map(([itemId, { managementDate }]) => (
                  <TextButton
                    handleClick={() => onManagementDateSelect(Number(itemId))}
                    icon="arrowRight"
                    key={itemId}
                    label={toFormattedDate(managementDate, "YYYY-MM-DD")}
                    size="14px"
                    theme={selectedId === Number(itemId) ? "withIcon" : "gray"}
                  />
                ))}
              </Styled.dateList>
            ) : (
              <Styled.emptyMessage>
                선택한 정상입고 항목이 없습니다.
              </Styled.emptyMessage>
            )}
          </Styled.dateListWrapper>
        </Styled.dateListByType>

        <Styled.dateListByType>
          <Styled.dateListWrapper isNormal={false}>
            <StateBadgeForInspection status="faulty" label="불량입고" />

            {hasFaulty ? (
              <Styled.dateList>
                {faultyList.map(([itemId, { managementDate }]) => (
                  <TextButton
                    handleClick={() => onManagementDateSelect(Number(itemId))}
                    icon="arrowRight"
                    key={itemId}
                    label={toFormattedDate(managementDate, "YYYY-MM-DD")}
                    size="14px"
                    theme={selectedId === Number(itemId) ? "withIcon" : "gray"}
                  />
                ))}
              </Styled.dateList>
            ) : (
              <Styled.emptyMessage>
                선택한 불량입고 항목이 없습니다.
              </Styled.emptyMessage>
            )}
          </Styled.dateListWrapper>
        </Styled.dateListByType>
      </Styled.selectItemContainer>
    </Styled.managementDateList>
  );
}

import { omitWithEllipsis } from "./string";

function getTitleAmongItemListName(
  items?: { name: string }[],
  maxLength?: number
) {
  let result = "";

  if (items && items.length > 0) {
    result += items[0].name;

    if (maxLength) {
      result = omitWithEllipsis({
        src: result,
        maxLength,
        ellipsis: "...",
      });
    }

    if (items.length > 1) {
      result += ` 외 ${items.length - 1}개`;
    }
  }

  return result;
}

function getSupplyType(freightType: string) {
  let result = "";
  switch (freightType) {
    case "AIR":
      result = "C/W";
      break;
    case "LCL":
      result = "R.TON";
      break;
    case "FCL":
      result = "FEU";
      break;
    default:
      break;
  }

  return result;
}

export { getTitleAmongItemListName, getSupplyType };

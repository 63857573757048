import Paging from "components/sds-v1/Paging";
import { TableDataListItem } from "headlessComponents/table/useTable";
import INVENTORY_QUERY from "queries/INVENTORY_QUERY";
import { ReactNode, useState } from "react";
import { getPageSize } from "utils/common";
import { toFormattedDate } from "utils/date";

import Table from "components/sds-v1/table/Table";

import Styled from "./index.styles";
import StateBadgeForFulfillment from "_sds-v2/components/stateBadge/StateBadgeForFulfillment";

type ShippingOrderItem = {
  shippingOrder: ReactNode;
  managementDate: ReactNode;
  availableQty: number;
  inWorkingQty: number;
  faultyQty: number;
  totalWithoutFaultyQty: number;
  warehouseName: string;
};

const PER_PAGE = 20;

export default function ManagementSection({ skuId }: { skuId: number }) {
  const [page, setPage] = useState(0);

  const {
    data: inventoryPriorityList,
    ResponseHandler: ResponseHandlerOfInventoryPriorityList,
  } = INVENTORY_QUERY.useGetInventoryPriorityList({
    skuId,
    page: page,
    perPage: PER_PAGE,
  });

  const dataList: TableDataListItem<ShippingOrderItem>[] =
    inventoryPriorityList?.list?.map((item) => {
      const shippingOrder = (() => {
        return (
          <Styled.shippingOrderCell>
            <span>{item.priorityRank}</span>

            {item.userSetPriority ? (
              <Styled.changedItem>순위 변경됨</Styled.changedItem>
            ) : (
              <Styled.changedItem>{""}</Styled.changedItem>
            )}
          </Styled.shippingOrderCell>
        );
      })();

      return {
        rowKey: item.id,
        shippingOrder,
        managementDate: (
          <Styled.managementDate>
            <span>{toFormattedDate(item.managementDate, "YYYY-MM-DD")}</span>

            {item.priorityRank === -1 && (
              <StateBadgeForFulfillment status="problem" label={"만료"} />
            )}

            {item.priorityRank === 0 && (
              <StateBadgeForFulfillment status="inProgress" label={"작업 중"} />
            )}
          </Styled.managementDate>
        ),
        availableQty: item.availableQty,
        inWorkingQty: item.inWorkingQty,
        faultyQty: item.faultyQty,
        totalWithoutFaultyQty: item.totalWithoutFaultyQty,
        warehouseName: "인천1센터",
      };
    }) ?? [];

  return (
    <>
      <Paging
        pageSize={getPageSize(PER_PAGE, inventoryPriorityList?.total)}
        currentPage={page}
        currentPageData={
          <Table<ShippingOrderItem>
            columnInfo={{
              shippingOrder: {
                label: "출고순위",
                portion: 200,
              },
              managementDate: {
                label: "관리일자",
                portion: 130,
              },
              availableQty: {
                label: "가용재고",
                portion: 90,
              },
              inWorkingQty: {
                label: "작업중재고",
                portion: 90,
              },
              faultyQty: {
                label: "불량품",
                portion: 80,
              },
              totalWithoutFaultyQty: {
                label: "총재고(불량품 제외)",
                portion: 180,
              },
              warehouseName: {
                label: "입출고지",
                portion: 70,
              },
            }}
            dataList={dataList}
          />
        }
        handleClickPage={setPage}
        isZeroBasedPage
      />

      {ResponseHandlerOfInventoryPriorityList}
    </>
  );
}

import { COLOR } from "styles/constants";

function getStyledSKUCount({
  currentCount,
  goalCount,
}: {
  currentCount?: number;
  goalCount?: number;
}) {
  if (!goalCount) return;

  const isCompleted = currentCount === goalCount;

  return (
    <div>
      {/* 스캔해야할 수량은 검은색, 스캔한 수량은 스캔해야할 수량과 다르면 빨강, 같으면 녹색 */}
      <span
        style={{ color: isCompleted ? COLOR.pointSuccess : COLOR.pointWarning }}
      >
        {currentCount || 0}
      </span>
      {" / "}
      <span>{goalCount}</span>
    </div>
  );
}

/**
 * SKUId에서 접두어 S(s)를 뺀 결과를 반환
 */
function removeSKUIdPrefix(SKUId: string | number | undefined) {
  if (!SKUId) return SKUId;

  if (typeof SKUId === "number") return SKUId;

  return SKUId.replace(/^s/gi, "");
}

/**
 * group의 SKU ID에서 접두어 G를 뺀 결과를 반환
 */
function removeGroupSkuIdPrefix(skuId: string | number | undefined) {
  if (!skuId) return skuId;

  if (typeof skuId === "number") return skuId;

  return skuId.replace(/^g/gi, "");
}

/**
 * group의 상품명에서 접두어 [그룹]을 뺀 결과를 반환
 */
function removeGroupItemNamePrefix(groupItemName: string | undefined) {
  if (!groupItemName) return "";

  return groupItemName.replace("[그룹] ", "");
}

const checkSkuValue = (value: string | number | undefined) => {
  if (!value) {
    return true;
  }

  const regex = /^(\d{1,3}(,\d{3})*|\d+)(\.\d{0,2})?$/;

  return regex.test(value.toString());
};

export {
  getStyledSKUCount,
  removeSKUIdPrefix,
  removeGroupSkuIdPrefix,
  removeGroupItemNamePrefix,
  checkSkuValue,
};

import { useMemo } from "react";
import { Autocomplete } from "@mui/material";

import TextField from "components/TextField";

import { LocationOption } from "./useLocationOptions";

export default function ItemInputForLocation({
  locationId,
  getLocationOptions,
  setItemLocation,
}: {
  locationId: string | number;
  getLocationOptions: (selectedLocationId: string | number) => LocationOption[];
  setItemLocation: ({
    prevLocationId,
    locationOption,
  }: {
    prevLocationId: string | number;
    locationOption: LocationOption;
  }) => void;
}) {
  const locationOptions = useMemo(() => {
    return getLocationOptions(locationId);
  }, [getLocationOptions, locationId]);

  const selectedLocationOption = useMemo(() => {
    return locationOptions.find((v) => v.id === Number(locationId)) || null;
  }, [locationId, locationOptions]);

  return (
    <>
      <Autocomplete
        key={locationId}
        disablePortal
        id={`select-item-location-${locationId}`}
        options={locationOptions}
        sx={{ width: 220 }}
        renderInput={(params) => {
          return <TextField {...params} />;
        }}
        value={selectedLocationOption}
        onChange={(event, newOption) => {
          if (!newOption) return;

          setItemLocation({
            prevLocationId: locationId,
            locationOption: newOption as LocationOption,
          });
        }}
      />
    </>
  );
}

import { memo } from "react";
import { Checkbox, TableCell } from "@mui/material";

import { KeyType } from "hooks/useMultiSelect";

function CheckboxCell({
  id,

  isChecked,
  select,
  unSelect,
}: {
  id: string | number;

  isChecked: boolean;
  select: (key: KeyType) => void;
  unSelect: (key: KeyType) => void;
}) {
  return (
    <TableCell
      sx={{
        verticalAlign: "middle",

        position: "sticky",
        left: 0,
        zIndex: 1,
        bgcolor: "background.default",
      }}
      align="left"
    >
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
          isChecked ? unSelect(id) : select(id);
        }}
        checked={isChecked}
      />
    </TableCell>
  );
}

export default memo(CheckboxCell);

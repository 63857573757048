import {
  CREATED_FROM_TYPE,
  MALL_OPTION_TYPE,
  SHIPPING_DELIVERY_TYPE,
} from "constants/shipping";

import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UploadFile } from "@mui/icons-material";
import { Button } from "@mui/material";
import { amber, red } from "@mui/material/colors";
import {
  GET_ADMIN_SHIPPING_LIST_REQ_DATE_KIND,
  GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR,
  GET_ADMIN_SHIPPING_LIST_REQ_SEARCH_KIND,
} from "api-interfaces/shipping";
import COMMON_QUERY from "queries/COMMON_QUERY";
import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import useMultiSelect from "hooks/useMultiSelect";
import useQueryString from "hooks/useQueryString";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "hooks/useSearchWithDateForBofulAdminOnly";
import useSearchWithTerm, {
  TermSearchType,
} from "hooks/useSearchWithTermForBufulAdminOnly";
import CancelShipping from "pages/shipping/CancelShipping";
import CompleteShipment from "pages/shipping/CompleteShipment";
import EditDeliveryInfo from "pages/shipping/EditDeliveryInfo";
import PrintInvoice from "pages/shipping/PrintInvoice";
import PrintTransactionStatement from "pages/shipping/PrintTransactionStatement";
import SkuIdSearchOperatorSwitch from "pages/shipping/SkuIdSearchOperatorSwitch";
import UploadKyoungDongShippingInvoiceExcelModal from "pages/shipping/UploadKyoungDongShippingInvoiceExcelModal";
import useCreateFromFilter from "pages/shipping/useCreateFromFilter";
import useIsDelayFilter from "pages/shipping/useIsDelayFilter";
import useIsPackingFilter from "pages/shipping/useIsPackingFilter";
import useIsPickingFilter from "pages/shipping/useIsPickingFilter";
import useIsShipmentFilter from "pages/shipping/useIsShipmentFilter";
import useShippingAdminMemo from "pages/shipping/useShippingAdminMemo";
import useShippingQuantityFilter, {
  checkIsValidShippingQuantityLength,
} from "pages/shipping/useShippingQuantityFilter";
import {
  AdminShippingListTabStatus,
  DeliveryCategory,
  ShippingListItem,
} from "types/shipping";
import { getGroupedSearchListByProperty } from "utils/common";
import { isBeforeToday, toFormattedDate } from "utils/date";
import { getTitleAmongItemListName } from "utils/forwarding";
import {
  getFormattedCustomerAddress,
  getOrderQuantity,
  getPayloadByFilter,
  getShippingQuantityV2,
  getSkuIdListForAdmin,
} from "utils/fulfillment";
import { toParcelCompanyLabel } from "utils/fulfillment";
import { classifyShippingItemsV2, translateSaleChannel } from "utils/shipping";
import { getPhoneNumberWithHyphen } from "utils/string";
import { CellId } from "./types";
import {
  getDefaultData,
  getGroupedHeadRow,
  getGroupedHeadRowIdsToExceptForExcelDownload,
  getHeadCells,
  getInvoiceNoFromShipping,
  getShipmentAt,
  getTabList,
  getTaskDataList,
  makeStatusToGetAdminShippingList,
} from "./utils";

import MemoizedTable, {
  TableBodyRow,
  TableHeadCell,
} from "components/MemoizedTable";

import UploadShippingInvoiceExcelModal from "../../UploadShippingInvoiceExcelModal";
import { DomesticCategory } from "..";
import useDomesticMallFilter from "../useDomesticMallFilter";
import useShippingDetailModal from "../useShippingDetailModal";
import TempTotalPicking from "./TempTotalPicking";
import useDomesticParcelCompanyFilter from "./useDomesticParcelCompanyFilter";
import useDomesticParcelDeliveryTypeFilter from "./useDomesticParcelDeliveryTypeFilter";

const pinnedColumnList = [{ id: "shippingId", prevColumnWidth: 74 }];

const termSearchTypeOptions: TermSearchType<GET_ADMIN_SHIPPING_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "출고요청번호",
      value: "id",
    },
    {
      label: "주문번호",
      value: "orderNo",
    },
    {
      label: "송장번호",
      value: "invoiceNo",
    },
    {
      label: "받는 분 성함",
      value: "customerName",
    },
    {
      label: "받는 분 연락처",
      value: "customerPhone",
    },
    {
      label: "SKU ID",
      value: "skuId",
    },
    {
      label: "상품바코드",
      value: "barCode",
    },
    {
      label: "판매자상품코드",
      value: "productCode",
    },
    {
      label: "옵션관리코드",
      value: "managementCode",
    },
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "상품명",
      value: "itemName",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<GET_ADMIN_SHIPPING_LIST_REQ_DATE_KIND>[] =
  [
    {
      label: "등록일시",
      value: "createdAt",
    },
    {
      label: "발송희망일",
      value: "dueDate",
    },
    {
      label: "발송일시",
      value: "endedPackingAt",
    },
  ];

function ShippingDomesticParcelList({
  deliveryCategory,
}: {
  deliveryCategory: DeliveryCategory;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const { pathname } = useLocation();

  const history = useHistory();
  const {
    addQuery,
    queries: { status, operatorInit, skuIdSearchListInit },
  } = useQueryString<{
    category: DomesticCategory;
    status: AdminShippingListTabStatus;
    operatorInit: GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR;
    skuIdSearchListInit: string;
  }>(history);

  const [operator, setOperator] =
    useState<GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR>(operatorInit || "or");

  const [
    isUploadKyoungDongShippingInvoiceExcelModalOpen,
    setIsUploadKyoungDongShippingInvoiceExcelModalOpen,
  ] = useState(false);

  const [
    isUploadShippingInvoiceExcelModalOpen,
    setIsUploadShippingInvoiceExcelModalOpen,
  ] = useState(false);

  const handleUploadShippingInvoiceListExcelModalOpen = () => {
    setIsUploadShippingInvoiceExcelModalOpen(true);
  };

  const handleUploadShippingInvoiceListExcelModalClose = () => {
    setIsUploadShippingInvoiceExcelModalOpen(false);
  };

  const handleUploadKyoungDongShippingInvoiceListExcelModalOpen = () => {
    setIsUploadKyoungDongShippingInvoiceExcelModalOpen(true);
  };

  const handleUploadKyoungDongShippingInvoiceListExcelModalClose = () => {
    setIsUploadKyoungDongShippingInvoiceExcelModalOpen(false);
  };

  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOperator(e.target.checked ? "and" : "or");
  };

  const {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    selectedIdList,
  } = useMultiSelect();

  const resetCurrentPage = useCallback(() => setCurrentPage(0), []);

  const { TermSearchPanel, searchList } = useSearchWithTerm({
    pageType: "shipping",
    termSearchTypeOptions,
    resetCurrentPage,
    searchListInitialValue: skuIdSearchListInit?.split(",").map((skuId) => {
      return {
        searchKind: "skuId",
        searchKindLabel:
          termSearchTypeOptions.find((v) => v.value === "skuId")?.label || "",
        searchTerm: skuId,
      };
    }),
  });

  const { DateSearchPanel, dateSearchType, dateToSearch } = useSearchWithDate({
    dateSearchTypeOptions: dateSearchTypeOptions,
    resetCurrentPage,
  });

  const {
    DomesticMallFilterPanel,
    domesticMallPayloadListByFilter,
    resetDomesticMallFilter,
  } = useDomesticMallFilter({ resetCurrentPage });
  const {
    CreateFromFilterPanel,
    createFromPayloadListByFilter,
    resetCreateFromFilter,
  } = useCreateFromFilter({ resetCurrentPage });
  const {
    DomesticParcelDeliveryTypeFilterPanel,
    domesticParcelDeliveryTypePayloadListByFilter,
    resetDomesticParcelDeliveryTypeFilter,
  } = useDomesticParcelDeliveryTypeFilter({ resetCurrentPage });
  const {
    DomesticParcelCompanyFilterPanel,
    domesticParcelCompanyPayloadListByFilter,
    resetDomesticParcelCompanyFilter,
  } = useDomesticParcelCompanyFilter({ resetCurrentPage });
  const {
    IsPickingFilterPanel,
    isPickingPayloadListByFilter,
    resetIsPickingFilter,
  } = useIsPickingFilter({ resetCurrentPage });
  const {
    IsPackingFilterPanel,
    isPackingPayloadListByFilter,
    resetIsPackingFilter,
  } = useIsPackingFilter({ resetCurrentPage });
  const { IsDelayFilterPanel, isDelayPayloadListByFilter, resetIsDelayFilter } =
    useIsDelayFilter({
      resetCurrentPage,
    });
  const {
    IsShipmentFilterPanel,
    isShipmentPayloadListByFilter,
    resetIsShipmentFilter,
  } = useIsShipmentFilter({ resetCurrentPage });

  const {
    ShippingQuantityFilterPanel,
    shippingQuantityPayloadByFilter,
    resetShippingQuantityFilter,
  } = useShippingQuantityFilter({ operator, searchList, resetCurrentPage });

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
    resetCurrentPage();
  };

  const { shippingStatus, wmsStatus } = useMemo(() => {
    return makeStatusToGetAdminShippingList(
      status as AdminShippingListTabStatus
    );
  }, [status]);

  const { data: shippingList, ResponseHandler: ResponseHandlerOfShippingList } =
    SHIPPING_QUERY.useGetAdminShippingList({
      pathname,
      perPage,
      page: currentPage,

      deliveryArea: "domestic",
      deliveryCategory,

      shippingStatus,
      wmsStatus,

      enabled: (() => {
        if (operator === "and") {
          // 출고수량 필터링 이후 검색리스트에서 상품만 하나 지우는 경우 API 요청을 막기 위함(400오류 발생)
          if (shippingQuantityPayloadByFilter) {
            return checkIsValidShippingQuantityLength({
              searchList,
              shippingQuantity: shippingQuantityPayloadByFilter,
            });
          }

          return true;
        }

        return true;
      })(),

      operator,

      ...getGroupedSearchListByProperty({ pageType: "shipping", searchList }),

      ...(dateSearchType && dateToSearch?.startDate && dateToSearch?.endDate
        ? {
            dateKind: dateSearchType,
            startDate: dateToSearch.startDate,
            endDate: dateToSearch.endDate,
          }
        : {}),

      ...getPayloadByFilter({
        key: "malls",
        payloadList: domesticMallPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "createdFrom",
        payloadList: createFromPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "deliveryTypes",
        payloadList: domesticParcelDeliveryTypePayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "parcelCompanies",
        payloadList: domesticParcelCompanyPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isPicking",
        payloadList: isPickingPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isPacking",
        payloadList: isPackingPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isShipment",
        payloadList: isShipmentPayloadListByFilter,
      }),
      ...getPayloadByFilter({
        key: "isDelay",
        payloadList: isDelayPayloadListByFilter,
      }),

      ...(shippingQuantityPayloadByFilter
        ? { quantities: shippingQuantityPayloadByFilter }
        : {}),

      onSuccess: (data) => {
        initSelectionDict(data.list.map((item) => item.id));
      },
    });

  const tabList = getTabList({
    status,
    totalShippings: shippingList?.totalShippings,
    total: shippingList?.total,
  });
  const activeTabIndex = tabList.findIndex((v) => v.status === status);

  const { channelDict } = COMMON_QUERY.useGetChannelList();
  const { data: workerList } = COMMON_QUERY.useGetWorkerList();

  const { showAdminMemoModal, ShippingAdminModal } = useShippingAdminMemo();

  // 송장채번을 진행해야하는지 체크 - 의뢰대기 상태에만 송장채번을 진행
  const needsNumberingWhenPrintingInvoice =
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT";

  // 화물택배 > 송장출력 대신 거래명세 출력 시 상태변경이 필요한지 체크 - 의뢰대기 상태에만 상태변경을 진행
  const needsStateUpdateWhenPrintingTransactionStatement =
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT";

  const {
    setAdminShippingDetail,

    showShippingDetailModal,
    ShippingDetailModal,
    ResponseHandlerOfShippingDetail,
  } = useShippingDetailModal({
    status,
    pageCategory: "PARCEL",
    needsStateUpdateWhenPrintingTransactionStatement,
  });

  const groupedHeadRow = useMemo(() => getGroupedHeadRow(status), [status]);

  const headCells: TableHeadCell<CellId>[] = useMemo(
    () =>
      getHeadCells({
        status,
        columnFilter: {
          deliveryType: DomesticParcelDeliveryTypeFilterPanel,
          parcelCompany: DomesticParcelCompanyFilterPanel,
          createFrom: CreateFromFilterPanel,
          saleChannel: DomesticMallFilterPanel,
          isPicking: IsPickingFilterPanel,
          isPacking: IsPackingFilterPanel,
          isDelay: IsDelayFilterPanel,
          isShipment: IsShipmentFilterPanel,
          shippingQuantity: ShippingQuantityFilterPanel,
        },
      }),
    [
      CreateFromFilterPanel,
      DomesticMallFilterPanel,
      DomesticParcelCompanyFilterPanel,
      DomesticParcelDeliveryTypeFilterPanel,
      IsDelayFilterPanel,
      IsPackingFilterPanel,
      IsPickingFilterPanel,
      IsShipmentFilterPanel,
      ShippingQuantityFilterPanel,
      status,
    ]
  );

  const mapDataForTable = useCallback(
    (data: ShippingListItem[] | undefined, isForCSV?: boolean) => {
      if (!data) return [];

      let list = data.map((v) => {
        const classifiedShippingItems = classifyShippingItemsV2({
          items: v.items,
          groupList: v.groupList,
          needsToMerge: true,
        });

        return { ...v, items: classifiedShippingItems };
      });

      if (isForCSV) {
        // CSV다운로드할때는 item별로 row를 분리해해서 표시한다
        const listByItem = list.flatMap((v) => {
          if (!v.items?.length) return [v];

          return v.items.map((item) => {
            return {
              ...v,
              // items에 하나의 item만 오게 함으써 공통 매핑함수를 쓸 수 있게 함.
              items: [item],
            };
          });
        });

        list = listByItem;
      }

      return list.map((v) => {
        const row: TableBodyRow<CellId> = {
          id: v.id,

          /** 기본정보 */
          shippingId: v.id,
          saleChannel: translateSaleChannel(v.saleChannel),
          orderNo: v.orderNo,
          createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD HH:mm"),
          createdFrom: v.createdFrom && CREATED_FROM_TYPE[v.createdFrom],
          ...getDefaultData({ status, shipping: v }),

          /** 상품정보 */
          skuIdList: getSkuIdListForAdmin(v.items),
          orderQuantity: getOrderQuantity(v.items),
          shippingQuantity: getShippingQuantityV2({
            type: "admin",
            items: v.items,
            groupList: v.groupList,
          }),
          itemName: isForCSV
            ? v.items?.[0]?.sku?.itemName ?? ""
            : getTitleAmongItemListName(
                v.items.map((item) => ({
                  name: item.sku?.itemName ?? "",
                })),
                10
              ),

          /** 운송정보 */
          dueDate: toFormattedDate(v.dueDate, "YYYY-MM-DD"),
          ...getShipmentAt({ shipping: v, status }),
          deliveryType: SHIPPING_DELIVERY_TYPE[v.deliveryType],
          parcelCompany: toParcelCompanyLabel(v.parcelCompany),
          invoiceNo: v.invoiceErrorMessage
            ? v.invoiceErrorMessage
            : getInvoiceNoFromShipping({ shipping: v, isForCSV }),

          /** 보내는 분 정보 */
          senderCompanyName: v.team?.company,
          senderTeamName: v.team?.name,
          senderName: v.senderName,
          senderPhone: getPhoneNumberWithHyphen(v.senderPhone),

          /** 받는 분 정보 */
          customerCountryCode: "KR",
          customerName: v.customerName,
          customerPhone: getPhoneNumberWithHyphen(v.customerPhone),
          customerAddress: getFormattedCustomerAddress({
            type: "domestic",
            customerAddress: v.customerAddress,
            customerDetailAddress: v.customerDetailAddress,
          }),
          customerPostalCode: v.customerPostalCode,

          /** 작업현황 */
          ...getTaskDataList({
            status,
            shipping: v,
            workerList,
          }),

          /** 요청사항 */
          memo: v.memo
            ?.filter(({ category }) => category === "customer")
            .map((v) => v.memo)
            .join("\n"),

          /** 메모 */
          adminMemo: (
            <Button
              variant={v.adminMemo?.length ? "contained" : "outlined"}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                showAdminMemoModal(v.id);
              }}
            >
              메모
            </Button>
          ),

          handleRowClick: () => {
            showShippingDetailModal(v.id);
            setAdminShippingDetail(v);
          },

          backgroundColor: (() => {
            if (status === "WAITING_EXPECTED" || status === "WAITING_TODAY") {
              if (v.invoiceErrorMessage) {
                return red[300];
              }
            }

            if (status === "WAITING_TODAY") {
              if (isBeforeToday(v.dueDate)) {
                return amber[500];
              }
            }
          })(),
        };

        return row;
      });
    },
    [
      channelDict,
      status,
      workerList,
      showAdminMemoModal,
      showShippingDetailModal,
      setAdminShippingDetail,
    ]
  );

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(
      shippingList?.list.filter(({ id }) => selectedIdList.includes(id)),
      true
    );
  }, [mapDataForTable, shippingList?.list, selectedIdList]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(shippingList?.list);
  }, [shippingList, mapDataForTable]);

  const isAllFreightDeliveryTypeSelected = useMemo(
    () =>
      selectedIdList.every((id) => {
        const shipping = shippingList?.list.find((v) => v.id === id);

        return shipping?.deliveryType === "freight";
      }),
    [selectedIdList, shippingList?.list]
  );

  const showsTempTotalPickingButton =
    status === "PICKING" || status === "PACKING";

  const showsPrintInvoiceButton =
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT" ||
    status === "PICKING" ||
    status === "PACKING" ||
    status === "SHIPMENT_READY" ||
    status === "SHIPMENT_DONE";

  const showsPrintTransactionStatementButton =
    showsPrintInvoiceButton || status === "DONE";

  const showsCompleteShipmentButton = status === "SHIPMENT_READY";

  const showsCancelShippingButton =
    status === "WAITING_EXPECTED" ||
    status === "WAITING_TODAY" ||
    status === "INSPECT" ||
    status === "PICKING" ||
    status === "PACKING" ||
    status === "SHIPMENT_READY";

  const isKyoungDongSelected = useMemo(
    () =>
      selectedIdList.length > 0 &&
      selectedIdList.every((id) => {
        const shipping = shippingList?.list.find((v) => v.id === id);
        return shipping?.parcelCompany === "kd";
      }),
    [selectedIdList, shippingList?.list]
  );

  const handleTableTabChange = (val: number) => {
    addQuery({ status: tabList[val].status });

    resetCurrentPage();

    unSelectAll();

    resetDomesticMallFilter();
    resetCreateFromFilter();
    resetDomesticParcelDeliveryTypeFilter();
    resetDomesticParcelCompanyFilter();
    resetIsPickingFilter();
    resetIsPackingFilter();
    resetIsShipmentFilter();
    resetIsDelayFilter();
    resetShippingQuantityFilter();
  };

  return (
    <>
      <SkuIdSearchOperatorSwitch
        operator={operator}
        handleOperatorChange={handleOperatorChange}
      />

      <MemoizedTable
        title="출고 리스트"
        groupedHeadRow={groupedHeadRow}
        headCells={headCells}
        rows={rows}
        pinnedColumnList={pinnedColumnList}
        tableTabInfo={{
          activeTabIndex,
          setActiveTabIndex: handleTableTabChange,
          tabList,
        }}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 200, 250, 500, 1000],
          totalCount: shippingList?.total || 0,
          perPage,
          setPerPage: handlePerPageChange,
          currentPage,
          setCurrentPage,
        }}
        csvDownloadForSelectedRowsInfo={{
          filename: `출고 리스트_국내_택배(${toFormattedDate(
            new Date(),
            "YYYY-MM-DD-HHmmss"
          )})`,
          idsToExceptForExcelDownload: ["checkbox", "adminMemo"],
          groupedHeadRowIdsToExceptForExcelDownload:
            getGroupedHeadRowIdsToExceptForExcelDownload(status),
          rowsForCSVDownload,
        }}
        toolbarItems={{
          left: [TermSearchPanel, DateSearchPanel],

          right: [
            <>
              {showsTempTotalPickingButton && (
                <TempTotalPicking
                  key="tempTotalPicking"
                  selectedShippingIdList={selectedIdList}
                  // 필터링된 상황에서만 사용되는 임시 기능이기 때문에 별도의 연산 없이 첫번째 출고 건의 userId를 사용
                  teamId={shippingList?.list?.[0]?.teamId}
                />
              )}
            </>,

            <>
              {isKyoungDongSelected && (
                <Button
                  variant="outlined"
                  key="uploadKyoungdongShippingInvoiceExcel"
                  startIcon={<UploadFile />}
                  onClick={
                    handleUploadKyoungDongShippingInvoiceListExcelModalOpen
                  }
                >
                  경동택배 대량업로드
                </Button>
              )}
            </>,

            <Button
              variant="outlined"
              key="uploadShippingInvoiceExcel"
              startIcon={<UploadFile />}
              onClick={handleUploadShippingInvoiceListExcelModalOpen}
            >
              송장번호 대량업로드
            </Button>,

            <EditDeliveryInfo
              key="editDeliveryInfo"
              status={status}
              pageCategory="PARCEL"
              selectedShippingIds={selectedIdList}
            />,

            <>
              {showsPrintInvoiceButton && (
                <>
                  {/* 화물택배의 송장출력 시에는 버튼 라벨은 송장출력 이지만, 실제 동작은 거래명세서 출력 */}
                  {isAllFreightDeliveryTypeSelected ? (
                    <PrintTransactionStatement
                      isUsedInsteadOfPrintingInvoice
                      selectedShippingIds={selectedIdList}
                      shippingList={shippingList}
                      needsStateUpdateWhenPrintingTransactionStatement={
                        needsStateUpdateWhenPrintingTransactionStatement
                      }
                    />
                  ) : (
                    <PrintInvoice
                      deliveryCategory="domesticParcel"
                      selectedShippingIds={selectedIdList}
                      shippingList={shippingList}
                      needsNumberingWhenPrintingInvoice={
                        needsNumberingWhenPrintingInvoice
                      }
                    />
                  )}
                </>
              )}
            </>,

            <>
              {showsPrintTransactionStatementButton && (
                <PrintTransactionStatement
                  selectedShippingIds={selectedIdList}
                  shippingList={shippingList}
                  needsStateUpdateWhenPrintingTransactionStatement={false}
                />
              )}
            </>,

            <>
              {showsCompleteShipmentButton && (
                <CompleteShipment
                  key="completeShipment"
                  selectedShippingIdList={selectedIdList}
                />
              )}
            </>,
          ],

          rightBottom: [
            <>
              {showsCancelShippingButton && (
                <CancelShipping
                  key="cancelShipping"
                  type="list"
                  selectedShippingIdList={selectedIdList}
                />
              )}
            </>,
          ],
        }}
        selectionDict={selectionDict}
        select={select}
        unSelect={unSelect}
        selectAll={selectAll}
        unSelectAll={unSelectAll}
        selectedCount={selectedCount}
        isAllSelected={isAllSelected}
      />

      {ResponseHandlerOfShippingList}

      {ShippingDetailModal}
      {ResponseHandlerOfShippingDetail}

      {ShippingAdminModal}

      <UploadShippingInvoiceExcelModal
        isOpen={isUploadShippingInvoiceExcelModalOpen}
        onModalClose={handleUploadShippingInvoiceListExcelModalClose}
      />

      <UploadKyoungDongShippingInvoiceExcelModal
        selectedIdList={selectedIdList}
        isOpen={isUploadKyoungDongShippingInvoiceExcelModalOpen}
        onModalClose={handleUploadKyoungDongShippingInvoiceListExcelModalClose}
      />
    </>
  );
}

export default ShippingDomesticParcelList;

import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import TextField from "components/TextField";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import { ShippingDetailStatus } from "types/shipping";

interface UpdateShippingStatusForm {
  shippingId: string;
  status: ShippingDetailStatus;
}

function UpdateShippingStatusDev() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<UpdateShippingStatusForm>({
    mode: "onChange",
    defaultValues: {
      shippingId: "",
      status: ShippingDetailStatus.Reset,
    },
  });

  const {
    mutate: updateShippingStatus,
    ResponseHandler: ResponseHandlerOfUpdateShippingStatus,
  } = SHIPPING_QUERY.useUpdateShippingStatus({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        reset();
      },
      customizeMessage: () => ({
        messageType: "titleAndBody",
        title: "출고 상태 수정이 완료되었습니다.",
      }),
    },
  });

  const handleUpdateShippingStatus = ({
    shippingId,
    status,
  }: UpdateShippingStatusForm) => {
    updateShippingStatus({ shippingId: Number(shippingId), status: status });
  };

  const shippingStatusOptions = [
    { value: "reset", label: "초기화" },
    { value: "error", label: "에러발생" },
    { value: "print", label: "운송장 출력(거래명세서 출력)" },
    { value: "pickingStart", label: "피킹시작" },
    { value: "pickingEnd", label: "피킹종료" },
    { value: "packingStart", label: "패킹시작" },
    { value: "packingEnd", label: "패킹종료(출하준비)" },
    { value: "completeShipment", label: "출하완료" },
    { value: "delivering", label: "배송중" },
    { value: "done", label: "배송 완료" },
    { value: "cancel", label: "취소" },
  ];

  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "300px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          출고 상태 변경
        </Typography>

        <form onSubmit={handleSubmit(handleUpdateShippingStatus)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="status"
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} label="출고 상태" error={!!errors.status}>
                  {shippingStatusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <Controller
              control={control}
              name="shippingId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="출고요청번호"
                  error={!!errors.shippingId}
                  helperText={errors.shippingId && "숫자만 입력해주세요."}
                />
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandlerOfUpdateShippingStatus}
    </Layout>
  );
}

export default withRequireAuth(UpdateShippingStatusDev);

import { TableHeadCell } from "components/Table";
import { CellId } from "./types";

const HeadCell: Array<TableHeadCell<CellId>> = [
  {
    id: "no",
    disablePadding: false,
    label: "항목",
    width: 60,
  },
  {
    id: "skuId",
    disablePadding: false,
    label: "SKU ID",
    width: 100,
  },
  {
    id: "quantity",
    disablePadding: false,
    label: "수량",
    width: 60,
  },
  {
    id: "itemName",
    disablePadding: false,
    label: "상품명",
    width: 180,
    maxContentLength: 10,
  },
  {
    id: "amount",
    disablePadding: false,
    label: "상품종류(SKU)",
    width: 60,
  },
  {
    id: "count",
    disablePadding: false,
    label: "주문수",
    width: 60,
  },
];

export { HeadCell };

import {
  useAppMutation,
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
} from "services/query";

import { ResponseFailureInfoOfDeleteItems } from "types/fulfillment";
import { MaterialItem } from "types/fulfillment";

import { QueryResponseHandlerFailureModalInfo } from "components/sds-v1/QueryResponseHandler";

import {
  POST_MATERIAL_ITEM_REQ,
  UPDATE_MATERIAL_DIMENSION_REQ,
  UPDATE_MATERIAL_DIMENSION_RES,
  UPDATE_MATERIAL_ITEM_REQ,
} from "api-interfaces/fulfillment";
import {
  DELETE_MATERIAL_ITEM_REQ,
  DELETE_MATERIAL_ITEM_RES,
  GET_MANAGER_MATERIAL_LIST_REQ,
  GET_MANAGER_MATERIAL_LIST_RES,
  GET_MATERIAL_DETAIL_REQ,
  GET_MATERIAL_DETAIL_RES,
  GET_MATERIAL_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_MATERIAL_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_MATERIAL_LIST_FOR_TOTAL_PICKING_REQ_PATH_PARAMS,
  GET_MATERIAL_LIST_FOR_TOTAL_PICKING_RES,
  GET_MATERIAL_LIST_REQ,
  GET_MATERIAL_LIST_RES,
  GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ,
  GET_MATERIAL_SEARCH_SUGGESTION_LIST_RES,
  GET_USER_MATERIAL_LIST_REQ,
  GET_USER_MATERIAL_LIST_RES,
  UPLOAD_MATERIAL_EXCEL_REQ,
  UPLOAD_MATERIAL_EXCEL_RES,
  VALIDATE_MATERIAL_EXCEL_REQ,
  VALIDATE_MATERIAL_EXCEL_RES,
} from "api-interfaces/material";

export const MATERIAL_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/MATERIAL_QUERY" }] as const,
  materialList: () =>
    [{ ...MATERIAL_QUERY_KEY_GEN.all()[0], subScope: "materialList" }] as const,
  // TODO: 타입 수정
  getMaterialList: (params: GET_MATERIAL_LIST_REQ) =>
    [
      {
        ...MATERIAL_QUERY_KEY_GEN.materialList()[0],
        ...params,
        entity: "getMaterialList",
      },
    ] as const,

  getUserMaterialList: (params: GET_USER_MATERIAL_LIST_REQ) =>
    [
      {
        ...MATERIAL_QUERY_KEY_GEN.materialList()[0],
        ...params,
        entity: "getUserMaterialList",
      },
    ] as const,

  getMaterialListForCSVDownload: (
    params: Omit<GET_MATERIAL_LIST_REQ, "page" | "perPage">
  ) =>
    [
      {
        ...MATERIAL_QUERY_KEY_GEN.materialList()[0],
        ...params,
        entity: "getMaterialListForCSVDownload",
      },
    ] as const,

  materialDetail: () =>
    [
      { ...MATERIAL_QUERY_KEY_GEN.all()[0], subScope: "materialDetail" },
    ] as const,
  getMaterialDetail: (params: GET_MATERIAL_DETAIL_REQ) =>
    [
      {
        ...MATERIAL_QUERY_KEY_GEN.materialDetail()[0],
        ...params,
      },
    ] as const,

  getManagerMaterialList: (params: GET_MANAGER_MATERIAL_LIST_REQ) =>
    [
      {
        ...MATERIAL_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "managerMaterialList",
      },
    ] as const,

  getMaterialSearchSuggestionList: (
    params: GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ
  ) =>
    [
      {
        ...MATERIAL_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "materialSearchSuggestionList",
      },
    ] as const,

  getMaterialListForTotalPicking: (
    params: GET_MATERIAL_LIST_FOR_TOTAL_PICKING_REQ_PATH_PARAMS
  ) =>
    [
      {
        ...MATERIAL_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "materialListForTotalPicking",
      },
    ] as const,
};

function useGetMaterialDetail({
  materialId,
  enabled = true,
  onSuccess,
}: {
  materialId: number;
  enabled?: boolean;
  onSuccess?: (data: GET_MATERIAL_DETAIL_RES) => void;
}) {
  const queryResult = useAppQuery<GET_MATERIAL_DETAIL_RES>({
    queryKey: MATERIAL_QUERY_KEY_GEN.getMaterialDetail({
      materialId,
    }),

    requestOptions: {
      method: "get",
      path: `/material/${materialId}`,
      apiType: "BofulDefault",
    },

    enabled,

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `SKU 정보 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetMaterialList(params: GET_MATERIAL_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof MATERIAL_QUERY_KEY_GEN.getMaterialList>,
    GET_MATERIAL_LIST_RES
  >({
    queryKey: MATERIAL_QUERY_KEY_GEN.getMaterialList(params),

    requestOptions: {
      method: "get",
      path: "/material",
      apiType: "BofulDefault",
      params: {
        ...params,
        provider: "customer",
      },
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `부자재 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetUserMaterialList({
  onSuccess,
  ...params
}: GET_USER_MATERIAL_LIST_REQ & {
  onSuccess?: (data: GET_USER_MATERIAL_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof MATERIAL_QUERY_KEY_GEN.getUserMaterialList>,
    GET_USER_MATERIAL_LIST_RES
  >({
    queryKey: MATERIAL_QUERY_KEY_GEN.getUserMaterialList(params),
    requestOptions: {
      method: "GET",
      path: "/material/user",
      apiType: "BofulDefault",
      params,
    },
    onSuccess,
  });

  return { ...queryResult };
}

function useGetMaterialListForCSVDownload({
  enabled,
  ...params
}: GET_MATERIAL_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof MATERIAL_QUERY_KEY_GEN.getMaterialListForCSVDownload>,
    GET_MATERIAL_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey: MATERIAL_QUERY_KEY_GEN.getMaterialListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/material",
      apiType: "BofulDefault",
      params: {
        ...params,
        provider: "customer",
      },
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useAddMaterialItem() {
  const mutation = useAppMutation<POST_MATERIAL_ITEM_REQ, MaterialItem>({
    requestOptions: {
      method: "post",
      path: `/material`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "부자재 등록 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useUpdateMaterialItem({
  materialId,
  failureInfoTitle,
  failureInfoBody,
}: {
  materialId: number;
  failureInfoTitle: React.ReactNode;
  failureInfoBody: (
    failureInfo: ResponseFailureInfoOfDeleteItems
  ) => React.ReactNode;
}) {
  const mutation = useAppMutation<UPDATE_MATERIAL_ITEM_REQ, MaterialItem>({
    requestOptions: {
      method: "put",
      path: `/material/${materialId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 422) {
          return {
            title: failureInfoTitle,
            body: failureInfoBody(failureInfo),
            messageType: "titleAndBody",
          };
        }

        return { title: "부자재 수정 중 오류가 발생했습니다." };
      },
    },
  });

  return { ...mutation };
}

function useDeleteMaterialItem({
  failureInfoTitle,
  failureInfoBody,
}: {
  failureInfoTitle: React.ReactNode;
  failureInfoBody: (
    failureInfo: ResponseFailureInfoOfDeleteItems
  ) => React.ReactNode;
}) {
  const mutation = useAppMutation<
    DELETE_MATERIAL_ITEM_REQ,
    DELETE_MATERIAL_ITEM_RES
  >({
    requestOptions: {
      method: "delete",
      path: `/material`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 422)
          return {
            title: failureInfoTitle,
            body: failureInfoBody(failureInfo),
            messageType: "titleAndBody",
          };

        return { title: "부자재 삭제 중 오류가 발생했습니다." };
      },
    },
  });

  return { ...mutation };
}

function useGetManagerMaterialList({
  onSuccess,
  ...params
}: GET_MANAGER_MATERIAL_LIST_REQ & {
  onSuccess?: (data: GET_MANAGER_MATERIAL_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof MATERIAL_QUERY_KEY_GEN.getManagerMaterialList>,
    GET_MANAGER_MATERIAL_LIST_RES
  >({
    queryKey: MATERIAL_QUERY_KEY_GEN.getManagerMaterialList(params),
    requestOptions: {
      method: "get",
      path: "/material/manager/v2?provider=customer",
      apiType: "BofulDefault",
      params,
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: `부자재 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },

    onSuccess,
  });

  return { ...queryResult };
}

function useGetMaterialSearchSuggestionList({
  enabled,
  ...params
}: {
  enabled?: boolean;
} & GET_MATERIAL_SEARCH_SUGGESTION_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof MATERIAL_QUERY_KEY_GEN.getMaterialSearchSuggestionList>,
    GET_MATERIAL_SEARCH_SUGGESTION_LIST_RES
  >({
    queryKey: MATERIAL_QUERY_KEY_GEN.getMaterialSearchSuggestionList(params),
    requestOptions: {
      method: "get",
      path: "/search/suggestion/material",
      apiType: "BofulDefault",
      params,
    },

    enabled,
  });

  return { ...queryResult };
}

function useGetMaterialListForTotalPicking({
  teamId,
  enabled,
}: { enabled: boolean } & GET_MATERIAL_LIST_FOR_TOTAL_PICKING_REQ_PATH_PARAMS) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof MATERIAL_QUERY_KEY_GEN.getMaterialListForTotalPicking>,
    GET_MATERIAL_LIST_FOR_TOTAL_PICKING_RES
  >({
    queryKey: MATERIAL_QUERY_KEY_GEN.getMaterialListForTotalPicking({
      teamId,
    }),
    requestOptions: {
      method: "get",
      path: `/material/total-picking/${teamId}`,
      apiType: "BofulDefault",
    },

    enabled,
  });

  return queryResult;
}

function useUpdateMaterialDimension({ materialId }: { materialId: number }) {
  const mutation = useAppMutation<
    UPDATE_MATERIAL_DIMENSION_REQ,
    UPDATE_MATERIAL_DIMENSION_RES
  >({
    requestOptions: {
      method: "put",
      path: `/material/${materialId}/master`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "부자재 Dimension 수정 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useValidateMaterialExcel({
  failureModalInfo,
}: {
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    VALIDATE_MATERIAL_EXCEL_REQ,
    VALIDATE_MATERIAL_EXCEL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/material/excel/validate",
      apiType: "BofulDefault",
    },

    failureModalInfo,
  });

  return mutation;
}

function useUploadMaterialExcel() {
  const mutation = useAppMutation<
    UPLOAD_MATERIAL_EXCEL_REQ,
    UPLOAD_MATERIAL_EXCEL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/material/excel/save",
      apiType: "BofulDefault",
    },
  });

  return mutation;
}

const MATERIAL_QUERY = {
  useGetMaterialDetail,
  useGetMaterialList,
  useGetUserMaterialList,
  useGetMaterialListForCSVDownload,

  useAddMaterialItem,
  useUpdateMaterialItem,
  useDeleteMaterialItem,

  useGetManagerMaterialList,

  useGetMaterialSearchSuggestionList,

  useGetMaterialListForTotalPicking,

  useUpdateMaterialDimension,

  useValidateMaterialExcel,
  useUploadMaterialExcel,
};

export default MATERIAL_QUERY;

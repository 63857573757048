import { Controller, useForm } from "react-hook-form";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";

import INVENTORY_QUERY from "queries/INVENTORY_QUERY";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

interface ForceCreateInventoryForm {
  barCode: string;
  skuId: string;
  qty: string;
}

function UtilityForceCreateInventory() {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ForceCreateInventoryForm>({
    mode: "onChange",
    defaultValues: {
      barCode: "",
      skuId: "",
      qty: "",
    },
  });

  const {
    mutate: forceCreateInventory,
    ResponseHandler: ResponseHandlerOfForceCreateInventory,
  } = INVENTORY_QUERY.useForceCreateInventory();

  const handleForceCreateInventory = ({
    barCode,
    skuId,
    qty,
  }: ForceCreateInventoryForm) => {
    forceCreateInventory(
      {
        barCode,
        skuId: Number(skuId),
        qty: Number(qty),
      },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <Layout>
      <Paper
        style={{
          width: 300,
          padding: 20,
        }}
      >
        <Typography variant="h6" style={{ marginBottom: 20 }}>
          유통 상품 재고 등록
        </Typography>

        <form onSubmit={handleSubmit(handleForceCreateInventory)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="barCode"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="로케이션 바코드"
                  error={!!errors.barCode}
                  helperText={errors.barCode && "필수값입니다."}
                />
              )}
            />

            <Controller
              control={control}
              name="skuId"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="SKU ID"
                  error={!!errors.skuId}
                  helperText={errors.skuId && "숫자만 입력해주세요."}
                />
              )}
            />

            <Controller
              control={control}
              name="qty"
              rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="수량"
                  error={!!errors.qty}
                  helperText={errors.qty && "숫자만 입력해주세요."}
                />
              )}
            />

            <Button type="submit" variant="contained" disabled={!isValid}>
              확인
            </Button>
          </Stack>
        </form>
      </Paper>

      {ResponseHandlerOfForceCreateInventory}
    </Layout>
  );
}

export default withRequireAuth(UtilityForceCreateInventory);

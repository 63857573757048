import { useState } from "react";

import usePrevious from "hooks/usePrevious";

import Button from "components/sds-v1/button/Button";

import InputText from "../InputText";
import Styled from "./index.styles";

export default function InputTextWithSearch({
  handleSearch,
  searchTerm,
  resetSearch,
  placeholder,
  className,
}: {
  handleSearch: (val: string) => void;
  searchTerm: string | undefined;
  resetSearch: () => void;
  placeholder?: string;
  className?: string;
}) {
  // const { t } = getI18nInstanceForDesignSystem();

  const [localSearchTerm, setLocalSearchTerm] = useState<string>(
    searchTerm || ""
  );

  const previousSearchTerm = usePrevious(searchTerm);
  const previousLocalSearchTerm = usePrevious(localSearchTerm);

  const isLocalSearchTermChanged = localSearchTerm !== previousLocalSearchTerm;

  const isSearchTermChanged = searchTerm !== previousSearchTerm;

  // searchTerm이 초기화 되는 경우 localSearchTerm에 반영
  if (
    isSearchTermChanged &&
    !isLocalSearchTermChanged &&
    localSearchTerm !== searchTerm
  ) {
    setLocalSearchTerm(searchTerm || "");
  }

  return (
    <Styled.container
      className={`${className ? className : ""} input-text-with-search`}
    >
      <InputText
        borderType="outline"
        valueType="string"
        placeholder={placeholder || "검색해주세요."}
        icon="search"
        value={localSearchTerm}
        setValue={(v) => setLocalSearchTerm(v)}
        clearCB={resetSearch}
        handleEnter={() => handleSearch(localSearchTerm)}
      />

      <Button
        theme="primary"
        size="input"
        label={"검색"}
        handleClick={() => handleSearch(localSearchTerm)}
        disabled={!localSearchTerm}
      />
    </Styled.container>
  );
}

import { useState } from "react";
import { useQueryClient } from "react-query";
import { Warehouse } from "@mui/icons-material";
import { Button } from "@mui/material";

import { COMPLETE_SHIPMENT_RES } from "api-interfaces/shipping";
import SHIPPING_QUERY, { SHIPPING_QUERY_KEY_GEN } from "queries/SHIPPING_QUERY";
import { toThousandUnitFormat } from "utils/number";
import ConfirmModal from "components/ConfirmModal";

import CompleteShipmentResponseModal from "./CompleteShipmentResponseModal";

export default function CompleteShipment({
  selectedShippingIdList,
}: {
  selectedShippingIdList: number[];
}) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [completeShipmentResponse, setCompleteShipmentResponse] =
    useState<COMPLETE_SHIPMENT_RES>();

  const canCompleteShipment = !!selectedShippingIdList.length;

  const {
    mutate: completeShipment,
    ResponseHandler: ResponseHandlerOfCompleteShipment,
  } = SHIPPING_QUERY.useCompleteShipment();

  const queryClient = useQueryClient();

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleShippingComplete = () => {
    completeShipment(
      { ids: selectedShippingIdList },
      {
        onSuccess: ({ data }) => {
          setCompleteShipmentResponse(data);

          handleConfirmModalClose();
        },
      }
    );
  };

  const handleCompleteShipmentResponseClose = () => {
    setCompleteShipmentResponse(undefined);

    queryClient.invalidateQueries(
      SHIPPING_QUERY_KEY_GEN.getAdminShippingList({})
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Warehouse />}
        onClick={handleConfirmModalOpen}
        disabled={!canCompleteShipment}
      >
        출하완료
      </Button>

      <ConfirmModal
        isConfirmModalOpen={isConfirmModalOpen}
        onConfirmModalClose={handleConfirmModalClose}
        title={`출고 ${toThousandUnitFormat(selectedShippingIdList.length)}건을
            출하완료로 설정하시겠습니까?`}
        actionPositive={{
          label: "설정하기",
          onClick: handleShippingComplete,
        }}
      />

      <CompleteShipmentResponseModal
        completeShipmentResponse={completeShipmentResponse}
        handleCompleteShipmentResponseClose={
          handleCompleteShipmentResponseClose
        }
      />

      {ResponseHandlerOfCompleteShipment}
    </>
  );
}

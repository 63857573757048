import { useMemo, useState } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

import { ReceivingMemo } from "types/receiving";
import { toFormattedDate } from "utils/date";
import Modal from "components/Modal";

export default function useMemoModal() {
  const [memoList, setMemoList] = useState<ReceivingMemo[]>();

  const MemoModal = useMemo(() => {
    if (!memoList) return null;

    return (
      <Modal
        isOpened
        handleClose={() => setMemoList(undefined)}
        modalBody={
          <>
            {memoList.map((v, i) => {
              return (
                <Card key={i} sx={{ minWidth: 200, marginBottom: "10px" }}>
                  <CardHeader
                    title={toFormattedDate(v.createdAt, "YYYY-MM-DD HH:mm")}
                  />
                  <CardContent>{v.memo}</CardContent>
                </Card>
              );
            })}
          </>
        }
      />
    );
  }, [memoList]);

  return {
    setMemoModalData: setMemoList,
    MemoModal,
  };
}

import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const filterList = styled.div`
  display: flex;

  .filter {
    margin-right: 16px;
  }
`;

const excelUploadDesc = styled.div`
  margin-top: 16px;
  ${setFontStyle("Body6")};
  color: ${COLOR.pointWarning};
`;

export default {
  filterList,
  excelUploadDesc,
};

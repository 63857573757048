import { ReactNode, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import RETURNING_QUERY, {
  RETURNING_QUERY_KEY_GEN,
} from "queries/RETURNING_QUERY";
import { ReturningListItem, ReturningPacking } from "types/returning";
import {
  isSameOrBeforeEndOfDate,
  toFormattedDate,
  toFormattedDateWithoutLocalTime,
} from "utils/date";
import { toParcelCompanyLabel } from "utils/fulfillment";
import ConfirmModal from "components/ConfirmModal";
import Table, { TableBodyRow, TableHeadCell } from "components/Table";

type CellId =
  | Extract<
      keyof ReturningPacking,
      "parcelCompany" | "invoiceNo" | "createdAt" | "issuedAt"
    >
  | "invoiceStatus"
  | "cancel";

const headCellList: TableHeadCell<CellId>[] = [
  {
    id: "parcelCompany",
    disablePadding: false,
    label: "택배사",
    width: 100,
  },
  {
    id: "invoiceNo",
    disablePadding: false,
    label: "반송장번호",
    width: 160,
  },
  {
    id: "invoiceStatus",
    disablePadding: false,
    label: "상태",
    width: 80,
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "신청일시",
    width: 150,
  },
  {
    id: "issuedAt",
    disablePadding: false,
    label: "발급일시",
    width: 150,
  },
  {
    id: "cancel",
    disablePadding: false,
    label: "취소",
    width: 80,
  },
];

type ConfirmModalType = "add" | "overCount" | "cancel";

const CONFIRM_MODAL_TITLE_RECORD: Record<ConfirmModalType, ReactNode> = {
  add: "발송택배사에 반송장을 신청하시겠습니까?",
  cancel: "신청한 반송장을 취소하시겠습니까?",
  overCount: (
    <>
      <Typography sx={{ color: red[800] }}>
        반송장 신청개수가 원송장의 개수를 초과하였습니다.
      </Typography>
      발송택배사에 반송장을 신청하시겠습니까?
    </>
  ),
};

export default function AddReturningInvoice({
  returningDetailData,
  showsAddReturningInvoiceModal,
  onAddReturningInvoiceModalClose,
}: {
  returningDetailData: ReturningListItem | undefined;
  showsAddReturningInvoiceModal: boolean;
  onAddReturningInvoiceModalClose: () => void;
}) {
  const [confirmModal, setConfirmModal] = useState<{
    type: ConfirmModalType;
    title: ReactNode;
    /** 취소 시 활용 */
    packingId?: number;
  }>();

  const queryClient = useQueryClient();

  const {
    mutate: addReturningInvoice,
    ResponseHandler: ResponseHandlerOfAddReturningInvoice,
  } = RETURNING_QUERY.useAddReturningInvoice({
    returningId: returningDetailData?.id,
  });

  const {
    mutate: cancelReturningInvoice,
    ResponseHandler: ResponseHandlerOfCancelReturningInvoice,
  } = RETURNING_QUERY.useCancelReturningInvoice({
    returningId: returningDetailData?.id,
  });

  const setConfirmModalData = (type: ConfirmModalType, packingId?: number) => {
    setConfirmModal({
      type,
      title: CONFIRM_MODAL_TITLE_RECORD[type],
      packingId,
    });
  };

  const handleConfirmModalClose = () => {
    setConfirmModal(undefined);
  };

  const handleAddConfirmModalOpen = () => {
    const shippingPackingsCount = (
      returningDetailData?.shipping?.packings ?? []
    ).length;
    const returningPackingsCount = (
      returningDetailData?.returningPackings ?? []
    ).length;
    if (shippingPackingsCount <= returningPackingsCount) {
      setConfirmModalData("overCount");
      return;
    }

    setConfirmModalData("add");
  };

  const handleCancelConfirmModalOpen = (packingId: number) => () => {
    setConfirmModalData("cancel", packingId);
  };

  const handleAddOrCancelSuccess = (returningId: number) => {
    queryClient
      .invalidateQueries(RETURNING_QUERY_KEY_GEN.adminReturning())
      .then(handleConfirmModalClose);
  };

  const handleReturningInvoiceAdd = () => {
    if (!returningDetailData?.id) {
      return;
    }

    addReturningInvoice(undefined, {
      onSuccess: () => handleAddOrCancelSuccess(returningDetailData.id),
    });
  };

  const handleReturningInvoiceCancel = () => {
    if (!returningDetailData?.id || !confirmModal?.packingId) {
      return;
    }

    cancelReturningInvoice(
      { pathParams: { packingId: confirmModal.packingId } },
      { onSuccess: () => handleAddOrCancelSuccess(returningDetailData.id) }
    );
  };

  const mapDataForTable = (
    data: ReturningPacking[] | undefined
  ): TableBodyRow<CellId>[] => {
    if (!data) {
      return [];
    }

    return data.map(
      ({ id, parcelCompany, invoiceNo, createdAt, issuedAt }, index) => {
        const isIssued = !!issuedAt;
        /** 반송장은 신청 당일 자정까지만 취소가 가능 */
        const canCancel = !issuedAt && isSameOrBeforeEndOfDate(createdAt);

        return {
          parcelCompany: toParcelCompanyLabel(parcelCompany),
          invoiceNo: invoiceNo ?? "-",
          invoiceStatus: issuedAt ? "발급완료" : "발급대기",
          createdAt: toFormattedDate(createdAt, "YYYY-MM-DD HH:mm"),
          issuedAt:
            toFormattedDateWithoutLocalTime(issuedAt, "YYYY-MM-DD HH:mm") ||
            "-",
          cancel: isIssued ? (
            "-"
          ) : (
            <Button
              onClick={handleCancelConfirmModalOpen(id)}
              disabled={!canCancel}
            >
              취소
            </Button>
          ),
        };
      }
    );
  };

  const rowList = mapDataForTable(returningDetailData?.returningPackings);

  return (
    <>
      <Dialog
        open={showsAddReturningInvoiceModal}
        maxWidth={false}
        onClose={onAddReturningInvoiceModalClose}
      >
        <DialogTitle sx={{ justifyContent: "space-between" }}>
          반송장 신청
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="contained" onClick={handleAddConfirmModalOpen}>
              반송장 신청
            </Button>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <Table headCells={headCellList} rows={rowList} />
        </DialogContent>

        <DialogActions sx={{ alignSelf: "center" }}>
          <Button variant="contained" onClick={onAddReturningInvoiceModalClose}>
            확인
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmModal
        isConfirmModalOpen={!!confirmModal}
        title={confirmModal?.title}
        onConfirmModalClose={handleConfirmModalClose}
        actionPositive={{
          label: "확인",
          onClick:
            confirmModal?.type === "cancel"
              ? handleReturningInvoiceCancel
              : handleReturningInvoiceAdd,
        }}
        actionNegative={{
          label: "취소",
          onClick: handleConfirmModalClose,
        }}
      />

      {ResponseHandlerOfAddReturningInvoice}
      {ResponseHandlerOfCancelReturningInvoice}
    </>
  );
}

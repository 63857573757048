import styled from "styled-components";

const metaInfoOnItemInfo = styled.div`
  > .item {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 4px 0;

    > .label {
      min-width: 100px;
      font-weight: bold;
    }
  }
`;

export default {
  metaInfoOnItemInfo,
};

import styled, { css } from "styled-components";
import { COLOR } from "styles/constants";
import { mobile } from "styles/functions";

const container = styled.div`
  width: 100%;
`;

const img = styled.div`
  text-align: center;
  padding-top: 100px;
  padding-bottom: 80px;

  ${mobile(css`
    padding-top: 60px;
    padding-bottom: 24px;
  `)}

  > img {
    width: 200px;
    height: 200px;

    ${mobile(css`
      width: 96px;
      height: 96px;
    `)}
  }
`;

const textGroup = styled.div`
  text-align: center;
  color: ${COLOR.grayScale_500};
  padding-bottom: 48px;

  ${mobile(css`
    padding-bottom: 40px;
  `)}
`;

const backAction = styled.div`
  padding-bottom: 120px;
  text-align: center;

  ${mobile(css`
    padding-bottom: 0px;
  `)}
`;

export default {
  container,
  img,
  textGroup,
  backAction,
};

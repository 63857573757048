/**
 * 보통은 dev환경일때 process.env.NODE_ENV을 체크하는 것으로 하지만,
 * dev도 배포환경에서는 process.env.NODE_ENV가 production으로 나오므로 (production빌드 결과가 실행되는 것이므로)
 * 혼돈을 방지하고자 이런 상수를 만듦
 */
const IS_UNDER_LOCAL_DEVELOPMENT = process.env.NODE_ENV !== "production";

/**
 * Production 배포 환경인지 확인
 */
const IS_UNDER_PRODUCTION = (() => {
  return (
    process.env.REACT_APP_ENV === "production" ||
    process.env.NEXT_PUBLIC_ENV === "production" ||
    process.env.STORYBOOK_ENV === "production"
  );
})();

/**
 * 앱의 빌드 정보.
 * 빌드 설정에 빌드정보를 추가하는 기능이 없으면 null이 반환됨.
 *
 * TODO: 이후에 version 정보도 추가하기
 * - git tag정보로 버전을 가져와야하는데 현재 vercel에서 git tag에 대한 환경변수 지원은 안 하고 있음. 향후 지원될 것으로 보이긴 함
 * - 참고: AB7910, https://github.com/orgs/vercel/discussions/166
 */
const APP_BUILD_INFO: AppBuildInfo | null = (() => {
  const rawInfo =
    process.env.REACT_APP_APP_BUILD_INFO ||
    process.env.NEXT_PUBLIC_APP_BUILD_INFO;

  return rawInfo ? (JSON.parse(rawInfo) as AppBuildInfo) : null;
})();

type AppBuildInfo = {
  /** 빌드된 시각 (ISO 포맷) */
  builtAt: string;
  gitBranch: string;
  gitCommitSha: string;
};

const IS_ON_BROWSER = typeof window !== "undefined";

const SHIPDA_API_URL = "https://api-v2-dev.ship-da.com";
const SHIPDA_API_NEW_URL = "https://api-dev.ship-da.com";
const SHIPDA_ADMIN_API_URL = "https://admin-api-dev.ship-da.com";
const BOFUL_API_URL = "https://boful-api-dev.ship-da.com";
const CONTENTS_API_URL = "https://contents-api-v2-dev.ship-da.com";
const LOCAL_PRINTER_URL = "http://localhost:1457";
const NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL =
  "http://192.168.60.201:9100";
const NETWORK_PRINTER_FOR_CJ_URL = "http://localhost:18080/print";
const BOFUL_DASHBOARD_API_URL = "https://boful-dashboard-dev.ship-da.com";

const LOCAL_PRINTER_FIRST_URL = "192.168.60.231:9100";
const LOCAL_PRINTER_SECOND_URL = "192.168.60.232:9100";

export type { AppBuildInfo };

/**
 * UNIX TIME 기준 년도
 */
const UNIX_TIME_YEAR = 1970;

export {
  IS_UNDER_LOCAL_DEVELOPMENT,
  IS_UNDER_PRODUCTION,
  APP_BUILD_INFO,
  IS_ON_BROWSER,
  SHIPDA_API_URL,
  SHIPDA_API_NEW_URL,
  SHIPDA_ADMIN_API_URL,
  BOFUL_API_URL,
  CONTENTS_API_URL,
  LOCAL_PRINTER_URL,
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
  NETWORK_PRINTER_FOR_CJ_URL,
  BOFUL_DASHBOARD_API_URL,
  UNIX_TIME_YEAR,
  LOCAL_PRINTER_FIRST_URL,
  LOCAL_PRINTER_SECOND_URL,
};

import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";

import { ShippingListForPrinting } from ".";
import usePrintTransactionStatement from "./usePrintTransactionStatement";

export default function useUpdateStatusAndPrintTransactionStatement({
  shippings,
  onModalClose,
}: {
  shippings: ShippingListForPrinting | undefined;
  onModalClose: () => void;
}) {
  const [isModalOpen, setIsOpenedModalToUpdateStatus] = useState(false);

  const shippingIdsToUpdateStatus = useMemo(() => {
    if (!shippings?.length) {
      return;
    }

    return shippings.map((v) => v.id);
  }, [shippings]);

  const {
    mutate: updateStatus,
    ResponseHandler: ResponseHandlerOfUpdateStatus,
    data: resultOfUpdateStatus,
    reset: resetUpdateStatus,
    isLoading: isLoadingOfUpdateStatus,
    status: statusOfUpdateStatus,
  } = SHIPPING_QUERY.useUpdateStatusWhenPrintingTransactionStatement();

  const shippingsToPrint = useMemo(() => {
    if (!shippings?.length) {
      return [];
    }

    if (statusOfUpdateStatus !== "success") {
      return [];
    }

    if (resultOfUpdateStatus?.fail.ids?.length) {
      return shippings.filter(
        (shipping) => !resultOfUpdateStatus.fail.ids.includes(shipping.id)
      );
    }

    return shippings;
  }, [shippings, statusOfUpdateStatus, resultOfUpdateStatus?.fail.ids]);

  const { printTransactionStatement, canPrint } = usePrintTransactionStatement({
    shippingsToPrint,
  });

  const canUpdateStatus = !!shippingIdsToUpdateStatus?.length;

  const updateStatusAndPrintTransactionStatement = useCallback(() => {
    if (!canUpdateStatus || !shippingIdsToUpdateStatus) {
      return;
    }

    setIsOpenedModalToUpdateStatus(true);

    updateStatus(shippingIdsToUpdateStatus);
  }, [canUpdateStatus, shippingIdsToUpdateStatus, updateStatus]);

  const handleModalClose = useCallback(() => {
    setIsOpenedModalToUpdateStatus(false);
    onModalClose();
    resetUpdateStatus();
  }, [onModalClose, resetUpdateStatus]);

  const UpdateStatusInfo = useMemo(() => {
    const isLoading =
      isLoadingOfUpdateStatus || statusOfUpdateStatus !== "success";

    return (
      <>
        {isLoading && (
          <>
            <Grid item xs={12} textAlign="center">
              거래명세서 생성 중…
            </Grid>

            <Grid item xs={12} textAlign="center" marginBottom={4}>
              <CircularProgress size={140} color="primary" />
            </Grid>
          </>
        )}

        {statusOfUpdateStatus === "success" && (
          <>
            <Grid item xs={12} textAlign="center">
              거래명세서 생성이 완료되었습니다.
            </Grid>

            <Grid item xs={12} marginTop={1}>
              <Paper
                sx={{ padding: 2, backgroundColor: blue[50], lineHeight: 2 }}
              >
                • 선택한 출고 수 : {resultOfUpdateStatus?.total ?? "-"}
                건
                <br />• 생성된 출고 수 :{" "}
                <b>{resultOfUpdateStatus?.success ?? "-"}건</b>
                <br />• 생성되지 않은 출고 수 :{" "}
                {resultOfUpdateStatus?.fail?.count ?? "-"}건
                <br />
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              marginTop={1}
              marginBottom={2}
              textAlign="center"
            >
              * 생성된 출고 건에 대하여만 출력이 됩니다.
            </Grid>
          </>
        )}
      </>
    );
  }, [
    isLoadingOfUpdateStatus,
    resultOfUpdateStatus?.fail?.count,
    resultOfUpdateStatus?.success,
    resultOfUpdateStatus?.total,
    statusOfUpdateStatus,
  ]);

  const ModalToUpdateStatus = useMemo(() => {
    const canPrintClick = canPrint && statusOfUpdateStatus === "success";

    return (
      <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle>거래명세서 출력</DialogTitle>

        <DialogContent>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            {UpdateStatusInfo}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            onClick={printTransactionStatement}
            disabled={!canPrintClick}
          >
            거래명세서 출력
          </Button>
        </DialogActions>

        {ResponseHandlerOfUpdateStatus}
      </Dialog>
    );
  }, [
    ResponseHandlerOfUpdateStatus,
    UpdateStatusInfo,
    canPrint,
    handleModalClose,
    isModalOpen,
    printTransactionStatement,
    statusOfUpdateStatus,
  ]);

  return {
    ModalToUpdateStatus,
    updateStatusAndPrintTransactionStatement,
    canUpdateStatus,
  };
}

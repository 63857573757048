import styled, { css } from "styled-components";

import { COLOR } from "styles/constants";

const detailContainer = styled.div<{ hasTitle: boolean }>`
  width: 1184px;
  height: ${({ hasTitle }) => (hasTitle ? "648px" : "624px")};

  .input-radio {
    margin-bottom: 24px;
  }
`;

const detailImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 570px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid ${COLOR.grayScale_300};
  background: ${COLOR.grayScale_50};
`;

const detailImage = styled.img<{ zoom: boolean }>`
  width: 100%;
  height: 100%;

  ${({ zoom }) =>
    zoom
      ? css`
          object-fit: cover;
          cursor: zoom-out;
        `
      : css`
          object-fit: contain;
          cursor: zoom-in;
        `};
`;

export default { detailContainer, detailImageContainer, detailImage };

import { MutationSideEffectType, useAppMutation } from "services/query";

import { PRINT_REQ, PRINT_RES } from "api-interfaces/print";

export const PRINT_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/PRINT_QUERY" }] as const,
};

function usePrint(
  sideEffectOptions?: MutationSideEffectType<PRINT_REQ, PRINT_RES>
) {
  const mutation = useAppMutation<PRINT_REQ, PRINT_RES>({
    requestOptions: {
      method: "post",
      path: "",
      apiType: "LocalPrinter",
      isLocalMiddleWare: true,
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

const PRINT_QUERY = {
  usePrint,
};
export default PRINT_QUERY;

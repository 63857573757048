import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ShippingItemDetail } from "types/shipping";
import { SkuPickingInfo } from "../utils";
import { PickingList } from "./PickingList";

function UpdateItemQuantityForm({
  shippingDetail,
  initialSkuPickingInfoList,
  onUpdateSuccess,
}: {
  shippingDetail: ShippingItemDetail;
  initialSkuPickingInfoList: SkuPickingInfo[];
  onUpdateSuccess: () => void;
}) {
  const skuPickingInfoList = initialSkuPickingInfoList;

  return (
    <>
      <Paper
        style={{
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          출고 상품 수량 수정(차감) 폼
        </Typography>
        <Stack gap={"10px"}>
          <TextField
            label="센터번호"
            size="small"
            value={shippingDetail.warehouseId}
            disabled={true}
          />
          <TextField
            label="주문번호"
            size="small"
            value={shippingDetail.orderNo || " "}
            disabled={true}
          />
          <TextField
            label="받는분 이름"
            size="small"
            value={shippingDetail.customerName}
            disabled={true}
          />

          <br />

          <span>피킹 로케이션 정보 </span>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"120px"}>SKU ID</TableCell>
                  <TableCell width={"150px"}>주문 수량</TableCell>
                  <TableCell width={"500px"}>피킹 로케이션</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {skuPickingInfoList.map((skuPickingInfo, keyInner) => (
                  <PickingList
                    shippingId={shippingDetail.id}
                    skuPickingInfo={skuPickingInfo}
                    onUpdateSuccess={onUpdateSuccess}
                    key={keyInner}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />
          <div>skuPickingInfoList[dev]</div>
          <textarea
            style={{
              backgroundColor: "black",
              color: "greenyellow",
              resize: "none",
              width: "100%",
              height: "500px",
            }}
            readOnly={true}
            rows={Object.keys(skuPickingInfoList).length}
            value={JSON.stringify(skuPickingInfoList, null, 2)}
          />
        </Stack>
      </Paper>
    </>
  );
}

export default UpdateItemQuantityForm;

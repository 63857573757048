import { useCallback, useMemo, useState } from "react";
import { NoteAlt } from "@mui/icons-material";
import Button from "@mui/material/Button";

import SHIPPING_QUERY from "queries/SHIPPING_QUERY";
import { ShippingDeliveryType, ShippingItemDetail } from "types/shipping";

import InputParcelModal from "./InputParcelModal";
import InputTruckModal from "./InputTruckModal";

export default function useInputDeliveryDetail({
  shippingId,
  deliveryType,
  shippingDetailData,
}: {
  shippingId: number | undefined;
  deliveryType: ShippingDeliveryType | undefined;
  shippingDetailData: ShippingItemDetail | undefined;
}) {
  const [
    isVisibleInputDeliveryDetailModal,
    setIsVisibleInputDeliveryDetailModal,
  ] = useState(false);

  const handleInputDeliveryDetailModalOpen = useCallback(() => {
    setIsVisibleInputDeliveryDetailModal(true);
  }, []);

  const handleInputDeliveryDetailModalClose = useCallback(() => {
    setIsVisibleInputDeliveryDetailModal(false);
  }, []);

  const {
    data: truckDeliveryDetailData,
    ResponseHandler: ResponseHandlerOfGettingTruckDeliveryDetail,
  } = SHIPPING_QUERY.useGetTruckDeliveryDetail({
    shippingId: shippingId ?? 0,
    enabled:
      deliveryType === "truck" &&
      !!shippingId &&
      isVisibleInputDeliveryDetailModal,
    handleConfirmFailure: handleInputDeliveryDetailModalClose,
  });

  const DeliveryDetailButton = useMemo(
    () => (
      <Button
        startIcon={<NoteAlt />}
        variant="outlined"
        onClick={handleInputDeliveryDetailModalOpen}
      >
        배송정보 수기 입력
      </Button>
    ),
    [handleInputDeliveryDetailModalOpen]
  );

  const InputDeliveryDetailModal = useMemo(() => {
    if (!shippingId || !isVisibleInputDeliveryDetailModal) return null;

    if (
      (deliveryType === "parcel" || deliveryType === "freight") &&
      !!shippingDetailData
    ) {
      return (
        <InputParcelModal
          shippingId={shippingId}
          shippingDetailData={shippingDetailData}
          onModalClose={handleInputDeliveryDetailModalClose}
        />
      );
    }

    if (deliveryType === "truck") {
      return (
        <>
          <InputTruckModal
            shippingId={shippingId}
            truckDeliveryDetailData={truckDeliveryDetailData}
            onModalClose={handleInputDeliveryDetailModalClose}
          />
          {ResponseHandlerOfGettingTruckDeliveryDetail};
        </>
      );
    }
  }, [
    ResponseHandlerOfGettingTruckDeliveryDetail,
    deliveryType,
    handleInputDeliveryDetailModalClose,
    isVisibleInputDeliveryDetailModal,
    shippingDetailData,
    shippingId,
    truckDeliveryDetailData,
  ]);

  return {
    DeliveryDetailButton,
    InputDeliveryDetailModal,
  };
}

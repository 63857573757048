import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  Tooltip,
} from "@mui/material";

import { GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR } from "api-interfaces/shipping";

export default function SkuIdSearchOperatorSwitch({
  operator,
  handleOperatorChange,
}: {
  operator: GET_ADMIN_SHIPPING_LIST_REQ_OPERATOR;
  handleOperatorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <FormControl
      component="fieldset"
      sx={{ position: "absolute", top: 54, right: 16 }}
    >
      <FormLabel sx={{ fontSize: "0.875rem" }} component="legend">
        SKU ID 검색조건
      </FormLabel>
      <Tooltip
        title={
          operator === "and"
            ? "검색한 SKU ID만 출고물품에 존재하는 출고건을 조회합니다."
            : "검색한 SKU ID가 출고물품에 포함된 출고건을 조회합니다."
        }
        placement="left"
      >
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={operator === "and"}
              onChange={handleOperatorChange}
              name="operator"
            />
          }
          label={operator === "and" ? "AND" : "OR"}
          labelPlacement="start"
          sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.875rem" } }}
        />
      </Tooltip>
    </FormControl>
  );
}

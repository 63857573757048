import { ShippingInputForm } from "types/fulfillment";
import {
  ShipperMemoType,
  ShippingItemDetail,
  ShippingMemo,
} from "types/shipping";

export const convertShippingDetailToShippingInputForm = (
  shippingItemDetail: ShippingItemDetail
): ShippingInputForm => {
  const data = shippingItemDetail;
  const shipperMemo = (data.memo ?? []).filter(
    (memo) => memo.category === "shipper"
  ) as ShipperMemoType[];
  const memo = data.memo?.filter((memo) => memo.category !== "shipper") as
    | ShippingMemo[]
    | undefined;

  const newShippingInputForm: ShippingInputForm = {
    deliveryArea: data.deliveryArea || "domestic",
    warehouseId: data.warehouseId,
    deliveryType: data.deliveryType,
    dueDate: data.dueDate,
    customerName: data.customerName,
    customerPhone: data.customerPhone,
    customerPostalCode:
      data.customerPostalCode !== "-" ? data.customerPostalCode : null,
    customerAddress: data.customerAddress !== "-" ? data.customerAddress : null,
    customerDetailAddress:
      data.customerDetailAddress !== "-" ? data.customerDetailAddress : null,
    saleChannel: data.saleChannel,
    orderNo: data.orderNo,
    senderName: data.senderName,
    senderPhone: data.senderPhone,

    sku: data.orders
      .filter((v) => !!v.skuId)
      .map((v) => ({
        skuId: v.skuId as number,
        qty: v.quantity,
      })),

    productGroupsInput: data.orders
      .filter((v) => !!v.productGroupId)
      .map((v) => ({
        productGroupId: v.productGroupId as number,
        qty: v.quantity,
      })),

    shipperMemo,

    memo: memo?.find(({ category }) => category === "customer"),
  };

  return newShippingInputForm;
};

import { useMemo, useState } from "react";
import {
  SelectButtonProps,
  SelectButtonValue,
} from "headlessComponents/form/useSelectButton";

import newId from "utils/newId";
import { COLOR } from "../../../styles/colors";

import Badge from "_sds-v2/components/Badge";
import CheckboxFilledActiveCheckedIcon from "_sds-v2/components/svgIcons/CheckboxFilledActiveCheckedIcon";
import CheckboxFilledActiveIcon from "_sds-v2/components/svgIcons/CheckboxFilledActiveIcon";
import CheckboxFilledDisabledCheckedIcon from "_sds-v2/components/svgIcons/CheckboxFilledDisabledCheckedIcon";
import CheckboxFilledDisabledIcon from "_sds-v2/components/svgIcons/CheckboxFilledDisabledIcon";
import CheckboxOutlinedActiveCheckedIcon from "_sds-v2/components/svgIcons/CheckboxOutlinedActiveCheckedIcon";
import CheckboxOutlinedActiveIcon from "_sds-v2/components/svgIcons/CheckboxOutlinedActiveIcon";
import CheckboxOutlinedDisabledCheckedIcon from "_sds-v2/components/svgIcons/CheckboxOutlinedDisabledCheckedIcon";
import CheckboxOutlinedDisabledIcon from "_sds-v2/components/svgIcons/CheckboxOutlinedDisabledIcon";
import QuestionOutlinedIcon from "_sds-v2/components/svgIcons/QuestionOutlinedIcon";
import RadioActiveCheckedIcon from "_sds-v2/components/svgIcons/RadioActiveCheckedIcon";
import RadioActiveIcon from "_sds-v2/components/svgIcons/RadioActiveIcon";
import RadioDisabledCheckedIcon from "_sds-v2/components/svgIcons/RadioDisabledCheckedIcon";
import RadioDisabledIcon from "_sds-v2/components/svgIcons/RadioDisabledIcon";

import Tooltip from "../../Tooltip";
import Styled from "./index.styles";

const BTN_ICON_BY_STATUS = {
  radio: {
    active: {
      selected: RadioActiveCheckedIcon,
      unselected: RadioActiveIcon,
    },
    disabled: {
      selected: RadioDisabledCheckedIcon,
      unselected: RadioDisabledIcon,
    },
  },
  checkboxFilled: {
    active: {
      selected: CheckboxFilledActiveCheckedIcon,
      unselected: CheckboxFilledActiveIcon,
    },
    disabled: {
      selected: CheckboxFilledDisabledCheckedIcon,
      unselected: CheckboxFilledDisabledIcon,
    },
  },
  checkboxOutlined: {
    active: {
      selected: CheckboxOutlinedActiveCheckedIcon,
      unselected: CheckboxOutlinedActiveIcon,
    },
    disabled: {
      selected: CheckboxOutlinedDisabledCheckedIcon,
      unselected: CheckboxOutlinedDisabledIcon,
    },
  },
};

/**
 * Figma에서의 'Select/RadioBTN', 'Select/'CheckBox'의 컴포넌트.
 * 두 컴포넌트의 차이가 Select Icon모양(uiType속성)밖에 없어서 두 컴포넌트를 통합하는 컴포넌트로서 'SelectButton'를 사용
 *
 * TODO: uiType이 checkbox이면서 값이 boolean인 경우 value를 사용할수없는 이슈가 있음. 일단은 selected와 onSelect만을 이용해서 사용하고 있으나 추후 개선 필요
 */
export default function SelectButton<T extends SelectButtonValue>({
  uiType,
  size,
  label,
  isLabelHidden,
  value,
  desc,
  onSelect,
  tooltipInfo,
  badgeInfo,
  selected,
  disabled,
  isSelectList,
  name,
  className,
}: SelectButtonProps<T>) {
  // label id를 unique하게 사용하기 위함
  const [inputId] = useState(() => newId(`${uiType}-id-`));

  const SelectIcon = useMemo(() => {
    const iconSize = size === "default" ? 16 : 24;

    const Icon =
      BTN_ICON_BY_STATUS[uiType][disabled ? "disabled" : "active"][
        selected ? "selected" : "unselected"
      ];

    return <Icon width={iconSize} height={iconSize} />;
  }, [disabled, selected, size, uiType]);

  return (
    <Styled.container
      className={`${className ? className : ""} select-button ${
        selected ? "selected" : ""
      }`}
      disabled={disabled}
      size={size}
      selected={selected}
      isSelectList={isSelectList}
      desc={desc}
      isLabelHidden={isLabelHidden}
    >
      <label htmlFor={inputId}>
        <div className="select-icon-container">{SelectIcon}</div>

        <div className="label-text-container">
          {badgeInfo && (
            <Badge
              borderType="filled"
              label={badgeInfo.label}
              colorInfo={badgeInfo.colorInfo}
            />
          )}

          <div className="title-area">
            <span
              className={`title ${(isLabelHidden && "visually-hidden") ?? ""} `}
            >
              {label}
            </span>

            {tooltipInfo && (
              <Tooltip
                title={tooltipInfo.title}
                position={tooltipInfo.position}
                desc={tooltipInfo.desc}
                width={tooltipInfo.width}
              >
                <QuestionOutlinedIcon
                  className="tooltip-trigger-question-icon"
                  width={16}
                  height={16}
                  color={COLOR.grayScale_600}
                />
              </Tooltip>
            )}
          </div>

          {desc && <div className="desc">{desc}</div>}
        </div>
      </label>

      <input
        id={inputId}
        type={uiType === "radio" ? "radio" : "checkbox"}
        name={name}
        value={value}
        disabled={disabled}
        className="visually-hidden"
        onClick={(e) => {
          e.stopPropagation();

          onSelect((e.target as HTMLInputElement).value as T);
        }}
      />
    </Styled.container>
  );
}

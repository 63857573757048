import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const container = styled.div<{ width?: string; hasTitle: boolean }>`
  width: ${({ width }) => width || "100%"};
  padding: ${({ hasTitle }) =>
    hasTitle ? "24px 16px 48px 16px" : "24px 16px"};
  border-radius: 4px;
  border: 1px solid ${COLOR.grayScale_300};
  background: ${COLOR.white};

  .image-title {
    display: block;
    text-align: center;
    margin-bottom: 16px;
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.grayScale_700};
  }

  .slick-slider {
    padding: 0 40px;

    .slick-slide {
      display: flex;

      > div {
        margin-right: 8px;
        flex-grow: 1;
        flex-basis: 0;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .slick-current {
      cursor: pointer;
    }

    .slick-arrow::before {
      display: none;
    }

    .slick-arrow {
      z-index: 1;
      width: auto;
    }

    .slick-prev {
      left: 0px;
    }

    .slick-next {
      right: 0px;
    }

    .slick-disabled {
      cursor: default;
    }

    .slick-dots {
      left: 0;

      li {
        width: 8px;
        height: 8px;
        margin: 0 1px;
      }

      button {
        width: 8px;
        height: 8px;
        padding: 0;
      }

      button::before {
        width: 8px;
        height: 8px;
        opacity: 1;
        line-height: 8px;
        color: ${COLOR.primaryBlue_40};
      }

      .slick-active button::before {
        width: 8px;
        height: 8px;
        opacity: 1;
        line-height: 8px;
        color: ${COLOR.primaryBlue};
      }
    }
  }
`;

const block = styled.div<{ imageUrl: string }>`
  width: 460px;
  height: 460px;
  width: 100%;
  padding-top: 71.5%;
  background-color: ${COLOR.grayScale_100};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  border: 1px solid ${COLOR.grayScale_400};
`;

const noImageBlock = styled.div`
  width: 460px;
  height: 460px;
  width: 100%;
  background-color: ${COLOR.grayScale_100};
  cursor: default;
  color: ${COLOR.grayScale_500};
  text-align: center;
  line-height: 460px;
`;

export default {
  container,
  block,
  noImageBlock,
};

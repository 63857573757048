import { Button, Paper, Typography } from "@mui/material";
import axios from "axios";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

function InvoiceTestIssue() {
  return (
    <Layout>
      <Paper
        style={{
          maxWidth: "700px",
          padding: "20px",
        }}
        elevation={2}
      >
        <Typography
          style={{ fontSize: 20, marginBottom: "20px" }}
          color="text.primary"
        >
          송장 출력 테스트(1457포트 프린터)
        </Typography>

        <Button
          variant="contained"
          size="medium"
          style={{ marginTop: "10px" }}
          onClick={() => {
            axios.post("http://localhost:1457", {
              script:
                "^XA^FWR^POI^SEE:UHANGUL.DAT^FS^CW0,E:H2.FNT^CI26^FS^LH5,20^FO740,80^A0,30,30^FD{0}^FS^FO740,430^A0,19,19^FD{1}^FS^FO740,640^A0,19,19^FD{2}^FS^FO600,8^BY2,3,120^BC,,N,N^FD{3}^FS^FO600,350^A0,42,42^FD{4}^FS^FT^A0,85,85^FD{5}^FS^FT^A0,42,42^FD{6}^FS^FO560,40^A0,21,21^FD{7}^FS^FT^A0,21,21^FD{8}^FS^FO490,40^FB800,2^A0,21,21^FD{9}^FS^FO550,575^BY3^BC,40,N,N,N^FD>;{10}^FS^FO440,40^A0,42,42^FD{11}^FS^FO400,40^A0,17,17^FD{12}^FS^FO400,250^A0,17,17^FD{13}^FS^FO395,550^A0,21,21^FD{14}^FS^FO395,780^A0,21,21^FD{15}^FS^FO395,910^A0,21,21^FD{16}^FS^FO370,40^A0,17,17^FD{17}^FS^FO185,40^FB1000,8^A0,20,20^FD{18}^FS^LH0,0^FO75,40^A0,35,35^FD{19}^FS^FO55,40^A0,19,19^FD{20}^FS^FO25,40^A0,25,25^FD{21}^FS^FO25,420^A0,21,21^FD{22}^FS^FO25,570^A0,21,21^FD{23}^FS^FO30,645^BY3^BC,80,N,N,N^FD>;{24}^FS^FO7,715^A0,21,21^FD{24}^FS^XZ",
              value: [
                {
                  "{0}": "1234-1234-5678",
                  "{1}": "2020.01.01",
                  "{2}": "1/1",
                  "{3}": ">98K95",
                  "{4}": "8",
                  "{5}": "K95",
                  "{6}": "-3cnt:1",
                  "{7}": "홍길*",
                  "{8}": "010-1234-****/010-1234-****",
                  "{9}":
                    "서울중구세종대로9길53[서소문동58-12]홍길동아파트101동201호",
                  "{10}": "1234-1234-5678",
                  "{11}": "홍길동아파트101동201호",
                  "{12}": "송화인이름",
                  "{13}": "012-123-1234",
                  "{14}": "극소B1",
                  "{15}": "0",
                  "{16}": "신용",
                  "{17}": "서울중구세종대로9길53[서소문동58-12]",
                  "{18}":
                    "테스트TEST상품정보ABCDEFG0000컬러[COLOR]:12345BK_블랙\\&테스트TEST상품정보ABCDEFG0001컬러[COLOR]:12345BK_화이트",
                  "{19}": "서소문58-12대한통운",
                  "{20}": "안내문구삽입",
                  "{21}": "중구Bsub-홍길동-A01",
                  "{22}": "0",
                  "{23}": "신용",
                  "{24}": "123412345678",
                },
              ],
            });
          }}
        >
          시험출력
        </Button>
      </Paper>
    </Layout>
  );
}

export default withRequireAuth(InvoiceTestIssue);

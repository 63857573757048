import { memo, ReactNode } from "react";
import { TableCell } from "@mui/material";
import { grey } from "@mui/material/colors";

type PinnedCell = {
  isPinned: true;
  leftPosition: number;
  isLastPinnedCell: boolean;
};

type DefaultCell = {
  isPinned: false;
};

function MemoizedCell({
  rowValue,
  ...propsByIsPinned
}: {
  rowValue: ReactNode;
} & (PinnedCell | DefaultCell)) {
  const { isPinned } = propsByIsPinned;

  if (!isPinned) {
    return (
      <TableCell align="left" sx={{ verticalAlign: "middle" }}>
        {rowValue}
      </TableCell>
    );
  }

  const { leftPosition, isLastPinnedCell } = propsByIsPinned;

  return (
    <TableCell
      align="left"
      sx={{
        verticalAlign: "middle",

        position: "sticky",
        left: leftPosition,
        zIndex: 1,
        backgroundColor: "background.default",

        "&:hover": { backgroundColor: "background.default" },

        ...(isLastPinnedCell && { borderRight: `1px solid ${grey[300]}` }),
      }}
    >
      {rowValue}
    </TableCell>
  );
}

export default memo(MemoizedCell);

import styled from "styled-components";

import { COLOR } from "styles/constants";
import { setFontStyle } from "styles/typography";

const managementDateList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const selectItemContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${COLOR.grayScale_300};
  border-radius: 4px;
`;

const description = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    ${setFontStyle("Body7")};
    color: ${COLOR.grayScale_700};
  }
`;

const divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLOR.grayScale_300};
`;

const dateListByType = styled.div`
  display: flex;
  align-items: center;
`;

const dateListWrapper = styled.div<{ isNormal: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: ${({ isNormal }) => (isNormal ? "34px" : "24px")};
`;

const dateList = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 16px;
  flex-wrap: wrap;
`;

const emptyMessage = styled.p`
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_400};
`;

export default {
  managementDateList,
  selectItemContainer,
  description,
  divider,
  dateListByType,
  dateListWrapper,
  dateList,
  emptyMessage,
};

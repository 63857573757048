import { ReactNode } from "react";

import { COLOR } from "../../../styles/colors";

import StateBadge from "../StateBadge";

type InspectionStatus = "unselect" | "stock" | "faulty";

interface StateBadgeForInspectionProps {
  status: InspectionStatus;
  label: ReactNode;
  disabled?: boolean;
  className?: string;
}

type ActivationStatus = "enabled" | "disabled";

export type { InspectionStatus, StateBadgeForInspectionProps };

const STYLE_BY_STATUS: {
  [key in InspectionStatus]: {
    [key in ActivationStatus]: {
      color: string;
      borderColor: string;
      backgroundColor: string;
    };
  };
} = {
  stock: {
    enabled: {
      color: "#005AA3",
      borderColor: COLOR.primary_100,
      backgroundColor: "rgba(193, 220, 248, 0.40)",
    },
    disabled: {
      color: "rgba(0, 90, 163, 0.40)",
      borderColor: "rgba(193, 220, 248, 0.40)",
      backgroundColor: "rgba(193, 220, 248, 0.20)",
    },
  },

  faulty: {
    enabled: {
      color: "#8F2300",
      borderColor: "#F48B6A",
      backgroundColor: "rgba(237, 87, 39, 0.30)",
    },
    disabled: {
      color: "rgba(143, 35, 0, 0.40)",
      borderColor: "rgba(244, 139, 106, 0.30)",
      backgroundColor: "rgba(237, 87, 39, 0.10)",
    },
  },

  unselect: {
    enabled: {
      color: COLOR.grayScale_500,
      borderColor: COLOR.grayScale_300,
      backgroundColor: COLOR.wh,
    },
    disabled: {
      color: COLOR.grayScale_500,
      borderColor: COLOR.grayScale_300,
      backgroundColor: COLOR.wh,
    },
  },
};

export default function StateBadgeForInspection({
  status,
  label,
  disabled,
  className,
}: StateBadgeForInspectionProps) {
  const disabledStatus = disabled ? "disabled" : "enabled";

  return (
    <StateBadge
      color={STYLE_BY_STATUS[status][disabledStatus].color}
      borderColor={STYLE_BY_STATUS[status][disabledStatus].borderColor}
      backgroundColor={STYLE_BY_STATUS[status][disabledStatus].backgroundColor}
      type="default"
      size="small"
      hasBorder
      label={label}
      className={className}
    />
  );
}

import styled from "styled-components";

const excelUploadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > .button,
  a {
    margin-right: 16px;
  }

  > .button:last-child {
    margin-right: 0;
  }
`;

// 풀필먼트 엑셀 업로드 모달 공통 리스트 컨테이너
const excelListContainer = styled.div`
  .table {
    margin-top: 24px;

    table {
      min-height: 300px;
    }

    th {
      .tool-tip {
        margin-left: 2px;
        margin-top: 3px;
      }

      .tool-tip .trigger {
        display: flex;
        align-items: center;
      }
    }

    td {
      display: flex;
      align-items: center;
    }
  }
`;

export default {
  excelUploadButtonContainer,
  excelListContainer,
};

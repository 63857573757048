import useTableHeadFilter, {
  TableHeadFilterOption,
} from "hooks/useTableHeadFilterForBofulAdminOnly";

const filterOptionList: TableHeadFilterOption<boolean>[] = [
  { label: "ETD 제공", value: true },
  { label: "ETD 미제공", value: false },
];

export default function useIsETDSupportedFilter({
  resetCurrentPage,
}: {
  resetCurrentPage: () => void;
}) {
  const {
    FilterPanel: IsETDSupportedFilterPanel,
    filterList: isETDSupportedFilterList,
    resetFilterState: resetIsETDSupportedFilter,
  } = useTableHeadFilter({
    filterOptionList,
    resetCurrentPage,
    isSingle: true,
  });

  return {
    IsETDSupportedFilterPanel,
    isETDSupportedPayloadListByFilter: isETDSupportedFilterList,
    resetIsETDSupportedFilter,
  };
}

import Styled from "./index.styles";

type DataItem = { label?: string; value: string | number };

type SummaryItem = {
  list: DataItem[];
  hasBottomDivider?: boolean;
  isItemName?: boolean;
};

type SummaryData = SummaryItem[];

// 모달에서 공통으로 쓰이는 상단 요약 컴포넌트.
export default function ModalInfoSummary({
  summaryData,
  className,
}: {
  summaryData: SummaryData;
  className?: string;
}) {
  return (
    <Styled.summary className={`modal-info-summary ${className ?? ""}`}>
      {summaryData.map(({ list, hasBottomDivider, isItemName }, i) => {
        return (
          <Styled.summaryItem
            key={`summary-list-${i}`}
            hasBottomDivider={hasBottomDivider}
            isItemName={isItemName}
          >
            {list.map(({ label, value }) => (
              <div key={label} className="data-item">
                {label && <dt>{label}:</dt>}

                <dd>{value}</dd>
              </div>
            ))}
          </Styled.summaryItem>
        );
      })}
    </Styled.summary>
  );
}
